import React from "react";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { selectCatalogState } from "store/catalog";
import {
  fetchProductDescription,
  updateProductDescription,
} from "store/catalog/thunks";
import { displaySnackbar } from "store/snackbar";

const MachineTranslation = (row: any) => {
  const { selectedRow } = useAppSelector(selectCatalogState);
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();
  const [value, setValue] = useState(row.row.value);

  const handleCheckboxChange = async (event: any) => {
    if (
      row.row.id === selectedRow.id &&
      !!selectedRow &&
      selectedRow[row.row.value] !== event.target.checked
    ) {
      setValue(event.target.checked);
      await dispatch(
        updateProductDescription({
          ...selectedRow,
          [row.row.field]: event.target.checked,
        }),
      )
        .unwrap()
        .finally(() => {
          dispatch(
            displaySnackbar({
              message: "Product updated successfully",
              action: null,
              open: false,
            }),
          );
        });
      dispatch(fetchProductDescription(searchParams.toString()));
    }
  };

  return (
    <div>
      <label>
        <input
          type="checkbox"
          name="trueCheckbox"
          checked={value}
          onChange={handleCheckboxChange}
        />
      </label>
    </div>
  );
};
export default MachineTranslation;
