import React, { useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useAppSelector from "hooks/useAppSelector";
import { selectCatalogState, setOpenExportDialog } from "store/catalog";
import useAppDispatch from "hooks/useAppDispatch";
import config from "../../../../config";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

const linkTitle = (url: string): string => {
  if (!url) return "Invalid URL";

  const fileExtension = url.slice(url.lastIndexOf(".") + 1);
  const lowerUrl = url.toLocaleLowerCase();
  if (lowerUrl.includes("images")) {
    return `Images (${fileExtension})`;
  } else if (lowerUrl.includes("attachments")) {
    return `Attachments (${fileExtension})`;
  } else if (lowerUrl.includes("garp")) {
    return `Garp (${fileExtension})`;
  } else if (lowerUrl.includes("stock_data")) {
    return `Products (Larger) (${fileExtension})`;
  } else if (lowerUrl.includes("products")) {
    return `Products (${fileExtension})`;
  }

  return `Unknown (${fileExtension})`;
};

const DownloadLink = ({ url }: { url: string }): JSX.Element => (
  <a href={config.api.replace("/api/v1", "") + url}>{linkTitle(url)}</a>
);

const ExportDialog = (): JSX.Element => {
  const { openExportDialog, exportUrls, publishAction } =
    useAppSelector(selectCatalogState);
  const dispatch = useAppDispatch();

  const urls = useMemo(
    () => [...exportUrls].splice(0, exportUrls.length - 1),
    [exportUrls],
  );
  const notes = useMemo(
    () =>
      ("" + exportUrls[exportUrls.length - 1])
        .replace(/<\/?b>/g, "")
        .split("<br>"),
    [exportUrls],
  );

  const action = useMemo(() => publishAction.split("_")[0], [publishAction]);

  const handleClose = () => {
    dispatch(setOpenExportDialog(false));
  };

  return (
    <Dialog
      open={openExportDialog}
      onClose={handleClose}
      aria-labelledby="export-dialog-title"
      aria-describedby="export-dialog-description"
    >
      <DialogTitle
        id="export-dialog-title"
        sx={{ textTransform: "capitalize" }}
      >
        {action} export
      </DialogTitle>
      <DialogContent
        sx={{
          a: {
            textDecoration: "none",
          },
        }}
      >
        <p>Download</p>
        <ul>
          {urls.filter(Boolean)?.map((url, i) => (
            <li key={url + i}>
              <DownloadLink url={url} />
            </li>
          ))}
        </ul>
        {notes.length > 1 && (
          <>
            <Divider sx={{ margin: "0.6rem 0" }} />
            <div>
              {notes?.map((note) => (
                <Box sx={{ padding: "0.2rem 0" }} key={note}>
                  {note}
                </Box>
              ))}
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ExportDialog;
