import { createAsyncThunk } from "@reduxjs/toolkit";
import procurementCategories from "services/procurement-categories";
import {
  IProcurementCategoriesResponseDTO,
  IProcurementCategory,
  IUpdateProcurementCategoryDTO,
} from "./interfaces";
import { removeProcurementCategory } from ".";

export const fetchProcurementCategoriesAsync = createAsyncThunk(
  "procurementCategories/fetchProcurementCategories",
  async (
    params: URLSearchParams,
  ): Promise<IProcurementCategoriesResponseDTO> => {
    return await procurementCategories
      .service()
      .fetchProcurementCategories(params);
  },
);
export const fetchProcurementCategoryAsync = createAsyncThunk(
  "procurementCategories/fetchProcurementCategory",
  async (id: string, thunkAPI): Promise<IProcurementCategory> => {
    return await procurementCategories.service().fetchProcurementCategory(id);
  },
);
export const addProcurementCategoryAsync = createAsyncThunk(
  "procurementCategories/addProcurementCategory",
  async ({ object }: { object: IUpdateProcurementCategoryDTO }, thunkAPI) => {
    try {
      return await procurementCategories
        .service()
        .addProcurementCategory(object);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
export const updateProcurementCategoryAsync = createAsyncThunk(
  "procurementCategories/updateProcurementCategory",
  async (
    { id, object }: { id: string; object: IUpdateProcurementCategoryDTO },
    thunkAPI,
  ) => {
    try {
      return await procurementCategories
        .service()
        .updateProcurementCategory(id, object);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const deleteProcurementCategory = createAsyncThunk(
  "procurementCategories/deleteProcurementCategories",
  async (id: number, { dispatch }) => {
    await procurementCategories.service().deleteProcurementCategory(id);
    dispatch(removeProcurementCategory(id));
  },
);
