import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash-es";
import { GridEventListener, GridRowId, GridSortModel } from "@mui/x-data-grid";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import {
  deleteProcurementCategory,
  fetchProcurementCategoryAsync,
  fetchProcurementCategoriesAsync,
} from "store/procurement-categories/thunks";
import Filter from "components/filter-products/Filter";
import Searchbar from "components/filter-products/Searchbar";
import {
  clearCategoryMessage,
  selectProcurementCategoriesState,
  setProcurementCatagoriesParams,
} from "store/procurement-categories";
import Toolbar from "./parts/Toolbar";
import ErrorPage from "components/ErrorComponent";
import ColumnsForDataGrid from "./parts/ColumnsForDataGrid";
import { fetchCatalogCategoriesAsync } from "store/catalog-categories/thunks";
import { selectCatalogCategoriesState } from "store/catalog-categories";
import {
  SUB_ROUTE_ADD_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES,
  SUB_ROUTE_EDIT_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES,
} from "constants/urls";
import AvailableFilters from "./parts/AvailableFilters";
import { fetchSupplierListAsync } from "store/procurement-supplierList/thunks";
import useChangeFilters from "hooks/useChangeFilters";
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Snackbar,
} from "@mui/material";
import theme from "theme/theme";
import { DataGridPro } from "@mui/x-data-grid-pro";
import GridContainer from "components/common-components/GridContainer";
import useBreakpoint from "hooks/useBreakpoint";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const ProcurementCategoriesPage = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const changeFilters = useChangeFilters();
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const {
    categories,
    procurementCategoriesLoaded,
    loading,
    success,
    fail,
    error,
  } = useAppSelector(selectProcurementCategoriesState);
  const { catalogCategoriesLoaded } = useAppSelector(
    selectCatalogCategoriesState,
  );
  const isMediumScreen = useBreakpoint("medium");

  const sortModel: GridSortModel = searchParams.has("ordering")
    ? searchParams.get("ordering") === "slug"
      ? [{ field: "slug", sort: "asc" }]
      : [{ field: "slug", sort: "desc" }]
    : [{ field: "slug", sort: null }];

  useEffect(() => {
    !catalogCategoriesLoaded &&
      dispatch(fetchCatalogCategoriesAsync(new URLSearchParams()));
  }, [dispatch, catalogCategoriesLoaded]);

  useEffect(() => {
    dispatch(fetchProcurementCategoriesAsync(searchParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(fetchSupplierListAsync(new URLSearchParams()));
  }, [dispatch]);

  const deleteCategories = () => {
    dispatch(deleteProcurementCategory(Number(selectionModel[0])));
  };

  const handleEvent: GridEventListener<"rowClick"> = (params: any) => {
    if (params.field == "id") {
      dispatch(fetchProcurementCategoryAsync(params.id));
    }
    if (params.field == "slug") {
      navigate(
        `/${SUB_ROUTE_EDIT_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES}?category=${params.id}`,
      );
    }
  };

  if (!isEmpty(error)) {
    return <ErrorPage {...error} />;
  }

  const searchHandler = (value: string) => {
    dispatch(setProcurementCatagoriesParams({ search: value, page: 0 }));
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const sortingOrder = sortModel[0] ? sortModel[0].sort : sortModel[0];
    const orderingParam = sortingOrder
      ? sortingOrder === "asc"
        ? "slug"
        : "-slug"
      : "";
    changeFilters(new URLSearchParams([["ordering", orderingParam]]));
  };

  return (
    <>
      <Backdrop
        sx={{
          color: theme.palette.light.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          height="100vh"
          ml="22vw"
        >
          <CircularProgress />
        </Box>
      </Backdrop>
      <Snackbar
        open={!!success || !!fail}
        autoHideDuration={4000}
        onClose={() => {
          dispatch(clearCategoryMessage());
        }}
      >
        <Alert severity={success ? "success" : "error"} sx={{ width: "100%" }}>
          {success || fail}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Searchbar
          placeholder="Search in Category Slug and URL"
          onChange={searchHandler}
        />

        <ToolboxArea>
          <ToolInfoTooltip title="Add File URL">
            <ToolIconContainer
              onClick={() =>
                navigate(
                  `/${SUB_ROUTE_ADD_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES}`,
                )
              }
            >
              <AddCircleIcon
                fontSize="large"
                sx={{
                  color: theme.palette.primary.main,
                  stroke: theme.palette.primary.main,
                  strokeWidth: 1,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>

          <Divider orientation="vertical" variant="middle" flexItem />

          <Toolbar
            deleteCategories={deleteCategories}
            selectionModel={selectionModel}
          />
        </ToolboxArea>
      </Box>

      <Filter
        viewName="procurement_categories"
        supplierInfoRequired={true}
        children={<AvailableFilters />}
      />

      <GridContainer>
        <DataGridPro
          loading={!procurementCategoriesLoaded}
          rows={categories}
          columns={ColumnsForDataGrid()}
          rowHeight={150}
          columnHeaderHeight={40}
          // disableColumnMenu
          // onCellClick={handleEvent}
          // checkboxSelection
          // rowSelectionModel={selectionModel}
          // onRowSelectionModelChange={(selection: any) => {
          //   if (selection.length > 1) {
          //     const selectionSet = new Set(selectionModel);
          //     const result = selection.filter(
          //       (s: any) => !selectionSet.has(s),
          //     );
          //     setSelectionModel(result);
          //   } else {
          //     setSelectionModel(selection);
          //   }
          // }}
          pagination
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          // sortingMode="server"
          // sortModel={sortModel}
          // onSortModelChange={handleSortModelChange}
        />
      </GridContainer>
    </>
  );
};

export default ProcurementCategoriesPage;
