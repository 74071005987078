import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import React from "react";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    width: 90,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "taxonomy_id",
    headerName: "Taxonomy ID",
    renderCell: (params: any) => {
      return (
        <Typography
          style={{
            cursor: "pointer",
            color: "#26479e",
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {params.value}
        </Typography>
      );
    },
    width: 100,
    editable: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "slug_sv",
    headerName: "SLAG(SWEDISH)",
    editable: false,
    cellClassName: "super-app-theme--cell",
    width: 500,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "slug_en",
    headerName: "SLAG(ENGLISH)",
    width: 500,
    editable: false,
    headerClassName: "super-app-theme--header",
  },
];

export default columns;
