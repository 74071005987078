import React from "react";
import { Box, Button } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

interface ISaveButtonProps {
  onClickHandler: () => void;
}

const SaveButton = ({ onClickHandler }: ISaveButtonProps): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        position: "sticky",
        mt: 2,
      }}
    >
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Button
          variant="contained"
          onClick={onClickHandler}
          sx={{
            width: "6em",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <SaveOutlinedIcon /> Save
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default SaveButton;
