import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Backdrop, CircularProgress } from "@mui/material";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { useEffect, useState } from "react";
import {
  clearSync7DaysMessage,
  selectSyncOrderState,
} from "store/orders/syncOrder";
import {
  clearSync1MonthMessage,
  selectSyncOrderState1month,
} from "store/orders/syncOrder1month";
import { fetchSyncOrder } from "store/orders/syncOrder/thunks";
import { fetchSyncOrder1month } from "store/orders/syncOrder1month/thunks";
import SimpleSnackbar from "components/SimpleSnackbar";
import theme from "theme/theme";
import CommonButton from "components/common-components/CommonButton";

export const options = ["Sync order last 7 days", "Sync order last 1 month"];

const SyncOrderDropdown = (): JSX.Element => {
  const [show, setShow] = useState(false);
  const [sync, setSync] = useState(3);
  const dispatch = useAppDispatch();
  const { message: messageFor7days, loading: loadingFor7days } =
    useAppSelector(selectSyncOrderState);
  const { message: messageFor1month, loading: loadingFor1month } =
    useAppSelector(selectSyncOrderState1month);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClick = (value: number) => {
    setSync(value);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (sync == 0) {
      dispatch(fetchSyncOrder())
        .unwrap()
        .finally(() => setShow(true));
    } else if (sync == 1) {
      dispatch(fetchSyncOrder1month())
        .unwrap()
        .finally(() => setShow(true));
    }
  }, [sync, dispatch]);

  return (
    <>
      {loadingFor7days || loadingFor1month ? (
        <Backdrop
          sx={{
            color: theme.palette.light.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loadingFor7days || loadingFor1month}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}

      {sync == 0 && (
        <SimpleSnackbar
          open={show}
          message={messageFor7days}
          toggleSnackbar={() => {
            dispatch(clearSync7DaysMessage());
            setShow(false);
          }}
          snackbarAction={null}
        />
      )}
      {sync == 1 && (
        <SimpleSnackbar
          open={show}
          message={messageFor1month}
          toggleSnackbar={() => {
            dispatch(clearSync1MonthMessage());
            setShow(false);
          }}
          snackbarAction={null}
        />
      )}

      <CommonButton
        variant="outlined"
        size="small"
        width="fit-content"
        whiteSpace="nowrap"
        fontSize={theme.typography.body2.fontSize}
        bgColor={theme.palette.light.main}
        onClick={handleClick}
      >
        Sync Order
        <ArrowDropDownIcon sx={{ transform: open ? "rotate(180deg)" : "" }} />
      </CommonButton>

      <Menu
        onClose={handleClose}
        anchorEl={anchorEl}
        open={open}
        PaperProps={{
          style: {
            width: "fit-content",
            whiteSpace: "nowrap",
            fontSize: theme.typography.body2.fontSize,
          },
        }}
      >
        {options?.map((option, index) => (
          <MenuItem
            data-testid="menuitem"
            sx={{
              fontSize: "14px",
            }}
            key={option}
            onClick={() => handleMenuItemClick(index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default SyncOrderDropdown;
