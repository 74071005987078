import React from "react";
import {
  Stack,
  Typography,
  IconButton,
  Box,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Container,
  Autocomplete,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { isEmpty } from "lodash-es";
import { useState, useEffect } from "react";
import { selectInventoriesState } from "store/inventories";
import { IInventory } from "store/inventories/interfaces";
import { selectInventoryTransactionsState } from "store/inventory-transactions";
import {
  addInventoryTransactionAsync,
  fetchInventoryTransactionsAsync,
} from "store/inventory-transactions/thunks";
import Modal from "components/ModalComponent";
import { textFieldProps } from "../ColumnsForDataGrid";
import { displaySnackbar } from "store/snackbar";
import { fetchCatalogProducts } from "store/catalog/thunks";
import { selectInventoryLocationState } from "store/inventory-locations";
import { IInventoryLocation } from "store/inventory-locations/interfaces";
import MoveStock from "./MoveStock";
import theme from "theme/theme";

const UpdateInventoryLocation = ({ id }: { id: number }): JSX.Element => {
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [inventory, setInventory] = useState<IInventory>();
  const [quantity, setQuantity] = useState("");
  const [type, setType] = useState("");
  const [action, setAction] = useState("");
  const [comments, setComments] = useState("");
  const [optionSelected, setOptionSelected] = useState<any>(null);

  const { inventoryLocations } = useAppSelector(selectInventoryLocationState);

  const [filteredInventoryLocations, setFilteredInventoryLocations] =
    useState<IInventoryLocation[]>();

  const { inventoryData } = useAppSelector(selectInventoriesState);
  const { inventoryTransactions } = useAppSelector(
    selectInventoryTransactionsState,
  );

  const inventories =
    !isEmpty(inventoryData[id]) && inventoryData[id].inventories;

  const handleClickOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (action === "edit") {
      await dispatch(
        addInventoryTransactionAsync({
          object: {
            product: id,
            location: inventory ? inventory.location_id : 0,
            quantity,
            comments,
            inventory_action: type,
          },
        }),
      )
        .unwrap()
        .finally(() => {
          setModalOpen(false);
          dispatch(fetchCatalogProducts(""));
          dispatch(
            displaySnackbar({
              message: "Transaction updated successfully",
              action: null,
              open: false,
            }),
          );
        });
    }
    if (action === "add") {
      await dispatch(
        addInventoryTransactionAsync({
          object: {
            quantity,
            comments,
            inventory_action: "STOCK_TAKING",
            product: id,
            location: optionSelected ? optionSelected.id : 0,
          },
        }),
      )
        .unwrap()
        .finally(() => {
          setModalOpen(false);
          dispatch(fetchCatalogProducts(""));
          dispatch(
            displaySnackbar({
              message: "Transaction added successfully",
              action: null,
              open: false,
            }),
          );
        });
    }
  };

  useEffect(() => {
    if (!isEmpty(inventoryTransactions)) {
      setQuantity(
        inventoryTransactions[0] && inventoryTransactions[0].quantity,
      );
      setType(
        inventoryTransactions[0] && inventoryTransactions[0].inventory_action,
      );
      setComments(
        inventoryTransactions[0] && inventoryTransactions[0].comments,
      );
    }
  }, [inventoryTransactions]);

  useEffect(() => {
    !isEmpty(inventory) &&
      dispatch(
        fetchInventoryTransactionsAsync(
          new URLSearchParams([
            ["location", inventory.location_id.toString()],
            ["product", id.toString()],
          ]),
        ),
      );
  }, [dispatch, id, inventory]);

  useEffect(() => {
    if (action === "add") {
      setQuantity("0");
      setComments("");
      setType("STOCK_TAKING");
    }
  }, [action]);

  useEffect(() => {
    !isEmpty(inventoryLocations) &&
      setFilteredInventoryLocations(inventoryLocations);
  }, [dispatch, inventoryLocations]);

  const countTotalInventories = () => {
    const total =
      !isEmpty(inventories) &&
      (inventories as IInventory[]).reduce((accu, curr) => {
        const quantity = +curr.accumulated_quantity;
        if (quantity > 0) {
          return accu + quantity;
        } else {
          return accu;
        }
      }, 0);
    if (total && total > 0) {
      return total;
    } else {
      return 0;
    }
  };

  return (
    <>
      <Stack alignItems="start">
        <Box>Total: {countTotalInventories()}</Box>
        {!isEmpty(inventories) &&
          (inventories as IInventory[])?.map((inventory) => {
            const quantity = +inventory.accumulated_quantity;
            if (quantity > 0)
              return (
                <Stack
                  direction="row"
                  alignItems="center"
                  key={inventory.abbreviation_slug}
                >
                  <Typography component="div" fontSize={12}>
                    {quantity} @ {inventory.abbreviation_slug}
                  </Typography>
                  <IconButton
                    name="Edit Inventory Transaction"
                    onClick={() => {
                      setAction("edit");
                      setInventory(inventory);
                      handleClickOpen();
                    }}
                  >
                    <EditIcon sx={{ fontSize: 13 }} />
                  </IconButton>
                </Stack>
              );
            return null;
          })}

        <Stack direction="row" spacing={1}>
          <Button
            sx={{
              width: "fit-content",
              whiteSpace: "nowrap",
              fontSize: theme.typography.body2.fontSize,
            }}
            name="Add Inventory Transaction"
            onClick={() => {
              setAction("add");
              handleClickOpen();
            }}
          >
            <AddIcon sx={{ fontSize: 16, color: "primary.main" }} />
            Add Inventory Transaction
          </Button>
          {!isEmpty(inventories) &&
            (inventories as IInventory[]).filter(
              (inv) => +inv.accumulated_quantity > 0,
            ).length > 0 && (
              <IconButton
                name="Move Stock"
                onClick={() => {
                  setAction("move");
                  handleClickOpen();
                }}
              >
                <MoveUpIcon sx={{ fontSize: 16, color: "primary.main" }} />
              </IconButton>
            )}
        </Stack>
      </Stack>
      <Modal
        title={`${action.charAt(0).toUpperCase() + action.slice(1)} Inventory Transaction`}
        subtitle={
          action === "edit"
            ? `Location: ${inventory && inventory.abbreviation_slug}`
            : ""
        }
        isOpen={modalOpen && action !== "move"}
        handleClose={handleClose}
      >
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              onSubmit={handleOnSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              {action === "add" && (
                <Box sx={{ minWidth: 300 }}>
                  <Autocomplete
                    value={optionSelected}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    id="autocomplete-1"
                    options={
                      !isEmpty(filteredInventoryLocations)
                        ? (
                            filteredInventoryLocations as IInventoryLocation[]
                          )?.map((inventory) => {
                            if (inventory.abbreviation_slug) {
                              return {
                                slug: inventory.abbreviation_slug,
                                id: inventory.id,
                              };
                            } else {
                              return {
                                slug: `${inventory.name_slug} (abbreviation_slug: undefined)`,
                                id: inventory.id,
                              };
                            }
                          })
                        : []
                    }
                    getOptionLabel={(option) => `${option.slug}`}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Location"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    onChange={(e, value) => setOptionSelected(value ?? "")}
                  />
                </Box>
              )}

              <TextField
                label="Quantity"
                type="number"
                id="quantity"
                value={quantity}
                InputProps={{ inputProps: { min: 0, step: ".01" } }}
                onChange={(e) => setQuantity(e.target.value ?? "")}
                {...textFieldProps}
              />

              {action === "edit" && (
                <Box sx={{ my: 2, fontSize: 15 }}>
                  Accoumulated quantity:{" "}
                  {inventory &&
                    parseFloat(inventory.accumulated_quantity).toFixed(2)}
                </Box>
              )}

              {action === "edit" && (
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Transaction Type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <FormControlLabel
                      value="STOCK_IN"
                      control={<Radio />}
                      label="Stock in"
                    />
                    <FormControlLabel
                      value="STOCK_OUT"
                      control={<Radio />}
                      label="Stock out"
                    />
                    <FormControlLabel
                      value="STOCK_TAKING"
                      control={<Radio />}
                      label="Stocktaking"
                    />
                  </RadioGroup>
                </FormControl>
              )}

              <TextField
                label="Comments"
                type="string"
                id="comments"
                value={comments}
                multiline
                maxRows={4}
                onChange={(e) => setComments(e.target.value ?? "")}
                {...textFieldProps}
              />

              <Button
                disabled={action === "add" && optionSelected === null}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Container>
      </Modal>
      <Modal
        title="Move Stock"
        isOpen={modalOpen && action === "move"}
        handleClose={handleClose}
      >
        <MoveStock id={id} handleClose={handleClose} />
      </Modal>
    </>
  );
};

export default UpdateInventoryLocation;
