import React from "react";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { Box, CircularProgress } from "@mui/material";
import useAppSelector from "hooks/useAppSelector";
import { selectCatalogCategoriesState } from "store/catalog-categories";
import { fetchCatalogCategoriesAsync } from "store/catalog-categories/thunks";
import useAppDispatch from "hooks/useAppDispatch";
import { ICatalogCategory } from "store/catalog-categories/interfaces";
import SearchbarTransferList from "./SearchbarTransferList";

function not(a: ICatalogCategory[], b: ICatalogCategory[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}
function intersection(a: ICatalogCategory[], b: ICatalogCategory[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
function union(a: ICatalogCategory[], b: ICatalogCategory[]) {
  return [...a, ...not(b, a)];
}

export default function TransferList(props: {
  setCatalogCatagories: Dispatch<SetStateAction<any[]>>;
}): JSX.Element {
  const { setCatalogCatagories } = props;
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState<ICatalogCategory[]>([]);
  const [left, setLeft] = useState<ICatalogCategory[]>([]);
  const [right, setRight] = useState<ICatalogCategory[]>([]);

  const { catalogCategoriesLoaded, categories } = useAppSelector(
    selectCatalogCategoriesState,
  );
  const [searchText, setSearchText] = useState("");

  const searchResult =
    categories.length > 0 &&
    categories.filter((cat) =>
      cat.slug.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()),
    );

  useEffect(() => {
    !catalogCategoriesLoaded &&
      dispatch(fetchCatalogCategoriesAsync(new URLSearchParams()));
  }, [dispatch, catalogCategoriesLoaded]);

  useEffect(() => {
    searchText
      ? setLeft(searchResult as ICatalogCategory[])
      : setLeft(categories);
  }, [categories, searchText]);

  useEffect(() => {
    setCatalogCatagories(right?.map((rightVal) => rightVal.id));
  }, [right, setCatalogCatagories]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const handleToggle = (value: ICatalogCategory) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const numberOfChecked = (items: ICatalogCategory[]) =>
    intersection(checked, items).length;
  const handleToggleAll = (items: ICatalogCategory[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };
  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };
  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const customList = (title: ReactNode, items: ICatalogCategory[]) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        data-testid="transferList"
        sx={{
          height: 400,
          width: 500,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        disablePadding
        component="div"
        role="list"
      >
        {items?.map((value: ICatalogCategory) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          return (
            <ListItem
              key={value.id}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.slug} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
  return (
    <>
      {!catalogCategoriesLoaded && (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          height="300px"
        >
          <CircularProgress size={25} />
        </Box>
      )}
      <Box>
        <SearchbarTransferList
          searchText={searchText}
          setSearchText={setSearchText}
        />

        <Grid container spacing={0} justifyContent="center" alignItems="center">
          <Grid item>{customList("Available Catalog Categories", left)}</Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 0,
            }}
          >
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
          <Grid item>{customList("Chosen Catalog Categories", right)}</Grid>
        </Grid>
      </Box>
    </>
  );
}
