import { Box, Stack, Paper, Button, Divider } from "@mui/material";
import Searchbar from "components/filter-products/Searchbar";
import SyncOrderDropdown from "../pick-pack-order-page/parts/SyncOrderDrpdown";
import Filter from "components/filter-products/Filter";
import ParentTable from "./parts/OrdersParentTable";
import React, { useEffect } from "react";
import { ordersSelectors, setOrdersParams } from "store/orders";
import { fetchProcurementOrdersAsync } from "store/procurement-orders/thunks";
import useAppDispatch from "hooks/useAppDispatch";
import AvailableFilters from "./parts/AvailableFilters";
import { useSearchParams } from "react-router-dom";
import {
  fetchCompanyDetailsAsync,
  fetchOrdersAsync,
} from "store/orders/thunks";
import useAppSelector from "hooks/useAppSelector";
import PdfGeneratorComponent from "../pdf-generator/PdfGenerator";
import useBreakpoint from "hooks/useBreakpoint";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import theme from "theme/theme";

const CustomersOrdersPage = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const [expandAll, setExpandAll] = React.useState(false);
  const { count, orders, companyDetails } = useAppSelector(ordersSelectors);
  const dispatch = useAppDispatch();
  const isMediumScreen = useBreakpoint("medium");

  useEffect(() => {
    dispatch(fetchCompanyDetailsAsync());
  }, []);

  useEffect(() => {
    dispatch(fetchOrdersAsync(searchParams));
  }, [dispatch, searchParams]);

  const searchHandler = (value: string) => {
    dispatch(setOrdersParams({ search: value, page: 0 }));
    dispatch(fetchProcurementOrdersAsync({ search: value }));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" onChange={searchHandler} />
        <ToolboxArea>
          <ToolInfoTooltip title={expandAll ? "Collapsed all" : "Expand all"}>
            <ToolIconContainer onClick={() => setExpandAll(!expandAll)}>
              {expandAll ? (
                <UnfoldLessIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.primary.main,
                    stroke: theme.palette.primary.main,
                    strokeWidth: 1,
                  }}
                />
              ) : (
                <UnfoldMoreIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.primary.main,
                    stroke: theme.palette.primary.main,
                    strokeWidth: 1,
                  }}
                />
              )}
            </ToolIconContainer>
          </ToolInfoTooltip>

          <Divider orientation="vertical" variant="middle" flexItem />
          <PdfGeneratorComponent
            orders={orders}
            companyDetails={companyDetails}
          />
        </ToolboxArea>
        <SyncOrderDropdown />
      </Box>
      <Filter viewName="customer_orders">
        <AvailableFilters />
      </Filter>
      <ParentTable expandAll={expandAll} />
    </>
  );
};

export default CustomersOrdersPage;
