import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../index";
import { ICatalogMarkupsSliceState } from "./interfaces";
import { fetchCatalogMarkups } from "./thunks";
import { endpoint } from "constants/endpoint";
import config from "../../config";
import { httpErrorHandler } from "utils/error-response";

export const initialState: ICatalogMarkupsSliceState = {
  loading: false,
  markups: [],
  count: 0,
  markupCategoriesLoaded: false,
  filterParams: {
    page: 0,
    limit: 25,
    name: "",
  },
  error: {},
};

const catalogMarkupsSlice = createSlice({
  name: "markups",
  initialState,
  reducers: {
    setCatalogMarkupParams: (state, action) => {
      state.markupCategoriesLoaded = false;
      state.filterParams = { ...state.filterParams, ...action.payload };
    },
    resetAllFilters: (state) => {
      state.markupCategoriesLoaded = false;
      state.filterParams = {
        page: 0,
        limit: 25,
        name: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatalogMarkups.fulfilled, (state, action) => {
        state.markups = action.payload.results;
        state.markupCategoriesLoaded = true;
        state.nextPageUrl = action.payload.next;
        state.previousPageUrl = action.payload.previous;
        state.count = action.payload.count;
      })
      .addCase(fetchCatalogMarkups.rejected, (state, { error }) => {
        state.loading = false;
        state.error = {
          ...httpErrorHandler(error),
          url: config.api + endpoint.catalog_markups,
        };
      });
  },
});

export const selectCatalogMarkups = (
  state: RootState,
): ICatalogMarkupsSliceState => state.markups;

export const { setCatalogMarkupParams, resetAllFilters } =
  catalogMarkupsSlice.actions;

export default catalogMarkupsSlice.reducer;
