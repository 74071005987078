import React, { useState } from "react";
import { Add, Edit, Visibility } from "@mui/icons-material";
import config from "../../../../config";
import { IHistoryItem } from "./Row";
import useAppSelector from "hooks/useAppSelector";
import {
  clearAddedProcurementMessage,
  selectProcurementOrdersState,
} from "store/procurement-orders";
import useAppDispatch from "hooks/useAppDispatch";
import {
  addProcurementOrderItemAsync,
  editProcurementOrderItemAsync,
} from "store/procurement-orders/thunks";
import { selectProcurementSourcesState } from "store/procurement-sources";
import { IProcurementOrderProduct } from "store/procurement-sources/interfaces";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Link,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import theme from "theme/theme";
import { IOrderItem } from "store/procurement-orders/interfaces";

const ProcurementOptions = ({
  historyRow,
}: {
  historyRow: IHistoryItem;
}): JSX.Element => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const {
    procurementOrders,
    loading,
    addedMessage,
    error: procurementOrderError,
  } = useAppSelector(selectProcurementOrdersState);

  const { procurementSources, error } = useAppSelector(
    selectProcurementSourcesState,
  );

  let procurementOptions: {
    product: number;
    procurementOrderProduct: IProcurementOrderProduct;
  }[] = [];

  if (procurementSources) {
    procurementOptions = historyRow.procurementProductsOptions?.map(
      (option) => ({
        product: option,
        procurementOrderProduct: procurementSources[option],
      }),
    );
  }

  const quantityNeeded =
    +historyRow.quantity.qty_ordered -
    +historyRow.quantity.qty_picked -
    +historyRow.quantity.qty_to_be_picked;

  const reserveQuantity = quantityNeeded === 0 ? 1 : quantityNeeded;

  const openOrderNumber = (supplier: number) => {
    const filterBySupplier = procurementOrders.filter(
      (item) => item.supplier === supplier,
    );

    const latestItem = filterBySupplier[0];

    if (filterBySupplier.length > 0) {
      return latestItem.order_made === null ? latestItem.id : null;
    }
    return "latestItem is unknown";
  };

  const addProcurementOrder = (
    supplier: number,
    product: number,
    quantity: number,
  ) => {
    const filterBySupplier = procurementOrders.filter(
      (item) => item.supplier === supplier,
    );
    const latestItem = filterBySupplier[0];

    const foundItem = (filterBySupplier.length > 0 &&
      latestItem.order_items.length > 0 &&
      latestItem.order_items.find(
        (item) => item.product === product,
      )) as IOrderItem;

    if (foundItem !== undefined) {
      dispatch(
        editProcurementOrderItemAsync({
          id: foundItem.id,
          quantity: foundItem.quantity + quantity,
        }),
      )
        .unwrap()
        .finally(() => setOpen(true));
    } else
      dispatch(addProcurementOrderItemAsync({ product, supplier, quantity }))
        .unwrap()
        .finally(() => setOpen(true));
  };

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <>
      {loading ? (
        <Backdrop
          sx={{
            color: theme.palette.light.main,
            backgroundColor: theme.palette.action.disabled,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      {(addedMessage || procurementOrderError) && (
        <Snackbar
          open={open}
          autoHideDuration={4000}
          onClose={() => {
            dispatch(clearAddedProcurementMessage());
            setOpen(false);
          }}
        >
          <Alert
            severity={procurementOrderError ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {addedMessage || procurementOrderError}
          </Alert>
        </Snackbar>
      )}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Supplier</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Price (Sek)</TableCell>
              <TableCell>Procurement Actions</TableCell>
              <TableCell>Product Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {procurementOptions.length > 0 &&
              procurementOptions?.map((row, index) =>
                row.procurementOrderProduct ? (
                  <TableRow
                    key={row.procurementOrderProduct.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.procurementOrderProduct.supplier_name}
                    </TableCell>
                    <TableCell component="th" scope="row" width="20%">
                      [{row.procurementOrderProduct.sku}]{" "}
                      {row.procurementOrderProduct.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.procurementOrderProduct.cost_sek}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {procurementOrderError ? (
                        <Alert severity="error">{procurementOrderError}</Alert>
                      ) : (
                        <Stack direction="row" spacing={1}>
                          <Button
                            variant="contained"
                            size="small"
                            startIcon={<Add />}
                            onClick={() =>
                              addProcurementOrder(
                                row.procurementOrderProduct.supplier,
                                row.product,
                                reserveQuantity,
                              )
                            }
                          >
                            Add {reserveQuantity}x
                          </Button>

                          {openOrderNumber(
                            row.procurementOrderProduct.supplier,
                          ) && (
                            <Link
                              target="_blank"
                              href={`${config.clientUrl}/admin/procurement/order/${openOrderNumber(
                                row.procurementOrderProduct.supplier,
                              )}/change/`}
                            >
                              <Button
                                variant="outlined"
                                size="small"
                                startIcon={<Edit />}
                              >
                                Procurement
                              </Button>
                            </Link>
                          )}
                        </Stack>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Stack direction="row" spacing={1}>
                        {row.procurementOrderProduct.urls[0] && (
                          <Link
                            target="_blank"
                            href={row.procurementOrderProduct.urls[0]}
                          >
                            <Button
                              variant="outlined"
                              size="small"
                              startIcon={<Visibility />}
                            >
                              {row.procurementOrderProduct.sku}
                            </Button>
                          </Link>
                        )}
                        <Link
                          target="_blank"
                          href={`${config.clientUrl}/admin/procurement/product/${row.product}/change/`}
                        >
                          <Button
                            variant="outlined"
                            size="small"
                            startIcon={<Edit />}
                          >
                            {row.procurementOrderProduct.sku}
                          </Button>
                        </Link>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={index}>
                    <TableCell colSpan={6}>no records found</TableCell>
                  </TableRow>
                ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProcurementOptions;
