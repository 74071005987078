import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";

const SupplierAlert = ({
  supplierNames,
}: {
  supplierNames: string[];
}): JSX.Element => {
  const [open, setOpen] = useState(true);
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        Attention! Latest feed for these suppliers is older than expected:
      </DialogTitle>
      <List>
        {supplierNames?.map((supplierName) => (
          <ListItem key={supplierName}>
            <ListItemText style={{ textAlign: "center" }}>
              {supplierName}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default SupplierAlert;
