export const convertOrderText = (key: string): string => {
  switch (key) {
    case "ALL_IN_STOCK":
      return "All items available";
      break;
    case "PARTLY_IN_STOCK":
      return "Some items available";
      break;
    case "TO_BE_PICKED":
      return "Ready for picking";
      break;
    case "NOTHING_TO_PICK":
      return "No items available";
      break;
    case "DONE_PICKING":
      return "All done";
      break;
    case "ALL_BACKORDERED":
      return "All items on backorder";
      break;
    case "PARTLY_BACKORDERED":
      return "Some items on backorder";
      break;
    case "NO_BACKORDERS":
      return "Nothing to backorder";
      break;
    case "READY_FOR_SHIPPING":
      return "Ready to be shipped";
      break;
    case "ON_HOLD":
      return "On hold, e.g. waiting for backordered items";
      break;
    case "PROCESSING":
      return "Processing";
      break;
    case "'UNKNOWN":
      return "Unknown order status";
      break;
    case "ERROR":
      return "Error state";
      break;
    case "NOT_PAID":
      return "Waiting for payment";
      break;
    case "CANCELLED":
      return "Cancelled";
      break;
    case "COMPLETED":
      return "Completed";
      break;
    case "NOT_APPLICABLE":
      return "Not Applicable";
      break;
    default:
      return "Unknown order text";
      break;
  }
};
