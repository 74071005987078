import React from "react";
import { ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { debounce } from "@mui/material";
import theme from "theme/theme";
import CommonIconButton from "components/common-components/CommonIconButton";

interface ISearchbarProps {
  placeholder: string;
  onChange?: any;
  maxWidth?: string | number;
}

const Searchbar = ({
  placeholder,
  onChange,
  maxWidth,
}: ISearchbarProps): JSX.Element => {
  const param = "search";
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = debounce(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.target.value === "") searchParams.delete(param);
      else searchParams.set(param, event.target.value);
      onChange && onChange(event.target.value);
      setSearchParams(searchParams);
    },
    800,
  );

  return (
    <>
      <OutlinedInput
        sx={{
          height: "3.6rem",
          minWidth: "150px",
          backgroundColor: theme.palette.light.main,
          maxWidth: { maxWidth },
          padding: theme.spacing(0.5),
          "& fieldset": {
            borderWidth: 0,
          },
        }}
        fullWidth
        startAdornment={
          <CommonIconButton width="2rem" height="2rem">
            <SearchOutlinedIcon sx={{ fontSize: theme.typography.button }} />
          </CommonIconButton>
        }
        placeholder={placeholder}
        onChange={updateSearchParams}
      />
    </>
  );
};

export default Searchbar;
