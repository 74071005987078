import React, { useState, MouseEvent } from "react";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PublishMenu from "./PublishMenu";
import useAppSelector from "hooks/useAppSelector";
import { selectCatalogState } from "store/catalog";
import theme from "theme/theme";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";

const PublishButton = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { selectedProducts } = useAppSelector(selectCatalogState);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <ToolInfoTooltip title="Publish">
        <ToolIconContainer
          id="publish-positioned-button"
          aria-controls={open ? "publish-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <FileUploadOutlinedIcon
            fontSize="large"
            sx={{
              color: theme.palette.primary.main,
              stroke: theme.palette.primary.main,
              strokeWidth: 1,
            }}
          />
        </ToolIconContainer>
      </ToolInfoTooltip>
      <PublishMenu
        anchorEl={anchorEl}
        setAnchor={setAnchorEl}
        products={() => selectedProducts}
      />
    </>
  );
};

export default PublishButton;
