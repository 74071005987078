import React from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Fragment } from "react";
import theme from "theme/theme";

const SearchbarTransferList = (props: any): JSX.Element => {
  return (
    <Fragment>
      <OutlinedInput
        data-testid="searchitem"
        value={props.searchText}
        sx={{
          height: "2.5rem",
          backgroundColor: theme.palette.light.main,
          border: "1px solid #000",
          maxWidth: "400px",
          "& fieldset": {
            borderWidth: 0,
          },
          fontSize: "0.8rem",
        }}
        fullWidth
        startAdornment={
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        }
        placeholder="search catalog categories"
        onChange={(e) => props.setSearchText(e.target.value)}
      />
    </Fragment>
  );
};

export default SearchbarTransferList;
