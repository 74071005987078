/* eslint-disable no-console */
import { createAsyncThunk } from "@reduxjs/toolkit";

import BookmarksService from "services/bookmarks";
import { setLoading, addBookmark, reload, removeBookmark } from ".";
import { IBookmark } from "./interfaces";

export const createBookmark = createAsyncThunk(
  "bookmarks/createBookmark",
  async (bookmark: IBookmark, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      await new BookmarksService().create(bookmark);
      dispatch(addBookmark(bookmark));
      return bookmark;
    } catch (err: any) {
      // if network error occured during the put request
      if (err.code === "ERR_NETWORK") {
        return rejectWithValue(err);
      }
      return rejectWithValue(err);
    } finally {
      dispatch(setLoading(false));
    }
  },
);

export const fetchBookmarks = createAsyncThunk(
  "bookmarks/fetchbookmarks",
  async (queryParams: string, { dispatch }) => {
    dispatch(setLoading(true));
    const result = await new BookmarksService().read(queryParams);
    dispatch(setLoading(false));
    return result;
  },
);

export const updateBookmark = createAsyncThunk(
  "bookmarks/updateBookmark",
  async (
    {
      old_view,
      old_name,
      bookmark,
    }: { old_view: string; old_name: string; bookmark: IBookmark },
    { dispatch },
  ) => {
    dispatch(setLoading(true));
    try {
      await new BookmarksService().put(old_view, old_name, bookmark);
      dispatch(reload(bookmark));
    } catch (error) {
      console.log(error);
    }
    dispatch(setLoading(false));
  },
);

export const deleteBookmark = createAsyncThunk(
  "bookmarks/deleteBookmark",
  async (bookmark: IBookmark, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      await new BookmarksService().delete(bookmark.name, bookmark.view);
      dispatch(removeBookmark(bookmark));
    } catch (error) {
      console.log(error);
    }
    dispatch(setLoading(false));
  },
);
