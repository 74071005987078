import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { ICatalogInventoryLocationTypesSliceState } from "./interfaces";
import { fetchCatalogInventoryLocationTypes } from "./thunks";
import { endpoint } from "constants/endpoint";
import config from "../../config";
import { httpErrorHandler } from "utils/error-response";

export const initialState: ICatalogInventoryLocationTypesSliceState = {
  loading: false,
  inventoryLocationTypes: [],
  count: 0,
  inventoryLocationTypesLoaded: false,
  filterParams: {
    page: 0,
    limit: 25,
    name: "",
  },
  error: {},
};

const catalogInventoryLocationTypesSlice = createSlice({
  name: "inventorylocationtypes",
  initialState,
  reducers: {
    setCatalogInventoryLocationTypesParams: (state, action) => {
      state.inventoryLocationTypesLoaded = false;
      state.filterParams = { ...state.filterParams, ...action.payload };
    },
    resetAllFilters: (state) => {
      state.inventoryLocationTypesLoaded = false;
      state.filterParams = {
        page: 0,
        limit: 25,
        name: "",
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCatalogInventoryLocationTypes.fulfilled,
        (state, action) => {
          state.inventoryLocationTypes = action.payload.results;
          state.inventoryLocationTypesLoaded = true;
          state.nextPageUrl = action.payload.next;
          state.previousPageUrl = action.payload.previous;
          state.count = action.payload.count;
        },
      )
      .addCase(
        fetchCatalogInventoryLocationTypes.rejected,
        (state, { error }) => {
          state.loading = false;
          state.error = {
            ...httpErrorHandler(error),
            url: config.api + endpoint.catalog_inventory_location_types,
          };
        },
      );
  },
});

export const selectCatalogInventoryLocationTypes = (
  state: RootState,
): ICatalogInventoryLocationTypesSliceState => state.inventoryLocationTypes;

export const { setCatalogInventoryLocationTypesParams, resetAllFilters } =
  catalogInventoryLocationTypesSlice.actions;

export default catalogInventoryLocationTypesSlice.reducer;
