const colors = {
  primaryMain: "#26479e",
  primaryContrast: "#2f2f2f",
  primaryLight: "#29D9A2",
  primaryDark: "#5B23EB",
  secondaryMain: "#737373",
  secondaryLight: "#eff3fe",
  secondaryDark: "#f2f2f2",
  errorMain: "#f31d10",
  backgroundDefault: "#efefef",
  backgroundPaper: "#ffffff",
  textPrimary: "#1c1b1f",
  textSecondary: "#737373",
  textDisabled: "#585858",
  actionHover: "#eff3fd",
  borderColor: "#e0e0e0",
  warningLight: "#ffb74d",
  warningMain: "#ffa726",
  warningDark: "#f57c00",
  backgroundLogin: "#e0e7e9",
};

export default colors;
