import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import {
  addInventoryTransactionAsync,
  fetchInventoryTransactionsAsync,
} from "./thunks";
import { fetchCatalogProducts } from "../catalog/thunks";
import { IInventoryTransactionsSliceState } from "./interfaces";

export const initialState: IInventoryTransactionsSliceState = {
  loading: false,
  inventoryTransactions: [],
  count: 0,
  nextPageUrl: "",
  previousPageUrl: "",
  inventoryTransactionsLoaded: false,
  error: {},
  selectedRow: {},
};
export const inventoryTransactionsSlice = createSlice({
  name: "inventoryTransactions",
  initialState,
  reducers: {
    setSelectedRow: (state, action: PayloadAction<any>) => {
      state.selectedRow = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventoryTransactionsAsync.fulfilled, (state, action) => {
        state.inventoryTransactions = action.payload.results;
        state.inventoryTransactionsLoaded = true;
        state.nextPageUrl = action.payload.next;
        state.previousPageUrl = action.payload.previous;
        state.count = action.payload.count;
      })
      .addCase(fetchCatalogProducts.fulfilled, (state, action) => {
        const pro: any = state.inventoryTransactions?.map((item) => {
          const foundProduct = action.payload.results.find(
            (elem: any) => elem.id === item.product,
          );
          if (foundProduct) {
            return {
              ...item,
              product: foundProduct.name + "[" + foundProduct.sku + "]",
            };
          }
          return item;
        });
        state.inventoryTransactions = pro;
      })
      .addCase(addInventoryTransactionAsync.fulfilled, (state, action) => {
        state.inventoryTransactions = [
          ...state.inventoryTransactions,
          action.payload,
        ];
      });
  },
});

export const selectInventoryTransactionsState = (
  state: RootState,
): IInventoryTransactionsSliceState => state.inventoryTransactions;
export const { setSelectedRow } = inventoryTransactionsSlice.actions;
export default inventoryTransactionsSlice.reducer;
