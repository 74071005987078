import React from "react";
import { ImageListItem } from "@mui/material";
import dashboard from "assets/images/dashboard.jpg";
import theme from "theme/theme";

const OnboardingStartImage = () => {
  return (
    <ImageListItem
      rows={1}
      sx={{
        height: "750px !important",
        padding: "4rem 0 4rem 4rem",
        background: theme.palette.bg.main,
      }}
    >
      <img
        style={{
          height: "100%",
          boxShadow: "0 0 3rem -1rem #000000d0",
          objectFit: "cover",
          objectPosition: "left",
        }}
        src={dashboard}
        alt="dashboard image"
        loading="lazy"
      />
    </ImageListItem>
  );
};

export default OnboardingStartImage;
