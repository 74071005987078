import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { IProcurementFacebookList } from "./interfaces";
import { fetchFacebookListAsync } from "./thunks";

export const initialState: IProcurementFacebookList = {
  facebookLoading: false,
  count: 0,
  next: "",
  previous: "",
  results: [],
  updateMessage: "",
  id: 0,
  taxonomy_id: 0,
  slug_sv: "",
  slug_en: "",
  params: {
    page: 0,
    limit: 50,
    search: "",
    filter: "True",
  },
};

export const ProcurementFacebookListSlice = createSlice({
  name: "ProcurementFacebookList",
  initialState,
  reducers: {
    setUpdateMessage: (state, { payload }) => {
      state.updateMessage = payload;
    },
    setParams: (state, action) => {
      state.params = { ...state.params, ...action.payload };
    },
    resetAllParams: (state) => {
      state.params = {
        page: 0,
        limit: 50,
        search: "",
        filter: "True",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFacebookListAsync.fulfilled, (state, action) => {
      state.facebookLoading = false;
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results;
    });

    builder.addCase(fetchFacebookListAsync.pending, (state) => {
      state.facebookLoading = true;
    });

    builder.addCase(fetchFacebookListAsync.rejected, (state) => {
      state.facebookLoading = false;
    });
  },
});

export const selectFacebookListState = (
  state: RootState,
): IProcurementFacebookList => state.ProcurementFacebookList;

export const { setUpdateMessage, setParams, resetAllParams } =
  ProcurementFacebookListSlice.actions;

export default ProcurementFacebookListSlice.reducer;
