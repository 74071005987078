/* eslint-disable no-console */
import { createAsyncThunk } from "@reduxjs/toolkit";
import inventoryTransactions from "services/inventory-transactions";
import {
  IAddInventoryTransaction,
  IInventoryTransaction,
  IInventoryTransactionsDTO,
} from "./interfaces";

export const fetchInventoryTransactionsAsync = createAsyncThunk(
  "inventoryTransactions/fetchInventoryTransactionsAsync",
  async (searchParams: URLSearchParams): Promise<IInventoryTransactionsDTO> => {
    return await inventoryTransactions
      .service()
      .fetchInventoryTransactions(searchParams);
  },
);
export const updateInventoryTransactionAsync = createAsyncThunk(
  "inventory/updateInventoryTransaction",
  async (updateInventoryTransactions: IInventoryTransaction) => {
    try {
      await inventoryTransactions
        .service()
        .updateInventoryTransaction(updateInventoryTransactions);
    } catch (error) {
      console.error(error);
    }
  },
);
export const addInventoryTransactionAsync = createAsyncThunk(
  "inventory/addInventoryTransaction",
  async ({ object }: { object: IAddInventoryTransaction }, thunkAPI) => {
    try {
      return await inventoryTransactions
        .service()
        .addInventoryTransaction(object);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
