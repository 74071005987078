import React from "react";
import { useSearchParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback } from "react";
import { IAppliedFilter } from "../../interfaces";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import theme from "theme/theme";

const SelectedFilter = ({
  label,
  value,
  extraValues,
  queryToRemove,
}: IAppliedFilter): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();

  const removeFilter = useCallback(() => {
    for (const query of queryToRemove.split("&")) {
      searchParams.delete(query);
    }
    setSearchParams(searchParams);
  }, [queryToRemove, searchParams, setSearchParams]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.dark,
        padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
        borderRadius: "4px",
        width: "fit-content",
      }}
    >
      {extraValues && (
        <Tooltip title={extraValues}>
          <Typography
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              fontSize: theme.typography.subtitle1.fontSize,
            }}
          >
            {label}: {value}
            <Button
              color="dark"
              sx={{
                padding: 0,
                minWidth: 0,
              }}
              onClick={removeFilter}
            >
              <CloseIcon />
            </Button>
          </Typography>
        </Tooltip>
      )}
      {!extraValues && (
        <Typography
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
            fontSize: theme.typography.subtitle1.fontSize,
          }}
        >
          {label}: {value}
          <Button
            color="dark"
            sx={{
              padding: 0,
              minWidth: 0,
            }}
            onClick={removeFilter}
          >
            <CloseIcon />
          </Button>
        </Typography>
      )}
    </Box>
  );
};

export default SelectedFilter;
