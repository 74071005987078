import React, { useCallback, useState } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import useAppDispatch from "hooks/useAppDispatch";
import {
  fetchProductDescription,
  updateProductDescription,
} from "store/catalog/thunks";
import useAppSelector from "hooks/useAppSelector";
import { selectCatalogState } from "store/catalog";
import { displaySnackbar } from "store/snackbar";
import MachineTranslation from "./bulk-edit/MachineTranslation";
import Language from "./bulk-edit/Langulage";
import { useSearchParams } from "react-router-dom";

export const textFieldProps: TextFieldProps = {
  fullWidth: true,
  margin: "normal",
  size: "small",
  variant: "outlined",
};

const EditComponent = (row: any) => {
  const { selectedRow } = useAppSelector(selectCatalogState);
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState(row.row.value);
  const searchParams = useSearchParams();

  const handleInputChange = useCallback((event: any) => {
    setInputValue(event.target.value);
  }, []);
  const onDescriptionChangeBlur = async () => {
    if (
      row.row.id === selectedRow.id &&
      !!selectedRow &&
      selectedRow[row.row.field] !== undefined &&
      inputValue !== undefined &&
      selectedRow[row.row.field] !== inputValue
    ) {
      await dispatch(
        updateProductDescription({
          ...selectedRow,
          [row.row.field]: inputValue,
        }),
      )
        .unwrap()
        .finally(() => {
          dispatch(
            displaySnackbar({
              message: "Product updated successfully",
              action: null,
              open: false,
            }),
          );
        });
      dispatch(fetchProductDescription(searchParams.toString()));
    }
  };
  return (
    <div>
      <TextField
        type="text"
        value={inputValue ?? ""}
        onChange={handleInputChange}
        onBlur={onDescriptionChangeBlur}
      />
    </div>
  );
};

const ColumnsForBulkEdit = (): GridColDef[] => {
  const bulkEditColumns: GridColDef[] = [
    {
      field: "productName",
      width: 150,
      minWidth: 90,
      maxWidth: 160,
      flex: 1,
      headerName: "Name",
      sortable: true,
      pinnable: true,
    },

    {
      field: "language",
      flex: 1,
      headerName: "Language",
      width: 70,
      minWidth: 20,
      maxWidth: 75,
      sortable: false,
      pinnable: true,
      headerAlign: "left",
      renderCell: (params: GridRenderCellParams) => {
        return <Language row={params} />;
      },
    },
    {
      field: "machine_translation",
      flex: 1,
      width: 80,
      minWidth: 30,
      maxWidth: 100,
      renderHeader: () => {
        return (
          <div style={{ lineHeight: "1.5em", fontWeight: 500 }}>
            <span>Machine</span>
            <br />
            <span>Translation</span>
          </div>
        );
      },
      sortable: false,
      pinnable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <MachineTranslation row={params} />;
      },
    },
    {
      field: "source",
      renderHeader: () => {
        return (
          <div style={{ lineHeight: "1.5em", fontWeight: 500 }}>
            <span>Original</span>
            <br />
            <span>Content</span>
          </div>
        );
      },
      sortable: false,
      pinnable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <MachineTranslation row={params} />;
      },
    },
    {
      field: "name",
      width: 100,
      headerName: "Product Name",
      minWidth: 70,
      maxWidth: 120,
      flex: 0.5,
      sortable: true,
      pinnable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <EditComponent row={params} />;
      },
    },
    {
      field: "meta_title",
      flex: 0.5,
      headerName: "Meta Title",
      sortable: true,
      pinnable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <EditComponent row={params} />;
      },
    },
    {
      field: "meta_keywords",
      flex: 0.5,
      headerName: "Meta Keywords",
      sortable: true,
      pinnable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <EditComponent row={params} />;
      },
    },
    {
      field: "meta_description",
      flex: 0.5,
      headerName: "Meta Description",
      sortable: true,
      pinnable: true,
      renderCell: (params: GridRenderCellParams) => {
        return <EditComponent row={params} />;
      },
    },
    {
      field: "content",
      width: 200,
      headerName: "Full Description",
      minWidth: 150,
      maxWidth: 200,
      flex: 0.5,
      sortable: false,
      pinnable: true,
    },
    {
      field: "excerpt",
      width: 100,
      headerName: "Short Description",
      minWidth: 70,
      maxWidth: 120,
      flex: 0.5,
      sortable: false,
      pinnable: true,
    },
  ];

  return bulkEditColumns;
};

export default ColumnsForBulkEdit;
