import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import config from "../../../../config";
import StockOptions from "./StockOptions";
import ProcurementOptions from "./ProcurementOptions";
import Modal from "components/ModalComponent";
import { IInventory } from "store/inventories/interfaces";
import Backorders from "./Backorders";
import { convertOrderText } from "../utils/convertOrderText";
import {
  TableRow,
  TableCell,
  Collapse,
  Table,
  TableHead,
  TableBody,
  Typography,
  Theme,
  styled,
  Button,
} from "@mui/material";
import theme from "theme/theme";

export const StyledLink = styled(Link)`
  color: ${theme.palette.primary.main};
  text-decoration: none;
`;

function Row(props: { row: IOrderItem }): JSX.Element {
  const { row } = props;
  const [selectedRow, setSelectedRow] = useState<IHistoryItem>();
  const [modalOpen, setModalOpen] = useState(false);
  const handleClickOpen = (row: IHistoryItem) => {
    setModalOpen(true);
    setSelectedRow(row);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <TableRow
        sx={{
          textTransform: "capitalize",
          backgroundColor: (theme: Theme) => theme.palette.primary[50],
          lineHeight: "0.85",
          fontSize: "12px",
        }}
      >
        <TableCell
          sx={{
            color: "primary.main",
            fontWeight: "bold",
            lineHeight: "0.85",
            fontSize: "12px",
          }}
        >
          <StyledLink to={`/customers/orders/edit?order=${row.order}`}>
            {row.order}
          </StyledLink>
        </TableCell>
        <TableCell sx={{ lineHeight: "0.85", fontSize: "12px" }}>
          {convertOrderText(row.status)}
        </TableCell>
        <TableCell sx={{ lineHeight: "0.85", fontSize: "12px" }}>
          {row.shippingMethod ? row.shippingMethod : "Unknown"}
          {row.orderNotes && (
            <Typography
              sx={{ maxWidth: "250px" }}
              color="warning.main"
              py={1}
              fontSize={12}
              textTransform="lowercase"
            >
              Order Notes: {row.orderNotes}
            </Typography>
          )}
        </TableCell>
        <TableCell sx={{ lineHeight: "0.85", fontSize: "12px" }}>
          {convertOrderText(row.availability)}
        </TableCell>
        <TableCell sx={{ lineHeight: "0.85", fontSize: "12px" }}>
          {convertOrderText(row.backorder)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ p: 0 }} colSpan={6}>
          <Collapse in={row.expandAll} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow
                  id="tableHead"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  <TableCell>qty</TableCell>
                  <TableCell>Product details</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Stock availability</TableCell>
                  <TableCell>Pick/Unpick</TableCell>
                  <TableCell>Stock-Taking</TableCell>
                  <TableCell>Procurement sources</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.history?.map((historyRow) => (
                  <TableRow
                    key={historyRow.id}
                    hover
                    style={{ verticalAlign: "top" }}
                  >
                    <TableCell width="6%" sx={{ mt: 3 }}>
                      {historyRow.quantity.qty_picked} /{" "}
                      {historyRow.quantity.qty_ordered}
                    </TableCell>
                    <TableCell width="25%">
                      <>
                        <StyledLink
                          target="_blank"
                          //I think this link is static and need to be changed
                          to={`${config.clientUrl}/admin/catalog/product/${historyRow.product.product_item_id}/change/`}
                        >{`[${historyRow.product.prod_sku}] ${historyRow.product.prod_name}`}</StyledLink>
                        {historyRow.proxyParent.id ? (
                          <Typography color="warning.main" py={1} fontSize={12}>
                            Proxy product: [{historyRow.proxyParent.sku}]{" "}
                            {historyRow.proxyParent.name}
                          </Typography>
                        ) : null}
                      </>
                    </TableCell>
                    <TableCell width="10%">{historyRow.price}</TableCell>

                    <StockOptions id={row.id} historyRow={historyRow} />

                    <TableCell width="20%">
                      <>
                        <Backorders historyRow={historyRow} />
                        <Button
                          onClick={() => handleClickOpen(historyRow)}
                          variant="outlined"
                          size="small"
                          sx={{ fontSize: 13, lineHeight: "1.35" }}
                        >
                          More options...
                        </Button>
                      </>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        title="Procurement Sources"
        isOpen={modalOpen}
        handleClose={handleClose}
      >
        <ProcurementOptions historyRow={selectedRow!} />
      </Modal>
    </>
  );
}

export default Row;

//Code Refactoring needed
//These interfaces need to be kept in separate file
export interface IHistoryItem {
  id: number;
  product: {
    prod_sku: string;
    prod_name: string;
    product_item_id: number;
  };
  quantity: {
    qty_ordered: number;
    qty_picked: number;
    qty_to_be_picked: number;
    qty_to_be_backordered: number;
  };
  price: string;
  stock: IInventory[];
  procurementProductsOptions: number[];
  inventoryTransaction: number[];
  procurementOrderItem: null | number;
  proxyParent: { id: number; sku: string; name: string; unit_factor: string };
}

export interface IOrderItem {
  id: number;
  order: string;
  availability: string;
  backorder: string;
  shippingMethod: string;
  status: string;
  history: IHistoryItem[];
  expandAll: boolean;
  orderNotes: string;
}
