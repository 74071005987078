import React from "react";
import FilterDateRangeStacked from "components/filter-products/FilterDateRangeStacked";
import FilterSelectBinary from "components/filter-products/FilterSelectBinary";
import FilterSelectMultiple from "components/filter-products/FilterSelectMultiple";
import { Convertinventorylocationstext } from "pages/main-page/catalog-inventory-locations/utils/ConvertInventoryLocationsText";
import { useMemo } from "react";

const AvailableFilters = (): JSX.Element => {
  const statusOptions: [string, string][] = useMemo(
    () =>
      ["false", "all", "true"]?.map((option) => [
        Convertinventorylocationstext(option),
        option,
      ]),
    [],
  );
  return (
    <>
      <FilterSelectMultiple
        label="By obsolete location"
        param={"obsolete_location"}
        options={statusOptions}
      />
      <FilterDateRangeStacked
        label="By last date of stocktaking for location"
        param="stocktaking_perfomed_at_"
      />
      <FilterSelectBinary
        label="Has date of stocktaking for location"
        param="stocktaking_perfomed_at__isnull"
        inverted={false}
      />
    </>
  );
};

export default AvailableFilters;
