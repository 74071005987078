import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import {
  IAddedOrderItemResponse,
  IAddedOrderResponse,
  IProcurementOrdersResponseDTO,
} from "store/procurement-orders/interfaces";
import { endpoint } from "constants/endpoint";

export interface FetchProcurementOrdersParams {
  order_delivered__isnull?: boolean;
  order_made__isnull?: boolean;
  search?: string;
}

export interface IProcurementOrdersService {
  fetchProcurementOrders(
    params: FetchProcurementOrdersParams | URLSearchParams,
  ): Promise<IProcurementOrdersResponseDTO>;
  addProcurementOrder(
    supplier: number,
    internal_order_number: number,
  ): Promise<IAddedOrderResponse>;
  addProcurementOrderItem(
    order: number,
    quantity: number,
    product: number,
  ): Promise<IAddedOrderItemResponse>;
  editProcurementOrderItem(
    itemId: number,
    quantity: number,
  ): Promise<IAddedOrderItemResponse>;
}

export default new Mockable<IProcurementOrdersService>({
  async fetchProcurementOrders(params): Promise<IProcurementOrdersResponseDTO> {
    try {
      const res = await axios.get(
        `${config.api}${endpoint.procurement_orders}`,
        {
          params: params,
          headers: commonHeaders(),
        },
      );

      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error("Unexpected response format in fetchProcurementOrders"),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
  async addProcurementOrder(
    supplier,
    internal_order_number,
  ): Promise<IAddedOrderResponse> {
    try {
      const res = await axios(`${config.api}${endpoint.procurement_orders}`, {
        method: "POST",
        headers: commonHeaders(),
        data: JSON.stringify({ supplier, internal_order_number }),
      });

      if (res.status === 201) {
        return res.data;
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async addProcurementOrder <<",
          ),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
  async addProcurementOrderItem(
    order,
    quantity,
    product,
  ): Promise<IAddedOrderItemResponse> {
    try {
      const res = await axios(`${config.api}${endpoint.procurement_orders}`, {
        method: "POST",
        headers: commonHeaders(),
        data: JSON.stringify({ order, quantity, product }),
      });

      if (res.status === 201) {
        return res.data;
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async addProcurementOrderItem <<",
          ),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
  async editProcurementOrderItem(
    itemId,
    quantity,
  ): Promise<IAddedOrderItemResponse> {
    try {
      const res = await axios(
        `${config.api}${endpoint.procurement_orders}${itemId}/`,
        {
          method: "PUT",
          headers: commonHeaders(),
          data: JSON.stringify({ quantity }),
        },
      );

      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async editProcurementOrderItem <<",
          ),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
});
