import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  Divider,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import useAppDispatch from "hooks/useAppDispatch";

import { editCatalogInventoryLocationTypeAsync } from "store/catalog-inventorylocationtype/thunks";

interface EditModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleEditConfirm: () => void;
  selectedRows: any[];
  typeName: string;
  linebreak: boolean;
}

const EditInventoryLocationType = ({
  isOpen,
  handleClose,
  selectedRows,
  typeName,
  linebreak,
}: EditModalProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [editedTypeName, setEditedTypeName] = useState(typeName);
  const [editedLinebreak, setEditedLinebreak] = useState(linebreak);

  useEffect(() => {
    if (isOpen) {
      setEditedTypeName(typeName);
      setEditedLinebreak(linebreak);
    }
  }, [isOpen, typeName, linebreak]);

  const handleSave = () => {
    dispatch(
      editCatalogInventoryLocationTypeAsync({
        id: selectedRows[0].id,
        params: {
          type: editedTypeName,
          label_linebreak: editedLinebreak,
        },
      }),
    );
    handleClose();
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedLinebreak(e.target.checked);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              marginBottom: 3,
            }}
          >
            Edit Inventory Location Type
          </Typography>
        </Box>
        <Box mt={2} justifyContent="center" alignItems="center">
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "5px",
            }}
          >
            <Box>
              Name of Inventory Location Type:
              <TextField
                variant="outlined"
                size="small"
                value={editedTypeName}
                onChange={(e) => setEditedTypeName(e.target.value ?? "")}
                sx={{
                  padding: "5px",
                }}
              />
              <FormHelperText>
                E.g. City, Warehouse, Room, Shelf...
              </FormHelperText>
            </Box>
            <br />
            <Divider />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "5px",
              }}
            >
              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={editedLinebreak}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Linebreak"
                  />
                </FormGroup>
                <FormHelperText>
                  If set to True, a line break will be inserted after this
                  entity in the slug.
                </FormHelperText>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mt={2} justifyContent="center" alignItems="center">
          <Divider />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditInventoryLocationType;
