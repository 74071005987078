import { GridColDef } from "@mui/x-data-grid";

export const supplierFeedsTableColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    width: 50,
    sortable: false,
  },
  {
    field: "supplier_name",
    headerName: "SUPPLIER",
    type: "string",
    width: 150,
  },
  {
    field: "feed_date",
    headerName: "FEED CREATED",
    type: "dateTime",
    valueFormatter: (value: Date) => {
      if (!value) return "";
      return new Date(value).toLocaleDateString("sv", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
    },
    minWidth: 200,
  },
  {
    field: "feed_type",
    headerName: "FEED TYPE",
    type: "string",
    minWidth: 150,
  },
  {
    field: "duration_feed",
    headerName: "FEED DURATION",
    type: "string",
    valueFormatter: (value: string) => (value ? value.slice(0, 8) : ""),
    minWidth: 200,
    sortable: false,
  },
  {
    field: "duration_total",
    headerName: "TOTAL DURATION",
    type: "string",
    valueFormatter: (value: string) => (value ? value.slice(0, 8) : ""),
    width: 200,
    sortable: false,
  },
  {
    field: "user_warning",
    headerName: "USER WARNING",
    type: "string",
    minWidth: 200,
    sortable: false,
  },
  {
    field: "admin_warning",
    headerName: "ADMIN WARNING",
    type: "string",
    width: 200,
    sortable: false,
  },
  {
    field: "statistics_no_request",
    headerName: "NO OF REQUESTS",
    type: "number",
    minWidth: 150,
    sortable: false,
  },
  {
    field: "statistics_no_category_urls",
    headerName: "NO OF UNIQUE CATEGORY",
    type: "number",
    width: 200,
    sortable: false,
  },
  {
    field: "statistics_no_product_urls",
    headerName: "NO OF PRODUCT URLS",
    type: "number",
    width: 200,
    sortable: false,
  },
  {
    field: "statistics_no_product_skus",
    headerName: "NO OF PRODUCT SKUS",
    type: "number",
    minWidth: 200,
    sortable: false,
  },
  {
    field: "statistics_no_errors",
    headerName: "NO OF ERRORS FOUND",
    type: "number",
    width: 200,
    sortable: false,
  },
  {
    field: "statistics_no_warnings",
    headerName: "NO OF WARNINGS FOUND",
    type: "number",
    width: 200,
    sortable: false,
  },
  {
    field: "warning_level_days_between_feed_updates",
    headerName: "DAYS BEFORE WARNING",
    type: "number",
    minWidth: 200,
    sortable: false,
  },
];
