import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { INotificationItemDTO } from "./interfaces";
import {
  fetchNotificationItemAsync,
  fetchNotificationItemTopBarAsync,
  fetchNotificationCount,
} from "./thunks";

export const initialState: INotificationItemDTO = {
  loading: false,
  results: [],
  count: 0,
  notificationsLoaded: false,
  TopBarResults: [],
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationItemAsync.fulfilled, (state, action) => {
      state.results = action.payload.results;
      state.notificationsLoaded = true;
    });

    builder
      .addCase(fetchNotificationItemTopBarAsync.fulfilled, (state, action) => {
        state.TopBarResults = action.payload.results;
      })

      .addCase(fetchNotificationItemAsync.rejected, (state) => {
        state.loading = false;
      });

    builder.addCase(fetchNotificationCount.fulfilled, (state, action) => {
      state.count = action.payload.count;
    });
  },
});

export const selectNotifications = (state: RootState): INotificationItemDTO =>
  state.notifications;

export default notificationsSlice.reducer;
