import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import ImageLink from "components/common-components/ImageLink";
import ImageThumbnail from "components/common-components/ImageThumbnail";

const ColumnsForDataGrid = (): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: "thumbnail",
      flex: 0.5,
      headerName: "Thumbnail",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <ImageThumbnail
            src={params.row.source_url}
            width={30}
            height={30}
            alt="product image"
            loading="lazy"
          />
        );
      },
    },
    {
      field: "title",
      flex: 1,
      headerName: "Title",
    },
    {
      field: "product",
      flex: 0.5,
      headerName: "Product",
      sortable: false,
    },
    {
      field: "image",
      flex: 1,
      headerName: "Source URL",
      renderCell: (params: GridRenderCellParams) => {
        const image = params.row;
        const updatedURLLink = image?.url;

        return <ImageLink href={updatedURLLink} />;
      },
    },
  ];

  return columns;
};

export default ColumnsForDataGrid;
