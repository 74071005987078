import { createAsyncThunk } from "@reduxjs/toolkit";
import backorder, { IAddBackorderParams } from "services/backorder";

export const fetchBackOrderAsync = createAsyncThunk(
  "backorders/fetchBackOrderAsync",
  async (_, thunkAPI) => {
    const res = await backorder.service().fetchCustomerBackOrder();
    return res;
  },
);
export const addBackOrderAsync = createAsyncThunk(
  "backorders/addBackOrderAsync",
  async (params: IAddBackorderParams, thunkAPI) => {
    const res = await backorder.service().addCustomerBackOrder(params);
    return res;
  },
);
export const removeBackOrderAsync = createAsyncThunk(
  "backorders/removeBackOrderAsync",
  async (id: number, thunkAPI) => {
    const res = await backorder.service().removeCustomerBackOrder(id);
    return res;
  },
);
