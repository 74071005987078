import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useAppSelector from "hooks/useAppSelector";
import { selectInventoriesState } from "store/inventories";
import { selectProcurementSourcesState } from "store/procurement-sources";
import { isEmpty } from "lodash-es";

export default function ProcurementDetails({
  id,
}: {
  id: number;
}): JSX.Element {
  const { inventoryData } = useAppSelector(selectInventoriesState);
  const { procurementSources } = useAppSelector(selectProcurementSourcesState);
  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: 170, overflowY: "auto" }}
    >
      <Table aria-label="procurement details table">
        <TableHead>
          <TableRow
            sx={{
              "& th": {
                fontSize: 12,
              },
            }}
          >
            <TableCell sx={{ fontSize: 12, p: 0.4 }}>Supplier</TableCell>
            <TableCell sx={{ fontSize: 12, p: 0.4 }}>Product</TableCell>
            <TableCell sx={{ fontSize: 12, p: 0.4 }}>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(inventoryData[id]) &&
            !isEmpty(procurementSources) &&
            inventoryData[id].procurement_products?.map((prod) => (
              <TableRow
                key={prod}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={{ fontSize: 12, p: 0.4 }}>
                  {procurementSources[prod] &&
                    procurementSources[prod].supplier_name}
                </TableCell>
                <TableCell sx={{ fontSize: 12, p: 0.4 }}>
                  {procurementSources[prod] &&
                    `[${procurementSources[prod].sku}]
                   ${procurementSources[prod].name}`}
                </TableCell>
                <TableCell sx={{ fontSize: 12, p: 0.4 }}>
                  {procurementSources[prod] &&
                    Math.round(
                      procurementSources[prod] &&
                        +procurementSources[prod].cost_sek,
                    )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
