import React from "react";
import Paper from "@mui/material/Paper";
import { SUB_ROUTE_CATALOG_PRODUCTS } from "constants/urls";
import useAppSelector from "hooks/useAppSelector";
import { useMemo } from "react";
import {
  selectEditedProductsCount,
  selectLowInStockProductsCount,
  selectMappedProductsCount,
  selectUneditedProductsCount,
} from "store/catalog";
import theme from "theme/theme";
import TopRibbonItem from "../TopRibbonItem";

const InfoBar = (): JSX.Element => {
  const editedProducts = useAppSelector(selectEditedProductsCount);
  const uneditedProducts = useAppSelector(selectUneditedProductsCount);
  const mappedProducts = useAppSelector(selectMappedProductsCount);
  const lowInStockProducts = useAppSelector(selectLowInStockProductsCount);

  const totalProductCount = useMemo(() => {
    return editedProducts + uneditedProducts;
  }, [editedProducts, uneditedProducts]);

  return (
    <Paper
      elevation={0}
      sx={{
        padding: theme.spacing(1),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <TopRibbonItem
        totalCount={totalProductCount}
        caption="My product catalog"
      />
      <TopRibbonItem
        totalCount={mappedProducts}
        caption="Products on your website"
        pathnameUrl={`${SUB_ROUTE_CATALOG_PRODUCTS}`}
        serachPhrase="?is_synced_to_ecommerce=true"
      />
      <TopRibbonItem
        totalCount={lowInStockProducts}
        caption="Products low in stock"
        pathnameUrl={`${SUB_ROUTE_CATALOG_PRODUCTS}`}
      />
    </Paper>
  );
};

export default InfoBar;
