import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import { IInventoryLocationResponseDTO } from "store/inventory-locations/interfaces";
import { endpoint } from "constants/endpoint";

export interface IInventoryLocationsService {
  fetchInventoryLocations(params: {
    limit: string;
    obsolete: boolean;
  }): Promise<IInventoryLocationResponseDTO>;
}

export default new Mockable<IInventoryLocationsService>({
  async fetchInventoryLocations(
    params,
  ): Promise<IInventoryLocationResponseDTO> {
    const res = await axios(
      `${config.api}${endpoint.catalog_inventory_locations}?limit=${params.limit}&obsolete=${params.obsolete}`,
      {
        headers: commonHeaders(),
      },
    );
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Couldn't get inventory locations data");
  },
});
