import React, { useEffect, useState } from "react";
import { GridRowId } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { SUB_ROUTE_EDIT_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES } from "constants/urls";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import theme from "theme/theme";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

interface IProps {
  deleteCategories(): void;
  selectionModel: GridRowId[];
}

const Toolbar = ({ deleteCategories, selectionModel }: IProps): JSX.Element => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const isSingleRow = selectionModel.length !== 1;
    if (isSingleRow) {
      setIsDisabled(true);
    }
  }, [selectionModel.length]);

  return (
    <Box
      sx={{ display: "flex", gap: 0.2, height: "100%", alignItems: "center" }}
    >
      <ToolInfoTooltip title="Edit (Bulk Action)">
        <ToolIconContainer
          disabled={isDisabled}
          onClick={() =>
            navigate(
              `/${SUB_ROUTE_EDIT_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES}?category=${selectionModel[0]}`,
            )
          }
        >
          <EditIcon
            fontSize="large"
            sx={{
              color: !isDisabled
                ? theme.palette.warning.main
                : theme.palette.text.disabled,
              stroke: !isDisabled
                ? theme.palette.warning.main
                : theme.palette.text.disabled,
              strokeWidth: 2,
            }}
          />
        </ToolIconContainer>
      </ToolInfoTooltip>

      <Divider orientation="vertical" variant="middle" flexItem />

      <ToolInfoTooltip title="Delete (Bulk Action)">
        <ToolIconContainer disabled={isDisabled} onClick={openModal}>
          <DeleteForeverIcon
            fontSize="large"
            sx={{
              color: !isDisabled
                ? theme.palette.error.main
                : theme.palette.text.disabled,
            }}
          />
        </ToolIconContainer>
      </ToolInfoTooltip>

      <Dialog open={open} onClose={closeModal}>
        <DialogTitle>Confirm delete products</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selected category will no longer be available.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteCategories();
              closeModal();
            }}
            variant="contained"
            color={"error"}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Toolbar;
