import React from "react";
import { FormHelperText, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export default function CustomTextField(props: any) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{ ...props.rules }}
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            id={props.id}
            {...field}
            fullWidth
            variant={props.variant ?? "outlined"}
            color={props.color ?? "secondary"}
            label={props.label}
            error={!!error}
            // value={props.value ? props.value : ""}
            type={props.type ?? "text"}
            rows={props.rows ?? 1}
            InputProps={{ ...props.InputProps }}
            helperText={props.helperText}
          />
          <FormHelperText error>{error ? error.message : null}</FormHelperText>
        </>
      )}
    />
  );
}
