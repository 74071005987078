import React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

interface ISimpleSnackbarProps {
  open: boolean;
  toggleSnackbar?: (flag: boolean) => void;
  message: string;
  vertical?: "top" | "bottom";
  horizontal?: "left" | "center" | "right";
  snackbarAction: JSX.Element | null;
}

const SimpleSnackbar = (props: ISimpleSnackbarProps): JSX.Element => {
  const theme = useTheme();

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    props.toggleSnackbar(false);
  };

  const action = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        borderLeft: `1px solid ${theme.palette.subtle.main}`,
        height: theme.spacing(2.5),
        paddingLeft: theme.spacing(1),
      }}
    >
      {props.snackbarAction}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );

  const vertical = props.vertical || "bottom";
  const horizontal = props.horizontal || "left";

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={props.open}
      autoHideDuration={4000}
      onClose={handleClose}
      message={props.message}
      action={action}
    />
  );
};

export default SimpleSnackbar;
