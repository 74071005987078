import React from "react";
import { GridColDef } from "@mui/x-data-grid-pro";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
  },
  {
    field: "supplier",
    headerName: "Supplier",
  },
  {
    field: "url",
    headerName: "URL (Ecombooster download area)",
    editable: false,
  },
  {
    field: "source_url",
    headerName: "Source URL",
    editable: false,
  },
  {
    field: "priority",
    headerName: "Priority",
    editable: false,
    sortable: false,
  },
  {
    field: "title",
    headerName: "Title",
  },
  {
    field: "removed_from_source",
    headerName: "Remove from source",
    editable: false,
    sortable: false,
  },
  {
    field: "account",
    headerName: "Account",
  },
  {
    field: "company",
    headerName: "Company",
  },

  {
    field: "product",
    headerName: "Product",
  },
];

export default columns;
