import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import {
  IFileUrlsInsertResult,
  IFileUrlsList,
  IItemsFileUrls,
} from "store/catalog-file-urls/interfaces";
import { Mockable } from "../index";
import { endpoint } from "constants/endpoint";

export interface IFileUrlsListService {
  deleteFileUrls(ids: number[]): Promise<number>;
  fetchFileUrlsList(params: URLSearchParams): Promise<IFileUrlsList>;
  AddFileUrls(params: IItemsFileUrls): Promise<IFileUrlsInsertResult>;
  UpdateFileUrls(id: number, params: IItemsFileUrls): Promise<IFileUrlsList>;
}

export default new Mockable<IFileUrlsListService>({
  async fetchFileUrlsList(params): Promise<IFileUrlsList> {
    try {
      const res = await axios(`${config.api}${endpoint.file_urls}`, {
        params: params,
        headers: commonHeaders(),
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject("Something went wrong with the fetch");
      }
    } catch {
      return Promise.reject("Something went wrong with the fetch");
    }
  },

  async deleteFileUrls(ids: number[]): Promise<any> {
    let result = 0;

    ids.forEach(async (item) => {
      const res = await axios(`${config.api}${endpoint.file_urls}${item}/`, {
        method: "DELETE",
        headers: commonHeaders(),
      });
      if (res.status === 204) {
        result += 1;
      }
    });
    return result;
  },

  async AddFileUrls(params: IItemsFileUrls): Promise<IFileUrlsInsertResult> {
    let result: IFileUrlsInsertResult = {
      message: "Something went wrong",
      statusCode: 400,
    };
    await axios(`${config.api}${endpoint.file_urls}`, {
      method: "POST",
      headers: commonHeaders(),
      data: JSON.stringify(params),
    })
      .then((response) => {
        if (response.status === 201) {
          result = {
            statusCode: response.status,
            message: "A new resource was created successfully.",
          };
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          const jsonString = JSON.stringify(error.response.data, null, 4);
          const jsonStringWithNewlines = jsonString
            ?.replace(/\{/g, "")
            .replace(/\}/g, "")
            .replace(/\[/g, "")
            .replace(/\]/g, "")
            .replace(/\"/g, "")
            .replace(/\"/g, "")
            .trim();
          result = {
            message: jsonStringWithNewlines,
            statusCode: error.response.status,
          };
        }
      });

    return result;
  },

  async UpdateFileUrls(
    id: number,
    params: IItemsFileUrls,
  ): Promise<IFileUrlsList> {
    const res = await axios(`${config.api}${endpoint.file_urls}${id}/`, {
      method: "PUT",
      headers: commonHeaders(),
      data: JSON.stringify(params),
    });
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },
});
