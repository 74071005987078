import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import theme from "theme/theme";

interface Props {
  isOpen: boolean;
  content: string;
  affirmativeButtonText?: string;
  negativeButtonText?: string;
  onApprove?: () => void;
  onCancel?: () => void;
}

const CommonConfirmDialog = ({
  isOpen,
  content,
  affirmativeButtonText,
  negativeButtonText,
  onApprove,
  onCancel,
}: Props) => {
  return (
    //The style we need for the component in all of the implementations are the same. That's why we hard-coded them in this source for now
    <Dialog open={isOpen} sx={{ borderRadius: theme.shape.borderRadius }}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            fontSize: theme.typography.body2.fontSize,
          }}
          onClick={onCancel}
        >
          {negativeButtonText}
        </Button>

        <Button
          sx={{
            fontSize: theme.typography.body2.fontSize,
            color: theme.palette.error.main,
          }}
          onClick={onApprove}
        >
          {affirmativeButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommonConfirmDialog;
