import React from "react";
import OnboardingDone from "./OnboardingDone";
import logo from "assets/images/EcomboosterLogo.png";
import useAppSelector from "hooks/useAppSelector";
import { selectCurrentStep, selectUserRole } from "store/onboarding";
import SupplierAccountDetails from "./SupplierAccountDetails";
import SupplierOnboardingDone from "./SupplierOnboardingDone";
import { Box, CardMedia } from "@mui/material";
import SetUserRole from "./SetUserRole";
import AccountDetails from "./AccountDetails";
import PageNotFoundComponent from "components/PageNotFound";

const Register = () => {
  const currentStep = useAppSelector(selectCurrentStep);
  const userRole = useAppSelector(selectUserRole);

  const activeComponent = () => {
    switch (currentStep) {
      case 0:
        return <SetUserRole />;
      case 1:
        if (userRole === "supplier") {
          return <SupplierAccountDetails />;
        }
        return <AccountDetails />;
      case 2:
        if (userRole === "supplier") {
          return <SupplierOnboardingDone />;
        }
        return <OnboardingDone />;

      default:
        return <PageNotFoundComponent />;
    }
  };

  return (
    <>
      <CardMedia sx={{ marginBottom: "1rem" }}>
        <img width="170px" src={logo} />
      </CardMedia>
      <Box sx={{ flex: 1 }}>{activeComponent()}</Box>
    </>
  );
};

export default Register;
