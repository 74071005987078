import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { ISupplier, ISupplierSliceState } from "./interfaces";

import { fetchSuppliers } from "./thunks";

const initialState: ISupplierSliceState = {
  suppliers: [],
};

const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSuppliers.fulfilled, (state, action) => {
      state.suppliers = action.payload;
    });
  },
});

export const selectSuppliers = (state: RootState): ISupplier[] =>
  state.suppliers.suppliers;

export default suppliersSlice.reducer;
