import { createAsyncThunk } from "@reduxjs/toolkit";
import catalogMarkup from "services/catalog-markup";
import { RootState } from "../index";
import { ICatalogMarkupsFilterParams, ICatalogMarkupsDTO } from "./interfaces";

function getAxiosParams(catalogMarkupsParams: ICatalogMarkupsFilterParams) {
  const params = new URLSearchParams();
  params.append(
    "offset",
    (catalogMarkupsParams.page * catalogMarkupsParams.limit).toString(),
  );
  params.append("limit", catalogMarkupsParams.limit.toString());
  params.append("search", catalogMarkupsParams.name.toString());

  return params;
}
export const fetchCatalogMarkups = createAsyncThunk(
  "catalog/fetchCatalogMarkup",
  async (_, thunkAPI): Promise<ICatalogMarkupsDTO> => {
    const state = thunkAPI.getState() as RootState;
    const params = getAxiosParams(state.markups.filterParams);

    return await catalogMarkup.service().fetchCatalogMarkups(params);
  },
);
