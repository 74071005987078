import React from "react";
import Searchbar from "components/filter-products/Searchbar";
import CompaniesList from "./parts/CompaniesList";
import useAppDispatch from "hooks/useAppDispatch";
import { fetchCompaniesListAsync } from "store/procurements-companies/thunks";

export default function Companies(): JSX.Element {
  const dispatch = useAppDispatch();

  const searchHandler = (value: string) => {
    dispatch(fetchCompaniesListAsync({ search: value }));
  };

  return (
    <>
      <Searchbar placeholder="Search in companies" onChange={searchHandler} />
      <CompaniesList />
    </>
  );
}
