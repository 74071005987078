import React from "react";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  Stack,
} from "@mui/material";
import { uploadImageAsync } from "store/image-libraries/thunks";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { displaySnackbar } from "store/snackbar";
import { selectImagesState } from "store/image-libraries";
import { FileUploader } from "react-drag-drop-files";

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
}
const fileTypes = ["JPG", "PNG", "GIF", "JPEG", "SVG"];
const MultiFileUploadComponent = ({
  isOpen,
  handleClose,
}: ModalProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { imageUploadSuccess, imageUploadFailed } =
    useAppSelector(selectImagesState);
  const [imageList, setFile] = useState<any>(null);

  const handleUpload = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (typeof imageList === "undefined") return;
    Object.keys(imageList)?.forEach((image: any) => {
      const formData = new FormData();
      formData.append("image", imageList[image]);
      dispatch(uploadImageAsync(formData))
        .unwrap()
        .finally(() => {
          handleClose();
          dispatch(
            displaySnackbar({
              message: `File uploaded successfully`,
              action: null,
              open: false,
            }),
          );
        });
    });
  };
  const handleChange = (file: File) => {
    setFile(file);
  };
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Typography fontWeight="bold">File Upload</Typography>
        <Typography>Please select the file to upload</Typography>
        <Stack mt={2} justifyContent="center" alignItems="center">
          <FileUploader
            multiple={true}
            handleChange={handleChange}
            name="file"
            types={fileTypes}
          />
          <p>
            {imageList
              ? `Total file: ${imageList.length}`
              : "No files uploaded yet"}
          </p>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleUpload} color="primary">
          Upload
        </Button>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultiFileUploadComponent;
