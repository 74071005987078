import React from "react";
import TextField from "@mui/material/TextField";
import { textFieldProps, textFieldPropsFullWidth } from "../shared";
import {
  selectAccountDetails,
  setAccountDetails,
  selectCompanyInfo,
  setCompanyInfo,
} from "store/onboarding";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import { useCallback, useState } from "react";
import DefaultFooter from "./Footer/DefaultFooter";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";

interface IAccountDetailsProp {
  first_name?: string;
  last_name?: string;
  email?: string;
  phonenumber?: string;
  password?: string;
  password2?: string;
}

interface IAccountDetailsErrorProp {
  first_name: boolean;
  last_name: boolean;
  email: boolean;
  phonenumber: boolean;
  password: boolean;
  password2: boolean;
  agreed_to_terms: boolean;
}

interface ICompanyInfoProp {
  agreed_to_terms?: boolean;
}

const AccountDetails = (): JSX.Element => {
  const accountDetails = useAppSelector(selectAccountDetails);
  const companyInfo = useAppSelector(selectCompanyInfo);

  const dispatch = useAppDispatch();
  const [error, setError] = useState<IAccountDetailsErrorProp>({
    first_name: false,
    last_name: false,
    email: false,
    phonenumber: false,
    password: false,
    password2: false,
    agreed_to_terms: false,
  });

  const changeAccountDetails = useCallback(
    (details: IAccountDetailsProp) => {
      dispatch(setAccountDetails({ ...accountDetails, ...details }));
      setError({ ...error, [Object.keys(details)[0]]: false });
    },
    [accountDetails, dispatch, error],
  );

  const changeCompanyInfo = useCallback(
    (details: ICompanyInfoProp) => {
      dispatch(setCompanyInfo({ ...companyInfo, ...details }));
      setError({ ...error, [Object.keys(details)[0]]: false });
    },
    [companyInfo, dispatch, error],
  );

  const validate = useCallback((): boolean => {
    const validated: IAccountDetailsErrorProp = {
      first_name: !accountDetails.first_name.trim(),
      last_name: !accountDetails.last_name.trim(),
      email:
        accountDetails.email.match(
          /^[\w.]+\+?[\w.]*@[\w-]{2,}\.\w{2,}(\.\w{2,})?$/,
        ) === null,
      phonenumber: accountDetails.phonenumber?.match(/^[\d\s-]+$/) === null,
      password:
        accountDetails.password.match(/(?!^(\d)+$)^[^\s-]{8,}$/) === null,
      password2: accountDetails.password2 !== accountDetails.password,
      agreed_to_terms: companyInfo.agreed_to_terms === false,
    };

    setError(validated);
    return !Object.values(validated).some((e) => e === true);
  }, [accountDetails, companyInfo]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="h4">Account Details</Typography>
        <Typography>
          Please fill in your information below to create your demo account.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.3,
            padding: "0.5rem 0 0",
          }}
        >
          <Box sx={{ display: "flex", gap: 1.3 }}>
            <TextField
              error={error.first_name}
              required
              label="First name"
              id="first-name"
              onChange={(e) =>
                changeAccountDetails({ first_name: e.target.value ?? "" })
              }
              value={accountDetails.first_name ?? ""}
              {...textFieldProps}
            />
            <TextField
              error={error.last_name}
              required
              label="Last name"
              id="last-name"
              onChange={(e) =>
                changeAccountDetails({ last_name: e.target.value ?? "" })
              }
              value={accountDetails.last_name ?? ""}
              {...textFieldProps}
            />
          </Box>
          <TextField
            error={error.email}
            required
            label="Email address"
            id="email-address"
            onChange={(e) =>
              changeAccountDetails({ email: e.target.value ?? "" })
            }
            type="email"
            value={accountDetails.email ?? ""}
            {...textFieldPropsFullWidth}
          />
          <TextField
            error={error.phonenumber}
            label="Phone number"
            required
            id="phone-number"
            onChange={(e) =>
              changeAccountDetails({ phonenumber: e.target.value ?? "" })
            }
            type="text"
            value={accountDetails.phonenumber ?? ""}
            {...textFieldPropsFullWidth}
          />
          <Tooltip
            placement="bottom-start"
            title={
              <Box
                sx={{
                  ul: {
                    margin: 0,
                    paddingLeft: "2em",
                  },
                }}
              >
                Password must be:
                <ul>
                  <li>at least 8 characters</li>
                  <li>not only numbers</li>
                </ul>
              </Box>
            }
          >
            <TextField
              error={error.password}
              required
              label="Choose a password"
              id="password"
              onChange={(e) =>
                changeAccountDetails({ password: e.target.value ?? "" })
              }
              type="password"
              value={accountDetails.password ?? ""}
              {...textFieldPropsFullWidth}
            />
          </Tooltip>
          <TextField
            error={error.password2}
            required
            label="Confirm password"
            id="confirm-password"
            onChange={(e) =>
              changeAccountDetails({ password2: e.target.value ?? "" })
            }
            type="password"
            value={accountDetails.password2 ?? ""}
            {...textFieldPropsFullWidth}
          />
          <Box className={`${error.agreed_to_terms ? "error" : ""}`}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) =>
                      changeCompanyInfo({ agreed_to_terms: e.target.checked })
                    }
                  />
                }
                label={
                  <div>
                    <span>I accept the </span>
                    <Link
                      href="https://www.ecombooster.se/integritetspolicy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms and conditions
                    </Link>
                  </div>
                }
              />
            </FormGroup>
          </Box>
        </Box>
      </Box>
      <DefaultFooter validate={validate} />
    </Box>
  );
};

export default AccountDetails;
