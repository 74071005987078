import React, { useCallback } from "react";
import Searchbar from "components/filter-products/Searchbar";
import { useEffect, useState } from "react";
import useAppSelector from "hooks/useAppSelector";
import {
  selectFacebookListState,
  setUpdateMessage,
} from "store/procurements-facebook";
import useAppDispatch from "hooks/useAppDispatch";
import SimpleSnackbar from "components/SimpleSnackbar";
import {
  fetchFacebookListAsync,
  removeFacebookItemsAsync,
} from "store/procurements-facebook/thunks";
import { useSearchParams } from "react-router-dom";
import { DataGridPro } from "@mui/x-data-grid-pro";
import GridContainer from "components/common-components/GridContainer";
import { Alert, Box, Snackbar } from "@mui/material";
import theme from "theme/theme";
import useBreakpoint from "hooks/useBreakpoint";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import columns from "./parts/ColumnsForDataGrid";
import CommonConfirmDialog from "components/common-components/CommonConfirmDialog";

const FacebookProductTaxonomiesSelection = (): JSX.Element => {
  const { results, updateMessage, count } = useAppSelector(
    selectFacebookListState,
  );
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [showShortNameErrorMsg, setShowShortNameErrorMsg] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState<number[]>([]);

  const [searchParams] = useSearchParams();
  const isMediumScreen = useBreakpoint("medium");

  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelectionModel(newSelectionModel);
  };

  useEffect(() => {
    searchParams.set("customer_taxonomies__isnull", "False");
    dispatch(fetchFacebookListAsync(searchParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    setRows(results);
  }, [results]);

  const deleteFBProductTaxonomiesSelection = useCallback(
    async (idsArray: any) => {
      const ids = idsArray;

      await Promise.all(
        ids.map((singleId: any) => {
          return dispatch(removeFacebookItemsAsync({ ids: singleId }));
        }),
      );
      dispatch(setUpdateMessage("Delete Successful."));
      setShow(true);

      searchParams.set("customer_taxonomies__isnull", "False");
      dispatch(fetchFacebookListAsync(searchParams));
    },
    [],
  );

  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState(false);
  const [
    isNotificationVisibleToChoseOneRow,
    setIsNotificationVisibleToChoseOneRow,
  ] = useState(false);

  const handleDeleteBtnClick = (rowIds: any[]) => {
    if (rowIds.length > 0) {
      setIsDeleteConfirmDialogOpen(true);
    } else {
      setIsNotificationVisibleToChoseOneRow(true);
    }
  };

  const onCancleClick = () => {
    setIsDeleteConfirmDialogOpen(false);
  };

  const onApproveClick = () => {
    deleteFBProductTaxonomiesSelection(selectionModel);
    setIsDeleteConfirmDialogOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" />
        <ToolboxArea>
          <ToolInfoTooltip title="Remove my selection">
            <ToolIconContainer
              onClick={() => handleDeleteBtnClick(selectionModel)}
            >
              <DeleteForeverIcon
                fontSize="large"
                sx={{
                  color: theme.palette.error.main,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>
        </ToolboxArea>
      </Box>
      {showShortNameErrorMsg ? (
        <SimpleSnackbar
          open={showShortNameErrorMsg}
          message="Name or Short name exist!"
          toggleSnackbar={() => {
            setShowShortNameErrorMsg(false);
          }}
          snackbarAction={null}
        />
      ) : null}

      <GridContainer>
        <DataGridPro
          rows={rows}
          columns={columns}
          columnVisibilityModel={{
            id: false,
          }}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={handleSelectionModelChange}
          checkboxSelection
          pagination
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
        />
      </GridContainer>

      <Snackbar
        open={isNotificationVisibleToChoseOneRow}
        autoHideDuration={4000}
        onClose={() => setIsNotificationVisibleToChoseOneRow(false)}
      >
        <Alert variant="standard" severity="info">
          At least one row should be selected!
        </Alert>
      </Snackbar>

      <CommonConfirmDialog
        isOpen={isDeleteConfirmDialogOpen}
        content="Are you sure you want to delete the facebook product taxonomies selection(s)?"
        affirmativeButtonText="Delete"
        negativeButtonText="Cancel"
        onApprove={onApproveClick}
        onCancel={onCancleClick}
      />

      <Snackbar
        open={show}
        autoHideDuration={4000}
        onClose={() => setShow(false)}
      >
        <Alert>{updateMessage}</Alert>
      </Snackbar>
    </>
  );
};
export default FacebookProductTaxonomiesSelection;
