import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import {
  IImagesResponseDTO,
  ICatalogImageResponseDTO,
  IImageUploadResponseDTO,
} from "store/image-libraries/interfaces";
import { endpoint } from "constants/endpoint";

export interface IImagesService {
  fetchImages(params: URLSearchParams): Promise<IImagesResponseDTO>;
  fetchProductImages(): Promise<ICatalogImageResponseDTO>;
  fetchCategoryImages(): Promise<ICatalogImageResponseDTO>;
  uploadImage(file: FormData): Promise<IImageUploadResponseDTO>;
}

export default new Mockable<IImagesService>({
  async fetchImages(params) {
    const res = await axios.get(`${config.api}${endpoint.catalog_images}`, {
      params,
      headers: commonHeaders(),
    });

    if (res.status === 200) {
      return res.data;
    }

    return Promise.reject("Couldn't get images data");
  },
  async fetchProductImages() {
    const res = await axios.get(`${config.api}${endpoint.product_images}`, {
      headers: commonHeaders(),
    });

    if (res.status === 200) {
      return res.data;
    }

    return Promise.reject("Couldn't get images data");
  },
  async fetchCategoryImages() {
    const res = await axios.get(`${config.api}${endpoint.category_images}`, {
      headers: commonHeaders(),
    });

    if (res.status === 200) {
      return res.data;
    }

    return Promise.reject("Couldn't get images data");
  },

  async uploadImage(image) {
    const res = await axios.post(
      `${config.api}${endpoint.catalog_images}`,
      image,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "multipart/form-data",
        },
      },
    );

    if (res.status === 200 || res.status === 201) {
      return res.data;
    }

    return Promise.reject("Couldn't upload images");
  },
});
