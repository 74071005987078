import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { DateTime } from "luxon";
import useAppSelector from "hooks/useAppSelector";
import { selectProcurementCategoriesState } from "store/procurement-categories";

const SlugDetailsCard = (): JSX.Element => {
  const { category } = useAppSelector(selectProcurementCategoriesState);

  const createdAtTime = DateTime.fromISO(category.created_at).toLocaleString(
    DateTime.DATETIME_MED,
  );
  const updatedAtTime = DateTime.fromISO(category.updated_at).toLocaleString(
    DateTime.DATETIME_MED,
  );

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography fontWeight="bold">Slug:</Typography>
        <Typography fontSize="15"> {category.slug}</Typography>
        <Box sx={{ my: 1 }}>
          <Typography fontWeight="bold">Created at:</Typography>
          <Typography fontSize="15"> {createdAtTime}</Typography>
        </Box>
        <Typography fontWeight="bold">Updated at:</Typography>
        <Typography fontSize="15">{updatedAtTime}</Typography>
      </CardContent>
    </Card>
  );
};

export default SlugDetailsCard;
