import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import { IImageClassificationSliceDTO } from "store/image-classifications/interfaces";
import { endpoint } from "constants/endpoint";

export interface IImageClassificationService {
  fetchImageClassifications(): Promise<IImageClassificationSliceDTO>;
}

export default new Mockable<IImageClassificationService>({
  async fetchImageClassifications() {
    const res = await axios(
      `${config.api}${endpoint.catalog_image_classification}`,
      {
        headers: commonHeaders(),
      },
    );

    if (res.status === 200) {
      const results = res.data as IImageClassificationSliceDTO;
      return results;
    }

    return Promise.reject("Couldn't get images data");
  },
});
