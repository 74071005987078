import React from "react";
import SavedBookmarks from "./SavedBookmarks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createBookmark, fetchBookmarks } from "store/bookmarks/thunks";
import SafeSave from "components/SafeSave";
import useAppSelector from "hooks/useAppSelector";
import { selectbookmarksState } from "store/bookmarks";
import { IBookmark } from "store/bookmarks/interfaces";
import { isEqual } from "lodash-es";
import useAppDispatch from "hooks/useAppDispatch";
import { useSearchParams } from "react-router-dom";
import useChangeFilters from "hooks/useChangeFilters";
import {
  Box,
  Button,
  IconButton,
  SwipeableDrawer,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltSharpIcon from "@mui/icons-material/FilterAltSharp";
import CommonIconButton from "components/common-components/CommonIconButton";

interface IFilterSidebarProps {
  viewName: string;
  children: React.ReactNode;
}

enum ErrorCodes {
  NoError = "",
  NoFilterSelected = "No filters selected",
  BookmarkExists = "Selection already exists as bookmark: ",
  NameEmpty = "Please enter a bookmark name",
  NameExists = "Bookmark name already exists",
}

const FilterSidebar = ({
  viewName,
  children,
}: IFilterSidebarProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const changeFilters = useChangeFilters();
  const { bookmarks } = useAppSelector(selectbookmarksState);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(fetchBookmarks(viewName));
  }, [dispatch, viewName]);

  // ignore page, offset and limit in active filters
  const activeFilters = Array.from(searchParams.entries()).filter(
    (entry) =>
      entry[0] !== "limit" && entry[0] !== "page" && entry[0] !== "offset",
  );
  const noFilterSelected = activeFilters.length === 0;

  const areMapsEqual = useCallback(
    (map1: Map<string, string>, map2: Map<string, string>): boolean => {
      // compares two maps containing key, value pairs
      if (map1.size !== map2.size) return false;
      // compare corresponding keys
      for (const key of map1.keys()) {
        if (!map2.has(key)) return false;
        if (key === "procurement_supplier" || key === "woo_product_type__in") {
          const set1 = new Set(map1.get(key)?.split(","));
          const set2 = new Set(map2.get(key)?.split(","));
          if (!isEqual(set1, set2)) return false;
        } else if (map1.get(key) !== map2.get(key)) return false;
      }
      return true;
    },
    [],
  );

  // returns the name of the bookmark with the same filter selection if any, otherwise an empty string
  const getMatchedBookmarkName = useCallback(
    (activeFilters: [string, string][], bookmarks: IBookmark[]): string => {
      // function to check whether the current selection of filters is already bookmarked
      if (bookmarks.length === 0) return "";
      const activeFiltersMap = new Map(activeFilters);
      const matchedBookmark = bookmarks.find((bookmark) => {
        const query = bookmark.query;
        // convert the bookmark to a map for easy comparison with selected filters
        const map = new Map(
          query.split("&")?.map((param) => {
            const [key, value] = param.split("=");
            return [key, value];
          }),
        );
        return areMapsEqual(map, activeFiltersMap);
      });
      return matchedBookmark ? matchedBookmark.name : "";
    },
    [areMapsEqual],
  );

  const matchedBookmarkName = useMemo(
    () => !noFilterSelected && getMatchedBookmarkName(activeFilters, bookmarks),
    [noFilterSelected, activeFilters, bookmarks, getMatchedBookmarkName],
  );
  const isNameEmpty = name.length === 0;
  const nameExists =
    !isNameEmpty && bookmarks?.map((bookmark) => bookmark.name).includes(name);
  const errorCode = noFilterSelected
    ? ErrorCodes.NoFilterSelected
    : matchedBookmarkName !== ""
      ? ErrorCodes.BookmarkExists + matchedBookmarkName
      : isNameEmpty
        ? ErrorCodes.NameEmpty
        : nameExists
          ? ErrorCodes.NameExists
          : ErrorCodes.NoError;

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const getFilterData = useCallback(
    () => ({
      name: name,
      query: activeFilters?.map((param) => `${param[0]}=${param[1]}`).join("&"),
      view: viewName,
      pinned: false,
    }),
    [activeFilters, name, viewName],
  );

  return (
    <>
      <CommonIconButton onClickPorp={toggleDrawer(true)}>
        <FilterAltSharpIcon />
      </CommonIconButton>

      <SwipeableDrawer
        anchor={"right"}
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{
          sx: { width: "400px", style: "flex", flexDirection: "column" },
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box sx={{ overflowY: "scroll", flex: 1 }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              padding: "1rem",
            }}
          >
            <Box
              sx={{ justifySelf: "center", alignSelf: "center", gridColumn: 2 }}
            >
              Filter panel
            </Box>
            <IconButton
              aria-label="close"
              onClick={toggleDrawer(false)}
              sx={{
                justifySelf: "right",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <SavedBookmarks />
          {children}
        </Box>
        <Box
          sx={{
            width: "100%",
            paddingTop: "1rem",
            zIndex: 1,
            boxShadow: 12,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "0 1rem",
            }}
          >
            <TextField
              value={name}
              id="bookmark_name"
              label="Filter name"
              onChange={(e) => setName(e.target.value ? e.target.value : "")}
              variant="standard"
              error={errorCode !== ErrorCodes.NoError}
              helperText={errorCode}
              disabled={noFilterSelected || matchedBookmarkName !== ""}
              sx={{ width: "100%" }}
            />
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", padding: "1rem" }}>
            <Button
              variant="outlined"
              onClick={() => {
                changeFilters(new URLSearchParams(), true);
              }}
              sx={{ width: "100%" }}
            >
              Clear all filters
            </Button>
            <SafeSave
              thunk={createBookmark}
              getData={getFilterData}
              retryForMinutes={3}
              retryAfterSeconds={10}
              isDisabled={errorCode !== ErrorCodes.NoError}
            />
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default FilterSidebar;
