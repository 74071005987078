import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
  Box,
  InputLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { dataString } from "../utils/dataInfo";
import { IUser } from "store/auth/interfaces";
import { selectAuthenticatedUser } from "store/auth";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import { addInventoryLocationsItemsAsync } from "store/catalog-inventory-locations/thunks";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  selectInventoryLocationsListState,
  setUpdateMessage,
} from "store/catalog-inventory-locations";
import theme from "theme/theme";
import { DateTime } from "luxon";

const AddInventoryLocationsModal = ({
  closeModal,
  setShowNotification,
}: {
  closeModal: () => void;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const { resultTypes } = useAppSelector(selectInventoryLocationsListState);
  const user = useAppSelector(selectAuthenticatedUser) as IUser;

  const account = user.id;
  const company = user.company as number;
  const [nameOfInventory, setNameOfInventory] = useState<string>("");
  const [abbreviationSlug, setAbbreviationSlug] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [stockTakingLastDate, setStockTakingLastDate] =
    useState<any>(undefined);
  const [type, setType] = useState<number | string>(resultTypes?.[0]?.id || 1);
  const [obsolete, setObsolete] = useState<boolean>(false);
  const [notForStock, setNotForStock] = useState<boolean>(false);

  const [nameError, setNameError] = useState(false);

  const handleAddInventoryLocaitons = () => {
    const isNameEmpty = !nameOfInventory;
    setNameError(isNameEmpty);

    if (!isNameEmpty) {
      const newInventoryLocationData = {
        account: account as number,
        company: company as number,
        name: nameOfInventory,
        name_slug: nameOfInventory,
        abbreviation_slug: abbreviationSlug,
        content: content,
        comment: comment,
        stocktaking_perfomed_at: stockTakingLastDate,
        obsolete: obsolete,
        not_for_stock: notForStock,
        type: type as number,
      };
      dispatch(addInventoryLocationsItemsAsync(newInventoryLocationData));
      closeModal();
      dispatch(setUpdateMessage("Location has been added!"));
      setShowNotification(true);
    }
  };

  return (
    <FormControl component="form" sx={{ width: "100%" }}>
      <FormGroup
        sx={{ display: "flex", gap: 1, width: "100%", flexWrap: "wrap" }}
      >
        <Box sx={{ display: "flex" }}>
          <TextField
            id="name"
            fullWidth
            required
            error={nameError}
            label={dataString.modalNameOfTheLocation}
            data-testid="modalNameOfTheLocation"
            onChange={(e) => setNameOfInventory(e.target.value)}
          />
          <ToolInfoTooltip title="Understandable name describing the location in relation to it's context. Needs to be Unique in it's context.">
            <HelpOutlineIcon />
          </ToolInfoTooltip>
        </Box>

        <Box sx={{ display: "flex" }}>
          <TextField
            fullWidth
            id="abbreviation_slug"
            label={dataString.modalAbbreviationSlug}
            data-testid="modalAbbreviationSlug"
            onChange={(e) => setAbbreviationSlug(e.target.value)}
          />
          <ToolInfoTooltip title="Needs to be unique in it's context. Will be used to create a unique location identifier.">
            <HelpOutlineIcon />
          </ToolInfoTooltip>
        </Box>

        <Box sx={{ display: "flex" }}>
          <TextField
            fullWidth
            id="content"
            label={dataString.modalContent}
            data-testid="modalContent"
            onChange={(e) => setContent(e.target.value)}
          />
          <ToolInfoTooltip title="Describe the products normally stored here">
            <HelpOutlineIcon />
          </ToolInfoTooltip>
        </Box>

        <Box sx={{ display: "flex" }}>
          <TextField
            fullWidth
            id="comment"
            label={dataString.modalComment}
            data-testid="modalComment"
            onChange={(e) => setComment(e.target.value)}
          />

          <ToolInfoTooltip title="Misc comments about this location">
            <HelpOutlineIcon />
          </ToolInfoTooltip>
        </Box>

        <Box sx={{ display: "flex" }}>
          <DatePicker
            label={dataString.modalLastDateOfStocktakingForLocation}
            disableFuture
            format="yyyy-MM-dd"
            onChange={(date: DateTime) =>
              setStockTakingLastDate(date ? date.toFormat("yyyy-MM-dd") : "")
            }
            sx={{ width: "100%" }}
            slotProps={{
              openPickerButton: {
                size: "medium",
              },
            }}
          />
          <ToolInfoTooltip title="Pick a date">
            <HelpOutlineIcon />
          </ToolInfoTooltip>
        </Box>

        {resultTypes && resultTypes.length > 0 && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="type">Type</InputLabel>
              <Select
                labelId="type"
                id="type"
                label={dataString.modaltype}
                onChange={(e) => setType(Number(e.target.value))}
              >
                <MenuItem value={1}>Test1</MenuItem>
                <MenuItem value={2}>Test2</MenuItem>
                {/* <Box>
              {resultTypes.map((item: any) => {
              return (
                <MenuItem key={item?.id} value={item?.id}>
                  {item?.type}
                </MenuItem>
              );
            })}
            </Box> */}
              </Select>
            </FormControl>
            <ToolInfoTooltip title="Choose the type">
              <HelpOutlineIcon />
            </ToolInfoTooltip>
          </Box>
        )}

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            id="obsolete"
            label={dataString.modalObsoleteLocation}
            control={
              <Checkbox
                checked={obsolete}
                onChange={(e) => setObsolete(e.target.checked)}
              />
            }
          />
          <ToolInfoTooltip title="Set to True if location is obsolete">
            <HelpOutlineIcon />
          </ToolInfoTooltip>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            id="not_for_stock"
            label={dataString.modalNotForStock}
            control={
              <Checkbox
                onChange={(e) => setNotForStock(e.target.checked)}
                checked={notForStock}
              />
            }
          />
          <ToolInfoTooltip title="Set to True if location is only used as parent for other locations. Will exclude location from e.g. dropdown lists when selecting location for e.g. stock-in.">
            <HelpOutlineIcon />
          </ToolInfoTooltip>
        </Box>

        <Button
          sx={{
            width: "fit-content",
            display: "flex",
            alignSelf: "flex-end",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.default,
          }}
          type="button"
          onClick={handleAddInventoryLocaitons}
        >
          Add
        </Button>
      </FormGroup>
    </FormControl>
  );
};

export default AddInventoryLocationsModal;
