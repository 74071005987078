import React, { useCallback, useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import GridContainer from "components/common-components/GridContainer";
import columns from "./parts/ColumnsForDataGrid";
import useAppSelector from "hooks/useAppSelector";
import {
  selectCatalogBulkDescriptionState,
  selectShowNotification,
  selectUpdateMessage,
  setCellValue,
  setProductValues,
  setShowNotification,
} from "store/catalog-bulk-description/index";
import { fetchBulkDescriptionProducts } from "store/catalog-bulk-description/thunk";
import useAppDispatch from "hooks/useAppDispatch";
import { Alert, Box, Modal, Snackbar, Typography } from "@mui/material";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import theme from "theme/theme";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import useBreakpoint from "hooks/useBreakpoint";
import AddBulkDescriptionForm from "./parts/AddBulkDescriptionForm";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { get } from "lodash-es";

const CatalogBulkDescription = () => {
  const dispatch = useAppDispatch();
  const { products, loading } = useAppSelector(
    selectCatalogBulkDescriptionState,
  );
  const isMediumScreen = useBreakpoint("medium");
  const updateMessage = useSelector(selectUpdateMessage);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>();
  const showNotification = useSelector(selectShowNotification);

  useEffect(() => {
    dispatch(fetchBulkDescriptionProducts({}));
  }, [dispatch]);

  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const handleCellClickWithApi = useCallback((params) => {
    const { field, row } = params;
    const fieldPath = field.replace(".content", "");
    const cellValue = get(row, fieldPath);
    const rowValue = params.row;
    dispatch(setCellValue(cellValue));
    dispatch(setProductValues(rowValue));
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          gap: 1,
        }}
      >
        <ToolboxArea>
          <ToolInfoTooltip title="Add inventory location">
            <ToolIconContainer onClick={() => setIsModalOpen(true)}>
              <AddCircleIcon
                fontSize="large"
                sx={{
                  color: theme.palette.primary.main,
                  stroke: theme.palette.primary.main,
                  strokeWidth: 1,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>
        </ToolboxArea>
      </Box>

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "60vw",
            margin: `${theme.spacing(5)} auto`,
            bgcolor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[24],
            padding: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            id="modal-modal-title"
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" component="h2">
              Add new product description
            </Typography>
            <CloseIcon
              sx={{ fontSize: "18", cursor: "pointer" }}
              onClick={closeModal}
            />
          </Box>
          <Box id="modal-modal-description">
            <AddBulkDescriptionForm closeModal={closeModal} />
          </Box>
        </Box>
      </Modal>

      <GridContainer>
        <DataGridPro
          loading={isLoading}
          rows={products}
          columns={columns}
          rowHeight={600}
          columnHeaderHeight={40}
          pagination
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
              rowCount: 20,
            },
          }}
          columnVisibilityModel={{
            id: false,
          }}
          onCellClick={handleCellClickWithApi}
        />
      </GridContainer>

      <Snackbar
        open={showNotification}
        autoHideDuration={4000}
        onClose={() => dispatch(setShowNotification(false))}
      >
        <Alert>{updateMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default CatalogBulkDescription;
