import React, { useEffect } from "react";
import useAppSelector from "hooks/useAppSelector";
import { selectImagesState } from "store/procurement_images";
import { selectImageClassificationState } from "store/image-classifications";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Link,
  Typography,
} from "@mui/material";
import { fetchImageClassificationsAsync } from "store/image-classifications/thunks";
import useAppDispatch from "hooks/useAppDispatch";

const GridViewLayout = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { images } = useAppSelector(selectImagesState);
  const { imageClassificationsLoaded } = useAppSelector(
    selectImageClassificationState,
  );

  useEffect(() => {
    !imageClassificationsLoaded && dispatch(fetchImageClassificationsAsync());
  }, [imageClassificationsLoaded, dispatch]);

  return (
    <Container
      disableGutters
      sx={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        height: "60vh",
        overflow: "auto",
        padding: "1rem",
        gap: 1,
      }}
    >
      {images?.map((item, index) => (
        <Card
          key={index}
          elevation={1}
          sx={{
            width: "16.66%",
            maxWidth: "16.66%",
            boxSizing: "border-box",
            marginBottom: "1rem",
            borderRadius: "4px",
            background: "#F2F2F2",
          }}
        >
          <CardMedia
            component="img"
            height="140"
            image={item.source_url}
            alt="product image"
            loading="lazy"
          />
          <CardActionArea>
            <CardContent>
              <Link target="_blank" href={item.url} style={{ width: "100%" }}>
                <Typography
                  fontSize={12}
                  noWrap
                  style={{ display: "block", textOverflow: "ellipsis" }}
                >
                  {item.title}
                </Typography>
              </Link>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Container>
  );
};
export default GridViewLayout;
