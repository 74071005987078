import React, { useCallback, useRef, useState } from "react";
import LoadingButton, { LoadStatus } from "components/LoadingButton";
import useAppDispatch from "../hooks/useAppDispatch";
import { IUpdateProductsDTO } from "store/catalog/interfaces";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import NetworkErrorDialog from "components/NetworkErrorDialog";
import CircularWaitDialog from "components/CircularWaitDialog";
import { AxiosError } from "axios";
import { Box } from "@mui/material";
import { debounce } from "lodash-es";

const NETWORK_ERROR_CODE = "ERR_NETWORK";
const SUCCESS_MESSAGE = "Save Successful";
const ERROR_MESSAGE = "Save Cancelled";

interface SafeSaveProps {
  thunk: any;
  getData: () => any; // IUpdateProductsDTO;
  retryForMinutes: number;
  retryAfterSeconds: number;
  isDisabled?: boolean;
}

/* 
A component designed as a safer save operation in case the backend server is down while trying to save some data,
in which case it sends repeated save requests to the backend at certain intervals(specified by user) and pops up dialogs showing the countdown to the next try,
until a certain time threshold(specified by user) is exceeded or the save request is successful.
In case the time exceeds the threshold it asks the user whether to continue retrying or stop.
If they choose to continue it restarts the cycle of retries.

Arguments:
thunk: the redux thunk that saves the data.
data: the data to save
retryForMinutes: time (in minutes) for which to send repeated requests intermittently
retryAfterSeconds: time (in seconds) after which to send another request to the backend after no response to the previous one
successMessage: some message in case of successful save
errorMessage: some message in case if server is down and the user decides to stop retrying
*/

const SafeSave = ({
  thunk,
  getData,
  retryForMinutes,
  retryAfterSeconds,
  isDisabled,
}: SafeSaveProps): JSX.Element => {
  const [saveStatus, setSaveStatus] = useState<LoadStatus>("idle");
  const [isCircularWaitDialogOpen, setIsCircularWaitDialogOpen] =
    useState<boolean>(false);
  const [isNetworkDialogOpen, setIsNetworkDialogOpen] =
    useState<boolean>(false);
  const timeElapsed = useRef<number>(0);
  const askUser = useRef<boolean>(false);
  const clickedYes = useRef<boolean>(false);
  const cancelSave = useRef<((message: string) => void) | null>(null);
  const dispatch = useAppDispatch();

  const save = async (): Promise<string | void> => {
    const data = getData();
    if (clickedYes.current) {
      setIsCircularWaitDialogOpen(true);
    } else {
      setSaveStatus("loading");
    }
    // this promise will be eventually resolved when the save is successful or rejected when the user terminates the retries
    try {
      return await new Promise<any>((resolve, reject) => {
        cancelSave.current = reject;
        dispatch(thunk(data))
          .unwrap()
          .then(() => {
            resolve(SUCCESS_MESSAGE);
            if (clickedYes.current) {
              setIsCircularWaitDialogOpen(false);
              clickedYes.current = false;
            }
            setSaveStatus("done");
            setTimeout(() => {
              setSaveStatus("idle");
            }, 1000);
          })
          .catch((err: AxiosError) => {
            if (clickedYes.current) {
              clickedYes.current = false;
              setIsCircularWaitDialogOpen(false);
            } else {
              setSaveStatus("idle");
            }
            // start the retries if there is a network error
            if (err.code === NETWORK_ERROR_CODE) {
              retry(data, resolve);
            }
          });
      });
    } catch (error_msg) {
      return String(error_msg);
    }
  };

  const retry = (
    data: IUpdateProductsDTO,
    resolve: (value: unknown) => void,
  ) => {
    // a recursive function that responds to network error by calling itself repeatedly to make API calls unless save succeeds or retry time exceeds
    if (timeElapsed.current > retryForMinutes * 60) {
      timeElapsed.current = 0;
      askUser.current = true;
    }
    setIsNetworkDialogOpen(true);
    if (!askUser.current) {
      setTimeout(async () => {
        timeElapsed.current += retryAfterSeconds;
        setIsNetworkDialogOpen(false);
        setIsCircularWaitDialogOpen(true);
        try {
          await dispatch(thunk(data)).unwrap();
          resolve(SUCCESS_MESSAGE);
          setIsCircularWaitDialogOpen(false);
          setSaveStatus("done");
          setTimeout(() => {
            setSaveStatus("idle");
          }, 1000);
        } catch (err: any) {
          setIsCircularWaitDialogOpen(false);
          if (err.code === NETWORK_ERROR_CODE) {
            retry(data, resolve);
          }
        }
      }, retryAfterSeconds * 1000);
    }
  };

  const keepTrying = (): void => {
    // reject the previous save promise and start a new save operation
    setIsNetworkDialogOpen(false);
    clickedYes.current = true;
    timeElapsed.current = 0;
    askUser.current = false;
    if (cancelSave.current) {
      cancelSave.current(ERROR_MESSAGE);
    }
    save();
  };

  const cancelEdit = (): void => {
    timeElapsed.current = 0;
    askUser.current = false;
    if (cancelSave.current) {
      cancelSave.current(ERROR_MESSAGE);
    }
    setIsNetworkDialogOpen(false);
  };

  const handleSave = useCallback(
    debounce(() => save, 300),
    [save],
  );

  return (
    <>
      <LoadingButton
        variant="contained"
        onClick={handleSave}
        sx={{
          width: "6em",
        }}
        loadStatus={saveStatus}
        isDisabled={isDisabled}
      >
        <Box sx={{ display: "flex" }}>
          <SaveOutlinedIcon /> Save
        </Box>
      </LoadingButton>
      {isNetworkDialogOpen && (
        <NetworkErrorDialog
          open={isNetworkDialogOpen}
          retryInterval={retryAfterSeconds}
          askUser={askUser.current}
          handleYes={keepTrying}
          handleNo={cancelEdit}
        />
      )}
      {isCircularWaitDialogOpen && (
        <CircularWaitDialog open={isCircularWaitDialogOpen} />
      )}
    </>
  );
};

export default SafeSave;
