import React from "react";
import {
  Document,
  Page,
  View,
  StyleSheet,
  Svg,
  Rect,
  Text,
} from "@react-pdf/renderer";
import OrderItemListComponent from "./OrderItemList";
import OrderPDFFooterComponent from "./OrderFooter";
import OrderCustomerDetailComponent from "./OrderCustomerDetail";
import OrderPDFHeaderComponent from "./OrderHeader";
import OrderTotalDetailsComponent from "./OrderTotalDetails";
import { ICompanyDetails, ICustomersOrders } from "store/orders/interfaces";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    color: "black",
    padding: "6%",
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  container: {
    marginBottom: 10,
  },
  detailContainer: {
    flexDirection: "row",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
    backgroundColor: "#0a3751",
    padding: 16,
    color: "#ffffff",
  },
});

const GenerateInvoiceComponent = ({
  orders,
  companyDetails,
  type,
}: any): JSX.Element => {
  let companyInfo: ICompanyDetails;
  return (
    // <PDFViewer style={styles.viewer}>
    //   {/* Uncomment to view the Pdf*/}
    <Document>
      {/*render a single page*/}
      {orders &&
        orders?.map((order: ICustomersOrders, i: number) => {
          {
            companyDetails?.forEach((company: ICompanyDetails) => {
              if (order.company == company.id) {
                companyInfo = company;
              }
            });
            styles.headerContainer.backgroundColor =
              companyInfo?.branding_background_color;
            styles.headerContainer.color = companyInfo?.branding_font_color;
          }
          return (
            <Page key={i} size="A4" style={styles.page}>
              <View>
                <Text>Invoice for {order.order_number}</Text>
              </View>
              <OrderPDFHeaderComponent
                companyData={companyInfo}
                orderData={order}
              />
              <OrderCustomerDetailComponent userData={order} />
              <View style={styles.container}>
                <OrderItemListComponent
                  orderData={order}
                  companyData={companyInfo}
                  type={type}
                />
                {companyInfo.packing_slip_price_configuration == "no_prices" &&
                type == "slip" ? (
                  <View style={{ marginBottom: 10 }}>
                    <Svg viewBox="0 0 120 2">
                      <Rect
                        width="100%"
                        height="20"
                        fill={
                          companyInfo?.branding_background_color
                            ? companyInfo?.branding_background_color
                            : "rgb(10,55,81)"
                        }
                      />
                    </Svg>
                  </View>
                ) : (
                  <View style={styles.headerContainer}>
                    <Text>Total: ${order.total_cost}</Text>
                    <OrderTotalDetailsComponent orderData={order} />
                  </View>
                )}
                <OrderPDFFooterComponent
                  companyData={companyInfo}
                  order={order}
                />
              </View>
            </Page>
          );
        })}
    </Document>
    // </PDFViewer>
  );
};

export default GenerateInvoiceComponent;
