import React from "react";
import { Box, Card, Container } from "@mui/material";
import OnboardingStartImage from "components/common-components/OnBoardingStartImage";
import useBreakpoint from "hooks/useBreakpoint";
import { Outlet } from "react-router-dom";

const LoginPage = (): JSX.Element => {
  const isSmallScreen = useBreakpoint("small");
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        elevation={5}
        sx={{
          display: "flex",
          maxWidth: "1000px",
          margin: "auto",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "4rem 8rem",
            width: !isSmallScreen ? "100%" : "50%",
          }}
        >
          <Outlet />
        </Box>
        <Box sx={{ width: !isSmallScreen ? "0" : "50%" }}>
          <OnboardingStartImage />
        </Box>
      </Card>
    </Container>
  );
};

export default LoginPage;
