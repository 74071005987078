import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GridRowId, GridRowParams, GridSortModel } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Alert, Snackbar } from "@mui/material";
import { isEmpty } from "lodash-es";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import ViewsDropdown from "./parts/ViewsDropdown";
import Toolbar from "./parts/Toolbar";
import { createGridRowsProp } from "./utils/createGridRowsProp";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  fetchCatalogProducts,
  fetchProductDescription,
  fetchCatalogProductsDescription,
  fetchProductTypes,
} from "store/catalog/thunks";
import {
  ViewType,
  selectCatalogState,
  setSelectedProducts,
  setSelectedRow,
} from "store/catalog";
import Filter from "components/filter-products/Filter";
import { fetchSuppliers } from "store/filter-catalog-products/thunks";
import { SUB_ROUTE_EDIT_CATALOG_PRODUCTS } from "constants/urls";
import Searchbar from "components/filter-products/Searchbar";
import ExportDialog from "./parts/ExportDialog";
import useFirstPageParams from "hooks/useFirstPageParams";
import ErrorPage from "components/ErrorComponent";
import Modal from "./parts/ModalComponent";
import { fetchInventoriesAsync } from "store/inventories/thunks";
import { fetchInventoryLocations } from "store/inventory-locations/thunks";
import { selectInventoryLocationState } from "store/inventory-locations";
import { selectInventoriesState } from "store/inventories";
import { fetchProcurementSourcesAsync } from "store/procurement-sources/thunks";
import { selectProcurementSourcesState } from "store/procurement-sources";
import {
  ICatalogProductsDescription,
  IProductDescription,
} from "store/catalog/interfaces";
import AvailableFilters from "./parts/AvailableFilters";
import { fetchSupplierListAsync } from "store/procurement-supplierList/thunks";
import GridContainer from "components/common-components/GridContainer";
import useBreakpoint from "hooks/useBreakpoint";
import { DataGridPro } from "@mui/x-data-grid-pro";

const CatalogPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const goToFirstPage = useFirstPageParams();
  const [selected, setSelected] = useState<GridRowId[]>([]);
  const navigate = useNavigate();
  const isMediumScreen = useBreakpoint("medium");
  const [showNotification, setShowNotification] = useState(false);
  const { updateMessage } = useAppSelector(selectCatalogState);

  const {
    columns,
    catalogProducts,
    count,
    loading,
    error,
    view: catalogView,
    productDescription,
    catalogProductsDescription,
  } = useAppSelector(selectCatalogState);
  const [view, setView] = useState<ViewType>(catalogView);

  const { inventoryLocationsLoaded } = useAppSelector(
    selectInventoryLocationState,
  );
  const { inventoryData } = useAppSelector(selectInventoriesState);
  const { procurementSourcesLoaded } = useAppSelector(
    selectProcurementSourcesState,
  );

  const onSelectChange = useCallback(
    //Discuss UX to have the right type fot selected
    (selected: any) => {
      setSelected(selected);
      dispatch(setSelectedProducts(selected));
    },
    [dispatch],
  );

  const combinedData = useMemo(() => {
    const pro = productDescription?.map((item) => {
      const coores = catalogProductsDescription.find(
        (elem: ICatalogProductsDescription) => elem.id === item.product,
      );
      if (coores) {
        return {
          ...item,
          productName: coores.name,
        };
      } else {
        return undefined;
      }
    });
    return pro.filter((item) => item !== undefined);
  }, [productDescription, catalogProductsDescription]);
  const rows = useMemo(
    () => createGridRowsProp(columns, catalogProducts, combinedData, view),
    [columns, catalogProducts, combinedData, view],
  );

  useEffect(() => {
    const catalogProductIds = catalogProducts?.map((product) => product.id);
    dispatch(
      fetchInventoriesAsync(
        catalogProductIds.length > 0 ? catalogProductIds.toString() : "0",
      ),
    );
  }, [dispatch, catalogProducts]);

  useEffect(() => {
    if (!isEmpty(inventoryData)) {
      const procurementProdIds: Array<number> = [];
      for (const x in inventoryData) {
        if (!inventoryData[x].procurement_products.includes(0)) {
          inventoryData[x].procurement_products.forEach((prod: number) => {
            if (!procurementProdIds.includes(prod))
              procurementProdIds.push(prod);
          });
        }
      }

      if (!procurementSourcesLoaded) {
        dispatch(
          fetchProcurementSourcesAsync(
            procurementProdIds.length > 0 ? procurementProdIds.toString() : "0",
          ),
        );
      }
    }
  }, [dispatch, inventoryData, procurementSourcesLoaded]);

  const fetchFilteredProducts = useCallback(() => {
    dispatch(fetchCatalogProducts(searchParams.toString()));
    dispatch(fetchProductDescription(searchParams.toString()));
    dispatch(fetchCatalogProductsDescription());
    document.querySelector(".MuiDataGrid-virtualScroller")!.scrollTo(0, 0);
  }, [dispatch, searchParams]);

  useEffect(() => {
    const debounce = setTimeout(fetchFilteredProducts, 100);
    return () => clearTimeout(debounce);
  }, [fetchFilteredProducts, searchParams]);

  const onRowClick = (event: GridRowParams) => {
    dispatch(setSelectedRow(event.row as IProductDescription));
  };

  useEffect(() => {
    dispatch(fetchSuppliers());
    dispatch(fetchProductTypes());
    dispatch(fetchSupplierListAsync(new URLSearchParams()));
  }, [dispatch]);

  useEffect(() => {
    if (!inventoryLocationsLoaded) {
      dispatch(fetchInventoryLocations({ limit: "10000", obsolete: false }));
    }
  }, [dispatch, inventoryLocationsLoaded]);

  const editProducts = () => {
    navigate(
      `/${SUB_ROUTE_EDIT_CATALOG_PRODUCTS}?products=${selected.join(",")}`,
    );
  };

  if (!isEmpty(error)) {
    return <ErrorPage {...error} />;
  }

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSettingsClick = () => setModalOpen(true);

  return (
    <>
      {modalOpen && (
        <Modal isOpen={modalOpen} handleClose={handleClose} label={view} />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          justifyContent: "flex-end",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" />
        <Box sx={{ display: "flex", gap: 1 }}>
          <Toolbar
            selectedIds={selected}
            editProducts={editProducts}
            view={view}
            onSettingsClick={handleSettingsClick}
            setSearchParams={searchParams as any}
            setShowNotification={setShowNotification}
          />
          <ViewsDropdown view={view} setView={setView} />
        </Box>
      </Box>

      <Filter
        viewName="catalog_bookmarks"
        supplierInfoRequired={true}
        productTypesInfoRequired={true}
      >
        <AvailableFilters />
      </Filter>

      <GridContainer>
        <DataGridPro
          loading={loading}
          rows={rows}
          columns={columns}
          rowHeight={
            view === "default"
              ? 90
              : view === "stocks"
                ? 140
                : view === "prices"
                  ? 180
                  : view === "bulk edit"
                    ? 120
                    : 90
          }
          columnHeaderHeight={40}
          disableColumnFilter
          disableColumnMenu
          hideFooterSelectedRowCount
          sortingMode="server"
          sortingOrder={["asc", "desc"]}
          onRowSelectionModelChange={onSelectChange}
          rowSelectionModel={selected}
          // onCellEditCommit={handleCellEditCommit as any}
          checkboxSelection={view === "default"}
          onSortModelChange={(sortModel: GridSortModel) => {
            searchParams.set("sort_field", sortModel[0].field);
            searchParams.set("sort_direction", sortModel[0].sort!);
            goToFirstPage(searchParams);
          }}
          pagination
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          onRowClick={onRowClick}
        />
      </GridContainer>
      <ExportDialog />

      <Snackbar
        open={showNotification}
        autoHideDuration={4000}
        onClose={() => setShowNotification(false)}
      >
        <Alert>{updateMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default CatalogPage;
