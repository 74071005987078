import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISelectedFilter } from "../../interfaces";

import { RootState } from "../index";
import { IFilterCatalogProductsSliceState, IFilters } from "./interfaces";
import { fetchSuppliers, fetchProductTypes } from "./thunks";

const initialState: IFilterCatalogProductsSliceState = {
  selectedFilters: [],
  filters: {
    supplier: {
      param: "procurement_supplier",
    },
    isSyncedToEcommerce: {
      param: "is_synced_to_ecommerce",
      Yes: "true",
      No: "false",
    },
    procurementProductsExists: {
      param: "has_procurement_products",
      Yes: "true",
      No: "false",
    },
    mappedToProcurement: {
      param: "mapped_to_procurement",
      Yes: "yes",
      No: "no",
    },
    createdAt: {
      param: "created_at",
    },
    updatedAt: {
      param: "updated_at",
    },
    isLowStock: {
      param: "low_in_stock",
      Yes: "true",
      No: "false",
    },
    isOverridePrice: {
      param: "is_override_price",
      Yes: "true",
      No: "false",
    },
    hasNegativeMargin: {
      param: "has_negative_margin",
      Yes: "true",
      No: "false",
    },
    productType: {
      param: "woo_product_type__in",
    },
    marginPercent: {
      param: "margin_percent",
      "< 0%": "_0",
      "0 - 9%": "0_10",
      "10 - 24%": "10_25",
      "25 - 49%": "25_50",
      "50 - 74%": "50_75",
      "75 - 100%": "75_100",
    },
    freeText: {
      param: "search",
    },
  },
};

function prettyPrintProductType(name: string): string {
  if (name === "variable") {
    name = "variable_parent";
  }
  return (name.charAt(0).toUpperCase() + name.slice(1)).replace(/_/g, " ");
}

const filterCatalogProductsSlice = createSlice({
  name: "filterCatalogProducts",
  initialState,
  reducers: {
    setSelectedFilter: (state, action: PayloadAction<ISelectedFilter>) => {
      const filterToReplace = state.selectedFilters.find(
        (f: any) => f.label === action.payload.label,
      );
      if (filterToReplace) {
        state.selectedFilters[state.selectedFilters.indexOf(filterToReplace)] =
          action.payload;
      } else {
        state.selectedFilters.push(action.payload);
      }
    },
    removeSelectedFilterByLabel: (state, action: PayloadAction<string>) => {
      const filterToRemove = state.selectedFilters.find(
        (f: any) => f.label === action.payload,
      );
      if (filterToRemove) {
        state.selectedFilters.splice(
          state.selectedFilters.indexOf(filterToRemove),
          1,
        );
      }
    },
  },
  extraReducers: (builder) => {
    // sort the suppliers by name before storing, assuming that all supplier names are unique!!
    builder.addCase(fetchSuppliers.fulfilled, (state, action) => {
      state.filters.supplier = action.payload
        .sort((supplier1, supplier2) =>
          supplier1.name < supplier2.name ? -1 : 1,
        )
        .reduce(
          (suppliers, supplier) => ({
            ...suppliers,
            [supplier.name]: supplier.name_abbrev,
          }),
          state.filters.supplier,
        );
    });
    builder.addCase(fetchProductTypes.fulfilled, (state, action) => {
      state.filters.productType = action.payload.reduce(
        (productTypes, productType) => ({
          ...productTypes,
          [prettyPrintProductType(productType.name)]: productType.name,
        }),
        state.filters.productType,
      );
    });
  },
});

export const selectFilters = (state: RootState): IFilters =>
  state.filterCatalogProducts.filters;
export const selectSelectedFilters = (state: RootState): ISelectedFilter[] =>
  state.filterCatalogProducts.selectedFilters;

export const { setSelectedFilter, removeSelectedFilterByLabel } =
  filterCatalogProductsSlice.actions;

export default filterCatalogProductsSlice.reducer;
