import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { IProcurementCompanies } from "./interfaces";
import {
  UpdateProcurementCompaniesListAsync,
  fetchCompaniesListAsync,
  fetchCompanesListByIdAsync,
} from "./thunks";

export const initialState: IProcurementCompanies = {
  companyLoading: false,
  count: 0,
  next: "",
  previous: "",
  results: [],
  updateMessage: "",
};

export const ProcurementCompaniesListSlice = createSlice({
  name: "ProcurementCompaniesList",
  initialState,
  reducers: {
    setUpdateMessage: (state, { payload }) => {
      state.updateMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompaniesListAsync.fulfilled, (state, action) => {
      state.companyLoading = false;
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results;
    });

    builder.addCase(fetchCompaniesListAsync.pending, (state) => {
      state.companyLoading = true;
    });

    builder.addCase(fetchCompaniesListAsync.rejected, (state) => {
      state.companyLoading = false;
    });

    builder.addCase(
      UpdateProcurementCompaniesListAsync.fulfilled,
      (state, action) => {
        state.companyLoading = false;
        state.updateMessage = "Update successfully";
      },
    );

    builder.addCase(fetchCompanesListByIdAsync.fulfilled, (state) => {
      state.companyLoading = false;
    });
  },
});

export const selectCompaniesListState = (
  state: RootState,
): IProcurementCompanies => state.ProcurementCompaniesList;

export const { setUpdateMessage } = ProcurementCompaniesListSlice.actions;

export default ProcurementCompaniesListSlice.reducer;
