/* eslint-disable no-console */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import config from "../../config";
import { RootState } from "../index";
import { IProcurementCategoriesState } from "./interfaces";
import {
  addProcurementCategoryAsync,
  deleteProcurementCategory,
  fetchProcurementCategoriesAsync,
  fetchProcurementCategoryAsync,
  updateProcurementCategoryAsync,
} from "./thunks";
import { axiosErrorResponse, httpErrorHandler } from "utils/error-response";

const initialState: IProcurementCategoriesState = {
  loading: false,
  categories: [],
  category: {
    id: 0,
    name: "",
    supplier: 0,
    url: "",
    slug: "",
    products: [],
    created_at: "",
    updated_at: "",
    url_local: "",
    depth: 0,
    description: "",
    description_html: "",
    db_id: "",
    last_date_scraped: "",
    account: 0,
    company: 0,
    parent: 0,
    latest_feed: "",
    urls: [],
    child_category_urls: [],
    product_urls: [],
    catalog_categories_auto_create: [],
  },
  count: 0,
  procurementCategoriesLoaded: false,
  filterParams: {
    page: 0,
    limit: 50,
    supplier: { id: 0, name: "" },
    search: "",
    productExist: Boolean(undefined),
    procurementCategoryCreatedGte: "",
    procurementCategoryCreatedLte: "",
    procurementCategoryUpdatedGte: "",
    procurementCategoryUpdatedLte: "",
  },
  success: "",
  fail: "",
  error: {},
};

const procurementCategoriesSlice = createSlice({
  name: "procurementCategories",
  initialState,
  reducers: {
    clearCategoryMessage: (state) => {
      state.success = "";
      state.error = {};
      state.fail = "";
    },
    setProcurementCatagoriesParams: (state, action) => {
      state.procurementCategoriesLoaded = false;
      state.filterParams = { ...state.filterParams, ...action.payload };
    },
    removeProcurementCategory: (state, action: PayloadAction<number>) => {
      state.categories = state.categories.filter(
        (item) => action.payload !== item.id,
      );
    },
    resetAllFilters: (state) => {
      state.procurementCategoriesLoaded = false;
      state.filterParams = {
        page: 0,
        limit: 100,
        supplier: { id: 0, name: "" },
        search: "",
        productExist: false,
        procurementCategoryCreatedGte: "",
        procurementCategoryCreatedLte: "",
        procurementCategoryUpdatedGte: "",
        procurementCategoryUpdatedLte: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProcurementCategoriesAsync.fulfilled, (state, action) => {
        state.categories = action.payload.results;
        state.procurementCategoriesLoaded = true;
        state.nextPageUrl = action.payload.next;
        state.previousPageUrl = action.payload.previous;
        state.count = action.payload.count;
      })
      .addCase(fetchProcurementCategoriesAsync.rejected, (state, { error }) => {
        state.loading = false;
        state.error = {
          ...httpErrorHandler(error),
          url: config.api + "/procurement/categories/",
        };
      })
      .addCase(fetchProcurementCategoryAsync.fulfilled, (state, action) => {
        state.category = action.payload;
      })
      .addCase(fetchProcurementCategoryAsync.rejected, (state, { error }) => {
        state.error = {
          ...httpErrorHandler(error),
          url: config.api + "/procurement/categories/:id",
        };
      })
      .addCase(addProcurementCategoryAsync.fulfilled, (state, action) => {
        state.category = action.payload;
        state.success = "category added successfully";
      })
      .addCase(addProcurementCategoryAsync.rejected, (state, { payload }) => {
        state.error = {
          ...axiosErrorResponse(payload),
          url: config.api + "/procurement/categories/:id",
        };
      })
      .addCase(updateProcurementCategoryAsync.fulfilled, (state, action) => {
        state.category = action.payload;
        state.success = "category updated successfully";
      })
      .addCase(
        updateProcurementCategoryAsync.rejected,
        (state, { payload }) => {
          state.error = {
            ...axiosErrorResponse(payload),
            url: config.api + "/procurement/categories/:id",
          };
        },
      )
      .addCase(deleteProcurementCategory.fulfilled, (state, action) => {
        state.success = "1 category deleted successfully";
        state.loading = false;
      })
      .addCase(deleteProcurementCategory.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteProcurementCategory.rejected, (state, { error }) => {
        state.loading = false;
        if (error.message) state.fail = error.message;
        else
          console.log(
            "guide: deleteProcurementCategory.rejected",
            error.message,
          );
      });
  },
});

export const selectProcurementCategoriesState = (
  state: RootState,
): IProcurementCategoriesState => state.procurementCategories;

export const {
  setProcurementCatagoriesParams,
  resetAllFilters,
  removeProcurementCategory,
  clearCategoryMessage,
} = procurementCategoriesSlice.actions;

export default procurementCategoriesSlice.reducer;
