import React from "react";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import MuiLink from "@mui/material/Link";
import { Link, useLocation } from "react-router-dom";

import { extractBreadcrumbs } from "./utils/extractBreadcrumbs";

const Breadcrumbs = (): JSX.Element => {
  const location = useLocation();
  const breadcrumbs = extractBreadcrumbs(location.pathname);

  return (
    <MuiBreadcrumbs separator=">" sx={{ marginBottom: "1rem" }}>
      {breadcrumbs?.map((breadcrumb) => (
        <MuiLink
          component={Link}
          key={breadcrumb.name}
          sx={{ fontWeight: "500", lineHeight: "31px" }}
          to={breadcrumb.link}
          underline="hover"
          variant="h5"
        >
          {breadcrumb.name}
        </MuiLink>
      ))}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
