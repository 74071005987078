import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { fetchProcurementSourcesAsync } from "./thunks";
import { IPickBackorder, IProcurementOrderProducts } from "./interfaces";

export interface IProcurementSourcesState {
  procurementSources: IProcurementOrderProducts;
  loading: boolean;
  backorders: { [key: string]: IPickBackorder };
  error: string;
  procurementSourcesLoaded: boolean;
}

const initialState = {
  procurementSources: {},
  loading: false,
  backorders: {},
  error: "",
  procurementSourcesLoaded: false,
};

export const procurementSourcesSlice = createSlice({
  name: "procurementSources",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProcurementSourcesAsync.fulfilled, (state, action) => {
      state.procurementSources = action.payload.result.procurement_products;
      state.backorders = action.payload.result.backorders;
      state.loading = false;
      state.procurementSourcesLoaded = true;
    });

    builder.addCase(fetchProcurementSourcesAsync.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      fetchProcurementSourcesAsync.rejected,
      (state, { error }) => {
        if (error.message) {
          state.error = error.message;
        }
      },
    );
  },
});

export const selectProcurementSourcesState = (
  state: RootState,
): IProcurementSourcesState => state.procurementSources;

export default procurementSourcesSlice.reducer;
