import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { IProcurementProduct } from "store/procurement-products/interfaces";

export const createGridRowsProp = (
  columns: GridColDef[],
  products: IProcurementProduct[],
): GridRowsProp => {
  const gridRowsProp: GridRowsProp = products?.map((product) => {
    const gridRow: any = {
      id: product.id,
    };

    columns.forEach((column) => {
      gridRow[column.field] = product[column.field];
    });

    return gridRow;
  });

  return gridRowsProp;
};
