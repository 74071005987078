import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { selectCatalogState } from "store/catalog";
import { fetchCatalogProductsWithIds } from "store/catalog/thunks";
import { selectProcurementCategoriesState } from "store/procurement-categories";
import { selectProcurementSourcesState } from "store/procurement-sources";
import { fetchProcurementSourcesAsync } from "store/procurement-sources/thunks";

export default function MappedProductsTable(): JSX.Element {
  const dispatch = useAppDispatch();
  const { category } = useAppSelector(selectProcurementCategoriesState);

  const { procurementSources } = useAppSelector(selectProcurementSourcesState);
  const { catalogProducts } = useAppSelector(selectCatalogState);

  const getCatalogProdIds = useCallback(() => {
    const catalogProdIds: number[] = [];
    for (const x in procurementSources) {
      if (!procurementSources[x].catalog_products.includes(0))
        procurementSources[x].catalog_products.forEach((prod) => {
          catalogProdIds.push(prod);
        });
    }
    return catalogProdIds;
  }, [procurementSources]);

  useEffect(() => {
    const catalogProdIds = getCatalogProdIds();
    if (catalogProdIds.length > 0)
      dispatch(fetchCatalogProductsWithIds(catalogProdIds));
  }, [dispatch, getCatalogProdIds]);

  useEffect(() => {
    const procurmentIds = category.products?.map((product) => product.id);
    if (procurmentIds.length > 0)
      dispatch(fetchProcurementSourcesAsync(procurmentIds.toString()));
  }, [category, dispatch]);

  const getCatalogProducts = (proId: number) => {
    const procurementSource = procurementSources[proId];

    if (procurementSource !== undefined) {
      const cataProd = procurementSource.catalog_products;
      if (cataProd[0] === null) {
        return "Not mapped yet";
      } else {
        const foundCatalogProduct = (catId: number) => {
          const found =
            catalogProducts.length > 0 &&
            catalogProducts.find((item) => item.id === catId);
          return found !== undefined && false;
        };

        for (const el of cataProd) {
          return foundCatalogProduct(el);
        }
      }
    }
    return "Procurement source not found";
  };

  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <Typography sx={{ pl: 2 }} fontWeight="bold">
        Products
      </Typography>

      <TableContainer sx={{ maxHeight: 200 }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Procurement Product</TableCell>
              <TableCell align="right">Catalog Product(s)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {category.products?.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {procurementSources[row.id] &&
                    `${procurementSources[row.id].name} [${procurementSources[row.id].sku}]`}
                </TableCell>
                <TableCell align="right">
                  {getCatalogProducts(row.id)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
