import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Grid, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import useAppSelector from "hooks/useAppSelector";
import { ordersSelectors } from "store/orders";
import { costs, payments, taxes } from "../utils";
import { selectCurrencies } from "store/currencies";
import { formatDateTime } from "utils/time/formatDateTime";

export default function PrisSkatt(): JSX.Element {
  const [editCustomersOrder, setEditCustomersOrder] = useState({
    currency: "",
    totalCost: "",
    disccountTotal: "",
    totalTaxes: "",
    taxOnProducts: "",
    discountTax: "",
    shippingTax: "",
    PricesIncludesTax: "",
    VatExempt: "",
    VatExemptID: "",
    PaymenMethodID: "",
    PaymentMethodDetails: "",
    PaymentDate: "",
  });

  const { order } = useAppSelector(ordersSelectors);
  const { currencies } = useAppSelector(selectCurrencies);

  const findCurrency = useCallback(
    (currency: number) => {
      const foundCurrency = currencies.find((curr) =>
        curr.url.includes(currency.toString()),
      );
      if (foundCurrency !== undefined) {
        return foundCurrency.iso_code;
      } else {
        return "";
      }
    },
    [currencies],
  );

  useEffect(() => {
    if (order) {
      setEditCustomersOrder({
        currency: findCurrency(order.currency),
        totalCost: order.total_cost,
        disccountTotal: order.discount_total,
        totalTaxes: order.total_tax,
        taxOnProducts: order.service_fee_tax,
        discountTax: order.discount_tax,
        shippingTax: order.shipping_tax,
        PricesIncludesTax: order.total_cost_incl_tax ? "Yes" : "No",
        VatExempt: order.vat_exempt ? "Yes" : "No",
        VatExemptID: order.vat_exempt_id,
        PaymenMethodID: order.payment_method,
        PaymentMethodDetails: order.payment_method_details,
        PaymentDate: formatDateTime(order.payment_date),
      });
    }
  }, [order, findCurrency]);

  return (
    <Grid container spacing={1}>
      <Grid item xl={3} lg={5} xs={12}>
        <List dense>
          <Typography sx={{ mt: 2 }} fontWeight={900} component="div">
            Cost
          </Typography>
          {costs?.map((OI, index) => (
            <ListItem key={index}>
              <Stack direction="row" spacing={5}>
                <ListItemText sx={{ width: 120 }} primary={OI.field} />
                {
                  <ListItemText
                    sx={{ maxWidth: 350 }}
                    primary={
                      editCustomersOrder[OI.identifier]
                        ? editCustomersOrder[OI.identifier]
                        : "-"
                    }
                  />
                }
              </Stack>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xl={3} lg={5} xs={12}>
        <List dense>
          <Typography sx={{ mt: 2 }} fontWeight={900} component="div">
            Tax
          </Typography>

          {taxes?.map((OI, index) => (
            <ListItem key={index}>
              <Stack direction="row" spacing={5}>
                <ListItemText sx={{ width: 120 }} primary={OI.field} />
                {
                  <ListItemText
                    sx={{ maxWidth: 350 }}
                    primary={
                      editCustomersOrder[OI.identifier]
                        ? editCustomersOrder[OI.identifier]
                        : "-"
                    }
                  />
                }
              </Stack>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xl={3} lg={5} xs={12}>
        <List dense>
          <Typography sx={{ mt: 2 }} fontWeight={900} component="div">
            Payment
          </Typography>
          {payments?.map((OI, index) => (
            <ListItem key={index}>
              <Stack direction="row" spacing={5}>
                <ListItemText sx={{ width: 120 }} primary={OI.field} />
                {
                  <ListItemText
                    sx={{ maxWidth: 350 }}
                    primary={
                      editCustomersOrder[OI.identifier]
                        ? editCustomersOrder[OI.identifier]
                        : "-"
                    }
                  />
                }
              </Stack>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
