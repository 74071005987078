import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  TextField,
  Autocomplete,
  Button,
} from "@mui/material";
import { isEmpty } from "lodash-es";
import { textFieldProps } from "../ColumnsForDataGrid";
import useAppDispatch from "hooks/useAppDispatch";
import { addInventoryTransactionAsync } from "store/inventory-transactions/thunks";
import { fetchCatalogProducts } from "store/catalog/thunks";
import { displaySnackbar } from "store/snackbar";
import useAppSelector from "hooks/useAppSelector";
import { selectInventoriesState } from "store/inventories";
import { IInventoryLocation } from "store/inventory-locations/interfaces";
import { selectInventoryLocationState } from "store/inventory-locations";
import { IInventory } from "store/inventories/interfaces";

interface MoveStockProps {
  id: number;
  handleClose: () => void;
}

const MoveStock = ({ id, handleClose }: MoveStockProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [moveFrom, setMoveFrom] = useState("");
  const [moveTo, setMoveTo] = useState<{
    slug: string;
    id: number;
  } | null>(null);
  const [quantityToMove, setQuantityToMove] = useState("");

  const [filteredInventoryLocations, setFilteredInventoryLocations] = useState<
    IInventoryLocation[]
  >([]);
  const { inventoryLocations } = useAppSelector(selectInventoryLocationState);

  const { inventoryData } = useAppSelector(selectInventoriesState);
  const inventories =
    !isEmpty(inventoryData[id]) && inventoryData[id].inventories;

  const getAccumulatedQunatity = () => {
    const found =
      !isEmpty(inventories) &&
      (inventories as IInventory[]).find(
        (inv) => inv.location_id === +moveFrom,
      );
    return found && parseFloat(found.accumulated_quantity).toFixed(2);
  };

  const onMoveQusntityChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const accumulatedQuantity = getAccumulatedQunatity();
    if (accumulatedQuantity && +e.target.value > +accumulatedQuantity!) {
      setQuantityToMove(accumulatedQuantity);
    } else {
      setQuantityToMove(e.target.value);
    }
  };

  useEffect(() => {
    if (!isEmpty(inventoryLocations))
      setFilteredInventoryLocations(inventoryLocations);
  }, [dispatch, inventoryLocations]);

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    await dispatch(
      addInventoryTransactionAsync({
        object: {
          quantity: quantityToMove,
          inventory_action: "STOCK_OUT",
          product: id,
          location: +moveFrom,
        },
      }),
    );
    await dispatch(
      addInventoryTransactionAsync({
        object: {
          quantity: quantityToMove,
          inventory_action: "STOCK_IN",
          product: id,
          location: moveTo!.id,
        },
      }),
    )
      .unwrap()
      .finally(() => {
        handleClose();
        dispatch(fetchCatalogProducts(""));
        dispatch(
          displaySnackbar({
            message: "Transaction moved successfully",
            action: null,
            open: false,
          }),
        );
      });
  };

  const filteredNonZeroQuantityInventories = () =>
    !isEmpty(inventories) &&
    (inventories as IInventory[]).filter(
      (inv) => +inv.accumulated_quantity > 0,
    );

  useEffect(() => {
    (filteredNonZeroQuantityInventories() as IInventory[]).length === 1 &&
      setMoveFrom(
        (
          filteredNonZeroQuantityInventories() as IInventory[]
        )[0].location_id.toString(),
      );
  }, []);
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="form"
          onSubmit={handleOnSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Move From</InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={moveFrom}
                label="Move From"
                onChange={(e) => setMoveFrom(e.target.value)}
              >
                {(filteredNonZeroQuantityInventories() as IInventory[])?.map(
                  (inventory) => {
                    return (
                      <MenuItem
                        value={inventory.location_id}
                        key={inventory.location_id}
                      >
                        {inventory.abbreviation_slug}
                      </MenuItem>
                    );
                  },
                )}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ my: 1, fontSize: 15 }}>
            Accoumulated quantity: {moveFrom && getAccumulatedQunatity()}
          </Box>

          <TextField
            required
            label="Quantity to Move"
            type="number"
            id="quantity"
            value={quantityToMove}
            disabled={moveFrom === ""}
            InputProps={{
              inputProps: { min: 0, step: ".01" },
            }}
            onChange={onMoveQusntityChanged}
            {...textFieldProps}
          />

          <Box sx={{ minWidth: 300, mt: 1 }}>
            <Autocomplete
              value={moveTo}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              id="autocomplete-1"
              options={
                !isEmpty(filteredInventoryLocations)
                  ? filteredInventoryLocations?.map((inventory) => {
                      if (inventory.abbreviation_slug) {
                        return {
                          slug: inventory.abbreviation_slug,
                          id: inventory.id,
                        };
                      } else {
                        return {
                          slug: `${inventory.name_slug} (abbreviation_slug: undefined)`,
                          id: inventory.id,
                        };
                      }
                    })
                  : []
              }
              getOptionLabel={(option) => `${option.slug}`}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Move To"
                  variant="outlined"
                  fullWidth
                />
              )}
              onChange={(e, value) => setMoveTo(value ?? { slug: "", id: 0 })}
            />
          </Box>

          <Button
            disabled={
              moveFrom === "" || quantityToMove === "" || moveTo === null
            }
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default MoveStock;
