import { createAsyncThunk } from "@reduxjs/toolkit";
import catalogCategories from "services/catalog-categories";
import {
  ICatalogCategoriesFilterParams,
  ICatalogCategoriesResponseDTO,
  ICatalogCategory,
} from "./interfaces";

function getAxiosParams(
  catalogCategoriesParams: ICatalogCategoriesFilterParams,
) {
  const params = new URLSearchParams();
  params.append(
    "offset",
    (catalogCategoriesParams.page * catalogCategoriesParams.limit).toString(),
  );
  params.append("limit", catalogCategoriesParams.limit.toString());
  params.append(
    "created_at__gte",
    catalogCategoriesParams.catalogCategoryCreatedGte.toString(),
  );
  params.append(
    "created_at__lte",
    catalogCategoriesParams.catalogCategoryCreatedLte.toString(),
  );
  params.append(
    "updated_at__gte",
    catalogCategoriesParams.catalogCategoryUpdatedGte.toString(),
  );
  params.append(
    "updated_at__lte",
    catalogCategoriesParams.catalogCategoryUpdatedLte.toString(),
  );
  params.append("search", catalogCategoriesParams.search.toString());

  return params;
}

export const fetchCatalogCategoriesAsync = createAsyncThunk(
  "catagory/fetchCatalogCategories",
  async (params: URLSearchParams): Promise<ICatalogCategoriesResponseDTO> => {
    return await catalogCategories.service().fetchCatalogCategories(params);
  },
);

export const updateCatalogCategoriesAsync = createAsyncThunk(
  "catagory/updateCatalogCategoriesAsync",
  async (
    { id, object }: { id: string; object: ICatalogCategory },
    thunkAPI,
  ) => {
    try {
      return await catalogCategories
        .service()
        .updateCatalogCategoriesAsync(id, object);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
