import React from "react";
import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { customerTableColumns } from "./ParentTableColumn";
import Loading from "./Loading";
import { isEmpty } from "lodash-es";
import ErrorPage from "components/ErrorComponent";
import OrdersChildTable from "./OrdersChildTable";
import useAppSelector from "hooks/useAppSelector";
import { ordersSelectors } from "store/orders";
import GridContainer from "components/common-components/GridContainer";

export default function ParentTable(props: {
  expandAll: boolean;
}): JSX.Element {
  const expandAll = props.expandAll;
  const { orders, ordersLoaded, error } = useAppSelector(ordersSelectors);

  if (!isEmpty(error)) {
    return <ErrorPage {...error} />;
  }
  return (
    <>
      {!ordersLoaded ? (
        <Loading />
      ) : (
        <GridContainer>
          <DataGridPro
            key={Number(expandAll)} // Needed to add this line for toggle to work
            rows={orders}
            columns={customerTableColumns as any}
            checkboxSelection
            pagination
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            // getDetailPanelContent={({ row }) => {
            //   return row.order_items.length > 0 ? (
            //     <OrdersChildTable products={row.order_items} />
            //   ) : (
            //     <>No items to show inside an order</>
            //   );
            // }}
            // pagination
            // initialState={{
            //   pagination: {
            //     paginationModel: { pageSize: 25, page: 0 },
            //   },
            //   detailPanel: {
            //     expandedRowIds: expandAll
            //       ? orders?.map((row) => row.id ?? [])
            //       : [],
            //   },
            // }}
          />
        </GridContainer>
      )}
    </>
  );
}
