import React from "react";
import { useNavigate } from "react-router-dom";

import { resetOnboarding } from "store/onboarding";
import useAppDispatch from "hooks/useAppDispatch";
import ContactDetails from "./Contact";
import { Button, Grid, Typography } from "@mui/material";

const OnboardingDone = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOnDone = () => {
    dispatch(resetOnboarding());
    navigate("/onboarding");
  };

  return (
    <Grid gap="1rem">
      <Typography variant="h4">Your demo account has been created!</Typography>
      <Typography>
        You can now login and try out our demo, if you have any questions please
        contact us.
      </Typography>
      <ContactDetails />
      <Button
        onClick={handleOnDone}
        variant="outlined"
        sx={{ flex: 1, height: "2.3rem" }}
      >
        Log in
      </Button>
    </Grid>
  );
};

export default OnboardingDone;
