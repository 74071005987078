import React, { useState } from "react";
import useAppSelector from "hooks/useAppSelector";
import { selectIsDemoAccount } from "store/auth";
import ContactDetails from "pages/onboarding-page/parts/Contact";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ToolInfoTooltip from "./common-components/ToolInfoTooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import theme from "theme/theme";
import ToolIconContainer from "./common-components/ToolIconContainer";

const AddNewSupplierButton = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const isDemoAccount = useAppSelector(selectIsDemoAccount);

  return (
    <>
      <ToolInfoTooltip title="Add new supplier (coming soon)">
        <ToolIconContainer disabled>
          <AddCircleIcon
            fontSize="large"
            sx={{
              color: theme.palette.text.disabled,
              stroke: theme.palette.text.disabled,
            }}
          />
        </ToolIconContainer>
      </ToolInfoTooltip>

      <Dialog open={open} onClose={closeModal}>
        <DialogTitle>Demo version</DialogTitle>
        <DialogContent>
          <Typography>
            This is a demo version you cannot add a supplier.
            <br />
            Would you like to become a customer, please contact us.
          </Typography>
          <ContactDetails />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddNewSupplierButton;
