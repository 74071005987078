import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { fetchInventoryLocations } from "./thunks";
import { IInventoryLocationSliceState } from "./interfaces";

const initialState: IInventoryLocationSliceState = {
  inventoryLocations: [],
  count: 0,
  inventoryLocationsLoaded: false,
};

const inventoryLocationsSlice = createSlice({
  name: "inventoryLocations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInventoryLocations.fulfilled, (state, action) => {
      state.inventoryLocations = action.payload.results;
      state.count = action.payload.count;
      state.inventoryLocationsLoaded = true;
    });
  },
});

export const selectInventoryLocationState = (
  state: RootState,
): IInventoryLocationSliceState => state.inventoryLocations;

export default inventoryLocationsSlice.reducer;
