import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { IBookmark, IBookmarksResponseDTO } from "store/bookmarks/interfaces";

export interface IBookmarksService {
  create(bookmark: IBookmark): Promise<void>;
  read(view: string): Promise<IBookmarksResponseDTO>;
  put(old_view: string, old_name: string, bookmark: IBookmark): Promise<void>;
  delete(name: string, view: string): Promise<void>;
}

export default class BookmarksService {
  private readonly url: string;
  private readonly headers: Record<string, string>;

  constructor(url?: string, headers?: Record<string, string>) {
    this.url = url ?? config.api + "/user/bookmarks/";
    this.headers = headers ?? commonHeaders();
  }

  async create(bookmark: IBookmark): Promise<void> {
    try {
      const res = await axios.post(
        this.url,
        {
          name: bookmark.name,
          query: bookmark.query,
          view: bookmark.view,
          pinned: bookmark.pinned,
        },
        { headers: this.headers },
      );

      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error("Could not update product in >> async create <<"),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async read(view: string): Promise<IBookmarksResponseDTO> {
    const res = await axios.get(`${this.url}?view=${view}`, {
      headers: this.headers,
    });
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  }

  async put(
    old_view: string,
    old_name: string,
    bookmark: IBookmark,
  ): Promise<void> {
    const res = await axios.put(
      `${this.url}?view=${old_view}&name=${old_name}`,
      {
        name: bookmark.name,
        query: bookmark.query,
        view: bookmark.view,
        pinned: bookmark.pinned,
      },
      { headers: this.headers },
    );
    if (res.status !== 200) {
      Promise.reject("Something went wrong on update");
    }
  }

  async delete(name: string, view: string): Promise<void> {
    const res = await axios.delete(`${this.url}?view=${view}&name=${name}`, {
      headers: this.headers,
    });
    if (res.status !== 200) {
      Promise.reject("Something went wrong on delete");
    }
  }
}
