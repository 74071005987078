import React from "react";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import {
  selectProcurementSupplierFeedState,
  setSupplierFeedParam,
} from "store/procurement-feeds";
import { fetchProcurementSupplierFeedAsync } from "store/procurement-feeds/thunks";
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import { supplierFeedsTableColumns } from "./utils/SupplierFeedsTable";
import Filter from "components/filter-products/Filter";
import AddNewSupplierButton from "components/AddNewSupplierButton";
import Searchbar from "components/filter-products/Searchbar";
import { selectSupplierListState } from "store/procurement-supplierList";
import { fetchSupplierListAsync } from "store/procurement-supplierList/thunks";
import SupplierAlert from "./parts/SupplierAlert";
import { getSuppliersWithWarning, createRows } from "./utils/FeedAlertUtils";
import { SupplierContext } from "./interfaces";
import { useSearchParams } from "react-router-dom";
import AvailableFilters from "./parts/AvailableFilters";
import useChangeFilters from "hooks/useChangeFilters";
import GridContainer from "components/common-components/GridContainer";
import ToolboxArea from "components/common-components/ToolboxArea";

const ProcurementsAndSupplierFeedsPage = (): JSX.Element => {
  // const filters = useAppSelector(selectFilters);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [allSuppliers, setAllSuppliers] = useState<[number, string][]>([]);
  const [displayWarning, setDisplayWarning] = useState<boolean>(false);
  const [suppliersWithWarning, setSuppliersWithWarning] = useState<string[]>(
    [],
  );
  const { count, FeedList, loading } = useAppSelector(
    selectProcurementSupplierFeedState,
  );
  const changeFilters = useChangeFilters();
  useEffect(() => {
    dispatch(fetchProcurementSupplierFeedAsync(searchParams));
  }, [dispatch, searchParams]);
  const { supplierLoading, results } = useAppSelector(selectSupplierListState);
  // fetch the procurement supplier data on mount
  useEffect(() => {
    dispatch(fetchSupplierListAsync(new URLSearchParams()));
  }, [dispatch]);
  useEffect(
    () =>
      setAllSuppliers(results?.map((supplier) => [supplier.id, supplier.name])),
    [results],
  );
  // alert dialog when the page mounts or there is a change in the supplier data (warning days) or procurement feed
  useEffect(() => {
    results &&
      FeedList &&
      setSuppliersWithWarning(getSuppliersWithWarning(results, FeedList));
    setDisplayWarning(suppliersWithWarning.length !== 0);
  }, [results, supplierLoading, FeedList, suppliersWithWarning.length]);
  const rows = createRows(results, FeedList);

  const searchHandler = (value: string) => {
    dispatch(setSupplierFeedParam({ search: value, page: 0 }));
  };

  const getSortModel = (): GridSortModel => {
    if (!searchParams.has("ordering")) return [];
    switch (searchParams.get("ordering")) {
      case "supplier__name":
        return [{ field: "supplier_name", sort: "asc" }];
      case "-supplier__name":
        return [{ field: "supplier_name", sort: "desc" }];
      case "feed_date":
        return [{ field: "feed_date", sort: "asc" }];
      case "-feed_date":
        return [{ field: "feed_date", sort: "desc" }];
      case "feed_type":
        return [{ field: "feed_type", sort: "asc" }];
      case "-feed_type":
        return [{ field: "feed_type", sort: "desc" }];
      default:
        return [];
    }
  };

  const sortModel: GridSortModel = getSortModel();

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const sortingField = sortModel[0] ? sortModel[0].field : "";
    if (!sortingField) {
      changeFilters(new URLSearchParams([["ordering", ""]]));
      return;
    }
    let orderingParam =
      sortingField === "supplier_name" ? "supplier__name" : sortingField;
    const sortingOrder = sortModel[0].sort;
    orderingParam =
      sortingOrder === "desc" ? "-" + orderingParam : orderingParam;
    changeFilters(new URLSearchParams([["ordering", orderingParam]]));
  };

  return (
    <SupplierContext.Provider value={allSuppliers}>
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" onChange={searchHandler} />
        <ToolboxArea>
          <AddNewSupplierButton />
        </ToolboxArea>
      </Box>
      <Filter viewName="procurement_feeds" supplierInfoRequired={true}>
        <AvailableFilters />
      </Filter>
      <GridContainer>
        <DataGrid
          loading={loading}
          rows={rows}
          columns={supplierFeedsTableColumns}
          rowHeight={40}
          columnHeaderHeight={40}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          pagination
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
        />
      </GridContainer>
      {displayWarning && <SupplierAlert supplierNames={suppliersWithWarning} />}
    </SupplierContext.Provider>
  );
};
export default ProcurementsAndSupplierFeedsPage;
export { SupplierContext };
