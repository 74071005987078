import React from "react";
import { ReactNode, useEffect, useRef, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";

interface IProps {
  label: string;
  children?: ReactNode;
  forceToggleExpanded?: number;
}

const EditAccordion = ({
  label,
  children,
  forceToggleExpanded,
}: IProps): JSX.Element => {
  const [expanded, setExpanded] = useState(true);
  const [showChildren, setShowChildren] = useState(false);
  const theme = useTheme();
  const displayTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    if (displayTimeoutRef.current) {
      clearTimeout(displayTimeoutRef.current);
    }

    if (forceToggleExpanded) {
      setExpanded(forceToggleExpanded > 0);

      if (forceToggleExpanded > 0) {
        setShowChildren(true);
      } else {
        displayTimeoutRef.current = window.setTimeout(() => {
          setShowChildren(false);
        }, 400);
      }
    }
  }, [forceToggleExpanded]);

  const toggleExpanded = () => {
    if (displayTimeoutRef.current) {
      clearTimeout(displayTimeoutRef.current);
    }
    if (expanded) {
      // delay removing children to keep animations smooth
      displayTimeoutRef.current = window.setTimeout(() => {
        setShowChildren(false);
      }, 400);
    } else {
      setShowChildren(true);
    }
    setExpanded((prev) => !prev);
  };

  return (
    <>
      <Divider />
      <Accordion
        expanded={expanded}
        square
        disableGutters
        elevation={0}
        onChange={toggleExpanded}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: expanded ? theme.palette.primary.main : "black",
              }}
            />
          }
          sx={{
            color: expanded ? theme.palette.primary.main : "black",
            flexDirection: "row-reverse",
            paddingLeft: 0,
            gap: "0.5rem",
          }}
        >
          <Typography
            sx={{
              textTransform: "uppercase",
            }}
          >
            {label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ height: "100%" }}>
          {showChildren ? children : null}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default EditAccordion;
