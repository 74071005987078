import React, { useCallback } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import useAppDispatch from "hooks/useAppDispatch";
import { shallowEqual, useSelector } from "react-redux";
import {
  selectProductValues,
  setShowNotification,
  setUpdateMessage,
} from "store/catalog-bulk-description";
import {
  fetchBulkDescriptionProducts,
  updateOnlyBulkEditFixedDescription,
} from "store/catalog-bulk-description/thunk";

const EditForBulkEditFixedDescription = ({ params }) => {
  const dispatch = useAppDispatch();
  const selectedRowValue = useSelector(selectProductValues, shallowEqual);
  const productId = selectedRowValue?.id;

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const checker = e.target.value === "true" ? true : false;

    const newValue = {
      ...selectedRowValue,
      bulk_edit_fixed_descriptions: checker,
    };

    dispatch(
      updateOnlyBulkEditFixedDescription({ id: productId, params: newValue }),
    ).then(() => {
      dispatch(setUpdateMessage("Update Successful."));
      dispatch(setShowNotification(true));
    });
    dispatch(fetchBulkDescriptionProducts({}));
  }, []);

  return (
    <FormControl fullWidth required>
      <RadioGroup
        defaultValue={params.row?.bulk_edit_fixed_descriptions}
        onChange={(e) => handleChange(e)}
      >
        <FormControlLabel value="true" control={<Radio />} label="Yes" />
        <FormControlLabel value="false" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
};

export default EditForBulkEditFixedDescription;
