import { GridRowsProp } from "@mui/x-data-grid";
import { ISupplierFeeds } from "store/procurement-feeds/interface";
import { ISupplierList } from "store/procurement-supplierList/interfaces";

// function to compare feeds based on reverse order of creation date
export const compareFeeds = (
  feed1: ISupplierFeeds,
  feed2: ISupplierFeeds,
): number => (new Date(feed1.created_at) > new Date(feed2.created_at) ? -1 : 1);

// function to get the supplier names whose last procurement feed is older than expected
export const getSuppliersWithWarning = (
  supplierList: ISupplierList[],
  feedList: ISupplierFeeds[],
): string[] => {
  const supplierAlertList: string[] = [];
  if (supplierList.length !== 0) {
    // if feed list is empty, warn for every supplier
    if (feedList.length === 0) {
      return supplierList
        .filter(
          (supplier) => supplier.warning_level_days_between_feed_updates > 0,
        )
        ?.map((supplier) => supplier.name);
    }
    const currentDateMillis = new Date().valueOf();
    supplierList.forEach((supplier) => {
      const warningDays = supplier.warning_level_days_between_feed_updates;
      if (warningDays > 0) {
        const supplierFeeds = feedList.filter(
          (feed) => feed.supplier === supplier.id,
        );

        if (supplierFeeds.length > 0) {
          // sort feeds in reverse order of creation date
          const latestSupplierFeedDateMillis = new Date(
            supplierFeeds.sort(compareFeeds)[0].created_at,
          ).valueOf();
          const differenceInDays =
            (currentDateMillis - latestSupplierFeedDateMillis) /
            (1000 * 60 * 60 * 24);
          if (differenceInDays > warningDays) {
            supplierAlertList.push(supplier.name);
          }
        }
      }
    });
  }
  return supplierAlertList;
};

export const createRows = (
  supplierList: ISupplierList[],
  feedList: ISupplierFeeds[],
): GridRowsProp => {
  if (feedList.length === 0) {
    return [];
  }
  return feedList?.map((feed) => {
    return {
      id: feed.id,
      supplier_name: feed.supplier_info.name,
      feed_date: feed.created_at,
      feed_type: feed.feed_type,
      duration_feed: feed.duration_feed,
      duration_total: feed.duration_total,
      user_warning: feed.user_warning,
      admin_warning: feed.admin_warning,
      statistics_no_request: feed.statistics_no_request,
      statistics_no_category_urls: feed.statistics_no_category_urls,
      statistics_no_product_urls: feed.statistics_no_product_urls,
      statistics_no_product_skus: feed.statistics_no_product_skus,
      statistics_no_errors: feed.statistics_no_errors,
      statistics_no_warnings: feed.statistics_no_warnings,
      warning_level_days_between_feed_updates:
        supplierList.length !== 0
          ? supplierList.find(
              (supplier) => supplier.id === feed.supplier_info.id,
            )?.warning_level_days_between_feed_updates
          : null,
    };
  });
};
