import { createAsyncThunk } from "@reduxjs/toolkit";
import procurementSup from "services/notification-system";
import {
  INotificationItemDTO,
  IfetchNotificationItemParams,
} from "./interfaces";

export const fetchNotificationItemAsync = createAsyncThunk(
  "NotificationItem/fetchNotificationItemAsync",
  async (
    params: IfetchNotificationItemParams,
  ): Promise<INotificationItemDTO> => {
    const res = await procurementSup.service().fetchNotificationItem(params);
    return res;
  },
);

export const fetchNotificationItemTopBarAsync = createAsyncThunk(
  "NotificationItem/fetchNotificationItemTopBarAsync",
  async (
    params: IfetchNotificationItemParams,
  ): Promise<INotificationItemDTO> => {
    const res = await procurementSup.service().fetchNotificationItem(params);
    return res;
  },
);

export const fetchNotificationCount = createAsyncThunk(
  "NotificationItem/fetchNotificationCount",
  async (
    params: IfetchNotificationItemParams,
  ): Promise<INotificationItemDTO> => {
    const res = await procurementSup.service().fetchNotificationItem(params);
    return res;
  },
);
