import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISelectedFilterStocktaking } from "../../interfaces";

import { RootState } from "../index";
import { IFilterStocktakingProductsSliceState, IFilters } from "./interfaces";

const initialState: IFilterStocktakingProductsSliceState = {
  selectedFilters: [],
  filters: {
    byLocation: {
      param: "by_location",
    },
    byProduct: {
      param: "by_product",
    },
    stocktakingDoneBefore: {
      param: "stocktaking_done_before",
    },
  },
};

const filterStocktakingProductsSlice = createSlice({
  name: "filterStocktakingProducts",
  initialState,
  reducers: {
    setSelectedFilter: (
      state,
      action: PayloadAction<ISelectedFilterStocktaking>,
    ) => {
      const filterToReplace = state.selectedFilters.find(
        (f) => f.label === action.payload.label,
      );

      if (filterToReplace) {
        state.selectedFilters[state.selectedFilters.indexOf(filterToReplace)] =
          action.payload;
      } else {
        state.selectedFilters.push(action.payload);
      }
    },
    removeSelectedFilterByLabel: (state, action?: PayloadAction<string>) => {
      const filterToRemove = state.selectedFilters.find(
        (f) => f.label === action?.payload,
      );
      if (filterToRemove) {
        state.selectedFilters.splice(
          state.selectedFilters.indexOf(filterToRemove),
          1,
        );
      }
    },
  },
});

export const selectFilters = (state: RootState): IFilters =>
  state.filterStocktakingProducts.filters;
export const selectSelectedFilters = (
  state: RootState,
): ISelectedFilterStocktaking[] =>
  state.filterStocktakingProducts.selectedFilters;

export const { setSelectedFilter, removeSelectedFilterByLabel } =
  filterStocktakingProductsSlice.actions;

export default filterStocktakingProductsSlice.reducer;
