import React from "react";
import { useMemo } from "react";

import { selectCurrentStep } from "store/onboarding";
import useAppSelector from "hooks/useAppSelector";
import HandledFooter from "./HandledFooter";

interface IFooterProps {
  validate?(): boolean;
}

const DefaultFooter = ({ validate }: IFooterProps): JSX.Element => {
  const currentStep = useAppSelector(selectCurrentStep);
  const lastInteractiveStep = useMemo(() => currentStep === 1, [currentStep]);
  return (
    <HandledFooter
      validate={validate}
      next={lastInteractiveStep ? "Create my account" : "Continue"}
      previous={"Go back"}
    />
  );
};

export default DefaultFooter;
