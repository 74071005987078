import React, { useCallback, useEffect, useState } from "react";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { useSearchParams } from "react-router-dom";
import {
  selectFileUrlsListState,
  setUpdateMessage,
} from "store/catalog-file-urls";
import {
  deleteFileUrlsAsync,
  fetchFileUrlsListAsync,
} from "store/catalog-file-urls/thunks";
import { GridSortModel } from "@mui/x-data-grid-pro";
import useChangeFilters from "hooks/useChangeFilters";

// Data fetching and handling selection
const useFileUrls = ({
  rowSelectionModel,
  setShowNotification,
}: {
  rowSelectionModel: any;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const changeFilters = useChangeFilters();
  const { results } = useAppSelector(selectFileUrlsListState);
  const [loading, setLoading] = useState(true); // Loading state
  const [searchParams] = useSearchParams();
  const [localResults, setLocalResults] = useState(results);

  const getSortModel = (): GridSortModel => {
    if (!searchParams.has("ordering")) return [];
    switch (searchParams.get("ordering")) {
      case "title":
        return [{ field: "title", sort: "asc" }];
      case "-title":
        return [{ field: "title", sort: "desc" }];
      case "url":
        return [{ field: "url", sort: "asc" }];
      case "-url":
        return [{ field: "url", sort: "desc" }];
      case "source_url":
        return [{ field: "source_url", sort: "asc" }];
      case "-source_url":
        return [{ field: "source_url", sort: "desc" }];
      default:
        return [];
    }
  };
  const sortModel: GridSortModel = getSortModel();
  const handleSortModelChange = (sortModel: GridSortModel) => {
    let orderingParam = sortModel[0] ? sortModel[0].field : "";
    const sortingOrder = sortModel[0] && sortModel[0].sort;
    orderingParam =
      sortingOrder === "desc" ? "-" + orderingParam : orderingParam;
    changeFilters(new URLSearchParams([["ordering", orderingParam]]));
  };

  useEffect(() => {
    setLoading(true); // Start loading indicator
    dispatch(fetchFileUrlsListAsync(searchParams))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch, searchParams]);

  const handleDelete = useCallback(async () => {
    const ids: number[] = rowSelectionModel;

    await dispatch(deleteFileUrlsAsync({ ids }));

    const updatedResults = localResults.filter(
      (item) => !ids.includes(item.id),
    );
    setLocalResults(updatedResults);

    dispatch(setUpdateMessage("Successfuly Deleted!"));

    setShowNotification(true);
  }, [dispatch, rowSelectionModel, searchParams, results]);

  return {
    localResults,
    loading,
    sortModel,
    setUpdateMessage,
    handleSortModelChange,
    handleDelete,
  };
};

export default useFileUrls;
