import React from "react";
import { View, StyleSheet, Text, Svg, Rect } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  leftColumn: {
    flex: 1,
  },
  footerFont: {
    display: "flex",
    flexDirection: "row",
    fontSize: 9,
    padding: 6,
  },
  fontBold: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 9,
  },
});

const addTax = (tax: string, total: string) => {
  const discount_incl_vat = parseInt(tax) + parseInt(total);
  return discount_incl_vat;
};

const OrderPDFFooterComponent = ({ companyData, order }: any): JSX.Element => {
  return (
    <>
      {addTax(order.discount_total, order.discount_tax) > 0 && (
        <View
          style={{
            color: "black",
            textAlign: "center",
            marginBottom: 10,
            fontSize: 9,
          }}
        >
          <Text>
            {`Du fick rabatt på din beställning med ${addTax(
              order.discount_total,
              order.discount_tax,
            )} kr inkl moms`}
          </Text>
        </View>
      )}
      <View
        style={{
          color: "black",
          textAlign: "center",
          marginBottom: 10,
          fontSize: 9,
        }}
      >
        <Text>{companyData.packing_slip_extra_content1} </Text>
      </View>
      <View
        style={{
          color: "black",
          textAlign: "center",
          marginBottom: 14,
          fontSize: 9,
        }}
      >
        <Text>{companyData.packing_slip_extra_content2}</Text>
      </View>
      <View wrap={false}>
        <View style={{ marginBottom: 14 }}>
          <Svg viewBox="0 0 120 2">
            <Rect
              width="100%"
              height="20"
              fill={
                companyData?.branding_background_color
                  ? companyData?.branding_background_color
                  : "rgb(10,55,81)"
              }
            />
          </Svg>
        </View>
        <View style={styles.footerFont}>
          <View style={styles.leftColumn}>
            <Text style={styles.fontBold}>{"Address"}</Text>
            <Text>{`${companyData.company_street_address1} ${companyData.company_street_address2}
      ${companyData.company_postcode} ${companyData.company_city}
      ${companyData.company_state} ${companyData.company_country}`}</Text>
          </View>
          <View style={styles.leftColumn}>
            <Text style={styles.fontBold}>{`Phone number: `}</Text>
            <Text>{`${companyData.company_phonenumber}`}</Text>
            <Text style={styles.fontBold}>{`Email: `}</Text>
            <Text>{`${companyData.company_email_address}`}</Text>
          </View>
          <View style={styles.leftColumn}>
            <Text style={styles.fontBold}>{`Company registration no: `}</Text>
            <Text>{`${companyData.company_vat_number}`}</Text>
            <Text style={styles.fontBold}>{`Homepage: `}</Text>
            <Text>{`${companyData.company_homepage}`}</Text>
          </View>
          <View style={styles.leftColumn}>
            <Text>{`${companyData.packing_slip_footer_right}`}</Text>
          </View>
          <Text break></Text>
        </View>
      </View>
    </>
  );
};

export default OrderPDFFooterComponent;
