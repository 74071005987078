import React from "react";
import FilterSelectBinary from "components/filter-products/FilterSelectBinary";
import FilterSelectMultiple from "components/filter-products/FilterSelectMultiple";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { useEffect, useState } from "react";
import { selectSupplierListState } from "store/procurement-supplierList";
import { fetchSupplierListAsync } from "store/procurement-supplierList/thunks";

const AvailableFilters = (): JSX.Element => {
  const { results } = useAppSelector(selectSupplierListState);
  const dispatch = useAppDispatch();
  const [suppliers, setSuppliers] = useState<[string, string][]>([]);

  useEffect(() => {
    dispatch(fetchSupplierListAsync(new URLSearchParams()));
  }, [dispatch]);

  useEffect(() => {
    setSuppliers(
      results?.map(
        (supplier) =>
          [supplier.name, supplier.id.toString()] as [string, string],
      ),
    );
  }, [results]);

  return (
    <>
      <FilterSelectMultiple
        label="Supplier"
        param={"supplier"}
        options={suppliers}
      />
      <FilterSelectBinary
        label="Removed from source"
        param={"removed_from_source"}
        inverted={true}
      />
    </>
  );
};

export default AvailableFilters;
