import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import useAppDispatch from "hooks/useAppDispatch";
import { IInventoryTransaction } from "store/inventory-transactions/interfaces";

//For future use
// const EditQuantity = (row: any) => {
//   const { selectedRow } = useAppSelector(selectInventoryTransactionsState);
//   const dispatch = useAppDispatch();
//   const [inputValue, setInputValue] = useState(row.row.value);
//   const [searchParams] = useSearchParams();

//   const handleInputChange = useCallback((event: any) => {
//     setInputValue(event.target.value);
//   }, []);
//   const onDescriptionChangeBlur = async () => {
//     if (
//       row.row.id === selectedRow.id &&
//       !!selectedRow &&
//       selectedRow[row.row.field] !== undefined &&
//       inputValue !== undefined &&
//       selectedRow[row.row.field] !== inputValue
//     ) {
//       await dispatch(
//         updateInventoryTransactionAsync({
//           ...selectedRow,
//           [row.row.field]: inputValue,
//         }),
//       )
//         .unwrap()
//         .finally(() => {
//           dispatch(
//             displaySnackbar({
//               message: "Product updated successfully",
//             } as ISnackbarSliceState),
//           );
//         });
//       dispatch(fetchInventoryTransactionsAsync(searchParams));
//     }
//   };
//   return (
//     <div>
//       <TextField
//         type="text"
//         value={inputValue ?? ""}
//         onChange={handleInputChange}
//         onBlur={onDescriptionChangeBlur}
//       />
//     </div>
//   );
// };

const ColumnsForDataGrid = (
  updateGrid: any,
  categories: IInventoryTransaction[],
  loading: boolean,
): GridColDef[] => {
  const dispatch = useAppDispatch();

  const columns: GridColDef[] = [
    {
      field: "product",
      flex: 1,
      headerName: "Product",
      sortable: false,
    },
    {
      field: "location",
      flex: 1,
      headerName: "Location",
      sortable: false,
    },
    {
      field: "accumulated_quantity",
      flex: 1,
      headerName: "Accumulated Quantity",
      sortable: false,
    },
    {
      field: "inventory_action",
      flex: 1,
      headerName: "Action",
      sortable: false,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 0.5,
      sortable: false,
      pinnable: true,
      minWidth: 70,
      maxWidth: 120,
      width: 100,
      // renderCell: (params: GridRenderCellParams) => {
      //   return <EditQuantity row={params} />;
      // },
    },
    {
      field: "comments",
      flex: 1,
      headerName: "Comments",
      sortable: false,
    },
    {
      field: "proxy_transaction",
      flex: 1,
      headerName: "Proxy Transaction",
      sortable: false,
    },
    {
      field: "unproxy_transaction",
      flex: 1,
      headerName: "Unproxy Transaction",
      sortable: false,
    },
    {
      field: "created_at",
      flex: 1,
      headerName: "Created at",
      sortable: false,
    },
    {
      field: "parent",
      flex: 1,
      headerName: "Parent Transaction",
      sortable: false,
    },
  ];

  return columns;
};

export default ColumnsForDataGrid;
function useAppSelector(selectInventoryTransactionsState: any): {
  selectedRow: any;
} {
  throw new Error("Function not implemented.");
}
