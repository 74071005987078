import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface NetworkErrorDialogProps {
  open: boolean;
  retryInterval: number;
  askUser: boolean;
  handleYes: () => void;
  handleNo: () => void;
}

const NetworkErrorDialog = ({
  open,
  retryInterval,
  askUser,
  handleYes,
  handleNo,
}: NetworkErrorDialogProps): JSX.Element => {
  // countdown timer in seconds
  const [countdown, setCountdown] = useState<number>(retryInterval);
  useEffect(() => {
    if (!askUser) {
      const countdownInterval = setInterval(() => {
        setCountdown((countdown) => countdown - 1);
      }, 1000);
      return () => {
        clearInterval(countdownInterval);
      };
    }
    return undefined;
  }, [askUser, retryInterval]);

  return (
    <Dialog
      open={open}
      aria-labelledby="network-dialog-title"
      aria-describedby="network-dialog-description"
    >
      <DialogTitle
        id="network-dialog-title"
        sx={{ textTransform: "capitalize", textAlign: "center" }}
      >
        {askUser ? "Server Down" : "Edit Failed"}
      </DialogTitle>
      <DialogContent
        sx={{
          a: {
            textDecoration: "none",
          },
        }}
      >
        <DialogContentText id="alert-dialog-description">
          {askUser ? (
            <>The server seems to be down. Do you want to keep trying?</>
          ) : (
            <>
              A Network Error occurred, retrying in {countdown} seconds.
              <span style={{ fontWeight: "bold" }}>
                {" "}
                PLEASE DO NOT CLOSE THIS TAB
              </span>
              , otherwise the changes made to the product(s) will be lost.
            </>
          )}
        </DialogContentText>
        {askUser && (
          <DialogActions>
            <Button onClick={handleYes}>Yes</Button>
            <Button onClick={handleNo}>No</Button>
          </DialogActions>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default NetworkErrorDialog;
