import React from "react";
import { ReactNode } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Button,
  DialogActions,
} from "@mui/material";

interface ModalProps {
  title: string;
  subtitle?: string;
  children: ReactNode;
  isOpen: boolean;
  handleClose: () => void;
}

const Modal = ({
  title,
  subtitle,
  children,
  isOpen,
  handleClose,
}: ModalProps): JSX.Element => {
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xl">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{subtitle}</DialogContentText>
        <Divider />
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
