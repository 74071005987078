import React from "react";
import { Link } from "@mui/material";

interface Props {
  href: any;
}

const ImageLink = ({ href }: Props) => {
  return (
    <Link target="_blank" href={href}>
      {href}
    </Link>
  );
};

export default ImageLink;
