export const catalogColumnsArr = [
  {
    field: "image_urls",
    flex: 1,
    headerName: "Image",
    sortable: false,
    pinnable: true,
  },
  { field: "sku", flex: 1, headerName: "SKU", sortable: true, pinnable: true },
  {
    field: "name",
    flex: 2,
    headerName: "Product name",
    sortable: true,
    pinnable: true,
  },
  {
    field: "qty_in_stock",
    flex: 1,
    headerName: "Stock",
    sortable: true,
    pinnable: true,
  },
  {
    field: "cost_shipping",
    flex: 1,
    headerName: "Cost for shipping",
    sortable: true,
    pinnable: true,
  },
  {
    field: "price_manual_override",
    flex: 1,
    headerName: "Price (incl VAT)",
    sortable: true,
    pinnable: true,
  },
  {
    field: "price_on_sale",
    flex: 1,
    headerName: "Sale price (incl VAT)",
    sortable: true,
    pinnable: true,
  },
  { field: "url", flex: 1, headerName: "Link", sortable: true, pinnable: true },
];
