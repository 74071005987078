import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import {
  FilterType,
  ICatalogProductResponseDTO,
  IProductCount,
  IProductType,
  IPublishedProductsExport,
  IPublishProductsDTO,
  IUpdateProductsDTO,
  ICatalogProductDescription,
  ICatalogProductsDescription,
} from "store/catalog/interfaces";
import { endpoint } from "constants/endpoint";

export interface ICatalogService {
  fetchProductCount(filter: FilterType): Promise<IProductCount>;
  fetchCatalogProducts(
    queryParams: string,
  ): Promise<ICatalogProductResponseDTO>;
  fetchCatalogProductsWithIds(
    ids: number[],
  ): Promise<ICatalogProductResponseDTO>;
  fetchProductTypes(): Promise<IProductType[]>;
  publishProducts(
    publishProductsDto: IPublishProductsDTO,
  ): Promise<IPublishedProductsExport>;
  updateProducts(updateProductsDto: IUpdateProductsDTO): Promise<void>;
  deleteProducts(ids: number[]): Promise<void>;
  fetchProductDescription(
    queryParams: string,
  ): Promise<ICatalogProductDescription>;
  fetchCatalogProductsDescription(): Promise<ICatalogProductsDescription>;
  updateProductDescription(
    updateProductDescription: ICatalogProductDescription,
  ): Promise<void>;
}

export default new Mockable<ICatalogService>({
  async fetchProductCount(filter: FilterType): Promise<IProductCount> {
    const res = await axios(
      `${config.api}${endpoint.catalog_product_count}?f=${filter}`,
      {
        headers: commonHeaders(),
      },
    );
    if (res.status === 200) {
      return { type: filter, ...res.data };
    }

    return Promise.reject("Couldn't get catalog product count");
  },
  async fetchProductDescription(
    queryParams: string,
  ): Promise<ICatalogProductDescription> {
    const res = await axios(
      `${config.api}${endpoint.product_description}?${queryParams}`,
      {
        headers: commonHeaders(),
      },
    );
    if (res.status === 200) {
      return res.data;
    }

    return Promise.reject("Couldn't get catalog product count");
  },
  async fetchCatalogProductsDescription(): Promise<ICatalogProductsDescription> {
    const res = await axios(
      `${config.api}${endpoint.catalogProduct_description}`,
      {
        headers: commonHeaders(),
      },
    );
    if (res.status === 200) {
      return res.data;
    }

    return Promise.reject("Couldn't get catalog product count");
  },
  async fetchCatalogProducts(
    queryParams: string,
  ): Promise<ICatalogProductResponseDTO> {
    try {
      const res = await axios(
        `${config.api}${endpoint.catalog_products}?${queryParams}`,
        {
          headers: commonHeaders(),
        },
      );

      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async fetchCatalogProducts <<",
          ),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },

  async fetchCatalogProductsWithIds(
    ids: number[],
  ): Promise<ICatalogProductResponseDTO> {
    try {
      const res = await axios(
        `${config.api}${endpoint.catalog_products}?id__in=${ids.join(",")}`,
        {
          headers: commonHeaders(),
        },
      );

      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async fetchCatalogProductsWithIds <<",
          ),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },

  async fetchProductTypes(): Promise<IProductType[]> {
    const res = await axios(`${config.api}${endpoint.catalog_products_types}`, {
      headers: commonHeaders(),
    });

    if (res.status === 200) {
      return res.data;
    }

    return Promise.reject("Couldn't get product types data");
  },

  async publishProducts(
    publishProductsDto: IPublishProductsDTO,
  ): Promise<IPublishedProductsExport> {
    const res = await axios(
      `${config.api}${endpoint.catalog_products_publish}`,
      {
        method: "POST",
        headers: commonHeaders(),
        data: JSON.stringify(publishProductsDto),
      },
    );

    if (res.status === 200) {
      return res.data;
    }

    return Promise.reject("Couldn't publish products");
  },

  async updateProducts(updateProductsDto: IUpdateProductsDTO): Promise<void> {
    try {
      const res = await axios.put(
        `${config.api}${endpoint.catalog_products}`,
        updateProductsDto,
        {
          headers: commonHeaders(),
        },
      );

      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error("Could not update product in >> async updateProducts <<"),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
  async updateProductDescription(
    updateProductDescription: ICatalogProductDescription,
  ): Promise<void> {
    const res = await axios.put(
      `${config.api}${endpoint.product_description}${updateProductDescription.id}/`,
      updateProductDescription,
      {
        headers: commonHeaders(),
      },
    );
    if (res.status !== 200) {
      Promise.reject("Couldn't update products");
    }
  },
  async deleteProducts(ids: number[]): Promise<void> {
    const res = await axios.delete(
      `${config.api}${endpoint.catalog_products}`,
      {
        method: "DELETE",
        headers: commonHeaders(),
        data: { products: ids },
      },
    );
    if (res.status !== 200) {
      Promise.reject("Couldn't delete products");
    }
  },
});
