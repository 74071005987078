import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { UnPickOrder } from "./interfaces";
import { fetchUnPickOrderAsync } from "./thunks";
import { fetchOrdersAsync } from "store/orders/thunks";

export const initialState: UnPickOrder = {
  message: "",
  loading: false,
  unpickOrderId: 0,
  error: "",
};

export const unpickOrderSlice = createSlice({
  name: "unpickOrder",
  initialState,
  reducers: {
    clearunPickOrderMessage: (state) => {
      state.message = "";
      state.error = "";
    },
    setUnpickOrdertId: (state, action) => {
      state.unpickOrderId = action.payload.orderId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUnPickOrderAsync.fulfilled, (state, action) => {
      state.message = action.payload?.result;
      state.loading = false;
    });
    builder.addCase(fetchUnPickOrderAsync.pending, (state) => {
      state.loading = true;
      state.message = "";
      state.error = "";
    });
    builder.addCase(fetchUnPickOrderAsync.rejected, (state, { error }) => {
      if (error.message) state.error = error.message;
      state.loading = false;
    });
    builder.addCase(fetchOrdersAsync.fulfilled, (state) => {
      state.unpickOrderId = 0;
    });
  },
});

export const selectUnpickOrderState = (state: RootState): UnPickOrder =>
  state.unpickOrder;
export const { clearunPickOrderMessage, setUnpickOrdertId } =
  unpickOrderSlice.actions;

export default unpickOrderSlice.reducer;
