import { createAsyncThunk } from "@reduxjs/toolkit";
import { BulkDescriptionArgs, Product, ProductFilters } from "./interfaces";
import catalogBulkDescription from "services/catalog-bulk-description";

export const fetchBulkDescriptionProducts = createAsyncThunk(
  "products/fetchFilteredProducts",
  async (filters?: ProductFilters) => {
    const result = await catalogBulkDescription
      .service()
      .fetchFilteredProducts(filters);
    return result;
  },
);

export const addNewBulkDescription = createAsyncThunk(
  "products/addNewBulkDescription",
  async (params: BulkDescriptionArgs) => {
    const result = await catalogBulkDescription
      .service()
      .addBulkDescription(params);
    return result;
  },
);

export const updateBulkDescription = createAsyncThunk(
  "products/updateBulkDescription",
  async ({ id, params }: { id: number; params: BulkDescriptionArgs }) => {
    const result = await catalogBulkDescription
      .service()
      .updateBulkDescription(id, params);
    return result;
  },
);

export const updateOnlyBulkEditFixedDescription = createAsyncThunk(
  "products/updateBulkDescription",
  async ({ id, params }: { id: number; params: Product }) => {
    const result = await catalogBulkDescription
      .service()
      .updateOnlyBulkEditFixedDescription(id, params);
    return result;
  },
);
