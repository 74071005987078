import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import useChangeFilters from "hooks/useChangeFilters";
import { useSearchParams } from "react-router-dom";

const CustomPagination = ({ count }: any): JSX.Element => {
  const getPageFromOffset = (limit: number, offset: number): number =>
    offset / limit + 1;
  const getOffsetFromPage = (limit: number, page: number): number =>
    limit * (page - 1);
  const isPageInvalid = (page: number) =>
    isNaN(page) || page < 1 || page > numPages;

  const [searchParams, setSearchParams] = useSearchParams();
  const usesPage = searchParams.has("page");
  const changeFilters = useChangeFilters();
  const limitString = searchParams.get("limit")!;
  const limit = parseInt(limitString, 10);
  const numPages = Math.ceil(count / limit) ?? 2;
  const pageParam = searchParams.get("page")!;
  const offsetParam = searchParams.get("offset")!;

  const [page, setPage] = useState(
    usesPage
      ? parseInt(pageParam, 10)
      : getPageFromOffset(limit, parseInt(offsetParam, 10)),
  );
  useEffect(
    () =>
      setPage(
        searchParams.has("page")
          ? parseInt(pageParam, 10)
          : getPageFromOffset(limit, parseInt(offsetParam, 10)),
      ),
    [limit, searchParams],
  );

  // used by handleLimitChange, handePageChange, handlePrevious and handleNext, so that each can cancel the others' actions
  const pageChangeTimer = useRef<NodeJS.Timeout>();

  const handleLimitChange = (event: SelectChangeEvent): void => {
    clearTimeout(pageChangeTimer.current);
    const newLimit = event.target.value ?? "";
    changeFilters(new URLSearchParams([["limit", newLimit]]));
  };

  const handlePageChange = (event: ChangeEvent<HTMLInputElement>): void => {
    clearTimeout(pageChangeTimer.current);
    const pageString = event.target.value ?? "";
    const pageNumber = parseInt(pageString, 10);
    setPage(pageNumber);
    // change searchParams only if the page is in the valid range
    if (!isPageInvalid(pageNumber)) {
      pageChangeTimer.current = setTimeout(() => {
        const param = usesPage ? "page" : "offset";
        const value = usesPage
          ? pageString
          : getOffsetFromPage(limit, pageNumber).toString();
        searchParams.set(param, value);
        setSearchParams(searchParams);
      }, 1000);
    }
  };

  const handlePrevious = (): void => {
    clearTimeout(pageChangeTimer.current);
    const previousPage = page - 1;
    setPage(previousPage);
    pageChangeTimer.current = setTimeout(
      (previousPage: number) => {
        const param = usesPage ? "page" : "offset";
        const value = usesPage
          ? previousPage.toString()
          : getOffsetFromPage(limit, previousPage).toString();
        searchParams.set(param, value);
        setSearchParams(searchParams);
      },
      1000,
      previousPage,
    );
  };

  const handleNext = (): void => {
    clearTimeout(pageChangeTimer.current);
    const nextPage = page + 1;
    setPage(nextPage);
    pageChangeTimer.current = setTimeout(
      (nextPage: number) => {
        const param = usesPage ? "page" : "offset";
        const value = usesPage
          ? nextPage.toString()
          : getOffsetFromPage(limit, nextPage).toString();
        searchParams.set(param, value);
        setSearchParams(searchParams);
      },
      1000,
      nextPage,
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: "100%", padding: "0.5rem" }}
    >
      <FormControl variant="outlined" sx={{ minWidth: 80 }} size="small">
        <InputLabel id="select-page-size">Page Size</InputLabel>
        <Select
          value={limitString ?? ""}
          onChange={handleLimitChange}
          label="Page Size"
        >
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>
      <Box>
        <IconButton
          aria-label="previous"
          onClick={handlePrevious}
          disabled={page === 1 || isPageInvalid(page)}
        >
          <NavigateBefore />
        </IconButton>
        <TextField
          type="number"
          slotProps={{
            input: {
              minRows: 1,
              maxRows: numPages,
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end"> of {numPages}</InputAdornment>
            ),
          }}
          label="Page"
          value={isNaN(page) ? "" : page}
          variant="outlined"
          onChange={handlePageChange}
          error={page < 1 || page > numPages}
          size="small"
          sx={{
            'input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
              {
                WebkitAppearance: "none",
                margin: 0,
              },
            'input[type="number"]': {
              MozAppearance: "textfield",
              margin: 0,
            },
          }}
        />
        <IconButton
          aria-label="next"
          onClick={handleNext}
          disabled={page === numPages || isPageInvalid(page)}
        >
          <NavigateNext />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CustomPagination;
