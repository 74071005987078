import React from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { TextField } from "@mui/material";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "id",
    editable: false,
    hideable: true,
  },
  {
    field: "name",
    headerName: "Full name slug",
    width: 200,
    editable: false,
  },
  {
    field: "abbreviation_slug",
    headerName: "Abbrevation slug",
    width: 200,
    editable: false,
  },
  {
    field: "content",
    headerName: "Content",
    editable: true,
    width: 200,
    display: "flex",
    renderCell: (params) => (
      <ToolInfoTooltip title="Click to edit">
        <TextField
          value={params.value}
          size="small"
          aria-label="content text field"
        />
      </ToolInfoTooltip>
    ),
  },
  {
    field: "comment",
    headerName: "Comment",
    width: 200,
    display: "flex",
    editable: true,
    renderCell: (params) => (
      <ToolInfoTooltip title="Click to edit">
        <TextField
          value={params.value}
          size="small"
          aria-label="content text field"
        />
      </ToolInfoTooltip>
    ),
  },
  {
    field: "not_for_stock",
    headerName: "Not for stock ",
    width: 100,
    editable: true,
    // renderCell: RenderCheckBox,
  },
  {
    field: "stocktaking_perfomed_at",
    headerName: "Last date of stocktaking for location ",
    width: 220,
    editable: false,
  },
];

export default columns;
