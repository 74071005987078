import React from "react";
import { Typography } from "@mui/material";
import { StyledLink } from "pages/main-page/pick-pack-order-page/parts/Row";

export const customerTableColumns = [
  {
    field: "order_number",
    headerName: "#Order",
    renderCell: (params: { row: { order_number: string } }): JSX.Element => {
      return (
        <StyledLink
          to={`/customers/orders/edit?order=${params.row?.order_number}`}
        >
          {params.row.order_number}
        </StyledLink>
      );
    },
  },
  {
    field: "order_created",
    headerName: "Date of order",
    minWidth: 180,
    renderCell: (params: { row: { order_created: string } }): JSX.Element => {
      const order_date = new Date(params.row.order_created);
      return (
        <strong>
          {order_date.getFullYear()}-{order_date.getMonth() + 1}-
          {order_date.getDate()}
        </strong>
      );
    },
  },
  {
    field: "internal_order_status",
    headerName: "Status",
    minWidth: 150,
    renderCell: (params: { row: { internal_order_status: string } }) =>
      params.row.internal_order_status,
  },
  {
    field: "shipping_method",
    headerName: "Shipping method",
    renderCell: (params: {
      row: { order_notes: string; shipping_method: string };
    }): JSX.Element => {
      return (
        <>
          {params.row.shipping_method ? params.row.shipping_method : "Unknown"}
          {params.row.order_notes && (
            <Typography
              color="warning.main"
              fontSize={12}
              textTransform="lowercase"
            >
              Order Notes: {params.row.order_notes}
            </Typography>
          )}
        </>
      );
    },
    minWidth: 190,
  },
  {
    field: "payment_method_details",
    headerName: "Payment method",
    width: 200,
  },
];
