import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { StyledLink } from "pages/main-page/pick-pack-order-page/parts/Row";
import React from "react";

export const procurementTableColumn: GridColDef[] = [
  {
    field: "id",
    headerName: "Order Number",
    width: 200,

    renderCell: (params: {
      row: {
        internal_order_number: number;
        supplier_data: any;
        order_number: string;
      };
    }): JSX.Element => {
      return (
        <StyledLink
          to={`/procurement/orders/edit?order=${params.row.internal_order_number}`}
        >
          <Typography style={{ margin: 0 }}>
            {params.row.internal_order_number} |{params.row.supplier_data.name}{" "}
            | {params.row.order_number}
          </Typography>
        </StyledLink>
      );
    },
  },
  {
    field: "createdAt",
    type: "date",
    headerName: "Created Dates",
    width: 200,
    renderCell: (params: { row: { estimated_delivery_date: string } }): any =>
      new Date(params.row.estimated_delivery_date).toDateString(),
  },
  {
    field: "deliveries",
    headerName: "Deliveries",
    width: 200,
    // renderCell: (params: { row: { is_open: boolean } }): any => {
    //   return <>{params.row.is_open}</>;
    // },
  },

  { field: "not_delivered", headerName: "Not Delivered  ", width: 200 },

  { field: "comments", headerName: "Comments", width: 200 },
];
