import axios from "axios";
import config from "../../../config";
import commonHeaders from "services/http/headers";
import { endpoint } from "constants/endpoint";

export async function fetchPickOrderAsyncSync(
  order_item_id: number,
  location_id: number,
  product_id: number,
  quantity: number,
): Promise<{ result: string }> {
  try {
    const res = await axios(
      `${config.api}${endpoint.customers_order_items}${order_item_id}/actions/pick/?location_id=${location_id}&product_id=${product_id}&quantity=${quantity}`,
      {
        headers: commonHeaders(),
      },
    );

    if (res.status === 200) {
      return res.data;
    } else {
      return {
        result: "fetchPickOrderAsyncSync failed with status" + res.status,
      };
    }
  } catch (err: any) {
    return Promise.reject(err);
  }
}
