import { createContext } from "react";

export interface ICreatedDate {
  at?: Date;
  from?: Date;
  to?: Date;
}

export interface IProcurementFeedsFilters {
  suppliers: number[];
  createdDate: ICreatedDate;
}

export interface IFilterProps {
  supplierIds: number[];
  supplierNames: string[];
}

export interface IAppliedFilter {
  label: string;
  value: string;
  extraValues?: string;
  queryToRemove: string;
}

export interface IAppliedFilters {
  supplier?: IAppliedFilter;
  created?: IAppliedFilter;
}

export const SupplierContext = createContext<[number, string][]>([]);
