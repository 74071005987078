import { TextFieldProps } from "@mui/material/TextField";

export const textFieldProps: TextFieldProps = {
  margin: "normal",
  size: "small",
  variant: "outlined",
  sx: {
    margin: "0",
  },
};

export const textFieldPropsFullWidth: TextFieldProps = {
  ...textFieldProps,
  fullWidth: true,
};
