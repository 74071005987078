import axios from "axios";
import config from "../../config";
import {
  ICustomersOrders,
  ICustomersOrdersResponseDTO,
  ICompanyDetailsResponseDTO,
} from "store/orders/interfaces";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import { IInventoryResponseDTO } from "store/inventories/interfaces";
import { IProcurementOrderProductsDTO } from "store/procurement-sources/interfaces";
import { IInventoryTransactionsDTO } from "store/inventory-transactions/interfaces";
import { endpoint } from "constants/endpoint";

export interface IOrdersService {
  fetchCustomersOrders(
    params: URLSearchParams,
  ): Promise<ICustomersOrdersResponseDTO>;
  fetchCustomersOrder(id: string): Promise<ICustomersOrders>;
  fetchInventories(params: string): Promise<IInventoryResponseDTO>;
  fetchProcurementSources(
    params: string,
  ): Promise<IProcurementOrderProductsDTO>;
  fetchInventoryTransactions(
    params: string,
  ): Promise<IInventoryTransactionsDTO>;
  fetchCompanyDetails(): Promise<ICompanyDetailsResponseDTO>;
}

export default new Mockable<IOrdersService>({
  async fetchCustomersOrders(
    params: URLSearchParams,
  ): Promise<ICustomersOrdersResponseDTO> {
    try {
      const res = await axios(`${config.api}${endpoint.customers_orders}`, {
        params,
        headers: commonHeaders(),
      });
      if (res.status === 200 && res.data) {
        return res.data;
      } else {
        return Promise.reject(
          new Error("Unexpected response format in fetchCustomersOrders"),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
  async fetchCustomersOrder(id: string): Promise<ICustomersOrders> {
    try {
      const res = await axios(
        `${config.api}${endpoint.customers_orders}?order_number=${id}`,
        {
          headers: commonHeaders(),
        },
      );
      if (res.status === 200) {
        return res.data.results[0];
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async fetchCatalogCategories(params) <<",
          ),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },

  async fetchInventories(params: string): Promise<IInventoryResponseDTO> {
    try {
      const res = await axios(
        `${config.api}${endpoint.catalog_products_inventories}?product_ids=${params}`,
        {
          headers: commonHeaders(),
        },
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error("Unexpected response format in fetchInventories"),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
  async fetchProcurementSources(
    params: string,
  ): Promise<IProcurementOrderProductsDTO> {
    try {
      const res = await axios(
        `${config.api}${endpoint.procurement_products_with_procurement_order_and_backorder_data}?product_ids=${params}`,
        {
          headers: commonHeaders(),
        },
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error("Unexpected response format in fetchProcurementSources"),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
  async fetchInventoryTransactions(params: string) {
    try {
      const res = await axios(
        `${config.api}${endpoint.catalog_products_inventories_transactions}?inventory_transaction_ids=${params}`,
        {
          headers: commonHeaders(),
        },
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error("Unexpected response format in fetchInventoryTransactions"),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
  async fetchCompanyDetails(): Promise<ICompanyDetailsResponseDTO> {
    try {
      const res = await axios(`${config.api}${endpoint.users_companies}`, {
        headers: commonHeaders(),
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error("Unexpected response format in fetchCompanyDetails"),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
});
