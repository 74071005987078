import { createAsyncThunk } from "@reduxjs/toolkit";
import procurementSup from "services/catalog-inventory-locations";
import {
  IRemoveInventoryLocationsListArgs,
  IInventoryLocations,
  AddInventoryLocationsListArgs,
  IUpdateListInventoryLocationsListParams,
  IUpdateInventoryLocationsListParams,
  IInventoryLocationsTypesList,
} from "./interfaces";

export const fetchInventoryLocationsListAsync = createAsyncThunk<
  IInventoryLocations,
  URLSearchParams
>("InventoryLocationsList/fetchInventoryLocationsListAsync", async (params) => {
  const res = await procurementSup
    .service()
    .fetchInventoryLocationsList(params);
  return res;
});

export const removeInventoryLocationsItemsAsync = createAsyncThunk(
  "InventoryLocationsList/RemoveInventoryLocationsItemsAsync",
  async (args: IRemoveInventoryLocationsListArgs) => {
    const { ids } = args;
    const res = await procurementSup
      .service()
      .deleteProcurementInventoryLocations(ids);
    return res;
  },
);
export const addInventoryLocationsItemsAsync = createAsyncThunk(
  "InventoryLocationsList/AddInventoryLocationsItemsAsync",
  async (params: AddInventoryLocationsListArgs) => {
    const res = await procurementSup
      .service()
      .addInventoryLocationsItems(params);
    return res;
  },
);

export const updateInventoryLocationsItemsAsync = createAsyncThunk(
  "InventoryLocationsList/updateInventoryLocationsItemsAsync",
  async (params: IUpdateInventoryLocationsListParams) => {
    const res = await procurementSup
      .service()
      .UpdateInventoryLocationsList(params);
    return res;
  },
);

export const updateListInventoryLocationsItemsAsync = createAsyncThunk(
  "InventoryLocationsList/updateListInventoryLocationsItemsAsync",
  async (params: IUpdateListInventoryLocationsListParams) => {
    const res = await procurementSup
      .service()
      .UpdateListInventoryLocationsList(params);
    return res;
  },
);

export const fetchTypes = createAsyncThunk<IInventoryLocationsTypesList>(
  "InventoryLocationsList/fetchTypes",
  async () => {
    const res = await procurementSup.service().fetchTypes();
    return res;
  },
);
