import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { IBookmark, IBookmarksSliceState } from "./interfaces";
import { fetchBookmarks } from "./thunks";

const initialState: IBookmarksSliceState = {
  loading: true,
  bookmarks: [],
};

const bookmarksSlice = createSlice({
  name: "bookmarks",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addBookmark: (state, action: PayloadAction<IBookmark>) => {
      state.bookmarks.push(action.payload);
    },
    reload: (state, action: PayloadAction<IBookmark>) => {
      const entry = state.bookmarks.find(
        (bookmark) =>
          bookmark.name === action.payload.name &&
          bookmark.view === action.payload.view,
      )!;
      const i = state.bookmarks.indexOf(entry);
      state.bookmarks[i] = action.payload;
    },
    removeBookmark: (state, action: PayloadAction<IBookmark>) => {
      state.bookmarks = state.bookmarks.filter(
        (bookmark) =>
          bookmark.name !== action.payload.name &&
          bookmark.view === action.payload.view,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBookmarks.fulfilled, (state, action) => {
      state.bookmarks = action.payload.bookmarks;
    });
  },
});

export const { setLoading, addBookmark, reload, removeBookmark } =
  bookmarksSlice.actions;

export const selectbookmarksState = (state: RootState): IBookmarksSliceState =>
  state.bookmarks;

export const selectbookmarks = (state: RootState): IBookmark[] =>
  state.bookmarks.bookmarks;

export default bookmarksSlice.reducer;
