import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { fetchSyncOrder1month } from "./thunks";
import { SyncOrder1month } from "./interfaces";

const initialState: SyncOrder1month = {
  message: "",
  loading: false,
};

export const syncOrderSlice1month = createSlice({
  name: "syncOrder1month",
  initialState,
  reducers: {
    clearSync1MonthMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSyncOrder1month.fulfilled, (state, action) => {
      state.message = action.payload?.message;
      state.loading = false;
    });
    builder.addCase(fetchSyncOrder1month.pending, (state, action) => {
      state.message = "";
      state.loading = true;
    });
  },
});

export const selectSyncOrderState1month = (state: RootState): SyncOrder1month =>
  state.ordersSync1month;
export const { clearSync1MonthMessage } = syncOrderSlice1month.actions;
export default syncOrderSlice1month.reducer;
