import React from "react";
import { useNavigate } from "react-router-dom";

import logo from "assets/images/EcomboosterLogo.png";

import { LOGIN, SUB_ROUTE_REGISTER } from "constants/urls";
import { Box, Button, CardMedia, Divider, Typography } from "@mui/material";

const OnboardingStart = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <>
      <Box>
        <CardMedia sx={{ marginBottom: "1rem" }}>
          <img width="170px" src={logo} />
        </CardMedia>
        <Typography variant="h4">
          Log in & register free demo account
        </Typography>
        <Typography>
          Already a customer? Log in below or create a new account to get a free
          demo version of Ecombooster (limited version). Are you a supplier or
          brand, please follow the steps to create a new account. If you have
          any questions, contact us!
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Button
          onClick={() => navigate(SUB_ROUTE_REGISTER)}
          variant="contained"
        >
          Create an account
        </Button>
        <Divider
          sx={{
            margin: "1rem 0 1.5rem",
            "> .MuiDivider-wrapper": {
              marginBottom: "-0.5rem",
              color: "subtle.main",
            },
          }}
        >
          or
        </Divider>
        <Button onClick={() => navigate(LOGIN)} variant="outlined">
          Log in
        </Button>
      </Box>
    </>
  );
};

export default OnboardingStart;
