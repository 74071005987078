import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { IBackOrder, ICustomersBackOrder } from "store/backorder/interfaces";
import { Mockable } from "..";
import { endpoint } from "constants/endpoint";

export interface IAddBackorderParams {
  company: number;
  order_item: number;
  quantity?: number;
  product: number;
  account: number;
  procurement_order_item: number | null;
}

export interface IBackOrdersService {
  fetchCustomerBackOrder(): Promise<IBackOrder>;
  addCustomerBackOrder(
    params: IAddBackorderParams,
  ): Promise<ICustomersBackOrder>;
  removeCustomerBackOrder(id: number): Promise<string>;
}

export default new Mockable<IBackOrdersService>({
  async fetchCustomerBackOrder(): Promise<IBackOrder> {
    try {
      const res = await axios(`${config.api}${endpoint.customers_backorders}`, {
        headers: commonHeaders(),
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async fetchCustomerBackOrder() <<",
          ),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },

  async addCustomerBackOrder(params): Promise<ICustomersBackOrder> {
    try {
      const res = await axios(`${config.api}${endpoint.customers_backorders}`, {
        method: "POST",
        headers: commonHeaders(),
        data: JSON.stringify(params),
      });
      if (res.status === 201) {
        return res.data;
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async addCustomerBackOrder(params) <<",
          ),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },

  async removeCustomerBackOrder(id): Promise<string> {
    try {
      const res = await axios(
        `${config.api}${endpoint.customers_backorders}${id}`,
        {
          method: "DELETE",
          headers: commonHeaders(),
        },
      );
      if (res.status === 204) {
        return "Deleted";
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async removeCustomerBackOrder(id) <<",
          ),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
});
