import React from "react";
import FilterDateRangeStacked from "components/filter-products/FilterDateRangeStacked";

const AvailableFilters = (): JSX.Element => {
  return (
    <>
      <FilterDateRangeStacked label="Created at" param="order_made" />
      <FilterDateRangeStacked label="Delivered at" param="order_delivered" />
    </>
  );
};

export default AvailableFilters;
