import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUnPickOrder } from "services/orders/unPickOrder";
import { RootState } from "../../index";
import { pickStateUpdate } from "store/orders";
import ordersService from "services/orders";
import { fetchInventoriesAsync } from "store/inventories/thunks";
import { ICustomersOrders, IOrderItems } from "../interfaces";

export const fetchUnPickOrderAsync = createAsyncThunk(
  "unpickOrder/fetchUnPickOrderAsync",
  async (
    params: { order_item_id: number; inventory_transaction_id: number },
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as RootState;

    const { unpickOrder, orders } = state;

    const res = await fetchUnPickOrder(
      params.order_item_id,
      params.inventory_transaction_id,
    );

    const searchParams = new URLSearchParams();
    searchParams.append("order_number", unpickOrder.unpickOrderId.toString());
    const { results } = await ordersService
      .service()
      .fetchCustomersOrders(searchParams);

    const changes = orders.orders?.map((order: ICustomersOrders) =>
      order.id === results[0].id ? results[0] : order,
    );

    if (res.result) {
      const orderIds: Array<number> = [];
      orders.orders.forEach((orders: ICustomersOrders) => {
        orders.order_items.forEach((order: IOrderItems) => {
          !orderIds.includes(order.product) && orderIds.push(order.product);
        });
      });

      await thunkAPI.dispatch(
        fetchInventoriesAsync(orderIds.length > 0 ? orderIds.toString() : "0"),
      );
      thunkAPI.dispatch(
        pickStateUpdate({
          changes,
        }),
      );
    }

    const { prod_sku, prod_name } = results[0].order_items.find(
      (order) => order.id === params.order_item_id,
    ) as IOrderItems;

    return {
      result: `UnPicked [${prod_sku}]${prod_name} for order ${results[0].order_number}. `,
    };
  },
);
