import React from "react";
import FilterSidebar from "components/filter-products/FilterSidebar";
import SelectedFiltersList from "./SelectedFiltersList";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Paper, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useSearchParams } from "react-router-dom";
import { isAnyFilterApplied } from "utils/filter-utils/FilterUtils";
import useChangeFilters from "hooks/useChangeFilters";
import theme from "theme/theme";

interface IFilterProps {
  viewName: string;
  children: ReactNode;
  supplierInfoRequired?: boolean;
  productTypesInfoRequired?: boolean;
  imageClassificationInfoRequired?: boolean;
}

const Filter = ({
  children,
  viewName,
  supplierInfoRequired = false,
  productTypesInfoRequired = false,
  imageClassificationInfoRequired = false,
}: IFilterProps): JSX.Element => {
  const [searchParams] = useSearchParams();
  const changeFilters = useChangeFilters();

  return (
    <Paper
      elevation={0}
      sx={{
        padding: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography>Applied Filters</Typography>
        <FilterSidebar viewName={viewName}>{children}</FilterSidebar>
      </Typography>

      <SelectedFiltersList
        viewName={viewName}
        supplierInfoRequired={supplierInfoRequired}
        productTypesInfoRequired={productTypesInfoRequired}
        imageClassificationInfoRequired={imageClassificationInfoRequired}
      />

      {isAnyFilterApplied(searchParams) && (
        <Box component="span">
          <Button
            size="medium"
            sx={{
              textTransform: "none",
              color: theme.palette.error.main,
              fontSize: theme.typography.subtitle1.fontSize,
            }}
            onClick={() => {
              changeFilters(new URLSearchParams(), true);
            }}
          >
            <CloseIcon /> Clear all filters
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default Filter;
