import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash-es";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useAppSelector from "hooks/useAppSelector";
import { selectCatalogState } from "store/catalog";
import { ICatalogProduct } from "store/catalog/interfaces";

export default function PriceDetails({ id }: { id: number }): JSX.Element {
  const { catalogProducts } = useAppSelector(selectCatalogState);
  const [foundProduct, setFoundProduct] = useState<ICatalogProduct | any>();

  useEffect(() => {
    const found =
      !isEmpty(catalogProducts) &&
      catalogProducts.find((prod) => prod.id === id);
    setFoundProduct(found);
  }, [catalogProducts, id]);

  const ProductDetails = () => {
    if (!isEmpty(foundProduct)) {
      const {
        price,
        vat,
        price_manual_override,
        price_calculated_automatically,
        price_on_sale,
        procurement_cost,
      } = foundProduct;

      return {
        mannualIncluding: Math.round(+price * (+vat + 1)),
        mannualMargin: Math.round(+price_manual_override - +procurement_cost),
        priceManualOverride: Math.round(+price_manual_override),
        priceCalculatedAutomatically: Math.round(
          +price_calculated_automatically,
        ),
        automaticIncluding: Math.round(
          +price_calculated_automatically * (+vat + 1),
        ),
        priceOnSale: Math.round(+price_on_sale),
        saleMargin: Math.round(+price_on_sale - +procurement_cost),
        autoMargin: Math.round(
          +price_calculated_automatically - +procurement_cost,
        ),
      };
    }
    return null;
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="price details table">
        <TableHead>
          <TableRow
            sx={{
              "& th": {
                fontSize: 12,
              },
            }}
          >
            <TableCell sx={{ fontSize: 12, p: 0.7 }}>Type</TableCell>
            <TableCell sx={{ fontSize: 12, p: 0.7 }}>Inkl</TableCell>
            <TableCell sx={{ fontSize: 12, p: 0.7 }}>Exkl</TableCell>
            <TableCell sx={{ fontSize: 12, p: 0.7 }}>Marg</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(ProductDetails()) && ProductDetails()!.priceOnSale > 0 && (
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell sx={{ fontSize: 12, p: 0.7 }}>Rea</TableCell>
              <TableCell sx={{ fontSize: 12, p: 0.7 }}>
                {ProductDetails()!.priceOnSale}
              </TableCell>
              <TableCell sx={{ fontSize: 12, p: 0.7 }}>
                {ProductDetails()!.priceOnSale}
              </TableCell>
              <TableCell sx={{ fontSize: 12, p: 0.7 }}>
                {ProductDetails()!.autoMargin}
              </TableCell>
            </TableRow>
          )}
          {!isEmpty(ProductDetails()) &&
            ProductDetails()!.priceManualOverride > 0 && (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell sx={{ fontSize: 12, p: 0.7 }}>Man</TableCell>
                <TableCell sx={{ fontSize: 12, p: 0.7 }}>
                  {ProductDetails()!.mannualIncluding}
                </TableCell>
                <TableCell sx={{ fontSize: 12, p: 0.7 }}>
                  {ProductDetails()!.priceManualOverride}
                </TableCell>
                <TableCell sx={{ fontSize: 12, p: 0.7 }}>
                  {ProductDetails()!.mannualMargin}
                </TableCell>
              </TableRow>
            )}
          {!isEmpty(ProductDetails()) &&
            ProductDetails()!.priceCalculatedAutomatically > 0 && (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell sx={{ fontSize: 12, p: 0.7 }}>Auto</TableCell>
                <TableCell sx={{ fontSize: 12, p: 0.7 }}>
                  {ProductDetails()!.automaticIncluding}
                </TableCell>
                <TableCell sx={{ fontSize: 12, p: 0.7 }}>
                  {ProductDetails()!.priceCalculatedAutomatically}
                </TableCell>
                <TableCell sx={{ fontSize: 12, p: 0.7 }}>
                  {ProductDetails()!.autoMargin}
                </TableCell>
              </TableRow>
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
