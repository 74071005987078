import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import {
  IInventoryTransactionUpdate,
  IStocktakingLocationsResponseDTO,
} from "store/stock-taking/interfaces";
import { endpoint } from "constants/endpoint";

export interface IStocktakingService {
  fetchStocktaking(
    params: URLSearchParams,
  ): Promise<IStocktakingLocationsResponseDTO>;
  addInventoryTransaction(object: IInventoryTransactionUpdate): Promise<string>;
  updateInventoryTransaction(
    id: number,
    object: IInventoryTransactionUpdate,
  ): Promise<string>;
}

export default new Mockable<IStocktakingService>({
  async fetchStocktaking(params): Promise<IStocktakingLocationsResponseDTO> {
    const res = await axios(
      `${config.api}${endpoint.catalog_inventory_locations_with_transactions}`,
      {
        params,
        headers: commonHeaders(),
      },
    );
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Couldn't get images data");
  },
  async addInventoryTransaction(object): Promise<string> {
    try {
      const res = await axios(
        `${config.api} ${endpoint.catalog_inventory_transactions}`,
        {
          method: "POST",
          headers: commonHeaders(),
          data: JSON.stringify(object),
        },
      );

      if (res.status === 201) {
        return "Successful";
      } else {
        return Promise.reject(
          new Error("Unexpected response format in addInventoryTransaction"),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
  async updateInventoryTransaction(id, object): Promise<string> {
    try {
      const res = await axios(
        `${config.api} ${endpoint.catalog_inventory_transactions}${id}`,
        {
          method: "PUT",
          headers: commonHeaders(),
        },
      );

      if (res.status === 200) {
        return "Successful";
      } else {
        return Promise.reject(
          new Error("Unexpected response format in updateInventoryTransaction"),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
});
