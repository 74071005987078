import React from "react";
import FilterSelectBinary from "components/filter-products/FilterSelectBinary";

const AvailableFilters = (): JSX.Element => {
  return (
    <>
      {/* Show copied or not copied Google product taxonomy items*/}
      <FilterSelectBinary
        label="Copied"
        param="customer_taxonomies__isnull"
        inverted={true}
      />
    </>
  );
};

export default AvailableFilters;
