import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, MobileStepper } from "@mui/material";

interface IStep {
  name: string;
  handler(): void;
}

interface IFooterProps {
  previous: IStep;
  next: IStep;
  steps: number;
  currentStep: number;
}

const Footer = ({
  previous,
  next,
  steps,
  currentStep,
}: IFooterProps): JSX.Element => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box
        sx={{
          color: "primary.main",
          cursor: "pointer",
          display: "inline-block",
        }}
      >
        <Button
          onClick={previous.handler}
          variant="text"
          sx={{ paddingLeft: 0 }}
        >
          <Box alignItems="center" gap="0.5rem">
            <ArrowBackIcon />
            {previous.name}
          </Box>
        </Button>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Button onClick={next.handler} variant="contained">
          {next.name}
        </Button>
      </Box>

      <MobileStepper
        variant="dots"
        steps={steps}
        position="static"
        activeStep={currentStep}
        nextButton={<span />}
        backButton={<span />}
      />
    </Box>
  );
};

export default Footer;
