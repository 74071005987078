import React from "react";
import { Typography } from "@mui/material";
import Link from "@mui/material/Link";

const ContactDetails = (): JSX.Element => {
  return (
    <Typography component="div">
      +46 708 122 012 <br />
      <Link href="mailto:info@ecombooster.io">info@ecombooster.io</Link>
    </Typography>
  );
};

export default ContactDetails;
