export const dataString = {
  headerText: "Choose supplier to edit",
  searchPlaceholder: "Search in supplier name",
  measured: "Measured: ",
  /*   addBtn: "Add Supplier",
   */ addNewBtn: " Add Supplier",
  saveBtn: "Save",
  deleteBtn: "Delete",
  deleteSelectedBtn: "Delete selected supplier",
  modalTitle: "Title ",
  modalShortname: "Short Name(name_abbrev) ",
  modalSupplierIndex: " Main Supplier Index ",
  modalLogin: "Login URL",
  modalUserName: "User Name",
  modalPassword: "Password",
  modalHomePage: "Home Page",
  ModalUrlSearch: "URL Search",
  modalEmail: "Email ",
  modalPrefix: "Prefix: ",
  modalSuffix: "Suffix: ",
  Comments: "Comments",
  procurement_notes: "Purchase Notes",
  minimum_order_value: "Minimum Order Value",
  basicData: "Basic Data",
  settings: "Settings",
  pricing_settings: "Pricing settings",
  import: "Import",
  name: "Name",
  phased_out: "Phased out",
  priority: "Priority",
  type_of_supplier: "Type of Supplier",
  rrp_sales_factor: "RRPsalesFactor",
  custom_pricing_rules: "Custom Pricing Rules",
  custom_pricing_rules_ecombooster_internal:
    "Custom Pricing Rules Ecombooster Internal",
  price_fFactor_based_on_procurement_cost:
    "Price Factor based on Procurement Cost",
  sale_price_as_factor_of_standard_price:
    "Sale Price as Factor of Standard Price",
  currency: "Currency",
  catalog_sku: "Catalog SKU = Supplier SKU",
  images_for_use: "Images for Use",
  reuse_files: "Reuse Files",
  reuse_descriptions: "Reuse Descriptions",
  expose_stock_status: "Expose Stock Status",
  expose_stock_quantity: "Expose Stock Quantity",
  default_catalog_categories: "Default Catalog Categories",
  deleteForEmptySelectRows: "No rows selected!",
  deleteMsgDialogContent:
    "Are you sure you want to delete the following suppliers?",
  deleteMsgDialogOKBtn: "Yes",
  deleteMsgDialogCancelBtn: "Cancel",
  import_wipe_images: "Import Wipe Images",
  import_wipe_attachments: "Import Wipe Attachments",
  import_wipe_attributes: "Import Wipe Attributes",
  import_skip_processing_categories: "Import Skip Processing Categories",
  import_ski_pprocessing_products: "Import Skip Processing Products",
  import_skip_processing_related_products:
    "Import Skip Processing Related Products",
  import_skip_updating_products: "Import Skip Updating Products",
  import_skip_updating_not_scraped_products:
    "Import Skip Updating Not Scraped Products",
  import_skip_creating_new_products: "Import Skip Creating New Products",
  helperTextforTitle: "Should be unique",
  helperTextforSupplierIndex:
    "Used e.g. to determine which purchase price should be used for price calculation. The price from the supplier with the highest priority is used. If several suppliers have the same priority, the lowest price among them is used for price calculation.",
  helperTextforPhasedOut:
    "If this is set to True, the supplier is considered phased out. This will e.g. remove items from the supplier when creating backorders etc.",
  helperTextforPriority:
    "Indicates how important the supplier is. Used e.g. to determine the importance of importing their products into the system.",
  helperTextforCatalogSKU:
    "If this is true, the sku from the supplier will be reused when copy/pasting products... If it is false, and the prefix/suffix are empty, sequential SKU is used.",
  helperTextforPrefix:
    "If defined, this is prefixed to the suppliers SKU when copying/creating new products. Remember to add a separator if applicable, for example, - (dash) or / (slash). For example, supplier Komplett.se might be prefixed with KOMP-.",
  helperTextforSuffix:
    "If defined, this is suffixed to the suppliers SKU when copying/creating new products. Remember to add a separator if applicable, for example, - (dash) or / (slash). For example, supplier Komplett.se might be suffixed with -KOMP.",
  helperTextforImagesforUse:
    "If this is set to True, images related to the procurement product is considered free to use when creating new catalog products based on the procurement product.",
  helperTextforReuseFiles:
    "If  this is set to True, files related to the procurement product is considered free to use when creating new catalog products based on the procurement product.",
  helperTextforReuseDescription:
    "If  this is set to True, descriptions related to the procurement product is considered free to use when creating new catalog products based on the procurement product.",
  helperTextforReuseRRP:
    "If this is set to True, RRP (Recommended Retail Price) will be used as hardcoded price. NOTE! This will have priority over the standard (generic) pricing rules defined ",
  helperTextforAllowRoundingRRP:
    "Only applicable if Reuse RRP is set to True. Will allow Ecombooster to use the defined Rounding rules to adjust the RRP price",
  helperTextforRRPfactor:
    "Makes it possible to reuse the RRP with a factor. A value of 0 disables the function. A value of 0.91 will reuse RRP with 9% lower price (RRP * 0.91). A value of 1.13 will increase the RRP with 13% (RRP * 1.13).",
  helperTextforRRPsalesFactor:
    "Makes it possible to reuse the RRP with a factor for setting a (standard) Sale Price. A value of 0 disables the function. A value of 0.91 will reuse RRP with 9% lower price (RRP * 0.91). A value of 1.13 will increase the RRP with 13% (RRP * 1.13). Must be lower than the RRP Factor.",
  helperTextforCustomPricingRule:
    "If you have pricing rules for the supplier that cannot be configured with the available pricing rules in Ecombooster, describe the rules in free text here.",
  helperTextforCustomRuleByEcombooster:
    "If Ecombooster has acted upon and understood the pricing rules, this will be the exact same text as in the field Custom Pricing Rules. This is a way for us to lock down on requirements. I.e. if customer changes the field Custom Pricing Rules after they are acknowledged, it will be possible to see what was actually agreed upon. Field is READ ONLY for customer.",
  helperTextforCustomRuleByEcomboosterInternal:
    "This field is used to describe the customer rules in a more programatic way, to aid implmentation of the same. It should also contain a brief history, like when rules was changed and also when they were implemented. READ ONLY for customer.",
  helperTextforPriceFactor:
    "Will automatically set the Standard Price to a factor of the Procurement Cost. A value of 0 disables the function. A value of 2.2 will set a Standard Price that is equal to the Procurement Cost * 2.2. Values <= 1.0 are not supported. Standard Rounding preferences will be applied. NOTE! This will have priority over the standard (generic) pricing rules defined ",
  helperTextforSalesPrice:
    "Will automatically set a Sale Price based on a factor of the Standard Price. A value of 0 disables the function. A value of 0.91 will set a Sale Price that is equal to the Standard Price * 0.91. Values > 1.0 are not supported. Standard Rounding preferences will be applied.",
  helperTextforStockStatus:
    "If this is set to True, Ecombooster will reuse the stock status from the supplier and just forward that to the ecommerce system. To be used for e.g. dropshipping or when there is no local stock. NOT USED YET!",
  helperTextforStockQuantity:
    "If this is set to True, Ecombooster will reuse the stock quantity from the supplier and just forward that to the ecommerce system. To be used for e.g. dropshipping or when there is no local stock. NOT USED YET!",
  helperTextforWebsite: "URL for website, example: https://www.hemsida.se",
  helperTextforLogin: "URL for login site, example: homepage/login",
  helperTextforURLsearch:
    "Add search URL, will be used in conjunction with SKU to enable direct access if URL is not available on product level. Put the string >>SEARCH<< where the search-term should be. This will be replaced with the SKU.",
  helperTextforPurchaseNote:
    "Will be displayed on the Procurement order! Can be used to highlight e.g. details about minimum order value to get discount or known information about shipping cost/rules from the supplier.",
  helperTextforMinimumOrder:
    "If total order value on Procurement order is below this, a warning will be displayed! This is just a warning, not a hard limit!",
  helperTextforCurrency:
    "Which currency is used for this supplier? Will be used to pre-fill the currency value when e.g. products are created.",
  helperTextforFeed:
    "For example, if the feed is executed every 3rd day, set this value to 3. A warning will be be raised if no there is more than 3 days between the feeds. Set the value to 0 to disable warnings.",
  helperTextforImportWipeImages:
    "If this is true, all images will be removed from the products that are scraped on next scraping session. This can be useful if e.g. supplier has changed image sizes or similar, which means that you end up with duplicated images. Products which are NOT scraped will not be affected. This flag will be cleared at next import session.",
  helperTextforImportWipeAttachments:
    "If this is true, all attachments will be removed from the products that are scraped on next scraping session. This can be useful if e.g. supplier has made major updates to attached files, and you have attachments that are no longer valid. Products which are NOT scraped will not be affected. This flag will be cleared at next import session.",
  helperTextforImportWipeAttributes:
    "If this is true, all attributes will be removed from the products that are scraped on next scraping session. This can be useful if e.g. supplier has changed hierarchy or similar of attributes, or you need to refresh the attributes from scratch due to some other reason. Products which are NOT scraped will not be affected. This flag will be cleared at next import session.",
  helperTextforImportSkipProcessingCategories:
    "If this is true, the import function will skip processing categories.",
  helperTextforImportSkipProcessingProducts:
    "If this is true, the import function will skip processing products.",
  helperTextforImportSkipProcessingRelatedProducts:
    "If this is true, the import function will skip processing relations between products.",
  helperTextforImportSkipUpdatingProducts:
    "If this is true, existing products will not be updated on next scraping session. This can be useful if you want to 'fast forward' to creating new products. Products which are NOT scraped will not be affected (see flag 'On import Skip updating not scraped products').",
  helperTextforImportSkipUpdatingNotScrapedProducts:
    "If this is true, existing not scraped products will not be updated on next scraping session.",
  helperTextforImportSkipCreatingNewProducts:
    "If this is true, new products will not be created on next scraping session.",
  // errors
  error_required: "This field is required",
};
