import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SidebarItem from "./SidebarItem";
import theme from "theme/theme";

const SidebarCategory = ({ category }: { category: any }) => {
  return (
    <Accordion
      defaultExpanded={category.key === 1}
      disableGutters
      elevation={0}
      sx={{
        boxShadow: "none",
        color: "#1c1b1f",
        borderRadius: "0 !important",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          fontSize: "1.4rem",
          fontFamily: "Roboto",
          color: "#1c1b1f",
          alignItems: "center",
          padding: "0 0.6rem",
          ":hover": {
            backgroundColor: `${theme.palette.action.hover}`,
          },
        }}
      >
        <Box sx={{ paddingRight: "0.4rem" }}>{category.icon}</Box>
        <Box sx={{ alignContent: "center" }}>{category.itemName}</Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0.8rem 0.6rem 0.8rem 1.6rem" }}>
        {category.children?.map((item: any) => (
          <SidebarItem key={item.key} item={item} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default SidebarCategory;
