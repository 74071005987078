import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { fetchInventoriesAsync } from "./thunks";
import { IInventoryData } from "./interfaces";

interface IInventoryState {
  inventoryData: IInventoryData;
  loading: boolean;
}

const initialState = {
  inventoryData: {},
  loading: false,
};

export const inventoriesSlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInventoriesAsync.fulfilled, (state, action) => {
      state.inventoryData = action.payload?.result;
      state.loading = false;
    });
    builder.addCase(fetchInventoriesAsync.pending, (state) => {
      state.loading = true;
    });
  },
});

export const selectInventoriesState = (state: RootState): IInventoryState =>
  state.inventories;

export default inventoriesSlice.reducer;
