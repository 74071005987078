import React from "react";
import { Alert, Box, Divider, Snackbar } from "@mui/material";
import Filter from "components/filter-products/Filter";
import Searchbar from "components/filter-products/Searchbar";
import AvailableFilters from "./parts/AvailableFilters";
import { useCallback, useEffect, useRef, useState } from "react";
import useAppSelector from "hooks/useAppSelector";
import {
  selectFacebookListState,
  setUpdateMessage,
} from "store/procurements-facebook";
import useAppDispatch from "hooks/useAppDispatch";
import SimpleSnackbar from "components/SimpleSnackbar";
import {
  copyFacebookItemsAsync,
  removeFacebookItemsAsync,
  fetchFacebookListAsync,
} from "store/procurements-facebook/thunks";
import { selectAuthenticatedUser } from "store/auth";
import { useSearchParams } from "react-router-dom";
import GridContainer from "components/common-components/GridContainer";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { IUser } from "store/auth/interfaces";
import useBreakpoint from "hooks/useBreakpoint";
import theme from "theme/theme";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import columns from "./parts/ColumnsForDataGrid";
import CommonConfirmDialog from "components/common-components/CommonConfirmDialog";

const FacebookProductTaxonomies = (): JSX.Element => {
  const { results, updateMessage, count } = useAppSelector(
    selectFacebookListState,
  );
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [showShortNameErrorMsg, setShowShortNameErrorMsg] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState<number[]>([]);
  const user = useAppSelector(selectAuthenticatedUser) as IUser;
  const [searchParams] = useSearchParams();
  const handleCopyTimeoutRef = useRef<number | null>(null);
  const isMediumScreen = useBreakpoint("medium");
  const filter = searchParams.get("customer_taxonomies__isnull");

  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelectionModel(newSelectionModel);
  };

  useEffect(() => {
    dispatch(fetchFacebookListAsync(searchParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    setRows(results);
  }, [results]);

  const handleCopy = useCallback(() => {
    if (handleCopyTimeoutRef.current) {
      clearTimeout(handleCopyTimeoutRef.current);
    }

    dispatch(
      copyFacebookItemsAsync({
        ids: selectionModel as [number],
        company: user.company,
        userId: user.id,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(setUpdateMessage("Copy Successful."));
        setShow(true);
      })
      .finally(() => {
        handleCopyTimeoutRef.current = window.setTimeout(() => {
          dispatch(fetchFacebookListAsync(searchParams));
        }, 500);
      });
  }, [dispatch, selectionModel, searchParams]);

  const deleteFBProductTaxonomies = useCallback(
    async (idsArray: any) => {
      const ids = idsArray;

      dispatch(removeFacebookItemsAsync({ ids: ids })).then(() => {
        dispatch(setUpdateMessage("Remove Successful."));
        setShow(true);
      });
      dispatch(fetchFacebookListAsync(searchParams));
    },
    [dispatch, selectionModel, searchParams],
  );

  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState(false);
  const [
    isNotificationVisibleToChoseOneRow,
    setIsNotificationVisibleToChoseOneRow,
  ] = useState(false);

  const handleDeleteBtnClick = (rowIds: any[]) => {
    if (rowIds.length > 0) {
      setIsDeleteConfirmDialogOpen(true);
    } else {
      setIsNotificationVisibleToChoseOneRow(true);
    }
  };

  const onCancleClick = () => {
    setIsDeleteConfirmDialogOpen(false);
  };

  const onApproveClick = () => {
    deleteFBProductTaxonomies(selectionModel);
    setIsDeleteConfirmDialogOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" />
        <ToolboxArea>
          {filter === "true" /* || filter === null */ ? (
            <ToolInfoTooltip title="Copy to my selection (Active after filter)">
              <ToolIconContainer onClick={handleCopy}>
                <ContentCopyOutlinedIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.primary.light,
                    stroke: theme.palette.primary.light,
                    strokeWidth: 1,
                  }}
                />
              </ToolIconContainer>
            </ToolInfoTooltip>
          ) : (
            <></>
          )}
          {filter === "false" /* || filter === null  */ ? (
            <ToolInfoTooltip title="Remove from my selection">
              <ToolIconContainer
                onClick={() => handleDeleteBtnClick(selectionModel)}
              >
                <DeleteForeverIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.error.main,
                  }}
                />
              </ToolIconContainer>
            </ToolInfoTooltip>
          ) : (
            <></>
          )}
          {filter === null ? (
            <>
              <ToolInfoTooltip title="Copy to my selection (Active after filter)">
                <ToolIconContainer disabled>
                  <ContentCopyOutlinedIcon
                    fontSize="large"
                    sx={{
                      color: theme.palette.text.disabled,
                      strokeWidth: 1,
                    }}
                  />
                </ToolIconContainer>
              </ToolInfoTooltip>

              <Divider orientation="vertical" variant="middle" flexItem />
              <ToolInfoTooltip title="Remove from my selectionmmm (Active after filter)">
                <ToolIconContainer disabled>
                  <DeleteForeverIcon
                    fontSize="large"
                    sx={{
                      color: theme.palette.text.disabled,
                    }}
                  />
                </ToolIconContainer>
              </ToolInfoTooltip>
            </>
          ) : (
            <></>
          )}
        </ToolboxArea>
      </Box>

      <Filter viewName="misc_facebook_taxonomies">
        <AvailableFilters />
      </Filter>

      <GridContainer>
        <DataGridPro
          rows={rows}
          columns={columns}
          columnVisibilityModel={{
            id: false,
          }}
          checkboxSelection
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={handleSelectionModelChange}
          pagination
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
        />
      </GridContainer>

      <Snackbar
        open={isNotificationVisibleToChoseOneRow}
        autoHideDuration={4000}
        onClose={() => setIsNotificationVisibleToChoseOneRow(false)}
      >
        <Alert variant="standard" severity="info">
          At least one row should be selected!
        </Alert>
      </Snackbar>

      <CommonConfirmDialog
        isOpen={isDeleteConfirmDialogOpen}
        content="Are you sure you want to delete the inventory location(s)?"
        affirmativeButtonText="Delete"
        negativeButtonText="Cancel"
        onApprove={onApproveClick}
        onCancel={onCancleClick}
      />

      {showShortNameErrorMsg ? (
        <SimpleSnackbar
          open={showShortNameErrorMsg}
          message="Name or Short name exist!"
          toggleSnackbar={() => {
            setShowShortNameErrorMsg(false);
          }}
          snackbarAction={null}
        />
      ) : null}
      {show ? (
        <SimpleSnackbar
          open={show}
          message={updateMessage}
          toggleSnackbar={() => {
            dispatch(setUpdateMessage(""));
            setShow(false);
          }}
          snackbarAction={null}
        />
      ) : null}
    </>
  );
};
export default FacebookProductTaxonomies;
