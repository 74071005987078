import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import Paper from "@mui/material/Paper";
import { Cell, Label, Pie, PieChart, Tooltip } from "recharts";
import useAppSelector from "hooks/useAppSelector";
import {
  selectEditedProductsCount,
  selectUneditedProductsCount,
  selectMappedProductsCount,
} from "store/catalog";
import { IProductCount } from "store/catalog/interfaces";
import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import CommonIconButton from "components/common-components/CommonIconButton";
import theme from "theme/theme";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

interface ICustomLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  index: number;
}

interface IProductCountList extends IProductCount {
  name: string;
}

const CatalogCharts = (): JSX.Element => {
  const editedProducts = useAppSelector(selectEditedProductsCount);
  const uneditedProducts = useAppSelector(selectUneditedProductsCount);
  const mappedProducts = useAppSelector(selectMappedProductsCount);

  const productCount: IProductCountList[] = [
    { type: "edited", name: "Edited products", products: editedProducts },
    { type: "unedited", name: "Unedited products", products: uneditedProducts },
    { type: "mapped", name: "Published to website", products: mappedProducts },
  ];

  const RADIAN = Math.PI / 180;
  const customLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index,
  }: ICustomLabelProps) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {productCount[index].products}
      </text>
    );
  };

  const getColor = (index: number) => {
    return ["#B7C2DD", "#E0E7F8", "#b1b6c3"][index];
  };

  const totalProductCount = useMemo(() => {
    return editedProducts + uneditedProducts;
  }, [editedProducts, uneditedProducts]);

  return (
    <Paper
      elevation={0}
      sx={{
        padding: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <CommonIconButton
          disableRipple
          width="2rem"
          height="2rem"
          backgroundColor={theme.palette.secondary.light}
          marginRight={theme.spacing(0.5)}
          hoverCursor="default"
          textColor={theme.palette.primary.main}
        >
          <Inventory2OutlinedIcon
            sx={{
              fontSize: "12",
              ":hover": {
                cursor: "default",
              },
            }}
          />
        </CommonIconButton>
        <Typography
          sx={{
            fontSize: theme.typography.h5.fontSize,
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          My product catalog
        </Typography>
      </Box>

      <Box margin="auto">
        <PieChart width={300} height={250}>
          <Pie
            data={productCount}
            dataKey="products"
            innerRadius={60}
            outerRadius={80}
            label={customLabel}
            labelLine={false}
            isAnimationActive={false}
          >
            {productCount?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getColor(index)} />
            ))}
            <Label value={totalProductCount} offset={0} position="center" />
          </Pie>
          <Tooltip />
        </PieChart>
      </Box>

      <Box
        sx={{
          display: "flex",
          "& > *:not(:last-child)": { marginRight: theme.spacing(0.5) },
        }}
      >
        <Typography
          component="div"
          sx={{
            fontSize: theme.typography.subtitle1.fontSize,
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircleIcon sx={{ color: getColor(0), fontSize: "1.2rem" }} />
          Edited products
        </Typography>
        <Typography
          component="div"
          sx={{
            fontSize: theme.typography.subtitle1.fontSize,
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircleIcon sx={{ color: getColor(1), fontSize: "1.2rem" }} />
          Unedited products
        </Typography>
        <Typography
          component="div"
          sx={{
            fontSize: theme.typography.subtitle1.fontSize,
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircleIcon sx={{ color: getColor(2), fontSize: "1.2rem" }} />
          Published to website
        </Typography>
      </Box>
    </Paper>
  );
};

export default CatalogCharts;
