import { createAsyncThunk } from "@reduxjs/toolkit";
import procurementSup from "services/users";

export const fetchUserListAsync = createAsyncThunk(
  "procurementUserList/fetchUserListAsync",
  async (searchText: string) => {
    const res = await procurementSup
      .service()
      .fetchProcurementUserList(searchText);
    return res;
  },
);
