import { createAsyncThunk } from "@reduxjs/toolkit";
import auth from "services/auth";
import { RootState } from "../index";

export const createAccount = createAsyncThunk(
  "onboarding/createAccount",
  async (_, { getState }) => {
    const state = getState() as RootState;

    return await auth
      .service()
      .register(state.onboarding.accountDetails, state.onboarding.companyInfo);
  },
);
