import React from "react";
import { FormHelperText, RadioGroup } from "@mui/material";
import { Controller } from "react-hook-form";

export default function CustomRadioGroupField(props: any) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{ ...props.rules }}
      render={({ field, fieldState: { error } }) => (
        <>
          <RadioGroup
            row={!!props.row}
            {...field}
            defaultValue={props.defaultValue}
          >
            {props.children}
          </RadioGroup>
          <FormHelperText error>{error ? error.message : null}</FormHelperText>
        </>
      )}
    />
  );
}
