import React from "react";
import { Button } from "@mui/material";

interface Props {
  children: React.ReactNode;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  id?: string;
  ariaControl?: string;
  ariaHasPopup?:
    | boolean
    | "dialog"
    | "menu"
    | "grid"
    | "listbox"
    | "false"
    | "true"
    | "tree";
  ariaExpanded?: boolean | undefined;
}

const ToolIconContainer = ({
  children,
  onClick,
  disabled,
  id,
  ariaControl,
  ariaHasPopup,
  ariaExpanded,
}: Props) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      id={id}
      aria-controls={ariaControl}
      aria-haspopup={ariaHasPopup}
      aria-expanded={ariaExpanded}
      sx={{
        width: "fit-content",
        minWidth: "unset",
        // border: "1px solid red",
      }}
    >
      {children}
    </Button>
  );
};

export default ToolIconContainer;
