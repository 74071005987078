import React, { useCallback } from "react";
import { useState } from "react";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import ComingSoonTooltip from "components/ComingSoonTooltip";
import { ViewType } from "store/catalog";
import { Alert, Box, Button, Snackbar } from "@mui/material";
import theme from "theme/theme";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import SettingsIcon from "@mui/icons-material/Settings";
import PublishButton from "./PublishButton";
import CommonConfirmDialog from "components/common-components/CommonConfirmDialog";
import { GridRowId } from "@mui/x-data-grid-pro";
import {
  deleteProductsWithIds,
  fetchCatalogProducts,
} from "store/catalog/thunks";
import { setUpdateMessage } from "store/catalog/index";
import useAppDispatch from "hooks/useAppDispatch";

interface IProps {
  editProducts(): void;
  view: ViewType;
  onSettingsClick: () => void;
  selectedIds: GridRowId[];
  setSearchParams: string;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
}

const Toolbar = ({
  editProducts,
  view,
  onSettingsClick,
  selectedIds,
  setSearchParams,
  setShowNotification,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState(false);
  const [
    isNotificationVisibleToChoseOneRow,
    setIsNotificationVisibleToChoseOneRow,
  ] = useState(false);

  const fetchData = useCallback(() => {
    dispatch(fetchCatalogProducts(setSearchParams));
  }, [dispatch]);

  const deleteProducts = useCallback(async (idsArray: any) => {
    const ids = idsArray;
    dispatch(deleteProductsWithIds(ids as number[])).then(() => {
      dispatch(setUpdateMessage("Delete Successful."));
      setShowNotification(true);
    });
    fetchData();
  }, []);

  const handleDeleteBtnClick = (selectedIds) => {
    if (selectedIds.length > 0) {
      setIsDeleteConfirmDialogOpen(true);
    } else {
      setIsNotificationVisibleToChoseOneRow(true);
    }
  };

  const onCancleClick = () => {
    setIsDeleteConfirmDialogOpen(false);
  };

  const onApproveClick = () => {
    deleteProducts(selectedIds);
    setIsDeleteConfirmDialogOpen(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-end",
          gap: "1rem",
        }}
      >
        <ToolboxArea>
          {view !== "prices" && (
            <>
              <PublishButton />
              <ToolInfoTooltip title="Edit">
                <ToolIconContainer onClick={editProducts}>
                  <EditIcon
                    fontSize="large"
                    sx={{
                      color: theme.palette.warning.main,
                      stroke: theme.palette.warning.main,
                      strokeWidth: 2,
                    }}
                  />
                </ToolIconContainer>
              </ToolInfoTooltip>
              <ToolInfoTooltip title="Delete">
                <ToolIconContainer
                  onClick={() => handleDeleteBtnClick(selectedIds)}
                >
                  <DeleteForeverIcon
                    fontSize="large"
                    sx={{
                      color: theme.palette.error.main,
                    }}
                  />
                </ToolIconContainer>
              </ToolInfoTooltip>
              <ToolInfoTooltip title="Re-assign attributes(coming soon)">
                <ToolIconContainer disabled>
                  <AutoAwesomeMotionIcon
                    fontSize="large"
                    sx={{
                      color: theme.palette.primary.light,
                      stroke: theme.palette.primary.light,
                      strokeWidth: 1,
                    }}
                  />
                </ToolIconContainer>
              </ToolInfoTooltip>
            </>
          )}

          <ToolInfoTooltip title="View settings">
            <ToolIconContainer onClick={onSettingsClick}>
              <SettingsIcon
                fontSize="large"
                sx={{
                  color: theme.palette.primary.contrastText,
                  stroke: theme.palette.primary.contrastText,
                  strokeWidth: 1,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>
        </ToolboxArea>
      </Box>

      <Snackbar
        open={isNotificationVisibleToChoseOneRow}
        autoHideDuration={4000}
        onClose={() => setIsNotificationVisibleToChoseOneRow(false)}
      >
        <Alert variant="standard" severity="info">
          At least one row should be selected!
        </Alert>
      </Snackbar>

      <CommonConfirmDialog
        isOpen={isDeleteConfirmDialogOpen}
        content={"Are you sure you want to delete the product(s)?"}
        affirmativeButtonText="Delete"
        negativeButtonText="Cancel"
        onApprove={onApproveClick}
        onCancel={onCancleClick}
      />

      {view === "stocks" && (
        <Box sx={{ display: "flex", gap: "1rem", padding: "1rem 0 0 0" }}>
          <ComingSoonTooltip>
            <Button disabled variant="outlined">
              <EditIcon /> Edit
            </Button>
          </ComingSoonTooltip>
        </Box>
      )}
    </Box>
  );
};

export default Toolbar;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
