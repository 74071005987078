import React, { useCallback, useEffect } from "react";
import {
  fetchInventoryLocationsListAsync,
  addInventoryLocationsItemsAsync,
  updateListInventoryLocationsItemsAsync,
  fetchTypes,
  removeInventoryLocationsItemsAsync,
} from "store/catalog-inventory-locations/thunks";
import {
  selectInventoryLocationsListState,
  setUpdateMessage,
} from "store/catalog-inventory-locations";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";

export const useInventoryLocations = ({
  searchParams,
  setShowNotification,
}: {
  searchParams: URLSearchParams;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const { results, params, updateMessage } = useAppSelector(
    selectInventoryLocationsListState,
  );

  const fetchData = useCallback(() => {
    dispatch(fetchInventoryLocationsListAsync(searchParams));
    dispatch(fetchTypes());
  }, [dispatch, searchParams]);

  const addLocation = async (data) => {
    await dispatch(addInventoryLocationsItemsAsync(data))
      .unwrap()
      .then(() => {
        dispatch(setUpdateMessage("Save Successful."));
        fetchData();
      });
  };

  const updateLocations = async (data) => {
    await dispatch(updateListInventoryLocationsItemsAsync(data))
      .unwrap()
      .then(() => {
        dispatch(setUpdateMessage("Update Successful."));
        fetchData();
      });
  };

  const removeLocations = useCallback(
    async (idsArray: any) => {
      const ids = idsArray;

      await Promise.all(
        ids.map((singleId: any) =>
          dispatch(removeInventoryLocationsItemsAsync({ ids: singleId })).then(
            () => {
              dispatch(setUpdateMessage("Delete Successful."));
              setShowNotification(true);
            },
          ),
        ),
      );
      fetchData();
    },
    [dispatch],
  );

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  return {
    results,
    params,
    updateMessage,
    fetchData,
    addLocation,
    updateLocations,
    removeLocations,
  };
};
