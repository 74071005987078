import React from "react";
import { FormControlLabel, Checkbox, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";

export default function CustomCheckboxField(props: any) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{ ...props.rules }}
      defaultValue={props.defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <FormControlLabel
            control={
              <Checkbox
                color={props.color ?? "secondary"}
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
            }
            label={props.label}
          />
          <FormHelperText error>{error ? error.message : null}</FormHelperText>
        </>
      )}
    />
  );
}
