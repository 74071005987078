import React from "react";
import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";

const columns: GridColDef<any>[] = [
  {
    field: "id",
    headerName: "Id",
    width: 90,
  },
  {
    field: "name",
    headerName: "Name",
    renderCell: (params: any) => {
      return (
        <Box
          style={{ cursor: "pointer", color: "#26479e" }}
          // onClick={() => handleNameClick(params)}
        >
          {params.value}
        </Box>
      );
    },
    width: 150,
    editable: false,
  },
  {
    field: "name_abbrev",
    headerName: "Short name",
    editable: true,
    cellClassName: "super-app-theme--cell",
    width: 150,
  },
  {
    field: "slug",
    headerName: "Slug",
    width: 150,
    sortable: false,
  },
  {
    field: "url_homepage",
    headerName: "Home page",
    width: 280,
    editable: true,
    sortable: false,
  },
  {
    field: "main_supplier_index",
    headerName: "Supplier index",
    editable: true,
    sortable: false,
    width: 150,
  },
  {
    field: "email_orders",
    headerName: "Email",
    width: 220,
    editable: true,
    sortable: false,
  },
  {
    field: "sku_prefix",
    headerName: "SKU prefix",
    editable: true,
    sortable: false,
    width: 150,
  },
  {
    field: "sku_suffix",
    headerName: "SKU suffix",
    editable: true,
    sortable: false,
    width: 150,
  },
];

export default columns;
