import React from "react";
import { IStocktakeUILocations } from "../Interfaces";
import ProductDataDisplay from "./ProductDataDisplay";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Button,
  DialogActions,
  Stack,
  Box,
} from "@mui/material";

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  currentRow: IStocktakeUILocations;
}

const Modal = ({
  isOpen,
  currentRow,
  handleClose,
}: ModalProps): JSX.Element => {
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Box
              sx={{
                color: "#4d4d4d",
                fontSize: "26px",
                fontWeight: "bold",
                padding: "6px",
              }}
            >
              Stocktaking @{" "}
            </Box>
            <Box
              sx={{
                color: "#4d4d4d",
                fontSize: "26px",
                fontWeight: "bold",
                padding: "6px",
              }}
            >
              {currentRow.name}
            </Box>
          </Box>

          <Box>
            <Box sx={{ textTransform: "unset" }}>
              Last stocktaking performed:{" "}
            </Box>
            <Box sx={{ color: "orange", textTransform: "unset" }}>
              {currentRow.last_stocktaking_done
                ? currentRow.last_stocktaking_done
                : " Never"}
            </Box>
          </Box>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Divider />
        <ProductDataDisplay productData={currentRow} />
        <Divider />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
