import React from "react";
import FilterDateRangeStacked from "components/filter-products/FilterDateRangeStacked";
import FilterSelectMultiple from "components/filter-products/FilterSelectMultiple";
import { convertOrderText } from "pages/main-page/pick-pack-order-page/utils/convertOrderText";
import { useMemo } from "react";

const AvailableFilters = (): JSX.Element => {
  const statusOptions: [string, string][] = useMemo(
    () =>
      [
        "NOT_PAID",
        "CANCELLED",
        "PROCESSING",
        "TO_BE_PICKED",
        "ON_HOLD",
        "READY_FOR_SHIPPING",
        "COMPLETED",
      ]
        ?.map((option): [string, string] => [convertOrderText(option), option])
        .sort((a, b) => a[0].localeCompare(b[0])),

    [],
  );

  const pickingOptions: [string, string][] = useMemo(
    () =>
      [
        "PARTLY_IN_STOCK",
        "NOTHING_TO_PICK",
        "NOT_APPLICABLE",
        "ALL_IN_STOCK",
        "DONE_PICKING",
      ]?.map((option) => [convertOrderText(option), option]),
    [],
  );

  const backorderOptions: [string, string][] = useMemo(
    () =>
      [
        "NOT_APPLICABLE",
        "NO_BACKORDERS",
        "PARTLY_BACKORDERED",
        "ALL_BACKORDERED",
      ]?.map((option) => [convertOrderText(option), option]),
    [],
  );

  return (
    <>
      <FilterSelectMultiple
        label="Order Status"
        param="internal_order_status"
        options={statusOptions}
      />
      <FilterSelectMultiple
        label="Picking Status"
        param="internal_picking_status"
        options={pickingOptions}
      />
      <FilterSelectMultiple
        label="Backorder Status"
        param="internal_backorder_status"
        options={backorderOptions}
      />
      <FilterDateRangeStacked label="Created at" param="order_created" />
      <FilterDateRangeStacked label="Delivered at" param="order_delivered" />
    </>
  );
};

export default AvailableFilters;
