import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { ITableHeader } from "../../../store/orders/interfaces";
const styles = StyleSheet.create({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  entryContainer: {
    marginBottom: 10,
    padding: 4,
  },
  font: {
    fontSize: 9,
  },
  fontStrike: {
    fontSize: 9,
    textDecoration: "line-through",
    color: "red",
  },
  fontBold: {
    fontFamily: "Open Sans",
    fontWeight: 800,
    fontSize: 10,
  },
  leftColumn: {
    flex: 1,
  },
  productColumn: {
    flex: 2,
    alignItems: "flex-end",
    justifySelf: "flex-end",
  },
  rightColumn: {
    flex: 1,
    alignItems: "flex-end",
    justifySelf: "flex-end",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
    backgroundColor: "#0a3751",
    padding: 16,
    color: "#ffffff",
  },
});

const tableHeader: ITableHeader[] = [
  { name: "qty_ordered", headerName: "Ant", class: styles.leftColumn },
  { name: "prod_sku", headerName: "Art nr", class: styles.leftColumn },
  {
    name: "prod_name",
    headerName: "Product Name",
    class: styles.productColumn,
  },
  { name: "prod_total", headerName: "Á Pris", class: styles.rightColumn },
  { name: "subtotal_tax", headerName: "Moms", class: styles.rightColumn },
  { name: "subtotal", headerName: "Pris summa", class: styles.rightColumn },
  { name: "total", headerName: "Summa", class: styles.rightColumn },
];

const renderHeader = (header: ITableHeader[]) => {
  return (
    <>
      {header?.map((key, i) => (
        <View key={i} style={key.class}>
          <Text style={styles.fontBold}>{key.headerName}</Text>
        </View>
      ))}
    </>
  );
};
const calculateTax = (amount: string) => {
  return (parseInt(amount) * 0.25).toFixed(2);
};

const renderTable = (data: any, tableHeader: ITableHeader[]) => {
  return (
    <>
      {tableHeader?.map((key: any, i: number) => (
        <View key={i} style={key.class} wrap={false}>
          {data["prod_total_discounted"] < 1 ? (
            <>
              {key["name"] == "subtotal_tax" ? (
                <Text style={styles.font}>
                  {calculateTax(data["subtotal"])}
                </Text>
              ) : (
                <Text style={styles.font}>{data[key.name]}</Text>
              )}
            </>
          ) : (
            <>
              {key["name"] == "subtotal" && (
                <>
                  <Text style={styles.fontStrike}>{data[key.name]}</Text>
                  <Text style={styles.font}>
                    {data["prod_price_discounted"]}
                  </Text>
                </>
              )}
              {key["name"] == "subtotal_tax" && (
                <>
                  <Text style={styles.fontStrike}>{data[key.name]}</Text>
                  <Text style={styles.font}>{data["prod_tax_discounted"]}</Text>
                </>
              )}
              {key["name"] == "total" && (
                <>
                  <Text style={styles.fontStrike}>{data[key.name]}</Text>
                  <Text style={styles.font}>
                    {data["prod_total_discounted"]}
                  </Text>
                </>
              )}
              {key["name"] != "total" &&
                key["name"] != "subtotal_tax" &&
                key["name"] != "subtotal" && (
                  <Text style={styles.font}>{data[key.name]}</Text>
                )}
            </>
          )}
        </View>
      ))}
    </>
  );
};

const renderItemList = (
  product: any,
  tableHeader: ITableHeader[],
): JSX.Element => (
  <View style={styles.headerContainer} wrap={false}>
    {renderTable(product, tableHeader)}
  </View>
);

const OrderItemListComponent = ({
  orderData,
  companyData,
  type,
}: any): JSX.Element => {
  styles.tableHeader.backgroundColor = companyData?.branding_background_color;
  styles.tableHeader.color = companyData?.branding_font_color;
  const header = JSON.parse(JSON.stringify(tableHeader));
  if (
    companyData?.packing_slip_price_configuration == "no_prices" &&
    type == "slip"
  ) {
    header.splice(3);
  }
  if (type == "excl_vat") {
    header.splice(6, 1);
  }
  if (type == "incl_vat") {
    header.splice(7);
  }
  return (
    <>
      <View style={styles.tableHeader} wrap={false}>
        {renderHeader(header)}
      </View>
      {orderData.order_items?.map((item: any, i: number) => (
        <View key={i} style={styles.entryContainer}>
          {renderItemList(item, header)}
        </View>
      ))}
    </>
  );
};
export default OrderItemListComponent;
