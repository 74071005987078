import React from "react";
import {
  AccordionDetails,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  Box,
} from "@mui/material";
import { editorOptions } from "pages/main-page/edit-catalog-product-page/parts/edit-product";
import SelectParentCategory from "./SelectParentCategory";
import useAppSelector from "hooks/useAppSelector";
import { selectSuppliers } from "store/suppliers";
import { ICatalogCategory } from "store/catalog-categories/interfaces";
import EditAccordion from "./EditAccordion";
import SunEditor from "components/lazy-loading/LazySunEditor";

interface IFilterSelectProps {
  procurementCategory: {
    name: string;
    supplier: number;
    url: string;
    parent: number;
    description: string;
    descriptionHtml: string;
  };
  updateProcurementCategory(
    field: string,
    value: number | string | ICatalogCategory[],
  ): void;
}

const AccordionFields = ({
  updateProcurementCategory,
  procurementCategory,
}: IFilterSelectProps): JSX.Element => {
  const suppliers = useAppSelector(selectSuppliers);

  return (
    <AccordionDetails sx={{ paddingLeft: 0 }}>
      <EditAccordion label="Supplier">
        <FormControl>
          <Select
            value={
              procurementCategory.supplier ? procurementCategory.supplier : ""
            }
            onChange={(e) =>
              updateProcurementCategory("supplier", e.target.value)
            }
            sx={{ height: "2.5rem" }}
          >
            {suppliers.length > 0 &&
              suppliers?.map((supplier) => (
                <MenuItem key={supplier.id} value={supplier.id}>
                  {supplier.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </EditAccordion>
      <EditAccordion label="Parent Category">
        <SelectParentCategory
          editProcurementCategory={procurementCategory}
          updateProcurementCategory={updateProcurementCategory}
        />
      </EditAccordion>
      <EditAccordion label="Name">
        <OutlinedInput
          sx={{
            height: "2.5rem",
          }}
          fullWidth
          value={procurementCategory.name}
          onChange={(e) => updateProcurementCategory("name", e.target.value)}
        />
      </EditAccordion>
      <EditAccordion label="URL">
        <OutlinedInput
          sx={{
            height: "2.5rem",
          }}
          fullWidth
          value={procurementCategory.url ? procurementCategory.url : ""}
          onChange={(e) => updateProcurementCategory("url", e.target.value)}
        />
      </EditAccordion>
      <EditAccordion label="Description (plain text)">
        <OutlinedInput
          fullWidth
          multiline
          rows={5}
          value={procurementCategory.description}
          onChange={(e) =>
            updateProcurementCategory("description", e.target.value)
          }
        />
      </EditAccordion>
      <EditAccordion label="Description (HTML)">
        <Box
          sx={{
            ".sun-editor": {
              borderRadius: "4px",
            },
            ".se-toolbar": {
              borderRadius: "4px 4px 0 0",
            },
          }}
        >
          <SunEditor
            defaultValue={procurementCategory.descriptionHtml}
            onChange={(val) =>
              updateProcurementCategory("descriptionHtml", val)
            }
            autoFocus
            setOptions={editorOptions}
          />
        </Box>
      </EditAccordion>
    </AccordionDetails>
  );
};

export default AccordionFields;
