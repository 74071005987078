import React, { useState } from "react";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Searchbar from "components/filter-products/Searchbar";
import Filter from "components/filter-products/Filter";
import { fetchInventoryTransactionsAsync } from "store/inventory-transactions/thunks";
import {
  selectInventoryTransactionsState,
  setSelectedRow,
} from "store/inventory-transactions";
import { fetchCatalogProducts } from "store/catalog/thunks";
import { IInventoryTransaction } from "store/inventory-transactions/interfaces";
import AvailableFilters from "./parts/AvailableFilters";
import { Box, Button } from "@mui/material";
import GridContainer from "components/common-components/GridContainer";
import useBreakpoint from "hooks/useBreakpoint";
import { DataGridPro } from "@mui/x-data-grid-pro";
import inventoryTransactions from "services/inventory-transactions";
import ColumnsForDataGrid from "./parts/ColumnsForDataGrid";
import theme from "theme/theme";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const InventoryTransactionsPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    inventoryTransactionsLoaded,
    count,
    inventoryTransactions: inventoryTransactionss,
  } = useAppSelector(selectInventoryTransactionsState);
  const [searchParams] = useSearchParams();
  const isMediumScreen = useBreakpoint("medium");
  const [transaction, setTransaction] = useState<IInventoryTransaction[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchInventoryTransactionsAsync(searchParams));
    dispatch(fetchCatalogProducts(searchParams.toString()));
  }, [dispatch, searchParams]);

  const onRowClick = (event: any) => {
    dispatch(setSelectedRow(event.row as IInventoryTransaction));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await inventoryTransactions
          .service()
          .fetchInventoryTransactions(new URLSearchParams());
        if (res && res.results) {
          setTransaction(res.results);
        } else {
          setTransaction([]);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const updateGrid = () => {
    dispatch(fetchInventoryTransactionsAsync(searchParams));
  };

  const columns = ColumnsForDataGrid(updateGrid, transaction, loading);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" />
        <ToolboxArea>
          <ToolInfoTooltip title="Add Markup">
            <ToolIconContainer disabled>
              <AddCircleIcon
                fontSize="large"
                sx={{
                  color: theme.palette.text.disabled,
                  stroke: theme.palette.text.disabled,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>
        </ToolboxArea>
      </Box>

      <Filter
        children={<AvailableFilters />}
        viewName="inventory_transactions"
      />

      <GridContainer>
        <DataGridPro
          loading={!inventoryTransactionsLoaded}
          rows={inventoryTransactionss}
          onRowClick={onRowClick}
          columns={columns}
          disableColumnMenu
          pagination
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
        />
      </GridContainer>
    </>
  );
};

export default InventoryTransactionsPage;
