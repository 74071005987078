import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import { ITableHeader } from "store/orders/interfaces";
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
  ],
});

const styles = StyleSheet.create({
  fontStrike: {
    fontFamily: "Open Sans",
    fontSize: 10,
    fontWeight: 600,
    textDecoration: "line-through",
    color: "red",
  },
  centerColumn: {
    flex: 1,
    alignItems: "center",
    justifySelf: "center",
    fontSize: 10,
    fontWeight: 800,
    fontFamily: "Open Sans",
  },
});

const totalTableHeader: ITableHeader[] = [
  { name: "total_cost", headerName: "Netto" },
  { name: "total_tax", headerName: "Moms" },
  { name: "total_cost_round_off", headerName: "Avrundning" },
  { name: "total_cost_incl_tax", headerName: "Summa" },
];

const calculateTotalAmount = (order: any, type: string) => {
  switch (type) {
    case "tax":
      return (parseInt(order.total_cost) * 0.25).toFixed(2);
      break;
    case "discountedTax":
      return (
        parseInt(order.total_cost) * 0.25 -
        parseInt(order.discount_tax)
      ).toFixed(2);
      break;
    case "totalAmount":
      return (parseInt(order.total_cost) * 1.25).toFixed(2);
      break;
    case "discountedAmount":
      return (
        parseInt(order.total_cost) - parseInt(order.discount_total)
      ).toFixed(2);
      break;
    case "discountedTotalAmt": {
      const amount =
        parseInt(order.total_cost) - parseInt(order.discount_total);
      const discount =
        parseInt(order.total_cost) * 0.25 - parseInt(order.discount_tax);
      return (amount + discount).toFixed(2);
      break;
    }
    default:
      return "Invalid type amount";
  }
};

const OrderTotalDetailsComponent = ({ orderData }: any): JSX.Element => {
  const { discount_total } = orderData;
  return (
    <>
      {totalTableHeader?.map((item: any, i) => (
        <View key={i} style={styles.centerColumn} wrap={false}>
          <Text>{item.headerName}</Text>
          {discount_total == 0 ? (
            <>
              {item.name == "total_cost" && <Text>{orderData[item.name]}</Text>}
              {item.name == "total_tax" && (
                <Text>{calculateTotalAmount(orderData, "tax")}</Text>
              )}
              {item.name == "total_cost_incl_tax" && (
                <Text>{calculateTotalAmount(orderData, "totalAmount")}</Text>
              )}
            </>
          ) : (
            <>
              {item.name == "total_cost" && (
                <>
                  <Text style={styles.fontStrike}>{orderData[item.name]}</Text>
                  <Text>
                    {calculateTotalAmount(orderData, "discountedAmount")}
                  </Text>
                </>
              )}
              {item.name == "total_tax" && (
                <>
                  <Text style={styles.fontStrike}>
                    {calculateTotalAmount(orderData, "tax")}
                  </Text>
                  <Text>
                    {calculateTotalAmount(orderData, "discountedTax")}
                  </Text>
                </>
              )}
              {item.name == "total_cost_incl_tax" && (
                <>
                  <Text style={styles.fontStrike}>
                    {calculateTotalAmount(orderData, "totalAmount")}
                  </Text>
                  <Text>
                    {calculateTotalAmount(orderData, "discountedTotalAmt")}
                  </Text>
                </>
              )}
            </>
          )}
          {item.name == "total_cost_round_off" && (
            <Text>{orderData[item.name]}</Text>
          )}
        </View>
      ))}
    </>
  );
};

export default OrderTotalDetailsComponent;
