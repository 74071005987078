import React from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  TablePagination,
} from "@mui/material";
import Searchbar from "components/filter-products/Searchbar";
import useAppSelector from "hooks/useAppSelector";
import {
  selectProcurementCategoriesState,
  setProcurementCatagoriesParams,
} from "store/procurement-categories";
import useAppDispatch from "hooks/useAppDispatch";
import { ICatalogCategory } from "store/catalog-categories/interfaces";
import theme from "theme/theme";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
    },
  },
};

interface ISelectParentCategoryProps {
  editProcurementCategory: {
    name: string;
    supplier: number;
    url: string;
    parent: any;
    description: string;
    descriptionHtml: string;
  };
  updateProcurementCategory: (
    field: string,
    value: number | string | ICatalogCategory[],
  ) => void;
}

const SelectParentCategory = ({
  editProcurementCategory,
  updateProcurementCategory,
}: ISelectParentCategoryProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { categories, procurementCategoriesLoaded, count, filterParams } =
    useAppSelector(selectProcurementCategoriesState);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    dispatch(setProcurementCatagoriesParams({ page: newPage }));
  };

  const searchHandler = (value: string) => {
    dispatch(setProcurementCatagoriesParams({ search: value, page: 0 }));
  };

  return (
    <FormControl>
      <Select
        value={
          editProcurementCategory.parent ? editProcurementCategory.parent : ""
        }
        onChange={(e) => updateProcurementCategory("parent", e.target.value)}
        sx={{ height: "2.5rem" }}
        MenuProps={MenuProps}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 2,
            height: "2.5rem",
            backgroundColor: theme.palette.light.main,
          }}
        >
          <Searchbar placeholder="Search" onChange={searchHandler} />
        </Box>
        {!procurementCategoriesLoaded ? (
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            height="20vh"
          >
            <CircularProgress size={25} />
          </Box>
        ) : (
          categories.length > 0 &&
          categories?.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.slug}
            </MenuItem>
          ))
        )}
        <TablePagination
          component={Box}
          count={count ? count : 0}
          page={filterParams.page}
          onPageChange={handleChangePage}
          rowsPerPage={filterParams.limit}
        />
      </Select>
    </FormControl>
  );
};

export default SelectParentCategory;
