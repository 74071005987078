import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import config from "../../config";
import { RootState } from "../index";
import { IStocktakingSliceState } from "./interfaces";
import {
  addInventoryTransactionAsync,
  fetchStocktaking,
  updateInventoryTransactionAsync,
} from "./thunks";
import { axiosErrorResponse, httpErrorHandler } from "utils/error-response";

const initialState: IStocktakingSliceState = {
  productColumns: [
    { field: "quantity", flex: 0.5, headerName: "QTY", sortable: false },
    { field: "sku", flex: 0.5, headerName: "SKU", sortable: false },
    {
      field: "name",
      flex: 2.5,
      headerName: "Product name",
      sortable: false,
    },
    {
      field: "performed_stocktaking",
      flex: 1,
      headerName: "Performed stocktaking",
      sortable: false,
      valueGetter: (params: { value: any }) =>
        params?.value ? params.value : "Not Performed",
    },
  ],
  loading: true,
  inventoryLocations: [],
  inventoryProducts: [],
  count: 0,
  inventoryLocationsLoaded: false,
  filterParams: {
    page: 0,
    limit: 100,
    locationName: "",
    productName: "",
    obsolete: "No",
  },
  success: "",
  fail: "",
  error: {},
};

const stocktakingSlice = createSlice({
  name: "stocktaking",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setStocktakingParams: (state, action) => {
      state.inventoryLocationsLoaded = false;
      state.filterParams = { ...state.filterParams, ...action.payload };
    },
    resetAllFilters: (state) => {
      state.inventoryLocationsLoaded = false;
      state.filterParams = initialState.filterParams;
    },
    clearTransactionMessage: (state) => {
      state.success = "";
      state.error = {};
      state.fail = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStocktaking.fulfilled, (state, action) => {
        state.inventoryLocations = Object.values(
          action.payload.result.inventory_locations,
        );
        state.inventoryProducts = Object.values(action.payload.result.products);
        state.count = action.payload.count;
        state.inventoryLocationsLoaded = true;
      })
      .addCase(fetchStocktaking.rejected, (state, { error }) => {
        state.error = {
          ...httpErrorHandler(error),
          url: config.api + "/inventory_locations/",
        };
      })
      .addCase(addInventoryTransactionAsync.fulfilled, (state, action) => {
        state.inventoryLocationsLoaded = false;
        state.success = "Transaction added successfully";
      })
      .addCase(addInventoryTransactionAsync.rejected, (state, { payload }) => {
        state.error = {
          ...axiosErrorResponse(payload),
          url: config.api + "/catalog/inventory_transactions/:id",
        };
      })
      .addCase(updateInventoryTransactionAsync.fulfilled, (state, action) => {
        state.inventoryLocationsLoaded = false;
        state.success = "Transaction updated successfully";
      })
      .addCase(
        updateInventoryTransactionAsync.rejected,
        (state, { payload }) => {
          state.error = {
            ...axiosErrorResponse(payload),
            url: config.api + "/catalog/inventory_transactions/:id",
          };
        },
      );
  },
});

export const selectStocktakingState = (
  state: RootState,
): IStocktakingSliceState => state.stocktaking;

export const {
  setLoading,
  setStocktakingParams,
  resetAllFilters,
  clearTransactionMessage,
} = stocktakingSlice.actions;

export default stocktakingSlice.reducer;
