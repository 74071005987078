import React, { useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Searchbar from "components/filter-products/Searchbar";
import {
  deleteProcurementSupplierListAsync,
  fetchSupplierListAsync,
  UpdateProcurementSupplierListAsync,
} from "store/procurement-supplierList/thunks";
import useAppDispatch from "hooks/useAppDispatch";
import {
  selectSupplierListState,
  setAddMessage,
  setUpdateMessage,
} from "store/procurement-supplierList";
import RedesignModalContainerAdd from "./utils/RedesignModalContainerAdd";
import {
  IAddProcurementSupplyListParams,
  ISupplierList,
} from "store/procurement-supplierList/interfaces";
import procurementSup from "services/procurement-supplierList";
import {
  Backdrop,
  Box,
  Modal,
  Typography,
  Alert,
  Snackbar,
  Button,
} from "@mui/material";
import useBreakpoint from "hooks/useBreakpoint";
import theme from "theme/theme";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  DataGridPro,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import GridContainer from "components/common-components/GridContainer";
import columns from "./parts/ColumnsForDataGrid";
import useAppSelector from "hooks/useAppSelector";
import useChangeFilters from "hooks/useChangeFilters";
import { useSearchParams } from "react-router-dom";
import CommonConfirmDialog from "components/common-components/CommonConfirmDialog";
import ModalContainerUpdate from "./utils/modalContainerUpdate";

export default function Supplier(): JSX.Element {
  const { results, updateMessage } = useAppSelector(selectSupplierListState);
  const dispatch = useAppDispatch();
  const isMediumScreen = useBreakpoint("medium");
  const [searchParams] = useSearchParams();
  const changeFilters = useChangeFilters();
  const [openNewModal, setOpenNewModal] = useState(false);
  const handleNewModalClose = () => setOpenNewModal(false);
  const handleNewAddModal = () => setOpenNewModal(true);
  const [rows, setRows] = useState<ISupplierList[]>([]);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState(false);
  const [
    isNotificationVisibleToChoseOneRow,
    setIsNotificationVisibleToChoseOneRow,
  ] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const [openModalEdit, setOpenModal] = useState(false);
  const [rowsEditModal, setRowsEditModal] = useState<ISupplierList[]>([]);
  const [titleEditModal, setTitle] = useState("");
  const [createdEditModal, setCreated] = useState("");
  const [updatedEditModal, setUpdated] = useState("");
  const [shortNameEditModal, setShortName] = useState("");
  const [homepage, setHomepage] = useState("");
  const [login, setLogin] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [search, setSearch] = useState("");
  const [emailEditModal, setEmail] = useState("");
  const [supplierType, setSupplierType] = useState("RESELLER");
  const [skuReuse, setSkuReuse] = useState(false);
  const [prefix, setPrefix] = useState("");
  const [suffix, setSuffix] = useState("");
  const [comments, setComments] = useState("");
  const [procurementNotes, setProcurementNotes] = useState("");
  const [proMinOrderVal, setProMinOrderVal] = useState(0);
  const [phasedOut, setPhasedOut] = useState(false);
  const [primaryLan, setPrimaryLan] = useState("EN");
  const [priority, setPriority] = useState("TBD");
  const [supplierIndex, setSupplierIndex] = useState(1);
  const [reuseImages, setReuseImages] = useState(false);
  const [reuseFiles, setReuseFiles] = useState(false);
  const [reuseDescription, setReuseDescription] = useState(false);
  const [reuseRRP, setReuseRRP] = useState(false);
  const [reuseRRPfactor, setReuseRRPfactor] = useState("0.0000");
  const [salePriceFactor, setSalePriceFactor] = useState("0.0000");
  const [allowRounding, setAllowRounding] = useState(false);
  const [procurementCost, setProcurementCost] = useState("0.0000");
  const [standardPrice, setStandardPrice] = useState("0.0000");
  const [pricingRule, setPricingRule] = useState("");
  const [pricingRuleByEcom, setPricingRuleByEcom] = useState("");
  const [pricingRuleByEcomInternal, setPricingRuleByEcomInternal] =
    useState("");
  const [stockStatus, setStockStatus] = useState(false);
  const [stockQuantity, setStockQuantity] = useState(false);
  const [feedUpdate, setFeedUpdate] = useState(0);
  const [currency, setCurrency] = useState(1);
  const [catalogCatagories, setCatalogCatagories] = useState<any[]>([]);
  const [onImportWipeImages, setOnImportWipeImages] = useState("");
  const [onImportWipeAttachments, setOnImportWipeAttachments] = useState("");
  const [onImportWipeAttributes, setOnImportWipeAttributes] = useState("");
  const [
    onImportSkipProcessingCategories,
    setOnImportSkipProcessingCategories,
  ] = useState("");
  const [onImportSkipProcessingProducts, setOnImportSkipProcessingProducts] =
    useState("");
  const [
    onImportSkipProcessingRelatedProducts,
    setOnImportSkipProcessingRelatedProducts,
  ] = useState("");
  const [onImportSkipUpdatingProducts, setOnImportSkipUpdatingProducts] =
    useState("");
  const [
    onImportSkipUpdatingNotScrapedProducts,
    setOnImportSkipUpdatingNotScrapedProducts,
  ] = useState("");
  const [onImportSkipCreatingNewProducts, setOnImportSkipCreatingNewProducts] =
    useState("");
  const [showShortNameErrorMsg, setShowShortNameErrorMsg] = useState(false);

  const handleEditModalClose = () => setOpenModal(false);

  useEffect(() => {
    dispatch(fetchSupplierListAsync(searchParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    setRows(results);
  }, [results]);

  const deleteSupplier = useCallback(
    async (idsArray: any) => {
      const ids = idsArray;

      await Promise.all(
        ids.map((singleId: any) => {
          return dispatch(deleteProcurementSupplierListAsync(singleId)).then(
            () => {
              dispatch(setUpdateMessage("Delete Successful."));
              setShowNotification(true);
            },
          );
        }),
      );
      return dispatch(fetchSupplierListAsync(searchParams));
    },
    [dispatch],
  );

  const getSortModel = (): GridSortModel => {
    if (!searchParams.has("ordering")) return [];
    switch (searchParams.get("ordering")) {
      case "name":
        return [{ field: "name", sort: "asc" }];
      case "-name":
        return [{ field: "name", sort: "desc" }];
      case "name_abbrev":
        return [{ field: "name_abbrev", sort: "asc" }];
      case "-name_abbrev":
        return [{ field: "name_abbrev", sort: "desc" }];
      default:
        return [];
    }
  };

  const sortModel: GridSortModel = getSortModel();

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const orderingParam = sortModel[0]
      ? sortModel[0].sort === "asc"
        ? sortModel[0].field
        : "-" + sortModel[0].field
      : "";
    changeFilters(new URLSearchParams([["ordering", orderingParam]]));
  };

  const handleNewAdd = async (params: IAddProcurementSupplyListParams) => {
    const res = await procurementSup
      .service()
      .AddProcurementSupplierNew(params);
    if (res.statusCode === 201) {
      dispatch(fetchSupplierListAsync(new URLSearchParams()));
      dispatch(setAddMessage(res.message));
      setOpenNewModal(false);
    } else {
      dispatch(setAddMessage(res.message));
    }
  };

  const searchHandler = (value: string) => {
    dispatch(fetchSupplierListAsync(new URLSearchParams()));
  };

  const handleDeleteBtnClick = (rowSelectionModel: any) => {
    if (rowSelectionModel.length > 0) {
      setIsDeleteConfirmDialogOpen(true);
    } else {
      setIsNotificationVisibleToChoseOneRow(true);
    }
  };

  const onCancleClick = () => {
    setIsDeleteConfirmDialogOpen(false);
  };

  const onApproveClick = () => {
    deleteSupplier(rowSelectionModel);
    setIsDeleteConfirmDialogOpen(false);
  };

  const handleEditModalDelete = () => {
    dispatch(deleteProcurementSupplierListAsync(rowSelectionModel as any))
      .unwrap()
      .finally(() => {
        dispatch(fetchSupplierListAsync(searchParams));
        setShowNotification(true);
      });
    setOpenModal(false);
  };

  const handleEditModalUpdate = () => {
    const selectedId: number = rowSelectionModel as any;
    const newRows = results.filter((res: any) => res.id === selectedId);

    const isNameExists = results.some((res) => {
      return res.name_abbrev === shortNameEditModal;
    });

    const isTitleExists = results.some((res) => {
      return res.name === titleEditModal;
    });

    const newData = {
      id: selectedId,
      params: {
        name: titleEditModal,
        created: createdEditModal,
        updated: updatedEditModal,
        name_abbrev: shortNameEditModal,
        url_homepage: homepage,
        url_login: login,
        username: username,
        password: password,
        url_search: search,
        email_orders: emailEditModal,
        supplier_type: supplierType,
        sku_reuse: skuReuse,
        sku_prefix: prefix,
        sku_suffix: suffix,
        comments: comments,
        procurement_notes: procurementNotes,
        procurement_minimum_order_value: proMinOrderVal,
        phased_out: phasedOut,
        primary_language: primaryLan,
        priority: priority,
        main_supplier_index: supplierIndex,
        reuse_images: reuseImages,
        reuse_files: reuseFiles,
        reuse_descriptions: reuseDescription,
        reuse_rrp: reuseRRP,
        reuse_rrp_factor: reuseRRPfactor,
        reuse_rrp_sale_price_factor: salePriceFactor,
        reuse_rrp_allow_rounding: allowRounding,
        price_factor_of_procurement_cost: procurementCost,
        sale_price_factor_of_standard_price: standardPrice,
        custom_pricing_rules: pricingRule,
        custom_pricing_rules_acknowledged_by_ecomb: pricingRuleByEcom,
        custom_pricing_rules_ecombooster_internal: pricingRuleByEcomInternal,
        expose_stock_status: stockStatus,
        expose_stock_quantity: stockQuantity,
        warning_level_days_between_feed_updates: feedUpdate,
        default_currency: currency,
        default_catalog_categories: catalogCatagories,
        on_import_wipe_images: onImportWipeImages,
        on_import_wipe_attachments: onImportWipeAttachments,
        on_import_wipe_attributes: onImportWipeAttributes,
        on_import_skip_processing_categories: onImportSkipProcessingCategories,
        on_import_skip_processing_products: onImportSkipProcessingProducts,
        on_import_skip_processing_related_products:
          onImportSkipProcessingRelatedProducts,
        on_import_skip_updating_products: onImportSkipUpdatingProducts,
        on_import_skip_updating_not_scraped_products:
          onImportSkipUpdatingNotScrapedProducts,
        on_import_skip_creating_new_products: onImportSkipCreatingNewProducts,
      },
    };
    (!isNameExists || shortNameEditModal === newRows[0].name_abbrev) &&
    (!isTitleExists || titleEditModal === newRows[0].name)
      ? dispatch(UpdateProcurementSupplierListAsync(newData))
          .unwrap()
          .finally(() => {
            dispatch(fetchSupplierListAsync(searchParams));
            setShowNotification(true);
            setOpenModal(false);
          })
      : setShowShortNameErrorMsg(true);
  };

  const handleNameClick = (params: { id: any }) => {
    const { id } = params;
    const dataModal = results.filter((res) => res.id === id);
    setTitle(dataModal[0].name);
    const createdAT = new Date(dataModal[0].created_at).toLocaleString(
      "sv-SE",
      {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      },
    );
    const updatedAT = new Date(dataModal[0].updated_at).toLocaleString(
      "sv-SE",
      {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      },
    );
    setCreated(createdAT);
    setUpdated(updatedAT);

    setShortName(dataModal[0].name_abbrev);
    setHomepage(dataModal[0].url_homepage);
    setLogin(dataModal[0].url_login);
    setUsername(dataModal[0].username);
    setPassword(dataModal[0].password);
    setSearch(dataModal[0].url_search);
    setEmail(dataModal[0].email_orders);
    setSupplierType(dataModal[0].supplier_type);
    setSkuReuse(dataModal[0].sku_reuse);
    setSkuReuse(dataModal[0].sku_reuse);
    setPrefix(dataModal[0].sku_prefix);
    setSuffix(dataModal[0].sku_suffix);
    setComments(dataModal[0].comments);
    setProcurementNotes(dataModal[0].procurement_notes);
    setProMinOrderVal(dataModal[0].procurement_minimum_order_value);
    setProMinOrderVal(dataModal[0].procurement_minimum_order_value);
    setPhasedOut(dataModal[0].phased_out);
    setPrimaryLan(dataModal[0].primary_language);
    setPriority(dataModal[0].priority);
    setSupplierIndex(dataModal[0].main_supplier_index);
    setReuseImages(dataModal[0].reuse_images);
    setReuseFiles(dataModal[0].reuse_files);
    setReuseDescription(dataModal[0].reuse_descriptions);
    setReuseRRP(dataModal[0].reuse_rrp);
    setReuseRRPfactor(dataModal[0].reuse_rrp_factor);
    setSalePriceFactor(dataModal[0].reuse_rrp_sale_price_factor);
    setAllowRounding(dataModal[0].reuse_rrp_allow_rounding);
    setProcurementCost(dataModal[0].price_factor_of_procurement_cost);
    setStandardPrice(dataModal[0].sale_price_factor_of_standard_price);
    setPricingRule(dataModal[0].custom_pricing_rules);
    setPricingRuleByEcom(
      dataModal[0].custom_pricing_rules_acknowledged_by_ecomb,
    );
    setPricingRuleByEcomInternal(
      dataModal[0].custom_pricing_rules_ecombooster_internal,
    );
    setStockStatus(dataModal[0].expose_stock_status);
    setStockQuantity(dataModal[0].expose_stock_quantity);
    setFeedUpdate(dataModal[0].warning_level_days_between_feed_updates);
    setCurrency(dataModal[0].default_currency);

    setCatalogCatagories(dataModal[0].default_catalog_categories);

    setRowsEditModal(dataModal);
    setRowSelectionModel(id);
    setOpenModal(true);

    setOnImportWipeImages(dataModal[0].on_import_wipe_images);
    setOnImportWipeAttachments(dataModal[0].on_import_wipe_attachments);
    setOnImportWipeAttributes(dataModal[0].on_import_wipe_attributes);
    setOnImportSkipProcessingCategories(
      dataModal[0].on_import_skip_processing_categories,
    );
    setOnImportSkipProcessingProducts(
      dataModal[0].on_import_skip_processing_products,
    );
    setOnImportSkipProcessingRelatedProducts(
      dataModal[0].on_import_skip_processing_related_products,
    );
    setOnImportSkipUpdatingProducts(
      dataModal[0].on_import_skip_updating_products,
    );
    setOnImportSkipUpdatingNotScrapedProducts(
      dataModal[0].on_import_skip_updating_not_scraped_products,
    );
    setOnImportSkipCreatingNewProducts(
      dataModal[0].on_import_skip_creating_new_products,
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" onChange={searchHandler} />
        <ToolboxArea>
          <ToolInfoTooltip title="Add supplier">
            <ToolIconContainer onClick={handleNewAddModal}>
              <AddCircleIcon
                fontSize="large"
                sx={{
                  color: theme.palette.primary.main,
                  stroke: theme.palette.primary.main,
                  strokeWidth: 1,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>

          <ToolInfoTooltip title="Delete selected supplier">
            <ToolIconContainer
              onClick={() => handleDeleteBtnClick(rowSelectionModel)}
            >
              <DeleteForeverIcon
                fontSize="large"
                sx={{
                  color: theme.palette.error.main,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>
        </ToolboxArea>
      </Box>

      <GridContainer>
        <DataGridPro
          rows={rows}
          columns={columns as any}
          columnVisibilityModel={{
            id: false,
          }}
          checkboxSelection
          disableRowSelectionOnClick
          // processRowUpdate={handleCellEditCommit}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            //newRowSelectionModel is [ids] of selected rows
            setRowSelectionModel(newRowSelectionModel);
          }}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          pagination
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
        />
      </GridContainer>

      <Snackbar
        open={isNotificationVisibleToChoseOneRow}
        autoHideDuration={4000}
        onClose={() => setIsNotificationVisibleToChoseOneRow(false)}
      >
        <Alert variant="standard" severity="info">
          At least one row should be selected!
        </Alert>
      </Snackbar>

      <CommonConfirmDialog
        isOpen={isDeleteConfirmDialogOpen}
        content={"Are you sure you want to delete the file url(s)?"}
        affirmativeButtonText="Delete"
        negativeButtonText="Cancel"
        onApprove={onApproveClick}
        onCancel={onCancleClick}
      />

      <Snackbar
        open={showNotification}
        autoHideDuration={4000}
        onClose={() => setShowNotification(false)}
      >
        <Alert>{updateMessage}</Alert>
      </Snackbar>

      {/* Add/setting/import modal */}
      <Modal
        open={openNewModal}
        onClose={handleNewModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ color: "text.secondary" }}
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 700 } }}
      >
        <Box
          sx={{
            top: "60",
            left: "60",
            right: "60",
            bottom: "60",
            position: "absolute",
            maxHeight: "80vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            transition: "opacity 0.2s ease-in-out",
            boxShadow: 24,
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "relative",
              justifyContent: "space-between",
              color: "text.secondary",
            }}
          >
            <Typography id="modal-modal-title">Add Supplier</Typography>
            <Box sx={{ cursor: "pointer" }} onClick={handleNewModalClose}>
              <CloseIcon />
            </Box>
          </Box>
          <Box sx={{ padding: "20px", fontSize: "12px", fontWeight: "bold" }}>
            <RedesignModalContainerAdd handleNewAdd={handleNewAdd} />
          </Box>
        </Box>
      </Modal>

      {/* Edit/Update modal */}

      <Modal
        open={openModalEdit}
        onClose={handleEditModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "60",
            left: "60",
            right: "60",
            bottom: "60",
            bgcolor: "background.paper",
            overflowY: "auto",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            data-testid="modalDisplay"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography id="modal-modal-title" sx={{ color: "text.secondary" }}>
              Edit supplier: {titleEditModal}
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={handleEditModalClose}>
              <CloseIcon />
            </Box>
          </Box>
          <ModalContainerUpdate
            rowsModal={rowsEditModal}
            shortName={shortNameEditModal}
            title={titleEditModal}
            created={createdEditModal}
            updated={updatedEditModal}
            email={emailEditModal}
            prefix={prefix}
            suffix={suffix}
            homepage={homepage}
            login={login}
            username={username}
            password={password}
            search={search}
            supplierType={supplierType}
            skuReuse={skuReuse}
            comments={comments}
            procurementNotes={procurementNotes}
            phasedOut={phasedOut}
            proMinOrderVal={proMinOrderVal}
            primaryLan={primaryLan}
            priority={priority}
            reuseImages={reuseImages}
            reuseFiles={reuseFiles}
            reuseDescription={reuseDescription}
            reuseRRP={reuseRRP}
            reuseRRPfactor={reuseRRPfactor}
            salePriceFactor={salePriceFactor}
            allowRounding={allowRounding}
            procurementCost={procurementCost}
            standardPrice={standardPrice}
            pricingRule={pricingRule}
            pricingRuleByEcom={pricingRuleByEcom}
            pricingRuleByEcomInternal={pricingRuleByEcomInternal}
            stockStatus={stockStatus}
            stockQuantity={stockQuantity}
            feedUpdate={feedUpdate}
            currency={currency}
            onImportWipeImages={onImportWipeImages}
            onImportWipeAttachments={onImportWipeAttachments}
            onImportWipeAttributes={onImportWipeAttributes}
            onImportSkipProcessingCategories={onImportSkipProcessingCategories}
            onImportSkipProcessingProducts={onImportSkipProcessingProducts}
            onImportSkipProcessingRelatedProducts={
              onImportSkipProcessingRelatedProducts
            }
            onImportSkipUpdatingProducts={onImportSkipUpdatingProducts}
            onImportSkipUpdatingNotScrapedproducts={
              onImportSkipUpdatingNotScrapedProducts
            }
            onImportSkipCreatingNewProducts={onImportSkipCreatingNewProducts}
            catalogCatagories={catalogCatagories}
            setShortName={setShortName}
            setSupplierIndex={setSupplierIndex}
            setEmail={setEmail}
            setPrefix={setPrefix}
            setSuffix={setSuffix}
            setTitle={setTitle}
            setHomepage={setHomepage}
            setLogin={setLogin}
            setUsername={setUsername}
            setPassword={setPassword}
            setSearch={setSearch}
            setSupplierType={setSupplierType}
            setSkuReuse={setSkuReuse}
            setComments={setComments}
            setProcurementNotes={setProcurementNotes}
            setProMinOrderVal={setProMinOrderVal}
            setPhasedOut={setPhasedOut}
            setPrimaryLan={setPrimaryLan}
            setPriority={setPriority}
            setReuseImages={setReuseImages}
            setReuseFiles={setReuseFiles}
            setReuseDescription={setReuseDescription}
            setReuseRRP={setReuseRRP}
            setReuseRRPfactor={setReuseRRPfactor}
            setSalePriceFactor={setSalePriceFactor}
            setAllowRounding={setAllowRounding}
            setProcurementCost={setProcurementCost}
            setStandardPrice={setStandardPrice}
            setPricingRule={setPricingRule}
            setPricingRuleByEcom={setPricingRuleByEcom}
            setPricingRuleByEcomInternal={setPricingRuleByEcomInternal}
            setStockStatus={setStockStatus}
            setStockQuantity={setStockQuantity}
            setFeedUpdate={setFeedUpdate}
            setCurrency={setCurrency}
            supplierIndex={supplierIndex}
            setCatalogCatagories={setCatalogCatagories}
            setOnImportWipeImages={setOnImportWipeImages}
            setOnImportWipeAttachments={setOnImportWipeAttachments}
            setOnImportWipeAttributes={setOnImportWipeAttributes}
            setOnImportSkipProcessingCategories={
              setOnImportSkipProcessingCategories
            }
            setOnImportSkipProcessingProducts={
              setOnImportSkipProcessingProducts
            }
            setOnImportSkipProcessingRelatedProducts={
              setOnImportSkipProcessingRelatedProducts
            }
            setOnImportSkipUpdatingProducts={setOnImportSkipUpdatingProducts}
            setOnImportSkipUpdatingNotScrapedProducts={
              setOnImportSkipUpdatingNotScrapedProducts
            }
            setOnImportSkipCreatingNewProducts={
              setOnImportSkipCreatingNewProducts
            }
          />
          <hr />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant="contained" onClick={handleEditModalDelete}>
              Delete
            </Button>
            <Button variant="contained" onClick={handleEditModalUpdate}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={showShortNameErrorMsg}
        autoHideDuration={4000}
        onClose={() => setShowShortNameErrorMsg(false)}
      >
        <Alert>Name or Short name exist!</Alert>
      </Snackbar>
    </>
  );
}
