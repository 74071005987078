import React, { useEffect, useState } from "react";
import { Alert, Box, Snackbar } from "@mui/material";
import Filter from "components/filter-products/Filter";
import Searchbar from "components/filter-products/Searchbar";
import AvailableFilters from "./parts/AvailableFilters";
import { useSearchParams } from "react-router-dom";
import useBreakpoint from "hooks/useBreakpoint";
import theme from "theme/theme";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ToolboxArea from "components/common-components/ToolboxArea";
import SaveIcon from "@mui/icons-material/Save";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DataGridPro, GridRowId, GridRowModel } from "@mui/x-data-grid-pro";
import GridContainer from "components/common-components/GridContainer";
import columns from "./parts/ColumnsForDataGrid";
import { useInventoryLocations } from "./hooks/useInventoryLocations";
import AddInventoryLocationsModal from "./parts/AddInventoryLocationsModal";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { selectAuthenticatedUser } from "store/auth";
import { IUser } from "store/auth/interfaces";
import { updateInventoryLocationsItemsAsync } from "store/catalog-inventory-locations/thunks";
import { setUpdateMessage } from "store/catalog-inventory-locations";
import CommonModal from "components/common-components/CommonModal";
import CommonConfirmDialog from "components/common-components/CommonConfirmDialog";

export default function InventoryLocations(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const isMediumScreen = useBreakpoint("medium");
  const [selectionModel, setSelectionModel] = useState<any[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectAuthenticatedUser) as IUser;
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState(false);
  const [
    isNotificationVisibleToChoseOneRow,
    setIsNotificationVisibleToChoseOneRow,
  ] = useState(false);

  const { results, params, updateMessage, fetchData, removeLocations } =
    useInventoryLocations({ searchParams, setShowNotification });

  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelectionModel(newSelectionModel);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    if (!urlParams.has("page") || !urlParams.has("limit")) {
      searchParams.set("page", "1");
      searchParams.set("limit", "50");
      setSearchParams(searchParams, { replace: true });
    }
    searchParams.set("page", (params.page + 1).toString());
    searchParams.set("limit", params.limit.toString());
    setSearchParams(searchParams, { replace: true });
  }, [params.limit, params.page, searchParams, setSearchParams]);

  const processRowUpdate = React.useCallback(
    async (
      newRow: GridRowModel,
      oldRow: GridRowModel,
      params: { rowId: GridRowId },
    ) => {
      const account = user.id;
      const company = user.company as number;
      const id: number = params.rowId as number;

      const updatedData = {
        id: id,
        account: account as number,
        company: company as number,
        name: newRow.name,
        name_slug: newRow.name,
        abbreviation_slug: newRow.abbreviation_slug,
        content: newRow.content,
        comment: newRow.comment,
        stocktaking_perfomed_at: newRow.stocktaking_perfomed_at,
        obsolete: newRow.obsolete,
        not_for_stock: newRow.not_for_stock,
        type: newRow.type as number,
      };
      dispatch(updateInventoryLocationsItemsAsync(updatedData));
      dispatch(setUpdateMessage("Update Successful."));
      return { ...oldRow, ...newRow };
    },
    [],
  );

  const handleDeleteBtnClick = (rowIds: any[]) => {
    if (rowIds.length > 0) {
      setIsDeleteConfirmDialogOpen(true);
    } else {
      setIsNotificationVisibleToChoseOneRow(true);
    }
  };

  const onCancleClick = () => {
    setIsDeleteConfirmDialogOpen(false);
  };

  const onApproveClick = () => {
    removeLocations(selectionModel);
    setIsDeleteConfirmDialogOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" />

        <ToolboxArea>
          <ToolInfoTooltip title="Add inventory location">
            <ToolIconContainer onClick={handleOpenModal}>
              <AddCircleIcon
                fontSize="large"
                sx={{
                  color: theme.palette.primary.main,
                  stroke: theme.palette.primary.main,
                  strokeWidth: 1,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>

          <ToolInfoTooltip title="Save">
            <ToolIconContainer onClick={() => processRowUpdate}>
              <SaveIcon
                fontSize="large"
                sx={{
                  color: theme.palette.primary.main,
                  stroke: theme.palette.primary.main,
                  strokeWidth: 1,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>

          <ToolInfoTooltip title="Delete">
            <ToolIconContainer
              onClick={() => handleDeleteBtnClick(selectionModel)}
            >
              <DeleteForeverIcon
                fontSize="large"
                sx={{
                  color: theme.palette.error.main,
                  stroke: theme.palette.error.main,
                  strokeWidth: 1,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>
        </ToolboxArea>
      </Box>

      <Filter viewName="inventory_locations">
        <AvailableFilters />
      </Filter>

      <CommonModal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        title="Add Inventory Location"
      >
        <AddInventoryLocationsModal
          closeModal={handleCloseModal}
          setShowNotification={setShowNotification}
        />
      </CommonModal>

      <GridContainer>
        <DataGridPro
          rows={results}
          columns={columns}
          processRowUpdate={processRowUpdate}
          // eslint-disable-next-line
          onProcessRowUpdateError={(error) => console.error(error)}
          columnVisibilityModel={{ id: false }}
          checkboxSelection
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={handleSelectionModelChange}
          pagination
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
        />
      </GridContainer>

      <Snackbar
        open={isNotificationVisibleToChoseOneRow}
        autoHideDuration={4000}
        onClose={() => setIsNotificationVisibleToChoseOneRow(false)}
      >
        <Alert variant="standard" severity="info">
          At least one row should be selected!
        </Alert>
      </Snackbar>

      <CommonConfirmDialog
        isOpen={isDeleteConfirmDialogOpen}
        content="Are you sure you want to delete the inventory location(s)?"
        affirmativeButtonText="Delete"
        negativeButtonText="Cancel"
        onApprove={onApproveClick}
        onCancel={onCancleClick}
      />

      <Snackbar
        open={showNotification}
        autoHideDuration={4000}
        onClose={() => setShowNotification(false)}
      >
        <Alert>{updateMessage}</Alert>
      </Snackbar>
    </>
  );
}
