import React from "react";
import { Box } from "@mui/material";
import { ICatalogProduct } from "store/catalog/interfaces";

const ProductDetails = (product: ICatalogProduct): JSX.Element => {
  return (
    <Box
      sx={{
        display: "grid",
        columns: "100px 1fr",
        gap: "2rem",
        alignItems: "center",
      }}
    >
      <a target="_blank" href={product.image_urls[0]} rel="noreferrer">
        <img src={product.image_urls[0]} alt="" width="100%" />
      </a>
      <Box>
        <p>{product.name}</p>
        <p>[{product.sku}]</p>
      </Box>
      {product.url}
    </Box>
  );
};

export default ProductDetails;
