import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../index";
import { ISupplier, ISupplierSliceState } from "../suppliers/interfaces";

import { fetchGlobalSuppliers } from "./thunks";

const initialState: ISupplierSliceState = {
  suppliers: [],
};

const allSuppliersAndBrandsSlice = createSlice({
  name: "all-suppliers-and-brands",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalSuppliers.fulfilled, (state, action) => {
      state.suppliers = action.payload;
    });
  },
});

export const selectAllSuppliersAndBrands = (state: RootState): ISupplier[] =>
  state.allSuppliersAndBrands.suppliers;

export default allSuppliersAndBrandsSlice.reducer;
