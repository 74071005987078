import { createAsyncThunk } from "@reduxjs/toolkit";
import procurementSup from "services/procurements-facebook";
import {
  CopyProcurementFacebookListArgs,
  RemoveProcurementFacebookListArgs,
  IProcurementFacebook,
} from "./interfaces";

export const fetchFacebookListAsync = createAsyncThunk<
  IProcurementFacebook,
  URLSearchParams
>("procurementFacebookList/fetchFacebokListAsync", async (params) => {
  const res = await procurementSup
    .service()
    .fetchProcurementFacebookList(params);
  return res;
});

export const copyFacebookItemsAsync = createAsyncThunk(
  "procurementFacebookList/CopyFacebookItemsAsync",
  async (args: CopyProcurementFacebookListArgs) => {
    const { ids, company, userId } = args;
    const res = await procurementSup
      .service()
      .copyFacebookItems(ids, company, userId);
    return res;
  },
);

export const removeFacebookItemsAsync = createAsyncThunk(
  "procurementFacebookList/RemoveFacebookItemsAsync",
  async (args: RemoveProcurementFacebookListArgs) => {
    const { ids } = args;
    const res = await procurementSup.service().removeFacebookItems(ids);
    return res;
  },
);
