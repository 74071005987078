type Field = "orderStatus";

interface IUpdateCustomersOrder {
  orderStatus: string;
}
export function updateCustomersOrderFn(
  setUpdate: React.Dispatch<React.SetStateAction<IUpdateCustomersOrder>>,
  update: IUpdateCustomersOrder,
): (field: Field, v: number | string) => void {
  return (field: Field, v: number | string) => {
    if (field === "orderStatus") {
      setUpdate({ ...update, orderStatus: v.toString() });
    }
  };
}

interface IEditAble {
  field: string;
  canEdit: boolean;
}

interface IOrderRef extends IEditAble {
  identifier: "orderNumber" | "url";
}

export const orderReference: IOrderRef[] = [
  { identifier: "orderNumber", field: "Order number:", canEdit: false },
  { identifier: "url", field: "Url:", canEdit: false },
];

interface IOrderInfo extends IEditAble {
  identifier: "internalOrderStatus" | "orderStatus" | "woocommerceIntegration";
}

export const orderInfo: IOrderInfo[] = [
  { identifier: "orderStatus", field: "Order status:", canEdit: false },
  {
    identifier: "internalOrderStatus",
    field: "Internal order status:",
    canEdit: true,
  },
  {
    identifier: "woocommerceIntegration",
    field: "Woocommerce Integration:",
    canEdit: true,
  },
];
interface IOrderNotes extends IEditAble {
  identifier: "orderNotes" | "orderMarks" | "ourReference";
}

export const orderNotes: IOrderNotes[] = [
  { identifier: "orderNotes", field: "Order notes:", canEdit: false },

  {
    identifier: "orderMarks",
    field: "Order marks:",
    canEdit: false,
  },
  {
    identifier: "ourReference",
    field: "Our reference:",
    canEdit: true,
  },
];
interface IOrderDate extends IEditAble {
  identifier:
    | "orderSourceCreated"
    | "orderSourceUpdated"
    | "orderUpdated"
    | "orderDelivered";
}

export const orderDate: IOrderDate[] = [
  {
    identifier: "orderSourceCreated",
    field: "Created in source:",
    canEdit: false,
  },
  {
    identifier: "orderSourceUpdated",
    field: "Updated in source:",
    canEdit: true,
  },
  {
    identifier: "orderUpdated",
    field: "Updated at:",
    canEdit: false,
  },
  {
    identifier: "orderDelivered",
    field: "Order Delivered:",
    canEdit: true,
  },
];

interface IShippingInfo extends IEditAble {
  identifier:
    | "shippingMethod"
    | "shippingTrackingNumber"
    | "shippingTrackingLink";
}

export const shippingInfo: IShippingInfo[] = [
  { identifier: "shippingMethod", field: "Shipping method:", canEdit: true },
  {
    identifier: "shippingTrackingNumber",
    field: "Shipping tracking number:",
    canEdit: true,
  },
  {
    identifier: "shippingTrackingLink",
    field: "Shipping tracking link:",
    canEdit: true,
  },
];

////////////////////////////////////////////////////
interface IBillingAddress extends IEditAble {
  identifier:
    | "firstName"
    | "lastName"
    | "addressLine1"
    | "addressLine2"
    | "postCode"
    | "city"
    | "state"
    | "country"
    | "email"
    | "phoneNumber";
}

export const billingAddress: IBillingAddress[] = [
  { identifier: "firstName", field: "First name", canEdit: true },
  { identifier: "lastName", field: "Last name", canEdit: true },
  { identifier: "addressLine1", field: "Address Line 1", canEdit: true },
  { identifier: "addressLine2", field: "Address Line 2", canEdit: true },
  { identifier: "postCode", field: "Postcode", canEdit: true },
  { identifier: "city", field: "City", canEdit: true },
  { identifier: "state", field: "State", canEdit: true },
  { identifier: "country", field: "Country", canEdit: true },
  { identifier: "email", field: "Email", canEdit: true },
  { identifier: "phoneNumber", field: "Phonenumber", canEdit: true },
];
////////////////////////////////////////////////////

interface IPrisSkattCosts extends IEditAble {
  identifier: "currency" | "totalCost" | "disccountTotal";
}

export const costs: IPrisSkattCosts[] = [
  { identifier: "currency", field: "Currency", canEdit: false },
  { identifier: "totalCost", field: "Total cost", canEdit: false },
  { identifier: "disccountTotal", field: "Discount total", canEdit: false },
];

interface IPrisTaxes extends IEditAble {
  identifier:
    | "totalTaxes"
    | "taxOnProducts"
    | "discountTax"
    | "shippingTax"
    | "PricesIncludesTax"
    | "VatExempt"
    | "VatExemptID";
}
export const taxes: IPrisTaxes[] = [
  { identifier: "totalTaxes", field: "Total taxes", canEdit: false },
  { identifier: "taxOnProducts", field: "Last name", canEdit: false },
  { identifier: "discountTax", field: "Discount tax", canEdit: false },
  { identifier: "shippingTax", field: "Shipping tax", canEdit: false },
  {
    identifier: "PricesIncludesTax",
    field: "Prices includes tax",
    canEdit: false,
  },
  { identifier: "VatExempt", field: "VAT Exempt", canEdit: false },
  { identifier: "VatExemptID", field: "VAT Exempt ID", canEdit: false },
];

interface IPrisPayment extends IEditAble {
  identifier: "PaymenMethodID" | "PaymentMethodDetails" | "PaymentDate";
}

export const payments: IPrisPayment[] = [
  { identifier: "PaymenMethodID", field: "Payment method", canEdit: false },
  {
    identifier: "PaymentMethodDetails",
    field: "Payment method details",
    canEdit: false,
  },
  { identifier: "PaymentDate", field: "Payment date", canEdit: false },
];
