import React from "react";
import {
  Stack,
  Typography,
  IconButton,
  Box,
  TextField,
  Button,
  Container,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import Modal from "components/ModalComponent";
import { textFieldProps } from "../ColumnsForDataGrid";
import useAppDispatch from "hooks/useAppDispatch";
import { updateProducts } from "store/catalog/thunks";
import useAppSelector from "hooks/useAppSelector";
import { selectCatalogState } from "store/catalog";
import { displaySnackbar } from "store/snackbar";
import { ICatalogProduct } from "store/catalog/interfaces";

const UpdatePackageDimension = ({
  id,
  width,
  length,
  height,
  weight,
}: {
  id: number;
  width: number;
  length: number;
  height: number;
  weight: string;
}): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [packingLength, setPackingLength] = useState<number>(length);
  const [packagingWidth, setPackingWidth] = useState<number>(width);
  const [packingHeight, setPackingHeight] = useState<number>(height);
  const [packingWeight, setPackingWeight] = useState<string>(weight);

  const dispatch = useAppDispatch();
  const { catalogProducts } = useAppSelector(selectCatalogState);

  const handleClickOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const foundProduct = catalogProducts.find((prod) => prod.id === id);
    const {
      sku,
      name,
      cost_shipping,
      price_manual_override,
      price_on_sale,
      description,
    } = foundProduct as ICatalogProduct;

    await dispatch(
      updateProducts({
        products: [
          {
            id,
            sku,
            name,
            cost_shipping,
            price_manual_override,
            price_on_sale,
            descriptions: [description],
            packaging_width: packagingWidth,
            packaging_length: packingLength,
            packaging_height: packingHeight,
            packaging_weight: packingWeight,
          },
        ],
      }),
    )
      .unwrap()
      .finally(() => {
        setModalOpen(false);
        dispatch(
          displaySnackbar({
            message: "Product updated successfully",
            action: null,
            open: false,
          }),
        );
      });
  };

  return (
    <>
      <Stack direction="row" justifyItems="center">
        <Stack>
          <Typography fontSize={13}>
            {length / 10} *{width / 10} *{height / 10} cm
          </Typography>

          <Typography fontSize={13}>
            {weight ? Number.parseFloat(weight).toFixed(1) : -1} kg
          </Typography>
        </Stack>
        <IconButton
          name="Edit Package Dimension and weight"
          onClick={handleClickOpen}
        >
          <EditIcon sx={{ fontSize: 13 }} />
        </IconButton>
      </Stack>
      <Modal
        title="Edit Package Dimension and Weight"
        isOpen={modalOpen}
        handleClose={handleClose}
      >
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              onSubmit={handleOnSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                label="Length(mm)"
                type="number"
                id="length"
                value={packingLength ?? 0}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => setPackingLength(+e.target.value)}
                {...textFieldProps}
              />
              <TextField
                label="Height(mm)"
                type="number"
                id="height"
                value={packingHeight ?? 0}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => setPackingHeight(+e.target.value)}
                {...textFieldProps}
              />
              <TextField
                label="Width(mm)"
                type="number"
                id="width"
                value={packagingWidth ?? 0}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => setPackingWidth(+e.target.value)}
                {...textFieldProps}
              />
              <TextField
                label="Weight(kg)"
                type="number"
                id="weight"
                value={packingWeight ?? 0}
                inputProps={{
                  min: 0,
                  step: ".1",
                }}
                onChange={(e) =>
                  setPackingWeight(parseFloat(e.target.value ?? "").toFixed(3))
                }
                {...textFieldProps}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Container>
      </Modal>
    </>
  );
};
export default UpdatePackageDimension;
