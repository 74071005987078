import { createAsyncThunk } from "@reduxjs/toolkit";
import procurementProducts from "services/procurement-products";
import { displaySnackbar } from "store/snackbar";
import { setLoading } from ".";

export const fetchProcurementsProducts = createAsyncThunk(
  "procurementProducts/fetchProcurementsProducts",
  async (queryParams: URLSearchParams, { dispatch }) => {
    dispatch(setLoading(true));

    const result = await procurementProducts
      .service()
      .fetchProcurementsProducts(queryParams);

    dispatch(setLoading(false));
    return result;
  },
);

export const addToCatalog = createAsyncThunk(
  "procurementProducts/addToCatalog",
  async (ids: number[], { dispatch }) => {
    dispatch(setLoading(true));

    let message = "Product(s) added";

    try {
      await procurementProducts.service().addToCatalog(ids);
      dispatch(fetchProcurementsProducts(new URLSearchParams()));
    } catch (error) {
      message =
        "Could not add product(s). Some might already be in your catalog.";
      throw error;
    }

    dispatch(
      displaySnackbar({
        message: message,
        action: null,
        open: false,
      }),
    );
    dispatch(setLoading(false));
  },
);
