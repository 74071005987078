import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { dataString } from "./dataInfo";
import TransferList from "./TransferList";
import config from "../../../../config";
import { endpoint } from "constants/endpoint";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface ModalContainerUpdateProprs {
  rowsModal: any[];
  shortName: string;
  title: string;
  created: string;
  updated: string;
  email: string;
  prefix: string;
  suffix: string;
  homepage: string;
  login: string;
  username: string;
  password: string;
  search: string;
  supplierType: string;
  skuReuse: boolean;
  comments: string;
  procurementNotes: string;
  proMinOrderVal: number;
  primaryLan: string;
  priority: string;
  reuseImages: boolean;
  reuseFiles: boolean;
  reuseDescription: boolean;
  phasedOut: boolean;
  reuseRRP: boolean;
  reuseRRPfactor: string;
  salePriceFactor: string;
  allowRounding: boolean;
  procurementCost: string;
  standardPrice: string;
  pricingRule: string;
  pricingRuleByEcom: string;
  pricingRuleByEcomInternal: string;
  stockStatus: boolean;
  stockQuantity: boolean;
  feedUpdate: number;
  currency: number;
  catalogCatagories: any[];
  supplierIndex: number;
  onImportWipeImages: string;
  onImportWipeAttachments: string;
  onImportWipeAttributes: string;
  onImportSkipProcessingCategories: string;
  onImportSkipProcessingProducts: string;
  onImportSkipProcessingRelatedProducts: string;
  onImportSkipUpdatingProducts: string;
  onImportSkipUpdatingNotScrapedproducts: string;
  onImportSkipCreatingNewProducts: string;

  setShortName: Dispatch<SetStateAction<string>>;
  setSupplierIndex: Dispatch<SetStateAction<number>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setPrefix: Dispatch<SetStateAction<string>>;
  setSuffix: Dispatch<SetStateAction<string>>;
  setTitle: Dispatch<SetStateAction<string>>;
  setHomepage: Dispatch<SetStateAction<string>>;
  setLogin: Dispatch<SetStateAction<string>>;
  setUsername: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
  setSearch: Dispatch<SetStateAction<string>>;
  setSupplierType: Dispatch<SetStateAction<string>>;
  setSkuReuse: Dispatch<SetStateAction<boolean>>;
  setComments: Dispatch<SetStateAction<string>>;
  setProcurementNotes: Dispatch<SetStateAction<string>>;
  setProMinOrderVal: Dispatch<SetStateAction<number>>;
  setPhasedOut: Dispatch<SetStateAction<boolean>>;
  setPrimaryLan: Dispatch<SetStateAction<string>>;
  setPriority: Dispatch<SetStateAction<string>>;
  setReuseImages: Dispatch<SetStateAction<boolean>>;
  setReuseFiles: Dispatch<SetStateAction<boolean>>;
  setReuseDescription: Dispatch<SetStateAction<boolean>>;
  setReuseRRP: Dispatch<SetStateAction<boolean>>;
  setReuseRRPfactor: Dispatch<SetStateAction<string>>;
  setSalePriceFactor: Dispatch<SetStateAction<string>>;
  setAllowRounding: Dispatch<SetStateAction<boolean>>;
  setProcurementCost: Dispatch<SetStateAction<string>>;
  setStandardPrice: Dispatch<SetStateAction<string>>;
  setPricingRule: Dispatch<SetStateAction<string>>;
  setPricingRuleByEcom: Dispatch<SetStateAction<string>>;
  setPricingRuleByEcomInternal: Dispatch<SetStateAction<string>>;
  setStockStatus: Dispatch<SetStateAction<boolean>>;
  setStockQuantity: Dispatch<SetStateAction<boolean>>;
  setFeedUpdate: Dispatch<SetStateAction<number>>;
  setCurrency: Dispatch<SetStateAction<number>>;
  setCatalogCatagories: Dispatch<SetStateAction<any[]>>;
  setOnImportWipeImages: React.Dispatch<React.SetStateAction<string>>;
  setOnImportWipeAttachments: React.Dispatch<React.SetStateAction<string>>;
  setOnImportWipeAttributes: React.Dispatch<React.SetStateAction<string>>;
  setOnImportSkipProcessingCategories: React.Dispatch<
    React.SetStateAction<string>
  >;
  setOnImportSkipProcessingProducts: React.Dispatch<
    React.SetStateAction<string>
  >;
  setOnImportSkipProcessingRelatedProducts: React.Dispatch<
    React.SetStateAction<string>
  >;
  setOnImportSkipUpdatingProducts: React.Dispatch<React.SetStateAction<string>>;
  setOnImportSkipUpdatingNotScrapedProducts: React.Dispatch<
    React.SetStateAction<string>
  >;
  setOnImportSkipCreatingNewProducts: React.Dispatch<
    React.SetStateAction<string>
  >;
}
interface ModalContainerUpdateProps {
  // Define all the props here, including handleUpdateSupplier
  handleUpdateSupplier: () => void;
  handleCheckboxSKU: () => void;
  handleTitleChange: () => void;
  handleShortNameChange: () => void;
  handleSupplierIndexChange: () => void;
  handlePhasedOut: () => void;
  handleEmailChange: () => void;
  handlePrefixChange: () => void;
  handleSuffixChange: () => void;
  handlePriorityChange: () => void;
  handleSupplierType: () => void;
  handleImageChange: () => void;
  handleFilesChange: () => void;
  handleDescriptionChange: () => void;
  handleRRPChange: () => void;
  handleSaleRRPFactorChange: () => void;
  handlePricingRuleChange: () => void;
  handleSalePriceFactorChange: () => void;
  handleProCostChange: () => void;
  handleStandCostChange: () => void;
  handleCheckboxStockStatus: () => void;
  handleCheckboxStockQuantity: () => void;
  handleHomepageChange: () => void;
  handleLoginChange: () => void;
  handleUsernameChange: () => void;
  handlePasswordChange: () => void;
  handleCommentsChange: () => void;
  handleProcurementNotesChange: () => void;
  handleProOrderValChange: () => void;
  handleCurrencyChange: () => void;
  handleFeedChange: () => void;
  handleOnImportWipeImagesChange: () => void;
  handleOnImportWipeAttachmentsChange: () => void;
  handleOnImportWipeAttributesChange: () => void;
  handleOnImportSkipProcessingCategoriesChange: () => void;
  handleOnImportSkipProcessingProductsChange: () => void;
  handleOnImportSkipProcessingRelatedProductsChange: () => void;
  handleOnImportSkipUpdatingProductsChange: () => void;
  handleOnImportSkipUpdatingNotScrapedProductsChange: () => void;
  handleOnImportSkipCreatingNewProductsChange: () => void;
}

const ModalContainerUpdate = (
  props: ModalContainerUpdateProprs,
): JSX.Element => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxSKU = (event: { target: { checked: any } }) => {
    setIsChecked(event.target.checked);
  };
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setTitle(event.target.value);
  };
  const handleShortNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setShortName(event.target.value);
  };
  const handleSupplierIndexChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setSupplierIndex(+event.target.value);
  };
  const handlePhasedOut = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setPhasedOut(event.target.checked);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setEmail(event.target.value);
  };
  const handlePrefixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setPrefix(event.target.value);
  };
  const handleSuffixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setSuffix(event.target.value);
  };
  const handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setPriority(event.target.value);
  };
  const handleSupplierType = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setSupplierType(event.target.value);
  };
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setReuseImages(event.target.checked);
  };
  const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setReuseFiles(event.target.checked);
  };
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setReuseDescription(event.target.checked);
  };
  const handleRRPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setReuseRRP(event.target.checked);
  };
  const handleAllowRoundingChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setAllowRounding(event.target.checked);
  };
  const handleSaleRRPFactorChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setReuseRRPfactor(event.target.value);
  };
  const handlePricingRuleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setPricingRule(event.target.value);
  };
  const handleSalePriceFactorChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setSalePriceFactor(event.target.value);
  };
  const handleProCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setProcurementCost(event.target.value);
  };
  const handleStandCostChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setStandardPrice(event.target.value);
  };
  const handleCheckboxStockStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setStockStatus(event.target.checked);
  };
  const handleCheckboxStockQuantity = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setStockQuantity(event.target.checked);
  };
  const handleHomepageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setHomepage(event.target.value);
  };
  const handleLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setLogin(event.target.value);
  };
  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setUsername(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setPassword(event.target.value);
  };
  const handleURLsearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setSearch(event.target.value);
  };
  const handleCommentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setComments(event.target.value);
  };
  const handleProcurementNotesChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setProcurementNotes(event.target.value);
  };
  const handleProOrderValChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setProMinOrderVal(+event.target.value);
  };
  const handleCurrencyChange = (event: { target: { value: any } }) => {
    props.setCurrency(event.target.value);
  };
  const handleFeedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setFeedUpdate(+event.target.value);
  };
  const handleOnImportWipeImagesChange = (event: {
    target: { value: string };
  }) => {
    props.setOnImportWipeImages(event.target.value);
  };
  const handleOnImportWipeAttachmentsChange = (event: {
    target: { value: string };
  }) => {
    props.setOnImportWipeAttachments(event.target.value);
  };
  const handleOnImportWipeAttributesChange = (event: {
    target: { value: string };
  }) => {
    props.setOnImportWipeAttributes(event.target.value);
  };
  const handleOnImportSkipProcessingCategoriesChange = (event: {
    target: { value: string };
  }) => {
    props.setOnImportSkipProcessingCategories(event.target.value);
  };
  const handleOnImportSkipProcessingProductsChange = (event: {
    target: { value: string };
  }) => {
    props.setOnImportSkipProcessingProducts(event.target.value);
  };
  const handleOnImportSkipProcessingRelatedProductsChange = (event: {
    target: { value: string };
  }) => {
    props.setOnImportSkipProcessingRelatedProducts(event.target.value);
  };
  const handleOnImportSkipUpdatingProductsChange = (event: {
    target: { value: string };
  }) => {
    props.setOnImportSkipUpdatingProducts(event.target.value);
  };
  const handleOnImportSkipUpdatingNotScrapedProductsChange = (event: {
    target: { value: string };
  }) => {
    props.setOnImportSkipUpdatingNotScrapedProducts(event.target.value);
  };
  const handleOnImportSkipCreatingNewProductsChange = (event: {
    target: { value: string };
  }) => {
    props.setOnImportSkipCreatingNewProducts(event.target.value);
  };
  useEffect(() => {
    props.prefix || props.suffix ? setIsChecked(true) : null;
  }, [props.prefix, props.suffix]);

  const customTheme = createTheme({
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: "11px",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: "12px",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: "12px",
            // fontWeight: "bold",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: "12px",
            fontWeight: "bold",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: "12px",
          },
        },
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <hr />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px",
          }}
        >
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.modalTitle}
              />
              <TextField
                value={props.title || ""}
                onChange={handleTitleChange}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                }}
              />
            </FormGroup>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.modalShortname}
              />
              <TextField
                value={props.shortName || ""}
                onChange={handleShortNameChange}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                }}
              />
            </FormGroup>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.modalSupplierIndex}
              />
              <input
                type="number"
                value={props.supplierIndex}
                onChange={handleSupplierIndexChange}
                style={{ width: "70px" }}
              />
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforSupplierIndex}
            </FormHelperText>
          </Box>
        </Box>
        <hr />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={props.phasedOut} onChange={handlePhasedOut} />
            }
            label="Phased out"
          />
        </FormGroup>
        <FormHelperText>{dataString.helperTextforPhasedOut}</FormHelperText>
        <hr />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px",
          }}
        >
          <Box>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Priority
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={props.priority}
                onChange={handlePriorityChange}
              >
                <FormControlLabel value="LOW" control={<Radio />} label="Low" />
                <FormControlLabel
                  value="MED"
                  control={<Radio />}
                  label="Medium"
                />
                <FormControlLabel
                  value="HIGH"
                  control={<Radio />}
                  label="High"
                />
                <FormControlLabel
                  value="TBD"
                  control={<Radio />}
                  label="Decide later"
                />
              </RadioGroup>
              <FormHelperText>
                {dataString.helperTextforPriority}
              </FormHelperText>
            </FormControl>
          </Box>
          <Box>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Type of supplier
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue={props.supplierType}
                onChange={handleSupplierType}
              >
                <FormControlLabel
                  value="RESELLER"
                  control={<Radio />}
                  label="Wholesale"
                />
                <FormControlLabel
                  value="FACTORY"
                  control={<Radio />}
                  label="Factory / Manufacturer"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <hr />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={isChecked} onChange={handleCheckboxSKU} />
            }
            label="Catalog SKU = Supplier SKU"
          />
        </FormGroup>
        <FormHelperText>{dataString.helperTextforCatalogSKU}</FormHelperText>
        {isChecked ? (
          <Box>
            <Box>
              {dataString.modalPrefix}
              <TextField
                value={props.prefix || ""}
                onChange={handlePrefixChange}
                variant="outlined"
                size="small"
                fullWidth
                helperText={dataString.helperTextforPrefix}
                sx={{
                  "& .MuiInputBase-root": {
                    width: "120px",
                  },
                  padding: "5px",
                }}
              />
            </Box>
            <Box>
              {dataString.modalSuffix}
              <TextField
                value={props.suffix || ""}
                onChange={handleSuffixChange}
                variant="outlined"
                size="small"
                fullWidth
                helperText={dataString.helperTextforSuffix}
                sx={{
                  "& .MuiInputBase-root": {
                    width: "120px",
                  },
                  padding: "5px",
                }}
              />
            </Box>
          </Box>
        ) : null}
        <hr />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px",
            marginRight: "5px",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.reuseImages}
                  onChange={handleImageChange}
                />
              }
              label="Images for use"
            />
            <FormHelperText>
              {dataString.helperTextforImagesforUse}
            </FormHelperText>
          </FormGroup>
          <FormGroup sx={{ marginRight: "8px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.reuseFiles}
                  onChange={handleFilesChange}
                />
              }
              label="Reuse Files"
            />
            <FormHelperText>
              {dataString.helperTextforReuseFiles}
            </FormHelperText>
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.reuseDescription}
                  onChange={handleDescriptionChange}
                />
              }
              label="Reuse Descriptions"
            />
            <FormHelperText>
              {dataString.helperTextforReuseDescription}
            </FormHelperText>
          </FormGroup>
        </Box>
        <hr />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={props.reuseRRP} onChange={handleRRPChange} />
              }
              label="Reuse RRP"
            />
            <FormHelperText>
              {dataString.helperTextforReuseRRP}
              <a
                href={`${config.api}${endpoint.admin_catalog_markup}`}
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </FormHelperText>
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={<Typography></Typography>}
              label="RRP Factor:"
            />
            <input
              type="number"
              value={props.reuseRRPfactor}
              onChange={handleSaleRRPFactorChange}
              style={{ width: "70px" }}
            />
            <FormHelperText>{dataString.helperTextforRRPfactor}</FormHelperText>
          </FormGroup>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormGroup sx={{ marginTop: "10px" }}>
            <FormControlLabel
              control={<Typography></Typography>}
              label="RRP On Sale Factor:"
            />
            <input
              type="number"
              value={props.salePriceFactor}
              onChange={handleSalePriceFactorChange}
              style={{ width: "70px" }}
            />
            <FormHelperText>
              {dataString.helperTextforRRPsalesFactor}
            </FormHelperText>
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.allowRounding}
                  onChange={handleAllowRoundingChange}
                />
              }
              label="Allow rounding of RRP"
            />
            <FormHelperText>
              {dataString.helperTextforAllowRoundingRRP}
            </FormHelperText>
          </FormGroup>
        </Box>
        <hr />
        <Box>
          <TextField
            label="Custom Pricing Rules"
            multiline
            rows={4}
            variant="outlined"
            helperText={dataString.helperTextforCustomPricingRule}
            value={props.pricingRule || ""}
            onChange={handlePricingRuleChange}
          />
          <FormGroup sx={{ marginTop: "10px" }}>
            <FormControlLabel
              control={<Typography></Typography>}
              label="Custom Pricing Rules Acknowledged by Ecombooster:"
            />
            <FormHelperText>
              {dataString.helperTextforCustomRuleByEcombooster}
            </FormHelperText>
          </FormGroup>
          <FormGroup sx={{ marginTop: "10px" }}>
            <FormControlLabel
              control={<Typography></Typography>}
              label="Custom Pricing Rules Ecombooster internal:"
            />
            <FormHelperText>
              {dataString.helperTextforCustomRuleByEcomboosterInternal}
            </FormHelperText>
          </FormGroup>
        </Box>
        <hr />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormGroup sx={{ marginTop: "10px" }}>
            <FormControlLabel
              control={<Typography></Typography>}
              label="Price Factor based on Procurement Cost:"
            />
            <input
              type="number"
              value={props.procurementCost}
              onChange={handleProCostChange}
              style={{ width: "70px" }}
            />
            <FormHelperText>
              {dataString.helperTextforPriceFactor}
              <a
                href={`${config.api}${endpoint.admin_catalog_markup}`}
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </FormHelperText>
          </FormGroup>
          <FormGroup sx={{ marginTop: "10px" }}>
            <FormControlLabel
              control={<Typography></Typography>}
              label="Sale price as factor of Standard Price:"
            />
            <input
              type="number"
              value={props.standardPrice}
              onChange={handleStandCostChange}
              style={{ width: "70px" }}
            />
            <FormHelperText>
              {dataString.helperTextforSalesPrice}
            </FormHelperText>
          </FormGroup>
        </Box>
        <hr />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: "10px",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={handleCheckboxStockStatus} />}
              label="Expose Stock Status"
            />
            <FormHelperText>
              {dataString.helperTextforStockStatus}
            </FormHelperText>
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={handleCheckboxStockQuantity} />}
              label="Expose Stock Quantity"
            />
            <FormHelperText>
              {dataString.helperTextforStockQuantity}
            </FormHelperText>
          </FormGroup>
        </Box>
        <hr />
        <Box>
          <FormLabel>Homepage:</FormLabel>
          <FormControl>
            <FormHelperText>
              <a href={props.homepage || ""} target="_blank" rel="noreferrer">
                {props.homepage || ""}
              </a>{" "}
            </FormHelperText>
            <TextField
              value={props.homepage || ""}
              onChange={handleHomepageChange}
              variant="outlined"
              size="small"
              helperText={dataString.helperTextforWebsite}
              sx={{
                "& .MuiInputBase-root": {
                  width: "270px",
                },
                padding: "5px",
              }}
            />
          </FormControl>
        </Box>
        <hr />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px",
          }}
        >
          <Box>
            <FormLabel>login:</FormLabel>
            <FormControl>
              <FormHelperText>
                <a href={props.login || ""} target="_blank" rel="noreferrer">
                  {props.login || ""}
                </a>{" "}
              </FormHelperText>
              <TextField
                value={props.login || ""}
                onChange={handleLoginChange}
                variant="outlined"
                size="small"
                helperText={dataString.helperTextforLogin}
                sx={{
                  "& .MuiInputBase-root": {
                    width: "270px",
                  },
                  padding: "5px",
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormLabel> username:</FormLabel>
            <TextField
              value={props.username || ""}
              onChange={handleUsernameChange}
              variant="outlined"
              size="small"
              sx={{
                "& .MuiInputBase-root": {
                  width: "270px",
                },
                padding: "5px",
              }}
            />
          </Box>
          <Box>
            <FormLabel>password:</FormLabel>
            <TextField
              value={props.password || ""}
              onChange={handlePasswordChange}
              variant="outlined"
              size="small"
              sx={{
                "& .MuiInputBase-root": {
                  width: "270px",
                },
                padding: "5px",
              }}
            />
          </Box>
        </Box>
        <hr />
        <Box>
          <FormLabel>URL Search:</FormLabel>
          <TextField
            value={props.search || ""}
            onChange={handleURLsearchChange}
            variant="outlined"
            size="small"
            helperText={dataString.helperTextforURLsearch}
            sx={{
              "& .MuiInputBase-root": {
                width: "270px",
              },
              padding: "5px",
            }}
          />
        </Box>
        <hr />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <FormLabel sx={{ marginRight: "15px" }}>
            Default catalog categories:
          </FormLabel>
          <Box sx={{ display: "flex", direction: "row" }}>
            <TransferList setCatalogCatagories={props.setCatalogCatagories} />
            <a
              href="https://app.ecombooster.io/admin/catalog/category/add/?_to_field=id&_popup=1"
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="contained">
                <AddIcon />
              </Button>
            </a>
          </Box>
        </Box>
        <Box data-testid="boxempty" sx={{ padding: "0.85rem" }}></Box>
        <hr />
        <Box>
          <TextField
            label="Comments..."
            multiline
            rows={4}
            variant="outlined"
            value={props.comments || ""}
            onChange={handleCommentsChange}
          />
        </Box>
        <hr />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px",
          }}
        >
          <Box>
            <FormLabel>{dataString.modalEmail}</FormLabel>
            <TextField
              value={props.email || ""}
              onChange={handleEmailChange}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Box>
          <TextField
            label="Purchase note..."
            multiline
            rows={4}
            variant="outlined"
            value={props.procurementNotes || ""}
            onChange={handleProcurementNotesChange}
            helperText={dataString.helperTextforPurchaseNote}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={<Typography></Typography>}
              label="Minimum order value:"
            />
            <input
              type="number"
              value={props.proMinOrderVal}
              onChange={handleProOrderValChange}
              style={{ width: "70px" }}
            />
            <FormHelperText>
              {dataString.helperTextforMinimumOrder}
            </FormHelperText>
          </FormGroup>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Currency</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.currency}
              onChange={handleCurrencyChange}
              label="currency"
              sx={{
                width: "100px",
              }}
            >
              <MenuItem value={5}>DKK</MenuItem>
              <MenuItem value={1}>EUR</MenuItem>
              <MenuItem value={2}>GBP</MenuItem>
              <MenuItem value={6}>NOK</MenuItem>
              <MenuItem value={4}>SEK</MenuItem>
              <MenuItem value={3}>USD</MenuItem>
            </Select>
            <FormHelperText>{dataString.helperTextforCurrency}</FormHelperText>
          </FormControl>
        </Box>
        <hr />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px",
            width: "50%",
          }}
        >
          <Box>
            <FormLabel>Skapad: </FormLabel>
            <FormLabel>{props.created || ""} </FormLabel>
          </Box>
          <Box>
            <FormLabel>Updated: </FormLabel>
            <FormLabel>{props.updated || ""} </FormLabel>
          </Box>
        </Box>
        <hr />
        <Box>
          <FormGroup>
            <FormControlLabel
              control={<Typography></Typography>}
              label="Feed Warning level, No of days between feed updates:"
            />
            <input
              type="number"
              value={props.feedUpdate}
              onChange={handleFeedChange}
              style={{ width: "70px" }}
            />
            <FormHelperText>{dataString.helperTextforFeed}</FormHelperText>
          </FormGroup>
        </Box>
        <hr />
        <br />
        <Box>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Import Wipe Images
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.onImportWipeImages}
              onChange={handleOnImportWipeImagesChange}
              label="Import Wipe Images"
              sx={{
                width: "300px",
              }}
            >
              <MenuItem value="KEEP">Do not wipe data</MenuItem>
              <MenuItem value="WIPE_NEXT">Wipe on next import</MenuItem>
            </Select>
            <FormHelperText>
              {dataString.helperTextforImportWipeImages}
            </FormHelperText>
          </FormControl>
          <hr />
          <br />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Import Wipe Attachments
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.onImportWipeAttachments}
              onChange={handleOnImportWipeAttachmentsChange}
              label="Import Wipe Attachments"
              sx={{
                width: "300px",
              }}
            >
              <MenuItem value="KEEP">Do not wipe data</MenuItem>
              <MenuItem value="WIPE_NEXT">Wipe on next import</MenuItem>
            </Select>
            <FormHelperText>
              {dataString.helperTextforImportWipeAttachments}
            </FormHelperText>
          </FormControl>
          <hr />
          <br />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Import Wipe Attributes
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.onImportWipeAttributes}
              onChange={handleOnImportWipeAttributesChange}
              label="Import Wipe Attributes"
              sx={{
                width: "300px",
              }}
            >
              <MenuItem value="KEEP">Do not wipe data</MenuItem>
              <MenuItem value="WIPE_NEXT">Wipe on next import</MenuItem>
            </Select>
            <FormHelperText>
              {dataString.helperTextforImportWipeAttributes}
            </FormHelperText>
          </FormControl>
          <hr />
          <br />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Import Skip Processing Categories
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.onImportSkipProcessingCategories}
              onChange={handleOnImportSkipProcessingCategoriesChange}
              label="Import Skip Processing Categories"
              sx={{
                width: "300px",
              }}
            >
              <MenuItem value="NO_SKIP">Do not skip</MenuItem>
              <MenuItem value="SKIP_NEXT">Skip on next import</MenuItem>
              <MenuItem value="SKIP_FOREVER">Skip until flag changed</MenuItem>
            </Select>
            <FormHelperText>
              {dataString.helperTextforImportSkipProcessingCategories}
            </FormHelperText>
          </FormControl>
          <hr />
          <br />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Import Skip Processing Products
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.onImportSkipProcessingProducts}
              onChange={handleOnImportSkipProcessingProductsChange}
              label="Import Skip Processing Products"
              sx={{
                width: "300px",
              }}
            >
              <MenuItem value="NO_SKIP">Do not skip</MenuItem>
              <MenuItem value="SKIP_NEXT">Skip on next import</MenuItem>
              <MenuItem value="SKIP_FOREVER">Skip until flag changed</MenuItem>
            </Select>
            <FormHelperText>
              {dataString.helperTextforImportSkipProcessingProducts}
            </FormHelperText>
          </FormControl>

          <hr />
          <br />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Import Skip Processing Related Products
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.onImportSkipProcessingRelatedProducts}
              onChange={handleOnImportSkipProcessingRelatedProductsChange}
              label="Import Skip Processing Related Products"
              sx={{
                width: "400px",
              }}
            >
              <MenuItem value="NO_SKIP">Do not skip</MenuItem>
              <MenuItem value="SKIP_NEXT">Skip on next import</MenuItem>
              <MenuItem value="SKIP_FOREVER">Skip until flag changed</MenuItem>
            </Select>
            <FormHelperText>
              {dataString.helperTextforImportSkipProcessingRelatedProducts}
            </FormHelperText>
          </FormControl>
          <hr />
          <br />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Import Skip Updating Products
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.onImportSkipUpdatingProducts}
              onChange={handleOnImportSkipUpdatingProductsChange}
              label="Import Skip UpdatingProducts"
              sx={{
                width: "300px",
              }}
            >
              <MenuItem value="NO_SKIP">Do not skip</MenuItem>
              <MenuItem value="SKIP_NEXT">Skip on next import</MenuItem>
              <MenuItem value="SKIP_FOREVER">Skip until flag changed</MenuItem>
            </Select>
            <FormHelperText>
              {dataString.helperTextforImportSkipProcessingProducts}
            </FormHelperText>
          </FormControl>

          <hr />
          <br />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Import Skip Updating Not Scraped Products
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.onImportSkipUpdatingNotScrapedproducts}
              onChange={handleOnImportSkipUpdatingNotScrapedProductsChange}
              label="Import Skip Updating Not Scraped Products"
              sx={{
                width: "400px",
              }}
            >
              <MenuItem value="NO_SKIP">Do not skip</MenuItem>
              <MenuItem value="SKIP_NEXT">Skip on next import</MenuItem>
              <MenuItem value="SKIP_FOREVER">Skip until flag changed</MenuItem>
            </Select>
            <FormHelperText>
              {dataString.helperTextforImportSkipUpdatingNotScrapedProducts}
            </FormHelperText>
          </FormControl>

          <hr />
          <br />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Import Skip Creating New Products
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.onImportSkipCreatingNewProducts}
              onChange={handleOnImportSkipCreatingNewProductsChange}
              label="Import Skip Creating New Products"
              sx={{
                width: "300px",
              }}
            >
              <MenuItem value="NO_SKIP">Do not skip</MenuItem>
              <MenuItem value="SKIP_NEXT">Skip on next import</MenuItem>
              <MenuItem value="SKIP_FOREVER">Skip until flag changed</MenuItem>
            </Select>
            <FormHelperText>
              {dataString.helperTextforImportSkipCreatingNewProducts}
            </FormHelperText>
          </FormControl>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default ModalContainerUpdate;
