import React from "react";
import Paper from "@mui/material/Paper";
import useAppSelector from "hooks/useAppSelector";
import { selectSuppliers } from "store/suppliers";
import { SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_PRODUCTS } from "constants/urls";
import { recently } from "utils/time/recently";
import { Box, Typography } from "@mui/material";
import theme from "theme/theme";
import Row from "../Row";

const MySuppliers = (): JSX.Element => {
  const suppliers = useAppSelector(selectSuppliers);

  return (
    <Paper elevation={0} sx={{ padding: theme.spacing(1) }}>
      <Typography
        sx={{
          fontSize: theme.typography.subtitle1.fontSize,
          fontWeight: theme.typography.fontWeightBold,
          marginBottom: theme.spacing(2),
        }}
      >
        My suppliers
      </Typography>

      <div className="suppliers">
        {Object.entries(suppliers)?.map(([key, value], index) => (
          <Box key={value.id}>
            <Row
              caption={`New products from supplier ${value.name}`}
              pathnameUrl={SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_PRODUCTS}
              serachPhrase={`?supplier=${
                value.name_abbrev
              }&created_at__gte=${recently()}&created_at__lte=`}
            >
              {value.new_products}
            </Row>
          </Box>
        ))}
      </div>
    </Paper>
  );
};

export default MySuppliers;
