import React from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import { Box, Typography } from "@mui/material";
import theme from "theme/theme";
import { IUser } from "store/auth/interfaces";
import useAppSelector from "hooks/useAppSelector";
import { selectAuthenticatedUser } from "store/auth";
import { shallowEqual, useSelector } from "react-redux";
import {
  selectCellValue,
  setShowNotification,
  setUpdateMessage,
} from "store/catalog-bulk-description";
import useAppDispatch from "hooks/useAppDispatch";
import {
  fetchBulkDescriptionProducts,
  updateBulkDescription,
} from "store/catalog-bulk-description/thunk";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import SaveIcon from "@mui/icons-material/Save";

//Editor component and edit/update -
//for the description inside the editor

const MemoizedJoditEditor = React.memo(JoditEditor);

const EditBulkDescription = ({ props }) => {
  const editor = useRef(null);

  const dispatch = useAppDispatch();
  const cellValue = useSelector(selectCellValue, shallowEqual);

  const user = useAppSelector(selectAuthenticatedUser, shallowEqual) as IUser;

  const [newBulkDescriptionValue, setNewBulkDescriptionValue] =
    useState<string>();

  const config = useMemo(
    () => ({
      readonly: false,
      height: 550,
      uploader: {
        insertImageAsBase64URI: true,
      },
      statusbar: false,
      tabIndex: 1,
      showTooltip: true,
      showTooltipDelay: 0,
      toolbarSticky: true,
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      toolbarAdaptive: false,
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "align",
        "indent",
        "outdent",
        "ul",
        "ol",
        "table",
        "|",
        "hr",
        "eraser",
        "|",
        "font",
        "fontsize",
        "paragraph",
        "lineHeight",
        "spellcheck",
        "|",
        "image",
      ],
      removeButtons: [
        "fullsize",
        "preview",
        "file",
        "video",
        "print",
        "find",
        "copy",
        "paste",
        "selectAll",
        "link",
      ],
    }),
    [],
  );

  const handleBlur = useCallback((newValue) => {
    setNewBulkDescriptionValue(newValue);
  }, []);

  const processRowUpdate = useCallback(
    (newOnBlurInfo: string, cellInfo: object) => {
      const account = user.id;
      const company = user.company as number;

      const params = {
        account: account as number,
        company: company as number,
        language: cellInfo["language"] || "SV",
        machine_translation: cellInfo["machine_translation"] || true,
        original_content_from_supplier:
          cellInfo["original_content_from_supplier"] || true,
        source: cellInfo["source"] || "own_content",
        name: cellInfo["name"] || "",
        excerpt: cellInfo["excerpt"] || "",
        content: newOnBlurInfo || "",
        meta_title: cellInfo["meta_title"] || "",
        meta_keywords: cellInfo["meta_keywords"] || "",
        meta_description: cellInfo["meta_description"] || "",
        links: cellInfo["links"] || "no",
        product: cellInfo["id"] || 0,
      };

      const id = cellInfo["id"];

      dispatch(updateBulkDescription({ id, params })).then(() => {
        dispatch(setUpdateMessage("Update Successful."));
        dispatch(setShowNotification(true));
      });
      dispatch(fetchBulkDescriptionProducts({}));
    },
    [dispatch],
  );

  return (
    <Typography
      component="div"
      sx={{
        boxSizing: "border-box",
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        wordBreak: "break-word",
        display: "flex",
        flexDirection: "column",
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <MemoizedJoditEditor
        ref={editor}
        value={props}
        config={config}
        onBlur={handleBlur}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <ToolInfoTooltip title="Save description changes">
          <ToolIconContainer
            onClick={() => processRowUpdate(newBulkDescriptionValue, cellValue)}
          >
            <SaveIcon
              fontSize="large"
              sx={{
                color: theme.palette.primary.main,
                stroke: theme.palette.primary.main,
                strokeWidth: 1,
              }}
            />
          </ToolIconContainer>
        </ToolInfoTooltip>
      </Box>
    </Typography>
  );
};

export default EditBulkDescription;
