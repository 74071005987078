import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import { ITaxonomiesDTO } from "store/taxonomies/interfaces";
import { endpoint } from "constants/endpoint";

export interface ITaxonomiesService {
  fetchTaxonomiesData(params: URLSearchParams): Promise<ITaxonomiesDTO>;
  copyMiscGoogleProductsTaxonomyItems(
    ids: [number],
    userId: number,
  ): Promise<number>;
  removeMiscGoogleProductsTaxonomyItems(ids: [number]): Promise<number>;
}

export default new Mockable<ITaxonomiesService>({
  async fetchTaxonomiesData(params): Promise<ITaxonomiesDTO> {
    try {
      const res = await axios(`${config.api}${endpoint.taxonomies}`, {
        params,
        headers: commonHeaders(),
      });
      if (res.status === 200) {
        return res.data;
      }
      return Promise.reject(
        new Error("Unexpected response format in fetchTaxonomiesData"),
      );
    } catch {
      return Promise.reject("Couldn't get taxonomies data");
    }
  },

  async copyMiscGoogleProductsTaxonomyItems(ids, userId): Promise<any> {
    let result = 0;
    ids.forEach(async (taxonomy_id: number) => {
      const params = {
        account: userId,
        taxonomy: taxonomy_id,
      };
      const res = await axios(`${config.api}${endpoint.taxonomies}`, {
        method: "POST",
        headers: commonHeaders(),
        data: JSON.stringify(params),
      });
      if (res.status === 204) {
        result += 1;
      }
    });
    return result;
  },

  async removeMiscGoogleProductsTaxonomyItems(ids): Promise<number> {
    let result = 0;
    const res = await axios(`${config.api}${endpoint.taxonomies}`, {
      method: "GET",
      headers: commonHeaders(),
    });

    if (res.status === 200 && res.data?.results?.length > 0) {
      const selectedIDs = res.data.results.filter(
        (t: { id: number; taxonomy: number }) => ids.includes(t.taxonomy),
      );

      selectedIDs.forEach(async (item: { id: number }) => {
        const res = await axios(
          `${config.api}${endpoint.taxonomies}${item.id}`,
          {
            method: "DELETE",
            headers: commonHeaders(),
          },
        );
        if (res.status === 204) {
          result += 1;
        }
      });
    }
    return result;
  },
});
