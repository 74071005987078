import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { setOpenExportDialog } from "store/catalog";
import useAppDispatch from "hooks/useAppDispatch";
import { publishCatalogProducts } from "store/catalog/thunks";

const actions = {
  "Jetshop - Export product data": "jetshop",
  "Kodmyran - Export product data & images": "kodmyran",
  "Magento - Export product data & images": "magento",
  /* Supported but hidden for now.
  "Magento - Export product data without images and attachments":
    "magento_without_images_and_attachements",
  */
  "Woocommerce - Export product data & images": "woocommerce",
  /* Supported but hidden for now.
  "Woocommerce - Export product data without images and attachments":
    "woocommerce_without_images_and_attachements",
  "Woocommerce - Export product data only stock & price": "woocommerce_stock_and_price",
  */
};

const fakedSpecialActions = ["Add API-key"];

const fakedActions = ["Carismar", "Norce", "Shopify"];

interface IPublishMenuProps {
  anchorEl: HTMLElement;
  setAnchor(value: React.SetStateAction<HTMLElement>): void;
  products(): number[];
  onPublish?(): void;
}

const PublishMenu = ({
  anchorEl,
  setAnchor,
  products,
  onPublish,
}: IPublishMenuProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchor(null);
  };

  const publish = async (action: string) => {
    if (onPublish !== undefined) {
      onPublish();
    }
    handleClose();
    if (products().length == 0) return;

    await dispatch(
      publishCatalogProducts({
        action,
        ids: products(),
      }),
    );

    dispatch(setOpenExportDialog(true));
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        id="publish-positioned-menu"
        aria-labelledby="publish-positioned-button"
      >
        {fakedSpecialActions?.map((label) => (
          <MenuItem disabled key={label}>
            {label}
          </MenuItem>
        ))}
        {Object.entries(actions)?.map(([label, action]) => (
          <MenuItem key={label} onClick={() => publish(action)}>
            {label}
          </MenuItem>
        ))}
        {fakedActions?.map((label) => (
          <MenuItem disabled key={label}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default PublishMenu;
