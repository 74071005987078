const colors: string[] = [
  "#42a5f5",
  "#7e57c2",
  "#66bb6a",
  "#ffa726",
  "#29b6f6",
  "#26a69a",
  "#ffee58",
  "#26c6da",
  "#d4e157",
  "#8d6e63",
  "#ff7043",
  "#78909c",
  "#9ccc65",
  "#26a69a",
  "#5c6bc0",
  "#42a5f5",
  "#ec407a",
  "#4fc3f7",
  "#81c784",
  "#ffd54f",
  "#bdbdbd",
  "#4db6ac",
  "#9575cd",
  "#2196f3",
];

export default colors;
