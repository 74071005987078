import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "assets/images/EcomboosterLogo.png";
import useAppDispatch from "hooks/useAppDispatch";
import { newPassword } from "store/auth/thunks";
import { displaySnackbar } from "store/snackbar";
import {
  Box,
  Button,
  CardMedia,
  TextField,
  TextFieldProps,
  Tooltip,
} from "@mui/material";

const textFieldProps: TextFieldProps = {
  fullWidth: true,
  margin: "normal",
  size: "small",
  variant: "outlined",
};

interface IErrorProp {
  password: boolean;
  password2: boolean;
}

interface IResetPasswordResponse {
  password: string[];
  status: string;
  detail: string;
}

const NewPassword = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [password, setPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");

  const params = useMemo(() => new URLSearchParams(location.search), []);

  const [responseError, setResponseError] = useState<string[]>([]);
  const [error, setError] = useState<IErrorProp>({
    password: false,
    password2: false,
  });

  const validate = useCallback((): boolean => {
    const validated: IErrorProp = {
      password: password.match(/(?!^(\d)+$)^[^\s-]{8,}$/) === null,
      password2: password2 !== password && password2.trim().length > 0,
    };

    setError(validated);
    return !Object.values(validated).some((e) => e === true);
  }, [password, password2]);

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const validated = validate();
    if (validated) {
      setResponseError([]);
      const response = await dispatch(
        newPassword({ token: params.get("token")!, password }),
      );
      const passwordResponse = response.payload as IResetPasswordResponse;
      if (passwordResponse.status === "OK") {
        dispatch(
          displaySnackbar({
            message: "Password has been reset.",
            action: null,
            open: false,
          }),
        );
        navigate("/login");
        return;
      } else if (passwordResponse.detail === "Not found.") {
        setResponseError(["Invalid reset password link."]);
      } else {
        setResponseError(passwordResponse.password || []);
      }
      setError({ password2: true, password: true });
    }
  };

  const ResponseError = (): JSX.Element => {
    return (
      <Box sx={{ color: "error.main" }}>
        {responseError?.map((error) => <p key={error}>{error}</p>)}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "40px 80px",
      }}
    >
      <Box>
        <CardMedia sx={{ marginBottom: "1rem" }}>
          <img width="170px" src={logo} />
        </CardMedia>
        <h4>New password</h4>
        <p>Fill out form below to set a new password.</p>
      </Box>
      <Box component="form" onSubmit={handleOnSubmit}>
        <ResponseError />
        <Tooltip
          placement="bottom-start"
          title={
            <Box
              sx={{
                ul: {
                  margin: 0,
                  paddingLeft: "2em",
                },
              }}
            >
              Password must be:
              <ul>
                <li>at least 8 characters</li>
                <li>not only numbers</li>
              </ul>
            </Box>
          }
        >
          <TextField
            error={error.password}
            label="New Password"
            id="password"
            onChange={(e) => {
              setPassword(e.target.value);
              setError((prev) => ({ ...prev, password: false }));
            }}
            type="password"
            value={password}
            {...textFieldProps}
          />
        </Tooltip>
        <TextField
          error={error.password2}
          label="Confirm New Password"
          id="password2"
          onChange={(e) => {
            setPassword2(e.target.value);
            setError((prev) => ({ ...prev, password2: false }));
          }}
          type="password"
          value={password2}
          {...textFieldProps}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "20px 0 0 0",
          }}
        >
          <Button type="submit" variant="contained">
            Reset password
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NewPassword;
