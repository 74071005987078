import axios from "axios";
import config from "../../../config";
import commonHeaders from "services/http/headers";
import { endpoint } from "constants/endpoint";

export async function fetchUnPickOrder(
  order_item_id: number,
  inventory_transaction_id: number,
): Promise<{ result: string }> {
  try {
    const res = await axios(
      `${config.api}${endpoint.customers_order_items}${order_item_id}/actions/unpick/?inventory_transaction_id=${inventory_transaction_id}`,
      {
        headers: commonHeaders(),
      },
    );

    if (res.status === 200) {
      return res.data;
    } else {
      return Promise.reject(
        new Error(
          "Unexpected response format in >> async function fetchUnPickOrder <<",
        ),
      );
    }
  } catch (err: any) {
    return Promise.reject(err);
  }
}
