import { GridColDef } from "@mui/x-data-grid";

const ColumnsForDataGrid = (): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: "taxonomy_id",
      headerName: "Taxnomy Id",
      flex: 1,
      sortable: true,
    },
    {
      field: "slug_sv",
      flex: 1,
      headerName: "Slug(Swedish)",
      sortable: true,
    },

    {
      field: "slug_en",
      flex: 1,
      headerName: "Slug(English)",
      sortable: true,
    },
    {
      field: "taxonomy_date_version",
      flex: 1,
      headerName: "Taxonomy Date Version",
      sortable: true,
    },
  ];

  return columns;
};

export default ColumnsForDataGrid;
