import React from "react";
import { Container } from "@mui/material";
import theme from "theme/theme";

interface Props {
  children?: any;
}

const GridContainer = ({ children }: Props) => {
  return (
    <Container
      disableGutters
      sx={{
        flexGrow: 1,
        height: ["calc(100% - 280px)", "62vh"],
        background: theme.palette.background.paper,
        borderRadius: "4px",
        margin: 0,
        padding: 0,
        maxWidth: "none !important",
      }}
    >
      {children}
    </Container>
  );
};
export default GridContainer;
