import React from "react";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { selectCatalogState } from "store/catalog";
import {
  fetchProductDescription,
  updateProductDescription,
} from "store/catalog/thunks";
import { displaySnackbar } from "store/snackbar";

const Language = (row: any) => {
  const { selectedRow } = useAppSelector(selectCatalogState);
  const searchParams = useSearchParams();
  const dispatch = useAppDispatch();

  const languages = [
    {
      option: "SV",
      value: "SV",
    },
    { option: "EN", value: "EN" },
    {
      option: "NO",
      value: "NO",
    },
    { option: "DA", value: "DA" },
  ];

  const [language, setLanguage] = useState<string>(row.row.value);

  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newStatus = event.target.value;
    setLanguage(newStatus);
    if (
      row.row.id === selectedRow.id &&
      !!selectedRow &&
      selectedRow[row.row.language] !== event.target.value
    ) {
      await dispatch(
        updateProductDescription({
          ...selectedRow,
          [row.row.field]: event.target.value,
        }),
      )
        .unwrap()
        .finally(() => {
          dispatch(
            displaySnackbar({
              message: "Product updated successfully",
              action: null,
              open: false,
            }),
          );
        });
      dispatch(fetchProductDescription(searchParams.toString()));
    }
  };
  return (
    <select value={language} onChange={handleChange}>
      {languages?.map((option) => (
        <option key={option.option} value={option.value}>
          {option.value}
        </option>
      ))}
    </select>
  );
};
export default Language;
