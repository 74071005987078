import React, { useEffect, useState } from "react";
import useAppSelector from "hooks/useAppSelector";
import { ViewType, selectCatalogState, setColumnOptions } from "store/catalog";
import useAppDispatch from "hooks/useAppDispatch";
import { catalogColumnsArr } from "store/catalog/utils/catalogColumns";
import ColumnsForStocks from "./ColumnsForDataGrid";
import ColumnsForPrices from "./ColumnsForPrices";
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import theme from "theme/theme";
import { GridColDef } from "@mui/x-data-grid-pro";

interface SelectViewOptionProps {
  handleClose: () => void;
  label: ViewType;
}

export default function SelectViewOption({
  handleClose,
  label,
}: SelectViewOptionProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState<string[]>([]);

  let columnsArr: GridColDef[] = [];
  switch (label) {
    case "default":
      columnsArr = catalogColumnsArr;
      break;
    case "stocks":
      columnsArr = ColumnsForStocks();
      break;
    case "prices":
      columnsArr = ColumnsForPrices();
      break;
    default:
      columnsArr = [];
  }

  const { columns } = useAppSelector(selectCatalogState);
  const columnsWithPinned = columnsArr?.map((col) => {
    const found = columns.find((col2) => col2.field === col.field);
    if (found !== undefined) {
      return found;
    } else {
      return col;
    }
  });

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  useEffect(() => {
    const checked: string[] = [];
    columns.forEach((col) => {
      if (col.pinnable) {
        checked.push(col.field);
      }
    });
    setChecked(checked);
  }, [columns]);

  const applySelectedCol = () => {
    const selectedArr = columnsWithPinned.filter((col) =>
      checked.includes(col.field),
    );

    dispatch(setColumnOptions(selectedArr));
    handleClose();
  };

  return (
    <>
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: theme.palette.action.selected,
        }}
      >
        {columnsWithPinned?.map((col) => {
          const labelId = `checkbox-list-label-${col.field}`;
          return (
            <ListItem key={col.field} disablePadding>
              <ListItemButton
                role={undefined}
                onClick={handleToggle(col.field)}
                dense
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(col.field) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={col.field} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Button variant="contained" sx={{ mt: 2 }} onClick={applySelectedCol}>
        Apply
      </Button>
    </>
  );
}
