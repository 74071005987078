import { createAsyncThunk } from "@reduxjs/toolkit";
import procurementFeeds from "services/procurement-feeds";
import { IProcurementSupplierFeedsResponseDTO } from "./interface";

// function getAxiosParams(supplierFeedsFilterParams: ISupplierFeedsFilterParams) {
//   const params = new URLSearchParams();
//   params.append(
//     "offset",
//     (supplierFeedsFilterParams.page * supplierFeedsFilterParams.limit).toString()
//   );
//   params.append("limit", supplierFeedsFilterParams.limit.toString());
//   params.append("supplier__name__icontains", supplierFeedsFilterParams.name.toString());
//   params.append(
//     "feed_date__gte",
//     supplierFeedsFilterParams.procurementCategoryCreatedGte.toString()
//   );
//   params.append(
//     "feed_date__lte",
//     supplierFeedsFilterParams.procurementCategoryCreatedLte.toString()
//   );

//   return params;
// }

export const fetchProcurementSupplierFeedAsync = createAsyncThunk(
  "suppliers/fetchProcurementCategories",
  async (
    searchParams: URLSearchParams,
  ): Promise<IProcurementSupplierFeedsResponseDTO> => {
    //async (): Promise<IProcurementSupplierFeedsResponseDTO> => {
    // const state = thunkAPI.getState() as RootState;
    // const params = getAxiosParams(state.procurementSupplierFeed.filterParams);

    return await procurementFeeds
      .service()
      .fetchProcurementSupplierFeeds(searchParams);
  },
);
