import React, { useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { getSearchData } from "utils/filter-utils/FilterUtils";
import SelectedFilter from "./SelectedFilter";
import {
  IAppliedFilter,
  IMultipleSelectionFilterMenus,
} from "../../interfaces";
import useAppSelector from "hooks/useAppSelector";
import { selectSupplierListState } from "store/procurement-supplierList";
import { selectCatalogProductTypes } from "store/catalog";
import { selectImageClassificationState } from "store/image-classifications";
import { convertOrderText } from "pages/main-page/pick-pack-order-page/utils/convertOrderText";
import { Box } from "@mui/material";

// it takes the data needed for labeling the multiple selection checkboxes from redux, so every page must ensure that the data is already there and set the corresponding argument to true when calling this!!
interface ISelectedFiltersListProps {
  viewName: string;
  supplierInfoRequired: boolean;
  productTypesInfoRequired: boolean;
  imageClassificationInfoRequired: boolean;
}

const SelectedFiltersList = ({
  viewName,
  supplierInfoRequired,
  productTypesInfoRequired,
  imageClassificationInfoRequired,
}: ISelectedFiltersListProps): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { results } = useAppSelector(selectSupplierListState);
  const productTypes = useAppSelector(selectCatalogProductTypes);
  const { imageClassifications } = useAppSelector(
    selectImageClassificationState,
  );
  const multipleSelectionMenus = useRef<IMultipleSelectionFilterMenus>({});

  const orderStatusInfo = useMemo(
    () =>
      new Map(
        [
          "NOT_PAID",
          "CANCELLED",
          "PROCESSING",
          "TO_BE_PICKED",
          "ON_HOLD",
          "READY_FOR_SHIPPING",
          "COMPLETED",
        ]?.map((option) => [option, convertOrderText(option)]),
      ),
    [],
  );

  const pickingStatusInfo = useMemo(
    () =>
      new Map(
        [
          "PARTLY_IN_STOCK",
          "NOTHING_TO_PICK",
          "NOT_APPLICABLE",
          "ALL_IN_STOCK",
          "DONE_PICKING",
        ]?.map((option) => [option, convertOrderText(option)]),
      ),
    [],
  );

  const backorderStatusInfo = useMemo(
    () =>
      new Map(
        [
          "NOT_APPLICABLE",
          "NO_BACKORDERS",
          "PARTLY_BACKORDERED",
          "ALL_BACKORDERED",
        ]?.map((option) => [option, convertOrderText(option)]),
      ),
    [],
  );

  multipleSelectionMenus.current.orderStatusInfo = orderStatusInfo;
  multipleSelectionMenus.current.pickingStatusInfo = pickingStatusInfo;
  multipleSelectionMenus.current.backorderStatusInfo = backorderStatusInfo;
  multipleSelectionMenus.current.supplierInfo = supplierInfoRequired
    ? new Map(
        results?.map((supplier) => [
          viewName === "catalog_bookmarks"
            ? supplier.name_abbrev
            : supplier.id.toString(),
          supplier.name,
        ]),
      )
    : undefined;
  multipleSelectionMenus.current.productTypesInfo = productTypesInfoRequired
    ? productTypes
    : undefined;
  multipleSelectionMenus.current.imageClassificationInfo =
    imageClassificationInfoRequired
      ? new Map(
          imageClassifications?.map((imageClassification) => [
            imageClassification.id.toString(),
            imageClassification.title,
          ]),
        )
      : undefined;

  const searchData = getSearchData(
    searchParams,
    multipleSelectionMenus.current,
  );

  return (
    <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
      {Object.values(searchData)?.map((appliedFilter: IAppliedFilter) => (
        <SelectedFilter key={appliedFilter.label} {...appliedFilter} />
      ))}
    </Box>
  );
};

export default SelectedFiltersList;
