import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import { ICatalogMarkupsDTO } from "store/catalog-markup/interfaces";
import { endpoint } from "constants/endpoint";

export interface ICatalogMarkupsService {
  fetchCatalogMarkups(params: URLSearchParams): Promise<ICatalogMarkupsDTO>;
}

export default new Mockable<ICatalogMarkupsService>({
  async fetchCatalogMarkups(
    params: URLSearchParams,
  ): Promise<ICatalogMarkupsDTO> {
    try {
      const res = await axios(
        `${config.api}${endpoint.catalog_markups}?${params}`,
        {
          headers: commonHeaders(),
        },
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async fetchCatalogMarkups <<",
          ),
        );
      }
    } catch {
      return Promise.reject("Couldn't get catalog markups data");
    }
  },
});
