import React from "react";
import Paper from "@mui/material/Paper";
import useAppSelector from "hooks/useAppSelector";
import {
  selectLowInStockProductsCount,
  selectNewProductsCount,
  selectUneditedProductsCount,
} from "store/catalog";
import { SUB_ROUTE_CATALOG_PRODUCTS } from "constants/urls";
import { recently } from "utils/time/recently";
import { Typography } from "@mui/material";
import theme from "theme/theme";
import Row from "../Row";

const MyProductCatalog = (): JSX.Element => {
  const newProducts = useAppSelector(selectNewProductsCount);
  const uneditedProducts = useAppSelector(selectUneditedProductsCount);
  const lowInStockProducts = useAppSelector(selectLowInStockProductsCount);

  return (
    <Paper elevation={0} sx={{ padding: theme.spacing(1) }}>
      <Typography
        sx={{
          fontSize: theme.typography.subtitle1.fontSize,
          fontWeight: theme.typography.fontWeightBold,
          marginBottom: theme.spacing(2),
        }}
      >
        Newly updated products
      </Typography>

      <Row
        children={uneditedProducts}
        caption="Unedited products"
        serachPhrase=""
      />
      <Row
        children={newProducts}
        caption="Recently added products"
        pathnameUrl={SUB_ROUTE_CATALOG_PRODUCTS}
        serachPhrase={`?is_synced_to_ecommerce=false&created_at__gte=${recently()}&created_at__lte=`}
      />
      <Row
        children={lowInStockProducts}
        caption="Products low in stock"
        pathnameUrl={SUB_ROUTE_CATALOG_PRODUCTS}
        serachPhrase={`?low_in_stock=true`}
      />
    </Paper>
  );
};

export default MyProductCatalog;
