import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { IImageClassification } from "store/image-classifications/interfaces";
import ImageThumbnail from "components/common-components/ImageThumbnail";
import ImageLink from "components/common-components/ImageLink";

const ColumnsForDataGrid = (
  imageClassifications: IImageClassification[],
): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: "thumbnail",
      flex: 0.5,
      headerName: "Thumbnail",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <ImageThumbnail
            src={params.row.thumbnail_small_url}
            width={30}
            height={30}
            alt="product image"
            loading="lazy"
          />
        );
      },
    },
    {
      field: "title",
      flex: 1,
      headerName: "Title",
      sortable: true,
    },
    {
      field: "classification",
      flex: 0.5,
      headerName: "Classification",
      sortable: false,
    },
    {
      field: "rights_belongs_to",
      flex: 0.5,
      headerName: "Rights Belong to",
      sortable: false,
    },
    {
      field: "created_at",
      flex: 0.5,
      headerName: "Date",
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        new Date(`${params}`).toUTCString(),
    },
    {
      field: "image",
      sortable: false,
      flex: 1,
      headerName: "Image Link",
      renderCell: (params: GridRenderCellParams) => {
        const image = params.row;
        const updatedURLLink = image?.image_url;

        return <ImageLink href={updatedURLLink} />;
      },
    },
  ];

  return columns;
};

export default ColumnsForDataGrid;
