import { Mockable } from "..";
import config from "../../config";
import Cookies from "js-cookie";

export interface ITokenService {
  setRefreshToken(refreshToken: string): void;
  setAccessToken(accessToken: string): void;
  getRefreshToken(): string;
  getAccessToken(): string;
  clearTokens(): void;
}

const REFRESH_TOKEN_KEY = "refreshToken";
const ACCESS_TOKEN_KEY = "accessToken";

export default new Mockable<ITokenService>({
  setAccessToken(accessToken: string): void {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  },
  setRefreshToken(refreshToken: string): void {
    Cookies.set(REFRESH_TOKEN_KEY, refreshToken, {
      domain: config.domain,
      expires: 90,
    });
  },
  clearTokens() {
    Cookies.remove(REFRESH_TOKEN_KEY);
    sessionStorage.removeItem(ACCESS_TOKEN_KEY);
  },
  getAccessToken(): string {
    return localStorage.getItem(ACCESS_TOKEN_KEY)!;
  },
  getRefreshToken(): string {
    return Cookies.get(REFRESH_TOKEN_KEY)!;
  },
});
