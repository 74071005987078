import { createAsyncThunk } from "@reduxjs/toolkit";
import procurementOrders, {
  FetchProcurementOrdersParams,
} from "services/procurement-orders";
import { IAddedOrderResponse } from "./interfaces";

export const fetchProcurementOrdersAsync = createAsyncThunk(
  "procurementOrders/procurementOrdersAsync",
  async (params: FetchProcurementOrdersParams | URLSearchParams) => {
    return await procurementOrders.service().fetchProcurementOrders(params);
  },
);

export const addProcurementOrderAsync = createAsyncThunk(
  "addProcurementOrder/addProcurementOrderAsync",
  async (supplier: number) => {
    return await procurementOrders
      .service()
      .addProcurementOrder(supplier, 66666);
  },
);

export const addProcurementOrderItemAsync = createAsyncThunk(
  "addProcurementOrderItem/addProcurementOrderItemAsync",
  async (
    {
      supplier,
      quantity,
      product,
    }: { product: number; supplier: number; quantity: number },
    thunkAPI,
  ) => {
    const result = await thunkAPI.dispatch(addProcurementOrderAsync(supplier));
    const resultPayload = result.payload as IAddedOrderResponse;

    return await procurementOrders
      .service()
      .addProcurementOrderItem(resultPayload.id, quantity, product);
  },
);
export const editProcurementOrderItemAsync = createAsyncThunk(
  "editProcurementOrder/editProcurementOrderAsync",
  async ({ id, quantity }: { id: number; quantity: number }) => {
    return await procurementOrders
      .service()
      .editProcurementOrderItem(id, quantity);
  },
);
