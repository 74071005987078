import React from "react";
import {
  IUpdateProductDTO,
  IUpdateProductsDTO,
  SourceType,
} from "store/catalog/interfaces";
import { isEmpty } from "lodash-es";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import {
  useEffect,
  useMemo,
  useState,
  MouseEvent,
  useRef,
  Fragment,
} from "react";
import { selectCatalogState } from "store/catalog";
import {
  fetchCatalogProductsWithIds,
  updateProducts,
} from "store/catalog/thunks";
import EditProduct, { Status } from "./parts/edit-product";
import { useNavigate } from "react-router-dom";
import { CATALOG_PRODUCTS } from "constants/urls";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PublishMenu from "../catalog-page/parts/PublishMenu";
import ExportDialog from "../catalog-page/parts/ExportDialog";
import { IIndexable } from "../../../interfaces";
import ErrorPage from "components/ErrorComponent";
import SafeSave from "components/SafeSave";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import theme from "theme/theme";

export interface IExpandFields extends IIndexable {
  name: number;
  cost_shipping: number;
  price_manual_override: number;
  price_on_sale: number;
  description: number;
  meta_title: number;
  meta_keywords: number;
  cost_packaging_labour: number;
  cost_packaging_material: number;
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
    },
  },
};

const EditCatalogProductPage = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { catalogProducts, loading, error } =
    useAppSelector(selectCatalogState);
  const [expandFields, setExpandFields] = useState<IExpandFields>({
    name: 0,
    cost_shipping: 0,
    price_manual_override: 0,
    price_on_sale: 0,
    description: 0,
    meta_title: 0,
    meta_keywords: 0,
    cost_packaging_labour: 0,
    cost_packaging_material: 0,
  });

  const handleToggleFieldChange = (
    event: SelectChangeEvent<typeof selectedFields>,
  ) => {
    const {
      target: { value },
    } = event;
    const updateSelected = typeof value === "string" ? value.split(",") : value;
    setSelectedFields(updateSelected);

    const expandedFields = Object.keys(expandFields).reduce((obj, field) => {
      obj[field] = updateSelected.includes(field)
        ? Math.random()
        : -Math.random();
      return obj;
    }, {} as IExpandFields);

    setExpandFields(expandedFields);
  };

  const productIds: number[] = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    const ids = urlParams.get("products") || "";
    return ids.split(",")?.map(Number);
  }, []);

  const isPrices: string = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    const isPrices = urlParams.get("prices") || "false";
    return isPrices;
  }, []);

  const updates = useRef(
    new Map<number, { update: IUpdateProductDTO; modified: Status }>(),
  );

  useEffect(() => {
    let debounceId: NodeJS.Timeout;
    if (productIds[0] !== 0) {
      debounceId = setTimeout(() => {
        dispatch(fetchCatalogProductsWithIds(productIds));
      }, 0);
    } else {
      navigate(CATALOG_PRODUCTS);
    }
    return () => clearTimeout(debounceId);
  }, [dispatch, navigate, productIds]);

  const saveAndPublishOptions = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getProductEditData = (): IUpdateProductsDTO => {
    const products = modified();
    const payload: IUpdateProductsDTO = {
      products: products?.map((update) => update.update),
    };
    return payload;
  };

  function modified() {
    return Array.from(updates.current.values()).filter(
      (update) => update.modified === Status.Modified,
    );
  }

  function saved() {
    return Array.from(updates.current.values()).filter(
      (update) => update.modified === Status.Saved,
    );
  }

  function statusOf(id: number) {
    const update = Array.from(updates.current.values()).find(
      (update) => update.update.id === id,
    );

    if (update) {
      return update.modified;
    }
    return Status.Unmodified;
  }

  function productCallback(
    id: number,
    field: string,
    v: number | string | boolean,
  ) {
    const update = updates.current.get(id)!;
    const product = update.update;
    if (field === "name") {
      product.name = v as string;
    } else if (field === "cost_shipping") {
      product.cost_shipping = v as string;
    } else if (field === "price_manual_override") {
      product.price_manual_override = v as string;
    } else if (field === "price_on_sale") {
      product.price_on_sale = v as string;
    }
    update.modified = Status.Modified;
  }

  function descriptionCallback(
    id: number,
    field: string,
    v: number | string | boolean,
  ) {
    const update = updates.current.get(id)!;
    const description = update.update.descriptions[0];
    if (field === "content") {
      description.content = v as string;
    } else if (field === "source") {
      description.source = v as SourceType;
    } else if (field === "meta_keywords") {
      description.meta_keywords = v as string;
    } else if (field === "meta_title") {
      description.meta_title = v as string;
    }
    update.modified = Status.Modified;
  }

  useEffect(() => {
    catalogProducts?.forEach((product) => {
      const update = updates.current.get(product.id);
      updates.current.set(product.id, {
        update: {
          id: product.id ?? 0,
          sku: product.sku ?? "",
          name: product.name ?? "",
          cost_shipping: product.cost_shipping ?? "",
          price_manual_override: product.price_manual_override ?? "",
          price_on_sale: product.price_on_sale ?? "",
          descriptions: [
            {
              id: product.description?.id ?? 0,
              content: product.description?.content ?? "",
              language: product.description?.language ?? "SV",
              source: product.description?.source ?? "chatgpt",
              meta_description: product.description?.meta_description ?? "",
              meta_keywords: product.description?.meta_keywords ?? "",
              meta_title: product.description?.meta_title ?? "",
            },
          ],
        },
        modified: update ? update.modified : Status.Unmodified,
      });
    });
  }, [catalogProducts, updates]);

  if (!isEmpty(error)) {
    return <ErrorPage {...error} />;
  }

  return (
    <>
      <Box
        sx={{
          background: "white",
          borderRadius: "4px",
          padding: "1rem",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "sticky",
              }}
            >
              <FormControl>
                <Select
                  sx={{ height: "2.5rem" }}
                  multiple
                  displayEmpty
                  value={selectedFields}
                  onChange={handleToggleFieldChange}
                  input={<OutlinedInput />}
                  renderValue={(s) => `Open fields (${s.length})`}
                  MenuProps={MenuProps}
                >
                  {Object.keys(expandFields)?.map((field) => (
                    <MenuItem
                      key={field}
                      value={field}
                      sx={{
                        height: "2rem",
                        paddingLeft: 0,
                        textTransform: "capitalize",
                      }}
                    >
                      <Checkbox checked={selectedFields.indexOf(field) > -1} />
                      <ListItemText primary={field.replaceAll("_", " ")} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <SafeSave
                  thunk={updateProducts}
                  getData={getProductEditData}
                  retryForMinutes={3}
                  retryAfterSeconds={10}
                />
                {isPrices === "false" && (
                  <Button variant="contained" onClick={saveAndPublishOptions}>
                    <SaveOutlinedIcon /> Save & publish
                  </Button>
                )}
                <PublishMenu
                  anchorEl={anchorEl!}
                  setAnchor={(e: any) => setAnchorEl(e)}
                  products={() => saved()?.map((update) => update.update.id)}
                  onPublish={getProductEditData}
                />
              </Box>
            </Box>
            <Box sx={{ height: "1rem" }} />
            <Box sx={{ overflowY: "scroll", height: "calc(100% - 14.2rem)" }}>
              {catalogProducts?.map((product, index) => (
                <Fragment key={`${product.id}-fragment`}>
                  {!!index && (
                    <Divider
                      key={`${product.id}-divider`}
                      sx={{
                        borderBottomWidth: 3,
                        background: theme.palette.primary.main,
                      }}
                    />
                  )}
                  <EditProduct
                    key={product.id}
                    status={() => statusOf(product.id)}
                    product={product}
                    onDescriptionChanged={descriptionCallback}
                    onProductChanged={productCallback}
                    expandFields={expandFields}
                    isPrices={isPrices}
                    {...product}
                  />
                </Fragment>
              ))}
            </Box>
          </>
        )}
      </Box>
      <ExportDialog />
    </>
  );
};

export default EditCatalogProductPage;
