import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import config from "../../config";
import { RootState } from "../index";
import {
  IProcurementProduct,
  IProcurementProductsSliceState,
} from "./interfaces";
import { fetchProcurementsProducts } from "./thunks";
import { httpErrorHandler } from "utils/error-response";

const initialState: IProcurementProductsSliceState = {
  columns: [
    {
      field: "image_urls",
      flex: 1,
      headerName: "0 products selected",
      sortable: false,
    },
    { field: "sku", flex: 1, headerName: "SKU", sortable: false },
    {
      field: "name",
      flex: 2,
      headerName: "Product name",
      sortable: false,
    },
    {
      field: "stock_details",
      flex: 1,
      headerName: "Stock status",
      sortable: false,
    },
    {
      field: "cost_currency_id",
      flex: 1,
      headerName: "Price",
      sortable: false,
    },
    { field: "urls", flex: 1, headerName: "Link", sortable: false },
    {
      field: "product_type",
      flex: 1,
      headerName: "Product type",
      sortable: false,
    },
    { field: "status", flex: 1, headerName: "Status", sortable: false },
  ],
  loading: true,
  procurementProducts: [],
  error: {},
};

const procurementProductsSlice = createSlice({
  name: "procurementProducts",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProcurementsProducts.fulfilled, (state, action) => {
      state.count = action.payload.count;
      state.nextPageUrl = action.payload.next;
      state.previousPageUrl = action.payload.previous;
      state.procurementProducts = action.payload.results?.map((product) => ({
        ...product,
        stock_details: product.stock_details.replace("In stock: ", ""),
      }));
    });
    builder.addCase(fetchProcurementsProducts.rejected, (state, { error }) => {
      state.error = {
        ...httpErrorHandler(error),
        url: config.api + "/procuement/products/",
      };
    });
  },
});

export const { setLoading } = procurementProductsSlice.actions;

export const selectProcurementProductsState = (
  state: RootState,
): IProcurementProductsSliceState => state.procurementProducts;

export const selectProcurementProducts = (
  state: RootState,
): IProcurementProduct[] => state.procurementProducts.procurementProducts;

export default procurementProductsSlice.reducer;
