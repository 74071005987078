import axios, { AxiosError } from "axios";
import config from "../../config";
import { ITokens, IUser } from "store/auth/interfaces";
import { Mockable } from "..";
import commonHeaders from "services/http/headers";
import { IAccountDetails, ICompanyInfo } from "store/onboarding/interfaces";
import { endpoint } from "constants/endpoint";
export interface IAuthService {
  login(email: string, password: string): Promise<ITokens>;
  refreshTokens(refreshToken: string): Promise<ITokens>;
  fetchUserData(): Promise<IUser>;
  register(account: IAccountDetails, company?: ICompanyInfo): Promise<void>;
  resetPassword(email: string): Promise<void>;
  newPassword(token: string, password: string): Promise<void>;
}

export default new Mockable<IAuthService>({
  async login(email: string, password: string): Promise<ITokens> {
    const res = await axios(`${config.api}${endpoint.login}`, {
      method: "POST",
      withCredentials: false,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({ username: email, password }),
    });

    if (res.status === 200) {
      return res.data;
    }

    return Promise.reject("Bad credentials");
  },

  async refreshTokens(refreshToken: string): Promise<ITokens> {
    const res = await axios.create()(`${config.api}${endpoint.token_refresh}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({ refresh: refreshToken }),
    });
    return res.data;
  },

  async resetPassword(email: string): Promise<void> {
    const res = await axios.create()(
      `${config.api}${endpoint.user_password_reset}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          email,
        }),
      },
    );
    return res.data;
  },

  async newPassword(token: string, password: string): Promise<any> {
    try {
      const res = await axios.create()(
        `${config.api}${endpoint.user_password_reset_confirm}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data: JSON.stringify({ token, password }),
        },
      );
      return res.data;
    } catch (e) {
      const error = e as AxiosError;
      return error.response?.data;
    }
  },

  async fetchUserData(): Promise<IUser> {
    const res = await axios.create()(`${config.api}${endpoint.user}`, {
      headers: commonHeaders(),
    });
    if (res.status === 200) {
      return res.data;
    }

    return Promise.reject("Invalid access token");
  },

  async register(
    account: IAccountDetails,
    company?: ICompanyInfo,
  ): Promise<void> {
    try {
      const res = await axios.create()(
        `${config.api}${endpoint.user_demo_register}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data: JSON.stringify({ account, company }),
        },
      );

      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(new Error("Unexpected response status"));
      }
    } catch (e) {
      const err = e as AxiosError;
      return Promise.reject(err.response?.data || "Unknown error occurred");
    }
  },
});
