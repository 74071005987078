import React from "react";
import {
  ICatalogProduct,
  ICatalogProductDescription,
} from "store/catalog/interfaces";
import EditAccordion from "./parts/EditAccordion";
import ProductDetails from "./parts/ProductDetails";
import { useCallback, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SetOptions from "suneditor-react/dist/types/SunEditorReactProps";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DoneIcon from "@mui/icons-material/Done";
import { defaultDescription } from "store/catalog";
import { IExpandFields } from "../..";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import SunEditor from "components/lazy-loading/LazySunEditor";

// see available SunEditor options here: https://github.com/JiHong88/SunEditor/blob/master/README.md#options
export const editorOptions: typeof SetOptions = {
  minHeight: "300px",
  maxHeight: "300px",
  resizingBar: false,
  showPathLabel: false,
  formats: ["p", "blockquote", "pre", "h1", "h2", "h3", "h4", "h5", "h6"],
  buttonList: [
    [
      "undo",
      "redo",
      "formatBlock",
      "bold",
      "underline",
      "italic",
      "strike",
      "subscript",
      "superscript",
      "fontColor",
      "hiliteColor",
      "removeFormat",
      "font",
      "fontSize",
      "outdent",
      "indent",
      "align",
      "horizontalRule",
      "list",
      "lineHeight",
      "table",
      "link",
      "video",
      "fullScreen",
      "codeView",
    ],
  ],
};

interface IFilterSelectProps {
  product: ICatalogProduct;
  status(): Status;
  onDescriptionChanged(
    id: number,
    field: string,
    value: number | string | boolean,
  ): void;
  onProductChanged(
    id: number,
    field: string,
    value: number | string | boolean,
  ): void;
  expandFields: IExpandFields;
  isPrices: string;
}

const EditProduct = ({
  product,
  status,
  onDescriptionChanged,
  onProductChanged,
  expandFields,
}: IFilterSelectProps): JSX.Element => {
  const [expanded, setExpanded] = useState(true);
  const [editProduct, setEditProduct] = useState<ICatalogProduct>(product);
  const [editDescription, setEditDescription] =
    useState<ICatalogProductDescription>(
      product.description || {
        ...defaultDescription,
        account_id: product.account_id,
        company_id: product.company_id,
        product_id: product.id,
      },
    );

  const updateProduct = useCallback(
    (field: string, value: number | string | boolean) => {
      setEditProduct({
        ...editProduct,
        [field]: value,
      });
      onProductChanged(product.id, field, value);
    },
    [editProduct, onProductChanged, product.id],
  );

  const updateDescription = useCallback(
    (field: string, value: number | string | boolean) => {
      setEditDescription({
        ...editDescription,
        [field]: value,
      });
      onDescriptionChanged(product.id, field, value);
    },
    [editDescription, onDescriptionChanged, product.id],
  );

  function modified_status() {
    switch (status()) {
      case Status.Modified:
        return (
          <Tooltip title="Edited">
            <EditOutlinedIcon
              fontSize="large"
              sx={{ alignSelf: "center", color: "orange" }}
            />
          </Tooltip>
        );
      case Status.Saved:
        return (
          <Tooltip title="Saved">
            <DoneIcon
              color="success"
              fontSize="large"
              sx={{ alignSelf: "center" }}
            />
          </Tooltip>
        );
      default:
        return <div />;
    }
  }

  return (
    <Box>
      <Accordion
        expanded={expanded}
        square
        disableGutters
        elevation={0}
        onChange={() => setExpanded((prev) => !prev)}
      >
        <AccordionSummary
          sx={{
            paddingLeft: 0,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Box sx={{ display: "grid", columns: "2rem 1fr", gap: "1rem" }}>
            {modified_status()}
            <ProductDetails {...product} />
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft: 0 }}>
          <EditAccordion
            label="Product name"
            forceToggleExpanded={expandFields.name}
          >
            <OutlinedInput
              sx={{
                height: "2.5rem",
              }}
              fullWidth
              value={editProduct.name}
              onChange={(e) => updateProduct("name", e.target.value)}
            />
          </EditAccordion>

          <EditAccordion
            label="Cost for shipping"
            forceToggleExpanded={expandFields.cost_shipping}
          >
            <OutlinedInput
              sx={{
                height: "2.5rem",
              }}
              fullWidth
              value={editProduct.cost_shipping}
              onChange={(e) => updateProduct("cost_shipping", e.target.value)}
            />
          </EditAccordion>

          <EditAccordion
            label="Price override (incl VAT)"
            forceToggleExpanded={expandFields.price_manual_override}
          >
            <OutlinedInput
              sx={{
                height: "2.5rem",
              }}
              fullWidth
              value={editProduct.price_manual_override}
              onChange={(e) =>
                updateProduct("price_manual_override", e.target.value)
              }
            />
          </EditAccordion>
          <EditAccordion
            label="Sale price (incl VAT)"
            forceToggleExpanded={expandFields.price_on_sale}
          >
            <OutlinedInput
              sx={{
                height: "2.5rem",
              }}
              fullWidth
              value={editProduct.price_on_sale}
              onChange={(e) => updateProduct("price_on_sale", e.target.value)}
            />
          </EditAccordion>

          <EditAccordion
            label="Product description"
            forceToggleExpanded={expandFields.description}
          >
            <Box
              sx={{
                ".sun-editor": {
                  borderRadius: "4px",
                },
                ".se-toolbar": {
                  borderRadius: "4px 4px 0 0",
                },
              }}
            >
              <SunEditor
                defaultValue={editDescription.content}
                onChange={(val: any) => {
                  updateDescription("content", val);
                }}
                autoFocus
                setOptions={editorOptions}
              />
            </Box>
          </EditAccordion>

          <EditAccordion
            label="Meta title"
            forceToggleExpanded={expandFields.meta_title}
          >
            <OutlinedInput
              sx={{
                height: "2.5rem",
              }}
              fullWidth
              value={editDescription.meta_title}
              onChange={(e) => updateDescription("meta_title", e.target.value)}
            />
          </EditAccordion>

          <EditAccordion
            label="Meta keywords"
            forceToggleExpanded={expandFields.meta_keywords}
          >
            <OutlinedInput
              sx={{
                height: "2.5rem",
              }}
              fullWidth
              value={editDescription.meta_keywords}
              onChange={(e) =>
                updateDescription("meta_keywords", e.target.value)
              }
            />
          </EditAccordion>
        </AccordionDetails>
      </Accordion>
      <Divider />
    </Box>
  );
};

export default EditProduct;

export enum Status {
  Unmodified,
  Modified,
  Saved,
}
