import { createAsyncThunk } from "@reduxjs/toolkit";
import stocktaking from "services/stock-taking";
import {
  IInventoryTransactionUpdate,
  IStocktakingFilterParams,
} from "./interfaces";

function getAxiosParams(stocktakingParams: IStocktakingFilterParams) {
  const params = new URLSearchParams();
  params.append(
    "offset",
    (stocktakingParams.page * stocktakingParams.limit).toString(),
  );
  params.append("limit", stocktakingParams.limit.toString());
  params.append(
    "obsolete",
    stocktakingParams.obsolete === "No" ? "false" : "true",
  );
  params.append("slug__icontains", stocktakingParams.locationName.toString());
  return params;
}

export const fetchStocktaking = createAsyncThunk(
  "stock/fetchStocktaking",
  async (params: URLSearchParams) => {
    const results = await stocktaking.service().fetchStocktaking(params);
    return results;
  },
);

export const addInventoryTransactionAsync = createAsyncThunk(
  "transactions/addInventoryTransaction",
  async ({ object }: { object: IInventoryTransactionUpdate }, thunkAPI) => {
    try {
      return await stocktaking.service().addInventoryTransaction(object);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
export const updateInventoryTransactionAsync = createAsyncThunk(
  "suppliers/updateInventoryTransaction",
  async (
    { id, object }: { id: number; object: IInventoryTransactionUpdate },
    thunkAPI,
  ) => {
    try {
      return await stocktaking.service().updateInventoryTransaction(id, object);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);
