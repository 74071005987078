import React, { useEffect, useState } from "react";
import {
  IconButton,
  Badge,
  Popover,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Switch,
  FormControlLabel,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  styled,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Campaign } from "@mui/icons-material";
import useAppDispatch from "hooks/useAppDispatch";
import {
  fetchNotificationItemTopBarAsync,
  fetchNotificationCount,
} from "store/notification-system/thunks";
import { selectNotifications } from "store/notification-system";
import useAppSelector from "hooks/useAppSelector";
import { selectAuthenticatedUser } from "store/auth";
import procurementSup from "services/notification-system";
import SimpleSnackbar from "components/SimpleSnackbar";
import theme from "theme/theme";
import { INotificationItemProps } from "store/notification-system/interfaces";
import { IUser } from "store/auth/interfaces";

// Custom styles
const StyledPopover = styled(Popover)(() => ({
  "& .MuiPaper-root": {
    borderRadius: 8,
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
    width: 400,
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  },
}));
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  backgroundColor: theme.palette.background.default,
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
}));

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const TopBar = () => {
  const dispatch = useAppDispatch();
  const { TopBarResults, count } = useAppSelector(selectNotifications);
  const [notifications, setNotifications] = useState<INotificationItemProps[]>(
    [],
  );
  const [unreadCount, setUnreadCount] = useState(count);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackContent, setFeedbackContent] = useState("");
  const [feedbackSelect, setFeedbackSelect] = useState("");
  const [onlyShowUnread, setOnlyShowUnread] = useState(false);
  const navigate = useNavigate();
  const user = useAppSelector(selectAuthenticatedUser) as IUser;
  const [alertDisplay, setAlertDisplay] = useState(false);

  useEffect(() => {
    dispatch(
      fetchNotificationItemTopBarAsync({
        userId: user.id,
        showUnread: onlyShowUnread,
      }),
    );
  }, [onlyShowUnread]);

  useEffect(() => {
    setNotificationsCount();
  }, [count]);

  useEffect(() => {
    const interval = setInterval(() => setNotificationsCount(), 3 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setNotifications(TopBarResults);
  }, [TopBarResults]);

  const setNotificationsCount = () => {
    dispatch(fetchNotificationCount({ userId: user.id, showUnread: true }));
    setUnreadCount(count);
  };

  const handleIconButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLinkClick = (url: string) => {
    setAnchorEl(null);
    navigate(url);
  };

  const handleFeedbackOpen = () => {
    setFeedbackOpen(true);
  };

  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
  };

  const handleFeedbackSubmit = async () => {
    const params = {
      title: "feedback",
      classification: feedbackSelect,
      description: feedbackContent,
      source_url: "",
      account: user.id,
      company: user.company,
    };

    const res =
      params && (await procurementSup.service().InsertFeedBack(params as any));
    if (res) {
      setAlertDisplay(true);
    } else {
      setAlertDisplay(false);
    }

    setFeedbackContent("");
    setFeedbackSelect("");
    setFeedbackOpen(false);
  };

  return (
    <>
      {alertDisplay ? (
        <SimpleSnackbar
          open={alertDisplay}
          message={"Your feedback has been sent successfully."}
          toggleSnackbar={() => {
            setAlertDisplay(false);
          }}
          vertical="bottom"
          horizontal="right"
          snackbarAction={null}
        />
      ) : null}
      <IconButton
        onClick={handleIconButtonClick}
        sx={{ marginRight: theme.spacing(2) }}
      >
        <Badge badgeContent={unreadCount}>
          <NotificationsIcon
            sx={{
              fontSize: "2rem",
              color: `${theme.palette.primary.main}`,
            }}
          />
        </Badge>
      </IconButton>
      <StyledPopover
        id={anchorEl ? "notifications-popover" : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "405px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
            }}
          >
            <Typography variant="h6" component="h2" gutterBottom>
              Notifications
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Tooltip title="Only show unread">
                    <div
                      style={{
                        transform: "scale(0.8)",
                        transformOrigin: "center right",
                      }}
                    >
                      <Android12Switch
                        checked={onlyShowUnread}
                        onChange={() => setOnlyShowUnread((prev) => !prev)}
                      />
                    </div>
                  </Tooltip>
                }
                label=""
                sx={{ mr: 1 }}
              />
              <Tooltip title="Open notifications in a new tab">
                <IconButton
                  component={RouterLink}
                  to="/notification"
                  aria-label="Open notifications in a new tab"
                  onClick={() => handleLinkClick("/notification")}
                  sx={{ p: 1 }}
                >
                  <LaunchIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Give feedback">
                <IconButton
                  onClick={handleFeedbackOpen}
                  aria-label="Give feedback"
                >
                  <Campaign />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <List sx={{ maxHeight: "1000px", overflowY: "auto", p: 0 }}>
            {notifications?.map((notification) => (
              <React.Fragment key={notification.id}>
                <StyledListItem alignItems="flex-start">
                  <ListItemAvatar>
                    {notification.notification_details.severity_icon && (
                      <StyledAvatar>
                        <img
                          src={notification.notification_details.severity_icon}
                          alt="Message Type Icon"
                          width="30"
                          height="30"
                        />
                      </StyledAvatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" component="span">
                        {notification?.notification_details?.title}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                notification?.notification_details?.message,
                            }}
                          />
                        </Typography>
                        <br />
                        <Typography
                          component="span"
                          variant="caption"
                          color="textSecondary"
                        >
                          From:{" "}
                          {`${notification.notification_details?.account_first_name} ${notification.notification_details?.account_last_name} (${notification.notification_details?.account_username})`}
                        </Typography>
                      </>
                    }
                  />
                </StyledListItem>
                <Divider component="li" />
              </React.Fragment>
            ))}
          </List>
        </Box>
      </StyledPopover>

      <Dialog open={feedbackOpen} onClose={handleFeedbackClose}>
        <DialogTitle>Give Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We value your feedback. Please provide your thoughts and suggestions
            below.
          </DialogContentText>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="feedback-type-label">Select feedback</InputLabel>
            <Select
              labelId="feedback-type-label"
              id="feedback-type"
              value={feedbackSelect}
              onChange={(e) => setFeedbackSelect(e.target.value)}
              label="Select feedback"
            >
              <MenuItem value="bug_report">Bug report</MenuItem>
              <MenuItem value="feature_suggestion">Feature suggestion</MenuItem>
              <MenuItem value="change_request">Change request</MenuItem>
              <MenuItem value="general_feedback">General Feedback</MenuItem>
              <MenuItem value="question">Question</MenuItem>
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="feedback"
            label="Your Feedback"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={feedbackContent ?? ""}
            onChange={(e) => setFeedbackContent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFeedbackClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFeedbackSubmit} color="primary">
            Send feedback
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TopBar;
