import React, { useEffect, useState } from "react";
import { Box, Backdrop, CircularProgress, Container } from "@mui/material";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { fetchCustomersOrderAsync } from "store/orders/thunks";
import SwitchListSecondary from "./parts/BasicInfo";
import BillingAddress from "./parts/BillingAddress";
import OrderItems from "./parts/OrderItems";
import ShippingAddress from "./parts/ShippingAddress";
import { selectCurrencies } from "store/currencies";
import { fetchCurrenciesAsync } from "store/currencies/thunks";
import PrisSkatt from "./parts/PrisSkatt";
import EditAccordion from "../add-edit-procurement-category-page/parts/EditAccordion";
import { ordersSelectors } from "store/orders";
import theme from "theme/theme";

const EditCustomersOrderPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const orderId = new URLSearchParams(location.search).get("order")!;

  const { currenciesLoaded } = useAppSelector(selectCurrencies);
  const { order } = useAppSelector(ordersSelectors);

  useEffect(() => {
    !currenciesLoaded && dispatch(fetchCurrenciesAsync());
  }, [dispatch, currenciesLoaded]);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchCustomersOrderAsync(orderId))
      .unwrap()
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, orderId]);

  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "4px",
        padding: "1rem",
      }}
    >
      <Box sx={{ overflowY: "scroll", height: "calc(100% - 14.2rem)" }}>
        {loading ? (
          <Backdrop
            sx={{
              color: theme.palette.light.main,
              backgroundColor: theme.palette.action.selected,
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" sx={{ ml: "22vw" }} />
          </Backdrop>
        ) : null}
        {order && (
          <Box>
            <EditAccordion label="Base Information">
              <SwitchListSecondary />
            </EditAccordion>
            <EditAccordion label="Billing & Shipping Address">
              <Container>
                <BillingAddress />
                <ShippingAddress />
              </Container>
            </EditAccordion>
            <EditAccordion label="Pris, skatt, mms">
              <PrisSkatt />
            </EditAccordion>
            <EditAccordion label="Order Items">
              <OrderItems />
            </EditAccordion>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EditCustomersOrderPage;
