import React from "react";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import GridContainer from "components/common-components/GridContainer";

function ProcurementOrdersChildTable({
  row: rowProp,
}: {
  row: any;
}): JSX.Element {
  return (
    <Stack
      sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: "auto", width: "90%", p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Typography component="span" variant="h6">
            {}
          </Typography>
          <Box sx={{ display: "grid" }}>
            <Box sx={{ display: "grid" }}>
              <Typography
                component="span"
                variant="body2"
                color="textSecondary"
              >
                Customer information
              </Typography>
              <Typography component="span" variant="body1">
                {}
              </Typography>
              <Typography component="span" variant="body1">
                {}
              </Typography>
            </Box>
            <Box sx={{ display: "grid" }}>
              <Typography
                component="span"
                variant="body2"
                align="right"
                color="textSecondary"
              >
                Shipping address
              </Typography>
              <Typography component="span" variant="body1" align="right">
                {}
              </Typography>
              <Typography component="span" variant="body1" align="right">
                {}
              </Typography>
            </Box>
          </Box>
          <GridContainer>
            <DataGridPro
              density="compact"
              columns={[
                { field: "name", headerName: "Product", flex: 1 },
                {
                  field: "quantity",
                  headerName: "Quantity",
                  align: "center",
                  type: "number",
                },
                {
                  field: "unitPrice",
                  headerName: "Unit Price",
                  type: "number",
                },
                {
                  field: "total",
                  headerName: "Total",
                  type: "number",
                  valueGetter: ({ row }: any) => row.quantity * row.unitPrice,
                },
              ]}
              rows={rowProp.products}
              sx={{ flex: 1 }}
              hideFooter
            />
          </GridContainer>
        </Stack>
      </Paper>
    </Stack>
  );
}
export default ProcurementOrdersChildTable;
