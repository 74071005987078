import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../index";
import { ICurrenciesSliceState } from "./interfaces";

import { fetchCurrenciesAsync } from "./thunks";

const initialState: ICurrenciesSliceState = {
  currencies: [],
  currenciesLoaded: false,
};

const currenciesSlice = createSlice({
  name: "currencies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrenciesAsync.fulfilled, (state, action) => {
      state.currencies = action.payload;
      state.currenciesLoaded = true;
    });
  },
});

export const selectCurrencies = (state: RootState): ICurrenciesSliceState =>
  state.currencies;

export default currenciesSlice.reducer;
