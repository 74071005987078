import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { PickOrder } from "./interfaces";
import { fetchPickOrderAsync } from "./thunks";
import { fetchOrdersAsync } from "store/orders/thunks";

export const initialState: PickOrder = {
  message: "",
  loading: false,
  pickOrderId: null,
  error: "",
};

export const pickOrderSlice = createSlice({
  name: "pickOrder",
  initialState,
  reducers: {
    clearPickOrderMessage: (state) => {
      state.message = "";
      state.error = "";
    },
    setPickProductId: (state, action) => {
      state.pickOrderId = action.payload.orderId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPickOrderAsync.fulfilled, (state, action) => {
      state.message = action.payload?.result;
      state.loading = false;
    });
    builder.addCase(fetchPickOrderAsync.pending, (state, action) => {
      state.loading = true;
      state.message = "";
      state.error = "";
    });
    builder.addCase(fetchPickOrderAsync.rejected, (state, { error }) => {
      state.error = error.message!;
      state.loading = false;
    });
    builder.addCase(fetchOrdersAsync.fulfilled, (state, action) => {
      state.pickOrderId = null;
    });
  },
});

export const selectPickOrderState = (state: RootState): PickOrder =>
  state.pickOrder;
export const { clearPickOrderMessage, setPickProductId } =
  pickOrderSlice.actions;

export default pickOrderSlice.reducer;
