import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Theme } from "@mui/material";

export interface Data {
  product: string;
  qty_ordered: string;
  qty_to_be_picked: string;
  qty_picked: string;
  qty_to_be_backordered: string;
  qty_already_backordered: string;
  price: string;
  vat: string;
  tax: string;
  price_incl_tax: string;
  subtotal: string;
  subtotalTax: string;
  total: string;
  totalTax: string;
}

export type Order = "asc" | "desc";

interface HeadCell {
  id: keyof Data;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: "product",
    label: "Product",
  },
  {
    id: "qty_ordered",
    label: "Qty Ordered",
  },
  {
    id: "qty_to_be_picked",
    label: "Qty to be Picked",
  },
  {
    id: "qty_picked",
    label: "Qty Picked",
  },
  {
    id: "qty_to_be_backordered",
    label: "Qty to be Backordered",
  },
  {
    id: "qty_already_backordered",
    label: "Qty already Backordered",
  },
  {
    id: "price",
    label: "Price",
  },
  {
    id: "vat",
    label: "Vat %",
  },
  {
    id: "tax",
    label: "Tax",
  },
  {
    id: "price_incl_tax",
    label: "Price Incl Tax",
  },
  {
    id: "subtotal",
    label: "Subtotal",
  },
  {
    id: "subtotalTax",
    label: "Subtotal Tax",
  },
  {
    id: "total",
    label: "Total",
  },
  {
    id: "totalTax",
    label: "Total Tax",
  },
];

function CustomTableHead(): JSX.Element {
  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              backgroundColor: (theme: Theme) => theme.palette.bg.main,
              borderBottom: "none",
              color: "#6D6D6D",
              fontSize: 13,
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default CustomTableHead;
