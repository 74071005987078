import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  INotificationItemProps,
  IUpdatecompletedBy,
  IUpdateReadAt,
} from "store/notification-system/interfaces";
import useAppSelector from "hooks/useAppSelector";
import { selectAuthenticatedUser } from "store/auth";
import procurementSup from "services/notification-system";
import {
  fetchNotificationItemAsync,
  fetchNotificationCount,
} from "store/notification-system/thunks";
import useAppDispatch from "hooks/useAppDispatch";
import { IUser } from "store/auth/interfaces";

const formatDate = (dateString: string | number | Date) => {
  const date = new Date(dateString);
  return format(date, "MMMM dd, yyyy, h:mm a");
};

// Styled components
const UnreadIndicatorButton = styled("button")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 24,
  height: 24,
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
  "&:focus": {
    outline: "none",
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  padding: theme.spacing(2),
  boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.1)`,
  transition: "box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out",
  "&:hover": {
    boxShadow: `0px 8px 16px rgba(0, 0, 0, 0.2)`,
    transform: "scale(1.02)",
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  backgroundColor: theme.palette.background.default,
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
}));

const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: 600,
  textAlign: "center",
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  color: theme.palette.text.primary,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
  justifyContent: "center",
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const ContentCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.1)`,
  padding: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
}));

const NotificationItem: React.FC<INotificationItemProps> = ({
  id,
  notification_details: {
    id: detailId,
    title,
    message,
    completed_by_username,
    completed_by_first_name,
    completed_by_last_name,
    severity_icon,
    account_username,
    account_first_name,
    account_last_name,
  },
  created_at,
  updated_at,
  read_at,
  showUnreadOnly,
}) => {
  const updateCompleted = () => {
    return !completed_by_username
      ? ""
      : ` ${completed_by_first_name} ${completed_by_last_name} ${completed_by_username}`;
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [completed, setCompleted] = useState(updateCompleted());
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [pendingCheckboxState, setPendingCheckboxState] = useState(false);
  const user = useAppSelector(selectAuthenticatedUser) as IUser;
  const [isRead, setIsRead] = useState(!!read_at);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setCompleted(updateCompleted());
  }, [completed_by_username]);

  const handleClickOpen = async (event: React.MouseEvent) => {
    // Prevent dialog open on checkbox click or its area
    if ((event.target as HTMLElement).closest(".checkbox-container")) {
      return;
    }
    // Update the notification to read when the dialog is opened
    if (!isRead) {
      updateAsRead(true);
      setIsRead(true);
    }
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleIndicatorClick = async (event: React.MouseEvent) => {
    const newReadStatus = !isRead;
    setIsRead(newReadStatus);
    updateAsRead(newReadStatus);
  };

  const updateAsRead = async (newReadStatus: boolean) => {
    const res = await UpdateReadAt(newReadStatus);
    if (res) {
      dispatch(
        fetchNotificationItemAsync({
          userId: user.id,
          showUnread: showUnreadOnly,
        }),
      );
    }
    if (user)
      dispatch(fetchNotificationCount({ userId: user.id, showUnread: true }));
  };

  const handleCompleteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPendingCheckboxState(event.target.checked);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = async (confirm: boolean) => {
    setConfirmDialogOpen(false);
    if (confirm) {
      if (pendingCheckboxState) {
        const res = await UpdateCompletedBy(user.id);
        if (res) {
          dispatch(
            fetchNotificationItemAsync({
              userId: user.id,
              showUnread: showUnreadOnly,
            }),
          );
        }
      }
    }
  };

  const UpdateCompletedBy = async (completedBy: number) => {
    const params: IUpdatecompletedBy = {
      completed_by: completedBy,
    };
    const res = await procurementSup
      .service()
      .UpdateCompletedBy(detailId, params);
    return res;
  };

  const UpdateReadAt = async (readStatus: boolean) => {
    const today = new Date();
    const params: IUpdateReadAt = {
      read_at: readStatus ? today.toISOString() : "",
    };

    const res = await procurementSup.service().UpdateReadAt(id, params);
    return res;
  };

  return (
    <>
      <StyledListItem
        alignItems="flex-start"
        sx={{
          backgroundColor: read_at ? "white" : "#f5f5f5",
          borderLeft: `5px solid ${completed ? "#4caf50" : "#ddd"}`,
        }}
        onClick={handleClickOpen}
      >
        <ListItemAvatar>
          {severity_icon && (
            <StyledAvatar>
              <img
                src={severity_icon}
                alt="Message Type Icon"
                width="30"
                height="30"
              />
            </StyledAvatar>
          )}
        </ListItemAvatar>

        <ListItemText
          primary={
            <Typography
              variant="subtitle1"
              component="span"
              sx={{
                fontWeight: read_at ? "normal" : "bold",
                color: read_at ? "textSecondary" : "textPrimary",
              }}
            >
              {title}
            </Typography>
          }
          secondary={
            <>
              <Box>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  <span dangerouslySetInnerHTML={{ __html: message }} />
                </Typography>
              </Box>
              <Typography
                component="span"
                variant="caption"
                color="textSecondary"
                display="block"
              >
                From:{" "}
                {`${account_first_name} ${account_last_name} (${account_username})`}
              </Typography>
              <Typography
                component="span"
                variant="caption"
                color="textSecondary"
                display="block"
              >
                Created at: {formatDate(created_at)}
              </Typography>
              <Typography
                component="span"
                variant="caption"
                color="textSecondary"
                display="block"
              >
                Updated at: {formatDate(updated_at)}
              </Typography>
              <Typography
                component="span"
                variant="caption"
                color="textSecondary"
                display="block"
              >
                Read at: {read_at ? formatDate(read_at) : "Not read yet"}
              </Typography>
              <Typography
                component="span"
                variant="caption"
                color="textSecondary"
                display="block"
              >
                Completed by:{" "}
                {completed_by_username ? completed : "Not yet completed"}
              </Typography>
            </>
          }
        />

        <Box
          className="checkbox-container"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={completed ? true : false}
                onChange={handleCompleteChange}
                name="completedCheckbox"
                disabled={completed ? true : false}
                sx={{
                  color: completed ? "#4caf50" : "#1976d2",
                  "&.Mui-checked": {
                    color: "#4caf50",
                  },
                }}
              />
            }
            label="Completed"
          />
          <Tooltip title={read_at ? "Mark as Unread" : "Mark as Read"}>
            <UnreadIndicatorButton
              onClick={handleIndicatorClick}
              aria-pressed={!read_at}
              aria-label={read_at ? "Mark as Unread" : "Mark as Read"}
            >
              <Box
                component="svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="8"
                  fill={read_at ? "#B0B0B0" : "#1976d2"} // Gray for read, Blue for unread
                />
              </Box>
            </UnreadIndicatorButton>
          </Tooltip>
        </Box>
      </StyledListItem>

      {/* Modern Dialog for Notification Details */}
      <Dialog open={dialogOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitleStyled>{title}</DialogTitleStyled>
        <DialogContentStyled>
          <ContentCard>
            <Typography variant="body1" gutterBottom>
              <span dangerouslySetInnerHTML={{ __html: message }} />
            </Typography>
            <Typography variant="caption" color="textSecondary" display="block">
              Created at: {formatDate(created_at)}
            </Typography>
            <Typography variant="caption" color="textSecondary" display="block">
              Updated at: {formatDate(updated_at)}
            </Typography>
            <Typography variant="caption" color="textSecondary" display="block">
              Read at: {read_at ? formatDate(read_at) : "Not read yet"}
            </Typography>
            <Typography variant="caption" color="textSecondary" display="block">
              Completed by:{" "}
              {completed_by_username
                ? `${completed_by_first_name} ${completed_by_last_name} (${completed_by_username})`
                : "Not yet completed"}
            </Typography>
          </ContentCard>
        </DialogContentStyled>
        <DialogActionsStyled>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActionsStyled>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => handleConfirmDialogClose(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitleStyled>Confirm Completion</DialogTitleStyled>
        <DialogContentStyled>
          <Typography variant="body2" style={{ marginTop: "20px" }}>
            Are you sure you want to mark this item as completed? This action
            cannot be undone.
          </Typography>
        </DialogContentStyled>
        <DialogActionsStyled>
          <Button
            onClick={() => handleConfirmDialogClose(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmDialogClose(true)}
            variant="contained"
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActionsStyled>
      </Dialog>
    </>
  );
};

export default NotificationItem;
