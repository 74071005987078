import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { IProcurementFacebook } from "store/procurements-facebook/interfaces";
import { Mockable } from "../index";
import { endpoint } from "constants/endpoint";

export interface IFacebookListService {
  [x: string]: any;
  removeFacebookItems(ids: [number]): Promise<number>;
  copyFacebookItems(
    ids: [number],
    company: number | undefined,
    userId: number,
  ): Promise<number>;
  fetchProcurementFacebookList(
    params: URLSearchParams,
  ): Promise<IProcurementFacebook>;
}

export default new Mockable<IFacebookListService>({
  async fetchProcurementFacebookList(params): Promise<IProcurementFacebook> {
    const res = await axios(`${config.api}${endpoint.misc_facebook}`, {
      params: params,
      headers: commonHeaders(),
    });

    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async copyFacebookItems(ids, company, userId): Promise<number> {
    let result = 0;
    ids.forEach(async (taxonomy_id: number) => {
      const params = {
        account: userId,
        company: company,
        taxonomy: taxonomy_id,
      };
      const res = await axios(
        `${config.api}${endpoint.procurements_facebook}`,
        {
          method: "POST",
          headers: commonHeaders(),
          data: JSON.stringify(params),
        },
      );
      if (res.status === 204) {
        result += 1;
      }
    });

    return result;
  },

  async removeFacebookItems(ids): Promise<number> {
    let result = 0;
    const res = await axios(`${config.api}${endpoint.procurements_facebook}`, {
      method: "GET",
      headers: commonHeaders(),
    });

    if (res.status === 200 && res.data?.results?.length > 0) {
      const selectedIDs = res.data.results.filter(
        (t: { id: number; taxonomy: number }) => ids.includes(t.taxonomy),
      );

      selectedIDs.forEach(async (item: { id: number }) => {
        const res = await axios(
          `${config.api}${endpoint.procurements_facebook}${item.id}`,
          {
            method: "DELETE",
            headers: commonHeaders(),
          },
        );
        if (res.status === 204) {
          result += 1;
        }
      });
    }
    return result;
  },
});
