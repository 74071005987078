import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Function that sets the page query param to 1.
 * Uses 'useSearchParams' for reactivity.
 */
const useFirstPageParams = (): ((searchParams: URLSearchParams) => void) => {
  const [_, setSearchParams] = useSearchParams();

  const goToFirstPage = useCallback(
    (searchParams: URLSearchParams) => {
      searchParams.set("page", "1");
      setSearchParams(searchParams, { replace: true });
    },
    [setSearchParams],
  );

  return goToFirstPage;
};

export default useFirstPageParams;
