import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { IProcurementUserListState } from "./interfaces";
import { fetchUserListAsync } from "./thunks";
export const initialState: IProcurementUserListState = {
  userLoading: false,
  count: 0,
  next: "",
  previous: "",
  results: [],
  deleteMessage: "",
  updateMessage: "",
  addMessage: "",
};
export const userListSlice = createSlice({
  name: "procurementUserList",
  initialState,
  reducers: {
    setUpdateMessage: (state, { payload }) => {
      state.updateMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserListAsync.fulfilled, (state, action) => {
      state.userLoading = false;
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results;
    });
    builder.addCase(fetchUserListAsync.pending, (state) => {
      state.userLoading = true;
    });
    builder.addCase(fetchUserListAsync.rejected, (state) => {
      state.userLoading = false;
    });
  },
});
export const selectUserListState = (
  state: RootState,
): IProcurementUserListState => state.procurementUsersList;
export const { setUpdateMessage } = userListSlice.actions;
export default userListSlice.reducer;
