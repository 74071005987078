import React from "react";
import { ICustomersOrders, IOrderItems } from "store/orders/interfaces";
import Row from "./Row";
import { IInventory } from "store/inventories/interfaces";
import useAppSelector from "hooks/useAppSelector";
import { selectInventoriesState } from "store/inventories";

const TableRows = (props: {
  customersOrders: ICustomersOrders;
  expandAll: boolean;
}): JSX.Element => {
  const { inventoryData } = useAppSelector(selectInventoriesState);

  const inventory = (orderItem: IOrderItems): IInventory[] => {
    const inventories =
      inventoryData[orderItem.product] !== undefined
        ? inventoryData[orderItem.product].inventories
        : [];
    return inventories;
  };

  const proxyProduct = (orderItem: IOrderItems) => {
    const proxyParent =
      inventoryData[orderItem.product] !== undefined &&
      inventoryData[orderItem.product].proxy_parent;
    return proxyParent;
  };

  const getProcurementProductsForOrderItem = (
    orderItem: IOrderItems,
  ): number[] => {
    let procurementProducts: number[] = [];

    if (
      (
        proxyProduct(orderItem) as {
          id: number;
          sku: string;
          name: string;
          unit_factor: string;
        }
      ).id
    ) {
      procurementProducts =
        inventoryData[
          (
            proxyProduct(orderItem) as {
              id: number;
              sku: string;
              name: string;
              unit_factor: string;
            }
          ).id
        ] !== undefined
          ? inventoryData[
              (
                proxyProduct(orderItem) as {
                  id: number;
                  sku: string;
                  name: string;
                  unit_factor: string;
                }
              ).id
            ].procurement_products
          : [];
    } else
      procurementProducts =
        inventoryData[orderItem.product] !== undefined
          ? inventoryData[orderItem.product].procurement_products
          : [];
    return procurementProducts;
  };

  // Need to refactor
  /* Same function is created inside 
src >pages >main-pages >customer-orders >parts >TableRows

*/
  const reverseOrderItems = (orderItems: IOrderItems[]): IOrderItems[] =>
    Object.assign([], orderItems).reverse();
  return (
    <Row
      row={{
        id: props.customersOrders.id,
        expandAll: props.expandAll,
        order: props.customersOrders.order_number,
        orderNotes: props.customersOrders.order_notes,
        availability: props.customersOrders.internal_picking_status,
        backorder: props.customersOrders.internal_backorder_status,
        shippingMethod: props.customersOrders.shipping_method,
        status: props.customersOrders.internal_order_status,
        history: props.customersOrders.order_items.length
          ? (reverseOrderItems(props.customersOrders.order_items)?.map(
              (order_item) => ({
                id: order_item.id,
                inventoryTransaction: order_item.inventory_transactions,
                product: {
                  prod_sku: order_item.prod_sku,
                  prod_name: order_item.prod_name,
                  product_item_id: order_item.product,
                },
                quantity: {
                  qty_picked: Math.trunc(+order_item.qty_picked),
                  qty_ordered: Math.trunc(+order_item.qty_ordered),
                  qty_to_be_picked: Math.trunc(+order_item.qty_to_be_picked),
                  qty_to_be_backordered: Math.trunc(
                    +order_item.qty_to_be_backordered,
                  ),
                },
                price: `${order_item.prod_price} SEK`,
                stock: inventory(order_item),
                proxyParent: proxyProduct(order_item),
                procurementProductsOptions:
                  getProcurementProductsForOrderItem(order_item),
                procurementOrderItem: order_item.procurement_order_item,
              }),
            ) as any)
          : [],
      }}
    />
  );
};

export default TableRows;
