import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash-es";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import useAppDispatch from "hooks/useAppDispatch";
import { fetchStocktaking } from "store/stock-taking/thunks";
import useAppSelector from "hooks/useAppSelector";
import DatagridColumns from "./parts/DataGridColumns";
import DataGridRows from "./parts/DataGridRows";
import Modal from "./parts/stocktaking-dialog/ModalComponent";
import { IStocktakeUILocations } from "./parts/Interfaces";
import Searchbar from "components/filter-products/Searchbar";
import {
  selectStocktakingState,
  setStocktakingParams,
} from "store/stock-taking";
import ErrorPage from "components/ErrorComponent";
import Filter from "components/filter-products/Filter";
import AvailableFilters from "./parts/AvailableFilters";
import { DataGridPro } from "@mui/x-data-grid-pro";
import GridContainer from "components/common-components/GridContainer";

const StockTakingPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const initIStocktakeUILocations: IStocktakeUILocations = {
    id: 0,
    name: "",
    content: "",
    comment: "",
    obsolete: false,
    last_stocktaking_done: null,
    stocktaking: "",
    location_id: 0,
    account_id: 0,
    company_id: 0,
    products: [],
  };
  const [modalData, setModalData] = useState<IStocktakeUILocations>(
    initIStocktakeUILocations,
  );
  const [searchParams] = useSearchParams();
  const { error, inventoryLocationsLoaded } = useAppSelector(
    selectStocktakingState,
  );

  const columns = DatagridColumns({ setModalOpen, setModalData });
  const rows = DataGridRows();

  useEffect(() => {
    dispatch(fetchStocktaking(searchParams));
  }, [dispatch, searchParams]);

  const handleClose = () => {
    setModalOpen(false);
  };

  if (!isEmpty(error)) {
    return <ErrorPage {...error} />;
  }

  const searchHandlerLocation = (value: string) => {
    dispatch(setStocktakingParams({ locationName: value, page: 0 }));
  };

  const searchHandlerProducts = (value: string) => {
    dispatch(setStocktakingParams({ productName: value, page: 0 }));
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: "1rem", paddingBottom: "0.5rem" }}>
        <Searchbar
          placeholder="Search in products"
          onChange={searchHandlerLocation}
        />
        <Searchbar
          placeholder="Search in location name"
          onChange={searchHandlerProducts}
        />
      </Box>
      <Filter viewName="catalog_stocktaking" children={<AvailableFilters />} />
      <Box sx={{ height: "0.5rem" }} />
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          handleClose={handleClose}
          currentRow={modalData}
        />
      )}
      <GridContainer>
        <DataGridPro
          loading={!inventoryLocationsLoaded}
          rows={rows}
          columns={columns}
          columnHeaderHeight={40}
          disableColumnMenu
          disableRowSelectionOnClick
          disableColumnFilter
          getRowHeight={() => "auto"}
          hideFooterPagination
        />
      </GridContainer>
    </>
  );
};

export default StockTakingPage;
