import { createAsyncThunk } from "@reduxjs/toolkit";
import procurementSup from "services/procurement-supplierList";
import {
  IAddProcurementSupplyListParams,
  IUpdateProcurementSupplyListParams,
} from "./interfaces";

interface UpdateProcurementSupplierListArgs {
  id: number;
  params: IUpdateProcurementSupplyListParams;
}

export const fetchSupplierListAsync = createAsyncThunk(
  "procurementSupplierList/fetchSupplierListAsync",
  async (params: URLSearchParams) => {
    const res = await procurementSup
      .service()
      .fetchProcurementSupplierList(params);
    return res;
  },
);

export const fetchSupplierListByIdAsync = createAsyncThunk(
  "procurementSupplierList/fetchSupplierListByIdAsync",
  async (id: number) => {
    const res = await procurementSup
      .service()
      .fetchProcurementSupplierListById(id);
    return res;
  },
);

export const AddProcurementSupplierListAsync = createAsyncThunk(
  "procurementSupplierList/AddProcurementSupplierListAsync",
  async (params: IAddProcurementSupplyListParams) => {
    const res = await procurementSup
      .service()
      .AddProcurementSupplierList(params);
    return res;
  },
);

export const UpdateProcurementSupplierListAsync = createAsyncThunk(
  "procurementSupplierList/UpdateProcurementSupplierListAsync",
  async (args: UpdateProcurementSupplierListArgs) => {
    const { id, params } = args;
    const res = await procurementSup
      .service()
      .UpdateProcurementSupplierList(id, params);
    return res;
  },
);

export const deleteProcurementSupplierListAsync = createAsyncThunk(
  "procurementSupplierList/deleteProcurementSupplierListAsync",
  async (id: number) => {
    const res = await procurementSup
      .service()
      .deleteProcurementSupplierList(id);
    return res;
  },
);
