import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import {
  ICatalogCategoriesResponseDTO,
  ICatalogCategory,
} from "store/catalog-categories/interfaces";
import { endpoint } from "constants/endpoint";

export interface ICatalogCategoriesService {
  fetchCatalogCategories(
    params: URLSearchParams,
  ): Promise<ICatalogCategoriesResponseDTO>;
  updateCatalogCategoriesAsync(
    id: string,
    object: ICatalogCategory,
  ): Promise<ICatalogCategoriesResponseDTO>;
}

export default new Mockable<ICatalogCategoriesService>({
  async fetchCatalogCategories(params): Promise<ICatalogCategoriesResponseDTO> {
    try {
      const res = await axios(`${config.api}${endpoint.catalog_categories}`, {
        params,
        headers: commonHeaders(),
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async fetchCatalogCategories(params) <<",
          ),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },

  async updateCatalogCategoriesAsync(
    id,
    object,
  ): Promise<ICatalogCategoriesResponseDTO> {
    try {
      const res = await axios(
        `${config.api}${endpoint.catalog_categories}${id}/`,
        {
          method: "PATCH",
          headers: commonHeaders(),
          data: {
            procurement_category_ids: object.procurement_category_ids?.map(
              (item: any) => item.id,
            ),
          },
        },
      );

      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async updateCatalogCategoriesAsync <<",
          ),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
});
