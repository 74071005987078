import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import GenerateInvoiceComponent from "./GenerateInvoice";
import { ICompanyDetails, ICustomersOrders } from "store/orders/interfaces";
import DownloadIcon from "@mui/icons-material/Download";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import theme from "theme/theme";
import { Box, Divider } from "@mui/material";

const PdfGeneratorComponent = ({
  orders,
  companyDetails,
}: {
  orders: ICustomersOrders[];
  companyDetails: ICompanyDetails[];
}): JSX.Element => {
  return (
    <>
      {orders && companyDetails && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <PDFDownloadLink
            document={
              <GenerateInvoiceComponent
                orders={orders}
                companyDetails={companyDetails}
                type={"slip"}
              ></GenerateInvoiceComponent>
            }
            fileName="PackingSlip.pdf"
          >
            {/* CODE:N Discuss the UX, find out about the link you need to replace with the below text */}
            <ToolInfoTooltip title="Download slip">
              <ToolIconContainer>
                <CloudDownloadIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.primary.main,
                    stroke: theme.palette.primary.main,
                    strokeWidth: 1,
                  }}
                />
              </ToolIconContainer>
            </ToolInfoTooltip>
          </PDFDownloadLink>

          <Divider orientation="vertical" variant="middle" flexItem />

          <PDFDownloadLink
            document={
              <GenerateInvoiceComponent
                orders={orders}
                companyDetails={companyDetails}
                type={"invoice"}
              ></GenerateInvoiceComponent>
            }
            fileName="Invoice.pdf"
          >
            <ToolInfoTooltip title="Download invoice">
              <ToolIconContainer>
                <DownloadIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.primary.main,
                    stroke: theme.palette.primary.main,
                    strokeWidth: 1,
                  }}
                />
              </ToolIconContainer>
            </ToolInfoTooltip>
          </PDFDownloadLink>
        </Box>
      )}
    </>
  );
};

export default PdfGeneratorComponent;
