export const dataString = {
  headerText: "Select Inventory Location to change",
  searchPlaceholder: "Search in All Field ",
  copyBtn: "Copy to my selection",
  removeBtn: "Delete Selected Inventory Location",
  saveBtn: "Save",
  modalNameOfTheLocation: "Name Of The Location",
  modalFullNameSlug: "Full Name Slug",
  modalAbbreviationSlug: "Abbreviation for the location",
  modalContent: "Content",
  modalComment: "Comment",
  modalLastDateOfStocktakingForLocation:
    "Last Date Of Stocktaking For Location",
  modalObsoleteLocation: "Obsolete Location",
  modalNotForStock: "Not For Stock:",
  modaltype: "Type",
  modaldefaultLocation: "Default Location",
  deleteForEmptySelectRows: "No rows selected!",
  deleteMsgDialogContent: "Are you sure you want to delete it?",
  deleteMsgDialogOKBtn: "Yes",
  deleteMsgDialogCancelBtn: "Cancel",
};
