import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import config from "../../config";
import { RootState } from "../index";
import { IProcurementSupplierFeedState } from "./interface";
import { fetchProcurementSupplierFeedAsync } from "./thunks";
import { httpErrorHandler } from "utils/error-response";

const initialState: IProcurementSupplierFeedState = {
  loading: false,
  FeedList: [],
  category: null,
  count: 0,
  procurementSupplierFeedLoaded: false,
  filterParams: {
    page: 0,
    limit: 50,
    name: "",
    procurementCategoryCreatedGte: "",
    procurementCategoryCreatedLte: "",
    procurementCategoryUpdatedGte: "",
    procurementCategoryUpdatedLte: "",
    search: "",
  },
  success: "",
  fail: "",
  error: {},
};

const procurementSupplierFeedSlice = createSlice({
  name: "procurementSupplierFeed",
  initialState,
  reducers: {
    clearCategoryMessage: (state) => {
      state.success = "";
      state.error = {};
      state.fail = "";
    },
    setSupplierFeedParam: (state, action) => {
      state.procurementSupplierFeedLoaded = false;
      state.filterParams = { ...state.filterParams, ...action.payload };
    },
    // remove a specific feed with given id
    removeSupplierFeedParam: (state, action: PayloadAction<number>) => {
      state.FeedList = state.FeedList.filter(
        (item: any) => action.payload !== item.id,
      );
    },
    resetAllFilters: (state) => {
      state.procurementSupplierFeedLoaded = false;
      state.filterParams = {
        page: 0,
        limit: 50,
        name: "",
        procurementCategoryCreatedGte: "",
        procurementCategoryCreatedLte: "",
        procurementCategoryUpdatedGte: "",
        procurementCategoryUpdatedLte: "",
        search: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProcurementSupplierFeedAsync.fulfilled, (state, action) => {
        state.FeedList = action.payload.results;
        state.procurementSupplierFeedLoaded = true;
        state.loading = false;
        state.nextPageUrl = action.payload.next;
        state.previousPageUrl = action.payload.previous;
        state.count = action.payload.count;
      })
      .addCase(
        fetchProcurementSupplierFeedAsync.rejected,
        (state, { error }) => {
          state.loading = false;
          state.error = {
            ...httpErrorHandler(error),
            url: config.api + "/procurement/categories/",
          };
        },
      );
    // .addCase(addProcurementSupplierFeedAsync.fulfilled, (state, action) => {
    //   state.category = action.payload;
    // })
    // .addCase(addProcurementSupplierFeedAsync.rejected, (state, { error }) => {
    //   state.error = {
    //     ...httpErrorHandler(error),
    //     url: config.api + "/procurement/categories/:id",
    //   };
    // })
    // .addCase(addProcurementSupplierFeedAsync.fulfilled, (state, action) => {
    //   state.category = action.payload;
    //   state.success = "category added successfully";
    // })
    // .addCase(addProcurementSupplierFeedAsync.rejected, (state, { payload }) => {
    //   state.error = {
    //     ...axiosErrorResponse(payload),
    //     url: config.api + "/procurement/categories/:id",
    //   };
    // });
    // .addCase(updateProcurementCategoryAsync.fulfilled, (state, action) => {
    //   state.category = action.payload;
    //   state.success = "category updated successfully";
    // })
    // .addCase(updateProcurementCategoryAsync.rejected, (state, { payload }) => {
    //   state.error = {
    //     ...axiosErrorResponse(payload),
    //     url: config.api + "/procurement/categories/:id",
    //   };
    // })
    // .addCase(deleteProcurementCategory.fulfilled, (state, action) => {
    //   state.success = "1 category deleted successfully";
    //   state.loading = false;
    // })
    // .addCase(deleteProcurementCategory.pending, (state, action) => {
    //   state.loading = true;
    // })
    // .addCase(deleteProcurementCategory.rejected, (state, { error }) => {
    //   state.loading = false;
    //   state.fail = error.message;
    // });
  },
});

export const selectProcurementSupplierFeedState = (
  state: RootState,
): IProcurementSupplierFeedState => state.procurementSupplierFeed;

export const {
  setSupplierFeedParam,
  removeSupplierFeedParam,
  clearCategoryMessage,
} = procurementSupplierFeedSlice.actions;

export default procurementSupplierFeedSlice.reducer;
