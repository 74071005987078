import { IBreadcrumbsRaw, IBreadcrumbsRefined } from "../../../interfaces";
import breadcrumbTranslations from "../json/breadcrumbTranslations.json";

export const extractBreadcrumbs = (pathname: string): IBreadcrumbsRefined[] => {
  const breadcrumbTranslationsObject: IBreadcrumbsRaw = breadcrumbTranslations;
  let pathnameParts: string[];

  if (pathname === "/") {
    pathnameParts = [""];
  } else {
    pathnameParts = pathname.split("/");
  }

  const refinedBreadcrumbs: IBreadcrumbsRefined[] = pathnameParts?.map(
    (part, index) => {
      const name = breadcrumbTranslationsObject[part].name;
      let link: string;

      if (pathnameParts.length === 1) {
        link = "/";
      } else {
        link =
          "/" +
          pathnameParts
            .slice(0, index + 1)
            .join("/")
            .replace("/", "");
      }

      const refinedBreadcrumb: IBreadcrumbsRefined = { link, name };

      return refinedBreadcrumb;
    },
  );

  return refinedBreadcrumbs;
};
