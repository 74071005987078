/* eslint-disable no-console */
import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import { endpoint } from "constants/endpoint";
import {
  IFeedBackProps,
  INotificationItemDTO,
  INotificationItemProps,
  IResultUpdatecompletedBy,
  IUpdateReadAt,
  IUpdatecompletedBy,
  IfetchNotificationItemParams,
} from "store/notification-system/interfaces";

export interface INotificatiintService {
  fetchNotificationItem(
    params: IfetchNotificationItemParams,
  ): Promise<INotificationItemDTO>;
  UpdateCompletedBy(
    id: number,
    params: IUpdatecompletedBy,
  ): Promise<IResultUpdatecompletedBy>;
  UpdateReadAt(
    id: number,
    params: IUpdateReadAt,
  ): Promise<INotificationItemProps>;
  MarkAllAsRead(NotificationItems: INotificationItemProps[]): Promise<number>;
  InsertFeedBack(params: IFeedBackProps): Promise<boolean>;
}

export default new Mockable<INotificatiintService>({
  async fetchNotificationItem(
    params: IfetchNotificationItemParams,
  ): Promise<INotificationItemDTO> {
    try {
      const res = await axios(
        `${config.api}${endpoint.notifications_user}?receiver_user_id=${params.userId}${
          params.showUnread ? "&read_at__isnull=true" : ""
        }`,
        {
          headers: commonHeaders(),
        },
      );

      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error("Unexpected response format in fetchNotificationItem"),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },

  async UpdateReadAt(
    id: number,
    params: IUpdateReadAt,
  ): Promise<INotificationItemProps> {
    const res = await axios(
      `${config.api}${endpoint.notifications_user}${id}/`,
      {
        method: "PUT",
        headers: commonHeaders(),
        data: JSON.stringify(params),
      },
    );
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async UpdateCompletedBy(
    id: number,
    params: IUpdatecompletedBy,
  ): Promise<IResultUpdatecompletedBy> {
    const res = await axios(
      `${config.api}${endpoint.notifications_system}${id}/`,
      {
        method: "PUT",
        headers: commonHeaders(),
        data: JSON.stringify(params),
      },
    );
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async MarkAllAsRead(
    NotificationItems: INotificationItemProps[],
  ): Promise<number> {
    let result = 0;

    const today = new Date();
    const readAt = today.toISOString();

    // Map each item to a promise
    const requests = NotificationItems?.map(async (item: { id: number }) => {
      try {
        const res = await axios(
          `${config.api}${endpoint.notifications_user}${item.id}/`,
          {
            method: "PUT",
            headers: commonHeaders(),
            data: JSON.stringify({ read_at: readAt }),
          },
        );
        if (res.status === 200) {
          result += 1; // Increment result on success
        }
      } catch (error) {
        console.error(`Error marking item ${item.id} as read:`, error);
      }
    });

    await Promise.all(requests);

    return result;
  },

  async InsertFeedBack(params: IFeedBackProps): Promise<boolean> {
    const res = await axios(`${config.api}${endpoint.notifications_feedback}`, {
      method: "POST",
      headers: commonHeaders(),
      data: JSON.stringify(params),
    });
    if (res.status === 201) {
      return true;
    }
    return false;
  },
});
