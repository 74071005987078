import { createAsyncThunk } from "@reduxjs/toolkit";
import procurementSup from "services/procurements-companies";
import { IUpdateProcurementCompaniesListParams } from "./interfaces";

interface UpdateProcurementCompaniesListArgs {
  id: number;
  params: IUpdateProcurementCompaniesListParams;
}

interface FetchCompaniesListParams {
  search: string;
}

export const fetchCompaniesListAsync = createAsyncThunk(
  "procurementCompaniesList/fetchCompaniesListAsync",
  async (params: FetchCompaniesListParams) => {
    const res = await procurementSup
      .service()
      .fetchProcurementCompaniesList(params.search);
    return res;
  },
);

export const fetchCompanesListByIdAsync = createAsyncThunk(
  "procurementCompaniesList/fetchCompaniesListByIdAsync",
  async (id: number) => {
    const res = await procurementSup
      .service()
      .fetchProcurementcompaniesListById(id);
    return res;
  },
);

export const UpdateProcurementCompaniesListAsync = createAsyncThunk(
  "procurementCompaniesList/UpdateProcurementCompaniesListAsync",
  async (args: UpdateProcurementCompaniesListArgs) => {
    const { id, params } = args;
    const res = await procurementSup
      .service()
      .UpdateProcurementCompaniesList(id, params);
    return res;
  },
);
