import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import useAppDispatch from "hooks/useAppDispatch";
import { IProcurementCategory } from "store/procurement-categories/interfaces";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { ICatalogCategory } from "store/catalog-categories/interfaces";
import { updateCatalogCategoriesAsync } from "store/catalog-categories/thunks";

const ColumnsForDataGrid = (
  updateGrid: any,
  categories: IProcurementCategory[],
  loading: boolean,
): GridColDef[] => {
  const dispatch = useAppDispatch();

  const columns: GridColDef[] = [
    {
      field: "slug",
      headerName: "Slug",
      flex: 1,
      sortable: true,
    },
    {
      field: "title",
      flex: 1,
      headerName: "Title",
      sortable: false,
    },

    {
      field: "db_id",
      flex: 1,
      headerName: "DataBase ID",
      sortable: false,
    },
    {
      field: "get_procurement_categories",
      flex: 3,
      headerName: "Procurement Category Mapping",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (loading) {
          return <CircularProgress size={24} />;
        }

        const filterAutoCreate = () => {
          const filtered: any = [];

          for (const category of categories) {
            for (const el of autoCreateArr) {
              if (category.id === el) {
                filtered.push(category);
              }
            }
          }
          return filtered;
        };
        const autoCreateArr = params.row["procurement_category_ids"];

        const hasData =
          categories.length > 0 && !!autoCreateArr && autoCreateArr.length > 0;
        const filtered = hasData ? filterAutoCreate() : [];

        const handleSelection = (event: any, newValue: any) => {
          const objectTobeUpdated: ICatalogCategory = {
            ...params.row,
            procurement_category_ids: newValue,
          };
          dispatch(
            updateCatalogCategoriesAsync({
              id: params.row.id,
              object: objectTobeUpdated,
            }),
          )
            .unwrap()
            .finally(() => {
              updateGrid();
            });
        };

        return (
          <Autocomplete
            multiple
            fullWidth
            id="tags-standard"
            options={categories}
            onChange={handleSelection}
            getOptionLabel={(option: any) => option.slug}
            defaultValue={filtered.length > 0 ? filtered : []}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
          />
        );
      },
    },

    {
      field: "no_of_products",
      flex: 1,
      headerName: "No Of Product",
      sortable: false,
    },
  ];

  return columns;
};

export default ColumnsForDataGrid;
