import React from "react";
import { Box, TextFieldProps } from "@mui/material";
import {
  GridColDef,
  GridEditInputCell,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import PriceDetails from "./price-views/PriceDetails";
import ProcurementDetails from "./price-views/ProcurementDetails";

export const textFieldProps: TextFieldProps = {
  fullWidth: true,
  margin: "normal",
  size: "small",
  variant: "outlined",
};

const ColumnsForPrices = (): GridColDef[] => {
  const stockColumns: GridColDef[] = [
    {
      field: "sku",
      flex: 0.5,
      headerName: "SKU",
      sortable: true,
      pinnable: true,
    },
    {
      field: "name",
      flex: 1,
      headerName: "Product name",
      sortable: true,
      pinnable: true,
    },
    {
      field: "Procurement_details",
      flex: 2,
      headerName: "Procurement details",
      pinnable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <ProcurementDetails id={params.row.id} />
      ),
    },
    {
      field: "price_details",
      flex: 1.5,
      headerName: "Price details",
      pinnable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <PriceDetails id={params.row.id} />
      ),
    },
    {
      field: "cost_shipping",
      type: "number",
      flex: 1,
      headerName: "Shipping cost",
      pinnable: true,
      sortable: false,
      editable: true,
      valueGetter: (params: { value: any }) => +params.value,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            step: ".01",
          }}
        />
      ),
    },
    {
      field: "cost_packaging_labour",
      type: "number",
      align: "left",
      flex: 1,
      headerName: "Packaging labour cost",
      pinnable: true,
      sortable: false,
      editable: true,
      valueGetter: (params: { value: any }) => +params.value,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            step: "1",
          }}
        />
      ),
    },
    {
      field: "cost_packaging_material",
      flex: 1,
      align: "left",
      headerName: "Packaging material cost",
      pinnable: true,
      sortable: false,
      editable: true,
      type: "number",
      valueGetter: (params: { value: any }) => +params.value,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            step: "1",
          }}
        />
      ),
    },
    {
      field: "price_manual_override",
      flex: 1,
      headerName: "Price override",
      pinnable: true,
      sortable: false,
      editable: true,
      type: "number",
      valueGetter: (params: { value: any }) => +params.value,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            step: ".01",
          }}
        />
      ),
    },
    {
      field: "price_on_sale",
      flex: 1,
      headerName: "Sale price",
      pinnable: true,
      editable: true,
      type: "number",
      valueGetter: (params: { value: any }) => +params.value,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            step: ".01",
          }}
        />
      ),
    },
    {
      field: "margin",
      flex: 1,
      headerName: "Margin/Percent",
      pinnable: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          {Math.round(+params.row.marginWithPercent.margin)} /{" "}
          {params.row.marginWithPercent.percent}
        </Box>
      ),
    },
  ];

  return stockColumns;
};

export default ColumnsForPrices;
