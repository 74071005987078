import React from "react";
import Fab from "@mui/material/Fab";
import SvgIcon from "@mui/material/SvgIcon";
import { useNavigate } from "react-router-dom";

interface IRightArrowButtonProps {
  destination?: {
    pathname: string;
    search: string;
  };
}

const RightArrowButton = ({
  destination,
}: IRightArrowButtonProps): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Fab
      onClick={() => (destination ? navigate(destination) : {})}
      sx={{
        backgroundColor: "white",
        width: "2rem",
        minHeight: "0",
        height: "2rem",
      }}
    >
      <SvgIcon>
        <svg
          fill="none"
          stroke="#000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="4" y1="12" x2="20" y2="12" />
          <polyline points="14 6 20 12 14 18" />
        </svg>
      </SvgIcon>
    </Fab>
  );
};

export default RightArrowButton;
