import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash-es";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  Paper,
  Stack,
  Button,
  TableCell,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { ICustomersOrders } from "store/orders/interfaces";
import Searchbar from "components/filter-products/Searchbar";
import Filter from "components/filter-products/Filter";
import CustomTableHead, { Data, Order } from "./parts/CustomTableHead";
import { ordersSelectors, setOrdersParams } from "store/orders";
import useAppDispatch from "hooks/useAppDispatch";
import SyncOrderDropdown from "./parts/SyncOrderDrpdown";
import TableRows from "./parts/TableRows";
import useAppSelector from "hooks/useAppSelector";
import { selectBackorderState } from "store/backorder";
import { fetchBackOrderAsync } from "store/backorder/thunks";
import { selectProcurementOrdersState } from "store/procurement-orders";
import { fetchProcurementOrdersAsync } from "store/procurement-orders/thunks";
import ErrorPage from "components/ErrorComponent";
import { fetchOrdersAsync } from "store/orders/thunks";
import AvailableFilters from "../customers-orders/parts/AvailableFilters";
import { useSearchParams } from "react-router-dom";
import useBreakpoint from "hooks/useBreakpoint";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import theme from "theme/theme";

export default function PickAndPackByOrderPage(): JSX.Element {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [expandAll, setExpandAll] = useState(true);
  const { count, orders, ordersLoaded, error } =
    useAppSelector(ordersSelectors);
  const { backOrderLoaded } = useAppSelector(selectBackorderState);
  const { procurementOrdersLoaded } = useAppSelector(
    selectProcurementOrdersState,
  );
  const isMediumScreen = useBreakpoint("medium");

  useEffect(() => {
    if (!backOrderLoaded) dispatch(fetchBackOrderAsync());
  }, [dispatch, backOrderLoaded]);

  useEffect(() => {
    if (!procurementOrdersLoaded)
      dispatch(fetchProcurementOrdersAsync({ order_delivered__isnull: true }));
  }, [procurementOrdersLoaded, dispatch]);

  useEffect(() => {
    dispatch(fetchOrdersAsync(searchParams));
  }, [dispatch, searchParams]);

  const [order, setOrder] = useState<Order>();
  const [orderBy, setOrderBy] = useState<keyof Data>();

  const handleSortRequest = (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data,
  ) => {
    const isAsc = orderBy === newOrderBy && order === "asc";
    const toggledOrder = isAsc ? "desc" : "asc";
    setOrder(toggledOrder);
    setOrderBy(newOrderBy);
    dispatch(
      setOrdersParams({ sortDirection: toggledOrder, sortField: newOrderBy }),
    );
  };

  if (!isEmpty(error)) {
    return <ErrorPage {...error} />;
  }
  const searchHandler = (value: string) => {
    dispatch(setOrdersParams({ search: value, page: 0 }));
    dispatch(fetchProcurementOrdersAsync({ search: value }));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" onChange={searchHandler} />
        <ToolboxArea>
          <ToolInfoTooltip title={expandAll ? "Collapsed all" : "Expand all"}>
            <ToolIconContainer onClick={() => setExpandAll(!expandAll)}>
              {expandAll ? (
                <UnfoldLessIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.primary.main,
                    stroke: theme.palette.primary.main,
                    strokeWidth: 1,
                  }}
                />
              ) : (
                <UnfoldMoreIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.primary.main,
                    stroke: theme.palette.primary.main,
                    strokeWidth: 1,
                  }}
                />
              )}
            </ToolIconContainer>
          </ToolInfoTooltip>
        </ToolboxArea>

        <SyncOrderDropdown />
      </Box>

      <Filter viewName="pick_and_pack">
        <AvailableFilters />
      </Filter>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ height: "calc(100vh - 370px)" }}>
          <Table>
            <CustomTableHead
              order={order as Order}
              orderBy={String(orderBy)}
              onRequestSort={handleSortRequest}
            />
            <TableBody>
              {!ordersLoaded ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="center"
                      height="40vh"
                    >
                      <CircularProgress size={25} />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : ordersLoaded && orders.length > 0 ? (
                orders?.map((customersOrders: ICustomersOrders) => (
                  <TableRows
                    key={customersOrders.id}
                    customersOrders={customersOrders}
                    expandAll={expandAll}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="center"
                      height="40vh"
                    >
                      No rows
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
