import { createAsyncThunk } from "@reduxjs/toolkit";
import suppliers from "services/suppliers";
import catalog from "services/catalog";

export const fetchSuppliers = createAsyncThunk(
  "filterCatalogProducts/fetchSuppliers",
  async () => {
    return await suppliers.service().fetchSuppliers();
  },
);

export const fetchProductTypes = createAsyncThunk(
  "filterCatalogProducts/fetchProductTypes",
  async () => {
    return await catalog.service().fetchProductTypes();
  },
);
