import { IconButton } from "@mui/material";
import React from "react";

interface Props {
  children?: React.ReactNode;
  width?: string;
  height?: string;
  backgroundColor?: string;
  marginRight?: string;
  textColor?: string;
  hoverCursor?: string;
  disableRipple?: boolean;
  onClickPorp?: () => void;
}

const CommonIconButton = ({
  children,
  width,
  height,
  backgroundColor,
  marginRight,
  hoverCursor,
  disableRipple,
  textColor,
  onClickPorp,
}: Props): JSX.Element => {
  return (
    <IconButton
      disableRipple={disableRipple}
      onClick={onClickPorp}
      sx={{
        width: width,
        height: height,
        background: backgroundColor,
        marginRight: marginRight,
        color: textColor,
        ":hover": {
          cursor: hoverCursor,
        },
      }}
    >
      {children}
    </IconButton>
  );
};

export default CommonIconButton;
