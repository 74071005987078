import { createAsyncThunk } from "@reduxjs/toolkit";
import taxonomies from "services/taxonomies";
import {
  CopyMiscGoogleProductsTaxonomyList,
  ITaxonomiesDTO,
  RemoveMiscGoogleProductsTaxonomyList,
} from "./interfaces";

export const fetchTaxonomies = createAsyncThunk(
  "/fetchTaxonomies",
  async (params: URLSearchParams): Promise<ITaxonomiesDTO> => {
    return await taxonomies.service().fetchTaxonomiesData(params);
  },
);

export const copyMiscGoogleProductsAsync = createAsyncThunk(
  "/copyMiscellaneusGoogleProductTaxonomyItems",
  async (args: CopyMiscGoogleProductsTaxonomyList) => {
    const { ids, userId } = args;
    const result = await taxonomies
      .service()
      .copyMiscGoogleProductsTaxonomyItems(ids, userId);
    return result;
  },
);

export const removeMiscGoogleProductsAsync = createAsyncThunk(
  "/removeMiscellaneusGoogleProductTaxonomyItems",
  async (args: RemoveMiscGoogleProductsTaxonomyList) => {
    const { ids } = args;
    const result = await taxonomies
      .service()
      .removeMiscGoogleProductsTaxonomyItems(ids);
    return result;
  },
);
