import React from "react";
import { Box, Typography } from "@mui/material";
import ComingSoonTooltip from "components/ComingSoonTooltip";
import theme from "theme/theme";
import RightArrowButton from "components/common-components/RightArrowButton";

interface Props {
  children: React.ReactNode;
  caption: string;
  pathnameUrl?: string;
  serachPhrase: string | "";
  key?: any;
}

const Row = ({ children, caption, pathnameUrl, serachPhrase, key }: Props) => {
  return (
    <Box key={key} sx={{ marginBlock: theme.spacing(1.5) }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontSize: theme.typography.subtitle2.fontSize,
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          {children}
        </Typography>
        {pathnameUrl ? (
          <RightArrowButton
            destination={{
              pathname: pathnameUrl,
              search: serachPhrase,
            }}
          />
        ) : (
          <ComingSoonTooltip>
            <RightArrowButton />
          </ComingSoonTooltip>
        )}
      </Box>
      <Typography
        sx={{
          fontSize: theme.typography.subtitle1.fontSize,
        }}
      >
        {caption}
      </Typography>
    </Box>
  );
};

export default Row;
