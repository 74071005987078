import { useMediaQuery } from "@mui/material";
import breakpoints from "theme/breakpoints";
import theme from "theme/theme";

const useBreakpoint = (breakpoint: keyof typeof breakpoints): boolean => {
  const matches = useMediaQuery(theme.breakpoints.up(breakpoints[breakpoint]));

  return matches;
};

export default useBreakpoint;
