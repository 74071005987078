import React from "react";
import { useSearchParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FilterAccordion from "./FilterAccordion";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import useChangeFilters from "hooks/useChangeFilters";

interface IFilterSelectBinaryProps {
  label: string;
  param: string;
  inverted?: boolean;
}

const FilterSelectBinary = ({
  label,
  param,
  inverted = false,
}: IFilterSelectBinaryProps): JSX.Element => {
  const [searchParams] = useSearchParams();
  const changeFilter = useChangeFilters();
  const selectedValue = searchParams.get(param);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    changeFilter(new URLSearchParams([[param, checked ? value : ""]]));
  };

  return (
    <FilterAccordion label={label}>
      <FormControl>
        <FormGroup row>
          <FormControlLabel
            label={"Yes"}
            control={
              <Checkbox
                checked={
                  inverted
                    ? selectedValue === "false"
                    : selectedValue === "true"
                }
                value={inverted ? "false" : "true"}
                onChange={handleChange}
              />
            }
          />
          <FormControlLabel
            label={"No"}
            control={
              <Checkbox
                checked={
                  inverted
                    ? selectedValue === "true"
                    : selectedValue === "false"
                }
                value={inverted ? "true" : "false"}
                onChange={handleChange}
              />
            }
          />
        </FormGroup>
      </FormControl>
    </FilterAccordion>
  );
};

export default FilterSelectBinary;
