import React, { useRef, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Paper from "@mui/material/Paper";
import { Cell, Label, Pie, PieChart, Tooltip } from "recharts";
import useAppSelector from "hooks/useAppSelector";
import { selectSuppliers } from "store/suppliers";
import { useNavigate } from "react-router-dom";
import { SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_PRODUCTS } from "constants/urls";
import { ISupplier } from "store/suppliers/interfaces";
import { Box, Typography } from "@mui/material";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import theme from "theme/theme";
import CommonIconButton from "components/common-components/CommonIconButton";
import colors from "../chartColors";

interface ICustomLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  index: number;
}

const SupplierCharts = (): JSX.Element => {
  const suppliers = useAppSelector(selectSuppliers);
  const legends = useRef<HTMLDivElement>();
  const [showAllSuppliers, setShowAllSuppliers] = useState(false);
  const navigate = useNavigate();

  const showMore = () => {
    setShowAllSuppliers(true);
  };

  const showLess = () => {
    setShowAllSuppliers(false);
  };

  const getColor = (index: number) => {
    return colors[index % colors.length];
  };

  const RADIAN = Math.PI / 180;
  const customLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index,
  }: ICustomLabelProps) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {suppliers[index].total_products}
      </text>
    );
  };

  return (
    <Paper
      elevation={0}
      sx={{
        padding: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CommonIconButton
          disableRipple
          width="2rem"
          height="2rem"
          backgroundColor={theme.palette.secondary.light}
          marginRight={theme.spacing(0.5)}
          hoverCursor="default"
          textColor={theme.palette.primary.main}
        >
          <AddShoppingCartOutlinedIcon
            sx={{
              fontSize: "12",
              ":hover": {
                cursor: "default",
              },
            }}
          />
        </CommonIconButton>
        <Typography
          sx={{
            fontSize: theme.typography.h5.fontSize,
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          My suppliers
        </Typography>
      </Box>
      <Box sx={{ margin: "auto" }}>
        <PieChart width={300} height={250}>
          <Pie
            data={suppliers}
            dataKey="total_products"
            innerRadius={60}
            outerRadius={80}
            label={customLabel}
            labelLine={false}
            isAnimationActive={false}
            cursor="pointer"
            onClick={(e: ISupplier) =>
              navigate({
                pathname: SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_PRODUCTS,
                search: `?supplier=${e.name_abbrev}`,
              })
            }
          >
            {suppliers?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getColor(index)} />
            ))}
            <Label
              value={suppliers.reduce(
                (acc, curr) => acc + curr.total_products,
                0,
              )}
              offset={0}
              position="center"
            />
          </Pie>
          <Tooltip />
        </PieChart>
      </Box>

      <Box
        ref={legends}
        sx={{
          display: "flex",
          "& > *:not(:last-child)": { marginRight: theme.spacing(0.5) },
        }}
      >
        {suppliers?.map((s, index) => (
          <Typography
            sx={{
              fontSize: theme.typography.subtitle1.fontSize,
              display: "flex",
              alignItems: "center",
            }}
            key={s.id}
            onClick={() =>
              navigate({
                pathname: SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_PRODUCTS,
                search: `?supplier=${s.name_abbrev}`,
              })
            }
          >
            <CircleIcon sx={{ color: getColor(index) }} />
            {s.name}
          </Typography>
        ))}
        {showAllSuppliers ? (
          <CloseIcon onClick={showLess} color="subtle" />
        ) : (
          <MoreHorizIcon onClick={showMore} color="subtle" />
        )}
      </Box>
    </Paper>
  );
};

export default SupplierCharts;
