import Sidebar from "./layout/side-bar";
import { Box, Container, IconButton, Toolbar, Typography } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import TopAppBar from "./layout/app-bar";
import Breadcrumbs from "components/breadcrumbs";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React, { useState } from "react";
import theme from "theme/theme";

const MainPage = (): JSX.Element => {
  const location = useLocation();
  const [isSidebarOpen, SetIsSidebarOpen] = useState(true);

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ width: "100%", display: "flex", flexDirection: "column" }}
    >
      <TopAppBar />
      <Toolbar variant="dense" />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: "calc(100vh - 48px)",
          borderRadius: "0",
        }}
      >
        <Box
          sx={{
            width: isSidebarOpen ? "350px" : "0px",
            overflowY: "auto",
            overflowX: "hidden",
            borderRadius: "0",
            padding: isSidebarOpen ? "0 1rem" : "0",
            scrollbarGutter: "stable",
            background: "white",
            transitionDuration: "300ms",
            borderRight: "2px solid #e0e0e0",
          }}
        >
          <IconButton
            disableRipple
            sx={{
              width: "1.5rem",
              height: "1.5rem",
              border: "1px solid #e0e0e0",
              position: "fixed",
              background: "white",
              top: "50px",
              left: isSidebarOpen ? "340px" : "2px",
            }}
            onClick={() => SetIsSidebarOpen((prev) => !prev)}
          >
            {isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <Sidebar />
        </Box>
        <Box
          sx={{
            flex: 1,
            width: "100%",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(3),
            height: "100%",
            gap: 1,
          }}
        >
          <Typography
            component="div"
            sx={{
              fontSize: theme.typography.h4.fontSize,
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            {location.pathname !== "/" && <Breadcrumbs />}
          </Typography>
          <Outlet />
        </Box>
      </Box>
    </Container>
  );
};

export default MainPage;
