import useAppSelector from "hooks/useAppSelector";
import { selectStocktakingState } from "store/stock-taking";
import { IStocktakeUILocations } from "./Interfaces";

const DataGridRows = (): IStocktakeUILocations[] => {
  const { inventoryLocations, inventoryProducts, filterParams } =
    useAppSelector(selectStocktakingState);

  let rows: IStocktakeUILocations[] = inventoryLocations?.map((loc) => ({
    id: loc.id,
    name: loc.abbreviation_slug ? loc.abbreviation_slug : loc.name_slug,
    content: loc.content,
    comment: loc.comment,
    obsolete: loc.obsolete,
    last_stocktaking_done: loc.stocktaking_perfomed_at,
    location_id: loc.id,
    account_id: loc.account_id,
    company_id: loc.company_id,
    stocktaking: "Stocktake",
    products: Object.entries(loc.inventory_transactions)?.map(
      (transactionsProduct) => {
        const product = inventoryProducts.find(
          (product) => product.id === transactionsProduct[1].product_id,
        )!;
        return {
          id: product.id,
          name: product.name,
          sku: product.sku,
          quantity: Math.trunc(+transactionsProduct[1].quantity).toString(),
          performed_stocktaking: loc.stocktaking_perfomed_at,
          transaction_id: +transactionsProduct[0],
        };
      },
    ),
  }));

  if (filterParams.productName !== "") {
    rows = rows.filter((locationObj) => {
      const filteredProducts = locationObj.products.filter((product) => {
        const identifyProductBy = `${product.sku} ${product.name}`;
        return identifyProductBy
          .toLowerCase()
          .includes(filterParams.productName.toLowerCase());
      });

      locationObj.products = filteredProducts;
      return filteredProducts.length ? locationObj : false;
    });
  }
  return rows;
};

export default DataGridRows;
