import Reactß from "react";
import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  AnyAction,
} from "@reduxjs/toolkit";
import authSlice from "./auth";
import snackbarSlice from "./snackbar";
import procurementProductsSlice from "./procurement-products";
import bookmarksSlice from "./bookmarks";
import suppliersSlice from "./suppliers";
import allSuppliersAndBrandsSlice from "./all-suppliers-and-brands";
import catalogSlice from "./catalog";
import stocktakingSlice from "./stock-taking";
import onboardingSlice from "./onboarding";
import filterProcurementProductsSlice from "./filter-procurement-products";
import filterCatalogProductsSlice from "./filter-catalog-products";
import filterStocktakingProductsSlice from "./filter-stocktaking-products";
import ordersSlice from "./orders";
import syncOrderSlice from "./orders/syncOrder";
import syncOrderSlice1month from "./orders/syncOrder1month";
import pickOrderSlice from "./orders/pickOrder";
import unpickOrderSlice from "./orders/unpickOrder";
import procurementOrdersSlice from "./procurement-orders";
import inventoriesSlice from "./inventories";
import procurementSourcesSlice from "./procurement-sources";
import backOrderSlice from "./backorder";
import inventoryTransactionsSlice from "./inventory-transactions";
import catalogInventoryLocationTypesSlice from "./catalog-inventorylocationtype";
import supplierListSlice from "./procurement-supplierList";
import procurementCategoriesSlice from "./procurement-categories";
import catalogCategoriesSlice from "./catalog-categories";
import currenciesSlice from "./currencies";
import imagesSlice from "./image-libraries";
import imageClassificationSlice from "./image-classifications";
import inventoryLocationsSlice from "./inventory-locations";
import catalogMarkupsSlice from "./catalog-markup";
import procurementSupplierFeedSlice from "./procurement-feeds";
import procurementImages from "./procurement_images";
import ProcurementCompaniesListSlice from "./procurements-companies";
import ProcurementFacebookListSlice from "./procurements-facebook";
import taxonomies from "./taxonomies";
import InventoryLocationsListSlice from "./catalog-inventory-locations";
import notificationSlice from "./notification-system";
import ProductCatalogFileUrlsListSlice from "./catalog-file-urls";
import userListSlice from "./users-page";
import sidebarSlice from "./sidebar/sidebarSlice";
import productFiltersSlice from "./catalog-bulk-description/index";

const RESET = "RESET";

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const combinedReducer = combineReducers({
  allSuppliersAndBrands: allSuppliersAndBrandsSlice,
  auth: authSlice,
  procurementProducts: procurementProductsSlice,
  bookmarks: bookmarksSlice,
  snackbar: snackbarSlice,
  suppliers: suppliersSlice,
  catalog: catalogSlice,
  onboarding: onboardingSlice,
  filterProcurementProducts: filterProcurementProductsSlice,
  filterCatalogProducts: filterCatalogProductsSlice,
  stocktaking: stocktakingSlice,
  filterStocktakingProducts: filterStocktakingProductsSlice,
  productFilters: productFiltersSlice,
  orders: ordersSlice,
  ordersSync: syncOrderSlice,
  ordersSync1month: syncOrderSlice1month,
  pickOrder: pickOrderSlice,
  unpickOrder: unpickOrderSlice,
  procurementOrders: procurementOrdersSlice,
  inventories: inventoriesSlice,
  procurementSources: procurementSourcesSlice,
  backOrder: backOrderSlice,
  inventoryTransactions: inventoryTransactionsSlice,
  inventoryLocationTypes: catalogInventoryLocationTypesSlice,
  procurementsupplierlist: supplierListSlice,
  procurementCategories: procurementCategoriesSlice,
  procurementSupplierFeed: procurementSupplierFeedSlice,
  catalogCategories: catalogCategoriesSlice,
  currencies: currenciesSlice,
  images: imagesSlice,
  markups: catalogMarkupsSlice,
  imageClassifications: imageClassificationSlice,
  inventoryLocations: inventoryLocationsSlice,
  procurementImages: procurementImages,
  ProcurementCompaniesList: ProcurementCompaniesListSlice,
  ProcurementFacebookList: ProcurementFacebookListSlice,
  taxonomies: taxonomies,
  catalogInventoryLocations: InventoryLocationsListSlice,
  notifications: notificationSlice,
  ProductCatalogFileUrlsList: ProductCatalogFileUrlsListSlice,
  procurementUsersList: userListSlice,
  sidebar: sidebarSlice,
});

const rootReducer: typeof combinedReducer = (
  state: RootState | any,
  action: Action,
) => {
  if (action.type === RESET) {
    state = {} as RootState;
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export const resetState: AnyAction = { type: RESET };

export default store;
