import React from "react";
import { Box, CircularProgress } from "@mui/material";

function Loading(): JSX.Element {
  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      height="40vh"
    >
      <CircularProgress size={25} />
    </Box>
  );
}

export default Loading;
