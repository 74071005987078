import React from "react";
import { View, StyleSheet, Text, Font } from "@react-pdf/renderer";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
  ],
});

const styles = StyleSheet.create({
  fontBold: {
    fontFamily: "Open Sans",
    fontWeight: 800,
  },
  addressFont: {
    display: "flex",
    flexDirection: "row",
    fontSize: 9,
    marginBottom: 10,
  },
  leftColumn: {
    flex: 1,
  },
});

const OrderCustomerDetailComponent = ({ userData }: any): JSX.Element => (
  <>
    <View style={styles.addressFont}>
      <View style={styles.leftColumn}>
        <Text style={styles.fontBold}>{"Delivery address"}</Text>
        <Text>{`${userData.shipping_address_first_name} ${userData.shipping_address_last_name}`}</Text>
        <Text>{`${userData.shipping_address_company}`}</Text>
        <Text>{`${userData.shipping_address_address_line1} ${userData.shipping_address_address_line2}
    ${userData.shipping_address_postcode} ${userData.shipping_address_city}
    ${userData.shipping_address_state} ${userData.shipping_address_country}`}</Text>
      </View>
      <View style={styles.leftColumn}>
        <Text style={styles.fontBold}>{"Billing address"}</Text>
        <Text>{`${userData.billing_address_first_name} ${userData.billing_address_last_name}`}</Text>
        <Text>{`${userData.billing_address_company}`}</Text>
        <Text
          style={{ marginBottom: 20 }}
        >{`${userData.billing_address_address_line1} ${userData.billing_address_address_line2}
          ${userData.billing_address_postcode} ${userData.billing_address_city}
          ${userData.billing_address_state} ${userData.billing_address_country}`}</Text>
        {userData.billing_address_email && (
          <Text
            style={{ marginBottom: 20 }}
          >{`Email:  ${userData.billing_address_email}`}</Text>
        )}
        <Text
          style={{ marginBottom: 20 }}
        >{`TelePhone:  ${userData.billing_address_phonenumber}`}</Text>
      </View>
      <View style={styles.leftColumn}>
        <Text style={styles.fontBold}>{`Er referens/märke: `}</Text>
        <Text style={{ marginBottom: 20 }}>{`${userData.our_reference}`}</Text>
        <Text style={styles.fontBold}>{`Ordernoteringar: `}</Text>
        <Text style={{ marginBottom: 20 }}>{`${userData.order_notes}`}</Text>
      </View>
    </View>
    <View style={styles.addressFont}>
      <View style={styles.leftColumn}>
        {userData?.payment_method_details && (
          <>
            <Text style={styles.fontBold}>{"Payment method"}</Text>
            <Text>{`${userData.payment_method_details}`}</Text>
          </>
        )}
      </View>
      <View style={styles.leftColumn}>
        {userData?.shipping_method && (
          <>
            <Text style={styles.fontBold}>{"Delivery Method"}</Text>
            <Text>{`${userData.shipping_method}`}</Text>
          </>
        )}
      </View>
      <View style={styles.leftColumn}>
        {userData?.our_reference && (
          <>
            <Text style={styles.fontBold}>{`Our reference`}</Text>
            <Text
              style={{ marginBottom: 20 }}
            >{`${userData.our_reference}`}</Text>
          </>
        )}
      </View>
    </View>
  </>
);

export default OrderCustomerDetailComponent;
