import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import config from "../../config";
import { IImagesFilterParams, IImagesState } from "./interfaces";
import {
  fetchImagesAsync,
  fetchCategoryImagesAsync,
  fetchProductImagesAsync,
  uploadImageAsync,
} from "./thunks";
import { httpErrorHandler } from "utils/error-response";
import { endpoint } from "constants/endpoint";

export const initialStateParams: IImagesFilterParams = {
  page: 0,
  limit: 25,
  title: "",
  size: 0,
  width: 0,
  height: 0,
  classification: { id: 0, title: "" },
  search: "",
};

const initialState: IImagesState = {
  images: [],
  imagesLoaded: false,
  count: 0,
  filterParams: initialStateParams,
  error: {},
  productImageUrl: [],
  catergoryImageUrl: [],
  imageUploadSuccess: 0,
  imageUploadFailed: 0,
  nextPageUrl: "",
  previousPageUrl: "",
  updateMessage: "",
};

const imagesSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    setImagesParams: (state, action) => {
      state.imagesLoaded = false;
      state.filterParams = { ...state.filterParams, ...action.payload };
    },
    resetAllFilters: (state) => {
      state.imagesLoaded = false;
      state.filterParams = initialStateParams;
    },
    setUpdateMessage: (state, { payload }) => {
      state.updateMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchImagesAsync.fulfilled, (state, action) => {
        state.images = action.payload.results;
        state.count = action.payload.count;
        state.nextPageUrl = action.payload.next;
        state.previousPageUrl = action.payload.previous;
        state.imagesLoaded = true;
      })
      .addCase(fetchImagesAsync.rejected, (state, { error }) => {
        state.error = {
          ...httpErrorHandler(error),
          url: config.api + endpoint.procurement_image_urls,
        };
      })
      .addCase(fetchProductImagesAsync.fulfilled, (state, action) => {
        state.productImageUrl = action.payload.results;
      })
      .addCase(fetchProductImagesAsync.rejected, (state, { error }) => {
        state.error = {
          ...httpErrorHandler(error),
          url: config.api + endpoint.product_images,
        };
      })
      .addCase(fetchCategoryImagesAsync.fulfilled, (state, action) => {
        state.catergoryImageUrl = action.payload.results;
      })
      .addCase(fetchCategoryImagesAsync.rejected, (state, { error }) => {
        state.error = {
          ...httpErrorHandler(error),
          url: config.api + endpoint.procurement_image_urls,
        };
      })
      .addCase(uploadImageAsync.fulfilled, (state, action) => {
        state.imageUploadSuccess++;
      })
      .addCase(uploadImageAsync.rejected, (state, { error }) => {
        state.imageUploadFailed++;
        state.error = {
          ...httpErrorHandler(error),
          url: config.api + endpoint.procurement_image_urls,
        };
      });
  },
});

export const selectImagesState = (state: RootState): IImagesState =>
  state.procurementImages;
export const { setImagesParams, resetAllFilters, setUpdateMessage } =
  imagesSlice.actions;

export default imagesSlice.reducer;
