import React, { useEffect } from "react";
import useAppSelector from "hooks/useAppSelector";
import { selectImagesState } from "store/image-libraries";
import { selectImageClassificationState } from "store/image-classifications";
import { IImageClassification } from "store/image-classifications/interfaces";
import { fetchImageClassificationsAsync } from "store/image-classifications/thunks";
import useAppDispatch from "hooks/useAppDispatch";
import { Box, Grid, Link, Typography } from "@mui/material";

const GridViewLayout = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { images } = useAppSelector(selectImagesState);
  const { imageClassificationsLoaded, imageClassifications } = useAppSelector(
    selectImageClassificationState,
  );

  useEffect(() => {
    !imageClassificationsLoaded && dispatch(fetchImageClassificationsAsync());
  }, [imageClassificationsLoaded, dispatch]);

  const findClassificationChange = (classification: number) => {
    const found =
      imageClassifications &&
      imageClassifications.find(
        (img: IImageClassification) => img.id === classification,
      );
    return found !== undefined ? (
      <Typography component="span" fontSize={12} paddingTop={"0.5rem"}>
        Classification: {found.title}
      </Typography>
    ) : (
      "-"
    );
  };

  return (
    <div style={{ margin: "0.5rem", height: "86%", overflow: "auto" }}>
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {images?.map((item, index) => (
          <Grid item xs={2} sm={2} md={2} key={index}>
            <div
              style={{
                border: "1px solid #cacaca",
                borderRadius: "4px",
                padding: "1rem",
                background: "#F2F2F2",
                height: "100%",
              }}
            >
              <Box sx={{ height: "140px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={item.thumbnail_small_url}
                    width={"90%"}
                    alt="product image"
                    loading="lazy"
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {findClassificationChange(item.classification)}
                </Box>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Link
                    target="_blank"
                    href={item.image_url}
                    style={{ width: "100%" }}
                  >
                    <Typography
                      fontSize={12}
                      noWrap
                      style={{ display: "block", textOverflow: "ellipsis" }}
                    >
                      {item.title}
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
export default GridViewLayout;
