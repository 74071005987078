import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Alert,
  Box,
  Divider,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import ColumnsForDataGrid from "./parts/ColumnsForDataGrid";
import {
  selectImagesState,
  setImagesParams,
  setUpdateMessage,
} from "store/procurement_images";
import {
  deleteImageUrlsAsync,
  fetchImagesAsync,
} from "store/procurement_images/thunks";
import Searchbar from "components/filter-products/Searchbar";
import { selectImageClassificationState } from "store/image-classifications";
import { fetchImageClassificationsAsync } from "store/image-classifications/thunks";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import GridViewLayout from "./parts/GridViewLayout";
import MultiFileUploadComponent from "./parts/MultiFileUpload";
import useChangeFilters from "hooks/useChangeFilters";
import Filter from "components/filter-products/Filter";
import AvailableFilters from "./parts/AvailableFilters";
import SimpleSnackbar from "components/SimpleSnackbar";
import GridContainer from "components/common-components/GridContainer";
import { DataGridPro } from "@mui/x-data-grid-pro";
import useBreakpoint from "hooks/useBreakpoint";
import theme from "theme/theme";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CommonConfirmDialog from "components/common-components/CommonConfirmDialog";

const ProcurementImages = (): JSX.Element => {
  const [view, setView] = useState("list");
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [selectionModel, setSelectionModel] = useState<any[]>([]);
  const { images, imagesLoaded, count, error, updateMessage } =
    useAppSelector(selectImagesState);
  const { imageClassificationsLoaded } = useAppSelector(
    selectImageClassificationState,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const changeFilters = useChangeFilters();
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const isMediumScreen = useBreakpoint("medium");
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState(false);
  const [
    isNotificationVisibleToChoseOneRow,
    setIsNotificationVisibleToChoseOneRow,
  ] = useState(false);

  useEffect(() => {
    if (!imageClassificationsLoaded) {
      dispatch(fetchImageClassificationsAsync());
    }
  }, [imageClassificationsLoaded, dispatch]);

  useEffect(() => {
    dispatch(fetchImagesAsync(searchParams));
  }, [dispatch, searchParams]);

  const handleChange = (event: any, nextView: any) => {
    setView(nextView);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const searchHandler = (value: string) => {
    dispatch(setImagesParams({ search: value, page: 0 }));
  };

  const getSortModel = (): GridSortModel => {
    if (!searchParams.has("ordering")) return [];
    switch (searchParams.get("ordering")) {
      case "title":
        return [{ field: "title", sort: "asc" }];
      case "-title":
        return [{ field: "title", sort: "desc" }];
      case "url":
        return [{ field: "image", sort: "asc" }];
      case "-url":
        return [{ field: "image", sort: "desc" }];
      default:
        return [];
    }
  };

  const sortModel: GridSortModel = getSortModel();

  const handleSortModelChange = (sortModel: GridSortModel) => {
    let orderingParam = sortModel[0] ? sortModel[0].field : "";
    orderingParam = orderingParam === "image" ? "url" : orderingParam;
    const sortingOrder = sortModel[0] && sortModel[0].sort;
    orderingParam =
      sortingOrder === "desc" ? "-" + orderingParam : orderingParam;
    changeFilters(new URLSearchParams([["ordering", orderingParam]]));
  };

  const deleteImageUrls = useCallback(
    async (idsArray: number[]) => {
      dispatch(deleteImageUrlsAsync({ ids: idsArray })).then(() => {
        dispatch(setUpdateMessage("Delete Successful."));
        setShowNotificationMessage(true);
      });
      dispatch(fetchImagesAsync(searchParams));
    },
    [dispatch, selectionModel, searchParams],
  );

  const handleSelection = (newSelectionModel: any) => {
    setSelectionModel(newSelectionModel);
  };

  const handleDeleteBtnClick = (rowIds: any[]) => {
    if (rowIds.length > 0) {
      setIsDeleteConfirmDialogOpen(true);
    } else {
      setIsNotificationVisibleToChoseOneRow(true);
    }
  };

  const onCancleClick = () => {
    setIsDeleteConfirmDialogOpen(false);
  };

  const onApproveClick = () => {
    deleteImageUrls(selectionModel);
    setIsDeleteConfirmDialogOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" onChange={searchHandler} />

        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <ToolboxArea>
            <ToolInfoTooltip title="Add Image URL">
              <ToolIconContainer onClick={() => setModalOpen(true)}>
                <AddCircleIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.primary.main,
                    stroke: theme.palette.primary.main,
                    strokeWidth: 1,
                  }}
                />
              </ToolIconContainer>
            </ToolInfoTooltip>

            <Divider orientation="vertical" variant="middle" flexItem />
            <ToolInfoTooltip title="Delete From List">
              <ToolIconContainer
                onClick={() => handleDeleteBtnClick(selectionModel)}
              >
                <DeleteForeverIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.error.main,
                  }}
                />
              </ToolIconContainer>
            </ToolInfoTooltip>
          </ToolboxArea>

          <ToggleButtonGroup
            value={view}
            size="medium"
            exclusive
            onChange={handleChange}
            sx={{ backgroundColor: theme.palette.background.paper }}
          >
            <ToggleButton value={"list"} aria-label="list">
              {view == "list" ? (
                <ViewListIcon
                  sx={{ fontSize: theme.typography.button.fontSize }}
                />
              ) : (
                <ViewModuleIcon />
              )}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>

      <Filter viewName="image_urls">
        <AvailableFilters />
      </Filter>

      <>
        {view == "list" ? (
          <GridContainer>
            <DataGridPro
              loading={!imagesLoaded}
              rows={images}
              columns={ColumnsForDataGrid()}
              rowSelectionModel={selectionModel}
              onRowSelectionModelChange={handleSelection}
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              pagination
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
              checkboxSelection
              disableColumnMenu
            />
          </GridContainer>
        ) : (
          <GridContainer>
            <GridViewLayout />
          </GridContainer>
        )}
      </>

      {/* Modal to add image url */}
      {modalOpen && (
        <MultiFileUploadComponent
          isOpen={modalOpen}
          handleClose={handleClose}
        />
      )}
      {/* Notification Snackbar */}
      {showNotificationMessage && (
        <SimpleSnackbar
          open={showNotificationMessage}
          message={updateMessage}
          toggleSnackbar={() => {
            dispatch(setUpdateMessage(""));
            setShowNotificationMessage(false);
          }}
          snackbarAction={null}
        />
      )}

      <Snackbar
        open={isNotificationVisibleToChoseOneRow}
        autoHideDuration={4000}
        onClose={() => setIsNotificationVisibleToChoseOneRow(false)}
      >
        <Alert variant="standard" severity="info">
          At least one row should be selected!
        </Alert>
      </Snackbar>

      <CommonConfirmDialog
        isOpen={isDeleteConfirmDialogOpen}
        content="Are you sure you want to delete the inventory location(s)?"
        affirmativeButtonText="Delete"
        negativeButtonText="Cancel"
        onApprove={onApproveClick}
        onCancel={onCancleClick}
      />
    </>
  );
};

export default ProcurementImages;
