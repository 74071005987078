import React, { useEffect, useCallback } from "react";
import useAppSelector from "hooks/useAppSelector";
import { selectAuthenticatedUser } from "store/auth";
import InfoBar from "./parts/Topbar";
import SupplierCharts from "./parts/SupplierCharts";
import CatalogCharts from "./parts/CatalogCharts";
import MySuppliers from "./parts/MySuppliers";
import MyProductCatalog from "./parts/MyProductCatalog";
import RecentActions from "./parts/RecentActions";
import useAppDispatch from "hooks/useAppDispatch";
import { fetchProductCount } from "store/catalog/thunks";
import { fetchSuppliers } from "store/suppliers/thunks";
import { FilterType } from "store/catalog/interfaces";
import AddNewSupplierButton from "components/AddNewSupplierButton";
import { Box, Divider, Link, Typography } from "@mui/material";
import theme from "theme/theme";
import useBreakpoint from "hooks/useBreakpoint";
import { IUser } from "store/auth/interfaces";
import SyncIcon from "@mui/icons-material/Sync";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolIconContainer from "components/common-components/ToolIconContainer";

function triggerSentryError() {
  throw new Error("This is a test error for Sentry.");
}

if (window.location.pathname === "/trigger-error") {
  triggerSentryError();
}

const OverviewPage = (): JSX.Element => {
  const user = useAppSelector(selectAuthenticatedUser) as IUser;
  const dispatch = useAppDispatch();
  const isLargesScreen = useBreakpoint("large");
  const isSmallScreen = useBreakpoint("small");

  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const fetchOnboardingData = useCallback(() => {
    dispatch(fetchSuppliers());
    ["edited", "unedited", "mapped", "new", "low-in-stock"].forEach((filter) =>
      dispatch(fetchProductCount(filter as FilterType)),
    );
  }, [dispatch]);

  useEffect(() => {
    fetchOnboardingData();
  }, [fetchOnboardingData]);

  return (
    <>
      <Typography
        sx={{
          fontSize: theme.typography.body2.fontSize,
          fontWeight: theme.typography.fontWeightMedium,
        }}
      >
        {new Date().toLocaleDateString(undefined, dateOptions)}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: theme.typography.h4.fontSize,
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          Welcome back {user.first_name}!
        </Typography>

        <Link href="/trigger-error" sx={{ fontSize: "30" }}>
          Test
        </Link>

        <ToolboxArea>
          <AddNewSupplierButton />
          <Divider orientation="vertical" variant="middle" flexItem />
          <ToolInfoTooltip title="Reload data">
            <ToolIconContainer>
              <SyncIcon
                onClick={fetchOnboardingData}
                fontSize="large"
                sx={{
                  color: theme.palette.primary.main,
                  stroke: theme.palette.primary.main,
                  strokeWidth: 2,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>
        </ToolboxArea>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: isLargesScreen ? "row" : "column",
          gap: 1,
          width: "100%",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, flex: 1 }}>
          <InfoBar />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: isSmallScreen ? "repeat(2, 1fr)" : "1fr",
              gap: 1,
            }}
          >
            <SupplierCharts />
            <CatalogCharts />
            <MySuppliers />
            <MyProductCatalog />
          </Box>
        </Box>
        <Box
          sx={{
            padding: "1rem",
            width: "100%",
            flex: 0.5,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <RecentActions />
        </Box>
      </Box>
    </>
  );
};

export default OverviewPage;
