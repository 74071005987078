import { FormHelperText } from "@mui/material";
import { createTheme, PaletteColorOptions } from "@mui/material/styles";
import colors from "./colors";
import fonts from "./fonts";
import breakpoints from "./breakpoints";

const theme = createTheme({
  palette: {
    bg: {
      main: colors.backgroundLogin,
    },
    dark: {
      main: colors.textPrimary,
    },
    subtle: {
      main: colors.textSecondary,
    },
    light: {
      main: colors.backgroundPaper,
    },
    primary: {
      main: colors.primaryMain,
      light: colors.primaryLight,
      dark: colors.primaryDark,
      contrastText: colors.primaryContrast,
    },
    secondary: {
      main: colors.secondaryMain,
      light: colors.secondaryLight,
      dark: colors.secondaryDark,
    },
    error: {
      main: colors.errorMain,
    },
    warning: {
      light: colors.warningLight,
      main: colors.warningMain,
      dark: colors.warningDark,
    },
    background: {
      default: colors.backgroundDefault,
      paper: colors.backgroundPaper,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
      disabled: colors.textDisabled,
    },
    action: {
      hover: colors.actionHover,
      selected: colors.actionHover, //CODE:N
    },
  },

  typography: {
    fontFamily: fonts.fontFamily,
    fontSize: 10,
    fontWeightLight: fonts.fontWeightLight,
    fontWeightRegular: fonts.fontWeightRegular,
    fontWeightMedium: fonts.fontWeightMedium,
    fontWeightBold: fonts.fontWeightBold,
    h1: {
      fontSize: fonts.fontSizeH1,
      fontWeight: fonts.fontWeightLight,
    },
    h2: {
      fontSize: fonts.fontSizeH2,
      fontWeight: fonts.fontWeightLight,
    },
    h3: {
      fontSize: fonts.fontSizeH3,
      fontWeight: fonts.fontWeightRegular,
    },
    h4: {
      fontSize: fonts.fontSizeH4,
      fontWeight: fonts.fontWeightRegular,
    },
    h5: {
      fontSize: fonts.fontSizeH5,
      fontWeight: fonts.fontWeightRegular,
    },
    h6: {
      fontSize: fonts.fontSizeH6,
      fontWeight: fonts.fontWeightMedium,
    },
    body1: {
      fontSize: fonts.fontSizeBody1,
      fontWeight: fonts.fontWeightLight,
    },
    body2: {
      fontSize: fonts.fontSizeBody2,
      color: colors.textSecondary,
    },
    button: {
      fontSize: fonts.button,
      fontWeight: fonts.fontWeightRegular,
    },
    subtitle1: {
      fontSize: fonts.fontSizeDefault,
      fontWeight: fonts.fontWeightLight,
      color: colors.textSecondary,
    },
    subtitle2: {
      fontSize: fonts.fontSubTitle2,
    },
    caption: {
      fontSize: fonts.caption,
    },
  },

  breakpoints: {
    values: {
      xs: breakpoints.xsmall,
      sm: breakpoints.small,
      md: breakpoints.medium,
      lg: breakpoints.large,
      xl: breakpoints.xlarge,
    },
  },

  spacing: 10, // Default spacing unit

  direction: "ltr",

  shape: {
    borderRadius: 2,
  },

  shadows: [
    "none",
    //Elevation #1
    "0px 2px 1px -1px rgba(0,0,0,.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    //Elevation #2
    "0px 3px 1px -2px rgba(0,0,0,.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    //Elevation #3
    "0px 3px 3px -2px rgba(0,0,0,.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
    //Elevation #4
    "0px 2px 4px -1px rgba(0,0,0,.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    //Elevation #5
    "0px 3px 5px -1px rgba(0,0,0,.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)",
    //Elevation #6
    "0px 3px 5px -1px rgba(0,0,0,.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    //Elevation #7
    "0px 4px 5px -2px rgba(0,0,0,.2), 0px 7px 10px 1px rgba(0,0,0,0.14), 0px 2px 16px 0px rgba(0,0,0,0.12)",
    //Elevation #8
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    //Elevation #9
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    //Elevation #10
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    //Elevation #11
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    //Elevation #12
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    //Elevation #13
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    //Elevation #14
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    //Elevation #15
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    //Elevation #16
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    //Elevation #17
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    //Elevation #18
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    //Elevation #19
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    //Elevation #20
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    //Elevation #21
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    //Elevation #22
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    //Elevation #23
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    //Elevation #24
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],

  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body, div, span, applet, object, iframe,
        h1, h2, h3, h4, h5, h6, p, blockquote, pre,
        a, abbr, acronym, address, big, cite, code,
        del, dfn, em, img, ins, kbd, q, s, samp,
        small, strike, strong, sub, sup, tt, var,
        b, u, i, center, dl, dt, dd, ol, ul, li,
        fieldset, form, label, legend, table, caption,
        tbody, tfoot, thead, tr, th, td, article, aside,
        canvas, details, embed, figure, figcaption, footer,
        header, hgroup, menu, nav, output, ruby, section,
        summary, time, mark, audio, video {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          font: inherit;
          vertical-align: baseline;
        }
        *, *::before, *::after {
        box-sizing: border-box;
        font-family: IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif;
        }
        html, body {
        font-size: 10px;
        }
        body {
        background-color: ${colors.backgroundDefault}; // Use your theme color variable
        width: 100%;
        overflow: hidden;
        }
        /* HTML5 display-role reset for older browsers */
        article, aside, details, figcaption, figure, footer,
        header, hgroup, menu, nav, section {
          display: block;
        }
        body {
          line-height: 1;
        }
        ol, ul {
          list-style: none;
        }
        blockquote, q {
          quotes: none;
        }
        blockquote:before, blockquote:after,
        q:before, q:after {
          content: '';
          content: none;
        }
        table {
          border-collapse: collapse;
          border-spacing: 0;
        }
        a {
          text-decoration: none;
        }
      `,
    },
    MuiAppBar: {
      styleOverrides: {
        root: { background: colors.backgroundPaper },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { fontSize: fonts.caption, fontFamily: fonts.fontFamily },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: { background: colors.backgroundPaper },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: "2rem",
          height: "2rem",
          "&:hover": {
            backgroundColor: "#eff3fd", // Hover effect for icons
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "0",
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            margin: "0", // Remove default spacing when expanded
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0 0.6rem",
          fontSize: "1.4rem",
          color: "#1c1b1f",
          "&:hover": {
            backgroundColor: "#eff3fd", // Hover color for accordion headers
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "0.8rem 0.6rem 0.8rem 1.6rem", // Adjust padding for accordion details
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "1.3rem",
          padding: "0.4rem",
          "&.Mui-selected": {
            borderLeft: "0.4rem solid #26479e", // Selected state border
            color: "#26479e",
            fontWeight: "bold",
          },
          "&:hover": {
            backgroundColor: "#eff3fd", // Hover color for menu items
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          padding: "1rem",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: "0",
          margin: "0 auto",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#e0e0e0", // Divider color
        },
      },
    },
    MuiGrid2: {
      styleOverrides: {
        root: {
          backgroundColor: "red",
          "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within":
            {
              outline: "none",
            },
          "& .MuiDataGrid-cellContent, .connected-supplier": {
            whiteSpace: "normal",
            fontSize: 12,
          },
          "& .MuiDataGrid-cell img": {
            height: "80px",
            aspectRatio: "1",
            objectFit: "cover",
          },

          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#e0e7e9",
            borderBottom: "none",
            color: "red",
            fontSize: "12px",
          },

          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },

          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            justifyContent: "flex-start",
            marginTop: "1rem",
            minHeight: 0,
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: colors.backgroundPaper,
          border: "unset",
          borderRadius: 2,
          "&.Mui-selected": {
            color: "#000000",
            backgroundColor: "#fefefe",
          },
          "&:hover": {
            color: "#000000",
            backgroundColor: "#fefefe",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.backgroundPaper,
          border: "unset",
          borderRadius: 2,
          "&.Mui-selected": {
            color: "#000000",
            backgroundColor: "#fefefe",
          },
          "&:hover": {
            color: "#000000",
            backgroundColor: "#fefefe",
          },
        },
      },
    },
  },
});

export default theme;

declare module "@mui/material/styles" {
  interface Palette {
    bg: PaletteColor;
    dark: PaletteColor;
    subtle: PaletteColor;
    light: PaletteColor;
  }

  interface PaletteColor {
    50?: string;
    80?: string;
    95?: string;
  }

  interface PaletteOptions {
    bg: PaletteColorOptions;
    dark: PaletteColorOptions;
    subtle: PaletteColorOptions;
    light: PaletteColorOptions;
  }

  interface SimplePaletteColorOptions {
    50?: string;
    80?: string;
    95?: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    dark: true;
    subtle: true;
  }
}

declare module "@mui/material/Icon" {
  interface IconPropsColorOverrides {
    subtle: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    subtle: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    dark: true;
  }
}
