import { createAsyncThunk } from "@reduxjs/toolkit";

import auth from "services/auth";
import { IUserAuthDTO } from "./interfaces";
import token from "services/auth/tokenService";

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }: IUserAuthDTO, { dispatch }) => {
    const result = await auth.service().login(email, password);

    const accessToken = result.access;
    const refreshToken = result.refresh;

    token.service().setAccessToken(accessToken);
    token.service().setRefreshToken(refreshToken);

    await dispatch(fetchUserData());
    return result;
  },
);

export const refreshTokens = createAsyncThunk(
  "auth/refreshTokens",
  async (refreshToken: string) => {
    return await auth.service().refreshTokens(refreshToken);
  },
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (email: string) => {
    return await auth.service().resetPassword(email);
  },
);

export const newPassword = createAsyncThunk(
  "auth/newPassword",
  async ({ token, password }: { token: string; password: string }) => {
    return await auth.service().newPassword(token, password);
  },
);

export const fetchUserData = createAsyncThunk(
  "auth/fetchUserData",
  async () => {
    return await auth.service().fetchUserData();
  },
);
