import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Box, Button, IconButton } from "@mui/material";
import theme from "theme/theme";

interface Props {
  title: any;
  children: React.ReactNode;
}

const ToolInfoTooltip = ({ title, children }: Props) => {
  return (
    <Tooltip
      sx={{
        backgroundColor: "transparent",
        "&:hover": { backgroundColor: "transparent" },
        "&:focus": { backgroundColor: "transparent" },
      }}
      title={
        <Box
          sx={{
            fontSize: theme.typography.subtitle1.fontSize,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            padding: theme.spacing(0.2),
          }}
        >
          {title}
        </Box>
      }
      arrow
    >
      <Box>{children}</Box>
    </Tooltip>
  );
};

export default ToolInfoTooltip;
