import { createAsyncThunk } from "@reduxjs/toolkit";
import images from "services/catalog-images";
import { RootState } from "../index";
import { IImagesFilterParams } from "./interfaces";

function getAxiosParams(catalogImagesParams: IImagesFilterParams) {
  const params = new URLSearchParams();
  params.append(
    "offset",
    (catalogImagesParams.page * catalogImagesParams.limit).toString(),
  );
  params.append("limit", catalogImagesParams.limit.toString());
  params.append("search", catalogImagesParams.title.toString());
  catalogImagesParams.size &&
    params.append("image_kb__gte", (catalogImagesParams.size - 200).toString());
  catalogImagesParams.size &&
    catalogImagesParams.size <= 1000 &&
    params.append("image_kb__lte", catalogImagesParams.size.toString());
  catalogImagesParams.width &&
    params.append(
      "image_width__gte",
      (catalogImagesParams.width - 500).toString(),
    );
  catalogImagesParams.width &&
    catalogImagesParams.width <= 2500 &&
    params.append("image_width__lte", catalogImagesParams.width.toString());
  catalogImagesParams.height &&
    params.append(
      "image_height__gte",
      (catalogImagesParams.height - 500).toString(),
    );
  catalogImagesParams.height &&
    catalogImagesParams.height <= 2500 &&
    params.append("image_height__lte", catalogImagesParams.height.toString());
  catalogImagesParams.classification.id !== null &&
    params.append(
      "classification",
      catalogImagesParams.classification.id.toString(),
    );
  params.append("search", catalogImagesParams.search.toString());

  return params;
}

export const fetchImagesAsync = createAsyncThunk(
  "images/fetchImages",
  async (params: URLSearchParams) => {
    return await images.service().fetchImages(params);
  },
);

export const fetchProductImagesAsync = createAsyncThunk(
  "images/fetchProductImages",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await images.service().fetchProductImages();
  },
);

export const fetchCategoryImagesAsync = createAsyncThunk(
  "images/fetchCategoryImages",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await images.service().fetchCategoryImages();
  },
);

export const uploadImageAsync = createAsyncThunk(
  "images/uploadImage",
  async (data: FormData, thunkAPI) => {
    return await images.service().uploadImage(data);
  },
);
