import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Theme } from "@mui/material";

export interface Data {
  order_number: string;
  Availability: string;
  Backorder: string;
  "Shipping Method": string;
  Status: string;
}

export type Order = "asc" | "desc";

interface HeadCell {
  id: keyof Data;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: "order_number",
    label: "# Order",
  },
  {
    id: "Status",
    label: "Status",
  },
  {
    id: "Shipping Method",
    label: "Shipping Method",
  },
  {
    id: "Availability",
    label: "Availability",
  },
  {
    id: "Backorder",
    label: "Backorder",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  order: Order;
  orderBy: string;
}

function CustomTableHead(props: EnhancedTableProps): JSX.Element {
  const { order, orderBy, onRequestSort } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              backgroundColor: (theme: Theme) => theme.palette.bg.main,
              borderBottom: "none",
              color: "#6D6D6D",
              fontSize: 13,
            }}
          >
            {headCell.id === "order_number" ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={(event) => onRequestSort(event, headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default CustomTableHead;
