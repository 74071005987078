import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";

// Dialog when the product update request is sent and response is awaited
const CircularWaitDialog = ({ open }: { open: boolean }): JSX.Element => {
  return (
    <Dialog open={open}>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export default CircularWaitDialog;
