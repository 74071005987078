import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactNode } from "react";
import { SxProps, Theme } from "@mui/material";

interface IFilterAccordionProps {
  label: string;
  children: ReactNode;
  onChange?(): void;
}

const FilterAccordion = (props: IFilterAccordionProps): JSX.Element => {
  const style: SxProps<Theme> = {
    marginRight: "1.5rem",
    marginLeft: "1.5rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    boxShadow: "none",
  };

  return (
    <Accordion disableGutters sx={style} {...props}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: 0 }}>
        <Typography>{props.label}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>{props.children}</AccordionDetails>
    </Accordion>
  );
};

export default FilterAccordion;
