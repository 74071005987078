import styled from "@emotion/styled";

export const DatePickerWrapper = styled.div`
  label:not([data-shrink="true"]) {
    top: -0.5rem;
  }

  input {
    padding: 0.5rem 0;
  }
`;
