import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import {
  IAddProcurementSupplyListParams,
  IProcurementSupplierDTO,
  ISupplierInsertResult,
  ISupplierList,
  IUpdateProcurementSupplyListParams,
} from "store/procurement-supplierList/interfaces";
import { Mockable } from "../index";
import { endpoint } from "constants/endpoint";

export interface ISupplierListService {
  fetchProcurementSupplierList(
    searchParams: URLSearchParams,
  ): Promise<IProcurementSupplierDTO>;
  fetchProcurementSupplierListById(id: number): Promise<ISupplierList>;
  AddProcurementSupplierList(
    params: IAddProcurementSupplyListParams,
  ): Promise<ISupplierList>;
  AddProcurementSupplierNew(
    params: IAddProcurementSupplyListParams,
  ): Promise<ISupplierInsertResult>;
  UpdateProcurementSupplierList(
    id: number,
    params: IUpdateProcurementSupplyListParams,
  ): Promise<ISupplierList>;
  deleteProcurementSupplierList(id: number): Promise<number>;
}
// gets the list of procurement suppliers whose name contains the searchText, or all suppliers if the searchText is an empty string ("")
export default new Mockable<ISupplierListService>({
  async fetchProcurementSupplierList(
    searchParams: URLSearchParams,
  ): Promise<IProcurementSupplierDTO> {
    const res = await axios.get(
      `${config.api}${endpoint.procurement_suppliers}`,
      {
        params: searchParams,
        headers: commonHeaders(),
      },
    );

    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async fetchProcurementSupplierListById(id): Promise<ISupplierList> {
    const res = await axios(
      `${config.api}${endpoint.procurement_suppliers}${id}/`,
      {
        method: "GET",
        headers: commonHeaders(),
      },
    );
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async AddProcurementSupplierList(params): Promise<ISupplierList> {
    const res = await axios(`${config.api}${endpoint.procurement_suppliers}`, {
      method: "POST",
      headers: commonHeaders(),
      data: JSON.stringify(params),
    });
    if (res.status === 201) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async AddProcurementSupplierNew(params): Promise<ISupplierInsertResult> {
    let result: ISupplierInsertResult = {
      message: "Something went wrong",
      statusCode: 400,
    };

    await axios(`${config.api}${endpoint.procurement_suppliers}`, {
      method: "POST",
      headers: commonHeaders(),
      data: JSON.stringify(params),
    })
      .then((response) => {
        if (response.status === 201) {
          result = {
            statusCode: response.status,
            message: "Add successfully.",
          };
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          const jsonString = JSON.stringify(error.response.data, null, 4);
          const jsonStringWithNewlines = jsonString
            ?.replace(/\{/g, "")
            .replace(/\}/g, "")
            .replace(/\[/g, "")
            .replace(/\]/g, "")
            .replace(/\"/g, "")
            .replace(/\"/g, "")
            .trim();
          result = {
            message: jsonStringWithNewlines,
            statusCode: error.response.status,
          };
        }
      });

    return result;
  },

  async UpdateProcurementSupplierList(
    id: number,
    params: IUpdateProcurementSupplyListParams,
  ): Promise<ISupplierList> {
    const res = await axios(
      `${config.api}${endpoint.procurement_suppliers}${id}/`,
      {
        method: "PUT",
        headers: commonHeaders(),
        data: JSON.stringify(params),
      },
    );
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async deleteProcurementSupplierList(id): Promise<number> {
    const res = await axios(
      `${config.api}${endpoint.procurement_suppliers}${id}/`,
      {
        method: "DELETE",
        headers: commonHeaders(),
      },
    );
    if (res.status === 204) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },
});
