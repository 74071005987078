import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import {
  IProcurementCompanies,
  ICompanyList,
  IUpdateProcurementCompaniesListParams,
} from "store/procurements-companies/interfaces";
import { Mockable } from "../index";
import { endpoint } from "constants/endpoint";

export interface ICompaniesListService {
  fetchProcurementCompaniesList(
    searchText: string,
  ): Promise<IProcurementCompanies>;
  fetchProcurementcompaniesListById(id: number): Promise<number>;
  UpdateProcurementCompaniesList(
    id: number,
    params: IUpdateProcurementCompaniesListParams,
  ): Promise<ICompanyList>;
}

export default new Mockable<ICompaniesListService>({
  async fetchProcurementCompaniesList(
    searchText: string,
  ): Promise<IProcurementCompanies> {
    const res = await axios(
      `${config.api}${endpoint.users_companies}?search=${searchText}`,
      {
        headers: commonHeaders(),
      },
    );
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async fetchProcurementcompaniesListById(id): Promise<number> {
    const res = await axios(`${config.api}${endpoint.users_companies}${id}/`, {
      method: "GET",
      headers: commonHeaders(),
    });
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async UpdateProcurementCompaniesList(
    id: number,
    params: IUpdateProcurementCompaniesListParams,
  ): Promise<ICompanyList> {
    const res = await axios(`${config.api}${endpoint.users_companies}${id}/`, {
      method: "PUT",
      headers: commonHeaders(),
      data: JSON.stringify(params),
    });
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },
});
