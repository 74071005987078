import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product, ProductFilters } from "./interfaces";
import { fetchBulkDescriptionProducts } from "./thunk";
import { RootState } from "..";

interface ProductFiltersState {
  filters: ProductFilters;
  products: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  count: number;
  next: string;
  previous: string;
  updateMessage: string;
  loading: boolean;
  cellValue?: object;
  showNotification?: boolean;
  rowValue?: Product;
}

const initialState: ProductFiltersState = {
  loading: true,
  filters: {},
  products: [],
  status: "idle",
  error: null,
  count: 0,
  next: "",
  previous: "",
  updateMessage: "",
  cellValue: {},
  showNotification: false,
  rowValue: null,
};

const productFiltersSlice = createSlice({
  name: "productsDescriptionBulk",
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<ProductFilters>) {
      state.filters = action.payload;
    },
    setUpdateMessage: (state, action) => {
      state.updateMessage = action.payload;
    },
    clearFilters(state) {
      state.updateMessage = "";
    },
    setCellValue: (state, action) => {
      state.cellValue = action.payload;
    },
    setShowNotification: (state, action) => {
      state.showNotification = action.payload;
    },
    setProductValues: (state, action) => {
      state.rowValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBulkDescriptionProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload.results;
        state.count = action.payload.count;
        state.next = action.payload.next;
        state.previous = action.payload.previous;
        state.loading = false;
      })
      .addCase(fetchBulkDescriptionProducts.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchBulkDescriptionProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const selectCatalogBulkDescriptionState = (
  state: RootState,
): ProductFiltersState => state.productFilters;

export const {
  setFilters,
  clearFilters,
  setUpdateMessage,
  setCellValue,
  setShowNotification,
  setProductValues,
} = productFiltersSlice.actions;

export const selectUpdateMessage = (state: RootState): string =>
  state.productFilters.updateMessage;

export const selectCellValue = (state: RootState): object =>
  state.productFilters.cellValue;

export const selectShowNotification = (state: RootState): boolean =>
  state.productFilters.showNotification;

export const selectProductValues = (state: RootState): Product =>
  state.productFilters.rowValue;

export default productFiltersSlice.reducer;
