import React from "react";
import { Box } from "@mui/material";
import useAppSelector from "hooks/useAppSelector";
import {
  selectCatalogMarkups,
  setCatalogMarkupParams,
} from "store/catalog-markup";
import useAppDispatch from "hooks/useAppDispatch";
import { fetchCatalogMarkups } from "store/catalog-markup/thunks";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ColumnsForDataGrid from "./parts/ColomnsForDataGrid";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Searchbar from "components/filter-products/Searchbar";
import useBreakpoint from "hooks/useBreakpoint";
import GridContainer from "components/common-components/GridContainer";
import { DataGridPro } from "@mui/x-data-grid-pro";
import theme from "theme/theme";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";

const Markup = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { markups, markupCategoriesLoaded, count } =
    useAppSelector(selectCatalogMarkups);
  const [searchParams] = useSearchParams();
  const isMediumScreen = useBreakpoint("medium");

  useEffect(() => {
    !markupCategoriesLoaded && dispatch(fetchCatalogMarkups());
  }, [dispatch, markupCategoriesLoaded, searchParams]);

  const searchHandler = (value: string) => {
    dispatch(setCatalogMarkupParams({ search: value, page: 0 }));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" onChange={searchHandler} />
        <ToolboxArea>
          <ToolInfoTooltip title="Add Markup (coming soon)">
            <ToolIconContainer disabled>
              <AddCircleIcon
                fontSize="large"
                sx={{
                  color: theme.palette.text.disabled,
                  stroke: theme.palette.text.disabled,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>
        </ToolboxArea>
      </Box>
      <GridContainer>
        <DataGridPro
          loading={!markupCategoriesLoaded}
          rows={markups}
          columns={ColumnsForDataGrid()}
          pagination
          disableColumnMenu
          rowHeight={90}
          columnHeaderHeight={40}
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
        />
      </GridContainer>
    </>
  );
};
export default Markup;
