import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { ISnackbarSliceState } from "./interfaces";

const initialState: ISnackbarSliceState = {
  action: null,
  message: "",
  open: false,
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    displaySnackbar: (state, action: PayloadAction<ISnackbarSliceState>) => {
      state.action = action.payload.action || null;
      state.message = action.payload.message;
      state.open = true;
    },
    clearSnackbar: (state) => {
      state.open = false;
    },
  },
});

export const selectIsSnackbarOpen = (state: RootState): boolean =>
  state.snackbar.open;
export const selectSnackbarMessage = (state: RootState): string =>
  state.snackbar.message;
export const selectSnackbarAction = (state: RootState): JSX.Element | null =>
  state.snackbar.action;

export const { displaySnackbar, clearSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
