// /* eslint-disable no-console */
import React, { useState } from "react";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  FormControl,
  FormGroup,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import theme from "theme/theme";
import useAppDispatch from "hooks/useAppDispatch";
import {
  fetchFileUrlsListAsync,
  UpdateFileUrls,
} from "store/catalog-file-urls/thunks";
import { useSelector } from "react-redux";
import { selectUpdateMessage, setUpdateMessage } from "store/catalog-file-urls";
import SimpleSnackbar from "components/SimpleSnackbar";

interface ModalContainerUpdateProps {
  id?: number;
  url: string;
  sourceUrl: string;
  priority: number;
  title: string;
  removedFromSource: boolean;
  account: number;
  company: number;
  product: number;

  setUrl: Dispatch<SetStateAction<string>>;
  setSourceUrl: Dispatch<SetStateAction<string>>;
  setPriority: Dispatch<SetStateAction<number>>;
  setTitle: Dispatch<SetStateAction<string>>;
  setRemovedFromSource: Dispatch<SetStateAction<boolean>>;
  setAccount: Dispatch<SetStateAction<number>>;
  setCompany: Dispatch<SetStateAction<number>>;
  setProduct: Dispatch<SetStateAction<number>>;
}

interface Props {
  closeModal: () => void;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
}

export type EditFileUrlModalProps = Props & ModalContainerUpdateProps;

const EditFileUrlModal = ({
  id,
  url,
  sourceUrl,
  priority,
  title,
  removedFromSource,
  product,
  closeModal,
  setProduct,
  setUrl,
  setSourceUrl,
  setPriority,
  setTitle,
  setRemovedFromSource,
  setShowNotification,
}: EditFileUrlModalProps) => {
  const dispatch = useAppDispatch();
  const updateMessage = useSelector(selectUpdateMessage);

  const handleSaveTheChanges = () => {
    const editedFileUrlData = {
      id: id,
      url: url,
      source_url: sourceUrl,
      priority: Number(priority),
      title: title,
      removed_from_source: removedFromSource,
      account: null,
      company: null,
      product: product,
    };
    dispatch(UpdateFileUrls({ params: editedFileUrlData }));
    closeModal();
    dispatch(fetchFileUrlsListAsync(new URLSearchParams()));
    dispatch(setUpdateMessage("Changes have been saved!"));
    setShowNotification(true);
  };

  return (
    <FormControl component="form" fullWidth>
      <FormGroup sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <TextField
          fullWidth
          id="product"
          label="Product"
          variant="outlined"
          type="number"
          value={product ?? ""}
          onChange={(e) => setProduct(Number(e.target.value))}
        />
        <TextField
          fullWidth
          id="url"
          label="URL (Ecombooster download area)"
          variant="outlined"
          value={url ?? ""}
          onChange={(e) => setUrl(e.target.value ?? "")}
        />
        <TextField
          fullWidth
          id="source-url"
          label="URL (Supplier's source URL)"
          variant="outlined"
          value={sourceUrl ?? ""}
          onChange={(e) => setSourceUrl(e.target.value ?? "")}
        />
        <TextField
          fullWidth
          id="priority"
          label="Priority"
          type="number"
          variant="outlined"
          value={priority ?? ""}
          onChange={(e) => setPriority(Number(e.target.value ?? ""))}
          helperText="Low number has highest priority... E.g. Main image priority=1, secondary images priority=2"
        />
        <TextField
          fullWidth
          id="title"
          label="Title of file"
          variant="outlined"
          value={title ?? ""}
          onChange={(e) => setTitle(e.target.value ?? "")}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={removedFromSource ?? false}
              onChange={(e) => setRemovedFromSource(e.target.checked)}
            />
          }
          label="Removed from source"
        />
        <Box
          sx={{
            fontSize: theme.typography.caption.fontSize,
            fontFamily: theme.typography.fontFamily,
          }}
        >
          If this is True, it seems like the file is no longer available on the
          source URL...
        </Box>
        <Button
          variant="contained"
          sx={{
            width: "fit-content",
            display: "flex",
            alignSelf: "flex-end",
          }}
          onClick={handleSaveTheChanges}
        >
          Save the changes
        </Button>

        <p>{updateMessage}</p>
      </FormGroup>
    </FormControl>
  );
};

export default EditFileUrlModal;
