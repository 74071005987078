import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { IProcurementSupplierFeedsResponseDTO } from "store/procurement-feeds/interface";
import { endpoint } from "constants/endpoint";
import { Mockable } from "../index";

export interface IProcurementFeedsService {
  fetchProcurementSupplierFeeds(
    params?: URLSearchParams,
  ): Promise<IProcurementSupplierFeedsResponseDTO>;
  fetchProcurementSupplierFeedDetails(id: string): Promise<any>;
}

export default new Mockable<IProcurementFeedsService>({
  async fetchProcurementSupplierFeeds(
    params,
  ): Promise<IProcurementSupplierFeedsResponseDTO> {
    try {
      const res = await axios(`${config.api}${endpoint.procurement_feed}`, {
        params,
        headers: commonHeaders(),
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in fetchProcurementSupplierFeeds",
          ),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },

  async fetchProcurementSupplierFeedDetails(id: string): Promise<any> {
    try {
      const res = await axios(
        `${config.api}${endpoint.procurement_feed}${id}`,
        {
          headers: commonHeaders(),
        },
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error("async fetchProcurementSupplierFeedDetails"),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
});
