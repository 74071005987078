interface IServiceModule<T> {
  mock(replacement: T): void;
  service(): T;
}

export class Mockable<T> implements IServiceModule<T> {
  [x: string]: any;
  constructor(private baseService: T) {}

  mock(replacement: T): void {
    this.baseService = replacement;
  }

  service(): T {
    return this.baseService;
  }
}
