import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { IFileUrlsList } from "./interfaces";
import { fetchFileUrlsListAsync } from "./thunks";

export const initialState: IFileUrlsList = {
  fileUrlsLoading: false,
  count: 0,
  next: "",
  previous: "",
  results: [],
  updateMessage: "",
  id: 0,
  url: "",
  source_url: "",
  priority: 0,
  title: "",
  removed_from_source: false,
  account: 0,
  company: 0,
  product: 0,
  params: {
    page: 0,
    limit: 0,
    search: "",
    filter: "True",
  },
  products: [],
};

export const ProductCatalogFileUrlsListSlice = createSlice({
  name: "ProductCatalogFileUrlsList",
  initialState,
  reducers: {
    setUpdateMessage: (state, action) => {
      state.updateMessage = action.payload;
    },
    setParams: (state, action) => {
      state.params = { ...state.params, ...action.payload };
    },
    resetAllParams: (state) => {
      state.params = {
        page: 1,
        limit: 25,
        search: "",
        filter: "True",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFileUrlsListAsync.fulfilled, (state, action) => {
      state.fileUrlsLoading = false;
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results;
      state.url = action.payload.url;
      state.source_url = action.payload.source_url;
    });
    // builder.addCase(fetchProcurementProducts.fulfilled, (state, action) => {
    //   state.products = action.payload.results;
    // });
    builder.addCase(fetchFileUrlsListAsync.pending, (state) => {
      state.fileUrlsLoading = true;
    });

    builder.addCase(fetchFileUrlsListAsync.rejected, (state) => {
      state.fileUrlsLoading = false;
    });
  },
});

export const selectFileUrlsListState = (state: RootState): IFileUrlsList =>
  state.ProductCatalogFileUrlsList;

export const { setUpdateMessage, setParams, resetAllParams } =
  ProductCatalogFileUrlsListSlice.actions;

export const selectUpdateMessage = (state: RootState): string =>
  state.ProductCatalogFileUrlsList.updateMessage;

export default ProductCatalogFileUrlsListSlice.reducer;
