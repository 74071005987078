import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import config from "../../config";
import { RootState } from "../index";
import {
  ICatalogProductDescription,
  ICatalogState,
  IUpdateProductsDTO,
} from "./interfaces";

import {
  fetchCatalogProducts,
  fetchCatalogProductsWithIds,
  fetchProductCount,
  fetchProductDescription,
  publishCatalogProducts,
  fetchCatalogProductsDescription,
  fetchProductTypes,
} from "./thunks";
import { httpErrorHandler } from "utils/error-response";
import { catalogColumnsArr } from "./utils/catalogColumns";
import { GridColDef } from "@mui/x-data-grid";
import ColumnsForStocks from "pages/main-page/catalog-page/parts/ColumnsForDataGrid";
import ColumnsForPrices from "pages/main-page/catalog-page/parts/ColumnsForPrices";
import ColumnsForBulkEdit from "pages/main-page/catalog-page/parts/ColumnsForBulkEdit";

export type ViewType = "default" | "stocks" | "prices" | "bulk edit";

enum Source {
  ChatGPT = "chatgpt",
  OrgSupplier = "org_supplier",
  OwnContent = "own_content",
}

export const defaultDescription: ICatalogProductDescription = {
  id: 0,
  content: "",
  meta_description: "",
  meta_keywords: "",
  meta_title: "",
  language: "SV",
  source: Source.ChatGPT || Source.OrgSupplier || Source.OwnContent,
  account: 0,
  company: 0,
  product: 0,
  excerpt: "",
  created_at: "",
  updated_at: "",
  machine_translation: false,
  name: "",
  updateMessage: "",
};

const prettyPrintProductType = (name: string): string => {
  if (name === "variable") {
    name = "variable_parent";
  }
  return (name.charAt(0).toUpperCase() + name.slice(1)).replace(/_/g, " ");
};

const initialState: ICatalogState = {
  // dashboard
  editedProductsCount: 0,
  uneditedProductsCount: 0,
  mappedProductsCount: 0,
  newProductsCount: 0,
  lowInStockProductsCount: 0,

  // product-list
  columns: catalogColumnsArr,
  loading: true,
  catalogProducts: [],
  publishAction: "",
  exportUrls: [],
  selectedProducts: [],
  openExportDialog: false,
  error: {},
  view: "default",
  productDescription: [],
  productTypes: new Map<"", "">(),
  catalogProductsDescription: [],
  catalogCategoriesLoaded: true,
  selectedRow: {},
  filterParams: {
    page: 0,
    limit: 25,
  },
  updateMessage: "",
};

const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setCatalogParams: (state, action) => {
      state.catalogCategoriesLoaded = false;
      state.filterParams = { ...state.filterParams, ...action.payload };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setOpenExportDialog: (state, action: PayloadAction<boolean>) => {
      state.openExportDialog = action.payload;
    },
    setSelectedProducts: (state, action: PayloadAction<number[]>) => {
      state.selectedProducts = action.payload;
    },
    setProductDescription: (state, action: PayloadAction<[]>) => {
      state.productDescription = action.payload;
    },
    setCatatogProductsDescription: (state, action: PayloadAction<[]>) => {
      state.catalogProductsDescription = action.payload;
    },
    setPublishAction: (state, action: PayloadAction<string>) => {
      state.publishAction = action.payload;
    },
    removeProducts: (state, action: PayloadAction<number[]>) => {
      state.catalogProducts = state.catalogProducts.filter(
        (product) => !action.payload.includes(product.id),
      );
    },
    setUpdateMessage: (state, { payload }) => {
      state.updateMessage = payload;
    },
    modifyProducts: (state, action: PayloadAction<IUpdateProductsDTO>) => {
      state.catalogProducts
        .filter((product) =>
          action.payload.products
            ?.map((update) => update.id)
            .includes(product.id),
        )
        .forEach((product) => {
          const update = action.payload.products.find(
            (update) => update.id === product.id,
          );
          if (update) {
            product.sku = update.sku;
            product.name = update.name;
            product.cost_shipping = update.cost_shipping;
            product.price_manual_override = update.price_manual_override;
            product.price_on_sale = update.price_on_sale;
            product.description = {
              id: product.description.id,
              account: product.description.account_id,
              company: product.description.company_id,
              product: product.description.product_id,
              content: update.descriptions[0].content,
              meta_description: update.descriptions[0].meta_description,
              meta_keywords: update.descriptions[0].meta_keywords,
              meta_title: update.descriptions[0].meta_title,
              language: update.descriptions[0].language,
              source: update.descriptions[0].source,
              excerpt: product.description.excerpt,
              machine_translation: product.description.machine_translation,
              name: product.description.name,
            };
          }
          if (update && update.packaging_width)
            product.packaging_width = update.packaging_width;
          if (update && update.packaging_length)
            product.packaging_length = update.packaging_length;
          if (update && update.packaging_height)
            product.packaging_height = update.packaging_height;
          if (update && update.packaging_weight)
            product.packaging_weight = update.packaging_weight;
          if (update && update.default_location_id)
            product.default_location_id = update.default_location_id;
          if (update && update.qty_restock_level)
            product.qty_restock_level = update.qty_restock_level;
          if (update && update.qty_restock_amount)
            product.qty_restock_amount = update.qty_restock_amount;
          if (update && update.cost_packaging_labour)
            product.cost_packaging_labour =
              update.cost_packaging_labour.toString();
          if (update && update.cost_packaging_material)
            product.cost_packaging_material =
              update.cost_packaging_material.toString();
        });
    },

    setViewOption: (state: ICatalogState, action: PayloadAction<ViewType>) => {
      state.view = action.payload;
      switch (action.payload) {
        case "default":
          state.columns = catalogColumnsArr;
          break;
        case "stocks":
          state.columns = ColumnsForStocks();
          break;
        case "prices":
          state.columns = ColumnsForPrices();
          break;
        case "bulk edit":
          state.columns = ColumnsForBulkEdit();
          break;
      }
    },
    setSelectedRow: (state, action: PayloadAction<any>) => {
      state.selectedRow = action.payload;
    },
    setColumnOptions: (
      state: ICatalogState,
      action: PayloadAction<GridColDef[]>,
    ) => {
      state.columns = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductCount.fulfilled, (state, action) => {
        switch (action.payload.type) {
          case "edited":
            state.editedProductsCount = action.payload.products;
            break;
          case "unedited":
            state.uneditedProductsCount = action.payload.products;
            break;
          case "mapped":
            state.mappedProductsCount = action.payload.products;
            break;
          case "new":
            state.newProductsCount = action.payload.products;
            break;
          case "low-in-stock":
            state.lowInStockProductsCount = action.payload.products;
            break;
        }
      })
      .addCase(fetchCatalogProducts.fulfilled, (state, action) => {
        state.error = {};
        state.count = action.payload.count;
        state.nextPageUrl = action.payload.next;
        state.previousPageUrl = action.payload.previous;
        state.catalogProducts = action.payload.results?.map(
          (product) => ({
            ...product,
            qty_in_stock: product.qty_in_stock.replace(".00", ""),
          }),
          state.catalogProducts,
        );
      })
      .addCase(fetchCatalogProductsDescription.fulfilled, (state, action) => {
        state.catalogProductsDescription = action.payload.results;
      })
      .addCase(fetchProductDescription.fulfilled, (state, action) => {
        const getUniqueRows = (data: ICatalogProductDescription) => {
          const uniqueRows: any = [];
          const uniqueIds = new Set();

          data.forEach((item: ICatalogProductDescription) => {
            if (!uniqueIds.has(item.product)) {
              uniqueIds.add(item.product);
              uniqueRows.push(item);
            }
          });

          return uniqueRows;
        };

        const uniqueRows = getUniqueRows(action.payload.results);

        state.productDescription = uniqueRows;
        state.productDescriptionCount = uniqueRows.length;
      })
      .addCase(fetchCatalogProducts.rejected, (state, { error }) => {
        state.error = {
          ...httpErrorHandler(error),
          url: config.api + "/catalog/products/",
        };
      })
      .addCase(fetchCatalogProductsWithIds.fulfilled, (state, action) => {
        state.catalogProducts = action.payload.results?.map((product) => ({
          ...product,
          description: product.description
            ? product.description
            : defaultDescription,
        }));
      })
      .addCase(fetchCatalogProductsWithIds.rejected, (state, { error }) => {
        state.error = {
          ...httpErrorHandler(error),
          url: config.api + "/catalog/products/?ids=",
        };
      })
      .addCase(publishCatalogProducts.fulfilled, (state, action) => {
        state.exportUrls = action.payload.export;
      })
      .addCase(fetchProductTypes.fulfilled, (state, action) => {
        state.productTypes = new Map(
          action.payload?.map((productType) => [
            productType.name,
            prettyPrintProductType(productType.name),
          ]),
        );
      });
  },
});
export const selectCatalogState = (state: RootState): ICatalogState =>
  state.catalog;
export const {
  setLoading,
  setSelectedProducts,
  setPublishAction,
  setOpenExportDialog,
  removeProducts,
  modifyProducts,
  setViewOption,
  setColumnOptions,
  setCatalogParams,
  setSelectedRow,
  setUpdateMessage,
} = catalogSlice.actions;

export const selectEditedProductsCount = (state: RootState): number =>
  state.catalog.editedProductsCount;
export const selectUneditedProductsCount = (state: RootState): number =>
  state.catalog.uneditedProductsCount;
export const selectMappedProductsCount = (state: RootState): number =>
  state.catalog?.mappedProductsCount;
export const selectNewProductsCount = (state: RootState): number =>
  state.catalog.newProductsCount;
export const selectLowInStockProductsCount = (state: RootState): number =>
  state.catalog.lowInStockProductsCount;
export const selectCatalogProductTypes = (
  state: RootState,
): Map<string, string> => state.catalog.productTypes;

export default catalogSlice.reducer;
