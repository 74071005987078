import React from "react";
import { MenuItem, IconButton, Box } from "@mui/material";
import { Link } from "react-router-dom";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../../../store/sidebar/store";
import {
  setActiveItem,
  togglePinnedItem,
} from "../../../../../store/sidebar/sidebarSlice";

const SidebarItem = ({ item }: { item: any }) => {
  const dispatch = useDispatch<AppDispatch>();
  const activeItem = useSelector(
    (state: RootState) => state.sidebar.activeItem,
  );
  const pinnedItems = useSelector(
    (state: RootState) => state.sidebar.pinnedItems,
  );

  return (
    <Box
      key={item.key}
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 0 0 1rem",
      }}
    >
      <Link
        to={item.link}
        style={{
          textDecoration: "none",
          color: "inherit",
          width: "100%",
          padding: "0.2rem",
          pointerEvents: item.disabled ? "none" : "auto",
        }}
        onClick={() => {
          if (!item.disabled) dispatch(setActiveItem(item.itemName));
        }}
      >
        <MenuItem
          sx={{
            opacity: item.disabled ? 0.5 : 1,
            cursor: item.disabled ? "default" : "pointer",
            borderLeft:
              activeItem === item.key ? "0.4rem solid #26479e" : "none",
            color: activeItem === item.key ? "#26479e" : "inherit",
            fontWeight: activeItem === item.key ? "bold" : "normal",
            fontSize: "1.3rem",
            padding: "0.4rem",
            pointerEvents: item.disabled ? "none" : "auto",
            ":focus": {
              borderLeft: "0.4rem solid #26479e",
              color: "#26479e",
              fontWeight: "bold",
            },
          }}
        >
          {item.itemName}
        </MenuItem>
      </Link>
      {!item.disabled && (
        <IconButton
          onClick={() => dispatch(togglePinnedItem(item.key))}
          sx={{ width: "2rem", height: "2rem" }}
        >
          {pinnedItems[item.key] ? (
            <PushPinIcon sx={{ fontSize: "1rem" }} />
          ) : (
            <PushPinOutlinedIcon sx={{ fontSize: "1rem" }} />
          )}
        </IconButton>
      )}
    </Box>
  );
};

export default SidebarItem;
