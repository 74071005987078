import axios from "axios";
import config from "../../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "../../index";
import { endpoint } from "constants/endpoint";

export interface ISyncOrderService {
  fetchCustomersOrderSync(): Promise<{
    message: string;
  }>;
}

export default new Mockable<ISyncOrderService>({
  async fetchCustomersOrderSync(): Promise<{
    message: string;
  }> {
    try {
      const res = await axios(
        `${config.api}${endpoint.customers_orders_sync}`,
        {
          headers: commonHeaders(),
        },
      );

      if (res.status === 200) {
        return res.data;
      } else {
        return {
          message: "fetchCustomersOrderSync failed with status" + res.status,
        };
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
});
