import React, { useEffect, useState } from "react";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import AddProcurementCategory from "./parts/AddProcurementCategory";
import useAppDispatch from "hooks/useAppDispatch";
import { fetchSuppliers } from "store/suppliers/thunks";
import {
  addProcurementCategoryAsync,
  fetchProcurementCategoriesAsync,
} from "store/procurement-categories/thunks";
import useAppSelector from "hooks/useAppSelector";
import { IUpdateProcurementCategoryDTO } from "store/procurement-categories/interfaces";
import SaveButton from "../parts/SaveButton";
import { updateProcurementCategoryFn } from "../utils";
import { selectProcurementCategoriesState } from "store/procurement-categories";
import theme from "theme/theme";

const AddProcurementCategoryPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState({
    name: "",
    description: "",
    url: "",
    supplier: 0,
    parent: 0,
    catalog_categories_auto_create: [0],
    description_html: "",
  });

  const procurementCategoryCallback = updateProcurementCategoryFn(
    setUpdate,
    update,
  );
  const { procurementCategoriesLoaded } = useAppSelector(
    selectProcurementCategoriesState,
  );

  useEffect(() => {
    dispatch(fetchSuppliers());
  }, [dispatch]);

  useEffect(() => {
    if (!procurementCategoriesLoaded) {
      dispatch(fetchProcurementCategoriesAsync(new URLSearchParams()));
    }
  }, [dispatch, procurementCategoriesLoaded]);

  const addProcurementCategory = () => {
    setLoading(true);
    const objectTobeUpdated: IUpdateProcurementCategoryDTO = {
      ...update,
    };
    dispatch(addProcurementCategoryAsync({ object: objectTobeUpdated }))
      .unwrap()
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <Backdrop
          sx={{
            color: theme.palette.light.main,
            backgroundColor: theme.palette.action.selected,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" sx={{ ml: "22vw" }} />
        </Backdrop>
      ) : null}
      <Box
        sx={{
          background: "white",
          borderRadius: "4px",
          padding: "1rem",
        }}
      >
        <Box sx={{ overflowY: "scroll", height: "calc(100% - 14.2rem)" }}>
          <AddProcurementCategory
            onProcurementCategoryChanged={procurementCategoryCallback}
          />
          <SaveButton onClickHandler={addProcurementCategory} />
        </Box>
      </Box>
    </>
  );
};

export default AddProcurementCategoryPage;
