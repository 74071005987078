import { useSearchParams } from "react-router-dom";

/**
 * Updates the given search params, or replaces the search params entirely with the given params if replace is true.
 * On update, removes the param if the value is empty, otherwise sets the param. Also removes the ordering of the items.
 * Uses 'useSearchParams' for reactivity.
 */
const useChangeFilters = (): ((
  queryParams: URLSearchParams,
  replace?: boolean,
) => void) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const changeFilter = (
    queryParams: URLSearchParams,
    replace = false,
  ): void => {
    const usesPage = searchParams.has("page");
    const limitParam = searchParams.get("limit")!;
    if (replace) {
      if (usesPage) {
        queryParams.set("page", "1");
      } else {
        queryParams.set("offset", "0");
      }
      queryParams.set("limit", limitParam);
      setSearchParams(queryParams, { replace: true });
    } else {
      queryParams.forEach((value, param) => {
        searchParams.delete("ordering");
        if (value) {
          searchParams.set(param, value);
        } else {
          searchParams.delete(param);
        }
      });
      if (usesPage) {
        searchParams.set("page", "1");
      } else {
        searchParams.set("offset", "0");
      }
      setSearchParams(searchParams, { replace: true });
    }
  };
  return changeFilter;
};

export default useChangeFilters;
