import React from "react";
import { useState } from "react";
import config from "../../../../config";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { fetchPickOrderAsync } from "store/orders/pickOrder/thunks";
import { IHistoryItem } from "./Row";
import { fetchUnPickOrderAsync } from "store/orders/unpickOrder/thunks";
import {
  clearPickOrderMessage,
  selectPickOrderState,
  setPickProductId,
} from "store/orders/pickOrder";
import {
  clearunPickOrderMessage,
  selectUnpickOrderState,
  setUnpickOrdertId,
} from "store/orders/unpickOrder";
import { Add, Edit, Undo } from "@mui/icons-material";
import { IInventory } from "store/inventories/interfaces";
import { selectInventoryTransactionsState } from "store/inventory-transactions";
import { isEmpty } from "lodash-es";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Link,
  Snackbar,
  Stack,
  TableCell,
} from "@mui/material";
import theme from "theme/theme";
import { IInventoryTransaction } from "store/inventory-transactions/interfaces";

const StockOptions = ({
  historyRow,
  id,
}: {
  historyRow: IHistoryItem;
  id: number;
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { message: pickMessage, error: pickError } =
    useAppSelector(selectPickOrderState);
  const { message: unpickMessage, error: unpickError } = useAppSelector(
    selectUnpickOrderState,
  );
  const { inventoryTransactions } = useAppSelector(
    selectInventoryTransactionsState,
  );
  const { orders } = useAppSelector((state) => state.orders);

  const filterZeroQuantityStock = () => {
    if (historyRow.quantity.qty_picked) {
      if (historyRow.stock.length > 1) {
        return historyRow.stock.filter(
          (item) => Math.trunc(+item.accumulated_quantity) !== 0,
        );
      } else {
        return historyRow.stock;
      }
    } else {
      return historyRow.stock.filter(
        (item) => Math.trunc(+item.accumulated_quantity) !== 0,
      );
    }
  };
  const onPickClickHandler = (item: IInventory) => {
    setLoading(true);
    const foundOrder = orders.find((order: { id: number }) => order.id === id)!;

    dispatch(
      setPickProductId({
        orderId: foundOrder.order_number,
      }),
    );

    dispatch(
      fetchPickOrderAsync({
        order_item_id: historyRow.id,
        location_id: item.location_id,
        product_id: historyRow.product.product_item_id,
        quantity: Number(historyRow.quantity.qty_ordered),
      }),
    )
      .unwrap()
      .finally(() => setLoading(false));
  };
  const onUnPickClickHandler = (transaction: number) => {
    setLoading(true);
    const foundOrder = orders.find((order: any) => order.id === id)!;

    dispatch(
      setUnpickOrdertId({
        orderId: foundOrder.order_number,
      }),
    );
    dispatch(
      fetchUnPickOrderAsync({
        order_item_id: historyRow.id,
        inventory_transaction_id: transaction,
      }),
    )
      .unwrap()
      .finally(() => setLoading(false));
  };

  const findUpickQuantity = (transaction: number) => {
    const foundTransaction =
      !isEmpty(inventoryTransactions) &&
      inventoryTransactions.find((item) => item.id === transaction);
    return (
      transaction !== undefined &&
      Math.trunc(+(foundTransaction as IInventoryTransaction).quantity)
    );
  };

  return (
    <>
      <TableCell>
        <Backdrop sx={{ color: theme.palette.light.main }} open={loading}>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            height="100vh"
            ml="22vw"
          >
            <CircularProgress />
          </Box>
        </Backdrop>

        <Snackbar
          open={
            !!pickMessage || !!unpickMessage || !!pickError || !!unpickError
          }
          autoHideDuration={4000}
          onClose={() => {
            (pickMessage || pickError) && dispatch(clearPickOrderMessage());
            (unpickError || unpickMessage) &&
              dispatch(clearunPickOrderMessage());
          }}
        >
          <Alert
            severity={pickError || unpickError ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {pickMessage || unpickMessage || pickError || unpickError}
          </Alert>
        </Snackbar>
        <Stack spacing={2} justifyContent="center">
          {filterZeroQuantityStock()?.map((item) => (
            <Box key={item.location_id} sx={{ width: "150px", height: "22px" }}>
              {Math.trunc(+item.accumulated_quantity)} @{" "}
              {item.abbreviation_slug}
            </Box>
          ))}
        </Stack>
        <Button
          sx={{ alignSelf: "flex-start", visibility: "hidden" }}
          variant="outlined"
          size="small"
        >
          hidden
        </Button>
      </TableCell>

      <TableCell>
        <Stack spacing={1}>
          {filterZeroQuantityStock()?.map((item, index) => (
            <Box key={item.location_id}>
              <Stack spacing={1}>
                {+historyRow.quantity.qty_picked > 0 &&
                  historyRow.inventoryTransaction?.map((item) => (
                    <Button
                      key={item}
                      variant="outlined"
                      color="error"
                      size="small"
                      startIcon={<Undo />}
                      onClick={() => onUnPickClickHandler(item)}
                      sx={{
                        visibility: index !== 0 ? "hidden" : "visible",
                        width: "100px",
                      }}
                    >
                      <Box sx={{ color: "red" }}>
                        UnPick{findUpickQuantity(item)}x
                      </Box>
                    </Button>
                  ))}

                {+historyRow.quantity.qty_ordered -
                  +historyRow.quantity.qty_picked >
                  0 &&
                  Math.trunc(+item.accumulated_quantity) > 0 && (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => onPickClickHandler(item)}
                      startIcon={<Add />}
                      sx={{ width: "100px" }}
                    >
                      <Box>
                        Pick
                        {Math.trunc(+item.accumulated_quantity) <=
                        +historyRow.quantity.qty_ordered
                          ? Math.trunc(+item.accumulated_quantity)
                          : historyRow.quantity.qty_ordered}
                        x
                      </Box>
                    </Button>
                  )}
              </Stack>
            </Box>
          ))}
          <Button
            sx={{ alignSelf: "flex-start", visibility: "hidden" }}
            variant="outlined"
            size="small"
          >
            hidden
          </Button>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack spacing={1} justifyContent="center">
          {filterZeroQuantityStock()?.map((item) => (
            <Link
              key={item.location_id}
              target="_blank"
              href={`${config.clientUrl}/admin/catalog/inventorytransaction/add/?product=${historyRow.product.product_item_id}&location=${item.location_id}`}
            >
              <Button
                sx={{ alignSelf: "flex-start" }}
                variant="outlined"
                size="small"
                startIcon={<Edit />}
                onClick={() => {}}
              >
                Stocktaking
              </Button>
            </Link>
          ))}

          <Link
            target="_blank"
            href={`${config.clientUrl}/admin/catalog/inventorytransaction/add/?product=${historyRow.product.product_item_id}`}
          >
            <Button
              sx={{ alignSelf: "flex-start" }}
              variant="outlined"
              size="small"
              startIcon={<Edit />}
              onClick={() => {}}
            >
              Stocktaking
            </Button>
          </Link>
        </Stack>
      </TableCell>
    </>
  );
};

export default StockOptions;
