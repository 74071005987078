import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import {
  IAddInventoryTransaction,
  IInventoryTransaction,
  IInventoryTransactionsDTO,
} from "store/inventory-transactions/interfaces";
import { endpoint } from "constants/endpoint";

export interface IInventoryTransactionsService {
  fetchInventoryTransactions(
    params: URLSearchParams,
  ): Promise<IInventoryTransactionsDTO>;
  addInventoryTransaction(
    object: IAddInventoryTransaction,
  ): Promise<IInventoryTransaction>;
  updateInventoryTransaction(obj: IInventoryTransaction): Promise<void>;
}

export default new Mockable<IInventoryTransactionsService>({
  async fetchInventoryTransactions(params): Promise<IInventoryTransactionsDTO> {
    try {
      const res = await axios(
        `${config.api}${endpoint.catalog_inventory_transactions}`,
        {
          params,
          headers: commonHeaders(),
        },
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async fetchInventoryTransactions(params) <<",
          ),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },

  async addInventoryTransaction(
    object: IAddInventoryTransaction,
  ): Promise<IInventoryTransaction> {
    const res = await axios(
      `${config.api}${endpoint.catalog_inventory_transactions}`,
      {
        method: "POST",
        headers: commonHeaders(),
        data: JSON.stringify(object),
      },
    );
    if (res.status === 201) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async updateInventoryTransaction(
    updateProductDescription: IInventoryTransaction,
  ): Promise<void> {
    const res = await axios.put(
      `${config.api}${endpoint.catalog_inventory_transactions}${updateProductDescription.id}/`,
      updateProductDescription,
      {
        headers: commonHeaders(),
      },
    );
    if (res.status === 200) {
      return res.data;
    } else {
      return Promise.reject(
        new Error(
          "Unexpected response format in >> async updateInventoryTransaction <<",
        ),
      );
    }
  },
});
