import React from "react";
import {
  Stack,
  Typography,
  IconButton,
  Box,
  Container,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { isEmpty } from "lodash-es";
import { useState, useEffect } from "react";
import { selectInventoryLocationState } from "store/inventory-locations";
import { IInventoryLocation } from "store/inventory-locations/interfaces";
import Modal from "components/ModalComponent";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import { selectCatalogState } from "store/catalog";
import { updateProducts } from "store/catalog/thunks";
import { displaySnackbar } from "store/snackbar";
import { ICatalogProduct } from "store/catalog/interfaces";

const UpdateDefaultLocation = ({
  location,
  id,
}: {
  location: number;
  id: number;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [optionSelected, setOptionSelected] = useState<{
    slug: string;
    id: number;
  } | null>(null);
  const { catalogProducts } = useAppSelector(selectCatalogState);

  const { inventoryLocations } = useAppSelector(selectInventoryLocationState);

  const [filteredInventoryLocations, setFilteredInventoryLocations] =
    useState<IInventoryLocation[]>();

  const foundDefaultLocation =
    !isEmpty(inventoryLocations) &&
    inventoryLocations.find((loc) => loc.id === location);

  const handleClickOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const foundProduct = catalogProducts.find((prod) => prod.id === id);

    const {
      sku,
      name,
      cost_shipping,
      price_manual_override,
      price_on_sale,
      description,
    } = foundProduct as ICatalogProduct;

    await dispatch(
      updateProducts({
        products: [
          {
            id,
            sku,
            name,
            cost_shipping,
            price_manual_override,
            price_on_sale,
            descriptions: [description],
            default_location_id: optionSelected?.id,
          },
        ],
      }),
    )
      .unwrap()
      .finally(() => {
        setModalOpen(false);
        dispatch(
          displaySnackbar({
            message: "Default Location updated successfully",
            action: null,
            open: false,
          }),
        );
      });
  };

  useEffect(() => {
    if (!isEmpty(foundDefaultLocation)) {
      setOptionSelected({
        slug: (foundDefaultLocation as IInventoryLocation).abbreviation_slug,
        id: (foundDefaultLocation as IInventoryLocation).id,
      });
    }
  }, [foundDefaultLocation]);

  useEffect(() => {
    !isEmpty(inventoryLocations) &&
      setFilteredInventoryLocations(inventoryLocations);
  }, [dispatch, inventoryLocations]);

  return (
    <>
      {!isEmpty(foundDefaultLocation) && (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography component="div" fontSize={13}>
            {(foundDefaultLocation as IInventoryLocation).abbreviation_slug}
          </Typography>
          <IconButton name="Edit default location" onClick={handleClickOpen}>
            <EditIcon sx={{ fontSize: 13 }} />
          </IconButton>
        </Stack>
      )}
      <Modal
        title="Update Default Location"
        isOpen={modalOpen}
        handleClose={handleClose}
      >
        <Container component="main">
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minHeight: 350,
            }}
          >
            <Box
              component="form"
              onSubmit={handleOnSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <Box sx={{ minWidth: 300 }}>
                <Autocomplete
                  value={optionSelected}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  id="autocomplete-1"
                  options={
                    !isEmpty(filteredInventoryLocations)
                      ? (
                          filteredInventoryLocations as IInventoryLocation[]
                        )?.map((inventory) => {
                          if (inventory.abbreviation_slug) {
                            return {
                              slug: inventory.abbreviation_slug,
                              id: inventory.id,
                            };
                          } else {
                            return {
                              slug: `${inventory.name_slug} (abbreviation_slug: undefined)`,
                              id: inventory.id,
                            };
                          }
                        })
                      : []
                  }
                  getOptionLabel={(option) => `${option.slug}`}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Location"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  onChange={(e, value) =>
                    setOptionSelected(value ?? { slug: "", id: 0 })
                  }
                />
              </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Container>
      </Modal>
    </>
  );
};
export default UpdateDefaultLocation;
