export const endpoint = {
  login: "/login/",
  token_refresh: "/token/refresh/",
  user_password_reset: "/user/password_reset/",
  user_password_reset_confirm: "/user/password_reset/confirm/",
  user: "/user/",
  user_demo_register: "/user/demo_register/",
  users_companies: "/users/companies/",
  customers_backorders: "/customers/backorders/",
  catalog_product_count: "/catalog/product-count/",
  catalog_products: "/catalog/products/",
  catalog_products_types: "/catalog/product-types/",
  catalog_products_publish: "/catalog/products/publish/",
  catalog_products_bulk3_description:
    "/catalog/products_with_related_entities/",
  catalog_categories: "/catalog/categories/",
  catalog_markups: "/catalog/markups/",
  catalog_images: "/catalog/images/",
  misc_currencies: "/misc/currencies",
  catalog_image_classification: "/catalog/image_classification",
  catalog_inventory_transactions: "/catalog/inventory_transactions/",
  catalog_inventory_locations: "/catalog/inventory_locations/",
  catalog_inventory_locations_types: "/catalog/inventory_location_types/",
  catalog_products_inventories: "/catalog/products/inventories/",
  catalog_inventory_location_types: "/catalog/inventory_location_types/",
  catalog_inventory_locations_with_transactions:
    "/catalog/inventory_locations_with_transactions/",
  catalog_products_inventories_transactions:
    "/catalog/products/inventories/transactions/",
  procurement_products_with_procurement_order_and_backorder_data:
    "/procurement/products/with_procurement_order_and_backorder_data/",
  customers_orders: "/customers/orders/",
  customers_order_items: "/customers/order_items/",
  suppliers: "/suppliers/",
  suppliers_global: "/suppliers_global/",
  customers_orders_sync: "/customers/orders/sync",
  procurement_categories: "/procurement/categories/",
  procurement_products: "/procurement/products/",
  procurement_products_add_to_catalog: "/procurement/products/add-to-catalog/",
  procurement_suppliers: "/procurement/suppliers/",
  procurement_orders: "/procurement/orders/",
  admin_catalog_markup: "/admin/catalog/markup/",
  admin_catalog_category_add: "/admin/catalog/category/add/",
  product_images: "/catalog/product_images/",
  category_images: "/catalog/category_images/",
  procurement_feed: "/procurement/feeds/",
  procurement_image_urls: "/procurement/image_urls/",
  product_description: "/catalog/product_descriptions/",
  catalogProduct_description: "/catalog/products2/",
  taxonomies: "/misc/google_product_taxonomies/",
  misc_facebook: "/misc/facebook_product_taxonomies/",
  procurements_facebook: "/preferences/facebook_product_taxonomies/",
  notifications_system: "/users/notifications/",
  notifications_user: "/users/user_notifications/",
  notifications_feedback: "/crm/tickets/",
  file_urls: "/procurement/file_urls/",
  users: "/users/users/",
};
