import React from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from "@mui/material";
import CustomTableHead from "./CustomTableHead";
import useAppSelector from "hooks/useAppSelector";
import { ordersSelectors } from "store/orders";

const OrderItems = (): JSX.Element => {
  const { order } = useAppSelector(ordersSelectors);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table>
          <CustomTableHead />
          <TableBody>
            {order && order.order_items.length > 0 ? (
              order.order_items?.map((orderItem) => (
                <TableRow key={orderItem.id}>
                  <TableCell>{orderItem.prod_name}</TableCell>
                  <TableCell>{orderItem.qty_ordered}</TableCell>
                  <TableCell>{orderItem.qty_to_be_picked}</TableCell>
                  <TableCell>{orderItem.qty_picked}</TableCell>
                  <TableCell>{orderItem.qty_to_be_backordered}</TableCell>
                  <TableCell>{orderItem.backordered}</TableCell>
                  <TableCell>{orderItem.prod_price}</TableCell>
                  <TableCell>{orderItem.prod_vat_percentage}</TableCell>
                  <TableCell>{orderItem.prod_tax}</TableCell>
                  <TableCell>{orderItem.prod_total}</TableCell>
                  <TableCell>{orderItem.subtotal}</TableCell>
                  <TableCell>{orderItem.subtotal_tax}</TableCell>
                  <TableCell>{orderItem.total}</TableCell>
                  <TableCell>{orderItem.total_tax}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                    height="40vh"
                  >
                    No rows
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default OrderItems;
