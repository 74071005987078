import { createAsyncThunk } from "@reduxjs/toolkit";
import imageClassifications from "services/image-classifications";
import { IImageClassificationSliceDTO } from "./interfaces";

export const fetchImageClassificationsAsync = createAsyncThunk<
  IImageClassificationSliceDTO,
  void
>("image-classifications/fetchImageClassifications", async () => {
  return await imageClassifications.service().fetchImageClassifications();
});
