import React from "react";
import { Box } from "@mui/material";

interface Props {
  src: any;
  width: number;
  height: number;
  alt: string;
  loading?: "eager" | "lazy";
}

const ImageThumbnail = ({ src, width, height, alt, loading }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <img
        src={src}
        width={width}
        height={height}
        alt={alt}
        loading={loading}
      />
    </Box>
  );
};
export default ImageThumbnail;
