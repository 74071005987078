import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";

import { IImageClassificationSliceState } from "./interfaces";
import { fetchImageClassificationsAsync } from "./thunks";

const initialState: IImageClassificationSliceState = {
  count: 0,
  next: "",
  previous: "",
  imageClassifications: [],
  imageClassificationsLoaded: false,
};

const imageClassificationSlice = createSlice({
  name: "imageClassifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchImageClassificationsAsync.fulfilled,
      (state, action) => {
        state.count = action.payload.count;
        state.next = action.payload.next;
        state.previous = action.payload.previous;
        state.imageClassifications = action.payload.results;
        state.imageClassificationsLoaded = true;
      },
    );
  },
});

export const selectImageClassificationState = (
  state: RootState,
): IImageClassificationSliceState => state.imageClassifications;

export default imageClassificationSlice.reducer;
