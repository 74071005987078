import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import { ISupplier } from "store/suppliers/interfaces";
import { endpoint } from "constants/endpoint";

export interface ISuppliersService {
  fetchSuppliers(): Promise<ISupplier[]>;
  fetchGlobalSuppliers(): Promise<ISupplier[]>;
}

export default new Mockable<ISuppliersService>({
  async fetchSuppliers(): Promise<ISupplier[]> {
    const res = await axios(`${config.api}${endpoint.suppliers}`, {
      headers: commonHeaders(),
    });

    if (res.status === 200) {
      return res.data;
    }

    return Promise.reject("Couldn't get suppliers data");
  },

  async fetchGlobalSuppliers(): Promise<ISupplier[]> {
    const res = await axios(`${config.api}${endpoint.suppliers_global}`, {
      headers: commonHeaders(),
    });

    if (res.status === 200) {
      return res.data;
    }

    return Promise.reject("Couldn't get suppliers data");
  },
});
