import React from "react";
import { View, StyleSheet, Image, Text, Font } from "@react-pdf/renderer";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
  ],
});

const styles = StyleSheet.create({
  content: {
    width: "100%",
    "@media max-width: 400": {
      flexDirection: "column",
    },
    "@media min-width: 400": {
      flexDirection: "row",
    },
  },
  subHeading: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: 9,
  },
});

const OrderPDFHeaderComponent = ({
  companyData,
  orderData,
}: any): JSX.Element => (
  <View style={styles.content}>
    <view
      style={{
        flex: 2,
      }}
    >
      {companyData.logo_rectangle && (
        <Image
          src={companyData.logo_rectangle}
          style={{
            marginLeft: 0,
            marginBottom: 30,
            width: 200,
          }}
        />
      )}
    </view>
    <View
      style={{
        flex: 1,
        marginBottom: 20,
      }}
    >
      <Text
        style={{ fontFamily: "Open Sans", fontWeight: 800, fontSize: 12 }}
      >{`FÖLJESEDEL ${orderData.order_number}`}</Text>
      <Text
        style={styles.subHeading}
      >{`Order date: ${orderData.order_created.slice(0, 10)}`}</Text>
      <Text
        style={styles.subHeading}
      >{`Packed: ${orderData.created_at.slice(0, 10)}`}</Text>
      <Text
        style={styles.subHeading}
        render={({ pageNumber }) => `Sida av ${pageNumber}`}
      ></Text>
    </View>
  </View>
);

export default OrderPDFHeaderComponent;
