import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import orders from "services/orders";
import { RootState } from "../index";
import {
  ICompanyDetailsResponseDTO,
  ICustomersOrdersResponseDTO,
} from "./interfaces";
import { fetchInventoriesAsync } from "store/inventories/thunks";
import { IInventoryResponseDTO } from "store/inventories/interfaces";
import { fetchProcurementSourcesAsync } from "store/procurement-sources/thunks";
import { fetchInventoryTransactionsAsync } from "store/inventory-transactions/thunks";
import { setErrorMessage } from ".";
import { httpErrorHandler } from "utils/error-response";

export interface OrdersParams {
  page: number;
  limit: number;
  orderCreatedGte: string;
  orderCreatedLte: string;
  orderDeliveredGte: string;
  orderDeliveredLte: string;
  orderNumber: string;
  sortField: string;
  sortDirection: string;
  internalOrderStatus: string;
}

export const fetchOrdersAsync = createAsyncThunk<
  ICustomersOrdersResponseDTO,
  URLSearchParams,
  { state: RootState }
>(
  "orders/fetchOrdersAsync",
  async (
    params: URLSearchParams,
    thunkAPI,
  ): Promise<ICustomersOrdersResponseDTO> => {
    const state = thunkAPI.getState();

    if (!state.pickOrder.pickOrderId && !state.unpickOrder.unpickOrderId) {
      const res = await orders.service().fetchCustomersOrders(params);

      const orderIds: Array<number> = [];
      res.results.forEach((orders) => {
        orders.order_items.forEach((order) => {
          order.product &&
            !orderIds.includes(order.product) &&
            orderIds.push(order.product);
          order.product__stock_proxy_parent &&
            orderIds.push(order.product__stock_proxy_parent);
        });
      });

      const inventoryTransactions: Array<number>[] = [];

      res.results.forEach((orders) => {
        orders.order_items.forEach((orderItem) => {
          orderItem.inventory_transactions.length > 0 &&
            inventoryTransactions.push(orderItem.inventory_transactions);
        });
      });

      const inventoryTransactionResult = await thunkAPI.dispatch(
        fetchInventoryTransactionsAsync(
          new URLSearchParams([["id__in", inventoryTransactions.toString()]]),
        ),
      );
      if (
        inventoryTransactionResult.type ===
        "orders/fetchInventoryTransactionsAsync/rejected"
      ) {
        const inventoryTransactionResultError =
          inventoryTransactionResult as any;

        thunkAPI.dispatch(
          setErrorMessage({
            ...httpErrorHandler(inventoryTransactionResultError.error),
            url: config.api + "/catalog/products/inventories/transactions/",
          }),
        );

        return { results: [] };
      }

      const inventoryResult = await thunkAPI.dispatch(
        fetchInventoriesAsync(orderIds.length > 0 ? orderIds.toString() : "0"),
      );

      if (inventoryResult.type === "orders/fetchInventoriesAsync/rejected") {
        const inventoryResultError = inventoryResult as any;

        thunkAPI.dispatch(
          setErrorMessage({
            ...httpErrorHandler(inventoryResultError.error),
            url: config.api + "/catalog/products/inventories/",
          }),
        );
        return { results: [] };
      }

      const resultPayload = inventoryResult.payload as IInventoryResponseDTO;
      const restProd = resultPayload.result;

      const procurementProdIds: Array<number> = [];
      for (const x in restProd) {
        !restProd[x].procurement_products.includes(0) &&
          restProd[x].procurement_products.forEach((order: number) => {
            !procurementProdIds.includes(order) &&
              procurementProdIds.push(order);
          });
      }

      await thunkAPI.dispatch(
        fetchProcurementSourcesAsync(
          procurementProdIds.length > 0 ? procurementProdIds.toString() : "0",
        ),
      );
      return res;
    } else {
      const ordersData = thunkAPI.getState().orders;

      return {
        count: ordersData.count,
        next: ordersData.nextPageUrl,
        previous: ordersData.previousPageUrl,
        results: ordersData.orders,
      };
    }
  },
);

export const fetchCustomersOrderAsync = createAsyncThunk(
  "orders/fetchCustomersOrderAsync",
  async (id: string, thunkAPI) => {
    return await orders.service().fetchCustomersOrder(id);
  },
);

export const fetchCompanyDetailsAsync = createAsyncThunk(
  "orders/fetchCompanyDetailsAsync",
  async (thunkAPI): Promise<ICompanyDetailsResponseDTO> => {
    return await orders.service().fetchCompanyDetails();
  },
);
