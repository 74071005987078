import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import {
  IImagesResponseDTO,
  ICatalogImageResponseDTO,
  IImageUploadResponseDTO,
} from "store/procurement_images/interfaces";
import { endpoint } from "constants/endpoint";

export interface IImagesService {
  fetchImages(params: URLSearchParams): Promise<IImagesResponseDTO>;
  fetchProductImages(): Promise<ICatalogImageResponseDTO>;
  fetchCategoryImages(): Promise<ICatalogImageResponseDTO>;
  uploadImage(file: FormData): Promise<IImageUploadResponseDTO>;
  deleteImageUrls(ids: number[]): Promise<number>;
}

export default new Mockable<IImagesService>({
  async fetchImages(params) {
    const res = await axios.get(
      `${config.api}${endpoint.procurement_image_urls}`,
      {
        params,
        headers: commonHeaders(),
      },
    );
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Couldn't get images data");
  },

  async fetchProductImages() {
    const res = await axios.get(
      `${config.api}${endpoint.procurement_image_urls}`,
      {
        headers: commonHeaders(),
      },
    );

    if (res.status === 200) {
      return res.data;
    }

    return Promise.reject("Couldn't get images data");
  },

  async fetchCategoryImages() {
    const res = await axios.get(
      `${config.api}${endpoint.procurement_image_urls}`,
      {
        headers: commonHeaders(),
      },
    );

    if (res.status === 200) {
      return res.data;
    }

    return Promise.reject("Couldn't get images data");
  },

  async uploadImage(image) {
    const res = await axios.post(
      `${config.api}${endpoint.procurement_image_urls}`,
      image,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "multipart/form-data",
        },
      },
    );

    if (res.status === 200 || res.status === 201) {
      return res.data;
    }

    return Promise.reject("Couldn't upload images");
  },

  async deleteImageUrls(ids: number[]): Promise<number> {
    let result = 0;

    ids.forEach(async (item) => {
      const res = await axios(
        `${config.api}${endpoint.procurement_image_urls}${item}`,
        {
          method: "DELETE",
          headers: commonHeaders(),
        },
      );
      if (res.status === 204) {
        result += 1;
      }
    });
    return result;
  },
});
