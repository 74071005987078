import { GridColDef } from "@mui/x-data-grid";
const ColumnsForDataGrid = (): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: "type",
      headerName: "Name of Inventory Location Type",
      flex: 1,
      sortable: true,
    },
    {
      field: "label_linebreak",
      flex: 1,
      headerName: "Line break after this?",
      sortable: false,
    },
  ];

  return columns;
};

export default ColumnsForDataGrid;
