import { createAsyncThunk } from "@reduxjs/toolkit";
import productSup from "services/catalog-file-urls";
import { IFileUrlsList, IItemsFileUrls } from "./interfaces";

interface UpdateItemsFileUrls {
  params: IItemsFileUrls;
}

export const fetchFileUrlsListAsync = createAsyncThunk<
  IFileUrlsList,
  URLSearchParams
>("fileUrlsList/fetchFileUrlsListAsync", async (params) => {
  const res = await productSup.service().fetchFileUrlsList(params);
  return res;
});

export const deleteFileUrlsAsync = createAsyncThunk<void, { ids: number[] }>(
  "fileUrlsList/deleteFileUrlsAsync",
  async ({ ids }) => {
    await productSup.service().deleteFileUrls(ids);
  },
);

export const AddFileUrls = createAsyncThunk(
  "fileUrlsList/AddFileUrls",
  async (args: UpdateItemsFileUrls) => {
    const { params } = args;
    const res = await productSup.service().AddFileUrls(params);
    return res;
  },
);

export const UpdateFileUrls = createAsyncThunk(
  "fileUrlsList/UpdateFileUrls",
  async (args: UpdateItemsFileUrls) => {
    const { params } = args;
    const res = await productSup.service().UpdateFileUrls(params.id, params);
    return res;
  },
);
