export const Convertinventorylocationstext = (key: string): string => {
  switch (key) {
    case "all":
      return "All";
      break;
    case "false":
      return "Not Obsolete";
      break;
    case "true":
      return "Obsolete";
      break;
  }
  return "Unknown in Convertinventorylocationstext file";
};
