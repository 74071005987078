import React from "react";
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  Stack,
} from "@mui/material";
import SelectViewOption from "./SelectViewOption";
import { ViewType } from "store/catalog";

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  label: ViewType;
}

const Modal = ({ isOpen, handleClose, label }: ModalProps): JSX.Element => {
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Typography fontWeight="bold">View Option</Typography>
        <Typography>
          Select the type of listing that you want to have from the left bar
          then you can customize items visible the table.
        </Typography>
        <Stack mt={2} justifyContent="center" alignItems="center">
          <Typography fontWeight="bold" fontSize={11} pb={1}>
            Choose items to view in the table
          </Typography>
          <SelectViewOption handleClose={handleClose} label={label} />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
