import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import config from "../../config";
import { RootState } from "../index";
import { fetchSyncOrder } from "store/orders/syncOrder/thunks";
import { fetchSyncOrder1month } from "store/orders/syncOrder1month/thunks";
import {
  ICompanyDetails,
  ICompanyDetailsResponseDTO,
  ICustomersOrders,
  ICustomersOrdersResponseDTO,
} from "./interfaces";
import {
  fetchCustomersOrderAsync,
  fetchOrdersAsync,
  fetchCompanyDetailsAsync,
} from "./thunks";
import { fetchUnPickOrderAsync } from "store/orders/unpickOrder/thunks";
import { fetchPickOrderAsync } from "store/orders/pickOrder/thunks";
import { IErrorMessage } from "components/ErrorComponent";
import { httpErrorHandler } from "utils/error-response";
import { fetchCatalogProducts } from "store/catalog/thunks";

interface OrdersState {
  order: ICustomersOrders | null;
  orders: ICustomersOrders[];
  companyDetails: ICompanyDetails[];
  ordersLoaded: boolean;
  error: IErrorMessage;
  count: number;
  nextPageUrl: string | undefined;
  previousPageUrl: string | undefined;
  filterParams: {
    page: number;
    limit: number;
    orderCreatedGte: string;
    orderCreatedLte: string;
    orderDeliveredGte: string;
    orderDeliveredLte: string;
    orderNumber: string;
    sortField: string;
    sortDirection: string;
    internalOrderStatus: string;
    search: string;
  };
}

export const initialState: OrdersState = {
  order: null,
  orders: [],
  companyDetails: [],
  ordersLoaded: false,
  error: {},
  count: 0,
  nextPageUrl: undefined,
  previousPageUrl: undefined,
  filterParams: {
    page: 0,
    limit: 25,
    orderCreatedGte: "",
    orderCreatedLte: "",
    orderDeliveredGte: "",
    orderDeliveredLte: "",
    orderNumber: "",
    sortField: "",
    sortDirection: "",
    internalOrderStatus: "",
    search: "",
  },
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    resetAllFilters: (state) => {
      state.ordersLoaded = false;
      state.filterParams = {
        page: 0,
        limit: 25,
        orderCreatedGte: "",
        orderCreatedLte: "",
        orderDeliveredGte: "",
        orderDeliveredLte: "",
        orderNumber: "",
        sortField: "",
        sortDirection: "",
        internalOrderStatus: "",
        search: "",
      };
    },
    setOrdersParams: (state, action) => {
      state.ordersLoaded = false;
      state.filterParams = {
        ...state.filterParams,
        ...action.payload,
        page: 0,
      };
    },
    setPageNumber: (state, action) => {
      state.ordersLoaded = false;
      state.filterParams = { ...state.filterParams, ...action.payload };
    },
    setRowsLimit: (state, action) => {
      state.ordersLoaded = false;
      state.filterParams = {
        ...state.filterParams,
        ...action.payload,
        page: 0,
      };
    },
    setInternalOrderStatus: (state, action) => {
      state.ordersLoaded = false;
      state.filterParams = {
        ...state.filterParams,
        ...action.payload,
        page: 0,
      };
    },
    resetInternalOrderStatus: (state) => {
      state.ordersLoaded = false;
      state.filterParams = {
        ...state.filterParams,
        internalOrderStatus: "",
        page: 0,
      };
    },
    resetOrderCreated: (state) => {
      state.ordersLoaded = false;
      state.filterParams = {
        ...state.filterParams,
        page: 0,
        orderCreatedGte: "",
        orderCreatedLte: "",
      };
    },
    resetOrderCreatedGte: (state) => {
      state.ordersLoaded = false;
      state.filterParams = {
        ...state.filterParams,
        orderCreatedGte: "",
      };
    },
    resetOrderCreatedLte: (state) => {
      state.ordersLoaded = false;
      state.filterParams = {
        ...state.filterParams,
        orderCreatedLte: "",
      };
    },
    resetOrderDelivered: (state) => {
      state.ordersLoaded = false;
      state.filterParams = {
        ...state.filterParams,
        page: 0,
        orderDeliveredGte: "",
        orderDeliveredLte: "",
      };
    },
    resetOrderDeliveredGte: (state) => {
      state.ordersLoaded = false;
      state.filterParams = {
        ...state.filterParams,
        orderDeliveredGte: "",
      };
    },
    resetOrderDeliveredLte: (state) => {
      state.ordersLoaded = false;
      state.filterParams = {
        ...state.filterParams,
        orderDeliveredLte: "",
      };
    },
    pickStateUpdate: (state, { payload }) => {
      state.orders = payload.changes;
    },

    setErrorMessage: (state, { payload }) => {
      state.error = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchOrdersAsync.fulfilled,
      (state, action: PayloadAction<ICustomersOrdersResponseDTO>) => {
        state.orders = action.payload?.results;
        state.ordersLoaded = true;
        state.count = action.payload.count ? action.payload.count : 0;
        state.nextPageUrl = action.payload.next ? action.payload.next : "";
        state.previousPageUrl = action.payload.previous
          ? action.payload.previous
          : "";
      },
    );
    builder.addCase(fetchOrdersAsync.rejected, (state, { error }) => {
      state.error = {
        ...httpErrorHandler(error),
        url: config.api + "/cusomers/orders/",
      };
    });

    builder.addCase(fetchSyncOrder.fulfilled, (state) => {
      state.ordersLoaded = false;
    });

    builder.addCase(fetchCustomersOrderAsync.fulfilled, (state, action) => {
      state.order = action.payload;
    });

    builder.addCase(fetchSyncOrder1month.fulfilled, (state) => {
      state.ordersLoaded = false;
    });

    builder.addCase(fetchPickOrderAsync.fulfilled, (state, { payload }) => {
      state.ordersLoaded = false;
    });
    builder.addCase(fetchUnPickOrderAsync.fulfilled, (state) => {
      state.ordersLoaded = false;
    });
    builder.addCase(fetchCatalogProducts.fulfilled, (state) => {
      state.ordersLoaded = false;
    });
    builder.addCase(
      fetchCompanyDetailsAsync.fulfilled,
      (state, action: PayloadAction<ICompanyDetailsResponseDTO>) => {
        state.companyDetails = action.payload?.results;
      },
    );
    builder.addCase(fetchCompanyDetailsAsync.rejected, (state, { error }) => {
      state.error = {
        ...httpErrorHandler(error),
        url: config.api + "/users/companies/",
      };
    });
  },
});

export default ordersSlice.reducer;

export const ordersSelectors = (state: RootState): OrdersState => state.orders;

export const {
  setPageNumber,
  setRowsLimit,
  resetOrderCreatedGte,
  resetOrderCreatedLte,
  resetOrderDeliveredGte,
  resetOrderDeliveredLte,
  setOrdersParams,
  resetOrderCreated,
  resetOrderDelivered,
  resetAllFilters,
  setInternalOrderStatus,
  resetInternalOrderStatus,
  pickStateUpdate,
  setErrorMessage,
} = ordersSlice.actions;
