export const dataString = {
  headerText: "Choose company to edit",
  searchPlaceholder: "Search in companies name",
  saveBtn: "Save",
  main: "Main",
  companyName: "Title:",
  companyAbbrev: "Shortening",
  companyVatNumber: "VAT registration number or Org no:",
  addresses: "Addresses",
  companyStreetAddress1: "Streetaddress 1:",
  companyStreetAddress2: "Streetaddress 2:",
  companyPostcode: "Postnumber:",
  companyCity: "City:",
  companyState: "State:",
  companyCountry: "Country:",
  contact: "Contact and more",
  companyEmailAddress: "Emailaddress: user@example.com",
  companyPhonenumber: "Telephonenumber:",
  companyHomepage: "URL to the Website:",
  companyFacebook: "URL to the Facebook:",
  companyLinkedin: "URL to the Linkedin:",
  companyYoutube: "URL to the Youtube:",
  companyInstagram: "URL to the Instagram:",
  urls: "URLs",
  urlSearch: "URL Search:",
  helperTextforurlSearch:
    "Add search URL, will be used in conjunction with SKU to be enable direct access if URL is not available on product level. Put the string >>SEARCH<< where the search-team should be. This will be replaced with the SKU.",
  urlAdminDirect: "URL Admin Direct:",
  helperTextforurlAdminDirect:
    "If the ecommerce platform supports direct access to products in admin page, enter the URL here. We will assume that this utilizes the field External Database ID are reference. Where that goes in the URL, replace that with >>EXT_DB_ID<<. ",
  urlAdminSearch: "URL Admin Search:",
  helperTextforurlAdminSearch:
    "Add search URL for Admin, will be used in conjunction with SKU to enable direct access if URL is not  available on product level. Put the string >>SEARCH<< where the search-team should be. This will be replaced with the SKU.",
  packing: "Packing slip and more",
  packingSlipPriceConfiguration: "Packing slip prices configuration:",
  helperTextforpackingSlipPriceConfiguration:
    "Gives the possibility to configure prices (or not) on packing slips. Allowed values are: no_prices, excl_vat, incl_vat",
  packingSlipExtraContent1: "Delivery note extra information 1:",
  helperTextforpackingSlipExtraContent1:
    "Optional text placed after the order lines on the packing slip",
  packingSlipExtraContent2: "Delivery note extra information 2:",
  helperTextforpackingSlipExtraContent2:
    "Optional text placed after the order lines on the packing slip",
  packingSlipFooterRight: "Delivary note text in the footer add Right:",
  branding: "Branding",
  brandingLogo: "Logo:",
  brandingFontColor: "Color of text in headings:",
  helperTextforbrandingFontColor: "HTML color codes, e.g.'red' or '#1010aa'",
  brandingBackgroundColor: "Background color:",
  helperTextforbrandingBackgroundColor: "HTML color codes, e.g.red or #1010aa",
  logoSquare: "Logotype Squarish:",
  helperTextforlogoSquare: "Use this to upload logotype which is squarish",
  logoSquareImg: "Logo square img:",
  logoRectangle: "Logotype Rectangular:",
  helperTextforlogoRectangle:
    "Use this to upload logotype which is rectangular",
  logoRectangleImg: "Logo square img:",
  pric: "Pricing",
  priceRoundingPreference: "Price rounding preference:",
  priceInclExclVat: "VAT incl/excl preference:",
  helperTextforpriceInclExclVat:
    "Specify if import/export to e-commerce platform shall exclude or include VAT.",
  displayPriceInclExclVat: "Display prices incl/excl VAT:",
  helperTextfordisplayPriceInclExclVat:
    "Specify if prices should be displayed including or excluding VAT.",
  exchangeRateAdjustment: "Adjustment of exchange rates:",
  helperTextforexchangeRateAdjustment:
    "Provides the ability to adjust the exchange rates retrieved from Forex. If you the value at 0, no adjustment made. -1 will decrease the exchange rate by 1% and will increase the exchange rate by 1%",
  priceNoOfDecimalsInExport: " Price Number of decimals in Export for prices:",
  helperTextforpriceNoOfDecimalsInExport: "Used when expoting to CSV.",
  priceCommaSign: "Price Comma Sign",
  helperTextforpriceCommaSign: "Used when expoting to CSV.",
  uid: "UUID and more",
  uuid: "Unique ID(UUID):",
  helperTextforuuid:
    "Used in e.g. file paths of guarantee that files cannot be access by other users",
  mediaStoragePath: "Path to media storage:",
  helperTextformediaStoragePath:
    "Automatically set upon creation of the company.",
  createdAt: "Created:",
  helperTextforcreatedAt: "Timeestamp when instance was created",
  updatedAt: "Updated:",
  helperTextforupdatedAt: "Timeestamp when instance was updated",
  other: "Other",
  skuSequenceNumber: "Product (SKU) Sequence Number:",
  helperTextforskuSequenceNumber:
    "If you want Ecombooster to keep track of your internal SKU, this will be the starting point. The number you specify here will be used as the first sequential number and then automatically incremented for every product that you create. You can use the fields SKU Perfix and SKU Suffix if you want to combine this sequential number with other text, e.g.ABC-100001-DEF. NOTE! Usage of this field can be overridden by setting on Supplier level!",
  skuPrefix: "Product (SKU) Perfix:",
  helperTextforskuPrefix:
    "Will be prepended to the SKU Sequence Number. If you want a separator,you add taht as well to the perfix field. For example, perfix 'ABC-' and SKU sequence number 100001 will result in: ABC-100001.",
  skuSuffix: "Product (SKU) Suffix:",
  helperTextforskuSuffix:
    "Will be prepended to the SKU Sequence Number. If you want a separator,you add taht as well to the suffix field. For example, suffix '/DEF' and SKU sequence number 100001 will result in: 100001/DEF.",
  customProductSequenceNumber: "Custom Product Sequence Number:",
  helperTextforcustomProductSequenceNumber:
    "This is used for some companies when they need more than one internal SKU for their oroducts. NOTE! This will only be actovated when there is a need for it.",
  customProductSequenceNumberPrefix: "Custom Product Sequence Perfix:",
  helperTextforcustomProductSequenceNumberPrefix:
    "Will be prepended to the Custom Sequence Number. If you want a separator,you add taht as well to the perfix field. For example, perfix 'ABC-' and Custom sequence number 100001 will result in: ABC-100001.",
  customProductSequenceNumberSuffix: "Custom Product Sequence Suffix:",
  helperTextforcustomProductSequenceNumberSuffix:
    "Will be prepended to the Custom Sequence Number. If you want a separator,you add taht as well to the suffix field. For example, suffix '/DEF' and Custom sequence number 100001 will result in: 100001/DEF.",
  urlError: "URL Format is NOT Correct",
  ErrorInSave: "Please check the fieldes.",
  comingSoon: "Coming Soon",
};
