import { GridColDef } from "@mui/x-data-grid";

const ColumnsForDataGrid = (): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name of markup rule",
      flex: 1,
      sortable: true,
    },
    {
      field: "priority",
      flex: 1,
      headerName: "Priority",
      sortable: false,
    },

    {
      field: "markup_attribute",
      flex: 1,
      headerName: "Markup Attribute",
      sortable: false,
    },
    {
      field: "value_gte",
      flex: 1,
      headerName: "Markup Applies >=",
      sortable: false,
    },
    {
      field: "value_lt",
      flex: 1,
      headerName: "Markup Applies",
      sortable: false,
    },
    {
      field: "markup_type",
      flex: 1,
      headerName: "Markup Type",
      sortable: false,
    },
    {
      field: "markup_value",
      flex: 1,
      headerName: "Markup Value",
      sortable: false,
    },
    {
      field: "comments",
      flex: 1,
      headerName: "Comments",
      sortable: false,
    },
  ];

  return columns;
};

export default ColumnsForDataGrid;
