import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router";
import LoginPage from "pages/login-page";
import MainPage from "pages/main-page";
import OverviewPage from "pages/main-page/overview-page";
import OnboardingPage from "pages/onboarding-page";
import ProcurementsAndSuppliersPage from "pages/main-page/procurements-suppliers-page";
import Register from "pages/onboarding-page/parts/Register";
import OnboardingStart from "pages/onboarding-page/parts/OnboardingStart";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { fetchUserData, refreshTokens } from "store/auth/thunks";
import { selectAuthenticatedUser } from "store/auth";
import token from "services/auth/tokenService";
import {
  clearSnackbar,
  selectIsSnackbarOpen,
  selectSnackbarAction,
  selectSnackbarMessage,
} from "store/snackbar";
import {
  GLOBAL_SUPPLIERS_AND_BRANDS,
  CATALOG_PRODUCTS,
  FORWARD_SLASH,
  LOGIN,
  ONBOARDING,
  PROCUREMENTS_AND_SUPPLIERS_PRODUCTS,
  SUB_ROUTE_GLOBAL_SUPPLIERS_AND_BRANDS,
  SUB_ROUTE_CATALOG,
  SUB_ROUTE_CATALOG_PRODUCTS,
  SUB_ROUTE_EDIT_CATALOG_PRODUCTS,
  SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS,
  SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_PRODUCTS,
  SUB_ROUTE_REGISTER,
  SUB_ROUTE_STOCK_TAKING,
  SUB_ROUTE_CUSTOMERS,
  SUB_ROUTE_CUSTOMERS_PICK_AND_PACK,
  CUSTOMERS_PICK_AND_PACK,
  SUB_ROUTE_PROCUREMENT_AND_SUPPLIER,
  SUB_ROUTE_PROCUREMENT_AND_SUPPLIER_FEED,
  SUB_ROUTE_PROCUREMENT,
  PROCUREMENT_AND_SUPPLIER,
  SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES,
  SUB_ROUTE_EDIT_CUSTOMERS_ORDER,
  SUB_ROUTE_CUSTOMERS_ORDERS,
  SUB_ROUTE_EDIT_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES,
  SUB_ROUTE_ADD_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES,
  SUB_ROUTE_CATALOG_PRODUCTS_IMAGES,
  SUB_ROUTE_CATEGORY,
  SUB_ROUTE_PROCUREMENT_AND_ORDERS,
  SUB_ROUTE_CATEGORY_MARKUP,
  SUB_ROUTE_PROCUREMENT_IMAGE_URLS,
  COMPANIES,
  COMPANY_PREFERENCES,
  SUB_ROUTE_MISCELLANEOUS_FACEBOOK_PRODUCT_TAXONOMIES,
  SUB_ROUTE_MISCELLANEOUS_GOOGLE_PRODUCT_TAXONOMIES,
  SUB_ROUTE_PREFERENCES_FACEBOOK_PRODUCT_TAXONOMIES_SELECTION,
  SUB_ROUTE_INVENTORY_TRANSACTIONS,
  SUB_ROUTE_CATALOG_INVENTORY_LOCATIONS,
  SUB_ROUTE_INVENTORYLOCATIONTYPE,
  SUB_ROUTE_MISCELLANEOUS_Notifcation,
  SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_FILE_URLS,
  SUB_ROUTE_BULK3_DESCRIPTION,
} from "constants/urls";
import SimpleSnackbar from "components/SimpleSnackbar";
import CatalogPage from "pages/main-page/catalog-page";
import EditCatalogProductPage from "pages/main-page/edit-catalog-product-page";
import Login from "pages/login-page/parts/Login";
import ResetPassword from "pages/login-page/parts/ResetPassword";
import NewPassword from "pages/login-page/parts/NewPassword";
import StockTakingPage from "pages/main-page/stock-taking-page";
import PickAndPackByOrderPage from "pages/main-page/pick-pack-order-page";
import Supplier from "pages/main-page/procurement-supplierList-page";
import ProcurementCategoriesPage from "pages/main-page/procurement-categories-page";
import EditCustomersOrderPage from "pages/main-page/edit-customers-order-page";
import CustomersOrdersPage from "pages/main-page/customers-orders";
import AddProcurementCategoryPage from "pages/main-page/add-edit-procurement-category-page/add-procurement-category-page";
import EditProcurementCategoryPage from "pages/main-page/add-edit-procurement-category-page/edit-procurement-category-page";
import CatalogImagesPage from "pages/main-page/catalog-images";
import Category from "pages/main-page/catalog-category";
import PageNotFoundComponent from "components/PageNotFound";
import Orders from "pages/main-page/procurement-orders-page";
import Markup from "pages/main-page/catalag-markup";
import ProcurementsAndSupplierFeedsPage from "pages/main-page/procurement-supplier-feeds";
import ProcurementImages from "pages/main-page/procurement_images";
import Companies from "pages/main-page/procurements-companies-page";
import Taxonomies from "pages/main-page/taxonomies";
import FacebookProductTaxonomies from "pages/main-page/procurements-facebook-page";
import FacebookProductTaxonomiesSelection from "pages/main-page/procurement-facebook-selection";
import InventoryTransactionPage from "pages/main-page/inventory-transactions";
import InventoryLocationType from "pages/main-page/catalog-inventorylocationtype";
import InventoryLocations from "pages/main-page/catalog-inventory-locations";
import NotificationsSystem from "pages/main-page/notification-system/part/index";
import ProductCatalogFileUrlsPage from "pages/main-page/catalog-file-urls";
import { Box, CircularProgress } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import CatalogBulkDescription from "pages/main-page/catalog-bulk-description";

const App = (): JSX.Element => {
  const [initialLoad, setInitialLoad] = useState(true);
  const user = useAppSelector(selectAuthenticatedUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const snackbarOpen = useAppSelector(selectIsSnackbarOpen);
  const snackbarMessage = useAppSelector(selectSnackbarMessage);
  const snackbarAction = useAppSelector(selectSnackbarAction);

  const MUIkey =
    "2d6fc005701a1418eaa43c7d8313db82Tz0xMDE1NzQsRT0xNzYyOTM3NTY2MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=";
  LicenseInfo.setLicenseKey(MUIkey.toString());

  useEffect(() => {
    const fetchUser = async () => {
      try {
        await dispatch(refreshTokens(token.service().getRefreshToken()));
        await dispatch(fetchUserData());
      } catch {
        if (!location.pathname.startsWith("/login")) {
          navigate("/onboarding");
        }
      }
      setInitialLoad(false);
    };
    fetchUser();
  }, [dispatch]);

  if (initialLoad) {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <CircularProgress size={64} />
      </div>
    );
  }

  return (
    <Box>
      <Routes>
        <Route
          path={FORWARD_SLASH}
          element={user ? <MainPage /> : <Navigate to={ONBOARDING} replace />}
        >
          {/* Overview - page */}
          <Route index element={<OverviewPage />} />

          {/* My Product Catalog - page */}
          <Route
            path={SUB_ROUTE_CATALOG}
            element={<Navigate to={CATALOG_PRODUCTS} replace />}
          />
          <Route path={SUB_ROUTE_CATALOG_PRODUCTS} element={<CatalogPage />} />
          <Route
            path={SUB_ROUTE_EDIT_CATALOG_PRODUCTS}
            element={<EditCatalogProductPage />}
          />
          <Route
            path={SUB_ROUTE_BULK3_DESCRIPTION}
            element={<CatalogBulkDescription />}
          />
          <Route path={SUB_ROUTE_STOCK_TAKING} element={<StockTakingPage />} />
          <Route path={SUB_ROUTE_CATEGORY} element={<Category />} />
          <Route path={SUB_ROUTE_CATEGORY_MARKUP} element={<Markup />} />
          <Route
            path={SUB_ROUTE_MISCELLANEOUS_GOOGLE_PRODUCT_TAXONOMIES}
            element={<Taxonomies />}
          />
          <Route path={SUB_ROUTE_CATEGORY} element={<Category />} />
          <Route path={SUB_ROUTE_CATEGORY_MARKUP} element={<Markup />} />
          <Route
            path={SUB_ROUTE_MISCELLANEOUS_GOOGLE_PRODUCT_TAXONOMIES}
            element={<Taxonomies />}
          />
          <Route
            path={SUB_ROUTE_CATALOG_INVENTORY_LOCATIONS}
            element={<InventoryLocations />}
          />

          {/* My Suppliers - page */}
          <Route
            path={SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS}
            element={
              <Navigate to={PROCUREMENTS_AND_SUPPLIERS_PRODUCTS} replace />
            }
          />
          <Route
            path={SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES}
            element={<ProcurementCategoriesPage />}
          />
          <Route
            path={SUB_ROUTE_EDIT_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES}
            element={<EditProcurementCategoryPage />}
          />
          <Route
            path={SUB_ROUTE_ADD_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES}
            element={<AddProcurementCategoryPage />}
          />

          <Route
            path={SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_PRODUCTS}
            element={<ProcurementsAndSuppliersPage />}
          />
          <Route
            path={SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_FILE_URLS}
            element={<ProductCatalogFileUrlsPage />}
          />

          {/* Customers - page */}
          <Route
            path={SUB_ROUTE_CATALOG}
            element={<Navigate to={CATALOG_PRODUCTS} replace />}
          />
          <Route path={SUB_ROUTE_CATALOG_PRODUCTS} element={<CatalogPage />} />
          <Route
            path={SUB_ROUTE_EDIT_CATALOG_PRODUCTS}
            element={<EditCatalogProductPage />}
          />
          <Route
            path={SUB_ROUTE_CATALOG_PRODUCTS_IMAGES}
            element={<CatalogImagesPage />}
          />
          <Route
            path={SUB_ROUTE_PROCUREMENT_IMAGE_URLS}
            element={<ProcurementImages />}
          />
          <Route
            path={SUB_ROUTE_INVENTORY_TRANSACTIONS}
            element={<InventoryTransactionPage />}
          />
          <Route
            path={SUB_ROUTE_INVENTORYLOCATIONTYPE}
            element={<InventoryLocationType />}
          />
          <Route
            path={SUB_ROUTE_GLOBAL_SUPPLIERS_AND_BRANDS}
            element={<Navigate to={GLOBAL_SUPPLIERS_AND_BRANDS} replace />}
          />
          <Route
            path={SUB_ROUTE_CUSTOMERS}
            element={<Navigate to={CUSTOMERS_PICK_AND_PACK} replace />}
          />
          <Route
            path={SUB_ROUTE_CUSTOMERS_PICK_AND_PACK}
            element={<PickAndPackByOrderPage />}
          />

          {/* Companies - page */}
          <Route path={COMPANIES} element={<Companies />} />
          <Route path={COMPANY_PREFERENCES} element={<Companies />} />

          {/* Users - page */}

          {/* Preferences - page */}

          <Route
            path={SUB_ROUTE_PREFERENCES_FACEBOOK_PRODUCT_TAXONOMIES_SELECTION}
            element={<FacebookProductTaxonomiesSelection />}
          />

          {/* Miscellaneous - page */}
          <Route
            path={SUB_ROUTE_MISCELLANEOUS_FACEBOOK_PRODUCT_TAXONOMIES}
            element={<FacebookProductTaxonomies />}
          />
          <Route
            path={SUB_ROUTE_MISCELLANEOUS_Notifcation}
            element={<NotificationsSystem />}
          />

          {/* All Suppliers & Brands - page */}
          <Route
            path={SUB_ROUTE_GLOBAL_SUPPLIERS_AND_BRANDS}
            element={<Navigate to={GLOBAL_SUPPLIERS_AND_BRANDS} replace />}
          />
          <Route
            path={SUB_ROUTE_CUSTOMERS_ORDERS}
            element={<CustomersOrdersPage />}
          />
          <Route
            path={SUB_ROUTE_EDIT_CUSTOMERS_ORDER}
            element={<EditCustomersOrderPage />}
          />
          <Route
            path={SUB_ROUTE_PROCUREMENT}
            element={<Navigate to={PROCUREMENT_AND_SUPPLIER} replace />}
          />
          <Route
            path={SUB_ROUTE_PROCUREMENT_AND_SUPPLIER}
            element={<Supplier />}
          />
          <Route path={SUB_ROUTE_PROCUREMENT_AND_ORDERS} element={<Orders />} />
          <Route
            path={SUB_ROUTE_PROCUREMENT_AND_SUPPLIER_FEED}
            element={<ProcurementsAndSupplierFeedsPage />}
          />
        </Route>

        <Route path={LOGIN} element={<LoginPage />}>
          <Route index element={<Login />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="new-password" element={<NewPassword />} />
        </Route>

        <Route path={ONBOARDING} element={<OnboardingPage />}>
          <Route index element={<OnboardingStart />} />
          <Route path={SUB_ROUTE_REGISTER} element={<Register />} />
        </Route>
        <Route path="*" element={<PageNotFoundComponent />} />
      </Routes>

      <SimpleSnackbar
        open={snackbarOpen!}
        toggleSnackbar={() => dispatch(clearSnackbar())}
        message={snackbarMessage}
        snackbarAction={snackbarAction}
      />
    </Box>
  );
};

export default App;
