import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";

export type LoadStatus = "idle" | "loading" | "done";

interface IProps extends ButtonProps {
  loadStatus: LoadStatus;
  isDisabled?: boolean;
}

const LoadingButton = ({
  loadStatus,
  isDisabled,
  children,
  ...props
}: IProps): JSX.Element => {
  return (
    <Button
      {...props}
      disabled={isDisabled}
      sx={{
        ...props.sx,
        position: "relative",
      }}
    >
      {loadStatus === "idle" && (
        <Grow in={loadStatus === "idle"}>
          <div>{children}</div>
        </Grow>
      )}
      {loadStatus === "loading" && (
        <Box
          sx={{
            position: "absolute",
          }}
        >
          <CircularProgress
            size={25}
            sx={{
              color: "white",
            }}
          />
        </Box>
      )}
      {loadStatus === "done" && (
        <Grow in={loadStatus === "done"}>
          <DoneIcon sx={{ color: "white" }} />
        </Grow>
      )}
    </Button>
  );
};

export default LoadingButton;
