export const FORWARD_SLASH = "/";
export const LOGIN = "/login";
export const ONBOARDING = "/onboarding";
export const PROCUREMENTS_AND_SUPPLIERS_PRODUCTS =
  "/procurements-and-suppliers/products";
export const CATALOG_PRODUCTS = "/catalog/products";
export const GLOBAL_SUPPLIERS_AND_BRANDS =
  "/global-suppliers-and-brands/products";
export const CUSTOMERS_PICK_AND_PACK = "/customers/pick-and-pack";
export const PROCUREMENT_AND_SUPPLIER = "/procurement/supplier";
export const COMPANIES = "/companies";
export const COMPANY_PREFERENCES = "/company-Preferences";
export const MISCELLANEOUS = "/miscellaneous";

export const SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS =
  "procurements-and-suppliers";
export const SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_PRODUCTS =
  "procurements-and-suppliers/products";
export const SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES =
  "procurements-and-suppliers/categories";
export const SUB_ROUTE_EDIT_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES =
  "procurements-and-suppliers/categories/edit";
export const SUB_ROUTE_ADD_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES =
  "procurements-and-suppliers/categories/add";
export const SUB_ROUTE_CATALOG = "catalog";
export const SUB_ROUTE_CATALOG_PRODUCTS = "catalog/products";
export const SUB_ROUTE_EDIT_CATALOG_PRODUCTS = "catalog/products/edit";
export const SUB_ROUTE_BULK3_DESCRIPTION = "catalog/product-bulk3-description";
export const SUB_ROUTE_GLOBAL_SUPPLIERS_AND_BRANDS =
  "global-suppliers-and-brands";
export const SUB_ROUTE_GLOBAL_SUPPLIERS_AND_BRANDS_PRODUCTS =
  "global-suppliers-and-brands/products";
export const SUB_ROUTE_PRODUCTS = "products";
export const SUB_ROUTE_REGISTER = "register";
export const SUB_ROUTE_STOCK_TAKING = "catalog/stocktaking";
export const SUB_ROUTE_CUSTOMERS = "customers";
export const SUB_ROUTE_PROCUREMENT = "procurement";
export const SUB_ROUTE_CUSTOMERS_PICK_AND_PACK = "customers/pick-and-pack";
export const SUB_ROUTE_CUSTOMERS_ORDERS = "customers/orders";
export const SUB_ROUTE_EDIT_CUSTOMERS_ORDER = "customers/orders/edit";
export const SUB_ROUTE_PROCUREMENT_AND_SUPPLIER = "procurement/supplier";
export const SUB_ROUTE_PROCUREMENT_AND_SUPPLIER_FEED =
  "procurement/supplier-feed";
export const SUB_ROUTE_CATALOG_PRODUCTS_IMAGES = "catalog/images";
export const SUB_ROUTE_CATEGORY = "catalog/category";
export const SUB_ROUTE_CATEGORY_MARKUP = "catalog/markup";
export const SUB_ROUTE_INVENTORYLOCATIONTYPE = "catalog/inventory";
export const SUB_ROUTE_PROCUREMENT_AND_ORDERS = "procurement/orders";
export const SUB_ROUTE_PROCUREMENT_IMAGE_URLS = "procurement/images";
export const SUB_ROUTE_PREFERENCES_FACEBOOK_PRODUCT_TAXONOMIES_SELECTION =
  "preferences/facebook-product-taxonomies-selection";
export const SUB_ROUTE_MISCELLANEOUS_FACEBOOK_PRODUCT_TAXONOMIES =
  "miscellaneous/facebook-product-taxonomies";
export const SUB_ROUTE_MISCELLANEOUS_GOOGLE_PRODUCT_TAXONOMIES =
  "miscellaneous/google-product-taxonomies";
export const SUB_ROUTE_INVENTORY_TRANSACTIONS = "catalog/inventorytransactions";
export const SUB_ROUTE_CATALOG_INVENTORY_LOCATIONS =
  "catalog/inventory-locations";
export const SUB_ROUTE_MISCELLANEOUS_Notifcation = "notification";
export const SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_FILE_URLS =
  "catalog/file-urls";
export const USERS = "/users";
