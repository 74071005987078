import React from "react";
import {
  TextField,
  Box,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Checkbox,
  Divider,
} from "@mui/material";
import { useState } from "react";
import useAppDispatch from "hooks/useAppDispatch";
import { addCatalogInventoryLocationTypeAsync } from "store/catalog-inventorylocationtype/thunks";

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const AddInventoryLocationType = ({
  isOpen,
  handleClose,
}: ModalProps): JSX.Element => {
  const [type, setType] = useState<string>("");
  const [linebreak, setLinebreak] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value);
  };

  const handleLineBreak = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLinebreak(event.target.checked);
  };

  const handleAdd = async () => {
    await dispatch(
      addCatalogInventoryLocationTypeAsync({
        type,
        label_linebreak: linebreak,
      }),
    );
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginBottom: 5 }}
        >
          <Typography variant="h5" fontWeight="bold">
            Add Inventory Location Type
          </Typography>
        </Box>
        <Box mt={2} justifyContent="center" alignItems="center">
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "5px",
            }}
          >
            <Box>
              Name of Inventory Location Type:
              <TextField
                label="Type"
                variant="outlined"
                value={type}
                size="small"
                onChange={handleTypeChange}
                sx={{
                  padding: "5px",
                }}
              />
              <FormHelperText>
                E.g. City, Warehouse, Room, Shelf...
              </FormHelperText>
            </Box>
            <br />
            <Divider />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "5px",
              }}
            >
              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={linebreak}
                        onChange={handleLineBreak}
                      />
                    }
                    label="Linebreak"
                  />
                </FormGroup>
                <FormHelperText>
                  If set to True, a line break will be inserted after this
                  entity in the slug.
                </FormHelperText>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleAdd} color="primary">
          Add
        </Button>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddInventoryLocationType;
