import React from "react";
import { useCallback, useMemo } from "react";

import { nextStep, previousStep, selectCurrentStep } from "store/onboarding";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { useNavigate } from "react-router-dom";
import { createAccount } from "store/onboarding/thunks";
import { displaySnackbar } from "store/snackbar";
import Footer from "./Footer";

interface IFooterProps {
  validate?(): boolean;
  previous: string;
  next: string;
}

const HandledFooter = ({
  validate,
  previous,
  next,
}: IFooterProps): JSX.Element => {
  const currentStep = useAppSelector(selectCurrentStep);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const lastInteractiveStep = useMemo(() => currentStep === 1, [currentStep]);

  const handleNextStep = useCallback(async () => {
    if (validate === undefined || validate()) {
      if (lastInteractiveStep) {
        try {
          await dispatch(createAccount());
        } catch (e) {
          const err = e as { message: string };
          dispatch(
            displaySnackbar({
              message: err.message,
              action: null,
              open: false,
            }),
          );
          return;
        }
      }
      dispatch(nextStep());
    }
  }, [dispatch, lastInteractiveStep, validate]);

  const handlePreviousStep = useCallback(() => {
    if (currentStep === 0) {
      navigate("/onboarding");
    } else {
      dispatch(previousStep());
    }
  }, [currentStep, dispatch, navigate]);

  return (
    <Footer
      previous={{
        name: previous,
        handler: handlePreviousStep,
      }}
      next={{
        name: next,
        handler: handleNextStep,
      }}
      steps={3}
      currentStep={currentStep}
    />
  );
};

export default HandledFooter;
