import React from "react";
import { TextFieldProps } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import UpdateInventoryLocation from "./stock-views/UpdateInventoryLocation";
import UpdateRestock from "./stock-views/UpdateRestock";
import UpdatePackageDimension from "./stock-views/UpdatePackageDimension";
import UpdateDefaultLocation from "./stock-views/UpdateDefaultLocation";

export const textFieldProps: TextFieldProps = {
  fullWidth: true,
  margin: "normal",
  size: "small",
  variant: "outlined",
};

const ColumnsForStocks = (): GridColDef[] => {
  const stockColumns: GridColDef[] = [
    {
      field: "sku",
      flex: 0.5,
      headerName: "SKU",
      sortable: true,
      pinnable: true,
    },
    {
      field: "name",
      flex: 1,
      headerName: "Product name",
      sortable: true,
      pinnable: true,
    },
    {
      field: "location",
      flex: 1,
      headerName: "Inventory",
      pinnable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.id ? (
          <UpdateInventoryLocation id={params.row.id} />
        ) : (
          "id not found"
        ),
    },

    {
      field: "packaging_dimension",
      flex: 1,
      headerName: "Packaging Dimension",
      pinnable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.packagingDimension ? (
          <UpdatePackageDimension
            id={params.row.id}
            length={params.row.packagingDimension.length}
            width={params.row.packagingDimension.width}
            height={params.row.packagingDimension.height}
            weight={params.row.packagingDimension.weight}
          />
        ) : (
          ""
        ),
    },
    {
      field: "default_location_id",
      flex: 1,
      headerName: "Default location",
      pinnable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.default_location_id ? (
          <UpdateDefaultLocation
            location={params.row.default_location_id}
            id={params.row.id}
          />
        ) : (
          ""
        ),
    },
    {
      field: "restock_level",
      flex: 0.5,
      headerName: "Restock level/ Quantity",
      pinnable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.restock ? (
          <UpdateRestock
            id={params.row.id}
            level={params.row.restock.level}
            quantity={params.row.restock.quantity}
          />
        ) : (
          ""
        ),
    },
  ];

  return stockColumns;
};

export default ColumnsForStocks;
