import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import theme from "theme/theme";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const CommonModal = ({ isOpen, onClose, title, children }: Props) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: "60vw",
          margin: `${theme.spacing(5)} auto`,
          bgcolor: theme.palette.background.paper,
          borderRadius: theme.shape.borderRadius,
          boxShadow: theme.shadows[24],
          padding: theme.spacing(2),
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          id="modal-modal-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
          <CloseIcon
            sx={{ fontSize: "18", cursor: "pointer" }}
            onClick={onClose}
          />
        </Box>
        <Box id="modal-modal-description">{children}</Box>
      </Box>
    </Modal>
  );
};

export default CommonModal;
