import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { IBackOrder } from "./interfaces";
import {
  addBackOrderAsync,
  fetchBackOrderAsync,
  removeBackOrderAsync,
} from "./thunks";

type IBackorderState = IBackOrder & {
  successMessage: string;
  loading: boolean;
  error: string;
};

const initialState: IBackorderState = {
  backOrderLoaded: false,
  count: 0,
  next: "",
  previous: "",
  results: [],
  successMessage: "",
  loading: false,
  error: "",
};

export const backOrderSlice = createSlice({
  name: "backorders",
  initialState,
  reducers: {
    clearBackorderSuccessMessage: (state) => {
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBackOrderAsync.fulfilled, (state, action) => {
      state.backOrderLoaded = true;
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results;
    });
    builder.addCase(fetchBackOrderAsync.pending, (state) => {
      state.backOrderLoaded = false;
    });
    builder.addCase(fetchBackOrderAsync.rejected, (state, { error }) => {
      state.backOrderLoaded = false;
      if (error.message) state.error = error.message;
    });
    builder.addCase(addBackOrderAsync.fulfilled, (state) => {
      state.backOrderLoaded = false;
      state.successMessage = "Reserved is done successfully";
    });
    builder.addCase(removeBackOrderAsync.fulfilled, (state) => {
      state.backOrderLoaded = false;
      state.successMessage = "Removed successfully";
      state.loading = false;
    });
    builder.addCase(removeBackOrderAsync.pending, (state) => {
      state.backOrderLoaded = false;
      state.successMessage = "Removed successfully";
      state.loading = true;
    });
  },
});

export const selectBackorderState = (state: RootState): IBackorderState =>
  state.backOrder;
export const { clearBackorderSuccessMessage } = backOrderSlice.actions;

export default backOrderSlice.reducer;
