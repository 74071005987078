import React from "react";
import useAppSelector from "hooks/useAppSelector";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { selectCatalogCategoriesState } from "store/catalog-categories";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { IUpdateProcurementCategoryDTO } from "store/procurement-categories/interfaces";
import { updateProcurementCategoryAsync } from "store/procurement-categories/thunks";
import useAppDispatch from "hooks/useAppDispatch";
import { ICatalogCategory } from "store/catalog-categories/interfaces";

const ColumnsForDataGrid = (): GridColDef[] => {
  const { categories } = useAppSelector(selectCatalogCategoriesState);
  const dispatch = useAppDispatch();

  const columns: GridColDef[] = [
    {
      field: "slug",
      flex: 1,
      headerName: "Category Slug",
      sortable: true,
    },
    {
      field: "catalog_category_mapping",
      flex: 1,
      headerName: "Catalog Category Mapping",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const filterAutoCreate = () => {
          const filtered = [];
          for (const category of categories) {
            for (const el of autoCreateArr) {
              if (category.id === el) {
                filtered.push(category);
              }
            }
          }
          return filtered;
        };
        const autoCreateArr = params.row["catalog_categories_auto_create"];
        const filtered1 = (categories.length > 0 &&
          !!autoCreateArr &&
          autoCreateArr.length > 0 &&
          filterAutoCreate()) as ICatalogCategory[];

        const handleSelection = (event: any, newValue: any) => {
          const objectTobeUpdated: IUpdateProcurementCategoryDTO = {
            ...params.row,
            catalog_categories_auto_create: newValue,
          };
          dispatch(
            updateProcurementCategoryAsync({
              id: params.row.id,
              object: objectTobeUpdated,
            }),
          )
            .unwrap()
            .finally(() => {});
        };
        return (
          <Autocomplete
            multiple
            fullWidth
            id="tags-standard"
            options={categories}
            onChange={handleSelection}
            getOptionLabel={(option: any) => option.slug}
            defaultValue={filtered1.length > 0 ? filtered1 : []}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
          />
        );
      },
    },
    {
      field: "url",
      flex: 1,
      headerName: "URL",
      sortable: false,
    },
    {
      field: "no_products",
      flex: 0.5,
      headerName: "#Products",
      sortable: false,
      // renderCell: (params: GridRenderCellParams) => (
      //   <Typography fontSize={13}>
      //     {!params.row && !params.row.products && params.row.products.length}
      //   </Typography>
      // ),
    },
  ];

  return columns;
};

export default ColumnsForDataGrid;
