import React from "react";
import FilterSelectBinary from "components/filter-products/FilterSelectBinary";

const AvailableFilters = (): JSX.Element => {
  return (
    <>
      <FilterSelectBinary label="Obsolete" param="obsolete" />
    </>
  );
};

export default AvailableFilters;
