import React from "react";
import DoubleControlSlider from "components/filter-products/DoubleControlSlider";
import FilterSelectMultiple from "components/filter-products/FilterSelectMultiple";
import useAppSelector from "hooks/useAppSelector";
import { selectImageClassificationState } from "store/image-classifications";

const AvailableFilters = (): JSX.Element => {
  const imageClassificationOptions: [string, string][] = useAppSelector(
    selectImageClassificationState,
  ).imageClassifications?.map((imageClassification) => [
    imageClassification.title,
    imageClassification.id.toString(),
  ]);

  return (
    <>
      <DoubleControlSlider
        label="Size (KB)"
        param={"image_kb"}
        min={0}
        max={1024}
      />
      <DoubleControlSlider
        label="Width (px)"
        param={"image_width"}
        min={0}
        max={2500}
      />
      <DoubleControlSlider
        label="Height (px)"
        param={"image_height"}
        min={0}
        max={2500}
      />
      <FilterSelectMultiple
        label="Classification"
        param="classification__in"
        options={imageClassificationOptions}
      />
    </>
  );
};

export default AvailableFilters;
