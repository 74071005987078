import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash-es";
import { GridRowId } from "@mui/x-data-grid";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import ColumnsForDataGrid from "./parts/ColumnsForDataGrid";
import { selectImagesState, setImagesParams } from "store/image-libraries";
import { fetchImagesAsync } from "store/image-libraries/thunks";
import Filter from "components/filter-products/Filter";
import { selectImageClassificationState } from "store/image-classifications";
import { fetchImageClassificationsAsync } from "store/image-classifications/thunks";
import ErrorPage from "components/ErrorComponent";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import GridViewLayout from "./parts/GridViewLayout";
import MultiFileUploadComponent from "./parts/MultiFileUpload";
import Searchbar from "components/filter-products/Searchbar";
import AvailableFilters from "./parts/AvailableFilters";
import CustomPagination from "components/pagination/CustomPagination";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import theme from "theme/theme";
import useBreakpoint from "hooks/useBreakpoint";
import GridContainer from "components/common-components/GridContainer";
import { DataGridPro } from "@mui/x-data-grid-pro";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const CatalogImagesPage = (): JSX.Element => {
  const [view, setView] = useState("list");
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [selectionModel] = useState<GridRowId[]>([]);
  const isMediumScreen = useBreakpoint("medium");
  const { images, imagesLoaded, count, error } =
    useAppSelector(selectImagesState);
  const { imageClassificationsLoaded, imageClassifications } = useAppSelector(
    selectImageClassificationState,
  );
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    !imageClassificationsLoaded && dispatch(fetchImageClassificationsAsync());
  }, [imageClassificationsLoaded, dispatch]);

  useEffect(() => {
    dispatch(fetchImagesAsync(searchParams));
  }, [dispatch, searchParams]);

  const handleChange = (event: any, nextView: any) => {
    setView(nextView);
  };

  if (!isEmpty(error)) {
    return <ErrorPage {...error} />;
  }

  const handleClose = () => {
    setModalOpen(false);
  };

  const searchHandler = (value: string) => {
    dispatch(setImagesParams({ search: value, page: 0 }));
  };

  return (
    <>
      {modalOpen && (
        <MultiFileUploadComponent
          isOpen={modalOpen}
          handleClose={handleClose}
        />
      )}
      <Box sx={{ display: "flex", gap: 1 }}>
        <Searchbar placeholder="Search" onChange={searchHandler} />

        <ToolboxArea>
          <ToolInfoTooltip title="Add new Image">
            <ToolIconContainer onClick={() => setModalOpen(true)}>
              <AddCircleIcon
                fontSize="large"
                sx={{
                  color: theme.palette.primary.main,
                  stroke: theme.palette.primary.main,
                  strokeWidth: 1,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>
        </ToolboxArea>

        <ToolInfoTooltip title="View Options">
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleChange}
            size="medium"
          >
            <ToggleButton value={"list"} aria-label="list">
              {view == "list" ? (
                <ViewListIcon
                  sx={{ fontSize: theme.typography.button.fontSize }}
                />
              ) : (
                <ViewModuleIcon />
              )}
            </ToggleButton>
          </ToggleButtonGroup>
        </ToolInfoTooltip>
      </Box>

      <Filter
        viewName="catalog_images"
        children={<AvailableFilters />}
        imageClassificationInfoRequired={true}
      />

      <>
        {view == "list" ? (
          <GridContainer>
            <DataGridPro
              loading={!imagesLoaded}
              rows={images}
              columns={ColumnsForDataGrid(imageClassifications)}
              rowHeight={90}
              columnHeaderHeight={40}
              disableColumnMenu
              checkboxSelection
              disableRowSelectionOnClick
              pagination
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
            />
          </GridContainer>
        ) : (
          <GridContainer>
            <GridViewLayout />
            <CustomPagination count={count} />
          </GridContainer>
        )}
      </>
    </>
  );
};

export default CatalogImagesPage;
