import React from "react";
import {
  Stack,
  Container,
  Typography,
  IconButton,
  Box,
  TextField,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import Modal from "components/ModalComponent";
import { textFieldProps } from "../ColumnsForDataGrid";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { selectCatalogState } from "store/catalog";
import { updateProducts } from "store/catalog/thunks";
import { displaySnackbar } from "store/snackbar";
import { ICatalogProduct } from "store/catalog/interfaces";

const UpdateRestock = ({
  level,
  quantity,
  id,
}: {
  level: number;
  quantity: number;
  id: number;
}): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [restockLevel, setRestockLevel] = useState<number>(level);
  const [restockQty, setRestockQty] = useState<number>(quantity);

  const dispatch = useAppDispatch();
  const { catalogProducts } = useAppSelector(selectCatalogState);

  const handleClickOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const foundProduct = catalogProducts.find((prod) => prod.id === id);
    const {
      sku,
      name,
      cost_shipping,
      price_manual_override,
      price_on_sale,
      description,
    } = foundProduct as ICatalogProduct;

    await dispatch(
      updateProducts({
        products: [
          {
            id,
            sku,
            name,
            cost_shipping,
            price_manual_override,
            price_on_sale,
            descriptions: [description],
            qty_restock_level: restockLevel,
            qty_restock_amount: restockQty,
          },
        ],
      }),
    )
      .unwrap()
      .finally(() => {
        setModalOpen(false);
        dispatch(
          displaySnackbar({
            message: "Product updated successfully",
            action: null,
            open: false,
          }),
        );
      });
  };

  return (
    <>
      <Stack direction="row" justifyContent="center">
        <Typography fontSize={13}>
          {level} / {quantity}
        </Typography>
        <IconButton name="Edit Restock quantit" onClick={handleClickOpen}>
          <EditIcon sx={{ fontSize: 13 }} />
        </IconButton>
      </Stack>
      <Modal
        title="Edit Restock quantity"
        isOpen={modalOpen}
        handleClose={handleClose}
      >
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              onSubmit={handleOnSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                label="Minimum stock level"
                type="number"
                id="level"
                value={restockLevel ?? 0}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => setRestockLevel(+e.target.value)}
                {...textFieldProps}
              />
              <TextField
                label="Restock quantity"
                type="number"
                id="quantity"
                value={restockQty}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => setRestockQty(+e.target.value)}
                {...textFieldProps}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Container>
      </Modal>
    </>
  );
};

export default UpdateRestock;
