import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import useAppSelector from "hooks/useAppSelector";
import { ordersSelectors } from "store/orders";
import { textFieldProps } from "pages/onboarding-page/shared";
import {
  orderDate,
  orderInfo,
  orderNotes,
  orderReference,
  shippingInfo,
} from "../utils";
import { formatDateTime } from "utils/time/formatDateTime";
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

export default function BasicInfoList(): JSX.Element {
  const [editAble, setEditAble] = useState(false);
  const [editCustomersOrder, setEditCustomersOrder] = useState({
    orderNumber: "",
    orderNotes: "",
    orderStatus: "",
    shippingMethod: "",
    shippingTrackingNumber: "",
    shippingTrackingLink: "",
    url: "",
    internalOrderStatus: "",
    woocommerceIntegration: null,
    orderSourceCreated: "",
    orderSourceUpdated: "",
    orderUpdated: "",
    orderDelivered: "",
    orderMarks: "",
    ourReference: "",
  });

  const { order } = useAppSelector(ordersSelectors);

  useEffect(() => {
    if (order)
      setEditCustomersOrder({
        orderNotes: order.order_notes,
        orderNumber: order.order_number,
        orderStatus: order.order_status,
        shippingMethod: order.shipping_method,
        shippingTrackingNumber: order.shipping_tracking_number,
        shippingTrackingLink: order.shipping_tracking_link,
        url: order.order_url,
        internalOrderStatus: order.internal_order_status,
        woocommerceIntegration: null,
        orderSourceCreated: formatDateTime(order.order_created),
        orderSourceUpdated: formatDateTime(order.updated_at),
        orderUpdated: formatDateTime(order.updated_at),
        orderDelivered: formatDateTime(order.order_delivered!),
        orderMarks: order.order_marks,
        ourReference: order.our_reference,
      });
  }, [order]);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          {editAble && (
            <Button
              sx={{ height: 35 }}
              size="small"
              variant="outlined"
              onClick={() => setEditAble(false)}
            >
              Cancel
            </Button>
          )}
          {editAble && (
            <Button sx={{ height: 35 }} size="small" variant="contained">
              Save
            </Button>
          )}
        </Stack>

        {!editAble && (
          <Button
            endIcon={<EditIcon sx={{ width: 15 }} />}
            sx={{ height: 35, width: 50 }}
            variant="outlined"
            onClick={() => setEditAble(true)}
          >
            Edit
          </Button>
        )}
      </Stack>
      <Grid container spacing={1}>
        <Grid item xl={4} lg={6} xs={12}>
          <List dense>
            <Typography fontWeight={900} component="div">
              Order Reference
            </Typography>
            {orderReference?.map((OI, index) => (
              <ListItem key={index}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <ListItemText sx={{ minWidth: 140 }} primary={OI.field} />
                  {editAble && OI.canEdit ? (
                    <TextField
                      inputProps={{ sx: { fontSize: 13 } }}
                      value={editCustomersOrder[OI.identifier] ?? ""}
                      {...textFieldProps}
                    />
                  ) : (
                    <ListItemText
                      sx={{ maxWidth: 350 }}
                      primary={
                        editCustomersOrder[OI.identifier]
                          ? editCustomersOrder[OI.identifier]
                          : "-"
                      }
                    />
                  )}
                </Stack>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xl={4} lg={6} xs={12}>
          <List dense>
            <Typography sx={{ mt: 2 }} fontWeight={900} component="div">
              Order Status
            </Typography>
            {orderInfo?.map((OI, index) => (
              <ListItem key={index}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <ListItemText sx={{ minWidth: 190 }} primary={OI.field} />
                  {editAble && OI.canEdit ? (
                    <TextField
                      inputProps={{ sx: { fontSize: 13 } }}
                      value={editCustomersOrder[OI.identifier] ?? ""}
                      {...textFieldProps}
                    />
                  ) : (
                    <ListItemText
                      sx={{ maxWidth: 350 }}
                      primary={
                        editCustomersOrder[OI.identifier]
                          ? editCustomersOrder[OI.identifier]
                          : "-"
                      }
                    />
                  )}
                </Stack>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xl={4} lg={6} xs={12}>
          <List dense>
            <Typography sx={{ mt: 2 }} fontWeight={900} component="div">
              Order Date
            </Typography>
            {orderDate?.map((OI, index) => (
              <ListItem key={index}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <ListItemText sx={{ minWidth: 170 }} primary={OI.field} />
                  {editAble && OI.canEdit ? (
                    <TextField
                      inputProps={{ sx: { fontSize: 13 } }}
                      value={editCustomersOrder[OI.identifier] ?? ""}
                      {...textFieldProps}
                    />
                  ) : (
                    <ListItemText
                      sx={{ maxWidth: 350 }}
                      primary={
                        editCustomersOrder[OI.identifier]
                          ? editCustomersOrder[OI.identifier]
                          : "-"
                      }
                    />
                  )}
                </Stack>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xl={4} lg={6} xs={12}>
          <List dense>
            <Typography sx={{ mt: 2 }} fontWeight={900} component="div">
              Order Notes
            </Typography>
            {orderNotes?.map((OI, index) => (
              <ListItem key={index}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <ListItemText sx={{ minWidth: 140 }} primary={OI.field} />
                  {editAble && OI.canEdit ? (
                    <TextField
                      inputProps={{ sx: { fontSize: 13 } }}
                      value={editCustomersOrder[OI.identifier] ?? ""}
                      {...textFieldProps}
                    />
                  ) : (
                    <ListItemText
                      sx={{ maxWidth: 350 }}
                      primary={
                        editCustomersOrder[OI.identifier]
                          ? editCustomersOrder[OI.identifier]
                          : "-"
                      }
                    />
                  )}
                </Stack>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xl={4} lg={6} xs={12}>
          <List dense>
            <Typography sx={{ mt: 2 }} fontWeight={900} component="div">
              Shipping Information
            </Typography>
            {shippingInfo?.map((OI, index) => (
              <ListItem key={index}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <ListItemText sx={{ minWidth: 190 }} primary={OI.field} />
                  {editAble && OI.canEdit ? (
                    <TextField
                      inputProps={{ sx: { fontSize: 13 } }}
                      value={editCustomersOrder[OI.identifier] ?? ""}
                      {...textFieldProps}
                    />
                  ) : (
                    <ListItemText
                      sx={{ maxWidth: 350 }}
                      primary={
                        editCustomersOrder[OI.identifier]
                          ? editCustomersOrder[OI.identifier]
                          : "-"
                      }
                    />
                  )}
                </Stack>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
}
