import React from "react";
import { useSearchParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import { DatePickerWrapper } from "./styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import useChangeFilters from "hooks/useChangeFilters";
import FilterAccordion from "./FilterAccordion";
import { JSX } from "react/jsx-runtime";

interface IFilterDateRangeStackedProps {
  label: string;
  param: string;
}

const FilterDateRangeStacked = ({
  label,
  param,
}: IFilterDateRangeStackedProps): JSX.Element => {
  const fromParam = `${param}__gte`;
  const toParam = `${param}__lt`;
  const [searchParams] = useSearchParams();
  const changeFilter = useChangeFilters();
  const fromParamValue = searchParams.get(fromParam)!;
  const toParamValue = searchParams.get(toParam)!;

  const fromDate = searchParams.has(fromParam)
    ? DateTime.fromISO(fromParamValue)
    : null;
  const toDate = searchParams.has(toParam)
    ? DateTime.fromISO(toParamValue).minus({ days: 1 })
    : null;

  const onFromChange = (date: DateTime | null) => {
    const query = new URLSearchParams([
      [fromParam, date ? date.toFormat("yyyy-MM-dd") : ""],
    ]);
    changeFilter(query);
  };

  const onToChange = (date: DateTime | null) => {
    const query = new URLSearchParams([
      [toParam, date ? date.plus({ days: 1 }).toFormat("yyyy-MM-dd") : ""],
    ]);
    changeFilter(query);
  };

  return (
    <FilterAccordion label={label}>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <DatePickerWrapper>
          <DatePicker
            label="From"
            value={fromDate}
            format="yyyy-MM-dd"
            maxDate={toDate ? toDate : DateTime.now()}
            disableFuture
            onChange={onFromChange}
            slotProps={{
              textField: {
                variant: "outlined",
                InputProps: {
                  startAdornment: (
                    <IconButton
                      onClick={() => {
                        onFromChange(null);
                      }}
                      style={{ order: 1 }}
                      sx={{ paddingRight: 0, paddingLeft: "0.25rem" }}
                    >
                      <ClearIcon color="disabled" fontSize="small" />
                    </IconButton>
                  ),
                },
              },
            }}
          />
        </DatePickerWrapper>
        <Box sx={{ width: "1rem" }} />
        <DatePickerWrapper>
          <DatePicker
            label="To"
            value={toDate}
            format="yyyy-MM-dd"
            minDate={fromDate ? fromDate : DateTime.fromISO("2000-01-01")} // just a random value of min time
            disableFuture
            onChange={onToChange}
            slotProps={{
              textField: {
                variant: "outlined",
                InputProps: {
                  startAdornment: (
                    <IconButton
                      onClick={() => {
                        onToChange(null);
                      }}
                      style={{ order: 1 }}
                      sx={{ paddingRight: 0, paddingLeft: "0.25rem" }}
                    >
                      <ClearIcon color="disabled" fontSize="small" />
                    </IconButton>
                  ),
                },
              },
            }}
          />
        </DatePickerWrapper>
      </Box>
    </FilterAccordion>
  );
};

export default FilterDateRangeStacked;
