import React, { useEffect, useState } from "react";
import useAppDispatch from "hooks/useAppDispatch";
import { fetchCatalogCategoriesAsync } from "store/catalog-categories/thunks";
import { Box } from "@mui/material";
import useAppSelector from "hooks/useAppSelector";
import {
  selectCatalogCategoriesState,
  setCatalogCatagoriesParams,
} from "store/catalog-categories";
import Searchbar from "components/filter-products/Searchbar";
import { GridSortModel } from "@mui/x-data-grid";
import ColumnsForDataGrid from "./parts/ColumnsForDataDrid";
import { useSearchParams } from "react-router-dom";
import useChangeFilters from "hooks/useChangeFilters";
import procurementCategories from "services/procurement-categories";
import { IProcurementCategory } from "store/procurement-categories/interfaces";
import useBreakpoint from "hooks/useBreakpoint";
import GridContainer from "components/common-components/GridContainer";
import { DataGridPro } from "@mui/x-data-grid-pro";
import theme from "theme/theme";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ToolIconContainer from "components/common-components/ToolIconContainer";

const Category = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { categories, catalogCategoriesLoaded } = useAppSelector(
    selectCatalogCategoriesState,
  );
  const isMediumScreen = useBreakpoint("medium");
  const changeFilters = useChangeFilters();
  const [searchParams] = useSearchParams();
  const [procutementCategories, setProcutementCategories] = useState<
    IProcurementCategory[]
  >([]);
  const [loading, setLoading] = useState(true);

  const sortModel: GridSortModel = searchParams.has("ordering")
    ? searchParams.get("ordering") === "slug"
      ? [{ field: "slug", sort: "asc" }]
      : [{ field: "slug", sort: "desc" }]
    : [{ field: "slug", sort: null }];

  useEffect(() => {
    updateGrid();
  }, [dispatch, searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await procurementCategories
          .service()
          .fetchProcurementCategories(new URLSearchParams());
        if (res && res.results) {
          setProcutementCategories(res.results);
        } else {
          setProcutementCategories([]);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const searchHandler = (value: string) => {
    dispatch(setCatalogCatagoriesParams({ search: value, page: 0 }));
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const sortingOrder = sortModel[0]?.sort;
    const orderingParam =
      sortingOrder === "asc" ? "slug" : sortingOrder === "desc" ? "-slug" : "";
    changeFilters(new URLSearchParams([["ordering", orderingParam]]));
  };

  const updateGrid = () => {
    dispatch(fetchCatalogCategoriesAsync(searchParams));
  };

  const columns = ColumnsForDataGrid(
    updateGrid,
    procutementCategories,
    loading,
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" onChange={searchHandler} />

        <ToolboxArea>
          <ToolInfoTooltip title="Add category (coming soon)">
            <ToolIconContainer>
              <AddCircleIcon
                fontSize="large"
                sx={{
                  color: theme.palette.text.disabled,
                  stroke: theme.palette.text.disabled,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>
        </ToolboxArea>
      </Box>
      <GridContainer>
        <DataGridPro
          loading={!catalogCategoriesLoaded}
          rows={categories}
          columns={columns}
          rowHeight={200}
          columnHeaderHeight={40}
          disableColumnMenu
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          pagination
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
        />
      </GridContainer>
    </>
  );
};

export default Category;
