/* eslint-disable no-console */
import React from "react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { debounce, isEmpty } from "lodash-es";
import AddIcon from "@mui/icons-material/Add";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { ChangeEvent, useState } from "react";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { IStocktakeUILocations } from "../Interfaces";
import {
  clearTransactionMessage,
  selectStocktakingState,
} from "store/stock-taking";
import {
  addInventoryTransactionAsync,
  updateInventoryTransactionAsync,
} from "store/stock-taking/thunks";
import { IStocktakeProduct } from "store/stock-taking/interfaces";
import usePagination from "hooks/CustomPagination";
import theme from "theme/theme";

const ProductDataDisplay = (props: {
  productData: IStocktakeUILocations;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [term, setTerm] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [existingProducts, setExistingProducts] = useState([
    ...props.productData.products,
  ]);
  const [newProducts, setNewProducts] = useState<
    { id: number; quantity: string; productId: number }[]
  >([{ id: 1, quantity: "0", productId: 0 }]);
  const { inventoryProducts, success } = useAppSelector(selectStocktakingState);
  const [filteredInventoryProducts, setFilteredInventoryProducts] =
    useState<IStocktakeProduct[]>(inventoryProducts);

  const addNewTextField = () => {
    setNewProducts([
      ...newProducts,
      { id: newProducts.length + 1, quantity: "0", productId: 0 },
    ]);
  };
  const updateProduct = (id: number, productId: string) => {
    const prodId = +productId;
    setNewProducts(
      newProducts?.map((prod) => (prod.id !== id ? prod : { ...prod, prodId })),
    );
  };
  const updateQuantity = (id: number, quantity: string) => {
    setNewProducts(
      newProducts?.map((prod) =>
        prod.id !== id ? prod : { ...prod, quantity },
      ),
    );
  };
  const editQuantityField = (id: number, newQuantity: string) => {
    setExistingProducts(
      existingProducts?.map((txt) =>
        txt.id !== id ? txt : { ...txt, quantity: newQuantity },
      ),
    );
  };

  const saveStocktaking = () => {
    setLoading(true);
    const { products, account_id, company_id, location_id } = props.productData;
    const existingProductsUpdate = existingProducts.filter((existProd) => {
      const found = products.find((prod) => prod.id === existProd.id);
      return existProd.quantity !== found?.quantity;
    });
    const newProductsUpdate = newProducts.filter(
      (prod) => prod.productId !== null,
    );
    if (existingProductsUpdate.length === 0 && newProductsUpdate.length === 0)
      return;
    try {
      existingProductsUpdate.length > 0 &&
        existingProductsUpdate.forEach((prod) => {
          dispatch(
            updateInventoryTransactionAsync({
              id: prod.transaction_id,
              object: {
                account: account_id,
                company: company_id,
                location: location_id,
                product: prod.id,
                quantity: prod.quantity,
              },
            }),
          );
        });
      newProductsUpdate.length > 0 &&
        newProductsUpdate.forEach((prod) => {
          dispatch(
            addInventoryTransactionAsync({
              object: {
                account: account_id,
                company: company_id,
                location: location_id,
                product: prod.productId,
                quantity: prod.quantity,
              },
            }),
          );
        });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const debouncedSearch = debounce(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFilteredInventoryProducts(
        inventoryProducts.filter((prod) => {
          const identifyProductBy = `${prod.sku} ${prod.name}`;
          return identifyProductBy
            .toLowerCase()
            .includes(event.target.value.toLowerCase());
        }),
      );
    },
    800,
  );

  const PER_PAGE = 20;

  const count = Math.ceil(filteredInventoryProducts.length / PER_PAGE);
  const _DATA = usePagination(filteredInventoryProducts, PER_PAGE);

  const handleChange = (event: ChangeEvent<unknown>, page: number) => {
    setPage(page);
    _DATA.jump(page);
  };

  return (
    <>
      <Backdrop
        sx={{
          color: theme.palette.light.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          height="100vh"
          ml="22vw"
        >
          <CircularProgress />
        </Box>
      </Backdrop>

      <Snackbar
        open={!!success}
        autoHideDuration={2000}
        onClose={() => {
          dispatch(clearTransactionMessage());
        }}
      >
        <Alert severity={success ? "success" : "error"} sx={{ width: "100%" }}>
          {success}
        </Alert>
      </Snackbar>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Product</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {existingProducts?.map((productData, index) => (
                <TableRow
                  key={productData.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell component="th" scope="row">
                    <TextField
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      value={productData.quantity ?? ""}
                      onChange={(e) =>
                        editQuantityField(productData.id, e.target.value ?? "")
                      }
                    />
                  </TableCell>

                  <TableCell>
                    {productData.sku} - {productData.name}
                  </TableCell>
                </TableRow>
              ))}

              {newProducts?.map((prod) => (
                <TableRow
                  key={prod.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{existingProducts.length + prod.id}</TableCell>
                  <TableCell component="th" scope="row">
                    <TextField
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      value={prod.quantity}
                      onChange={(e) =>
                        updateQuantity(prod.id, e.target.value ?? "")
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Product
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Product"
                          defaultValue=""
                          onChange={(e) =>
                            updateProduct(prod.id, e.target.value)
                          }
                        >
                          <OutlinedInput
                            sx={{
                              height: "2.5rem",
                              backgroundColor: theme.palette.light.main,
                              maxWidth: "400px",
                              "& fieldset": {
                                borderWidth: 0,
                              },
                              fontSize: ".8rem",
                            }}
                            fullWidth
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchOutlinedIcon />
                              </InputAdornment>
                            }
                            placeholder="Search in products"
                            value={term}
                            onChange={(event) => {
                              setTerm(event.target.value);
                              debouncedSearch(event);
                            }}
                          />

                          {!isEmpty(filteredInventoryProducts) &&
                            _DATA.currentData()?.map((prod) => (
                              <MenuItem value={prod.id} key={prod.id}>
                                {prod.sku} - {prod.name}
                              </MenuItem>
                            ))}

                          <Pagination
                            count={count}
                            size="large"
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChange}
                          />
                        </Select>
                      </FormControl>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </>
          </TableBody>
        </Table>
        <Stack direction="row" justifyContent="space-between" mt={4}>
          <Button variant="outlined" onClick={addNewTextField}>
            <AddIcon />
            Add new field
          </Button>
          <Button variant="contained" onClick={saveStocktaking}>
            <SaveOutlinedIcon /> Save
          </Button>
        </Stack>
      </TableContainer>
    </>
  );
};

export default ProductDataDisplay;
