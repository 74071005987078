import { ICatalogCategory } from "store/catalog-categories/interfaces";

type Field =
  | "name"
  | "description"
  | "supplier"
  | "url"
  | "parent"
  | "catalogCategoryAutoCreate"
  | "descriptionHtml";

interface IUpdateProcurementCategory {
  name: string;
  description: string;
  description_html: string;
  url: string;
  supplier: number;
  parent: number;
  catalog_categories_auto_create: number[];
}
export function updateProcurementCategoryFn(
  setUpdate: React.Dispatch<React.SetStateAction<IUpdateProcurementCategory>>,
  update: IUpdateProcurementCategory,
): (field: Field, v: number | string | ICatalogCategory[]) => void {
  return (field: Field, v: number | string | ICatalogCategory[]) => {
    if (field === "name") {
      setUpdate({ ...update, name: v.toString() });
    } else if (field === "description") {
      setUpdate({ ...update, description: v.toString() });
    } else if (field === "descriptionHtml") {
      setUpdate({ ...update, description_html: v.toString() });
    } else if (field === "parent") {
      setUpdate({ ...update, parent: +v });
    } else if (field === "supplier") {
      setUpdate({ ...update, supplier: +v });
    } else if (field === "url") {
      setUpdate({ ...update, url: v.toString() });
    } else if (field === "catalogCategoryAutoCreate") {
      const value = v as ICatalogCategory[];
      const ccac = value?.map((item) => item.id);
      setUpdate({ ...update, catalog_categories_auto_create: ccac });
    }
  };
}
