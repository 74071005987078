import React, { useState } from "react";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import useAppDispatch from "hooks/useAppDispatch";
import {
  AddFileUrls,
  fetchFileUrlsListAsync,
} from "store/catalog-file-urls/thunks";
import { setUpdateMessage } from "store/catalog-file-urls";
import useAppSelector from "hooks/useAppSelector";
import { selectAuthenticatedUser } from "store/auth";
import { IUser } from "store/auth/interfaces";

const AddFileUrlModal = ({
  closeModal,
  setShowNotification,
}: {
  closeModal: () => void;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectAuthenticatedUser) as IUser;
  const account = user.id;
  const company = user.company as number;

  const [product, setProduct] = useState<number>();
  const [url, setUrl] = useState("");
  const [sourceUrl, setSourceUrl] = useState("");
  const [id, setId] = useState("");
  const [priority, setPriority] = useState("");
  const [title, setTitle] = useState("");
  const [removedFromSource, setRemovedFromSource] = useState(true);

  const [productError, setProductError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [sourceUrlError, setSourceUrlError] = useState(false);
  const [idError, setIdError] = useState(false);
  const [priorityError, setPriorityError] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const handleAddFileUrlData = () => {
    const isProductEmpty = product === null;
    const isUrlEmpty = url === "";
    const isSourceUrlEmpty = sourceUrl === "";
    const isIdEmpty = id === "";
    const isPriorityEmpty = priority === "";
    const isTitleEmpty = title === "";

    setProductError(isProductEmpty);
    setUrlError(isUrlEmpty);
    setSourceUrlError(isSourceUrlEmpty);
    setIdError(isIdEmpty);
    setPriorityError(isPriorityEmpty);
    setTitleError(isTitleEmpty);

    if (
      isProductEmpty ||
      isUrlEmpty ||
      isSourceUrlEmpty ||
      isPriorityEmpty ||
      isTitleEmpty
    ) {
      return;
    } else {
      const newFileUrlData = {
        url: url,
        source_url: sourceUrl,
        priority: Number(priority),
        title: title,
        removed_from_source: false,
        account: account,
        company: company,
        product: product,
      };
      dispatch(AddFileUrls({ params: newFileUrlData }));
      closeModal();
      dispatch(fetchFileUrlsListAsync(new URLSearchParams()));
      dispatch(setUpdateMessage("File url has been added!"));
      setShowNotification(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemovedFromSource(event.target.checked);
  };

  return (
    <>
      <FormControl component="form" fullWidth>
        <FormGroup sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <TextField
            required
            error={productError}
            fullWidth
            id="product"
            label="Product (Number)"
            type="number"
            variant="outlined"
            onChange={(e) => setProduct(Number(e.target.value))}
          />
          <TextField
            required
            error={urlError}
            fullWidth
            id="url"
            label="URL (Ecombooster download area)"
            variant="outlined"
            onChange={(e) => setUrl(e.target.value)}
          />
          <TextField
            required
            error={sourceUrlError}
            fullWidth
            id="source_url"
            label="URL (Supplier's source URL)"
            variant="outlined"
            onChange={(e) => setSourceUrl(e.target.value)}
          />
          {/* <TextField
          required
          error={idError}
          fullWidth
          id="id"
          label="ID"
          variant="outlined"
          onChange={(e) => setId(e.target.value)}
        /> */}
          <TextField
            required
            error={priorityError}
            fullWidth
            id="priority"
            label="Priority (Lower number has higher priority)"
            type="number"
            variant="outlined"
            onChange={(e) => setPriority(e.target.value)}
          />
          <TextField
            required
            error={titleError}
            fullWidth
            id="title"
            label="Title of file"
            variant="outlined"
            onChange={(e) => setTitle(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={removedFromSource}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Removed from source"
            id="removed_from_source"
          />
          <FormHelperText>
            If you click the checkbox, the file will no longer available on the
            source URL...
          </FormHelperText>
          <Button
            variant="contained"
            sx={{
              width: "fit-content",
              display: "flex",
              alignSelf: "flex-end",
            }}
            onClick={handleAddFileUrlData}
          >
            Add
          </Button>
        </FormGroup>
      </FormControl>
    </>
  );
};

export default AddFileUrlModal;
