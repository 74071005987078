import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { IProcurementListDTO } from "store/users-page/interfaces";
import { Mockable } from "../index";
import { endpoint } from "constants/endpoint";
export interface IUserListService {
  [x: string]: any;
  fetchProcurementUserList(searchText: string): Promise<IProcurementListDTO>;
}
// gets the list of procurement Users whose name contains the searchText, or all Users if the searchText is an empty string ("")
export default new Mockable<IUserListService>({
  async fetchProcurementUserList(
    searchText: string,
  ): Promise<IProcurementListDTO> {
    const res = await axios.get(`${config.api}${endpoint.users}`, {
      params: searchText
        ? new URLSearchParams(`name__icontains=${searchText}`)
        : {},
      headers: commonHeaders(),
    });
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },
});
