import React from "react";
import FilterDateRangeStacked from "components/filter-products/FilterDateRangeStacked";

const AvailableFilters = (): JSX.Element => {
  return (
    <>
      <FilterDateRangeStacked label="Created at" param="created_at" />
    </>
  );
};

export default AvailableFilters;
