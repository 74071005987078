import React from "react";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import { Box, Card, Divider } from "@mui/material";
import theme from "theme/theme";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddNewSupplierButton from "components/AddNewSupplierButton";

interface IProps {
  addToCatalog(): void;
}

const Toolbar = ({ addToCatalog }: IProps): JSX.Element => {
  return (
    <ToolboxArea>
      <ToolInfoTooltip title="Add to my product list (Bulk action)">
        <ToolIconContainer onClick={addToCatalog}>
          <PlaylistAddIcon
            fontSize="large"
            sx={{
              color: theme.palette.primary.main,
              stroke: theme.palette.primary.main,
              strokeWidth: 1,
            }}
          />
        </ToolIconContainer>
      </ToolInfoTooltip>

      <Divider orientation="vertical" variant="middle" flexItem />

      <ToolInfoTooltip title="Delete File URL (coming soon)">
        <ToolIconContainer disabled>
          <DeleteForeverIcon
            fontSize="large"
            sx={{
              color: theme.palette.text.disabled,
              stroke: theme.palette.text.disabled,
            }}
          />
        </ToolIconContainer>
      </ToolInfoTooltip>

      <Divider orientation="vertical" variant="middle" flexItem />

      <ToolInfoTooltip title="Archive (coming soon)">
        <ToolIconContainer disabled>
          <ArchiveOutlinedIcon
            fontSize="large"
            sx={{
              color: theme.palette.text.disabled,
              stroke: theme.palette.text.disabled,
            }}
          />
        </ToolIconContainer>
      </ToolInfoTooltip>

      <Divider orientation="vertical" variant="middle" flexItem />

      <AddNewSupplierButton />
    </ToolboxArea>
  );
};

export default Toolbar;
