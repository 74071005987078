import React, { useCallback, useState } from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import { Alert, Snackbar } from "@mui/material";
import {
  clearCategoryMessage,
  selectProcurementCategoriesState,
} from "store/procurement-categories";
import { ICatalogCategory } from "store/catalog-categories/interfaces";
import AccordionFields from "../../parts/AccordionFields";
import { useNavigate } from "react-router-dom";

interface IFilterSelectProps {
  onProcurementCategoryChanged(
    field: string,
    value: number | string | ICatalogCategory[],
  ): void;
}

const AddProcurementCategory = ({
  onProcurementCategoryChanged,
}: IFilterSelectProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(true);
  const [addProcurementCategory, setAddProcurementCategory] = useState({
    name: "",
    supplier: 0,
    url: "",
    parent: 0,
    description: "",
    descriptionHtml: "",
    catalog_categories_auto_create: [0],
  });

  const { success, error } = useAppSelector(selectProcurementCategoriesState);

  const updateProcurementCategory = useCallback(
    (field: string, value: number | string | ICatalogCategory[]) => {
      setAddProcurementCategory({
        ...addProcurementCategory,
        [field]: value,
      });
      onProcurementCategoryChanged(field, value);
    },
    [addProcurementCategory, onProcurementCategoryChanged],
  );

  return (
    <Box>
      <Snackbar
        open={!!success || !!error.description}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          dispatch(clearCategoryMessage());
          if (success) {
            navigate("/procurements-and-suppliers/categories/");
          }
        }}
      >
        <Alert severity={success ? "success" : "error"} sx={{ width: "100%" }}>
          {success || error.description}
        </Alert>
      </Snackbar>
      <Accordion
        expanded={expanded}
        square
        disableGutters
        elevation={0}
        onChange={() => setExpanded((prev) => !prev)}
      >
        <AccordionFields
          updateProcurementCategory={updateProcurementCategory}
          procurementCategory={addProcurementCategory}
        />
      </Accordion>
      <Divider />
    </Box>
  );
};

export default AddProcurementCategory;
