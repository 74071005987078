import React from "react";

const FallBack = () => <div>Loading ...</div>;

type ExtractedComponent<T> =
  T extends React.LazyExoticComponent<infer U> ? U : never;
const SunEditorLazy = React.lazy(() => import("suneditor-react"));

const SunEditor: ExtractedComponent<typeof SunEditorLazy> = (props) => (
  <React.Suspense fallback={<FallBack />}>
    <SunEditorLazy {...props} />
  </React.Suspense>
);

export default SunEditor;
