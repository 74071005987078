import React from "react";
import { useNavigate } from "react-router-dom";
import { resetOnboarding } from "store/onboarding";
import useAppDispatch from "hooks/useAppDispatch";
import { Button, Grid, Link, Typography } from "@mui/material";

const SupplierOnboardingDone = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOnDone = () => {
    dispatch(resetOnboarding());
    navigate("/onboarding");
  };

  return (
    <Grid gap="1rem">
      <Typography variant="h4">Thanks for reaching out!</Typography>
      <Typography>
        We have got your email and will respond to you as soon as we can. If you
        have any questions please contact us on:
      </Typography>
      <Typography>
        +46 708 122 012 <br />
        <Link href="mailto:info@ecombooster.io">info@ecombooster.io</Link>
      </Typography>
      <Typography>/Ecombooster team</Typography>
      <Button
        onClick={handleOnDone}
        variant="outlined"
        sx={{ flex: 1, height: "2.3rem" }}
      >
        Log in
      </Button>
    </Grid>
  );
};

export default SupplierOnboardingDone;
