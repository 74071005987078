import React from "react";
import Tooltip from "@mui/material/Tooltip";

const ComingSoonTooltip = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  return (
    <Tooltip title="Coming soon" disableInteractive>
      <div>{children}</div>
    </Tooltip>
  );
};

export default ComingSoonTooltip;
