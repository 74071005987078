import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import GridContainer from "components/common-components/GridContainer";
import { selectStocktakingState } from "store/stock-taking";
import useAppSelector from "hooks/useAppSelector";

const InnerDataGrid = ({ params }: { params: any[] }) => {
  const { productColumns } = useAppSelector(selectStocktakingState);

  return (
    <GridContainer>
      <DataGridPro
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        columns={productColumns}
        rows={params}
      />
    </GridContainer>
  );
};

export default InnerDataGrid;
