import React from "react";
import { Dispatch, SetStateAction } from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { IStocktakeUILocations } from "../parts/Interfaces";
import { Box, Button, Divider, Stack, Theme, Typography } from "@mui/material";
import theme from "theme/theme";
import InnerDataGrid from "./InnerDataGrid";

interface IDataGridColumnsProps {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  setModalData: Dispatch<SetStateAction<IStocktakeUILocations>>;
}

const DataGridColumns = ({
  setModalOpen,
  setModalData,
}: IDataGridColumnsProps): GridColDef[] => {
  const openStocktakingModal = (params: GridRenderCellParams) => {
    setModalOpen(true);
    setModalData(params.row);
  };

  const columns: GridColDef[] = [
    {
      field: "location_id",
      headerName: "Id",
      sortable: false,
    },

    {
      field: "location",
      flex: 1,
      headerName: "LOCATION",
      sortable: false,
      cellClassName: "location-column--cell",

      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack py={2.5} spacing={1.5} divider={<Divider flexItem />}>
            <Typography color={theme.palette.primary.main} variant="subtitle2">
              {params.row.name}
            </Typography>

            <Stack px={0.5} spacing={1} divider={<Divider flexItem />}>
              {params.row.content && (
                <Typography
                  component="div"
                  color={theme.palette.dark.main}
                  variant="body2"
                >
                  Content:
                  <Box
                    sx={{
                      color: (theme: Theme) => `${theme.palette.subtle.main}`,
                    }}
                    component="span"
                  >
                    {params.row.content}
                  </Box>
                </Typography>
              )}

              {params.row.comment && (
                <Typography
                  component="div"
                  color={theme.palette.dark.main}
                  variant="body2"
                >
                  Comment:
                  <Box
                    sx={{
                      color: (theme: Theme) => `${theme.palette.subtle.main}`,
                    }}
                    component="span"
                  >
                    {params.row.comment}
                  </Box>
                </Typography>
              )}

              {params.row.obsolete && (
                <Typography color={theme.palette.dark.main} variant="body2">
                  OBSOLETE LOCATION!
                </Typography>
              )}

              <Typography
                component="div"
                color={theme.palette.dark.main}
                variant="body2"
              >
                Last stocktaking done:
                <Box sx={{ color: theme.palette.subtle.main }} component="span">
                  {params.row.last_stocktaking_done !== null
                    ? params.row.last_stocktaking_done
                    : " No stocktaking registred yet!"}
                </Box>
              </Typography>
            </Stack>

            <Button
              variant="contained"
              color="primary"
              sx={{ textTransform: "capitalize" }}
              onClick={() => openStocktakingModal(params)}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FileUploadOutlinedIcon
                  fontSize="small"
                  sx={{ paddingBottom: "1px" }}
                />
                {params.row.stocktaking}
              </Box>
            </Button>
          </Stack>
        );
      },
    },

    {
      field: "products",
      flex: 5,
      headerName: "Products",
      sortable: false,
      cellClassName: "products-column--cell",

      renderCell: (params: any) =>
        params ? <InnerDataGrid params={params.value} /> : <span></span>,
    },
  ];

  return columns;
};

export default DataGridColumns;
