import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import {
  IProcurementCategoriesResponseDTO,
  IProcurementCategory,
  IUpdateProcurementCategoryDTO,
} from "store/procurement-categories/interfaces";
import { endpoint } from "constants/endpoint";

export interface IProcurementCategoriesService {
  fetchProcurementCategories(
    params: URLSearchParams,
  ): Promise<IProcurementCategoriesResponseDTO>;
  fetchProcurementCategory(id: string): Promise<IProcurementCategory>;
  addProcurementCategory(
    object: IUpdateProcurementCategoryDTO,
  ): Promise<IProcurementCategory>;
  updateProcurementCategory(
    id: string,
    object: IUpdateProcurementCategoryDTO,
  ): Promise<IProcurementCategory>;
  deleteProcurementCategory(id: number): Promise<void>;
}

export default new Mockable<IProcurementCategoriesService>({
  async fetchProcurementCategories(
    params,
  ): Promise<IProcurementCategoriesResponseDTO> {
    try {
      const res = await axios(
        `${config.api}${endpoint.procurement_categories}`,
        {
          params,
          headers: commonHeaders(),
        },
      );

      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error("Unexpected response format in fetchProcurementCategories"),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
  async fetchProcurementCategory(id): Promise<IProcurementCategory> {
    try {
      const res = await axios(
        `${config.api}${endpoint.procurement_categories}${id}`,
        {
          headers: commonHeaders(),
        },
      );

      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error("Unexpected response format in fetchProcurementCategory"),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
  async addProcurementCategory(object): Promise<IProcurementCategory> {
    try {
      const res = await axios(
        `${config.api}${endpoint.procurement_categories}`,
        {
          method: "POST",
          headers: commonHeaders(),
          data: JSON.stringify(object),
        },
      );

      if (res.status === 201) {
        return res.data;
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async addProcurementCategory <<",
          ),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
  async updateProcurementCategory(id, object): Promise<IProcurementCategory> {
    try {
      const res = await axios(
        `${config.api}${endpoint.procurement_categories}${id}/`,
        {
          method: "PATCH",
          headers: commonHeaders(),
          data: {
            catalog_categories_auto_create:
              object.catalog_categories_auto_create?.map(
                (item: any) => item.id,
              ),
          },
        },
      );

      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async updateProcurementCategory <<",
          ),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
  async deleteProcurementCategory(id: number): Promise<void> {
    await axios.delete(`${config.api}${endpoint.procurement_categories}${id}`, {
      headers: commonHeaders(),
    });
  },
});
