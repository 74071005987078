import React, { useState } from "react";
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  Tab,
  Tabs,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
} from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import ContactsIcon from "@mui/icons-material/Contacts";
import SettingsIcon from "@mui/icons-material/Settings";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import PublishIcon from "@mui/icons-material/Publish";
import { dataString } from "./dataInfo";
import { endpoint } from "constants/endpoint";
import { selectCatalogCategoriesState } from "store/catalog-categories";
import useAppSelector from "hooks/useAppSelector";
import config from "../../../../config";
import CustomTextField from "components/custom-components/CustomTextField";
import CustomCheckboxField from "components/custom-components/CustomCheckboxField";
import CustomSelectField from "components/custom-components/CustomSelectField";
import CustomRadioGroupField from "components/custom-components/CustomRadioGroupField";
import { IAddProcurementSupplyListParams } from "store/procurement-supplierList/interfaces";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import {
  ICatalogCategoriesResponseDTO,
  ICatalogCategory,
} from "store/catalog-categories/interfaces";
import { selectAuthenticatedUser } from "store/auth";

interface RedesignModalContainerAddProps {
  handleNewAdd: (newData: IAddProcurementSupplyListParams) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function isCatalogCategory(
  option: string | ICatalogCategory,
): option is ICatalogCategory {
  return (option as ICatalogCategory).title !== undefined;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 4,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          textTransform: "none",
        },
      },
    },
  },
});

const RedesignModalContainerAdd: React.FC<RedesignModalContainerAddProps> = ({
  handleNewAdd,
}) => {
  const [value, setValue] = useState(0);
  const user = useAppSelector(selectAuthenticatedUser);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { categories } = useAppSelector(selectCatalogCategoriesState);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IAddProcurementSupplyListParams>();

  const onSubmit: SubmitHandler<IAddProcurementSupplyListParams> = (
    data: IAddProcurementSupplyListParams,
  ) => {
    data.company = user?.company;
    handleNewAdd(data);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 3,
            overflow: "hidden",
            position: "relative",
          }}
        >
          <AppBar position="sticky" color="primary">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
            >
              <Tab
                icon={<ContactsIcon />}
                label={dataString.basicData}
                {...a11yProps(0)}
              />
              <Tab
                icon={<SettingsIcon />}
                label={dataString.settings}
                {...a11yProps(1)}
              />
              <Tab
                icon={<PriceCheckIcon />}
                label={dataString.pricing_settings}
                {...a11yProps(2)}
              />
              <Tab
                icon={<PublishIcon />}
                label={dataString.import}
                {...a11yProps(3)}
              />
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <Box sx={{ p: 4 }}>
              <Grid container spacing={2} mt={2}>
                <Tooltip title={dataString.helperTextforTitle}>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      control={control}
                      name="name"
                      label={dataString.modalTitle}
                      rules={{ required: dataString.error_required }}
                    />
                  </Grid>
                </Tooltip>

                <Grid item xs={12} md={6}>
                  <CustomTextField
                    control={control}
                    name="name_abbrev"
                    label={dataString.modalShortname}
                    rules={{ required: dataString.error_required }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={6}>
                  <Tooltip title={dataString.helperTextforSupplierIndex}>
                    <span>
                      <CustomTextField
                        control={control}
                        name="main_supplier_index"
                        label={dataString.modalSupplierIndex}
                        rules={{
                          required: dataString.error_required,
                          min: {
                            value: 1,
                            message:
                              "Ensure this value is greater than or equal to 1.",
                          },
                        }}
                        type="number"
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Tooltip
                    title={dataString.helperTextforPhasedOut}
                    disableInteractive
                  >
                    <span>
                      <CustomCheckboxField
                        defaultValue={false}
                        control={control}
                        name="phased_out"
                        label={dataString.phased_out}
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={6}>
                  <Tooltip
                    title={dataString.helperTextforPriority}
                    disableInteractive
                  >
                    <span>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Priority</FormLabel>
                        <CustomRadioGroupField
                          control={control}
                          name="priority"
                          label={dataString.priority}
                          defaultValue="MED"
                          row
                        >
                          <FormControlLabel
                            value="LOW"
                            control={<Radio color="secondary" />}
                            label="Low"
                          />
                          <FormControlLabel
                            value="MED"
                            control={<Radio color="secondary" />}
                            label="Medium"
                          />
                          <FormControlLabel
                            value="HIGH"
                            control={<Radio color="secondary" />}
                            label="High"
                          />
                          <FormControlLabel
                            value="TBD"
                            control={<Radio color="secondary" />}
                            label="Decide later"
                          />
                        </CustomRadioGroupField>
                      </FormControl>
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Type of Supplier</FormLabel>
                    <CustomRadioGroupField
                      control={control}
                      name="supplier_type"
                      label={dataString.type_of_supplier}
                      defaultValue="RESELLER"
                      row
                    >
                      <FormControlLabel
                        value="RESELLER"
                        control={<Radio color="secondary" />}
                        label="Wholesale"
                      />
                      <FormControlLabel
                        value="FACTORY"
                        control={<Radio color="secondary" />}
                        label="Factory"
                      />
                    </CustomRadioGroupField>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    control={control}
                    name="url_login"
                    label={dataString.modalLogin}
                    rules={{
                      required: dataString.error_required,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    control={control}
                    name="username"
                    label={dataString.modalUserName}
                    rules={{ required: dataString.error_required }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    control={control}
                    name="password"
                    label={dataString.modalPassword}
                    rules={{ required: dataString.error_required }}
                    type="password"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    control={control}
                    name="url_homepage"
                    label={dataString.modalHomePage}
                    helperText={dataString.helperTextforWebsite}
                    rules={{ required: dataString.error_required }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Tooltip title={dataString.helperTextforURLsearch}>
                    <span>
                      <CustomTextField
                        control={control}
                        name="url_search"
                        label={dataString.ModalUrlSearch}
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    control={control}
                    name="email_orders"
                    label={dataString.modalEmail}
                    rules={{
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                  <CustomTextField
                    control={control}
                    name="comments"
                    label={dataString.Comments}
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Tooltip
                    title={dataString.helperTextforPurchaseNote}
                    disableInteractive
                  >
                    <span>
                      <CustomTextField
                        control={control}
                        name="procurement_notes"
                        label={dataString.procurement_notes}
                        rows={4}
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box sx={{ p: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Tooltip
                    title={dataString.helperTextforMinimumOrder}
                    disableInteractive
                  >
                    <span>
                      <CustomTextField
                        control={control}
                        name="procurement_minimum_order_value"
                        label={dataString.minimum_order_value}
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Tooltip
                    title={dataString.helperTextforFeed}
                    disableInteractive
                  >
                    <span>
                      <CustomTextField
                        control={control}
                        name="warning_level_days_between_feed_updates"
                        label="Feed Warning Level (days)"
                        type="number"
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Tooltip
                    title={dataString.helperTextforReuseRRP}
                    disableInteractive
                  >
                    <span>
                      <CustomCheckboxField
                        defaultValue={false}
                        control={control}
                        name="reuse_rrp"
                        label="Reuse RRP"
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Tooltip
                    title={dataString.helperTextforRRPfactor}
                    disableInteractive
                  >
                    <span>
                      <CustomTextField
                        control={control}
                        name="rrp_factor"
                        label="RRP Factor"
                        type="number"
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Tooltip
                    title={dataString.helperTextforAllowRoundingRRP}
                    disableInteractive
                  >
                    <span>
                      <CustomCheckboxField
                        defaultValue={false}
                        control={control}
                        name="reuse_rrp_allow_rounding"
                        label="Allow Rounding RRP"
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Tooltip
                    title={dataString.helperTextforRRPsalesFactor}
                    disableInteractive
                  >
                    <span>
                      <CustomTextField
                        control={control}
                        name="rrp_sales_factor"
                        label={dataString.rrp_sales_factor}
                        type="number"
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    control={control}
                    name="custom_pricing_rules"
                    label={dataString.custom_pricing_rules}
                    rows={4}
                    helperText={dataString.helperTextforCustomPricingRule}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Typography component={"span"} variant={"body2"} />
                      }
                      label="Custom Pricing Rules Acknowledged by Ecombooster"
                    />
                    <FormHelperText>
                      {dataString.helperTextforCustomRuleByEcombooster}
                    </FormHelperText>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Typography component={"span"} variant={"body2"} />
                      }
                      label={
                        dataString.custom_pricing_rules_ecombooster_internal
                      }
                    />
                    <FormHelperText>
                      {dataString.helperTextforCustomRuleByEcomboosterInternal}
                    </FormHelperText>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Tooltip
                    title={dataString.helperTextforPriceFactor}
                    disableInteractive
                  >
                    <span>
                      <CustomTextField
                        control={control}
                        name="price_factor_based_on_procurement_cost"
                        label={
                          dataString.price_fFactor_based_on_procurement_cost
                        }
                        rows={4}
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Tooltip
                    title={dataString.helperTextforSalesPrice}
                    disableInteractive
                  >
                    <span>
                      <CustomTextField
                        control={control}
                        name="sale_price_asFactor_of_standard_price"
                        label={
                          dataString.sale_price_as_factor_of_standard_price
                        }
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box sx={{ p: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Tooltip
                    title={dataString.helperTextforCurrency}
                    arrow
                    placement="right-start"
                  >
                    <div>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={!!errors.default_currency}
                      >
                        <InputLabel id="currency-label">Currency</InputLabel>
                        <CustomSelectField
                          labelId="currency-label"
                          name="currency"
                          label={dataString.currency}
                          control={control}
                        >
                          <MenuItem value={5}>DKK</MenuItem>
                          <MenuItem value={1}>EUR</MenuItem>
                          <MenuItem value={2}>GBP</MenuItem>
                          <MenuItem value={6}>NOK</MenuItem>
                          <MenuItem value={4}>SEK</MenuItem>
                          <MenuItem value={3}>USD</MenuItem>
                        </CustomSelectField>
                        <FormHelperText>
                          {errors.default_currency
                            ? "This field is required"
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={dataString.helperTextforCatalogSKU}
                          arrow
                          placement="right-start"
                        >
                          <span>
                            <CustomCheckboxField
                              defaultValue={false}
                              control={control}
                              name="sku_reuse"
                              label={dataString.catalog_sku}
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={dataString.helperTextforImagesforUse}
                          arrow
                          placement="right-start"
                        >
                          <span>
                            <CustomCheckboxField
                              defaultValue={false}
                              control={control}
                              name="reuse_images"
                              label={dataString.images_for_use}
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={dataString.helperTextforReuseFiles}
                          arrow
                          placement="right-start"
                        >
                          <span>
                            <CustomCheckboxField
                              defaultValue={false}
                              control={control}
                              name="reuse_files"
                              label={dataString.reuse_files}
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={dataString.helperTextforReuseDescription}
                          placement="right-start"
                        >
                          <span>
                            <CustomCheckboxField
                              defaultValue={false}
                              control={control}
                              name="reuse_descriptions"
                              label={dataString.reuse_descriptions}
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={dataString.helperTextforStockStatus}
                          arrow
                          placement="right-start"
                        >
                          <span>
                            <CustomCheckboxField
                              defaultValue={false}
                              control={control}
                              name="expose_stock_status"
                              label={dataString.expose_stock_status}
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={dataString.helperTextforStockQuantity}
                          arrow
                          placement="right-start"
                        >
                          <span>
                            <CustomCheckboxField
                              defaultValue={false}
                              control={control}
                              name="expose_stock_quantity"
                              label={dataString.expose_stock_quantity}
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Tooltip
                        title="Select default categories for the catalog"
                        arrow
                      >
                        <span>
                          <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={categories}
                            disableCloseOnSelect
                            getOptionLabel={(option) =>
                              isCatalogCategory(option) ? option.title : option
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox checked={selected} sx={{ mr: 1 }} />
                                {isCatalogCategory(option)
                                  ? option.title
                                  : option}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={dataString.default_catalog_categories}
                                placeholder="Select categories"
                              />
                            )}
                            sx={{ width: 800 }}
                          />
                        </span>
                      </Tooltip>
                      <a
                        href={`${config.api}${endpoint.admin_catalog_category_add}?_to_field=id&_popup=1`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginLeft: 10 }}
                      >
                        <Button variant="contained">
                          <Icon>add_circle</Icon>
                        </Button>
                      </a>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>

          <TabPanel value={value} index={3}>
            <Box sx={{ p: 4 }}>
              {[
                {
                  label: "Import Wipe Images",
                  id: "import-wipe-images",
                  helperText: dataString.helperTextforImportWipeImages,
                  width: "300px",
                  options: [
                    { value: "KEEP", text: "Do not wipe data" },
                    { value: "WIPE_NEXT", text: "Wipe on next import" },
                  ],
                },
                {
                  label: "Import Wipe Attachments",
                  id: "import-wipe-attachments",
                  helperText: dataString.helperTextforImportWipeAttachments,
                  width: "300px",
                  options: [
                    { value: "KEEP", text: "Do not wipe data" },
                    { value: "WIPE_NEXT", text: "Wipe on next import" },
                  ],
                },
                {
                  label: "Import Wipe Attributes",
                  id: "import-wipe-attributes",
                  helperText: dataString.helperTextforImportWipeAttributes,
                  width: "300px",
                  options: [
                    { value: "KEEP", text: "Do not wipe data" },
                    { value: "WIPE_NEXT", text: "Wipe on next import" },
                  ],
                },
                {
                  label: "Import Skip Processing Categories",
                  id: "import-skip-processing-categories",
                  helperText:
                    dataString.helperTextforImportSkipProcessingCategories,
                  width: "300px",
                  options: [
                    { value: "NO_SKIP", text: "Do not skip" },
                    { value: "SKIP_NEXT", text: "Skip on next import" },
                    { value: "SKIP_FOREVER", text: "Skip until flag changed" },
                  ],
                },
                {
                  label: "Import Skip Processing Products",
                  id: "import-skip-processing-products",
                  helperText:
                    dataString.helperTextforImportSkipProcessingProducts,
                  width: "300px",
                  options: [
                    { value: "NO_SKIP", text: "Do not skip" },
                    { value: "SKIP_NEXT", text: "Skip on next import" },
                    { value: "SKIP_FOREVER", text: "Skip until flag changed" },
                  ],
                },
                {
                  label: "Import Skip Processing Related Products",
                  id: "import-skip-processing-related-products",
                  helperText:
                    dataString.helperTextforImportSkipProcessingRelatedProducts,
                  width: "400px",
                  options: [
                    { value: "NO_SKIP", text: "Do not skip" },
                    { value: "SKIP_NEXT", text: "Skip on next import" },
                    { value: "SKIP_FOREVER", text: "Skip until flag changed" },
                  ],
                },
                {
                  label: "Import Skip Updating Products",
                  id: "import-skip-updating-products",
                  helperText:
                    dataString.helperTextforImportSkipUpdatingProducts,
                  width: "300px",
                  options: [
                    { value: "NO_SKIP", text: "Do not skip" },
                    { value: "SKIP_NEXT", text: "Skip on next import" },
                    { value: "SKIP_FOREVER", text: "Skip until flag changed" },
                  ],
                },
                {
                  label: "Import Skip Updating Not Scraped Products",
                  id: "import-skip-updating-not-scraped-products",
                  helperText:
                    dataString.helperTextforImportSkipUpdatingNotScrapedProducts,
                  width: "400px",
                  options: [
                    { value: "NO_SKIP", text: "Do not skip" },
                    { value: "SKIP_NEXT", text: "Skip on next import" },
                    { value: "SKIP_FOREVER", text: "Skip until flag changed" },
                  ],
                },
                {
                  label: "Import Skip Creating New Products",
                  id: "import-skip-creating-new-products",
                  helperText:
                    dataString.helperTextforImportSkipCreatingNewProducts,
                  width: "300px",
                  options: [
                    { value: "NO_SKIP", text: "Do not skip" },
                    { value: "SKIP_NEXT", text: "Skip on next import" },
                    { value: "SKIP_FOREVER", text: "Skip until flag changed" },
                  ],
                },
              ]?.map((selectField, index) => (
                <React.Fragment key={index}>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id={`${selectField.id}-label`}>
                      {selectField.label}
                    </InputLabel>
                    <CustomSelectField
                      labelId={`${selectField.id}-label`}
                      name={selectField.id}
                      label={selectField.label}
                      width={selectField.width}
                      control={control}
                    >
                      {selectField.options?.map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                          ref
                          {option.text}
                        </MenuItem>
                      ))}
                    </CustomSelectField>
                    <FormHelperText>{selectField.helperText}</FormHelperText>
                  </FormControl>
                  {index < 8 && (
                    <Box sx={{ my: 2 }}>
                      <Divider />
                    </Box>
                  )}
                </React.Fragment>
              ))}
            </Box>
          </TabPanel>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="medium"
          >
            Add
          </Button>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
export default RedesignModalContainerAdd;
function setCategories2(arg0: any) {
  throw new Error("Function not implemented.");
}
function dispatch(
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  arg0: AsyncThunkAction<ICatalogCategoriesResponseDTO, URLSearchParams, {}>,
) {
  throw new Error("Function not implemented.");
}
