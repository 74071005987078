import { SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { IErrorMessage } from "components/ErrorComponent";

export function httpErrorHandler(error: SerializedError): IErrorMessage {
  if (error.code === "ERR_NETWORK" || error.code === "ERR_BAD_RESPONSE") {
    return { description: error.message, code: 500 };
  }

  if (error.code === "ERR_BAD_REQUEST") {
    if (error.message && error.message.includes("404")) {
      return {
        description: "The page you're looking for doesn't exist.",
        code: 404,
      };
    } else if (error.message && error.message.includes("401")) {
      return {
        description: "Please login to access this resource",
        code: 401,
      };
    }
  }

  return { description: error.message };
}

export function axiosErrorResponse(error: unknown): IErrorMessage {
  const err = error as AxiosError;

  if (err && err.response) {
    return {
      description:
        err.response.status !== 500
          ? JSON.stringify(err.response.data)
          : "Internal Server Error",
      code: err.response.status,
    };
  } else if (err.request) {
    return err.request;
  } else {
    return { description: err.message };
  }
}
