import React from "react";
import {
  COMPANY_PREFERENCES,
  FORWARD_SLASH,
  SUB_ROUTE_BULK3_DESCRIPTION,
  SUB_ROUTE_CATALOG_INVENTORY_LOCATIONS,
  SUB_ROUTE_CATALOG_PRODUCTS,
  SUB_ROUTE_CATALOG_PRODUCTS_IMAGES,
  SUB_ROUTE_CATEGORY,
  SUB_ROUTE_CATEGORY_MARKUP,
  SUB_ROUTE_CUSTOMERS_ORDERS,
  SUB_ROUTE_CUSTOMERS_PICK_AND_PACK,
  SUB_ROUTE_GLOBAL_SUPPLIERS_AND_BRANDS,
  SUB_ROUTE_INVENTORY_TRANSACTIONS,
  SUB_ROUTE_INVENTORYLOCATIONTYPE,
  SUB_ROUTE_MISCELLANEOUS_FACEBOOK_PRODUCT_TAXONOMIES,
  SUB_ROUTE_MISCELLANEOUS_GOOGLE_PRODUCT_TAXONOMIES,
  SUB_ROUTE_PREFERENCES_FACEBOOK_PRODUCT_TAXONOMIES_SELECTION,
  SUB_ROUTE_PROCUREMENT_AND_ORDERS,
  SUB_ROUTE_PROCUREMENT_AND_SUPPLIER,
  SUB_ROUTE_PROCUREMENT_AND_SUPPLIER_FEED,
  SUB_ROUTE_PROCUREMENT_IMAGE_URLS,
  SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES,
  SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_FILE_URLS,
  SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_PRODUCTS,
  SUB_ROUTE_STOCK_TAKING,
} from "constants/urls";
import SidebarContent from "./parts/SidebarContent";
import DashboardCustomizeOutlined from "@mui/icons-material/DashboardCustomizeOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import theme from "theme/theme";
import CommonIconButton from "components/common-components/CommonIconButton";

const sidebarItems = [
  {
    key: 1,
    itemName: "Dashboard",
    icon: (
      <CommonIconButton
        disableRipple={true}
        width="2rem"
        height="2rem"
        backgroundColor={theme.palette.secondary.light}
        textColor={theme.palette.primary.main}
      >
        <DashboardCustomizeOutlined
          sx={{ fontSize: `${theme.typography.body2}` }}
        />
      </CommonIconButton>
    ),
    link: "",
    children: [
      {
        key: 2,
        itemName: "Overview",
        link: FORWARD_SLASH,
        pinned: false,
      },
      {
        key: 3,
        itemName: "Prices & margins",
        disabled: true,
        link: "",
        pinned: false,
      },
      {
        key: 4,
        itemName: "SEO & product information",
        disabled: true,
        link: "",
        pinned: false,
      },
      {
        key: 5,
        itemName: "Stock status",
        disabled: true,
        link: "",
        pinned: false,
      },
    ],
  },
  {
    key: 6,
    itemName: "Product Catalog",
    icon: (
      <CommonIconButton
        disableRipple={true}
        width="2rem"
        height="2rem"
        backgroundColor={theme.palette.secondary.light}
        textColor={theme.palette.primary.main}
      >
        <Inventory2OutlinedIcon
          sx={{ fontSize: `${theme.typography.body2}` }}
        />
      </CommonIconButton>
    ),
    link: "",
    children: [
      {
        key: 7,
        itemName: "Category",
        link: SUB_ROUTE_CATEGORY,
        pinned: false,
      },
      {
        key: 8,
        itemName: "File URLs",
        link: SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_FILE_URLS,
        pinned: false,
      },
      {
        key: 9,
        itemName: "Images",
        link: SUB_ROUTE_CATALOG_PRODUCTS_IMAGES,
        pinned: false,
      },
      {
        key: 10,
        itemName: "Inventory Locations",
        link: SUB_ROUTE_CATALOG_INVENTORY_LOCATIONS,
        pinned: false,
      },
      {
        key: 11,
        itemName: "Inventory Location Type",
        link: SUB_ROUTE_INVENTORYLOCATIONTYPE,
        pinned: false,
      },
      {
        key: 12,
        itemName: "Inventory Transactions",
        link: SUB_ROUTE_INVENTORY_TRANSACTIONS,
        pinned: false,
      },
      {
        key: 13,
        itemName: "Markup",
        link: SUB_ROUTE_CATEGORY_MARKUP,
        pinned: false,
      },
      {
        key: 14,
        itemName: "My Products",
        disabled: false,
        link: SUB_ROUTE_CATALOG_PRODUCTS,
        pinned: false,
      },
      {
        key: 15,
        itemName: "My Products - Low in Stock",
        link: SUB_ROUTE_CATALOG_PRODUCTS,
        pinned: false,
        disabled: true,
      },
      {
        key: 16,
        itemName: "My Products - Negative Margin",
        link: SUB_ROUTE_CATALOG_PRODUCTS,
        pinned: false,
        disabled: true,
      },
      {
        key: 17,
        itemName: "My Products - Recently Added",
        link: SUB_ROUTE_CATALOG_PRODUCTS,
        pinned: false,
        disabled: true,
      },
      {
        key: 37,
        itemName: "My Products - Bulk 3 - Descriptions",
        link: SUB_ROUTE_BULK3_DESCRIPTION,
        pinned: false,
        disabled: false,
      },
      {
        key: 18,
        itemName: "Stocktaking",
        link: SUB_ROUTE_STOCK_TAKING,
        pinned: false,
      },
    ],
  },
  {
    key: 19,
    itemName: "Procurement & Suppliers",
    icon: (
      <CommonIconButton
        disableRipple={true}
        width="2rem"
        height="2rem"
        backgroundColor={theme.palette.secondary.light}
        textColor={theme.palette.primary.main}
      >
        <AddShoppingCartOutlinedIcon
          sx={{ fontSize: `${theme.typography.body2}` }}
        />
      </CommonIconButton>
    ),
    link: "",
    children: [
      {
        key: 20,
        itemName: "Categories",
        link: SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_CATEGORIES,
        pinned: false,
      },
      {
        key: 21,
        itemName: "Image URLs",
        link: SUB_ROUTE_PROCUREMENT_IMAGE_URLS,
        pinned: false,
      },
      {
        key: 22,
        itemName: "Orders",
        link: SUB_ROUTE_PROCUREMENT_AND_ORDERS,
        pinned: false,
      },
      {
        key: 23,
        itemName: "Products",
        link: SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_PRODUCTS,
        pinned: true,
      },
      {
        key: 24,
        itemName: "Products - Recently Added",
        link: SUB_ROUTE_PROCUREMENTS_AND_SUPPLIERS_PRODUCTS,
        pinned: false,
      },
      {
        key: 25,
        itemName: "Suppliers",
        link: SUB_ROUTE_PROCUREMENT_AND_SUPPLIER,
        pinned: false,
      },
      {
        key: 26,
        itemName: "Supplier Feeds",
        link: SUB_ROUTE_PROCUREMENT_AND_SUPPLIER_FEED,
        pinned: false,
      },
    ],
  },
  {
    key: 27,
    itemName: "Customer Order",
    icon: (
      <CommonIconButton
        disableRipple={true}
        width="2rem"
        height="2rem"
        backgroundColor={theme.palette.secondary.light}
        textColor={theme.palette.primary.main}
      >
        <LocalShippingOutlinedIcon
          sx={{ fontSize: `${theme.typography.body2}` }}
        />
      </CommonIconButton>
    ),
    link: "",
    children: [
      {
        key: 28,
        itemName: "Orders",
        link: SUB_ROUTE_CUSTOMERS_ORDERS,
        pinned: false,
      },
      {
        key: 29,
        itemName: "Pick and Pack",
        link: SUB_ROUTE_CUSTOMERS_PICK_AND_PACK,
        pinned: true,
      },
    ],
  },
  {
    key: 30,
    itemName: "Settings",
    icon: (
      <CommonIconButton
        disableRipple={true}
        width="2rem"
        height="2rem"
        backgroundColor={theme.palette.secondary.light}
        textColor={theme.palette.primary.main}
      >
        <TuneOutlinedIcon sx={{ fontSize: `${theme.typography.body2}` }} />
      </CommonIconButton>
    ),
    link: "",
    children: [
      {
        key: 31,
        itemName: "All Suppliers & Brands",
        link: SUB_ROUTE_GLOBAL_SUPPLIERS_AND_BRANDS,
        disabled: true,
        pinned: false,
      },
      {
        key: 32,
        itemName: "Company Preferences",
        link: COMPANY_PREFERENCES,
        pinned: false,
      },
      {
        key: 33,
        itemName: "Facebook Product Taxonomies",
        link: SUB_ROUTE_MISCELLANEOUS_FACEBOOK_PRODUCT_TAXONOMIES,
        pinned: false,
      },
      {
        key: 34,
        itemName: "Facebook Product Taxonomies Selection",
        link: SUB_ROUTE_PREFERENCES_FACEBOOK_PRODUCT_TAXONOMIES_SELECTION,
        pinned: false,
      },
      {
        key: 35,
        itemName: "Google Product Taxonomies",
        link: SUB_ROUTE_MISCELLANEOUS_GOOGLE_PRODUCT_TAXONOMIES,
        pinned: false,
      },
      {
        key: 36,
        itemName: "Users",
        link: "/users",
        disabled: true,
        pinned: false,
      },
    ],
  },
];

const Sidebar = (): JSX.Element => {
  // MUI Drawer by defult has the fixed positon and it opens on top of other elements in a the page. Which -
  // means we can not access other elements. In this app ppl used it by doing a hack on every other part of-
  // the UI which is not a good UI practice. It is affecting most of the elements and it causes using-
  // absolute positioning for all other component.
  // For a solution, I will follow a more simple structure. Having HTML elements in mind, I will use the equivalant of ul>li pair in MUI for the menu items So I will use the MUI list inside of some other components if needed.

  return <SidebarContent sidebarItems={sidebarItems} />;
};

export default Sidebar;
