/* eslint-disable no-console */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../index";
import {
  ICatalogInventoryLocationTypesDTO,
  ICatalogInventoryLocationTypesParams,
  IInventoryLocationTypeParams,
} from "./interfaces";
import catalogInventorylocationtype from "services/catalog-inventorylocationtype";

function getAxiosParams(
  catalogInventoryLocationTypesParams: ICatalogInventoryLocationTypesParams,
) {
  const params = new URLSearchParams();
  params.append(
    "offset",
    (
      catalogInventoryLocationTypesParams.page *
      catalogInventoryLocationTypesParams.limit
    ).toString(),
  );
  params.append("limit", catalogInventoryLocationTypesParams.limit.toString());
  params.append("search", catalogInventoryLocationTypesParams.name.toString());

  return params;
}

export const fetchCatalogInventoryLocationTypes = createAsyncThunk(
  "catalog/fetchCatalogInventoryLocationTypes",
  async (_, thunkAPI): Promise<ICatalogInventoryLocationTypesDTO> => {
    const state = thunkAPI.getState() as RootState;
    const params = getAxiosParams(state.inventoryLocationTypes.filterParams);

    return await catalogInventorylocationtype
      .service()
      .fetchCatalogInventoryLocationTypes(params);
  },
);

export const addCatalogInventoryLocationTypeAsync = createAsyncThunk(
  "catalog/addCatalogInventoryLocationTypeAsync",
  async (params: IInventoryLocationTypeParams, thunkAPI) => {
    const response = await catalogInventorylocationtype
      .service()
      .AddInventoryLocationType(params);
    if (response) {
      thunkAPI.dispatch(fetchCatalogInventoryLocationTypes());
    }
    return response;
  },
);

export const editCatalogInventoryLocationTypeAsync = createAsyncThunk<
  void,
  { id: number; params: IInventoryLocationTypeParams },
  { state: RootState }
>(
  "catalog/editCatalogInventoryLocationTypeAsync",
  async ({ id, params }, thunkAPI) => {
    try {
      await catalogInventorylocationtype
        .service()
        .EditInventoryLocationType(id, params);
      thunkAPI.dispatch(fetchCatalogInventoryLocationTypes());
    } catch (error) {
      console.error("Error editing inventory location type:", error);
      throw error;
    }
  },
);

export const deleteInventoryLocationTypeAsync = createAsyncThunk<
  void,
  number,
  { state: RootState }
>(
  "catalog/deleteCatalogInventoryLocationTypeAsync",
  async (id: number, thunkAPI) => {
    try {
      await catalogInventorylocationtype
        .service()
        .deleteInventoryLocationType([id]);
      thunkAPI.dispatch(fetchCatalogInventoryLocationTypes());
    } catch (error) {
      console.error("Error deleting inventory location type:", error);
      throw error;
    }
  },
);
