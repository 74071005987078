import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { ViewType } from "store/catalog";
import { ICatalogProduct, IProductDescription } from "store/catalog/interfaces";

export const createGridRowsProp = (
  columns: GridColDef[],
  products: ICatalogProduct[],
  catalogProductsDescription: IProductDescription[],
  view: ViewType,
): GridRowsProp => {
  if (view === "bulk edit") {
    const bulkEditList = catalogProductsDescription.filter(
      (item) => item != undefined,
    );
    const gridRowsProp: GridRowsProp = bulkEditList?.map((product) => {
      const gridRow: any = {
        id: product.id,
        packagingDimension: {
          length: product.packaging_length,
          height: product.packaging_height,
          width: product.packaging_width,
          weight: product.packaging_weight,
        },
        restock: {
          level: product.qty_restock_level,
          quantity: product.qty_restock_amount,
        },
        marginWithPercent: {
          margin: product.margin,
          percent: product.margin_percent,
        },
      };

      columns.forEach((column) => {
        gridRow[column.field] = product[column.field];
      });

      return gridRow;
    });

    return gridRowsProp;
  } else {
    const gridRowsProp: GridRowsProp = products?.map((product) => {
      const gridRow: any = {
        id: product.id,
        packagingDimension: {
          length: product.packaging_length,
          height: product.packaging_height,
          width: product.packaging_width,
          weight: product.packaging_weight,
        },
        restock: {
          level: product.qty_restock_level,
          quantity: product.qty_restock_amount,
        },
        marginWithPercent: {
          margin: product.margin,
          percent: product.margin_percent,
        },
      };

      columns.forEach((column): any => {
        gridRow[column.field] = product[column.field];
      });

      return gridRow;
    });

    return gridRowsProp;
  }
};
