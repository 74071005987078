import React from "react";
import { Box } from "@mui/material";
import theme from "theme/theme";

interface Props {
  children: React.ReactNode;
}

//At the moment this component uses unchanged sx properties, so they are hard-coded.

const ToolboxArea = ({ children }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 0.2,
        background: "white",
        height: "3.6rem",
        padding: "0 0.2rem",
        width: "fit-content",
        borderRadius: theme.shape.borderRadius,
      }}
    >
      {children}
    </Box>
  );
};

export default ToolboxArea;
