const fonts = {
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  fontSizeH1: "6rem",
  fontSizeH2: "3.75rem",
  fontSizeH3: "3rem",
  fontSizeH4: "2.125rem",
  fontSizeH5: "1.5rem",
  fontSizeH6: "1.4rem",
  fontSizeBody1: "1.3rem",
  fontSizeBody2: "1.2rem",
  fontSizeDefault: "1rem",
  fontSubTitle2: "2rem",
  fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
  button: "1.2rem",
  caption: "1rem",
};

export default fonts;
