import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import { endpoint } from "constants/endpoint";
import {
  BulkDescriptionArgs,
  IBulkDescriotion,
  Product,
  ProductFilters,
} from "store/catalog-bulk-description/interfaces";

export interface Props {
  fetchFilteredProducts(filters: ProductFilters);
  addBulkDescription(params: BulkDescriptionArgs);
  updateBulkDescription(id: number, params: BulkDescriptionArgs);
  updateOnlyBulkEditFixedDescription(id: number, params: Product);
}

export default new Mockable<Props>({
  async fetchFilteredProducts(filters) {
    try {
      const response = await axios.get(
        `${config.api}${endpoint.catalog_products_bulk3_description}`,
        {
          params: filters,
          headers: commonHeaders(),
        },
      );
      return response.data;
    } catch (error: any) {
      throw error;
    }
  },

  async addBulkDescription(
    params: BulkDescriptionArgs,
  ): Promise<IBulkDescriotion> {
    try {
      const response = await axios(
        `${config.api}${endpoint.product_description}`,
        {
          method: "POST",
          headers: commonHeaders(),
          data: JSON.stringify(params),
        },
      );
      if (response.status !== 201) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
      return response.data;
    } catch (error: any) {
      throw error;
    }
  },

  async updateBulkDescription(
    id: number,
    params: BulkDescriptionArgs,
  ): Promise<IBulkDescriotion> {
    try {
      const response = await axios(
        `${config.api}${endpoint.product_description}${id}`,
        {
          method: "PUT",
          headers: commonHeaders(),
          data: JSON.stringify(params),
        },
      );
      if (response.status !== 200) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
      return response.data;
    } catch (error: any) {
      throw error;
    }
  },

  async updateOnlyBulkEditFixedDescription(
    id: number,
    params: Product,
  ): Promise<any> {
    try {
      const response = await axios(
        `${config.api}${endpoint.catalog_products_bulk3_description}${id}`,
        {
          method: "PATCH",
          headers: commonHeaders(),
          data: JSON.stringify(params),
        },
      );
      if (response.status !== 200) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
      return response.data;
    } catch (error: any) {
      throw error;
    }
  },
});
