import React from "react";
import { textFieldProps, textFieldPropsFullWidth } from "../shared";
import { selectAccountDetails, setAccountDetails } from "store/onboarding";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import { useCallback, useState } from "react";
import HandledFooter from "./Footer/HandledFooter";
import ContactDetails from "./Contact";
import { Box, TextField, Typography } from "@mui/material";

interface IAccountDetailsProp {
  first_name?: string;
  last_name?: string;
  email?: string;
  phonenumber?: string;
  company_name?: string;
  category?: string;
}

interface IAccountDetailsErrorProp {
  first_name: boolean;
  last_name: boolean;
  email: boolean;
  phonenumber: boolean;
  company_name: boolean;
  category: boolean;
}

const SupplierAccountDetails = (): JSX.Element => {
  const accountDetails = useAppSelector(selectAccountDetails);
  const dispatch = useAppDispatch();
  const [error, setError] = useState<IAccountDetailsErrorProp>({
    first_name: false,
    last_name: false,
    email: false,
    phonenumber: false,
    company_name: false,
    category: false,
  });

  const changeAccountDetails = useCallback(
    (details: IAccountDetailsProp) => {
      dispatch(setAccountDetails({ ...accountDetails, ...details }));
      setError({ ...error, [Object.keys(details)[0]]: false });
    },
    [accountDetails, dispatch, error],
  );

  const validate = useCallback((): boolean => {
    const validated: IAccountDetailsErrorProp = {
      first_name: !accountDetails.first_name.trim(),
      last_name: !accountDetails.last_name.trim(),
      email:
        accountDetails.email.match(
          /^[\w.]+\+?[\w.]*@[\w-]{2,}\.\w{2,}(\.\w{2,})?$/,
        ) === null,
      phonenumber: accountDetails.phonenumber?.match(/^[\d\s-]+$/) === null,
      company_name: !accountDetails.company_name?.trim(),
      category: !accountDetails.category?.trim(),
    };

    setError(validated);
    return !Object.values(validated).some((e) => e === true);
  }, [accountDetails]);

  return (
    <>
      <Box>
        <Typography variant="h4">Your details</Typography>
        <Typography>
          Great that you would like to be a part of the brands on Ecombooster!
          Please fill in your contact information below or contact us and we
          will get back to you as soon as we can!
        </Typography>
        <ContactDetails />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.3rem",
            padding: "0.5rem 0 0",
          }}
        >
          <Box gap="1.3rem">
            <TextField
              error={error.first_name}
              required
              label="First name"
              id="first-name"
              onChange={(e) =>
                changeAccountDetails({ first_name: e.target.value ?? "" })
              }
              value={accountDetails.first_name ?? ""}
              {...textFieldProps}
            />
            <TextField
              error={error.last_name}
              required
              label="Last name"
              id="last-name"
              onChange={(e) =>
                changeAccountDetails({ last_name: e.target.value ?? "" })
              }
              value={accountDetails.last_name ?? ""}
              {...textFieldProps}
            />
          </Box>
          <TextField
            error={error.email}
            required
            label="Email address"
            id="email-address"
            onChange={(e) =>
              changeAccountDetails({ email: e.target.value ?? "" })
            }
            type="email"
            value={accountDetails.email ?? ""}
            {...textFieldPropsFullWidth}
          />
          <TextField
            error={error.phonenumber}
            label="Phone number"
            required
            id="phone-number"
            onChange={(e) =>
              changeAccountDetails({ phonenumber: e.target.value ?? "" })
            }
            type="text"
            value={accountDetails.phonenumber ?? ""}
            {...textFieldPropsFullWidth}
          />
          <TextField
            error={error.company_name}
            required
            label="Company name"
            id="company-name"
            onChange={(e) =>
              changeAccountDetails({ company_name: e.target.value ?? "" })
            }
            value={accountDetails.company_name ?? ""}
            {...textFieldPropsFullWidth}
          />
          <TextField
            error={error.category}
            required
            label="Category"
            id="category"
            onChange={(e) =>
              changeAccountDetails({ category: e.target.value ?? "" })
            }
            value={accountDetails.category ?? ""}
            {...textFieldPropsFullWidth}
          />
        </Box>
      </Box>
      <HandledFooter validate={validate} next={"Send"} previous={"Go back"} />
    </>
  );
};

export default SupplierAccountDetails;
