import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
const PageNotFoundComponent = (): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      textAlign="center"
    >
      <Typography variant="h1" color="primary" gutterBottom>
        404
      </Typography>
      <Typography variant="h4" color="textSecondary" gutterBottom>
        Oops! The page you are looking for does not exist.
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        You might want to go back to the <Link to="/">home page</Link>.
      </Typography>
      <Button component={Link} to="/" variant="contained" color="primary">
        Go to Home
      </Button>
    </Box>
  );
};

export default PageNotFoundComponent;
