import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPickOrderAsyncSync } from "services/orders/pickOrder";
import { pickStateUpdate } from "store/orders";
import ordersService from "services/orders";
import { RootState } from "../../index";
import { fetchInventoriesAsync } from "store/inventories/thunks";
import { fetchInventoryTransactionsAsync } from "store/inventory-transactions/thunks";
import { ICustomersOrders, IOrderItems } from "../interfaces";

export const fetchPickOrderAsync = createAsyncThunk(
  "pickOrder/fetchPickOrderAsync",
  async (
    params: {
      order_item_id: number;
      location_id: number;
      product_id: number;
      quantity: number;
    },
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as RootState;
    const { pickOrder, orders } = state;
    const res = await fetchPickOrderAsyncSync(
      params.order_item_id,
      params.location_id,
      params.product_id,
      params.quantity,
    );

    const searchParams = new URLSearchParams();
    searchParams.append("order_number", pickOrder.pickOrderId!.toString());
    const { results } = await ordersService
      .service()
      .fetchCustomersOrders(searchParams);

    const changes = orders.orders?.map((order: ICustomersOrders) =>
      order.id === results[0].id ? results[0] : order,
    );
    const inventoryTransactions: Array<number>[] = [];
    changes.forEach((order: ICustomersOrders) => {
      order.order_items.forEach((orderItem: IOrderItems) => {
        orderItem.inventory_transactions.length > 0 &&
          inventoryTransactions.push(orderItem.inventory_transactions);
      });
    });

    await thunkAPI.dispatch(
      fetchInventoryTransactionsAsync(
        new URLSearchParams([["id__in", inventoryTransactions.toString()]]),
      ),
    );

    if (res.result) {
      const orderIds: Array<number> = [];
      orders.orders.forEach((orders: ICustomersOrders) => {
        orders.order_items.forEach((order: IOrderItems) => {
          !orderIds.includes(order.product) && orderIds.push(order.product);
        });
      });

      await thunkAPI.dispatch(
        fetchInventoriesAsync(orderIds.length > 0 ? orderIds.toString() : "0"),
      );

      thunkAPI.dispatch(
        pickStateUpdate({
          changes,
        }),
      );
    }

    const orderItem = results[0].order_items.find(
      (order) => order.id === params.order_item_id,
    );

    const { prod_sku, prod_name }: IOrderItems = orderItem!;

    return {
      result: `Picked ${params.quantity}x[${prod_sku}]${prod_name} for order ${results[0].order_number}. `,
    };
  },
);
