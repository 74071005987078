import * as React from "react";
import Searchbar from "components/filter-products/Searchbar";
import { Box } from "@mui/material";
import SyncOrderDropdown from "../pick-pack-order-page/parts/SyncOrderDrpdown";
import Filter from "components/filter-products/Filter";
import ProcurementOrdersTable from "./parts/ProcurementOrdersTable";
import AvailableFilters from "./parts/AvailableFilters";
import useAppSelector from "hooks/useAppSelector";
import { selectProcurementOrdersState } from "store/procurement-orders";
import useBreakpoint from "hooks/useBreakpoint";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import theme from "theme/theme";

const Orders = (): JSX.Element => {
  const { count } = useAppSelector(selectProcurementOrdersState);
  const [expandAll, setExpandAll] = React.useState(false);
  const isMediumScreen = useBreakpoint("medium");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" />
        <Box sx={{ display: "flex", gap: 1 }}>
          <ToolboxArea>
            <ToolInfoTooltip title={expandAll ? "Collapsed all" : "Expand all"}>
              <ToolIconContainer onClick={() => setExpandAll(!expandAll)}>
                {expandAll ? (
                  <UnfoldLessIcon
                    fontSize="large"
                    sx={{
                      color: theme.palette.primary.main,
                      stroke: theme.palette.primary.main,
                      strokeWidth: 1,
                    }}
                  />
                ) : (
                  <UnfoldMoreIcon
                    fontSize="large"
                    sx={{
                      color: theme.palette.primary.main,
                      stroke: theme.palette.primary.main,
                      strokeWidth: 1,
                    }}
                  />
                )}
              </ToolIconContainer>
            </ToolInfoTooltip>
          </ToolboxArea>
          <SyncOrderDropdown />
        </Box>
      </Box>

      <Filter viewName="procurement_orders">
        <AvailableFilters />
      </Filter>

      <ProcurementOrdersTable expandAll={expandAll} />
    </>
  );
};

export default Orders;
