import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import logo from "assets/images/EcomboosterLogo.png";
import useAppDispatch from "hooks/useAppDispatch";
import { login } from "store/auth/thunks";
import { displaySnackbar } from "store/snackbar";
import { Button, Typography } from "@mui/material";
import theme from "theme/theme";

const textFieldProps: TextFieldProps = {
  fullWidth: true,
  margin: "normal",
  size: "small",
  variant: "outlined",
};

const Login = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      await dispatch(login({ email: username, password }));
      navigate("/");
    } catch {
      dispatch(
        displaySnackbar({
          message: "Login failed, try again.",
          action: null,
          open: false,
        }),
      );
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleOnSubmit}
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <CardMedia sx={{ marginBottom: "1rem" }}>
          <img width="170px" src={logo} />
        </CardMedia>
        <Typography variant="h4">Log in</Typography>
        <Typography>
          Log in with your details below. If you have forgotten your password,
          see link below &quote;Forgot password?&quote;.
        </Typography>
        <TextField
          label="Username"
          id="username"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          {...textFieldProps}
        />
        <TextField
          label="Password"
          id="password"
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          value={password}
          {...textFieldProps}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button type="submit" variant="contained">
          Log in
        </Button>
        <Typography
          component="div"
          sx={{
            textAlign: "end",
            fontSize: theme.typography.subtitle1,
            a: {
              color: theme.palette.primary.main,
              textDecoration: "none",
            },
          }}
        >
          <Link to="/login/reset-password">Forgot password?</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
