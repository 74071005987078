import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import useAppSelector from "hooks/useAppSelector";
import { ordersSelectors } from "store/orders";
import { billingAddress } from "../utils";

export default function ShippingAddress(): JSX.Element {
  const [editCustomersOrder, setEditCustomersOrder] = useState({
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    postCode: "",
    city: "",
    state: "",
    country: "",
    email: "",
    phoneNumber: "",
    company: "",
  });

  const { order } = useAppSelector(ordersSelectors);

  useEffect(() => {
    if (order) {
      setEditCustomersOrder({
        firstName: order.shipping_address_first_name,
        lastName: order.shipping_address_last_name,
        addressLine1: order.shipping_address_address_line1,
        addressLine2: order.shipping_address_address_line2,
        postCode: order.shipping_address_postcode,
        city: order.shipping_address_city,
        country: order.shipping_address_country,
        state: order.shipping_address_state,
        email: order.shipping_address_email,
        phoneNumber: order.shipping_address_phonenumber,
        company: order.shipping_address_company,
      });
    }
  }, [order]);

  return (
    <Grid item lg={5} xs={12}>
      <List dense>
        <Typography fontWeight={900} component="div">
          Shipping Address
        </Typography>
        {billingAddress?.map((OI, index) => (
          <ListItem key={index}>
            <Stack direction="row" spacing={5}>
              <ListItemText sx={{ minWidth: 150 }} primary={OI.field} />
              <ListItemText
                sx={{ maxWidth: 350 }}
                primary={
                  editCustomersOrder[OI.identifier]
                    ? editCustomersOrder[OI.identifier]
                    : "-"
                }
              />
            </Stack>
          </ListItem>
        ))}
      </List>
    </Grid>
  );
}
