import { createSlice } from "@reduxjs/toolkit";
import config from "../../config";
import { RootState } from "../index";
import { ICatalogCategoriesState } from "./interfaces";
import { fetchCatalogCategoriesAsync } from "./thunks";
import { httpErrorHandler } from "utils/error-response";

export const initialState: ICatalogCategoriesState = {
  loading: false,
  categories: [],
  category: {
    id: 0,
    url: "",
    slug: "",
    created_at: "",
    updated_at: "",
    url_local: "",
    db_id: "",
    account: 0,
    company: 0,
    parent: 0,
    title: "",
    sub_title: "",
    include_root_in_slug: true,
    order: 0,
    image: null,
    default_category: false,
    news_category: false,
    active: false,
    visible: false,
    comments: "",
    lft: 0,
    rght: 0,
    tree_id: 0,
    level: 0,
    procurement_category_ids: [],
  },
  count: 0,
  catalogCategoriesLoaded: false,
  filterParams: {
    page: 0,
    limit: 25,
    slug: "",
    title: "",
    catalogCategoryCreatedGte: "",
    catalogCategoryCreatedLte: "",
    catalogCategoryUpdatedGte: "",
    catalogCategoryUpdatedLte: "",
    search: "",
  },

  error: {},
};

const catalogCategoriesSlice = createSlice({
  name: "catalogCategories",
  initialState,
  reducers: {
    setCatalogCatagoriesParams: (state, action) => {
      state.catalogCategoriesLoaded = false;
      state.filterParams = { ...state.filterParams, ...action.payload };
    },
    setLoadingStatus: (state, { payload }) => {
      state.catalogCategoriesLoaded = payload;
    },
    resetAllFilters: (state) => {
      state.catalogCategoriesLoaded = false;
      state.filterParams = {
        page: 0,
        limit: 25,
        slug: "",
        title: "",
        catalogCategoryCreatedGte: "",
        catalogCategoryCreatedLte: "",
        catalogCategoryUpdatedGte: "",
        catalogCategoryUpdatedLte: "",
        search: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatalogCategoriesAsync.fulfilled, (state, action) => {
        state.categories = action.payload.results;
        state.catalogCategoriesLoaded = true;
        state.nextPageUrl = action.payload.next;
        state.previousPageUrl = action.payload.previous;
        state.count = action.payload.count;
      })
      .addCase(fetchCatalogCategoriesAsync.rejected, (state, { error }) => {
        state.loading = false;
        state.error = {
          ...httpErrorHandler(error),
          url: config.api + "/catalog/categories/",
        };
      });
  },
});

export const selectCatalogCategoriesState = (
  state: RootState,
): ICatalogCategoriesState => state.catalogCategories;

export const { setLoadingStatus, setCatalogCatagoriesParams, resetAllFilters } =
  catalogCategoriesSlice.actions;

export default catalogCategoriesSlice.reducer;
