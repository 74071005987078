import React from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { useCallback, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IProcurementCategory } from "store/procurement-categories/interfaces";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import { Alert, Snackbar, Stack } from "@mui/material";
import {
  clearCategoryMessage,
  selectProcurementCategoriesState,
} from "store/procurement-categories";
import SlugDetailsCard from "./SlugDetailsCard";
import MappedProductsTable from "./MappedProductsTable";
import { ICatalogCategory } from "store/catalog-categories/interfaces";
import AccordionFields from "../../parts/AccordionFields";

interface IFilterSelectProps {
  procurementCategory: IProcurementCategory;
  onProcurementCategoryChanged(
    field: string,
    value: number | string | ICatalogCategory[],
  ): void;
}

const UpdateProcurementCategory = ({
  procurementCategory,
  onProcurementCategoryChanged,
}: IFilterSelectProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState(true);
  const [editProcurementCategory, setEditProcurementCategory] = useState({
    name: procurementCategory.name,
    supplier: procurementCategory.supplier,
    url: procurementCategory.url,
    parent: procurementCategory.parent,
    description: procurementCategory.description,
    descriptionHtml: procurementCategory.description_html,
  });

  const { success, error } = useAppSelector(selectProcurementCategoriesState);

  const updateProcurementCategory = useCallback(
    (field: string, value: number | string | ICatalogCategory[]) => {
      setEditProcurementCategory({
        ...editProcurementCategory,
        [field]: value,
      });
      onProcurementCategoryChanged(field, value);
    },
    [editProcurementCategory, onProcurementCategoryChanged],
  );

  return (
    <Box>
      <Snackbar
        open={!!success || !!error.description}
        autoHideDuration={4000}
        onClose={() => {
          dispatch(clearCategoryMessage());
        }}
      >
        <Alert severity={success ? "success" : "error"} sx={{ width: "100%" }}>
          {success || error.description}
        </Alert>
      </Snackbar>
      <Accordion
        expanded={expanded}
        square
        disableGutters
        elevation={0}
        onChange={() => setExpanded((prev) => !prev)}
      >
        <AccordionSummary
          sx={{
            paddingLeft: 0,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <Stack direction="row" spacing={2}>
            <SlugDetailsCard />
            <MappedProductsTable />
          </Stack>
        </AccordionSummary>
        <AccordionFields
          updateProcurementCategory={updateProcurementCategory}
          procurementCategory={editProcurementCategory}
        />
      </Accordion>
      <Divider />
    </Box>
  );
};

export default UpdateProcurementCategory;
