/* eslint-disable no-console */
import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import { endpoint } from "constants/endpoint";
import {
  ICatalogInventoryLocationTypesDTO,
  IInventoryLocationTypeParams,
} from "store/catalog-inventorylocationtype/interfaces";

export interface ICatalogInventoryLocationTypeService {
  fetchCatalogInventoryLocationTypes(
    params: URLSearchParams,
  ): Promise<ICatalogInventoryLocationTypesDTO>;
  AddInventoryLocationType(
    params: IInventoryLocationTypeParams,
  ): Promise<ICatalogInventoryLocationTypesDTO>;
  EditInventoryLocationType(
    id: number,
    params: IInventoryLocationTypeParams,
  ): Promise<ICatalogInventoryLocationTypesDTO>;
  deleteInventoryLocationType(ids: number[]): Promise<void>;
}

export default new Mockable<ICatalogInventoryLocationTypeService>({
  async fetchCatalogInventoryLocationTypes(
    params: URLSearchParams,
  ): Promise<ICatalogInventoryLocationTypesDTO> {
    try {
      const res = await axios(
        `${config.api}${endpoint.catalog_inventory_location_types}?${params}`,
        {
          method: "GET",
          headers: commonHeaders(),
        },
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error(
            "Unexpected response format in >> async fetchCatalogInventoryLocationTypes <<",
          ),
        );
      }
    } catch (err: any) {
      console.error("API call failed", err);
      return Promise.reject(
        "Couldn't get catalog inventory location types data",
      );
    }
  },

  async AddInventoryLocationType(
    params: IInventoryLocationTypeParams,
  ): Promise<ICatalogInventoryLocationTypesDTO> {
    const res = await axios(
      `${config.api}${endpoint.catalog_inventory_location_types}`,
      {
        method: "POST",
        headers: commonHeaders(),
        data: JSON.stringify(params),
      },
    );
    if (res.status === 201) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async EditInventoryLocationType(
    id: number,
    params: IInventoryLocationTypeParams,
  ): Promise<ICatalogInventoryLocationTypesDTO> {
    const res = await axios(
      `${config.api}${endpoint.catalog_inventory_location_types}${id}/`,
      {
        method: "PUT",
        headers: commonHeaders(),
        data: JSON.stringify(params),
      },
    );
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async deleteInventoryLocationType(id): Promise<void> {
    const res = await axios(
      `${config.api}${endpoint.catalog_inventory_location_types}${id}/`,
      {
        method: "DELETE",
        headers: commonHeaders(),
      },
    );
    if (res.status === 204) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },
});
