import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import {
  IInventoryLocations,
  IInventoryLocationsList,
  IUpdateInventoryLocationsListParams,
  IUpdateListInventoryLocationsListParams,
  AddInventoryLocationsListArgs,
  IInventoryLocationsTypesList,
} from "store/catalog-inventory-locations/interfaces";
import { Mockable } from "../index";
import { endpoint } from "constants/endpoint";

export interface IInventoryLocationsListService {
  fetchInventoryLocationsList(
    params: URLSearchParams,
  ): Promise<IInventoryLocations>;
  fetchInventoryLocationsListById(id: number): Promise<IInventoryLocationsList>;
  addInventoryLocationsItems(
    params: AddInventoryLocationsListArgs,
  ): Promise<IInventoryLocationsList>;
  UpdateInventoryLocationsList(
    params: IUpdateInventoryLocationsListParams,
  ): Promise<IInventoryLocationsList>;
  UpdateListInventoryLocationsList(
    params: IUpdateListInventoryLocationsListParams,
  ): Promise<number>;
  deleteProcurementInventoryLocations(id: number[]): Promise<number>;
  fetchTypes(): Promise<IInventoryLocationsTypesList>;
}

// gets the list of procurement suppliers whose name contains the searchText, or all suppliers if the searchText is an empty string ("")
export default new Mockable<IInventoryLocationsListService>({
  async fetchInventoryLocationsList(params): Promise<IInventoryLocations> {
    const res = await axios(
      `${config.api}${endpoint.catalog_inventory_locations}`,
      {
        params: params,
        headers: commonHeaders(),
      },
    );
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },
  async fetchInventoryLocationsListById(
    id: number,
  ): Promise<IInventoryLocationsList> {
    const res = await axios(
      `${config.api}${endpoint.catalog_inventory_locations}${id}/`,
      {
        method: "GET",
        headers: commonHeaders(),
      },
    );
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async addInventoryLocationsItems(
    params: AddInventoryLocationsListArgs,
  ): Promise<IInventoryLocationsList> {
    const res = await axios(
      `${config.api}${endpoint.catalog_inventory_locations}`,
      {
        method: "POST",
        headers: commonHeaders(),
        data: JSON.stringify(params),
      },
    );
    if (res.status === 201) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async UpdateInventoryLocationsList(
    params: IUpdateInventoryLocationsListParams,
  ): Promise<IInventoryLocationsList> {
    try {
      const id = params.id;
      params.abbreviation_slug = !params.abbreviation_slug
        ? params.name
        : params.abbreviation_slug;

      const res = await axios(
        `${config.api}${endpoint.catalog_inventory_locations}${id}/`,
        {
          method: "PUT",
          headers: commonHeaders(),
          data: JSON.stringify(params),
        },
      );
      if (res.status === 200) {
        return res.data;
      }
    } catch (ex) {
      return Promise.reject("Something went wrong with the fetch");
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async deleteProcurementInventoryLocations(id: any): Promise<number> {
    const res = await axios(
      `${config.api}${endpoint.catalog_inventory_locations}${id}/`,
      {
        method: "DELETE",
        headers: commonHeaders(),
      },
    );
    if (res.status === 204) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },

  async UpdateListInventoryLocationsList(params): Promise<number> {
    const newRows = params?.rows?.idRowsLookup;
    let result = 0;

    for (const [key, value] of Object.entries(newRows)) {
      try {
        const obj = value;
        obj.abbreviation_slug = !obj.abbreviation_slug
          ? obj.name
          : obj.abbreviation_slug;
        const res = await axios(
          `${config.api}${endpoint.catalog_inventory_locations}${key}/`,
          {
            method: "PUT",
            headers: commonHeaders(),
            data: obj,
          },
        );
        if (res.status === 200) {
          result += 1;
        }
      } catch {
        return Promise.reject(" async UpdateListInventoryLocationsList ");
      }
    }
    return result;
  },
  async fetchTypes(): Promise<IInventoryLocationsTypesList> {
    const res = await axios(
      `${config.api}${endpoint.catalog_inventory_locations_types}`,
      {
        method: "GET",
        headers: commonHeaders(),
      },
    );
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject("Something went wrong with the fetch");
  },
});
