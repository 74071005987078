import React from "react";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import { selectUserRole, setUserRole } from "store/onboarding";
import { UserRole } from "store/onboarding/interfaces";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import DefaultFooter from "./Footer/DefaultFooter";

const SetUserRole = (): JSX.Element => {
  const userRole = useAppSelector(selectUserRole);
  const dispatch = useAppDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserRole(event.target.value as UserRole));
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="h4">E-commerce or brand?</Typography>
        <Typography>
          Are you an E-commerce business or supplier/brand?
        </Typography>
        <FormControl>
          <RadioGroup
            defaultValue={userRole}
            name="radio-buttons-group"
            value={userRole}
            onChange={handleChange}
          >
            <FormControlLabel
              value="business"
              control={<Radio />}
              label="E-commerce business"
            />
            <FormControlLabel
              value="supplier"
              control={<Radio />}
              label="Supplier / Brand"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <DefaultFooter />
    </Box>
  );
};

export default SetUserRole;
