import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import { ICurrency } from "./../../store/currencies/interfaces";
import { endpoint } from "constants/endpoint";

export interface ICurrenciesService {
  fetchCurrencies(): Promise<ICurrency[]>;
}

export default new Mockable<ICurrenciesService>({
  async fetchCurrencies(): Promise<ICurrency[]> {
    const res = await axios(`${config.api}${endpoint.misc_currencies}}`, {
      headers: commonHeaders(),
    });

    if (res.status === 200) {
      return res.data.results;
    }

    return Promise.reject("Couldn't get suppliers data");
  },
});
