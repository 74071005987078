import React from "react";
import useAppSelector from "hooks/useAppSelector";
import {
  selectTaxonomies,
  setTaxonomiesParams,
  setUpdateMessage,
} from "store/taxonomies";
import useAppDispatch from "hooks/useAppDispatch";
import {
  copyMiscGoogleProductsAsync,
  fetchTaxonomies,
  removeMiscGoogleProductsAsync,
} from "store/taxonomies/thunks";
import ColumnsForDataGrid from "./parts/ColumnsForDataGrid";
import { useCallback, useEffect, useRef, useState } from "react";
import Searchbar from "components/filter-products/Searchbar";
import { useSearchParams } from "react-router-dom";
import Filter from "components/filter-products/Filter";
import AvailableFilters from "./parts/AvailableFilters";
import { selectAuthenticatedUser } from "store/auth";
import { Alert, Box, Snackbar } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { IUser } from "store/auth/interfaces";
import GridContainer from "components/common-components/GridContainer";
import theme from "theme/theme";
import useBreakpoint from "hooks/useBreakpoint";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ToolboxArea from "components/common-components/ToolboxArea";
import CommonConfirmDialog from "components/common-components/CommonConfirmDialog";

const Taxonomies = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { taxonomies, taxonomiesLoaded, updateMessage } =
    useAppSelector(selectTaxonomies);
  const [searchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const user = useAppSelector(selectAuthenticatedUser) as IUser;
  const handleCopyTimeoutRef = useRef<number | null>(null);
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState(false);
  const [
    isNotificationVisibleToChoseOneRow,
    setIsNotificationVisibleToChoseOneRow,
  ] = useState(false);
  const isMediumScreen = useBreakpoint("medium");
  const filter = searchParams.get("customer_taxonomies__isnull");

  useEffect(() => {
    dispatch(fetchTaxonomies(searchParams));
  }, [dispatch, searchParams]);

  const searchHandler = (value: string) => {
    dispatch(setTaxonomiesParams({ search: value, page: 0 }));
  };

  const handleCopy = useCallback(() => {
    if (handleCopyTimeoutRef.current) {
      clearTimeout(handleCopyTimeoutRef.current);
    }

    dispatch(
      copyMiscGoogleProductsAsync({
        ids: selectionModel as any,
        userId: user.id,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(setUpdateMessage("Copy Successful."));
        setShow(true);
      })
      .finally(() => {
        handleCopyTimeoutRef.current = window.setTimeout(() => {
          dispatch(fetchTaxonomies(searchParams));
        }, 500);
      });
  }, [dispatch, selectionModel, user.id, searchParams]);

  useEffect(() => {
    return () => {
      if (handleCopyTimeoutRef.current) {
        clearTimeout(handleCopyTimeoutRef.current);
      }
    };
  }, []);

  const deleteGoogleProductsTaxonomies = useCallback(
    async (idsArray: any) => {
      const ids = idsArray;

      dispatch(
        removeMiscGoogleProductsAsync({
          ids: ids,
        }),
      )
        .then(() => {
          dispatch(setUpdateMessage("Remove Successful."));
          setShow(true);
        })
        .catch(() => {
          dispatch(setUpdateMessage("Remove Failed."));
        })
        .finally(() => {
          dispatch(fetchTaxonomies(searchParams));
        });
    },
    [dispatch, selectionModel, searchParams],
  );

  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelectionModel(newSelectionModel);
  };

  const handleDeleteBtnClick = (rowIds: any[]) => {
    if (rowIds.length > 0) {
      setIsDeleteConfirmDialogOpen(true);
    } else {
      setIsNotificationVisibleToChoseOneRow(true);
    }
  };

  const onCancleClick = () => {
    setIsDeleteConfirmDialogOpen(false);
  };

  const onApproveClick = () => {
    deleteGoogleProductsTaxonomies(selectionModel);
    setIsDeleteConfirmDialogOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" onChange={searchHandler} />
        <ToolboxArea>
          <ToolInfoTooltip title="Add Markup (coming soon)">
            <ToolIconContainer disabled>
              <AddCircleIcon
                fontSize="large"
                sx={{
                  color: theme.palette.text.disabled,
                  stroke: theme.palette.text.disabled,
                  strokeWidth: 1,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>

          {filter === "true" ? (
            <ToolInfoTooltip title="Copy to my selection">
              <ToolIconContainer disabled onClick={handleCopy}>
                <ContentCopyOutlinedIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.primary.light,
                    stroke: theme.palette.primary.light,
                    strokeWidth: 1,
                  }}
                />
              </ToolIconContainer>
            </ToolInfoTooltip>
          ) : filter === "false" ? (
            <ToolInfoTooltip title="Remove from my selection">
              <ToolIconContainer
                onClick={() => handleDeleteBtnClick(selectionModel)}
              >
                <DeleteForeverIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.error.main,
                  }}
                />
              </ToolIconContainer>
            </ToolInfoTooltip>
          ) : filter === null ? (
            <>
              <ToolInfoTooltip title="Copy to my selection (Active after filter)">
                <ToolIconContainer disabled>
                  <ContentCopyOutlinedIcon
                    fontSize="large"
                    sx={{
                      color: theme.palette.text.disabled,
                      strokeWidth: 1,
                    }}
                  />
                </ToolIconContainer>
              </ToolInfoTooltip>

              <ToolInfoTooltip title="Remove from my selection (Active after filter)">
                <ToolIconContainer disabled>
                  <DeleteForeverIcon
                    fontSize="large"
                    sx={{
                      color: theme.palette.text.disabled,
                    }}
                  />
                </ToolIconContainer>
              </ToolInfoTooltip>
            </>
          ) : (
            <></>
          )}
        </ToolboxArea>
      </Box>

      <Filter viewName="google_product_taxonomies">
        <AvailableFilters />
      </Filter>

      <GridContainer>
        <DataGridPro
          loading={!taxonomiesLoaded}
          rows={taxonomies}
          columns={ColumnsForDataGrid()}
          disableColumnMenu
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={handleSelectionModelChange}
          checkboxSelection
          pagination
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
        />
      </GridContainer>

      <Snackbar
        open={isNotificationVisibleToChoseOneRow}
        autoHideDuration={4000}
        onClose={() => setIsNotificationVisibleToChoseOneRow(false)}
      >
        <Alert variant="standard" severity="info">
          At least one row should be selected!
        </Alert>
      </Snackbar>

      <CommonConfirmDialog
        isOpen={isDeleteConfirmDialogOpen}
        content="Are you sure you want to delete the inventory location(s)?"
        affirmativeButtonText="Delete"
        negativeButtonText="Cancel"
        onApprove={onApproveClick}
        onCancel={onCancleClick}
      />

      <Snackbar
        open={show}
        autoHideDuration={4000}
        onClose={() => setShow(false)}
      >
        <Alert>{updateMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default Taxonomies;
