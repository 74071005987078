import React, { useEffect, useState } from "react";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import UpdateProcurementCategory from "./parts/UpdateProcurementCategory";
import useAppSelector from "hooks/useAppSelector";
import { selectProcurementCategoriesState } from "store/procurement-categories";
import {
  fetchProcurementCategoriesAsync,
  fetchProcurementCategoryAsync,
  updateProcurementCategoryAsync,
} from "store/procurement-categories/thunks";
import useAppDispatch from "hooks/useAppDispatch";
import { IUpdateProcurementCategoryDTO } from "store/procurement-categories/interfaces";
import { fetchSuppliers } from "store/suppliers/thunks";
import { selectSuppliers } from "store/suppliers";
import { updateProcurementCategoryFn } from "../utils";
import SafeSave from "components/SafeSave";
import theme from "theme/theme";
import { ISupplier } from "store/suppliers/interfaces";

const EditProcurementCategoryPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { category } = useAppSelector(selectProcurementCategoriesState);
  const suppliers = useAppSelector(selectSuppliers);

  const [update, setUpdate] = useState({
    name: category && category.name,
    description: category && category.description,
    description_html: "",
    url: category && category.url,
    supplier: category && category.supplier,
    parent: category && category.parent,
    catalog_categories_auto_create:
      category && category.catalog_categories_auto_create,
  });
  const [loading, setLoading] = useState(false);

  const procurementCategoryCallback = updateProcurementCategoryFn(
    setUpdate,
    update,
  );

  const productId = new URLSearchParams(location.search).get("category")!;

  const { procurementCategoriesLoaded } = useAppSelector(
    selectProcurementCategoriesState,
  );

  useEffect(() => {
    if (!procurementCategoriesLoaded) {
      dispatch(fetchProcurementCategoriesAsync(new URLSearchParams()));
    }
  }, [dispatch, procurementCategoriesLoaded]);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchProcurementCategoryAsync(productId))
      .unwrap()
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, productId]);

  useEffect(() => {
    dispatch(fetchSuppliers());
  }, [dispatch]);

  useEffect(() => {
    setUpdate({
      name: category && category.name,
      description: category && category.description,
      description_html: category && category.description_html,
      url: category && category.url,
      supplier: category && category.supplier,
      parent: category && category.parent,
      catalog_categories_auto_create:
        category && category.catalog_categories_auto_create,
    });
  }, [category]);

  /*   const updateProcurementCategory = () => {
    setLoading(true);
    const foundSupplier = suppliers.find((supplier) => supplier.id === update.supplier);
    const objectTobeUpdated: IUpdateProcurementCategoryDTO = {
      ...update,
      account: foundSupplier && foundSupplier.account_id,
      company: foundSupplier && foundSupplier.company_id,
    };
    dispatch(updateProcurementCategoryAsync({ id: productId, object: objectTobeUpdated }))
      .unwrap()
      .finally(() => {
        setLoading(false);
      });
  }; */

  const getCategoryUpdateData = () => {
    const foundSupplier = suppliers.find(
      (supplier: ISupplier) => supplier.id === update.supplier,
    );
    const objectTobeUpdated: IUpdateProcurementCategoryDTO = {
      ...update,
      account: foundSupplier && foundSupplier.account_id,
      company: foundSupplier && foundSupplier.company_id,
    };
    return { id: productId, object: objectTobeUpdated };
  };

  return (
    <>
      <Box
        sx={{
          background: "white",
          borderRadius: "4px",
          padding: "1rem",
        }}
      >
        <Box sx={{ overflowY: "scroll", height: "calc(100% - 14.2rem)" }}>
          {loading ? (
            <Backdrop
              sx={{
                color: theme.palette.light.main,
                backgroundColor: theme.palette.action.selected,
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <CircularProgress color="inherit" sx={{ ml: "22vw" }} />
            </Backdrop>
          ) : null}
          {category && (
            <>
              <UpdateProcurementCategory
                key={category.id}
                procurementCategory={category}
                onProcurementCategoryChanged={procurementCategoryCallback}
                {...category}
              />
              <SafeSave
                thunk={updateProcurementCategoryAsync}
                getData={getCategoryUpdateData}
                retryForMinutes={3}
                retryAfterSeconds={10}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default EditProcurementCategoryPage;
