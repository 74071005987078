import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { SyncOrder } from "./interfaces";
import { fetchSyncOrder } from "./thunks";

const initialState: SyncOrder = {
  message: "",
  loading: false,
};

export const syncOrderSlice = createSlice({
  name: "syncOrder",
  initialState,
  reducers: {
    clearSync7DaysMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSyncOrder.fulfilled, (state, action) => {
      state.message = action.payload?.message;
      state.loading = false;
    });
    builder.addCase(fetchSyncOrder.pending, (state, action) => {
      state.message = "";
      state.loading = true;
    });
  },
});

export const selectSyncOrderState = (state: RootState): SyncOrder =>
  state.ordersSync;
export const { clearSync7DaysMessage } = syncOrderSlice.actions;

export default syncOrderSlice.reducer;
