import React, { useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Modal, Snackbar, Typography } from "@mui/material";
import GridContainer from "components/common-components/GridContainer";
import { DataGridPro, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import useFileUrls from "./hooks/useFileUrls";
import useFileUrlModal from "./hooks/useFileUrlModal";
import columns from "./parts/ColumnsForDataGrid";
import Searchbar from "components/filter-products/Searchbar";
import useBreakpoint from "hooks/useBreakpoint";
import theme from "theme/theme";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Filter from "components/filter-products/Filter";
import AvailableFilters from "./parts/AvailableFilters";
import { useSelector } from "react-redux";
import { selectUpdateMessage } from "store/catalog-file-urls";
import AddFileUrlModal from "./parts/AddFileUrlModal";
import EditFileUrlModal from "./parts/EditFileUrlModal";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import CommonConfirmDialog from "components/common-components/CommonConfirmDialog";

// Mainly rendering and view
const ProductCatalogFileUrlsPage = (): JSX.Element => {
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [showNotification, setShowNotification] = useState(false);
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState(false);
  const [
    isNotificationVisibleToChoseOneRow,
    setIsNotificationVisibleToChoseOneRow,
  ] = useState(false);

  const isMediumScreen = useBreakpoint("medium");
  const updateMessage = useSelector(selectUpdateMessage);
  const {
    localResults,
    loading,
    sortModel,
    handleSortModelChange,
    handleDelete,
  } = useFileUrls({ rowSelectionModel, setShowNotification });
  const {
    isModalOpen,
    isRowSelected,
    shouldBeDisable,
    selectedId,
    url,
    sourceUrl,
    priority,
    title,
    removedFromSource,
    account,
    company,
    product,
    openModal,
    closeModal,
    setTitle,
    setUrl,
    setSourceUrl,
    setPriority,
    setRemovedFromSource,
    setAccount,
    setCompany,
    setProduct,
  } = useFileUrlModal(localResults, rowSelectionModel, setRowSelectionModel);

  const handleDeleteBtnClick = (rowSelectionModel: any) => {
    if (rowSelectionModel.length > 0) {
      setIsDeleteConfirmDialogOpen(true);
    } else {
      setIsNotificationVisibleToChoseOneRow(true);
    }
  };

  const onCancleClick = () => {
    setIsDeleteConfirmDialogOpen(false);
  };

  const onApproveClick = () => {
    handleDelete();
    setIsDeleteConfirmDialogOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Searchbar placeholder="Search URL" />

        <ToolboxArea>
          {!isRowSelected ? (
            <ToolInfoTooltip title="Add File URL">
              <ToolIconContainer disabled={shouldBeDisable} onClick={openModal}>
                <AddCircleIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.primary.main,
                    stroke: theme.palette.primary.main,
                    strokeWidth: 1,
                  }}
                />
              </ToolIconContainer>
            </ToolInfoTooltip>
          ) : (
            <ToolInfoTooltip title="Edit File URL">
              <ToolIconContainer disabled={shouldBeDisable} onClick={openModal}>
                <EditIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.warning.main,
                    stroke: theme.palette.warning.main,
                    strokeWidth: 2,
                  }}
                />
              </ToolIconContainer>
            </ToolInfoTooltip>
          )}
          <ToolInfoTooltip title="Delete File URL">
            <ToolIconContainer
              onClick={() => handleDeleteBtnClick(rowSelectionModel)}
            >
              <DeleteForeverIcon
                fontSize="large"
                sx={{
                  color: theme.palette.error.main,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>
        </ToolboxArea>
      </Box>

      <Filter viewName="file-urls" supplierInfoRequired={true}>
        <AvailableFilters />
      </Filter>

      <GridContainer>
        <DataGridPro
          loading={loading}
          rows={localResults}
          columns={columns}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            //newRowSelectionModel is [ids] of selected rows
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          pagination
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
        />
      </GridContainer>

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "60vw",
            margin: `${theme.spacing(5)} auto`,
            bgcolor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[24],
            padding: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            id="modal-modal-title"
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" component="h2">
              {!isRowSelected ? "Add File URL" : "Edit File URL"}
            </Typography>
            <CloseIcon
              sx={{ fontSize: "18", cursor: "pointer" }}
              onClick={closeModal}
            />
          </Box>
          <Box id="modal-modal-description">
            {!isRowSelected ? (
              <Box>
                <AddFileUrlModal
                  closeModal={closeModal}
                  setShowNotification={setShowNotification}
                />
              </Box>
            ) : (
              <EditFileUrlModal
                id={selectedId}
                url={url}
                sourceUrl={sourceUrl}
                priority={priority}
                title={title}
                removedFromSource={removedFromSource}
                account={account}
                company={company}
                product={product}
                setUrl={setUrl}
                setSourceUrl={setSourceUrl}
                setPriority={setPriority}
                setTitle={setTitle}
                setRemovedFromSource={setRemovedFromSource}
                setAccount={setAccount}
                setCompany={setCompany}
                setProduct={setProduct}
                closeModal={closeModal}
                setShowNotification={setShowNotification}
              />
            )}
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={isNotificationVisibleToChoseOneRow}
        autoHideDuration={4000}
        onClose={() => setIsNotificationVisibleToChoseOneRow(false)}
      >
        <Alert variant="standard" severity="info">
          At least one row should be selected!
        </Alert>
      </Snackbar>

      <CommonConfirmDialog
        isOpen={isDeleteConfirmDialogOpen}
        content={"Are you sure you want to delete the file url(s)?"}
        affirmativeButtonText="Delete"
        negativeButtonText="Cancel"
        onApprove={onApproveClick}
        onCancel={onCancleClick}
      />

      <Snackbar
        open={showNotification}
        autoHideDuration={4000}
        onClose={() => setShowNotification(false)}
      >
        <Alert>{updateMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default ProductCatalogFileUrlsPage;
