import React from "react";
import { Button, Link, Stack, Typography } from "@mui/material";

export interface IErrorMessage {
  code?: number;
  description?: string;
  url?: string;
  other?: string;
}

export default function ErrorPage({
  code = 500,
  description = "Internal Server Error.",
  url,
}: IErrorMessage): JSX.Element {
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
      }}
    >
      <Typography variant="h1">{code}</Typography>
      <Typography variant="h6">{description}</Typography>
      {url && (
        <>
          <Typography>Error occurs while accessing the url:</Typography>
          <Link component="button" variant="body2">
            {url}
          </Link>
        </>
      )}

      <Link href="/">
        <Button sx={{ mt: 1 }} variant="contained">
          Homepage
        </Button>
      </Link>
    </Stack>
  );
}
