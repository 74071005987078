import React from "react";
import { Box, Button } from "@mui/material";

interface Props {
  variant: "text" | "outlined" | "contained";
  size: "small" | "medium" | "large";
  color?: string;
  width?: string;
  whiteSpace?: string;
  fontSize?: string | number;
  disabled?: boolean;
  bgColor?: string;
  hover?: string;
  onClick?: (e?: any) => void;
  children?: any;
}

const CommonButton = ({
  variant,
  size,
  color,
  width,
  whiteSpace,
  fontSize,
  disabled,
  bgColor,
  hover,
  onClick,
  children,
}: Props) => {
  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Button
        variant={variant}
        size={size}
        sx={{
          width: width,
          whiteSpace: whiteSpace,
          fontSize: fontSize,
          bgcolor: bgColor,
          color: color,
          "&:hover": { bgcolor: hover },
        }}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </Button>
    </Box>
  );
};

export default CommonButton;
