import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import theme from "theme/theme";

interface Props {
  totalCount: number;
  caption: string;
  pathnameUrl?: string;
  serachPhrase?: string;
}

const TopRibbonItem = ({
  totalCount,
  caption,
  pathnameUrl,
  serachPhrase,
}: Props) => {
  const navigate = useNavigate();

  return (
    <Box
      onClick={() =>
        navigate({
          pathname: pathnameUrl,
          search: serachPhrase,
        })
      }
    >
      <Typography
        sx={{
          fontSize: theme.typography.subtitle2.fontSize,
          fontWeight: theme.typography.fontWeightBold,
        }}
      >
        {totalCount}
      </Typography>
      <Typography>{caption}</Typography>
    </Box>
  );
};

export default TopRibbonItem;
