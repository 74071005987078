import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { IProcurementSupplierListState } from "./interfaces";
import {
  AddProcurementSupplierListAsync,
  UpdateProcurementSupplierListAsync,
  deleteProcurementSupplierListAsync,
  fetchSupplierListAsync,
  fetchSupplierListByIdAsync,
} from "./thunks";

export const initialState: IProcurementSupplierListState = {
  supplierLoading: false,
  count: 0,
  next: "",
  previous: "",
  results: [],
  deleteMessage: "",
  updateMessage: "",
  addMessage: "",
};

export const supplierListSlice = createSlice({
  name: "procurementSupplierList",
  initialState,
  reducers: {
    setDeleteMessage: (state, { payload }) => {
      state.deleteMessage = payload;
    },
    setUpdateMessage: (state, { payload }) => {
      state.updateMessage = payload;
    },
    setAddMessage: (state, { payload }) => {
      state.addMessage = payload;
    },
    sortSuppliersByName: (state) => {
      state.results.sort((supplierA, supplierB) =>
        supplierA.name <= supplierB.name ? -1 : 1,
      );
    },
    sortSuppliersByNameAbbrev: (state) => {
      state.results.sort((supplierA, supplierB) =>
        supplierA.name_abbrev <= supplierB.name_abbrev ? -1 : 1,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSupplierListAsync.fulfilled, (state, action) => {
      state.supplierLoading = false;
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results;
    });

    builder.addCase(fetchSupplierListAsync.pending, (state) => {
      state.supplierLoading = true;
    });
    builder.addCase(fetchSupplierListAsync.rejected, (state) => {
      state.supplierLoading = false;
    });

    builder.addCase(
      AddProcurementSupplierListAsync.fulfilled,
      (state, action) => {
        state.supplierLoading = false;
        state.addMessage = "Add successfully";
      },
    );

    builder.addCase(
      UpdateProcurementSupplierListAsync.fulfilled,
      (state, action) => {
        state.supplierLoading = false;
        state.updateMessage = "Update successfully";
      },
    );

    builder.addCase(deleteProcurementSupplierListAsync.fulfilled, (state) => {
      state.supplierLoading = false;
      state.deleteMessage = "Removed successfully";
    });

    builder.addCase(fetchSupplierListByIdAsync.fulfilled, (state) => {
      state.supplierLoading = false;
    });
  },
});

export const selectSupplierListState = (
  state: RootState,
): IProcurementSupplierListState => state.procurementsupplierlist;

export const {
  setDeleteMessage,
  setUpdateMessage,
  setAddMessage,
  sortSuppliersByName,
  sortSuppliersByNameAbbrev,
} = supplierListSlice.actions;

export default supplierListSlice.reducer;
