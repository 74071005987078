import React from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Box, ImageList, ImageListItem, Link, TextField } from "@mui/material";
import EditBulkDescription from "./EditBulkDescription";
import theme from "theme/theme";
import EditForBulkEditFixedDescription from "./EditForBulkEditFixedDescription";

const EditorComponentMemo = React.memo(EditBulkDescription);
const BulkEditFixedDescriptionMemo = React.memo(
  EditForBulkEditFixedDescription,
);

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    type: "string",
    minWidth: 200,
  },
  {
    field: "name",
    headerName: "Name",
    type: "string",
    minWidth: 200,
  },
  {
    field: "bulk_edit_fixed_descriptions",
    headerName: "Bulk edit fixed descriptions",
    type: "string",
    minWidth: 200,
    renderCell: (params) => <BulkEditFixedDescriptionMemo params={params} />,
  },
  {
    field: "product_descriptions[0].content",
    headerName: "Product descriptions (SE)",
    type: "string",
    renderCell: (params) => (
      <EditorComponentMemo
        props={
          params.row.product_descriptions[0]?.content
            ? params.row.product_descriptions[0]?.content
            : ""
        }
      />
    ),
    minWidth: 510,
  },
  {
    field: "product_descriptions[1].content",
    headerName: "Product descriptions (EN)",
    type: "string",
    renderCell: (params) => (
      <EditorComponentMemo
        props={
          params.row.product_descriptions[1]?.content
            ? params.row.product_descriptions[1]?.content
            : ""
        }
      />
    ),
    minWidth: 510,
  },
  {
    field: "procurement_products.descriptions",
    headerName: "Procurement product description",
    type: "custom",
    renderCell: (params) => (
      <Box
        sx={{ boxSizing: "border-box", height: "100%", overflowY: "scroll" }}
      >
        <TextField
          value={
            params.row.procurement_products[0]?.descriptions[0]?.description
              ? params.row.procurement_products[0]?.descriptions[0]?.description
              : ""
          }
          disabled
          size="small"
          multiline
          sx={{
            width: "100%",
            marginTop: theme.spacing(1),
            "& fieldset": { border: "none" },
          }}
        />
      </Box>
    ),
    minWidth: 510,
  },
  {
    field: "procurement_products.image_urls",
    headerName: "Procurement product image urls",
    type: "custom",
    renderCell: (params) => (
      <ImageList
        sx={{
          width: 150,
          height: 150,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <ImageListItem>
          <Link
            href={`${
              params.row.procurement_products[0]?.image_urls[0]?.source_url
                ? params.row.procurement_products[0]?.image_urls[0]?.source_url
                : ""
            }`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={
                params.row.procurement_products[0]?.image_urls[0]?.source_url
                  ? params.row.procurement_products[0]?.image_urls[0]
                      ?.source_url
                  : ""
              }
              alt={
                params.row.procurement_products[0]?.image_urls[0]?.title
                  ? params.row.procurement_products[0]?.image_urls[0]?.title
                  : ""
              }
              loading="lazy"
            />
          </Link>
        </ImageListItem>
      </ImageList>
    ),
    minWidth: 220,
  },
  {
    field: "procurement_products.urls",
    headerName: "Procurement product urls",
    type: "custom",
    valueGetter: (value, row) =>
      row.procurement_products[0]?.urls[0]?.url
        ? row.procurement_products[0]?.urls[0]?.url
        : "",
    minWidth: 220,
  },
  {
    field: "procurement_products.categories",
    headerName: "Procurement product categories",
    type: "custom",

    valueGetter: (value, row) =>
      row.procurement_products[0]?.categories[0]?.slug
        ? row.procurement_products[0]?.categories[0]?.slug
        : "",
    minWidth: 220,
  },
  {
    field: "procurement_products.supplier",
    headerName: "Procurement product supplier",
    type: "custom",
    valueGetter: (value, row) =>
      row.procurement_products[0]?.supplier?.name
        ? row.procurement_products[0]?.supplier?.name
        : "",
    minWidth: 220,
  },
  {
    field: "bulk_edit_description",
    headerName: "Bulk edit description",
    type: "string",
    minWidth: 200,
  },
  {
    field: "bulk_edit_fixed_prices",
    headerName: "Bulk edit fixed prices",
    type: "custom",
    minWidth: 150,
  },
  {
    field: "has_negative_margin",
    headerName: "Has Negative Margin",
    type: "string",
    minWidth: 150,
  },
  {
    field: "margin_percent",
    headerName: "Margin Percent",
    type: "number",
    minWidth: 150,
  },
  { field: "price", headerName: "Price", type: "number", minWidth: 150 },
  {
    field: "price_manual_override",
    headerName: "Price override",
    type: "number",
    minWidth: 150,
  },
  {
    field: "price_on_sale",
    headerName: "Sale price",
    type: "number",
    minWidth: 150,
  },
  {
    field: "qty_backordered_stock",
    headerName: "Backordered stock",
    type: "number",
    minWidth: 150,
  },
  {
    field: "qty_to_be_backordered_stock",
    headerName: "To be backordered",
    type: "number",
    minWidth: 150,
  },
  {
    field: "sale_price",
    headerName: "Sale price",
    type: "number",
    minWidth: 150,
  },
  {
    field: "updated_at",
    headerName: "Updated At",
    type: "string",
    minWidth: 150,
  },
  {
    field: "woo_product_type",
    headerName: "WooCommerce Product Type",
    type: "singleSelect",
    valueOptions: ["composite", "simple", "variable", "variable_child"],
    minWidth: 150,
  },
];

export default columns;
