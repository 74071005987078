import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tab,
  Tabs,
  AppBar,
  Tooltip,
} from "@mui/material";
import { dataString } from "./dataInfo";
import { Dispatch, SetStateAction, useState } from "react";
import React from "react";
import theme from "theme/theme";

const expression =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
const urlRegex = new RegExp(expression);

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}
function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

interface ModalContainerUpdateProprs {
  rowsModal: any[];
  created: string;
  updated: string;
  companyName: string;
  companyAbbrev: string;
  companyStreetAddress1: string;
  companyStreetAddress2: string;
  companyPostcode: string;
  companyCity: string;
  companyState: string;
  companyCountry: string;
  companyEmailAddress: string;
  companyPhonenumber: string;
  companyHomePage: string;
  companyFacebook: string;
  companyLinkedin: string;
  companyYoutube: string;
  companyInstagram: string;
  companyVatNumber: string;
  packingSlipPriceConfiguration: string;
  packingSlipExtraContent1: string;
  packingSlipExtraContent2: string;
  packingSlipFooterRight: string;
  brandingLogo: string;
  brandingFontColor: string;
  brandingBackgroundColor: string;
  logoSquare: string;
  logoRectangle: string;
  exchangeRateAdjustment: string;
  priceRoundingPreference: number;
  priceNoOfDecimalsInExport: number;
  priceCommaSign: number;
  priceInclExclVat: number;
  displayPriceInclExclVat: string;
  uuid: string;
  mediaStoragePath: string;
  urlSearch: string;
  urlAdminDirect: string;
  urlAdminSearch: string;
  skuSequenceNumber: number;
  skuPrefix: number;
  skuSuffix: number;
  customProductSequenceNumber: number;
  customProductSequenceNumberPrefix: number;
  customProductSequenceNumberSuffix: number;
  account: number;
  company: number;
  companyHomepageError: string;
  companyFacebookError: string;
  companyLinkedinError: string;
  companyYoutubeError: string;
  companyInstageramError: string;
  urlSearchError: string;
  urlAdminSearchError: string;
  urlAdminDirectError: string;

  setCreated: Dispatch<SetStateAction<string>>;
  setUpdated: Dispatch<SetStateAction<string>>;
  setCompanyName: Dispatch<SetStateAction<string>>;
  setCompanyAbbrev: Dispatch<SetStateAction<string>>;
  setCompanyStreetAddress1: Dispatch<SetStateAction<string>>;
  setCompanyStreetAddress2: Dispatch<SetStateAction<string>>;
  setCompanyPostcode: Dispatch<SetStateAction<string>>;
  setCompanyCity: Dispatch<SetStateAction<string>>;
  setCompanyState: Dispatch<SetStateAction<string>>;
  setCompanyCountry: Dispatch<SetStateAction<string>>;
  setCompanyEmailAddress: Dispatch<SetStateAction<string>>;
  setCompanyPhonenumber: Dispatch<SetStateAction<string>>;
  setCompanyHomepage: Dispatch<SetStateAction<string>>;
  setCompanyFacebook: Dispatch<SetStateAction<string>>;
  setCompanyLinkedin: Dispatch<SetStateAction<string>>;
  setCompanyYoutube: Dispatch<SetStateAction<string>>;
  setCompanyInstagram: Dispatch<SetStateAction<string>>;
  setCompanyVatNumber: Dispatch<SetStateAction<string>>;
  setPackingSlipPriceConfiguration: Dispatch<SetStateAction<string>>;
  setPackingSlipExtraContent1: Dispatch<SetStateAction<string>>;
  setPackingSlipExtraContent2: Dispatch<SetStateAction<string>>;
  setPackingSlipFooterRight: Dispatch<SetStateAction<string>>;
  setBrandingLogo: Dispatch<SetStateAction<string>>;
  setBrandingFontColor: Dispatch<SetStateAction<string>>;
  setBrandingBackgroundColor: Dispatch<SetStateAction<string>>;
  setLogoSquare: Dispatch<SetStateAction<string>>;
  setLogoRectangle: Dispatch<SetStateAction<string>>;
  setExchangeRateAdjustment: Dispatch<SetStateAction<string>>;
  setPriceRoundingPreference: Dispatch<SetStateAction<number>>;
  setPriceNoOfDecimalsInExport: Dispatch<SetStateAction<number>>;
  setPriceCommaSign: Dispatch<SetStateAction<number>>;
  setPriceInclExclVat: Dispatch<SetStateAction<number>>;
  setdisplayPriceInclExclVat: Dispatch<SetStateAction<string>>;
  setUuid: Dispatch<SetStateAction<string>>;
  setMediaStoragePath: Dispatch<SetStateAction<string>>;
  setUrlSearch: Dispatch<SetStateAction<string>>;
  setUrlAdminDirect: Dispatch<SetStateAction<string>>;
  setUrlAdminSearch: Dispatch<SetStateAction<string>>;
  setSkuSequenceNumber: Dispatch<SetStateAction<number>>;
  setSkuPrefix: Dispatch<SetStateAction<number>>;
  setSkuSuffix: Dispatch<SetStateAction<number>>;
  setCustomProductSequenceNumber: Dispatch<SetStateAction<number>>;
  setCustomProductSequenceNumberPrefix: Dispatch<SetStateAction<number>>;
  setCustomProductSequenceNumberSuffix: Dispatch<SetStateAction<number>>;
  setAccount: Dispatch<SetStateAction<number>>;
  setCompany: Dispatch<SetStateAction<number>>;
  setcompanyHomepageError: Dispatch<SetStateAction<string>>;
  setcompanyFacebookError: Dispatch<SetStateAction<string>>;
  setcompanyLinkedinError: Dispatch<SetStateAction<string>>;
  setcompanyYoutubeError: Dispatch<SetStateAction<string>>;
  setcompanyInstageramError: Dispatch<SetStateAction<string>>;
  seturlSearchError: Dispatch<SetStateAction<string>>;
  seturlAdminSearchError: Dispatch<SetStateAction<string>>;
  seturlAdminDirectError: Dispatch<SetStateAction<string>>;
}

const ModalContainerUpdate = (
  props: ModalContainerUpdateProprs,
): JSX.Element => {
  const handleSetCompanyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setCompanyName(event.target.value);
  };
  const handleSetCompanyAbbrevChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setCompanyName(event.target.value);
  };
  const handleSetCompanyStreetAddress1Change = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setCompanyStreetAddress1(event.target.value);
  };
  const handleSetCompanyStreetAddress2Change = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setCompanyStreetAddress2(event.target.value);
  };
  const handleSetCompanyPostcodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setCompanyPostcode(event.target.value);
  };
  const handlesetCompanyCitychange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setCompanyCity(event.target.value);
  };
  const handleSetCompanyStatechange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setCompanyState(event.target.value);
  };
  const handleSetCompanyCountrychange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setCompanyCountry(event.target.value);
  };
  const handleSetCompanyEmailAddresschange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setCompanyEmailAddress(event.target.value);
  };
  const handleSetCompanyPhonenumberchange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setCompanyPhonenumber(event.target.value);
  };
  const handleSetCompanyHomepagechange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    props.setCompanyHomepage(value);
    if (value && !value.match(urlRegex)) {
      props.setcompanyHomepageError(dataString.urlError);
    } else {
      props.setcompanyHomepageError("");
    }
  };
  const handleSetCompanyFacebookchange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    props.setCompanyFacebook(value);
    if (value && !value.match(urlRegex)) {
      props.setcompanyFacebookError(dataString.urlError);
    } else {
      props.setcompanyFacebookError("");
    }
  };
  const handleSetCompanyLinkedinchange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    props.setCompanyLinkedin(value);
    if (value && !value.match(urlRegex)) {
      props.setcompanyLinkedinError(dataString.urlError);
    } else {
      props.setcompanyLinkedinError("");
    }
  };
  const handleSetCompanyYoutubechange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    props.setCompanyYoutube(value);
    if (value && !value.match(urlRegex)) {
      props.setcompanyYoutubeError(dataString.urlError);
    } else {
      props.setcompanyYoutubeError("");
    }
  };
  const handleSetCompanyInstagramchange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    props.setCompanyInstagram(value);
    if (value && !value.match(urlRegex)) {
      props.setcompanyInstageramError(dataString.urlError);
    } else {
      props.setcompanyInstageramError("");
    }
  };
  const handleSetCompanyVatNumberchange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setCompanyVatNumber(event.target.value);
  };
  const handleSetPackingSlipPriceConfigurationchange = (event: {
    target: { value: any };
  }) => {
    props.setPackingSlipPriceConfiguration(event.target.value);
  };
  const handleSetPackingSlipExtraContent1change = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setPackingSlipExtraContent1(event.target.value);
  };
  const handleSetPackingSlipExtraContent2change = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setPackingSlipExtraContent2(event.target.value);
  };
  const handleSetPackingSlipFooterRightchange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setPackingSlipFooterRight(event.target.value);
  };
  const handleSetBrandingLogochange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setBrandingLogo(event.target.value);
  };
  const handleSetBrandingFontColorchange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setBrandingFontColor(event.target.value);
  };
  const handleSetBrandingBackgroundColorchange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setBrandingBackgroundColor(event.target.value);
  };
  const handleSetExchangeRateAdjustmentchange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.setExchangeRateAdjustment(event.target.value);
  };
  const handleSetPriceRoundingPreferencechange = (event: {
    target: { value: any };
  }) => {
    props.setPriceRoundingPreference(event.target.value);
  };
  const handleSetPriceNoOfDecimalsInExportchange = (event: {
    target: { value: any };
  }) => {
    props.setPriceNoOfDecimalsInExport(event.target.value);
  };
  const handleSetPriceCommaSignchange = (event: { target: { value: any } }) => {
    props.setPriceCommaSign(event.target.value);
  };
  const handleSetPriceInclExclVatchange = (event: {
    target: { value: any };
  }) => {
    props.setPriceInclExclVat(event.target.value);
  };
  const handleSetdisplayPriceInclExclVatchange = (event: {
    target: { value: any };
  }) => {
    props.setdisplayPriceInclExclVat(event.target.value);
  };
  const handleSetUrlSearchchange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    props.setUrlSearch(value);
    if (value && !value.match(urlRegex)) {
      props.seturlSearchError(dataString.urlError);
    } else {
      props.seturlSearchError("");
    }
  };
  const handleSetUrlAdminDirectchange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    props.setUrlAdminDirect(value);
    if (value && !value.match(urlRegex)) {
      props.seturlAdminDirectError(dataString.urlError);
    } else {
      props.seturlAdminDirectError("");
    }
  };
  const handleSetUrlAdminSearchchange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    props.setUrlAdminSearch(value);
    if (value && !value.match(urlRegex)) {
      props.seturlAdminSearchError(dataString.urlError);
    } else {
      props.seturlAdminSearchError("");
    }
  };
  const handleSetSkuSequenceNumberchange = (event: {
    target: { value: any };
  }) => {
    props.setSkuSequenceNumber(event.target.value);
  };
  const handleSetSkuPrefixchange = (event: { target: { value: any } }) => {
    props.setSkuPrefix(event.target.value);
  };
  const handleSetSkuSuffixchange = (event: { target: { value: any } }) => {
    props.setSkuSuffix(event.target.value);
  };
  const handleSetCustomProductSequenceNumberchange = (event: {
    target: { value: any };
  }) => {
    props.setCustomProductSequenceNumber(event.target.value);
  };
  const handleSetCustomProductSequenceNumberPrefixchange = (event: {
    target: { value: any };
  }) => {
    props.setCustomProductSequenceNumberPrefix(event.target.value);
  };
  const handleSetCustomProductSequenceNumberSuffixchange = (event: {
    target: { value: any };
  }) => {
    props.setCustomProductSequenceNumberSuffix(event.target.value);
  };

  const [value, setValue] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ bgcolor: "background.paper" }}>
        <AppBar position="sticky">
          <Tabs
            value={value}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            sx={{}}
          >
            <Tab label="main" {...a11yProps(0)} />
            <Tab label="addresses" {...a11yProps(1)} />
            <Tab label="contact" {...a11yProps(2)} />
            <Tab label="url" {...a11yProps(3)} />
            <Tab label="packing slip" {...a11yProps(4)} />
            <Tab label="branding" {...a11yProps(5)} />
            <Tab label="pricing" {...a11yProps(6)} />
            <Tab label="uuid" {...a11yProps(7)} />
            <Tab label="other" {...a11yProps(8)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <hr />
          <br />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
            }}
          >
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyName}
                />
                <TextField
                  value={props.companyName || ""}
                  onChange={handleSetCompanyNameChange}
                  variant="outlined"
                  size="small"
                  sx={{
                    width: "400px",
                    padding: "5px",
                  }}
                />
              </FormGroup>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyAbbrev}
                />
                <TextField
                  value={props.companyAbbrev || ""}
                  onChange={handleSetCompanyAbbrevChange}
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: "5px",
                    width: "400px",
                  }}
                />
              </FormGroup>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyVatNumber}
                />
                <input
                  type="number"
                  value={props.companyVatNumber}
                  onChange={handleSetCompanyVatNumberchange}
                  style={{ width: "250px" }}
                />
              </FormGroup>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <br />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
            }}
          >
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyStreetAddress1}
                />
                <TextField
                  value={props.companyStreetAddress1 || ""}
                  onChange={handleSetCompanyStreetAddress1Change}
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: "5px",
                    width: "400px",
                  }}
                />
              </FormGroup>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyStreetAddress2}
                />
                <TextField
                  value={props.companyStreetAddress2 || ""}
                  onChange={handleSetCompanyStreetAddress2Change}
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: "5px",
                    width: "400px",
                  }}
                />
              </FormGroup>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyPostcode}
                />
                <TextField
                  value={props.companyPostcode || ""}
                  onChange={handleSetCompanyPostcodeChange}
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: "5px",
                    width: "400px",
                  }}
                />
              </FormGroup>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
            }}
          >
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyCity}
                />
                <TextField
                  value={props.companyCity || ""}
                  onChange={handlesetCompanyCitychange}
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: "5px",
                    width: "400px",
                  }}
                />
              </FormGroup>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyState}
                />
                <TextField
                  value={props.companyState || ""}
                  onChange={handleSetCompanyStatechange}
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: "5px",
                    width: "400px",
                  }}
                />
              </FormGroup>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyCountry}
                />
                <TextField
                  value={props.companyCountry || ""}
                  onChange={handleSetCompanyCountrychange}
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: "5px",
                    width: "400px",
                  }}
                />
              </FormGroup>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <br />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
            }}
          >
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyEmailAddress}
                />
                <TextField
                  value={props.companyEmailAddress || ""}
                  onChange={handleSetCompanyEmailAddresschange}
                  placeholder="user@example.com"
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: "5px",
                    width: "350px",
                  }}
                />
              </FormGroup>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyPhonenumber}
                />
                <TextField
                  value={props.companyPhonenumber || ""}
                  onChange={handleSetCompanyPhonenumberchange}
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: "5px",
                    width: "250px",
                  }}
                />
              </FormGroup>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyHomepage}
                />
                <TextField
                  value={props.companyHomePage || ""}
                  onChange={handleSetCompanyHomepagechange}
                  placeholder="https://example.com"
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: "5px",
                    width: "400px",
                  }}
                  error={Boolean(props.companyHomepageError)}
                  helperText={props.companyHomepageError}
                />
              </FormGroup>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
            }}
          >
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyFacebook}
                />
                <TextField
                  value={props.companyFacebook || ""}
                  onChange={handleSetCompanyFacebookchange}
                  placeholder="https://example.com"
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: "5px",
                    width: "500px",
                  }}
                  error={Boolean(props.companyFacebookError)}
                  helperText={props.companyFacebookError}
                />
              </FormGroup>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyLinkedin}
                />
                <TextField
                  value={props.companyLinkedin || ""}
                  onChange={handleSetCompanyLinkedinchange}
                  placeholder="https://example.com"
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: "5px",
                    width: "500px",
                  }}
                  error={Boolean(props.companyLinkedinError)}
                  helperText={props.companyLinkedinError}
                />
              </FormGroup>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
            }}
          >
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyYoutube}
                />
                <TextField
                  value={props.companyYoutube || ""}
                  onChange={handleSetCompanyYoutubechange}
                  placeholder="https://example.com"
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: "5px",
                    width: "500px",
                  }}
                  error={Boolean(props.companyYoutubeError)}
                  helperText={props.companyYoutubeError}
                />
              </FormGroup>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.companyInstagram}
                />
                <TextField
                  value={props.companyInstagram || ""}
                  onChange={handleSetCompanyInstagramchange}
                  placeholder="https://example.com"
                  variant="outlined"
                  size="small"
                  sx={{
                    padding: "5px",
                    width: "500px",
                  }}
                  error={Boolean(props.companyInstageramError)}
                  helperText={props.companyInstageramError}
                />
              </FormGroup>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <br />
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.urlSearch}
              />
              <TextField
                value={props.urlSearch || ""}
                onChange={handleSetUrlSearchchange}
                placeholder="https://example.com"
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "600px",
                }}
                error={Boolean(props.urlSearchError)}
                helperText={props.urlSearchError}
              />
            </FormGroup>
            <FormHelperText>{dataString.helperTextforurlSearch}</FormHelperText>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.urlAdminDirect}
              />
              <TextField
                value={props.urlAdminDirect || ""}
                onChange={handleSetUrlAdminDirectchange}
                placeholder="https://example.com"
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "600px",
                }}
                error={Boolean(props.urlAdminDirectError)}
                helperText={props.urlAdminDirectError}
              />
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforurlAdminDirect}
            </FormHelperText>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.urlAdminSearch}
              />
              <TextField
                value={props.urlAdminSearch || ""}
                onChange={handleSetUrlAdminSearchchange}
                placeholder="https://example.com"
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "600px",
                }}
                error={Boolean(props.urlAdminSearchError)}
                helperText={props.urlAdminSearchError}
              />
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforurlAdminSearch}
            </FormHelperText>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <br />
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.urlAdminSearch}
              />
              <FormControl
                variant="outlined"
                size="small"
                sx={{ width: "100px", padding: "5px" }}
              >
                <Select
                  labelId="packing-slip-price-configuration-label"
                  value={props.packingSlipPriceConfiguration || ""}
                  label={dataString.packingSlipPriceConfiguration}
                  onChange={handleSetPackingSlipPriceConfigurationchange}
                >
                  <MenuItem value="no_prices">No prices</MenuItem>
                  <MenuItem value="excl_vat">Excl vat</MenuItem>
                  <MenuItem value="incl_vat">Incl vat</MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforpackingSlipPriceConfiguration}
            </FormHelperText>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.packingSlipExtraContent1}
              />
              <TextField
                value={props.packingSlipExtraContent1 || ""}
                onChange={handleSetPackingSlipExtraContent1change}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "600px",
                }}
              />
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforpackingSlipExtraContent1}
            </FormHelperText>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.packingSlipExtraContent2}
              />
              <TextField
                value={props.packingSlipExtraContent2 || ""}
                onChange={handleSetPackingSlipExtraContent2change}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "600px",
                }}
              />
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforpackingSlipExtraContent2}
            </FormHelperText>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.packingSlipFooterRight}
              />
              <TextField
                value={props.packingSlipFooterRight || ""}
                onChange={handleSetPackingSlipFooterRightchange}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "600px",
                }}
              />
            </FormGroup>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <br />
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.brandingLogo}
              />
              <TextField
                value={props.brandingLogo || ""}
                onChange={handleSetBrandingLogochange}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "600px",
                }}
              />
            </FormGroup>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.brandingFontColor}
              />
              <TextField
                value={props.brandingFontColor || ""}
                onChange={handleSetBrandingFontColorchange}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "600px",
                }}
              />
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforbrandingFontColor}
            </FormHelperText>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.brandingBackgroundColor}
              />
              <TextField
                value={props.brandingBackgroundColor || ""}
                onChange={handleSetBrandingBackgroundColorchange}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "600px",
                }}
              />
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforbrandingBackgroundColor}
            </FormHelperText>
          </Box>
          <hr />
          <Tooltip title="Coming Soon" followCursor>
            <Box>
              <Box>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.logoSquare}
                />
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  size="small"
                  disabled
                >
                  Choose file
                </Button>
                <FormHelperText>
                  {dataString.helperTextforlogoSquare}
                </FormHelperText>
              </Box>
              <Box>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.logoSquareImg}
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.logoRectangle}
                />
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  size="small"
                  disabled
                >
                  Choose file
                </Button>
                <FormHelperText>
                  {dataString.helperTextforlogoRectangle}
                </FormHelperText>
              </Box>
              <Box>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.logoRectangleImg}
                />
              </Box>
            </Box>
          </Tooltip>
        </TabPanel>
        <TabPanel value={value} index={6} dir={theme.direction}>
          <br />
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.priceRoundingPreference}
              />
              <FormControl
                variant="outlined"
                size="small"
                sx={{ width: "500px", padding: "5px" }}
              >
                <Select
                  labelId="price_rounding_preference"
                  value={props.priceRoundingPreference || ""}
                  label={dataString.priceRoundingPreference}
                  onChange={handleSetPriceRoundingPreferencechange}
                >
                  <MenuItem value="0">No rounding at all</MenuItem>
                  <MenuItem value="1">
                    Price (incl VAT)1000: End with 45 or 95; Price 20: End with
                    5; Pricegig=20: End with even number
                  </MenuItem>
                  <MenuItem value="2">
                    Price (incl VAT) rounded up to either 5 or 9
                  </MenuItem>
                  <MenuItem value="3">
                    Price (incl VAT) rounded up to closed multiple of 5
                  </MenuItem>
                  <MenuItem value="4">
                    Price (excl VAT) rounded up to next int
                  </MenuItem>
                  <MenuItem value="5">
                    {" "}
                    Price (incl VAT) rounded up to next int
                  </MenuItem>
                  <MenuItem value="6">
                    Price (incl VAT) rounded down to next 0 or 5
                  </MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.priceInclExclVat}
              />
              <FormControl
                variant="outlined"
                size="small"
                sx={{ width: "500px", padding: "5px" }}
              >
                <Select
                  labelId="price_incl_excl_vat"
                  value={props.priceInclExclVat || ""}
                  label={dataString.priceInclExclVat}
                  onChange={handleSetPriceInclExclVatchange}
                >
                  <MenuItem value="excl ">
                    Prices to/from e-commerce platform excludes VAT
                  </MenuItem>
                  <MenuItem value="incl">
                    Prices to/from e-commerce platform includes VAT
                  </MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforpriceInclExclVat}
            </FormHelperText>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.displayPriceInclExclVat}
              />
              <FormControl
                variant="outlined"
                size="small"
                sx={{ width: "300px", padding: "5px" }}
              >
                <Select
                  labelId="display_price_incl_excl_vat"
                  value={props.displayPriceInclExclVat || ""}
                  label={dataString.displayPriceInclExclVat}
                  onChange={handleSetdisplayPriceInclExclVatchange}
                >
                  <MenuItem value="EXCL">Display prices excl VAT</MenuItem>
                  <MenuItem value="INCL">Display prices incl VAT</MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextfordisplayPriceInclExclVat}
            </FormHelperText>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.exchangeRateAdjustment}
              />
              <TextField
                value={props.exchangeRateAdjustment || ""}
                onChange={handleSetExchangeRateAdjustmentchange}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "250px",
                }}
              />
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforexchangeRateAdjustment}
            </FormHelperText>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.priceNoOfDecimalsInExport}
              />
              <TextField
                value={props.priceNoOfDecimalsInExport || ""}
                onChange={handleSetPriceNoOfDecimalsInExportchange}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "150px",
                }}
              />
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforpriceNoOfDecimalsInExport}
            </FormHelperText>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.priceCommaSign}
              />
              <TextField
                value={props.priceCommaSign || ""}
                onChange={handleSetPriceCommaSignchange}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "150px",
                }}
              />
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforpriceCommaSign}
            </FormHelperText>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={7} dir={theme.direction}>
          <br />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
            }}
          >
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.uuid}
                />
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={props.uuid || ""}
                />
                <FormHelperText>{dataString.helperTextforuuid}</FormHelperText>
              </FormGroup>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.createdAt}
                />
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={props.created || ""}
                />
                <FormHelperText>
                  {dataString.helperTextforcreatedAt}
                </FormHelperText>
              </FormGroup>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px",
            }}
          >
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.mediaStoragePath}
                />
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={props.mediaStoragePath || ""}
                />
                <FormHelperText>
                  {dataString.helperTextformediaStoragePath}
                </FormHelperText>
              </FormGroup>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={dataString.updatedAt}
                />
                <FormControlLabel
                  control={<Typography></Typography>}
                  label={props.updated || ""}
                />
                <FormHelperText>
                  {dataString.helperTextforupdatedAt}
                </FormHelperText>
              </FormGroup>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={8} dir={theme.direction}>
          <br />
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.skuSequenceNumber}
              />
              <TextField
                value={props.skuSequenceNumber || ""}
                onChange={handleSetSkuSequenceNumberchange}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "200px",
                }}
              />
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforskuSequenceNumber}
            </FormHelperText>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.skuPrefix}
              />
              <TextField
                value={props.skuPrefix || ""}
                onChange={handleSetSkuPrefixchange}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "400px",
                }}
              />
            </FormGroup>
            <FormHelperText>{dataString.helperTextforskuPrefix}</FormHelperText>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.skuSuffix}
              />
              <TextField
                value={props.skuSuffix || ""}
                onChange={handleSetSkuSuffixchange}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "400px",
                }}
              />
            </FormGroup>
            <FormHelperText>{dataString.helperTextforskuSuffix}</FormHelperText>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.customProductSequenceNumber}
              />
              <TextField
                value={props.customProductSequenceNumber || ""}
                onChange={handleSetCustomProductSequenceNumberchange}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "400px",
                }}
              />
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforcustomProductSequenceNumber}
            </FormHelperText>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.customProductSequenceNumberPrefix}
              />
              <TextField
                value={props.customProductSequenceNumberPrefix || ""}
                onChange={handleSetCustomProductSequenceNumberPrefixchange}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "400px",
                }}
              />
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforcustomProductSequenceNumberPrefix}
            </FormHelperText>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Typography></Typography>}
                label={dataString.customProductSequenceNumberSuffix}
              />
              <TextField
                value={props.customProductSequenceNumberSuffix || ""}
                onChange={handleSetCustomProductSequenceNumberSuffixchange}
                variant="outlined"
                size="small"
                sx={{
                  padding: "5px",
                  width: "400px",
                }}
              />
            </FormGroup>
            <FormHelperText>
              {dataString.helperTextforcustomProductSequenceNumberSuffix}
            </FormHelperText>
          </Box>
        </TabPanel>
      </Box>
    </>
  );
};

export default ModalContainerUpdate;
