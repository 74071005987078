import {
  IAppliedFilters,
  IMultipleSelectionFilterMenus,
} from "../../interfaces";
import { DateTime } from "luxon";

// check whether a given supplier ID is in the URLSearchParams
export const isSupplierSelected = (
  searchParams: URLSearchParams,
  supplierId: string,
): boolean => {
  return Boolean(
    searchParams.get("supplier") === supplierId ||
      (searchParams.has("supplier__in") &&
        searchParams.get("supplier__in")?.includes(supplierId)),
  );
};

// return true if URLSearchParams include any key other than 'page', 'offset' and 'limit'
export const isAnyFilterApplied = (searchParams: URLSearchParams): boolean => {
  const invalidFilters = ["page", "offset", "limit", "ordering"];
  for (const key of searchParams.keys()) {
    if (!invalidFilters.includes(key)) return true;
  }
  return false;
};

// extracts the applied filters info from URLSearchParams and turns them into an array of convenient objects for display of selected filter list
// supplierInfo is a map of all supplier IDs to supplier names
// productTypeInfo is a map of all product type codes to product types
// imageClassificationInfo is a map of all image classification IDs to titles
// Note: It expects supplierInfo, productTypeInfo and imageClassificationInfo if the searchParams include suppliers, productTypes and imageClassifications respectively.
export const getSearchData = (
  searchParams: URLSearchParams,
  multipleSelectionMenus: IMultipleSelectionFilterMenus,
): IAppliedFilters => {
  const appliedFilters: IAppliedFilters = {};

  searchParams.forEach((value, key) => {
    switch (key) {
      case "procurement_supplier":
      case "supplier":
      case "supplier__in":
        appliedFilters.supplier = {
          label: "Suppliers",
          value: value.split(",").length.toString(),
          queryToRemove: key,
          extraValues: value
            .split(",")
            ?.map((code) =>
              (multipleSelectionMenus?.supplierInfo?.get ?? (() => {}))(code),
            )
            .sort()
            .join(", "),
        };
        break;

      case "woo_product_type__in":
        appliedFilters.productType = {
          label: "Product Types",
          value: value.split(",").length.toString(),
          queryToRemove: key,
          extraValues: value
            .split(",")
            ?.map((code) => multipleSelectionMenus.productTypesInfo?.get(code))
            .sort()
            .join(", "),
        };
        break;

      case "classification__in":
        appliedFilters.imageClassification = {
          label: "Classification",
          value: value.split(",").length.toString(),
          queryToRemove: key,
          extraValues: value
            .split(",")
            ?.map((code) =>
              multipleSelectionMenus.imageClassificationInfo?.get(code),
            )
            .sort()
            .join(", "),
        };
        break;

      case "internal_order_status":
        appliedFilters.orderStatus = {
          label: "Order Status",
          value: value.split(",").length.toString(),
          queryToRemove: key,
          extraValues: value
            .split(",")
            ?.map((code) => multipleSelectionMenus.orderStatusInfo?.get(code))
            .sort()
            .join(", "),
        };
        break;

      case "internal_picking_status":
        appliedFilters.pickingStatus = {
          label: "Picking Status",
          value: value.split(",").length.toString(),
          queryToRemove: key,
          extraValues: value
            .split(",")
            ?.map((code) => multipleSelectionMenus.pickingStatusInfo?.get(code))
            .sort()
            .join(", "),
        };
        break;

      case "internal_backorder_status":
        appliedFilters.backorderStatus = {
          label: "Backorder Status",
          value: value.split(",").length.toString(),
          queryToRemove: key,
          extraValues: value
            .split(",")
            ?.map((code) =>
              multipleSelectionMenus.backorderStatusInfo?.get(code),
            )
            .sort()
            .join(", "),
        };
        break;

      case "created_at__gte":
      case "feed_date__gte":
      case "order_made__gte":
      case "order_created__gte":
        // if lt is already there
        if (appliedFilters.created) {
          // if lt value is equal to gte value or not
          const ltDate = DateTime.fromISO(appliedFilters.created.value);
          appliedFilters.created = ltDate.hasSame(
            DateTime.fromISO(value),
            "day",
          )
            ? {
                label: "Created On",
                value: value,
                queryToRemove: `${key}&${appliedFilters.created.queryToRemove}`,
              }
            : {
                label: "Created",
                value: `${value} - ${appliedFilters.created.value}`,
                queryToRemove: `${key}&${appliedFilters.created.queryToRemove}`,
              };
        } else {
          appliedFilters.created = {
            label: "Created After",
            value: value,
            queryToRemove: key,
          };
        }
        break;

      case "created_at__lt":
      case "feed_date__lt":
      case "order_made__lt":
      case "order_created__lt": {
        const ltDate = DateTime.fromISO(value).minus({ days: 1 });
        // if gte is already there
        if (appliedFilters.created) {
          const gteDate = DateTime.fromISO(appliedFilters.created.value);
          // if lt value is equal to gte value or not
          appliedFilters.created = ltDate.hasSame(gteDate, "day")
            ? {
                label: "Created On",
                value: appliedFilters.created.value,
                queryToRemove: `${key}&${appliedFilters.created.queryToRemove}`,
              }
            : {
                label: "Created",
                value: `${appliedFilters.created.value} - ${ltDate.toFormat("yyyy-MM-dd")}`,
                queryToRemove: `${key}&${appliedFilters.created.queryToRemove}`,
              };
        } else {
          appliedFilters.created = {
            label: "Created Before",
            value: ltDate.toFormat("yyyy-MM-dd"),
            queryToRemove: key,
          };
        }
        break;
      }

      case "updated_at__gte":
        // if lt is already there
        if (appliedFilters.updated) {
          const ltDate = DateTime.fromISO(appliedFilters.updated.value);
          // if lt value is equal to gte value or not
          appliedFilters.updated = ltDate.hasSame(
            DateTime.fromISO(value),
            "day",
          )
            ? {
                label: "Updated On",
                value: value,
                queryToRemove: `${key}&${appliedFilters.updated.queryToRemove}`,
              }
            : {
                label: "Updated",
                value: `${value} - ${appliedFilters.updated.value}`,
                queryToRemove: `${key}&${appliedFilters.updated.queryToRemove}`,
              };
        } else {
          appliedFilters.updated = {
            label: "Updated After",
            value: value,
            queryToRemove: key,
          };
        }
        break;

      case "updated_at__lt": {
        const ltDate = DateTime.fromISO(value).minus({ days: 1 });
        // if gte is already there
        if (appliedFilters.updated) {
          // if lte value is equal to gte value or not
          appliedFilters.updated = DateTime.fromISO(
            appliedFilters.updated.value,
          ).hasSame(ltDate, "day")
            ? {
                label: "Updated On",
                value: appliedFilters.updated.value,
                queryToRemove: `${key}&${appliedFilters.updated.queryToRemove}`,
              }
            : {
                label: "Updated",
                value: `${appliedFilters.updated.value} - ${ltDate.toFormat("yyyy-MM-dd")}`,
                queryToRemove: `${key}&${appliedFilters.updated.queryToRemove}`,
              };
        } else {
          appliedFilters.updated = {
            label: "Updated Before",
            value: ltDate.toFormat("yyyy-MM-dd"),
            queryToRemove: key,
          };
        }
        break;
      }

      case "order_delivered__gte":
        // if lt is already there
        if (appliedFilters.delivered) {
          const ltDate = DateTime.fromISO(appliedFilters.delivered.value);
          // if lt value is equal to gte value or not
          appliedFilters.delivered = DateTime.fromISO(value).hasSame(
            ltDate,
            "day",
          )
            ? {
                label: "Delivered On",
                value: value,
                queryToRemove: `${key}&${appliedFilters.delivered.queryToRemove}`,
              }
            : {
                label: "Delivered",
                value: `${value} - ${appliedFilters.delivered.value}`,
                queryToRemove: `${key}&${appliedFilters.delivered.queryToRemove}`,
              };
        } else {
          appliedFilters.delivered = {
            label: "Delivered After",
            value: value,
            queryToRemove: key,
          };
        }
        break;

      case "order_delivered__lt": {
        const ltDate = DateTime.fromISO(value).minus({ days: 1 });
        // if gte is already there
        if (appliedFilters.delivered) {
          // if lte value is equal to gte value or not
          appliedFilters.delivered = DateTime.fromISO(
            appliedFilters.delivered.value,
          ).hasSame(ltDate, "day")
            ? {
                label: "Delivered On",
                value: appliedFilters.delivered.value,
                queryToRemove: `${key}&${appliedFilters.delivered.queryToRemove}`,
              }
            : {
                label: "Delivered",
                value: `${appliedFilters.delivered.value} - ${ltDate.toFormat("yyyy-MM-dd")}`,
                queryToRemove: `${key}&${appliedFilters.delivered.queryToRemove}`,
              };
        } else {
          appliedFilters.delivered = {
            label: "Delivered Before",
            value: ltDate.toFormat("yyyy-MM-dd"),
            queryToRemove: key,
          };
        }
        break;
      }

      case "margin_percent__gt":
        // if lte value is already there
        if (appliedFilters.marginPercent) {
          appliedFilters.marginPercent = {
            label: "Margin Percent",
            value:
              appliedFilters.marginPercent.value.slice(3) === value
                ? value.toString()
                : `${value} - ${appliedFilters.marginPercent.value.slice(3)}`,
            queryToRemove: `${key}&${appliedFilters.marginPercent.queryToRemove}`,
          };
        } else
          appliedFilters.marginPercent = {
            label: "Margin Percent",
            value: `> ${value}`,
            queryToRemove: key,
          };
        break;

      case "margin_percent__lte":
        // if gt value is already there
        if (appliedFilters.marginPercent) {
          appliedFilters.marginPercent = {
            label: "Margin Percent",
            value:
              appliedFilters.marginPercent.value.slice(2) === value
                ? value.toString()
                : `${appliedFilters.marginPercent.value.slice(2)} - ${value}`,
            queryToRemove: `${key}&${appliedFilters.marginPercent.queryToRemove}`,
          };
        } else
          appliedFilters.marginPercent = {
            label: "Margin Percent",
            value: `<= ${value}`,
            queryToRemove: key,
          };
        break;

      case "image_height__gte":
        // if lte value is already there
        if (appliedFilters.imageHeight) {
          appliedFilters.imageHeight = {
            label: "Height (px)",
            value:
              appliedFilters.imageHeight.value.slice(3) === value
                ? value.toString()
                : `${value} - ${appliedFilters.imageHeight.value.slice(3)}`,
            queryToRemove: `${key}&${appliedFilters.imageHeight.queryToRemove}`,
          };
        } else
          appliedFilters.imageHeight = {
            label: "Height (px)",
            value: `>= ${value}`,
            queryToRemove: key,
          };
        break;

      case "image_height__lte":
        // if gte value is already there
        if (appliedFilters.imageHeight) {
          appliedFilters.imageHeight = {
            label: "Height (px)",
            value:
              appliedFilters.imageHeight.value.slice(3) === value
                ? value.toString()
                : `${appliedFilters.imageHeight.value.slice(3)} - ${value}`,
            queryToRemove: `${key}&${appliedFilters.imageHeight.queryToRemove}`,
          };
        } else
          appliedFilters.imageHeight = {
            label: "Height (px)",
            value: value === "0" ? `${value}` : `<= ${value}`,
            queryToRemove: key,
          };
        break;

      case "image_width__gte":
        // if lte value is already there
        if (appliedFilters.imageWidth) {
          appliedFilters.imageWidth = {
            label: "Width (px)",
            value:
              appliedFilters.imageWidth.value.slice(3) === value
                ? value.toString()
                : `${value} - ${appliedFilters.imageWidth.value.slice(3)}`,
            queryToRemove: `${key}&${appliedFilters.imageWidth.queryToRemove}`,
          };
        } else
          appliedFilters.imageWidth = {
            label: "Width (px)",
            value: `>= ${value}`,
            queryToRemove: key,
          };
        break;

      case "image_width__lte":
        // if gte value is already there
        if (appliedFilters.imageWidth) {
          appliedFilters.imageWidth = {
            label: "Width (px)",
            value:
              appliedFilters.imageWidth.value.slice(3) === value
                ? value.toString()
                : `${appliedFilters.imageWidth.value.slice(3)} - ${value}`,
            queryToRemove: `${key}&${appliedFilters.imageWidth.queryToRemove}`,
          };
        } else
          appliedFilters.imageWidth = {
            label: "Width",
            value: value === "0" ? `${value}` : `<= ${value}`,
            queryToRemove: key,
          };
        break;

      case "image_kb__gte":
        // if lte value is already there
        if (appliedFilters.imageSize) {
          appliedFilters.imageSize = {
            label: "Size (KB)",
            value:
              appliedFilters.imageSize.value.slice(3) === value
                ? value.toString()
                : `${value} - ${appliedFilters.imageSize.value.slice(3)}`,
            queryToRemove: `${key}&${appliedFilters.imageSize.queryToRemove}`,
          };
        } else
          appliedFilters.imageSize = {
            label: "Size (KB)",
            value: `>= ${value}`,
            queryToRemove: key,
          };
        break;

      case "image_kb__lte":
        // if gte value is already there
        if (appliedFilters.imageSize) {
          appliedFilters.imageSize = {
            label: "Size (KB)",
            value:
              appliedFilters.imageSize.value.slice(3) === value
                ? value.toString()
                : `${appliedFilters.imageSize.value.slice(3)} - ${value}`,
            queryToRemove: `${key}&${appliedFilters.imageSize.queryToRemove}`,
          };
        } else
          appliedFilters.imageSize = {
            label: "Size (KB)",
            value: value === "0" ? `${value}` : `<= ${value}`,
            queryToRemove: key,
          };
        break;

      case "is_synced_to_ecommerce":
        appliedFilters.syncedToEcommerce = {
          label: "Synced To Ecommerce",
          value: value === "true" ? "Yes" : "No",
          queryToRemove: key,
        };
        break;

      case "has_procurement_products":
        appliedFilters.hasProcurementProducts = {
          label: "Procurement Product Exists",
          value: value === "true" ? "Yes" : "No",
          queryToRemove: key,
        };
        break;

      case "low_in_stock":
        appliedFilters.lowInStock = {
          label: "Low In Stock",
          value: value === "true" ? "Yes" : "No",
          queryToRemove: key,
        };
        break;

      case "is_override_price":
        appliedFilters.overridePrice = {
          label: "Override Price",
          value: value === "true" ? "Yes" : "No",
          queryToRemove: key,
        };
        break;

      case "has_negative_margin":
        appliedFilters.negativeMargin = {
          label: "Negative Margin",
          value: value === "true" ? "Yes" : "No",
          queryToRemove: key,
        };
        break;

      case "mapped_to_catalog":
        appliedFilters.syncedToEcommerce = {
          label: "Mapped To Catalog",
          value: value === "true" ? "Yes" : "No",
          queryToRemove: key,
        };
        break;

      case "obsolete":
        appliedFilters.obsolete = {
          label: "Obsolete",
          value: value === "true" ? "Yes" : "No",
          queryToRemove: key,
        };
    }
  });

  return appliedFilters;
};
