import axios from "axios";
import tokens from "services/auth/tokenService";
import config from "../../config";
import { refreshTokens } from "store/auth/thunks";
import { AppDispatch } from "../../store/index";

export const initHttp = (dispatch: AppDispatch): void => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalConfig = error.config;
      if (error.response) {
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const tokenService = tokens.service();
            await dispatch(refreshTokens(tokenService.getRefreshToken()));
            return axios({
              ...originalConfig,
              headers: {
                ...originalConfig.headers,
                Authorization: `Bearer ${localStorage.getAccessToken()}`,
              },
            });
          } catch {
            window.location.href = config.loginUrl;
          }
        }
      }
      return Promise.reject(error);
    },
  );
};
