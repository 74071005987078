import React from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Typography } from "@mui/material";

const RecentActions = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">Recent actions</Typography>
      <EditOutlinedIcon /> 0.5 inches 45 elbow[PL-10018]
    </>
  );
};

export default RecentActions;
