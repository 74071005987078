import React, { useCallback, useState } from "react";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import { selectbookmarksState } from "store/bookmarks";
import { deleteBookmark, updateBookmark } from "store/bookmarks/thunks";
import { IBookmark } from "store/bookmarks/interfaces";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import PushPin from "@mui/icons-material/PushPin";
import Paper from "@mui/material/Paper";
import FilterAccordion from "./FilterAccordion";
import Box from "@mui/material/Box";
import useChangeFilters from "hooks/useChangeFilters";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  ListItemButton,
  ListItemIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

const SavedBookmarks = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { bookmarks } = useAppSelector(selectbookmarksState);
  const changeFilters = useChangeFilters();

  const createOpenMap = useCallback(
    (bookmarkArray: IBookmark[]): Map<string, boolean> =>
      new Map(bookmarkArray?.map((bookmark) => [bookmark.name, false])),
    [],
  );

  // Map with keys representing saved bookmarks and values for their delete dialogs's state (open vs closed)
  const [dialogsOpen, setDialogsOpen] = useState<Map<string, boolean>>(
    createOpenMap(bookmarks),
  );
  const openModal = (bookmarkName: string) =>
    setDialogsOpen((oldMap) => new Map([...oldMap]).set(bookmarkName, true));
  const closeModal = (bookmarkName: string) =>
    setDialogsOpen((oldMap) => new Map([...oldMap]).set(bookmarkName, false));

  const nameClicked = (name: string) => {
    const bookmark = bookmarks.find((bookmark) => bookmark.name == name)!;
    changeFilters(new URLSearchParams(bookmark.query), true);
  };

  const renderDeleteButton = (bookmark: IBookmark) => {
    return (
      <Box>
        <ListItemButton
          onClick={() => {
            openModal(bookmark.name);
          }}
        >
          <ListItemIcon sx={{ minWidth: 0 }}>
            <DeleteIcon />
          </ListItemIcon>
        </ListItemButton>
        <Dialog
          open={dialogsOpen.get(bookmark.name) ?? false}
          onClose={() => {
            closeModal(bookmark.name);
          }}
        >
          <DialogTitle>{`Confirm delete saved filter: ${bookmark.name}`}</DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                closeModal(bookmark.name);
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                dispatch(deleteBookmark(bookmark));
                closeModal(bookmark.name);
              }}
              variant="contained"
              color={"error"}
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  function renderPinButton(bookmark: IBookmark) {
    return (
      <ListItemButton
        onClick={() => {
          dispatch(
            updateBookmark({
              old_view: bookmark.view,
              old_name: bookmark.name,
              bookmark: {
                name: bookmark.name,
                query: bookmark.query,
                view: bookmark.view,
                pinned: !bookmark.pinned,
              },
            }),
          );
        }}
      >
        <ListItemIcon sx={{ minWidth: 0 }}>
          {bookmark.pinned ? <PushPin /> : <PushPinOutlinedIcon />}
        </ListItemIcon>
      </ListItemButton>
    );
  }

  return (
    <FilterAccordion label="Saved filters">
      <TableContainer component={Paper} sx={{ height: "17rem" }}>
        <Table aria-label="simple table">
          <TableBody>
            {bookmarks?.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  align="left"
                  onClick={() => nameClicked(row.name)}
                  style={{ cursor: "pointer" }}
                >
                  {row.name}
                </TableCell>
                <TableCell align="right" size="small" padding="checkbox">
                  {renderPinButton(row)}
                </TableCell>
                <TableCell align="right" size="small" padding="checkbox">
                  {renderDeleteButton(row)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FilterAccordion>
  );
};

export default SavedBookmarks;
