import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { IAuthSliceState, IUser } from "./interfaces";
import { fetchUserData, login, refreshTokens } from "./thunks";
import token from "services/auth/tokenService";

const initialState: IAuthSliceState = {
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      token.service().clearTokens();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.rejected, (state, action) => {
        token.service().clearTokens();
        throw new Error(action.error.message);
      })
      .addCase(refreshTokens.rejected, () => {
        token.service().clearTokens();
      })
      .addCase(refreshTokens.fulfilled, (state, action) => {
        token.service().setAccessToken(action.payload.access);
        token.service().setRefreshToken(action.payload.refresh);
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.user = null;
        throw new Error(action.error.message);
      });
  },
});

export const selectAuthenticatedUser = (state: RootState): IUser | null =>
  state.auth.user;
export const selectIsDemoAccount = (state: RootState): boolean =>
  state.auth.user?.company === 29;

export const { logout } = authSlice.actions;

export default authSlice.reducer;
