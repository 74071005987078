import React, { useCallback, useState } from "react";
import logo from "assets/images/EcomboosterLogo.png";
import useAppDispatch from "hooks/useAppDispatch";
import { resetPassword } from "store/auth/thunks";
import { useNavigate } from "react-router-dom";
import { displaySnackbar } from "store/snackbar";
import useAppSelector from "hooks/useAppSelector";
import { selectAuthenticatedUser } from "store/auth";
import {
  Box,
  Button,
  CardMedia,
  TextField,
  TextFieldProps,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const textFieldProps: TextFieldProps = {
  fullWidth: true,
  margin: "normal",
  size: "small",
  variant: "outlined",
};

interface IErrorProp {
  email: boolean;
}

const ResetPassword = (): JSX.Element => {
  const user = useAppSelector(selectAuthenticatedUser);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>(user ? user.email : "");

  const [error, setError] = useState<IErrorProp>({
    email: false,
  });

  const validate = useCallback((): boolean => {
    const validated: IErrorProp = {
      email: email.match(/^[\w.+]+@[\w-]{2,}\.\w{2,}(\.\w{2,})?$/) === null,
    };

    setError(validated);
    return !Object.values(validated).some((e) => e === true);
  }, [email]);

  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const validated = validate();
    if (validated) {
      await dispatch(resetPassword(email));
      dispatch(
        displaySnackbar({
          message: "Reset password email has been sent.",
          action: null,
          open: false,
        }),
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "40px 80px",
      }}
    >
      <Box>
        <CardMedia sx={{ marginBottom: "1rem" }}>
          <img width="170px" src={logo} />
        </CardMedia>
        <h4>Reset password</h4>
        <p>Fill out your email to reset password.</p>
      </Box>
      <Box component="form" onSubmit={handleOnSubmit}>
        <TextField
          error={error.email}
          label="Email"
          id="email"
          onChange={(e) => {
            setEmail(e.target.value);
            setError((prev) => ({ ...prev, email: false }));
          }}
          value={email}
          {...textFieldProps}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "20px 0 0 0",
          }}
        >
          <Button type="submit" variant="contained">
            Send reset email
          </Button>
        </Box>
        <Button
          onClick={() => navigate(-1)}
          variant="text"
          sx={{ paddingLeft: 0, marginTop: "1rem" }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <ArrowBackIcon />
            Back
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default ResetPassword;
