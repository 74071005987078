import React, { useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";
import { selectProcurementOrdersState } from "store/procurement-orders";
import { fetchProcurementOrdersAsync } from "store/procurement-orders/thunks";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import Loading from "pages/main-page/customers-orders/parts/Loading";
import { procurementTableColumn } from "./ParentTableColumn";
import ProcurementOrdersChildTable from "./ProcurementOrdersChildTable";
import { useSearchParams } from "react-router-dom";
import GridContainer from "components/common-components/GridContainer";

export default function ProcurementOrdersTable(props: {
  expandAll: boolean;
}): JSX.Element {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const expandAll = props.expandAll;
  const { procurementOrders, procurementOrdersLoaded } = useAppSelector(
    selectProcurementOrdersState,
  );
  useEffect(() => {
    dispatch(fetchProcurementOrdersAsync(searchParams));
  }, [searchParams, dispatch]);

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps["getDetailPanelContent"]>
  >(({ row }) => <ProcurementOrdersChildTable row={row} />, []);

  return (
    <Box sx={{ width: "100%" }}>
      {!procurementOrdersLoaded ? (
        <Loading />
      ) : (
        <GridContainer>
          <DataGridPro
            key={Number(expandAll)}
            rows={procurementOrders}
            columns={procurementTableColumn}
            rowBufferPx={0}
            getDetailPanelContent={getDetailPanelContent}
            pagination
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
              detailPanel: {
                expandedRowIds: expandAll
                  ? procurementOrders.map((row) => row.id)
                  : [],
              },
            }}
          />
        </GridContainer>
      )}
    </Box>
  );
}
