import React, { useEffect, useState } from "react";
import NotificationItem from "./NotificationItem";
import {
  Box,
  Divider,
  List,
  Paper,
  Stack,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  useTheme,
  styled,
} from "@mui/material";
import {
  fetchNotificationCount,
  fetchNotificationItemAsync,
} from "store/notification-system/thunks";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { selectNotifications } from "store/notification-system";
import { selectAuthenticatedUser } from "store/auth";
import procurementSup from "services/notification-system";
import { INotificationItemProps } from "store/notification-system/interfaces";
import { IUser } from "store/auth/interfaces";

const notificationList = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { results } = useAppSelector(selectNotifications);
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const [notifications, setNotifications] = useState<INotificationItemProps[]>(
    [],
  );
  const user = useAppSelector(selectAuthenticatedUser) as IUser;

  useEffect(() => {
    dispatch(
      fetchNotificationItemAsync({
        userId: user.id,
        showUnread: showUnreadOnly,
      }),
    );
  }, [showUnreadOnly]);

  useEffect(() => {
    setNotifications(results);
  }, [results]);

  const theme = useTheme();

  const handleMarkAllAsRead = async () => {
    const unRead = results.filter((t) => t.read_at === null);
    const res = await procurementSup.service().MarkAllAsRead(unRead);
    if (res > 0) {
      dispatch(
        fetchNotificationItemAsync({
          userId: user.id,
          showUnread: showUnreadOnly,
        }),
      );
    }
    dispatch(fetchNotificationCount({ userId: user.id, showUnread: true }));
  };
  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  const filteredNotifications = notifications.filter((notification) => {
    //This line possibly creates bug:
    if (showUnreadOnly) return !notification.read_at__isnull;
    return true;
  });

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        m: 2,
        borderRadius: 2,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Stack spacing={3}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            sx={{
              flexGrow: 1,
              fontWeight: "bold",
              color: theme.palette.text.primary,
            }}
          >
            Notifications
          </Typography>
          <Button
            onClick={
              handleMarkAllAsRead
            } /* variant="contained" color="primary" */
          >
            Mark all as read
          </Button>
          <FormControlLabel
            control={
              <Android12Switch
                checked={showUnreadOnly}
                onChange={() => setShowUnreadOnly(!showUnreadOnly)}
                name="unreadFilter"
                color="primary"
              />
            }
            label="Show Unread Only"
            sx={{ ml: 2 }}
          />
        </Box>

        <Divider />

        <Box sx={{ flex: 1, overflowY: "auto", maxHeight: "70vh", padding: 1 }}>
          <List>
            {filteredNotifications.length ? (
              filteredNotifications?.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  {...notification}
                  showUnreadOnly={showUnreadOnly}
                />
              ))
            ) : (
              <Typography variant="body1" align="center" color="textSecondary">
                No notifications to display
              </Typography>
            )}
          </List>
        </Box>
      </Stack>
    </Paper>
  );
};

export default notificationList;
