import { createAsyncThunk } from "@reduxjs/toolkit";
import inventoryLocation from "services/inventory-location";

export const fetchInventoryLocations = createAsyncThunk(
  "stock/fetchInventoryLocations",
  async (params: { limit: string; obsolete: boolean }, thunkAPI) => {
    const results = await inventoryLocation
      .service()
      .fetchInventoryLocations(params);
    return results;
  },
);
