import React, { useState } from "react";
import { Add, Delete } from "@mui/icons-material";
import { isEmpty } from "lodash-es";
import useAppSelector from "hooks/useAppSelector";
import { selectProcurementOrdersState } from "store/procurement-orders";
import { IOrder } from "store/procurement-orders/interfaces";
import { IHistoryItem } from "./Row";
import {
  clearBackorderSuccessMessage,
  selectBackorderState,
} from "store/backorder";
import useAppDispatch from "hooks/useAppDispatch";
import {
  addBackOrderAsync,
  removeBackOrderAsync,
} from "store/backorder/thunks";
import config from "../../../../config";
import SimpleSnackbar from "components/SimpleSnackbar";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import theme from "theme/theme";

const Backorders = ({
  historyRow,
}: {
  historyRow: IHistoryItem;
}): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { procurementOrders, procurementOrdersLoaded } = useAppSelector(
    selectProcurementOrdersState,
  );
  const { results, successMessage, error } =
    useAppSelector(selectBackorderState);

  const reserveQuantity =
    +historyRow.quantity.qty_ordered -
    +historyRow.quantity.qty_picked -
    +historyRow.quantity.qty_to_be_picked;

  const findBackorderOptions = () => {
    const itemsWithOrderMade = procurementOrders.filter(
      (item) => item.order_made !== null,
    );

    const foundItems: {
      order: IOrder;
      quantity: number;
      procurement_order_item: number;
    }[] = [];

    for (const element of itemsWithOrderMade) {
      element.order_items.forEach((order_item) => {
        if (
          historyRow.procurementProductsOptions.includes(order_item.product) &&
          reserveQuantity !== 0 &&
          reserveQuantity <= order_item.quantity
        ) {
          return foundItems.push({
            order: element,
            quantity: order_item.quantity,
            procurement_order_item: order_item.id,
          });
        }
        return "No items found";
      });
    }
    return foundItems;
  };

  const addBackorder = (
    backorder: { order: IOrder; procurement_order_item: number },
    quantity: number,
  ) => {
    const { company, account } = backorder.order;
    setLoading(true);
    dispatch(
      addBackOrderAsync({
        order_item: historyRow.id,
        company,
        account,
        product: historyRow.product.product_item_id,
        procurement_order_item: backorder.procurement_order_item,
        quantity,
      }),
    )
      .unwrap()
      .finally(() => {
        setLoading(false);
        setOpen(true);
      });
  };

  const removeBackorder = (id: number) => {
    setLoading(true);
    dispatch(removeBackOrderAsync(id))
      .unwrap()
      .finally(() => {
        setLoading(false);
        setOpen(true);
      });
  };

  const foundBackorder = results.find(
    (backorder) => backorder.order_item === historyRow.id,
  );

  const reservedQuantity = foundBackorder && foundBackorder.quantity;
  const foundProcurementOrder =
    foundBackorder &&
    procurementOrders.find((item) =>
      item.order_items.some(
        (orderItem) => orderItem.id === foundBackorder.procurement_order_item,
      ),
    );

  const orderId =
    foundProcurementOrder && foundProcurementOrder.internal_order_number;
  const id = foundProcurementOrder && foundProcurementOrder.id;
  const orderNumber =
    foundProcurementOrder && foundProcurementOrder.order_number;
  const expectedDate =
    foundProcurementOrder && foundProcurementOrder.estimated_delivery_date;
  const supplierName =
    foundProcurementOrder && foundProcurementOrder.supplier_data.name;

  const orderItem =
    foundProcurementOrder &&
    foundProcurementOrder.order_items.find(
      (orderItem) => orderItem.id === foundBackorder.procurement_order_item,
    );

  const ifBackorderExists =
    +historyRow.quantity.qty_ordered !== +historyRow.quantity.qty_picked &&
    (isEmpty(historyRow.stock) ||
      Math.trunc(+historyRow.stock[0].accumulated_quantity) <
        +historyRow.quantity.qty_ordered);

  if (foundBackorder && !foundBackorder.procurement_order_item) {
    return <></>;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <>
      {loading ? (
        <Backdrop
          sx={{
            color: theme.palette.light.main,
            backgroundColor: theme.palette.action.selected,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" sx={{ ml: "22vw" }} />
        </Backdrop>
      ) : null}
      <SimpleSnackbar
        vertical="top"
        horizontal="right"
        open={open}
        message={successMessage}
        toggleSnackbar={() => {
          dispatch(clearBackorderSuccessMessage());
          setOpen(false);
        }}
        snackbarAction={null}
      />
      {!procurementOrdersLoaded && (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          height="20vh"
        >
          <CircularProgress size={25} />
        </Box>
      )}

      {ifBackorderExists && foundBackorder ? (
        <Card
          sx={{
            minWidth: 220,
            mb: 2,
            p: 1,
            background: theme.palette.light[95],
          }}
        >
          <Typography component="div" sx={{ fontSize: 14, mb: 1 }} gutterBottom>
            Reserved {reservedQuantity}x{" "}
            <Link
              target="_blank"
              href={orderItem && orderItem.product_data.urls[0].url}
            >
              {orderItem && orderItem.product_data.sku}
            </Link>{" "}
            on{" "}
            <Link
              target="_blank"
              href={`${config.clientUrl}/admin/procurement/order/${id}/change/`}
            >
              order #{orderId}
            </Link>{" "}
            from {supplierName} (#{orderNumber}), expected (ETA) on{" "}
            {expectedDate}.
          </Typography>

          <Button
            variant="outlined"
            color="error"
            size="small"
            startIcon={<Delete />}
            onClick={() => removeBackorder(foundBackorder.id)}
          >
            Remove {reservedQuantity}x
          </Button>
        </Card>
      ) : (
        <>
          {findBackorderOptions().length > 0 &&
            findBackorderOptions()?.map((backorder) => (
              <Card
                sx={{
                  minWidth: 220,
                  mb: 2,
                  p: 1,
                  background: theme.palette.light[95],
                }}
                key={backorder.order.id}
              >
                <Typography component="div" sx={{ fontSize: 14, mb: 1 }}>
                  Delivery on the way from{" "}
                  <Link
                    target="_blank"
                    href={`${config.clientUrl}/admin/procurement/order/${backorder.order.id}/change/`}
                  >
                    {backorder.order.supplier_data.name} with order number{" "}
                    {backorder.order.order_number}[#
                    {backorder.order.internal_order_number}]{" "}
                  </Link>
                  with spare capacity ({backorder.quantity}x), expected (ETA) on{" "}
                  {backorder.order.estimated_delivery_date}.
                </Typography>

                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => addBackorder(backorder, reserveQuantity)}
                  startIcon={<Add />}
                >
                  Reserve {reserveQuantity}x
                </Button>
              </Card>
            ))}
        </>
      )}
    </>
  );
};

export default Backorders;
