import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import PinnedItems from "./PinnedItems";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store";
import { initializePinnedItems } from "../../../../../store/sidebar/sidebarSlice";
import SidebarCategory from "./SidebarCategory";

const SidebarContent = ({ sidebarItems }: { sidebarItems: any }) => {
  const dispatch = useDispatch<AppDispatch>();
  const pinnedItems = useSelector(
    (state: RootState) => state.sidebar.pinnedItems,
  );

  useEffect(() => {
    const savedPinnedItems = localStorage.getItem("pinnedItems");
    if (savedPinnedItems) {
      dispatch(initializePinnedItems(JSON.parse(savedPinnedItems)));
    } else {
      const initialPinnedState: any = {};
      sidebarItems.forEach((category: any) => {
        category.children.forEach((child: any) => {
          if (child.pinned) initialPinnedState[child.key] = true;
        });
      });
      dispatch(initializePinnedItems(initialPinnedState));
    }
  }, [dispatch, sidebarItems]);

  useEffect(() => {
    localStorage.setItem("pinnedItems", JSON.stringify(pinnedItems));
  }, [pinnedItems]);

  return (
    <Container disableGutters>
      <PinnedItems sidebarItems={sidebarItems} />
      <Box>
        <Typography
          sx={{
            fontSize: "1.4rem",
            margin: "1rem",
            padding: "1rem 1rem 0 0",
            borderTop: "1px solid #e0e0e0",
          }}
        >
          Menu
        </Typography>
        {sidebarItems?.map((category: any) => (
          <SidebarCategory key={category.key} category={category} />
        ))}
      </Box>
    </Container>
  );
};

export default SidebarContent;
