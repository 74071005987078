import React, { useEffect, useRef } from "react";
import Slider from "@mui/material/Slider";
import useChangeFilters from "hooks/useChangeFilters";
import { useSearchParams } from "react-router-dom";
import FilterAccordion from "./FilterAccordion";

interface IDoubleControlSliderProps {
  label: string;
  param: string;
  min?: number;
  max?: number;
  lowerLimitIncluded?: boolean;
}

const DoubleControlSlider = ({
  label,
  param,
  min = 0,
  max = 100,
  lowerLimitIncluded = true,
}: IDoubleControlSliderProps): JSX.Element => {
  const [searchParams] = useSearchParams();
  const changeFilter = useChangeFilters();
  const debounce = useRef<NodeJS.Timeout>();
  const lowerParam = lowerLimitIncluded ? `${param}__gte` : `${param}__gt`;
  const upperParam = `${param}__lte`;
  const lowerParamValue = searchParams.get(lowerParam)!;
  const upperParamValue = searchParams.get(upperParam)!;
  const lowerLimit = searchParams.has(lowerParam)
    ? parseInt(lowerParamValue)
    : min;
  const upperLimit = searchParams.has(upperParam)
    ? parseInt(upperParamValue)
    : max;

  // construct the range using the given values and apply them
  const handleChange = (
    event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[],
  ) => {
    clearTimeout(debounce.current);
    const values = value as number[];
    // if left control is at min, ignore it else set it
    // if right control is at max, ignore it else set it
    const query = new URLSearchParams([
      [lowerParam, values[0] === min ? "" : values[0].toString()],
      [upperParam, values[1] === max ? "" : values[1].toString()],
    ]);

    useEffect(() => {
      if (debounce.current) {
        clearTimeout(debounce.current);
      }
      debounce.current = setTimeout(() => {
        changeFilter(query);
      }, 1000);

      return () => {
        if (debounce.current) {
          clearTimeout(debounce.current); // Clear the timeout when the component unmounts
        }
      };
    }, [query]);
  };

  const marks = [
    { value: min, label: param === "margin_percent" ? `<= ${min}` : `${min}` },
    { value: max, label: lowerLimitIncluded ? `>= ${max}` : `> ${max}` },
  ];

  return (
    <FilterAccordion label={label}>
      <Slider
        value={[lowerLimit, upperLimit]}
        min={min}
        max={max}
        valueLabelDisplay={"auto"}
        marks={marks}
        onChangeCommitted={handleChange}
      />
    </FilterAccordion>
  );
};

export default DoubleControlSlider;
