import React from "react";
import { Box, Button } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import { fetchCatalogInventoryLocationTypes } from "store/catalog-inventorylocationtype/thunks";
import AddIcon from "@mui/icons-material/Add";
import ColumnsForDataGrid from "./parts/ColumnsForDataGrid";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Searchbar from "components/filter-products/Searchbar";
import {
  selectCatalogInventoryLocationTypes,
  setCatalogInventoryLocationTypesParams,
} from "store/catalog-inventorylocationtype";
import AddInventoryLocationType from "./parts/AddInventoryLocationType";
import DeleteInventoryLocationType from "./parts/DeleteInventoryLocationtype";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditInventoryLocationType from "./parts/EditInventoryLocationType";
import useBreakpoint from "hooks/useBreakpoint";
import theme from "theme/theme";
import GridContainer from "components/common-components/GridContainer";
import { DataGridPro } from "@mui/x-data-grid-pro";
import CommonButton from "components/common-components/CommonButton";
import ToolboxArea from "components/common-components/ToolboxArea";
import ToolInfoTooltip from "components/common-components/ToolInfoTooltip";
import ToolIconContainer from "components/common-components/ToolIconContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const InventoryLocationType = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { inventoryLocationTypes, inventoryLocationTypesLoaded } =
    useAppSelector(selectCatalogInventoryLocationTypes);
  const [searchParams] = useSearchParams();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const isMediumScreen = useBreakpoint("medium");

  useEffect(() => {
    !inventoryLocationTypesLoaded &&
      dispatch(fetchCatalogInventoryLocationTypes());
  }, [dispatch, inventoryLocationTypesLoaded, searchParams]);

  const handleAddClose = () => {
    setAddModalOpen(false);
  };

  const handleDeleteClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDeleteConfirm = () => {
    handleDeleteClose();
  };

  const handleEditClose = () => {
    setEditModalOpen(false);
  };

  const handleEditConfirm = () => {
    handleEditClose();
  };

  useEffect(() => {
    dispatch(fetchCatalogInventoryLocationTypes());
  }, [dispatch, searchParams]);

  const searchHandler = (value: string) => {
    dispatch(setCatalogInventoryLocationTypesParams({ name: value, page: 0 }));
  };

  const handleSelectedRowsChange = (selectionModel: GridRowSelectionModel) => {
    const selectedItems = selectionModel?.map((id: any) =>
      inventoryLocationTypes.find((item) => item.id === id),
    );
    setSelectedRows(selectedItems);
  };

  return (
    <>
      {addModalOpen && (
        <AddInventoryLocationType
          isOpen={addModalOpen}
          handleClose={handleAddClose}
        />
      )}
      {deleteModalOpen && (
        <DeleteInventoryLocationType
          isOpen={deleteModalOpen}
          handleClose={handleDeleteClose}
          handleDelete={handleDeleteConfirm}
          selectedRows={selectedRows}
        />
      )}
      {editModalOpen && (
        <EditInventoryLocationType
          isOpen={editModalOpen}
          handleClose={handleEditClose}
          handleEditConfirm={handleEditConfirm}
          selectedRows={selectedRows}
          typeName={selectedRows.length > 0 ? selectedRows[0].type : ""}
          linebreak={
            selectedRows.length > 0 ? selectedRows[0].linebreak || false : false
          }
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMediumScreen ? "row" : "column",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" onChange={searchHandler} />
        <ToolboxArea>
          <ToolInfoTooltip title="Add inventory location type">
            <ToolIconContainer onClick={() => setAddModalOpen(true)}>
              <AddCircleIcon
                fontSize="large"
                sx={{
                  color: theme.palette.primary.main,
                  stroke: theme.palette.primary.main,
                  strokeWidth: 1,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>

          <ToolInfoTooltip title="Edit inventory location type">
            <ToolIconContainer
              onClick={() => {
                if (selectedRows.length === 1) {
                  setEditModalOpen(true);
                } else {
                  alert("Select only one row to edit.");
                }
              }}
            >
              <EditIcon
                fontSize="large"
                sx={{
                  color: theme.palette.warning.main,
                  stroke: theme.palette.warning.main,
                  strokeWidth: 2,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>

          <ToolInfoTooltip title="Delete inventory location type">
            <ToolIconContainer onClick={() => setDeleteModalOpen(true)}>
              <DeleteForeverIcon
                fontSize="large"
                sx={{
                  color: theme.palette.error.main,
                }}
              />
            </ToolIconContainer>
          </ToolInfoTooltip>
        </ToolboxArea>
      </Box>

      <GridContainer>
        <DataGridPro
          rows={inventoryLocationTypes}
          columns={ColumnsForDataGrid()}
          onRowSelectionModelChange={handleSelectedRowsChange}
          checkboxSelection
          pagination
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
        />
      </GridContainer>
    </>
  );
};
export default InventoryLocationType;
