interface Config {
  api: string;
  localApi?: string;
  domain: string;
  loginUrl: string;
  clientUrl: string;
  sentryDsn: string;
  environmen: string;
}

const config: Config = {
  api: import.meta.env.VITE_API as string,
  localApi: import.meta.env.VITE_LOCAL_API as string | undefined,
  domain: import.meta.env.VITE_DOMAIN as string,
  loginUrl: import.meta.env.VITE_LOGIN_URL as string,
  clientUrl: import.meta.env.VITE_CLIENT_URL as string,
  sentryDsn: import.meta.env.VITE_SENTRY_DSN as string,
  environmen: import.meta.env.VITE_ENV as string,
};

export default config;
