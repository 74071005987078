import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import Toolbar from "./parts/Toolbar";
import { selectProcurementProductsState } from "store/procurement-products";
import {
  addToCatalog,
  fetchProcurementsProducts,
} from "store/procurement-products/thunks";
import { createGridRowsProp } from "./utils/createGridRowsProp";
import { useSearchParams } from "react-router-dom";
import Filter from "components/filter-products/Filter";
import Searchbar from "components/filter-products/Searchbar";
import AddNewSupplierButton from "components/AddNewSupplierButton";
import ErrorPage from "components/ErrorComponent";
import { isEmpty } from "lodash-es";
import AvailableFilters from "./parts/AvailableFilters";
import { fetchSupplierListAsync } from "store/procurement-supplierList/thunks";
import GridContainer from "components/common-components/GridContainer";
import { DataGridPro } from "@mui/x-data-grid-pro";

const ProcurementsAndSuppliersPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const [selected, setSelected] = useState<GridRowSelectionModel>([]);

  const { columns, procurementProducts, count, loading, error } =
    useAppSelector(selectProcurementProductsState);

  const onSelectChange = useCallback((sel: GridRowSelectionModel) => {
    document.querySelector(
      "[data-field=image_urls] .MuiDataGrid-columnHeaderTitle",
    )!.textContent = `${sel.length} products selected`;
    setSelected(sel);
  }, []);

  const rows = useMemo(
    () => createGridRowsProp(columns, procurementProducts),
    [columns, procurementProducts],
  );

  const fetchFilteredProducts = useCallback(() => {
    dispatch(fetchProcurementsProducts(searchParams));
    document.querySelector(".MuiDataGrid-virtualScroller")!.scrollTo(0, 0);
  }, [dispatch, searchParams]);

  useEffect(() => {
    const debounce = setTimeout(fetchFilteredProducts, 100);
    return () => clearTimeout(debounce);
  }, [fetchFilteredProducts, searchParams]);

  useEffect(() => {
    dispatch(fetchSupplierListAsync(new URLSearchParams()));
  }, [dispatch]);

  const addToProductList = () => {
    dispatch(addToCatalog(selected as number[]));
  };

  if (!isEmpty(error)) {
    return <ErrorPage {...error} />;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <Searchbar placeholder="Search" />
        <Toolbar addToCatalog={addToProductList} />
      </Box>
      <Filter
        viewName="procurement_products"
        supplierInfoRequired={true}
        children={<AvailableFilters />}
      />

      <GridContainer>
        <DataGridPro
          loading={loading}
          rows={rows}
          columns={columns}
          rowHeight={90}
          columnHeaderHeight={40}
          onRowSelectionModelChange={onSelectChange}
          checkboxSelection
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          pagination
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          hideFooterSelectedRowCount
        />
      </GridContainer>
    </>
  );
};

export default ProcurementsAndSuppliersPage;
