import React, { useState } from "react";
import {
  TextField,
  FormControlLabel,
  Button,
  FormControl,
  FormGroup,
  RadioGroup,
  FormLabel,
  Radio,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { selectAuthenticatedUser } from "store/auth";
import { IUser } from "store/auth/interfaces";
import {
  addNewBulkDescription,
  fetchBulkDescriptionProducts,
} from "store/catalog-bulk-description/thunk";
import {
  setShowNotification,
  setUpdateMessage,
} from "store/catalog-bulk-description/index";

const AddBulkDescriptionForm = ({ closeModal }: { closeModal: () => void }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectAuthenticatedUser) as IUser;
  const account = user.id;
  const company = user.company as number;

  const [product, setProduct] = useState<number>();
  const [selectedLanguage, setSelectedLanguage] = useState<string>("SV");
  const [isMachineTranslation, setIsMachineTranslation] =
    useState<boolean>(true);
  const [isOriginalContentFromSupplier, setIsOriginalContentFromSupplier] =
    useState<boolean>(true);
  const [source, setSource] = useState<string>("own_content");
  const [name, setName] = useState<string>(""); // <= 150 characters
  const [excerpt, setExcerpt] = useState<string>(""); // short description <= 2000 characters
  const [content, setContent] = useState<string>(""); // full description <= 10000 characters
  const [metaTitle, setMetaTitle] = useState<string>(""); // <= 250 characters
  const [metaKeywords, setMetaKeywords] = useState<string>(""); // <= 250 characters
  const [metaDescription, setMetaDescription] = useState<string>(""); // <= 500 characters
  const [links, setLinks] = useState<any>("no");

  const [productError, setProductError] = useState<boolean>(false);
  const [languageError, setLanguageError] = useState<boolean>(false);
  const [sourceError, setSourceError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false); // <= 150 characters
  const [excerptError, setExcerptError] = useState<boolean>(false); // short description <= 2000 characters
  const [contentError, setContentError] = useState<boolean>(false); // full description <= 10000 characters
  const [metaTitleError, setMetaTitleError] = useState<boolean>(false); // <= 250 characters
  const [metaKeywordsError, setMetaKeywordsError] = useState<boolean>(false); // <= 250 characters
  const [metaDescriptionError, setMetaDescriptionError] =
    useState<boolean>(false); // <= 500 characters

  const handleAddBulkDescriptionData = () => {
    const isProductEmpty = product === undefined || null || product <= 0;
    const isLanguageEmpty = selectedLanguage === null;
    const isSourceEmpty = source === "";
    const isNameEmpty = name === "";
    const isExcerptEmpty = excerpt === "";
    const isContentEmpty = content === "";
    const isMetaTitleEmpty = metaTitle === "";
    const isMetaKeywordsEmpty = metaKeywords === "";
    const isMetaDescriptionEmpty = metaDescription === "";

    setProductError(isProductEmpty);
    setLanguageError(isLanguageEmpty);
    setSourceError(isSourceEmpty);
    setNameError(isNameEmpty);
    setExcerptError(isExcerptEmpty);
    setContentError(isContentEmpty);
    setMetaTitleError(isMetaTitleEmpty);
    setMetaKeywordsError(isMetaKeywordsEmpty);
    setMetaDescriptionError(isMetaDescriptionEmpty);

    if (
      isProductEmpty ||
      isLanguageEmpty ||
      isSourceEmpty ||
      isNameEmpty ||
      isExcerptEmpty ||
      isContentEmpty ||
      isMetaTitleEmpty ||
      isMetaKeywordsEmpty ||
      isMetaDescriptionEmpty
    ) {
      return;
    } else {
      const newData = {
        language: selectedLanguage,
        machine_translation: isMachineTranslation,
        original_content_from_supplier: isOriginalContentFromSupplier,
        source: source,
        name: name,
        excerpt: excerpt,
        content: content,
        meta_title: metaTitle,
        meta_keywords: metaKeywords,
        meta_description: metaDescription,
        links: links,
        account: account,
        company: company,
        product: product,
      };

      dispatch(addNewBulkDescription(newData)).then(() => {
        dispatch(setUpdateMessage("Bulk description has been added!"));
        dispatch(setShowNotification(true));
      });
      closeModal();
      dispatch(fetchBulkDescriptionProducts({}));
    }
  };

  return (
    <>
      <FormControl component="form" fullWidth>
        <FormGroup sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <FormControl fullWidth required>
            <InputLabel id="language">Language</InputLabel>
            <Select
              labelId="language"
              id="language"
              label="Language"
              defaultValue="SV"
              onChange={(e) => setSelectedLanguage(e.target.value)}
              error={languageError}
            >
              <MenuItem value="EN">English</MenuItem>
              <MenuItem value="SV">Swedish</MenuItem>
              <MenuItem value="NO">Norwegian</MenuItem>
              <MenuItem value="DA">Danish</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth required>
            <InputLabel id="source">Source</InputLabel>
            <Select
              labelId="source"
              id="source"
              label="Source"
              defaultValue="own_content"
              onChange={(e) => setSource(e.target.value)}
              error={sourceError}
            >
              <MenuItem value="own_content">Own content</MenuItem>
              <MenuItem value="org_supplier">Org supplier</MenuItem>
              <MenuItem value="chatgpt">Chat GPT</MenuItem>
            </Select>
          </FormControl>

          <TextField
            required
            error={nameError}
            fullWidth
            id="name"
            label="Product name"
            variant="outlined"
            onChange={(e) => setName(e.target.value)}
            slotProps={{ htmlInput: { maxLength: 150 } }} // <= 150 characters
          />
          <TextField
            required
            error={excerptError}
            fullWidth
            id="excerpt"
            label="Short description"
            variant="outlined"
            onChange={(e) => setExcerpt(e.target.value)}
            slotProps={{ htmlInput: { maxLength: 2000 } }} // <= 2000 characters
          />
          <TextField
            required
            error={contentError}
            fullWidth
            id="content"
            label="Full description"
            variant="outlined"
            onChange={(e) => setContent(e.target.value)}
            slotProps={{ htmlInput: { maxLength: 2000 } }} // <= 10000 characters
          />
          <TextField
            required
            error={metaTitleError}
            fullWidth
            id="meta_title"
            label="Meta title"
            variant="outlined"
            onChange={(e) => setMetaTitle(e.target.value)}
            slotProps={{ htmlInput: { maxLength: 250 } }} // <= 250 characters
          />
          <TextField
            required
            error={metaKeywordsError}
            fullWidth
            id="meta_keywords"
            label="Meta keywords"
            variant="outlined"
            onChange={(e) => setMetaKeywords(e.target.value)}
            slotProps={{ htmlInput: { maxLength: 250 } }} // <= 250 characters
          />
          <TextField
            required
            error={metaDescriptionError}
            fullWidth
            id="meta_descriptions"
            label="Meta description"
            variant="outlined"
            onChange={(e) => setMetaDescription(e.target.value)}
            slotProps={{ htmlInput: { maxLength: 500 } }} // <= 500 characters
          />
          <TextField
            required
            error={productError}
            fullWidth
            id="product"
            label="Product (Number)"
            type="number"
            variant="outlined"
            onChange={(e) => setProduct(Number(e.target.value))}
          />

          <FormControl fullWidth required>
            <FormLabel id="links">
              Does the content contains links, images? If yes, please choose if
              they are internal or external resources.
            </FormLabel>
            <RadioGroup
              aria-labelledby="links"
              name="link"
              onChange={(e) => {
                setLinks(e.target.value);
              }}
              defaultValue="no"
            >
              <FormControlLabel value="no" control={<Radio />} label="No" />
              <FormControlLabel
                value="internal"
                control={<Radio />}
                label="Yes, internal"
              />
              <FormControlLabel
                value="external"
                control={<Radio />}
                label="Yes, external"
              />
            </RadioGroup>
          </FormControl>

          <FormControl fullWidth required>
            <FormLabel id="machine_translation">
              Automatically translated with e.g. google translate?
            </FormLabel>
            <RadioGroup
              aria-labelledby="machine_translation"
              name="machine translation"
              onChange={(e) => {
                const checker = e.target.value === "true" ? true : false;
                setIsMachineTranslation(checker);
              }}
              defaultValue="true"
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          <FormControl fullWidth required>
            <FormLabel id="original_content_from_supplier">
              Original content from the supplier
            </FormLabel>
            <RadioGroup
              aria-labelledby="original_content_from_supplier"
              name="original content from supplier"
              onChange={(e) => {
                const checker = e.target.value === "true" ? true : false;
                setIsOriginalContentFromSupplier(checker);
              }}
              defaultValue="true"
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>

          <Button
            variant="contained"
            sx={{
              width: "fit-content",
              display: "flex",
              alignSelf: "flex-end",
            }}
            onClick={handleAddBulkDescriptionData}
          >
            Add
          </Button>
        </FormGroup>
      </FormControl>
    </>
  );
};

export default AddBulkDescriptionForm;
