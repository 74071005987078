/* eslint-disable no-console */
import { createAsyncThunk } from "@reduxjs/toolkit";
import catalog from "services/catalog";
import {
  modifyProducts,
  removeProducts,
  setLoading,
  setPublishAction,
} from ".";
import {
  FilterType,
  ICatalogProductDescription,
  IPublishProductsDTO,
  IUpdateProductsDTO,
} from "./interfaces";

export const fetchProductCount = createAsyncThunk(
  "catalog/fetchProductCount",
  async (filter: FilterType) => {
    return await catalog.service().fetchProductCount(filter);
  },
);
export const fetchProductDescription = createAsyncThunk(
  "catalog/fetchProductDescription",
  async (queryParams: string) => {
    return await catalog.service().fetchProductDescription(queryParams);
  },
);
export const fetchCatalogProductsDescription = createAsyncThunk(
  "catalog/fetchCatalogProductsDescription",
  async () => {
    return await catalog.service().fetchCatalogProductsDescription();
  },
);
export const fetchCatalogProducts = createAsyncThunk(
  "catalog/fetchCatalogProducts",
  async (queryParams: string, { dispatch }) => {
    dispatch(setLoading(true));

    const result = await catalog.service().fetchCatalogProducts(queryParams);

    dispatch(setLoading(false));
    return result;
  },
);

export const fetchProductTypes = createAsyncThunk(
  "catalog/fetchProductTypes",
  async () => {
    return await catalog.service().fetchProductTypes();
  },
);

export const fetchCatalogProductsWithIds = createAsyncThunk(
  "catalog/fetchCatalogProductsWithIds",
  async (ids: number[], { dispatch }) => {
    dispatch(setLoading(true));

    const result = await catalog.service().fetchCatalogProductsWithIds(ids);

    dispatch(setLoading(false));
    return result;
  },
);

export const publishCatalogProducts = createAsyncThunk(
  "catalog/publishCatalogProducts",
  async (publishProductsDto: IPublishProductsDTO, { dispatch }) => {
    dispatch(setPublishAction(publishProductsDto.action));
    return await catalog.service().publishProducts(publishProductsDto);
  },
);

export const updateProducts = createAsyncThunk(
  "catalog/updateProducts",
  async (
    updateProductsDto: IUpdateProductsDTO,
    { dispatch, rejectWithValue },
  ) => {
    try {
      await catalog.service().updateProducts(updateProductsDto);
      dispatch(modifyProducts(updateProductsDto));
      return updateProductsDto;
    } catch (err: any) {
      // if network error occured during the put request
      if (err.code === "ERR_NETWORK") {
        return rejectWithValue(err);
      }
      return rejectWithValue;
    }
  },
);

export const deleteProductsWithIds = createAsyncThunk(
  "catalog/deteleProducts",
  async (ids: number[]) => {
    const result = await catalog.service().deleteProducts(ids);
    return result;
  },
);

export const updateProductDescription = createAsyncThunk(
  "catalog/updateProductDescription",
  async (updateProductDescription: ICatalogProductDescription) => {
    try {
      await catalog
        .service()
        .updateProductDescription(updateProductDescription);
    } catch (error) {
      console.error(error);
    }
  },
);
