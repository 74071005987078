import React, { ReactNode, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";

interface IProps {
  label: string;
  children?: ReactNode;
}

const EditAccordion = ({ label, children }: IProps): JSX.Element => {
  const [expanded, setExpanded] = useState(true);
  const theme = useTheme();

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <>
      <Divider />
      <Accordion
        expanded={expanded}
        square
        disableGutters
        elevation={0}
        onChange={toggleExpanded}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: expanded ? theme.palette.primary.main : "black",
              }}
            />
          }
          sx={{
            color: expanded ? theme.palette.primary.main : "black",
            flexDirection: "row-reverse",
            paddingLeft: 0,
            gap: "0.5rem",
          }}
        >
          <Typography
            sx={{
              textTransform: "uppercase",
              fontSize: 14,
            }}
          >
            {label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ height: "100%" }}>{children}</AccordionDetails>
      </Accordion>
    </>
  );
};

export default EditAccordion;
