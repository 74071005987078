import React from "react";
import FilterDateRangeStacked from "components/filter-products/FilterDateRangeStacked";
import FilterSelectBinary from "components/filter-products/FilterSelectBinary";
import FilterSelectMultiple from "components/filter-products/FilterSelectMultiple";
import useAppSelector from "hooks/useAppSelector";
import { selectSupplierListState } from "store/procurement-supplierList";

const AvailableFilters = (): JSX.Element => {
  const { results } = useAppSelector(selectSupplierListState);
  const suppliers: [string, string][] = results?.map(
    (supplier): [string, string] => [supplier.name, supplier.id.toString()],
  );

  return (
    <>
      <FilterSelectMultiple
        label="Supplier"
        param={"supplier__in"}
        options={suppliers}
      />
      <FilterSelectBinary
        label="In my product catalog"
        param="mapped_to_catalog"
      />
      <FilterDateRangeStacked label="Created at" param="created_at" />
    </>
  );
};

export default AvailableFilters;
