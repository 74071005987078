import axios from "axios";
import config from "../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "..";
import { IProcurementProductResponseDTO } from "store/procurement-products/interfaces";
import { endpoint } from "constants/endpoint";

export interface IProcurementProductsService {
  fetchProcurementsProducts(
    queryParams: URLSearchParams,
  ): Promise<IProcurementProductResponseDTO>;
  addToCatalog(ids: number[]): Promise<void>;
}

export default new Mockable<IProcurementProductsService>({
  async fetchProcurementsProducts(
    queryParams: URLSearchParams,
  ): Promise<IProcurementProductResponseDTO> {
    try {
      const res = await axios(`${config.api}${endpoint.procurement_products}`, {
        params: queryParams,
        headers: commonHeaders(),
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return Promise.reject(
          new Error("Unexpected response format in fetchProcurementsProducts"),
        );
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },

  async addToCatalog(ids: number[]): Promise<void> {
    const res = await axios.post(
      `${config.api}${endpoint.procurement_products_add_to_catalog}`,
      { ids: ids },
      { headers: commonHeaders() },
    );
    if (res.status !== 200) {
      Promise.reject("Something went wrong on create");
    }
  },
});
