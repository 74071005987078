import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISelectedFilter } from "../../interfaces";

import { RootState } from "../index";
import { fetchSuppliers } from "./thunks";
import { IFilterProcurementProductsSliceState, IFilters } from "./interfaces";
import { ISupplier } from "store/suppliers/interfaces";

const initialState: IFilterProcurementProductsSliceState = {
  selectedFilters: [],
  filters: {
    wishlist: {
      param: "wishlist",
      "Not skipped": "NO_SKIP",
      Skipped: "SKIP",
    },
    mappedToCatalog: {
      param: "mapped_to_catalog",
      Yes: "true",
      No: "false",
    },
    createdAt: {
      param: "created_at",
    },
    supplier: {
      param: "supplier",
    },
    freeText: {
      param: "search",
    },
  },
};

const filterProcurementProductsSlice = createSlice({
  name: "filterProcurementProducts",
  initialState,
  reducers: {
    setSelectedFilter: (state, action: PayloadAction<ISelectedFilter>) => {
      const filterToReplace = state.selectedFilters.find(
        (f: ISelectedFilter) => f.label === action?.payload.label,
      );
      if (filterToReplace && action) {
        state.selectedFilters[state.selectedFilters.indexOf(filterToReplace)] =
          action.payload;
      } else {
        action && state.selectedFilters.push(action.payload);
      }
    },
    removeSelectedFilterByLabel: (state, action: PayloadAction<string>) => {
      const filterToRemove = state.selectedFilters.find(
        (f: ISelectedFilter) => f.label === action?.payload,
      );
      if (filterToRemove) {
        state.selectedFilters.splice(
          state.selectedFilters.indexOf(filterToRemove),
          1,
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSuppliers.fulfilled, (state, action) => {
      state.filters.supplier = action.payload.reduce(
        (suppliers, supplier) => ({
          ...suppliers,
          [supplier.name]: supplier.name_abbrev,
        }),
        state.filters.supplier,
      );
    });
  },
});

export const selectFilters = (state: RootState): IFilters =>
  state.filterProcurementProducts.filters;
export const selectSelectedFilters = (state: RootState): ISelectedFilter[] =>
  state.filterProcurementProducts.selectedFilters;

export const { setSelectedFilter, removeSelectedFilterByLabel } =
  filterProcurementProductsSlice.actions;

export default filterProcurementProductsSlice.reducer;
