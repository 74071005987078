import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { ITaxonomiesSliceState } from "./interfaces";
import { fetchTaxonomies } from "./thunks";
import { endpoint } from "constants/endpoint";
import config from "../../config";
import { httpErrorHandler } from "utils/error-response";

export const initialState: ITaxonomiesSliceState = {
  loading: false,
  taxonomies: [],
  count: 0,
  taxonomiesLoaded: false,
  filterParams: {
    page: 0,
    limit: 25,
    search: "",
    taxonomy_id: "",
    slug_en: "",
    slug_sv: "",
  },
  error: {},
  updateMessage: "",
};

const taxonomiesSlice = createSlice({
  name: "taxonomies",
  initialState,
  reducers: {
    setTaxonomiesParams: (state, action) => {
      state.taxonomiesLoaded = false;
      state.filterParams = { ...state.filterParams, ...action.payload };
    },
    resetAllFilters: (state) => {
      state.taxonomiesLoaded = false;
      state.filterParams = {
        page: 0,
        limit: 25,
        search: "",
      };
    },
    setUpdateMessage: (state, { payload }) => {
      state.updateMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxonomies.fulfilled, (state, action) => {
        state.taxonomies = action.payload.results;
        state.taxonomiesLoaded = true;
        state.nextPageUrl = action.payload.next;
        state.previousPageUrl = action.payload.previous;
        state.count = action.payload.count;
      })
      .addCase(fetchTaxonomies.rejected, (state, { error }) => {
        state.loading = false;
        state.error = {
          ...httpErrorHandler(error),
          url: config.api + endpoint.taxonomies,
        };
      });
  },
});

export const selectTaxonomies = (state: RootState): ITaxonomiesSliceState =>
  state.taxonomies;

export const { setTaxonomiesParams, resetAllFilters, setUpdateMessage } =
  taxonomiesSlice.actions;

export default taxonomiesSlice.reducer;
