import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../index";
import {
  IAccountDetails,
  ICompanyInfo,
  IOnboardingSliceState,
  UserRole,
} from "./interfaces";
import { createAccount } from "./thunks";

const initialState: IOnboardingSliceState = {
  currentStep: 0,
  accountDetails: {
    first_name: "",
    last_name: "",
    email: "",
    phonenumber: "",
    role: "business",
    password: "",
    password2: "",
    company_name: "",
    category: "",
  },
  companyInfo: {
    name: "",
    country: "",
    url: "",
    role: "Admin",
    platform: "",
    agreed_to_terms: false,
  },
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    nextStep: (state) => {
      const maxStep = 2;
      if (state.currentStep < maxStep) {
        state.currentStep++;
      }
    },
    previousStep: (state) => {
      if (state.currentStep > 0) {
        state.currentStep--;
      }
    },
    setAccountDetails: (state, action: PayloadAction<IAccountDetails>) => {
      state.accountDetails = action.payload;
    },
    setUserRole: (state, action: PayloadAction<UserRole>) => {
      state.accountDetails.role = action.payload;
    },
    setCompanyInfo: (state, action: PayloadAction<ICompanyInfo>) => {
      state.companyInfo = action.payload;
    },
    resetOnboarding: (state) => {
      state.currentStep = 0;
      state.accountDetails = {
        first_name: "",
        last_name: "",
        email: "",
        phonenumber: "",
        role: "business",
        password: "",
        password2: "",
        company_name: "",
        category: "",
      };
      state.companyInfo = {
        name: "",
        country: "",
        url: "",
        role: "Admin",
        platform: "Woocommerce",
        agreed_to_terms: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createAccount.rejected, (state, action) => {
      throw new Error(action.error.message);
    });
  },
});

export const selectAccountDetails = (state: RootState): IAccountDetails =>
  state.onboarding.accountDetails;
export const selectCompanyInfo = (state: RootState): ICompanyInfo =>
  state.onboarding.companyInfo;
export const selectCurrentStep = (state: RootState): number =>
  state.onboarding.currentStep;
export const selectUserRole = (state: RootState): UserRole =>
  state.onboarding.accountDetails.role;

export const {
  nextStep,
  previousStep,
  setAccountDetails,
  setUserRole,
  setCompanyInfo,
  resetOnboarding,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
