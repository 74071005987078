import React from "react";
import { useSearchParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FilterAccordion from "./FilterAccordion";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import useChangeFilters from "hooks/useChangeFilters";

interface IFilterSelectMultipleProps {
  label: string;
  param: string;
  options: [optionLabel: string, optionValue: string][];
}

const FilterSelectMultiple = ({
  label,
  param,
  options,
}: IFilterSelectMultipleProps): JSX.Element => {
  const [searchParams] = useSearchParams();
  const changeFilter = useChangeFilters();
  const searchParamsValue = searchParams.get(param)!;
  const selectedValues = searchParams.has(param)
    ? searchParamsValue.split(",")
    : [];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (checked) {
      selectedValues.push(value);
    } else {
      selectedValues.splice(selectedValues.indexOf(value), 1);
    }
    changeFilter(new URLSearchParams([[param, selectedValues.join(",")]]));
  };

  return (
    <FilterAccordion label={label}>
      <FormControl>
        <FormGroup>
          {options?.map(([optionLabel, optionValue]) => (
            <FormControlLabel
              key={optionValue}
              control={
                <Checkbox
                  checked={selectedValues.includes(optionValue)}
                  value={optionValue}
                  onChange={handleChange}
                />
              }
              label={optionLabel}
            />
          ))}
        </FormGroup>
      </FormControl>
    </FilterAccordion>
  );
};

export default FilterSelectMultiple;
