import React from "react";
import FilterDateRangeStacked from "components/filter-products/FilterDateRangeStacked";
import FilterSelectMultiple from "components/filter-products/FilterSelectMultiple";
import useAppSelector from "hooks/useAppSelector";
import { selectSupplierListState } from "store/procurement-supplierList";

const AvailableFilters = (): JSX.Element => {
  const { results } = useAppSelector(selectSupplierListState);
  const suppliers: [string, string][] = results
    ?.map(
      (supplier) => [supplier.name, supplier.id.toString()] as [string, string],
    )
    .sort((a, b) => a[0].localeCompare(b[0]));

  return (
    <>
      <FilterSelectMultiple
        label="Supplier"
        param={"supplier__in"}
        options={suppliers}
      />
      <FilterDateRangeStacked label="Created at" param="feed_date" />
    </>
  );
};

export default AvailableFilters;
