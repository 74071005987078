import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import useAppDispatch from "hooks/useAppDispatch";
import { deleteInventoryLocationTypeAsync } from "store/catalog-inventorylocationtype/thunks";

interface DeleteModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  selectedRows: any[];
}

const DeleteInventoryLocationType = ({
  isOpen,
  handleClose,
  selectedRows,
}: DeleteModalProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleDeleteBtn = () => {
    selectedRows.forEach((row) => {
      dispatch(deleteInventoryLocationTypeAsync(row.id));
    });
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              marginBottom: 3,
            }}
          >
            Are you sure?
          </Typography>
          <Typography component="p">
            Are you sure you want to delete the selected Inventory Location
            types? All of the following items as well as related items will be
            removed:
          </Typography>
        </Box>
        <Box mt={2} justifyContent="center" alignItems="center">
          <Divider />
        </Box>
        <List>
          {selectedRows?.map((row) => (
            <ListItem key={row.id}>
              - <ListItemText primary={row.type} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleDeleteBtn}>
          Delete types
        </Button>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteInventoryLocationType;
