import axios from "axios";
import config from "../../../config";
import commonHeaders from "services/http/headers";
import { Mockable } from "../../index";
import { endpoint } from "constants/endpoint";

export interface ISyncOrder1monthService {
  fetchCustomersOrderSync1month(): Promise<{
    message: string;
  }>;
}

export default new Mockable<ISyncOrder1monthService>({
  async fetchCustomersOrderSync1month(): Promise<{
    message: string;
  }> {
    try {
      const res = await axios(
        `${config.api}${endpoint.customers_orders_sync}?no_of_days=31`,
        {
          headers: commonHeaders(),
        },
      );

      if (res.status === 200) {
        return res.data;
      } else {
        return {
          message:
            "fetchCustomersOrderSync1month failed with status" + res.status,
        };
      }
    } catch (err: any) {
      return Promise.reject(err);
    }
  },
});
