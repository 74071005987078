import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import useAppDispatch from "hooks/useAppDispatch";
import { ViewType, setViewOption } from "store/catalog";
import theme from "theme/theme";

export const options: ViewType[] = ["default", "stocks", "prices", "bulk edit"];
interface ViewsDropdownProps {
  view: ViewType;
  setView: React.Dispatch<React.SetStateAction<ViewType>>;
}

const ViewsDropdown = ({ setView, view }: ViewsDropdownProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const handleChange = (event: SelectChangeEvent) => {
    setView(event.target.value as ViewType);
    dispatch(setViewOption(event.target.value as ViewType));
  };

  return (
    <FormControl sx={{ backgroundColor: theme.palette.background.paper }}>
      <InputLabel id="demo-simple-select-autowidth-label">
        View Options
      </InputLabel>
      <Select
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        value={view}
        onChange={handleChange}
        size="small"
        label="View Options"
      >
        {options?.map((option, index) => (
          <MenuItem data-testid="menuitem" key={option} value={option}>
            {option.toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default ViewsDropdown;
