import React from "react";
import DoubleControlSlider from "components/filter-products/DoubleControlSlider";
import FilterDateRangeStacked from "components/filter-products/FilterDateRangeStacked";
import FilterSelectBinary from "components/filter-products/FilterSelectBinary";
import FilterSelectMultiple from "components/filter-products/FilterSelectMultiple";
import useAppSelector from "hooks/useAppSelector";
import { selectCatalogProductTypes } from "store/catalog";
import { selectSupplierListState } from "store/procurement-supplierList";

const AvailableFilters = (): JSX.Element => {
  const { results } = useAppSelector(selectSupplierListState);
  const suppliers: [string, string][] = results?.map((supplier) => [
    supplier.name,
    supplier.name_abbrev,
  ]);
  const productTypes = useAppSelector(selectCatalogProductTypes);

  return (
    <>
      <FilterSelectMultiple
        label="Supplier"
        param={"procurement_supplier"}
        options={suppliers}
      />
      <FilterSelectBinary
        label="Synced to Ecommerce"
        param="is_synced_to_ecommerce"
      />
      <FilterDateRangeStacked label="Created at" param="created_at" />
      <FilterDateRangeStacked label="Updated at" param="updated_at" />
      <FilterSelectBinary
        label="Procurement products exist"
        param="has_procurement_products"
      />
      <FilterSelectBinary label="Low stock" param="low_in_stock" />
      <FilterSelectBinary label="Override price" param="is_override_price" />
      <FilterSelectBinary label="Negative margin" param="has_negative_margin" />
      <FilterSelectMultiple
        label="Product type"
        param={"woo_product_type__in"}
        options={
          productTypes
            ? Array.from(productTypes.entries())?.map((entry) => [
                entry[1],
                entry[0],
              ])
            : []
        }
      />
      <DoubleControlSlider
        label="Margin percent"
        param="margin_percent"
        lowerLimitIncluded={false}
      />
    </>
  );
};

export default AvailableFilters;
