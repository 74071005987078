import React from "react";
import { Box, Typography, MenuList, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SidebarItem from "./SidebarItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

const PinnedItems = ({ sidebarItems }: { sidebarItems: any }) => {
  const pinnedItems = useSelector(
    (state: RootState) => state.sidebar.pinnedItems,
  );

  const pinnedListByCategory = sidebarItems
    ?.map((category: any) => ({
      categoryName: category.itemName,
      items: category.children.filter(
        (item: any) => pinnedItems[item.key] && !item.disabled,
      ),
    }))
    .filter((category: any) => category.items.length > 0);

  return (
    <Box sx={{ backgroundColor: "white", paddingBottom: "1rem" }}>
      <Typography
        style={{
          backgroundColor: "transparent",
          border: "0",
          cursor: "default",
          userSelect: "none",
          verticalAlign: "middle",
          height: "4.8rem",
          padding: "0 0.6rem",
          fontSize: "1.4rem",
          fontFamily: "Roboto",
          display: "flex",
          alignItems: "center",
        }}
      >
        Pinned menu items
      </Typography>
      {pinnedListByCategory && pinnedListByCategory.length > 0 ? (
        <MenuList disablePadding>
          {pinnedListByCategory?.map((category: any) => (
            <Box key={category.categoryName}>
              <Typography variant="h6" sx={{ padding: "1rem" }}>
                {category.categoryName}
              </Typography>
              {category.items?.map((item: any) => (
                <Box key={item.key} sx={{ padding: "0 0.6rem 0 2.6rem" }}>
                  <SidebarItem key={item.key} item={item} />
                </Box>
              ))}
            </Box>
          ))}
        </MenuList>
      ) : (
        <Box
          sx={{ display: "flex", alignItems: "center", paddingLeft: "2.6rem" }}
        >
          <IconButton sx={{ width: "2rem", height: "2rem" }}>
            <InfoOutlinedIcon sx={{ fontSize: 12 }} />
          </IconButton>
          <Typography>There is no pinned menu item!</Typography>
        </Box>
      )}
    </Box>
  );
};

export default PinnedItems;
