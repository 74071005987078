import React from "react";
import longLogo from "assets/images/EcomboosterLogo.png";
import { useState } from "react";
import { logout } from "store/auth";
import useAppDispatch from "hooks/useAppDispatch";
import { Link } from "react-router-dom";
import TopBar from "pages/main-page/notification-system/part/TopBar";
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import theme from "theme/theme";

const TopAppBar = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    dispatch(logout());
  };

  return (
    <AppBar elevation={0} position="fixed">
      <Toolbar
        variant="dense"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <img alt="Logo" height={34} src={longLogo} />
        <Box sx={{ display: "flex" }}>
          <TopBar />
          <IconButton
            onClick={handleMenu}
            aria-controls="menu-appbar"
            aria-haspopup="true"
          >
            <AccountCircleOutlinedIcon
              sx={{ fontSize: "2rem", color: theme.palette.dark.main }}
            />
          </IconButton>
        </Box>
      </Toolbar>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}
      >
        <MenuItem
          sx={{
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
            a: {
              color: theme.palette.dark.main,
              textDecoration: "none",
            },
          }}
        >
          <Link to="/login/reset-password">Reset password</Link>
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          sx={{
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
          }}
        >
          Logout
        </MenuItem>
      </Menu>
      <Divider />
    </AppBar>
  );
};

export default TopAppBar;
