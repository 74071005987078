/* eslint-disable no-console */
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import {
  addProcurementOrderAsync,
  addProcurementOrderItemAsync,
  editProcurementOrderItemAsync,
  fetchProcurementOrdersAsync,
} from "./thunks";
import { IProcurementOrdersState } from "./interfaces";

export const initialState: IProcurementOrdersState = {
  count: 0,
  procurementOrders: [],
  procurementOrdersLoaded: false,
  addedMessage: "",
  loading: false,
  procurementOrder: { id: 0 },
  procurementOrderItem: { id: 0 },
  error: "",
};

export const procurementOrdersSlice = createSlice({
  name: "procurementOrders",
  initialState,
  reducers: {
    clearAddedProcurementMessage: (state) => {
      state.addedMessage = "";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProcurementOrdersAsync.fulfilled, (state, action) => {
        state.count = action.payload.count;
        state.procurementOrders = action.payload.results;
        state.procurementOrdersLoaded = true;
      })
      .addCase(fetchProcurementOrdersAsync.rejected, (state, { error }) => {
        if (error.message) state.error = error.message;
        else
          console.log(
            " the error related to: fetchProcurementOrdersAsync.rejected",
          );
        state.procurementOrders = [];
      })
      .addCase(addProcurementOrderAsync.fulfilled, (state, action) => {
        state.procurementOrder.id = action.payload.id;
      })
      .addCase(addProcurementOrderAsync.rejected, (state, { error }) => {
        if (error.message) state.error = error.message;
        else
          console.log(
            " the error related to: addProcurementOrderAsync.rejected",
          );
      })
      .addCase(addProcurementOrderItemAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addProcurementOrderItemAsync.fulfilled, (state, action) => {
        state.procurementOrdersLoaded = false;
        state.procurementOrderItem.id = action.payload.id;
        state.addedMessage = "ordered is added successfully";
        state.loading = false;
      })
      .addCase(addProcurementOrderItemAsync.rejected, (state, { error }) => {
        if (error.message) state.error = error.message;
        else
          console.log(
            " the error related to guide: addProcurementOrderItemAsync.rejected",
          );
      })
      .addCase(editProcurementOrderItemAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(editProcurementOrderItemAsync.fulfilled, (state, action) => {
        state.procurementOrdersLoaded = false;
        state.procurementOrderItem.id = action.payload.id;
        state.addedMessage = "ordered is added successfully";
        state.loading = false;
      })
      .addCase(editProcurementOrderItemAsync.rejected, (state, { error }) => {
        if (error.message) state.error = error.message;
        else
          console.log(
            " the error related to guide: editProcurementOrderItemAsync.rejected",
          );
      });
  },
});

export const selectProcurementOrdersState = (
  state: RootState,
): IProcurementOrdersState => state.procurementOrders;

export const { clearAddedProcurementMessage } = procurementOrdersSlice.actions;

export default procurementOrdersSlice.reducer;
