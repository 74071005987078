import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SidebarState {
  activeItem: string;
  pinnedItems: { [key: number]: boolean };
}

const initialState: SidebarState = {
  activeItem: "Overview",
  pinnedItems: {},
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setActiveItem: (state, action: PayloadAction<string>) => {
      state.activeItem = action.payload;
    },
    togglePinnedItem: (state, action: PayloadAction<number>) => {
      state.pinnedItems[action.payload] = !state.pinnedItems[action.payload];
    },
    initializePinnedItems: (
      state,
      action: PayloadAction<{ [key: number]: boolean }>,
    ) => {
      state.pinnedItems = action.payload;
    },
  },
});

export const { setActiveItem, togglePinnedItem, initializePinnedItems } =
  sidebarSlice.actions;

export default sidebarSlice.reducer;
