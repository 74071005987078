import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import useAppSelector from "hooks/useAppSelector";
import {
  selectCompaniesListState,
  setUpdateMessage,
} from "store/procurements-companies";
import useAppDispatch from "hooks/useAppDispatch";
import {
  UpdateProcurementCompaniesListAsync,
  fetchCompaniesListAsync,
} from "store/procurements-companies/thunks";
import { dataString } from "../utils/dataInfo";
import SimpleSnackbar from "components/SimpleSnackbar";
import ModalContainerUpdate from "../utils/ModalContainerUpdate";
import { Box, Button, Modal, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import GridContainer from "components/common-components/GridContainer";
import { ICompanyList } from "store/procurements-companies/interfaces";

const CompaniesList = (): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const { results, updateMessage } = useAppSelector(selectCompaniesListState);
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [showShortNameErrorMsg, setShowShortNameErrorMsg] = useState(false);
  const [rows, setRows] = useState<ICompanyList[]>([]);
  const [rowsModal, setRowsModal] = useState<ICompanyList[]>([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [created, setCreated] = useState("");
  const [updated, setUpdated] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAbbrev, setCompanyAbbrev] = useState("");
  const [companyStreetAddress1, setCompanyStreetAddress1] = useState("");
  const [companyStreetAddress2, setCompanyStreetAddress2] = useState("");
  const [companyPostcode, setCompanyPostcode] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [companyCountry, setCompanyCountry] = useState("");
  const [companyEmailAddress, setCompanyEmailAddress] = useState("");
  const [companyPhonenumber, setCompanyPhonenumber] = useState("");
  const [companyHomepage, setCompanyHomepage] = useState("");
  const [companyFacebook, setCompanyFacebook] = useState("");
  const [companyLinkedin, setCompanyLinkedin] = useState("");
  const [companyYoutube, setCompanyYoutube] = useState("");
  const [companyInstagram, setCompanyInstagram] = useState("");
  const [companyVatNumber, setCompanyVatNumber] = useState("");
  const [packingSlipPriceConfiguration, setPackingSlipPriceConfiguration] =
    useState("");
  const [packingSlipExtraContent1, setPackingSlipExtraContent1] = useState("");
  const [packingSlipExtraContent2, setPackingSlipExtraContent2] = useState("");
  const [packingSlipFooterRight, setPackingSlipFooterRight] = useState("");
  const [brandingLogo, setBrandingLogo] = useState("");
  const [brandingFontColor, setBrandingFontColor] = useState("");
  const [brandingBackgroundColor, setBrandingBackgroundColor] = useState("");
  const [logoSquare, setLogoSquare] = useState("");
  const [logoRectangle, setLogoRectangle] = useState("");
  const [exchangeRateAdjustment, setExchangeRateAdjustment] = useState("");
  const [priceRoundingPreference, setPriceRoundingPreference] = useState(0);
  const [priceNoOfDecimalsInExport, setPriceNoOfDecimalsInExport] = useState(0);
  const [priceCommaSign, setPriceCommaSign] = useState(0);
  const [priceInclExclVat, setPriceInclExclVat] = useState(0);
  const [displayPriceInclExclVat, setdisplayPriceInclExclVat] = useState("");
  const [uuid, setUuid] = useState("");
  const [mediaStoragePath, setMediaStoragePath] = useState("");
  const [urlSearch, setUrlSearch] = useState("");
  const [urlAdminDirect, setUrlAdminDirect] = useState("");
  const [urlAdminSearch, setUrlAdminSearch] = useState("");
  const [skuSequenceNumber, setSkuSequenceNumber] = useState(0);
  const [skuPrefix, setSkuPrefix] = useState(0);
  const [skuSuffix, setSkuSuffix] = useState(0);
  const [customProductSequenceNumber, setCustomProductSequenceNumber] =
    useState(0);
  const [
    customProductSequenceNumberPrefix,
    setCustomProductSequenceNumberPrefix,
  ] = useState(0);
  const [
    customProductSequenceNumberSuffix,
    setCustomProductSequenceNumberSuffix,
  ] = useState(0);
  const [account, setAccount] = useState(0);
  const [company, setCompany] = useState(0);
  const [companyHomepageError, setCompanyHomepageError] = useState("");
  const [companyFacebookError, setCompanyFacebookError] = useState("");
  const [companyLinkedinError, setCompanyLinkedinError] = useState("");
  const [companyYoutubeError, setCompanyYoutubeError] = useState("");
  const [companyInstageramError, setCompanyInstageramError] = useState("");
  const [urlSearchError, setUrlSearchError] = useState("");
  const [urlAdminSearchError, setUrlAdminSearchError] = useState("");
  const [urlAdminDirectError, setUrlAdminDirectError] = useState("");

  const handleModalClose = () => setOpenModal(false);

  useEffect(() => {
    dispatch(fetchCompaniesListAsync({ search: "" }));
  }, [dispatch]);

  useEffect(() => {
    setRows(results);
  }, [results]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "company_name",
      headerName: "COMPANY",
      renderCell: (params: any) => {
        return (
          <Typography
            style={{ cursor: "pointer", color: "#26479e" }}
            onClick={() => handleNameClick(params)}
          >
            {params.value}
          </Typography>
        );
      },
      width: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "company_abbrev",
      headerName: "SHORT NAME",
      editable: false,
      cellClassName: "super-app-theme--cell",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "uuid",
      headerName: "UNIQUE ID(UUID)",
      width: 300,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "media_storage_path",
      headerName: "PATH TO MEDIA STORAGE",
      editable: false,
      cellClassName: "super-app-theme--cell",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "company_email_address",
      headerName: "EMAIL",
      width: 200,
      editable: false,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "company_homepage",
      headerName: "HOME PAGE",
      width: 200,
      editable: false,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
    },
  ];

  const handleNameClick = (params: { id: number }) => {
    const { id } = params;
    const dataModal = results?.filter((res) => res.id === id);
    setCompanyName(dataModal[0].company_name);
    const createdAT = new Date(dataModal[0].created_at).toLocaleString(
      "sv-SE",
      {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      },
    );
    const updatedAT = new Date(dataModal[0].updated_at).toLocaleString(
      "sv-SE",
      {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      },
    );
    setCreated(createdAT);
    setUpdated(updatedAT);
    setCompanyName(dataModal[0].company_name);
    setCompanyAbbrev(dataModal[0].company_abbrev);
    setCompanyStreetAddress1(dataModal[0].company_street_address1);
    setCompanyStreetAddress2(dataModal[0].company_street_address2);
    setCompanyPostcode(dataModal[0].company_postcode);
    setCompanyCity(dataModal[0].company_city);
    setCompanyState(dataModal[0].company_state);
    setCompanyCountry(dataModal[0].company_country);
    setCompanyEmailAddress(dataModal[0].company_email_address);
    setCompanyPhonenumber(dataModal[0].company_phonenumber);
    setCompanyHomepage(dataModal[0].company_homepage);
    setCompanyFacebook(dataModal[0].company_facebook);
    setCompanyLinkedin(dataModal[0].company_linkedin);
    setCompanyYoutube(dataModal[0].company_youtube);
    setCompanyInstagram(dataModal[0].company_instagram);
    setCompanyVatNumber(dataModal[0].company_vat_number);
    setPackingSlipPriceConfiguration(
      dataModal[0]?.packing_slip_price_configuration,
    );
    setPackingSlipExtraContent1(dataModal[0].packing_slip_extra_content1);
    setPackingSlipExtraContent2(dataModal[0].packing_slip_extra_content2);
    setPackingSlipFooterRight(dataModal[0].packing_slip_footer_right);
    setBrandingLogo(dataModal[0].branding_logo);
    setBrandingFontColor(dataModal[0].branding_font_color);
    setBrandingBackgroundColor(dataModal[0].branding_background_color);
    setLogoSquare(dataModal[0].logo_square);
    setLogoRectangle(dataModal[0].logo_rectangle);
    setExchangeRateAdjustment(dataModal[0].exchange_rate_adjustment);
    setPriceRoundingPreference(dataModal[0].price_rounding_preference);
    setPriceNoOfDecimalsInExport(dataModal[0].price_no_of_decimals_in_export);
    setPriceCommaSign(dataModal[0].price_comma_sign);
    setPriceInclExclVat(dataModal[0].price_incl_excl_vat);
    setdisplayPriceInclExclVat(dataModal[0].display_price_incl_excl_vat);
    setUuid(dataModal[0].uuid);
    setMediaStoragePath(dataModal[0].media_storage_path);
    setUrlSearch(dataModal[0].url_search);
    setUrlAdminDirect(dataModal[0].url_admin_direct);
    setUrlAdminSearch(dataModal[0].url_admin_search);
    setSkuSequenceNumber(dataModal[0].sku_sequence_number);
    setSkuPrefix(dataModal[0].sku_prefix);
    setSkuSuffix(dataModal[0].sku_suffix);
    setCustomProductSequenceNumber(dataModal[0].custom_product_sequence_number);
    setCustomProductSequenceNumberPrefix(
      dataModal[0].custom_product_sequence_number_prefix,
    );
    setCustomProductSequenceNumberSuffix(
      dataModal[0].custom_product_sequence_number_suffix,
    );
    setAccount(dataModal[0].account);
    setCompany(dataModal[0].company);

    setRowsModal(dataModal);
    setSelectedRow(id);
    setOpenModal(true);
    setCompanyHomepageError("");
    setCompanyFacebookError("");
    setCompanyLinkedinError("");
    setCompanyYoutubeError("");
    setCompanyInstageramError("");
    setUrlSearchError("");
    setUrlAdminSearchError("");
    setUrlAdminDirectError("");
  };

  const handleUpdate = () => {
    if (
      companyHomepageError ||
      companyFacebookError ||
      companyLinkedinError ||
      companyYoutubeError ||
      companyInstageramError ||
      urlSearchError ||
      urlAdminSearchError ||
      urlAdminDirectError
    ) {
      setShow(true);
      dispatch(setUpdateMessage(dataString.ErrorInSave));
    } else {
      const newRows = results.filter((res: any) => res.id === selectedRow);

      const isNameExists = results.some((res) => {
        return res.name_abbrev === companyAbbrev;
      });

      const isTitleExists = results.some((res) => {
        return res.name === companyName;
      });

      const newData = {
        id: selectedRow,
        params: {
          company_name: companyName,
          company_abbrev: companyAbbrev,
          company_street_address1: companyStreetAddress1,
          company_street_address2: companyStreetAddress2,
          company_postcode: companyPostcode,
          company_city: companyCity,
          company_state: companyState,
          company_country: companyCountry,
          company_email_address: companyEmailAddress,
          company_phonenumber: companyPhonenumber,
          company_homepage: companyHomepage,
          company_facebook: companyFacebook,
          company_linkedin: companyLinkedin,
          company_youtube: companyYoutube,
          company_instagram: companyInstagram,
          company_vat_number: companyVatNumber,
          packing_slip_price_configuration: packingSlipPriceConfiguration,
          packing_slip_extra_content1: packingSlipExtraContent1,
          packing_slip_extra_content2: packingSlipExtraContent2,
          packing_slip_footer_right: packingSlipFooterRight,
          branding_logo: brandingLogo,
          branding_font_color: brandingFontColor,
          branding_background_color: brandingBackgroundColor,
          logo_square: logoSquare,
          logo_rectangle: logoRectangle,
          exchange_rate_adjustment: exchangeRateAdjustment,
          price_rounding_preference: priceRoundingPreference,
          price_no_of_decimals_in_export: priceNoOfDecimalsInExport,
          price_comma_sign: priceCommaSign,
          price_incl_excl_vat: priceInclExclVat,
          display_price_incl_excl_vat: displayPriceInclExclVat,
          uuid: uuid,
          media_storage_path: mediaStoragePath,
          url_search: urlSearch,
          url_admin_direct: urlAdminDirect,
          url_admin_search: urlAdminSearch,
          sku_sequence_number: skuSequenceNumber,
          sku_prefix: skuPrefix,
          sku_suffix: skuSuffix,
          custom_product_sequence_number: customProductSequenceNumber,
          custom_product_sequence_number_prefix:
            customProductSequenceNumberPrefix,
          custom_product_sequence_number_suffix:
            customProductSequenceNumberSuffix,
          account: account,
          company: company,
        },
      };

      (!isNameExists || companyAbbrev === newRows[0].name_abbrev) &&
      (!isTitleExists || companyName === newRows[0].name)
        ? dispatch(UpdateProcurementCompaniesListAsync(newData))
            .unwrap()
            .finally(() => {
              dispatch(fetchCompaniesListAsync({ search: "" }));
              setShow(true);
              setOpenModal(false);
            })
        : setShowShortNameErrorMsg(true);
    }
  };

  const handleSelectionModelChange = (newSelectionModel: any) => {
    setSelectionModel(newSelectionModel);
  };

  return (
    <>
      {showShortNameErrorMsg ? (
        <SimpleSnackbar
          open={showShortNameErrorMsg}
          message="Name or Short name exist!"
          toggleSnackbar={() => {
            setShowShortNameErrorMsg(false);
          }}
          snackbarAction={null}
        />
      ) : null}
      {show ? (
        <SimpleSnackbar
          open={show}
          message={updateMessage}
          toggleSnackbar={() => {
            dispatch(setUpdateMessage(""));
            setShow(false);
          }}
          snackbarAction={null}
        />
      ) : null}
      <GridContainer>
        <DataGridPro
          rows={rows}
          columns={columns}
          columnVisibilityModel={{
            id: false,
          }}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={handleSelectionModelChange}
          pagination
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
        />
      </GridContainer>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Box
            data-testid="modalDisplay"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography id="modal-modal-title" sx={{ color: "text.secondary" }}>
              Edit company: {companyName}
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={handleModalClose}>
              <CloseIcon />
            </Box>
          </Box>
          <ModalContainerUpdate
            rowsModal={rowsModal}
            created={created}
            updated={updated}
            companyName={companyName}
            companyAbbrev={companyAbbrev}
            companyStreetAddress1={companyStreetAddress1}
            companyStreetAddress2={companyStreetAddress2}
            companyPostcode={companyPostcode}
            companyCity={companyCity}
            companyCountry={companyCountry}
            companyState={companyState}
            companyPhonenumber={companyPhonenumber}
            companyYoutube={companyYoutube}
            companyInstagram={companyInstagram}
            companyVatNumber={companyVatNumber}
            companyEmailAddress={companyEmailAddress}
            companyHomePage={companyHomepage}
            companyFacebook={companyFacebook}
            companyLinkedin={companyLinkedin}
            packingSlipPriceConfiguration={packingSlipPriceConfiguration}
            packingSlipExtraContent1={packingSlipExtraContent1}
            packingSlipExtraContent2={packingSlipExtraContent2}
            brandingLogo={brandingLogo}
            brandingFontColor={brandingFontColor}
            brandingBackgroundColor={brandingBackgroundColor}
            logoRectangle={logoRectangle}
            exchangeRateAdjustment={exchangeRateAdjustment}
            priceNoOfDecimalsInExport={priceNoOfDecimalsInExport}
            priceRoundingPreference={priceRoundingPreference}
            priceCommaSign={priceCommaSign}
            priceInclExclVat={priceInclExclVat}
            displayPriceInclExclVat={displayPriceInclExclVat}
            uuid={uuid}
            mediaStoragePath={mediaStoragePath}
            urlSearch={urlSearch}
            urlAdminDirect={urlAdminDirect}
            urlAdminSearch={urlAdminSearch}
            skuSequenceNumber={skuSequenceNumber}
            skuPrefix={skuPrefix}
            skuSuffix={skuSuffix}
            customProductSequenceNumber={customProductSequenceNumber}
            customProductSequenceNumberPrefix={
              customProductSequenceNumberPrefix
            }
            customProductSequenceNumberSuffix={
              customProductSequenceNumberSuffix
            }
            account={account}
            company={company}
            packingSlipFooterRight={packingSlipFooterRight}
            logoSquare={logoSquare}
            companyHomepageError={companyHomepageError}
            companyFacebookError={companyFacebookError}
            companyLinkedinError={companyLinkedinError}
            companyYoutubeError={companyYoutubeError}
            companyInstageramError={companyInstageramError}
            urlSearchError={urlSearchError}
            urlAdminSearchError={urlAdminSearchError}
            urlAdminDirectError={urlAdminDirectError}
            setCompanyName={setCompanyName}
            setCompanyAbbrev={setCompanyAbbrev}
            setCompanyStreetAddress1={setCompanyStreetAddress1}
            setCompanyStreetAddress2={setCompanyStreetAddress2}
            setCompanyPostcode={setCompanyPostcode}
            setCompanyCity={setCompanyCity}
            setCompanyState={setCompanyState}
            setCompanyCountry={setCompanyCountry}
            setCompanyEmailAddress={setCompanyEmailAddress}
            setCompanyPhonenumber={setCompanyPhonenumber}
            setCompanyHomepage={setCompanyHomepage}
            setCompanyFacebook={setCompanyFacebook}
            setCompanyLinkedin={setCompanyLinkedin}
            setCompanyYoutube={setCompanyYoutube}
            setCompanyInstagram={setCompanyInstagram}
            setCompanyVatNumber={setCompanyVatNumber}
            setPackingSlipPriceConfiguration={setPackingSlipPriceConfiguration}
            setPackingSlipExtraContent1={setPackingSlipExtraContent1}
            setPackingSlipExtraContent2={setPackingSlipExtraContent2}
            setPackingSlipFooterRight={setPackingSlipFooterRight}
            setBrandingLogo={setBrandingLogo}
            setBrandingFontColor={setBrandingFontColor}
            setBrandingBackgroundColor={setBrandingBackgroundColor}
            setLogoSquare={setLogoSquare}
            setLogoRectangle={setLogoRectangle}
            setExchangeRateAdjustment={setExchangeRateAdjustment}
            setPriceRoundingPreference={setPriceRoundingPreference}
            setPriceNoOfDecimalsInExport={setPriceNoOfDecimalsInExport}
            setPriceCommaSign={setPriceCommaSign}
            setPriceInclExclVat={setPriceInclExclVat}
            setdisplayPriceInclExclVat={setdisplayPriceInclExclVat}
            setUuid={setUuid}
            setMediaStoragePath={setMediaStoragePath}
            setUrlSearch={setUrlSearch}
            setUrlAdminDirect={setUrlAdminDirect}
            setUrlAdminSearch={setUrlAdminSearch}
            setSkuSequenceNumber={setSkuSequenceNumber}
            setSkuPrefix={setSkuPrefix}
            setSkuSuffix={setSkuSuffix}
            setCustomProductSequenceNumber={setCustomProductSequenceNumber}
            setCustomProductSequenceNumberPrefix={
              setCustomProductSequenceNumberPrefix
            }
            setCustomProductSequenceNumberSuffix={
              setCustomProductSequenceNumberSuffix
            }
            setAccount={setAccount}
            setCompany={setCompany}
            setCreated={setCreated}
            setUpdated={setUpdated}
            setcompanyHomepageError={setCompanyHomepageError}
            setcompanyFacebookError={setCompanyFacebookError}
            setcompanyLinkedinError={setCompanyLinkedinError}
            setcompanyYoutubeError={setCompanyYoutubeError}
            setcompanyInstageramError={setCompanyInstageramError}
            seturlSearchError={setUrlSearchError}
            seturlAdminSearchError={setUrlAdminSearchError}
            seturlAdminDirectError={setUrlAdminDirectError}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant="contained" onClick={handleUpdate}>
              {dataString.saveBtn}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default CompaniesList;
