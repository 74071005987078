import React, { useEffect, useState } from "react";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";

// Managing modal state and updating file URLs
const useFileUrlModal = (
  localResults: any[],
  rowSelectionModel,
  setRowSelectionModel,
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isRowSelected, setIsRowSelected] = useState<boolean>(false);
  const [shouldBeDisable, setShouldBeDisable] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();

  const [url, setUrl] = useState<string>();
  const [sourceUrl, setSourceUrl] = useState<string>();
  const [priority, setPriority] = useState<number>();
  const [title, setTitle] = useState<string>();
  const [removedFromSource, setRemovedFromSource] = useState<boolean>();
  const [account, setAccount] = useState<number>();
  const [company, setCompany] = useState<number>();
  const [product, setProduct] = useState<number>();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const SelectedRow = (id: any) => {
    const selectedRow = localResults.find((item: any) => item.id == id);
    setUrl(selectedRow.url);
    setSourceUrl(selectedRow.source_url);
    setPriority(selectedRow.priority);
    setTitle(selectedRow.title);
    setRemovedFromSource(selectedRow.removed_from_source);
    setAccount(selectedRow.account);
    setCompany(selectedRow.company);
    setProduct(selectedRow.product);
    return selectedRow;
  };

  useEffect(() => {
    const selectionCount = rowSelectionModel.length;
    const isSingleSelection = selectionCount === 1;

    setIsRowSelected(selectionCount > 0);
    setShouldBeDisable(selectionCount > 1);

    if (isSingleSelection) {
      setSelectedId(rowSelectionModel[0] as any);
      SelectedRow(rowSelectionModel[0] as any);
    }
  }, [rowSelectionModel]);

  return {
    isModalOpen,
    rowSelectionModel,
    isRowSelected,
    shouldBeDisable,
    selectedId,
    url,
    sourceUrl,
    priority,
    title,
    removedFromSource,
    account,
    company,
    product,
    openModal,
    closeModal,
    setTitle,
    setUrl,
    setSourceUrl,
    setPriority,
    setRemovedFromSource,
    setAccount,
    setCompany,
    setProduct,
    setRowSelectionModel,
  };
};

export default useFileUrlModal;
